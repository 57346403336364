var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable class-methods-use-this */
import deviceMaster from 'helpers/device-master';
import dialogCaller from 'app/actions/dialog-caller';
import progressCaller from 'app/actions/progress-caller';
import i18n from 'helpers/i18n';
import getLevelingData from './getLevelingData';
class FisheyePreviewManagerBase {
    constructor() {
        this.support3dRotation = false;
        this.progressId = 'fisheye-preview-manager';
    }
    setupFisheyePreview() {
        throw new Error('Method not implemented.');
    }
    onObjectHeightChanged() {
        throw new Error('Method not implemented.');
    }
    reloadLevelingOffset() {
        return __awaiter(this, void 0, void 0, function* () {
            this.levelingOffset = yield getLevelingData('offset');
            this.onObjectHeightChanged();
        });
    }
    resetObjectHeight() {
        return __awaiter(this, void 0, void 0, function* () {
            let res = false;
            try {
                const newHeight = yield dialogCaller.getPreviewHeight({
                    initValue: this.objectHeight,
                });
                if (typeof newHeight === 'number') {
                    this.objectHeight = newHeight;
                    yield this.onObjectHeightChanged();
                    res = true;
                }
                return res;
            }
            finally {
                if (deviceMaster.currentControlMode === 'raw') {
                    yield deviceMaster.rawLooseMotor();
                    progressCaller.update(this.progressId, { message: i18n.lang.message.endingRawMode });
                    yield deviceMaster.endRawMode();
                }
                progressCaller.popById(this.progressId);
            }
        });
    }
}
export default FisheyePreviewManagerBase;
