var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import storage from 'implementations/storage';
import { axiosFluxId, fluxIDEvents, getCurrentUser } from 'helpers/api/flux-id';
const recordActivity = () => __awaiter(void 0, void 0, void 0, function* () {
    const user = getCurrentUser();
    if (!user)
        return;
    const date = new Date().toISOString().slice(0, 10);
    if (storage.get('last-record-activity') === date)
        return;
    const { data } = yield axiosFluxId.post('/user/activity/beam-studio', { version: window.FLUX.version }, { withCredentials: true });
    if (data.status === 'ok')
        storage.set('last-record-activity', date);
});
fluxIDEvents.addListener('update-user', recordActivity);
export default { recordActivity };
