import imageEdit from 'helpers/image-edit';
import { getSVGAsync } from 'helpers/svg-editor-helper';
let svgCanvas;
getSVGAsync((globalSVG) => {
    svgCanvas = globalSVG.Canvas;
});
const handleFinish = (element, src, base64, attrs = {}) => {
    const changes = Object.assign({ origImage: src, 'xlink:href': base64 }, attrs);
    imageEdit.addBatchCommand('Image Edit', element, changes);
    svgCanvas.selectOnly([element], true);
};
export default handleFinish;
