import classNames from 'classnames';
import React from 'react';
import BeamboxPreference from 'app/actions/beambox/beambox-preference';
import i18n from 'helpers/i18n';
import storage from 'implementations/storage';
import UnitInput from 'app/widgets/Unit-Input-v2';
import { getWorkarea } from 'app/constants/workarea-constants';
const LANG = i18n.lang.beambox.tool_panels;
class Interval extends React.Component {
    constructor(props) {
        super(props);
        this.onDxChanged = (dx) => {
            const { onValueChange } = this.props;
            const { dy } = this.state;
            onValueChange({
                dx,
                dy,
            });
            this.setState({ dx });
        };
        this.onDyChanged = (dy) => {
            const { onValueChange } = this.props;
            const { dx } = this.state;
            onValueChange({
                dx,
                dy,
            });
            this.setState({ dy });
        };
        this.getValueCaption = () => {
            const { dx, dy } = this.state;
            const units = storage.get('default-units') || 'mm';
            return units === 'inches'
                ? `${Number(dx / 25.4).toFixed(3)}", ${Number(dy / 25.4).toFixed(3)}"`
                : `${dx}, ${dy} mm`;
        };
        const { dx, dy } = this.props;
        this.state = {
            dx,
            dy,
            isCollapsed: false,
        };
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            dx: nextProps.dx,
            dy: nextProps.dy,
        });
    }
    render() {
        const { dx, dy, isCollapsed } = this.state;
        const workarea = getWorkarea(BeamboxPreference.read('workarea'));
        return (React.createElement("div", { className: "tool-panel" },
            React.createElement("label", { className: "controls accordion" },
                React.createElement("input", { type: "checkbox", className: "accordion-switcher", defaultChecked: true }),
                React.createElement("p", { className: "caption", onClick: () => this.setState({ isCollapsed: !isCollapsed }) },
                    LANG.array_interval,
                    React.createElement("span", { className: "value" }, this.getValueCaption())),
                React.createElement("div", { className: classNames('tool-panel-body', { collapsed: isCollapsed }) },
                    React.createElement("div", { className: "control" },
                        React.createElement("span", { className: "text-center header" }, LANG.dx),
                        React.createElement(UnitInput, { id: "array_width", min: 0, max: workarea.width, unit: "mm", defaultValue: dx, getValue: this.onDxChanged })),
                    React.createElement("div", { className: "control" },
                        React.createElement("span", { className: "text-center header" }, LANG.dy),
                        React.createElement(UnitInput, { id: "array_height", min: 0, max: workarea.displayHeight || workarea.height, unit: "mm", defaultValue: dy, getValue: this.onDyChanged }))))));
    }
}
export default Interval;
