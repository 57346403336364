import React, { useContext } from 'react';
import { Modal } from 'antd';
import useI18n from 'helpers/useI18n';
import { CalibrationContext } from 'app/contexts/CalibrationContext';
import { STEP_REFOCUS } from 'app/constants/camera-calibration-constants';
const StepPutPaper = () => {
    const lang = useI18n().calibration;
    const { gotoNextStep, onClose } = useContext(CalibrationContext);
    const video = (React.createElement("video", { className: "video", autoPlay: true, loop: true, muted: true },
        React.createElement("source", { src: "video/put_paper.webm", type: "video/webm" }),
        React.createElement("source", { src: "video/put_paper.mp4", type: "video/mp4" })));
    return (React.createElement(Modal, { width: 400, open: true, centered: true, className: "modal-camera-calibration", title: lang.camera_calibration, onCancel: () => onClose(false), okText: lang.next, cancelText: lang.cancel, onOk: () => gotoNextStep(STEP_REFOCUS) },
        lang.please_place_paper,
        video));
};
export default StepPutPaper;
