// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-components-beambox-top-bar-FileName-module__title--mO1rc{display:flex;align-items:center}.src-web-app-components-beambox-top-bar-FileName-module__file-name--uicyy{position:absolute;top:0;width:100%;height:40px;display:flex;align-items:center;justify-content:center;font-size:14px;letter-spacing:1.03px;color:#fff;pointer-events:none}@media screen and (max-width: 600px){.src-web-app-components-beambox-top-bar-FileName-module__file-name--uicyy{background-color:#333;top:40px;height:20px;z-index:1}}", "",{"version":3,"sources":["webpack://./src/web/app/components/beambox/top-bar/FileName.module.scss","webpack://./src/web/styles/_variables.scss"],"names":[],"mappings":"AAEA,sEACE,YAAA,CACA,kBAAA,CAGF,0EACE,iBAAA,CACA,KAAA,CACA,UAAA,CACA,WCJa,CDKb,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,cAAA,CACA,qBAAA,CACA,UAAA,CACA,mBAAA,CAEA,qCAbF,0EAcI,qBAAA,CACA,QCfW,CDgBX,WAAA,CACA,SAAA,CAAA","sourcesContent":["@use 'styles/variables' as variables;\n\n.title {\n  display: flex;\n  align-items: center;\n}\n\n.file-name {\n  position: absolute;\n  top: 0;\n  width: 100%;\n  height: variables.$topBarHeight;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-size: 14px;\n  letter-spacing: 1.03px;\n  color: #ffffff;\n  pointer-events: none;\n\n  @media screen and (max-width: 600px) {\n    background-color: #333;\n    top: variables.$topBarHeight;\n    height: 20px;\n    z-index: 1;\n  }\n}\n","$print-default-font-color: #4A4A4A;\n$default-button-border-radius: 4px;\n$size: 200px;\n$backgroundColor: #F0F0F0;\n$sidePanelWidth: 242px;\n$winSidePanelWidth: 258px;\n$panelBorderColor: #E0E0E0;\n$topBarHeight: 40px;\n\n$primary-blue: #1890ff;\n$primary-gray: #1e1e1e;\n$secondary-gray: #7c7c7c;\n$s3: 'https://beam-studio-web.s3.ap-northeast-1.amazonaws.com';\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "src-web-app-components-beambox-top-bar-FileName-module__title--mO1rc",
	"file-name": "src-web-app-components-beambox-top-bar-FileName-module__file-name--uicyy"
};
export default ___CSS_LOADER_EXPORT___;
