import React from 'react';
import i18n from 'helpers/i18n';
import ObjectPanelItem from 'app/views/beambox/Right-Panels/ObjectPanelItem';
import styles from 'app/views/beambox/Right-Panels/OptionsPanel.module.scss';
import UnitInput from 'app/widgets/Unit-Input-v2';
import { useIsMobile } from 'helpers/system-helper';
export default function StartOffsetBlock({ value, onValueChange }) {
    const LANG = i18n.lang.beambox.right_panel.object_panel.option_panel;
    const isMobile = useIsMobile();
    return isMobile ? (React.createElement(ObjectPanelItem.Number, { id: "start_offset", label: LANG.start_offset, value: value, min: 0, max: 100, updateValue: onValueChange, unit: "", decimal: 0 })) : (React.createElement("div", { className: styles['option-block'] },
        React.createElement("div", { className: styles.label }, LANG.start_offset),
        React.createElement(UnitInput, { min: 0, max: 100, unit: "", decimal: 0, className: { [styles['option-input']]: true }, defaultValue: value, getValue: onValueChange })));
}
