var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { swiftrayClient } from 'helpers/api/swiftray-client';
const loadCalibrationTask = (model, width) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c, _d;
    const fileName = `fcode/promark-calibration-${width}.bvg`;
    const resp = yield fetch(fileName);
    const scene = yield resp.text();
    // use mid dpi (254)
    const uploadRes = yield swiftrayClient.loadSVG({ data: scene, extension: 'svg', name: fileName, uploadName: fileName, thumbnail: '' }, { onProgressing: () => { }, onFinished: () => { }, onError: () => { } }, { engraveDpi: 254, model, rotaryMode: false });
    if (!uploadRes.success)
        throw new Error(`Failed to load calibration task: ${(_b = (_a = uploadRes.error) === null || _a === void 0 ? void 0 : _a.message) !== null && _b !== void 0 ? _b : 'Unknown Error'}`);
    const convertRes = yield swiftrayClient.convert('gcode', { onProgressing: () => { }, onFinished: () => { }, onError: () => { } }, { model, travelSpeed: 4000 });
    if (!convertRes.success)
        throw new Error(`Failed to convert calibration task: ${(_d = (_c = convertRes.error) === null || _c === void 0 ? void 0 : _c.message) !== null && _d !== void 0 ? _d : 'Unknown Error'}`);
});
export default loadCalibrationTask;
