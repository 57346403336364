var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import beamboxPrefernce from 'app/actions/beambox/beambox-preference';
import { getLayerName } from 'helpers/layer/layer-helper';
import { getSVGAsync } from 'helpers/svg-editor-helper';
import setElementsColor from './setElementsColor';
import updateLayerColorFilter from './updateLayerColorFilter';
let svgCanvas;
getSVGAsync((globalSVG) => {
    svgCanvas = globalSVG.Canvas;
});
// TODO: add test
const updateLayerColor = (layer) => __awaiter(void 0, void 0, void 0, function* () {
    const useLayerColor = beamboxPrefernce.read('use_layer_color');
    const color = useLayerColor ? layer.getAttribute('data-color') : '#000';
    const isFullColor = layer.getAttribute('data-fullcolor') === '1';
    const elems = Array.from(layer.childNodes);
    const tempGroup = svgCanvas.getTempGroup();
    if (tempGroup) {
        const layerName = getLayerName(layer);
        const multiSelectedElems = tempGroup.querySelectorAll(`[data-original-layer="${layerName}"]`);
        elems.push(...multiSelectedElems);
    }
    yield setElementsColor(elems, color, isFullColor);
    updateLayerColorFilter(layer);
});
export default updateLayerColor;
