import React from 'react';
import { Select as AntdSelect } from 'antd';
import isWeb from 'helpers/is-web';
// TODO: replace native selects (including widgets/Select.tsx) and rename to Select.tsx
const Select = (props) => (
// eslint-disable-next-line react/jsx-props-no-spreading
React.createElement(AntdSelect, Object.assign({}, props, { dropdownAlign: !isWeb() && { overflow: { adjustY: 0 } } })));
Select.Option = AntdSelect.Option;
Select.OptGroup = AntdSelect.OptGroup;
export default Select;
