// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-actions-canvas-grid-module__container--pGKUf{pointer-events:none}.src-web-app-actions-canvas-grid-module__container--pGKUf .src-web-app-actions-canvas-grid-module__x--\\+KhEx>line{stroke:#000;opacity:.18;stroke-width:1;vector-effect:non-scaling-stroke}.src-web-app-actions-canvas-grid-module__container--pGKUf .src-web-app-actions-canvas-grid-module__x--\\+KhEx>line:nth-child(10n+1){opacity:.5}.src-web-app-actions-canvas-grid-module__container--pGKUf .src-web-app-actions-canvas-grid-module__y--FlPhO>line{stroke:#000;opacity:.18;stroke-width:1;vector-effect:non-scaling-stroke}.src-web-app-actions-canvas-grid-module__container--pGKUf .src-web-app-actions-canvas-grid-module__y--FlPhO>line:nth-child(10n+1){opacity:.5}", "",{"version":3,"sources":["webpack://./src/web/app/actions/canvas/grid.module.scss"],"names":[],"mappings":"AAAA,0DACE,mBAAA,CAGE,kHACE,WAAA,CACA,WAAA,CACA,cAAA,CACA,gCAAA,CAEA,mIACE,UAAA,CAMJ,iHACE,WAAA,CACA,WAAA,CACA,cAAA,CACA,gCAAA,CAEA,kIACE,UAAA","sourcesContent":[".container {\n  pointer-events: none;\n\n  .x {\n    > line {\n      stroke: black;\n      opacity: 0.18;\n      stroke-width: 1;\n      vector-effect: non-scaling-stroke;\n\n      &:nth-child(10n + 1) {\n        opacity: 0.5;\n      }\n    }\n  }\n\n  .y {\n    > line {\n      stroke: black;\n      opacity: 0.18;\n      stroke-width: 1;\n      vector-effect: non-scaling-stroke;\n\n      &:nth-child(10n + 1) {\n        opacity: 0.5;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "src-web-app-actions-canvas-grid-module__container--pGKUf",
	"x": "src-web-app-actions-canvas-grid-module__x--+KhEx",
	"y": "src-web-app-actions-canvas-grid-module__y--FlPhO"
};
export default ___CSS_LOADER_EXPORT___;
