import classNames from 'classnames';
import React from 'react';
import i18n from 'helpers/i18n';
import ObjectPanelItem from 'app/views/beambox/Right-Panels/ObjectPanelItem';
import Select from 'app/widgets/AntdSelect';
import styles from 'app/views/beambox/Right-Panels/OptionsPanel.module.scss';
import { useIsMobile } from 'helpers/system-helper';
import { VerticalAlign } from 'app/actions/beambox/textPathEdit';
export default function VerticalAlignBlock({ value, onValueChange }) {
    const LANG = i18n.lang.beambox.right_panel.object_panel;
    const label = LANG.option_panel.vertical_align;
    const isMobile = useIsMobile();
    const options = [
        { value: VerticalAlign.BOTTOM, label: LANG.bottom_align },
        { value: VerticalAlign.MIDDLE, label: LANG.middle_align },
        { value: VerticalAlign.TOP, label: LANG.top_align },
    ];
    if (isMobile) {
        return (React.createElement(ObjectPanelItem.Select, { id: "vertical_align", selected: options.find((option) => option.value === value), options: options, onChange: onValueChange, label: label }));
    }
    return (React.createElement("div", { className: classNames(styles['option-block'], styles['with-select']) },
        React.createElement("div", { className: styles.label }, label),
        React.createElement(Select, { value: value, options: options, onChange: (val) => onValueChange(val), onKeyDown: (e) => e.stopPropagation(), dropdownMatchSelectWidth: false })));
}
