// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "div.src-web-app-widgets-FloatingPanel-module__panel--SQLiE{--border-radius: 24px}.src-web-app-widgets-FloatingPanel-module__panel--SQLiE .adm-floating-panel-content{display:flex;flex-direction:column;margin-top:-1px}.src-web-app-widgets-FloatingPanel-module__panel--SQLiE .src-web-app-widgets-FloatingPanel-module__close-icon--4IA2w{font-size:24px;position:absolute;top:12px;right:12px}.src-web-app-widgets-FloatingPanel-module__panel--SQLiE .src-web-app-widgets-FloatingPanel-module__title--qGwCe{text-align:center;font-size:16px;font-weight:600;margin-bottom:8px}.src-web-app-widgets-FloatingPanel-module__panel--SQLiE .src-web-app-widgets-FloatingPanel-module__scroll-content--qAK7v{overflow-y:scroll;margin-bottom:60px;padding-bottom:12px}", "",{"version":3,"sources":["webpack://./src/web/app/widgets/FloatingPanel.module.scss"],"names":[],"mappings":"AAAA,2DACE,qBAAA,CAIA,oFACE,YAAA,CACA,qBAAA,CACA,eAAA,CAGF,qHACE,cAAA,CACA,iBAAA,CACA,QAAA,CACA,UAAA,CAGF,gHACE,iBAAA,CACA,cAAA,CACA,eAAA,CACA,iBAAA,CAGF,yHACE,iBAAA,CAEA,kBAAA,CACA,mBAAA","sourcesContent":["div.panel {\n  --border-radius: 24px;\n}\n\n.panel {\n  :global(.adm-floating-panel-content) {\n    display: flex;\n    flex-direction: column;\n    margin-top: -1px;\n  }\n\n  .close-icon {\n    font-size: 24px;\n    position: absolute;\n    top: 12px;\n    right: 12px;\n  }\n\n  .title {\n    text-align: center;\n    font-size: 16px;\n    font-weight: 600;\n    margin-bottom: 8px;\n  }\n\n  .scroll-content {\n    overflow-y: scroll;\n    // Space for bottom toolbar\n    margin-bottom: 60px;\n    padding-bottom: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panel": "src-web-app-widgets-FloatingPanel-module__panel--SQLiE",
	"close-icon": "src-web-app-widgets-FloatingPanel-module__close-icon--4IA2w",
	"title": "src-web-app-widgets-FloatingPanel-module__title--qGwCe",
	"scroll-content": "src-web-app-widgets-FloatingPanel-module__scroll-content--qAK7v"
};
export default ___CSS_LOADER_EXPORT___;
