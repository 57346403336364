const lang = {
    global: {
        cancel: 'Zrušit',
        back: 'Zpět',
    },
    general: {
        processing: 'Zpracovávám',
        choose_folder: 'Vyberte složku',
    },
    buttons: {
        next: 'Další',
        back: 'Předchozí',
        done: 'Hotovo',
        back_to_beam_studio: 'Zpátky do Beam Studio',
    },
    topbar: {
        untitled: 'Bez názvu',
        titles: {
            settings: 'Vlastnosti',
        },
        export: 'SPUSTIT',
        preview: 'NÁHLED',
        preview_title: 'Náhled',
        curve_engrave: '3D křivka',
        task_preview: 'Náhled cesty',
        frame_task: 'Rámování',
        borderless: '(OTEVŘÍT SPODNÍ ČÁST)',
        tag_names: {
            rect: 'Obdélník',
            ellipse: 'Ovál',
            path: 'Cesta',
            polygon: 'Polygon',
            image: 'Obrázek',
            text: 'Text',
            text_path: 'Text na cestě',
            pass_through_object: 'Procházet objektem',
            line: 'Linka',
            g: 'Skupina',
            multi_select: 'Několik objektů',
            use: 'Importovaný objekt',
            svg: 'SVG objekt',
            dxf: 'DXF objekt',
            no_selection: 'Žádný výběr',
        },
        alerts: {
            start_preview_timeout: '#803 Během spuštění náhledu došlo k přerušení. Restartujte prosím své zařízení nebo Beam Studio. Pokud bude tato chyba přetrvávat, přečtěte si prosím <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">tento návod</a>.',
            fail_to_start_preview: '#803 Nepodařil se spustit náhled. Restartujte prosím své zařízení nebo Beam Studio. Pokud bude tato chyba přetrvávat, přečtěte si prosím <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">tento návod</a>.',
            fail_to_connect_with_camera: '#803 Nepodařilo se navázat spojení s kamerou. Restartujte prosím své zařízení nebo Beam Studio. Pokud bude tato chyba přetrvávat, přečtěte si prosím <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">tento návod</a>.',
            add_content_first: 'Nejprve prosím přidejte objekty.',
            headtype_mismatch: 'Nesprávný modul detekován.',
            headtype_none: 'Žádný modul nedetekován.',
            headtype_unknown: 'Neznámý modul detekován.',
            install_correct_headtype: 'Nainstalujte prosím správně moduly laserových diod 10W/20W, aby mohl laser nízké intenzity zahájit rámování.',
            door_opened: 'Uzavřete prosím dvířka, aby mohl laser nízké intenzity zahájit rámování.',
            fail_to_get_door_status: 'Ujistěte se prosím, že jsou dvířka uzavřená, aby mohl laser nízké intenzity zahájit rámování.',
            QcleanScene: 'Chcete vymazat kresbu?<br/> Smaže se tím i historie úprav!',
            power_too_high: 'PŘÍLIŠ VYSOKÁ INTENZITA',
            power_too_high_msg: 'Využívání nižší intenzity laseru (pod 70%) prodlouží životnost laserové trubice.\nStiskněte "ROZUMÍM" pro pokračování.',
            power_too_high_confirm: 'ROZUMÍM',
            pwm_unavailable: 'Depth mode (režim hloubky) vyžaduje firmware verze 4.3.4 / 5.3.4 nebo vyšší. Chcete nyní aktualizovat firmware?',
            job_origin_unavailable: 'Proces nastavení vodítek požaduje firmware verze 4.3.4 / 5.3.4 nebo vyšší. Chcete nyní aktualizovat firmware?',
            job_origin_warning: 'Momentálně používáte jako výchozí bod „aktuální pozici“. Ujistěte se, že je laserová hlava přesunuta do správné polohy, aby nedošlo ke kolizím.',
        },
        hint: {
            polygon: 'Pro zvyšování / snižování stran využívejte klávesy + / -.',
        },
        menu: {
            preferences: 'Vlastnosti',
            hide: 'Skrýt Beam Studio',
            hideothers: 'Skrýt ostatní',
            service: 'Služby',
            quit: 'Ukončit',
            window: 'Okno',
            minimize: 'Minimalizovat',
            close: 'Zavřít okno',
            file: 'Soubor',
            edit: 'Úpravy',
            help: 'Pomoc',
            open: 'Otevřít',
            samples: 'Příklady',
            import_hello_beamo: 'Příklady beamo',
            import_hello_beambox: 'Příklady beamboxu',
            import_material_testing_old: 'Test gravírování materiálu - Klasický',
            import_material_testing_simple_cut: 'Test řezání materiálu - Jednoduchý',
            import_material_testing_cut: 'Test řezání materiálu',
            import_material_testing_engrave: 'Test gravírování materiálu',
            import_material_testing_line: 'Test linkování materiálu',
            import_material_printing_test: 'Test tisknutí materiálu',
            import_ador_laser_example: 'Příklad Ador Laseru',
            import_ador_printing_example_single: 'Příklad Ador tisknutí - jednobarevné',
            import_ador_printing_example_full: 'Příklad Ador tisknutí - barevné',
            import_acrylic_focus_probe: 'Akrylový focus probe - 3mm',
            export_to: 'Exportovat do...',
            export_flux_task: 'Úloha FLUX',
            export_BVG: 'BVG',
            export_SVG: 'SVG',
            export_PNG: 'PNG',
            export_JPG: 'JPG',
            save_scene: 'Uložit',
            save_as: 'Uložit jako...',
            save_to_cloud: 'Uložit do cloudu',
            about_beam_studio: 'O Beam Studio',
            undo: 'Zpět',
            redo: 'Znovu',
            cut: 'Vyjmout',
            copy: 'Kopírovat',
            paste: 'Vložit',
            paste_in_place: 'Vložit na místo',
            group: 'Sloučit do skupiny',
            ungroup: 'Rozdělit skupinu',
            delete: 'Smazat',
            duplicate: 'Duplikovat',
            offset: 'Ofset',
            scale: 'Škálovat',
            rotate: 'Otočit',
            reset: 'Restartovat',
            align_center: 'Zarovnat na střed',
            photo_edit: 'Obrázek',
            svg_edit: 'SVG',
            path: 'Cesta',
            decompose_path: 'Rozložit',
            object: 'Objekt',
            layer_setting: 'Vrstva',
            layer_color_config: 'Nastavení barev',
            image_sharpen: 'Zaostřit',
            image_crop: 'Oříznout',
            image_invert: 'Invertovat',
            image_stamp: 'Zkosit',
            image_vectorize: 'Obkreslit',
            image_curve: 'Křivka',
            align_to_edges: 'Přichytit do vertexů',
            document_setting: 'Nastavení dokumentu',
            document_setting_short: 'Dokument',
            rotary_setup: 'Nastavení rotačního zařízení',
            clear_scene: 'Nový',
            machines: 'Přístroje',
            add_new_machine: 'Nastavení přístroje',
            help_center: 'Centrum nápovědy',
            show_gesture_tutorial: 'Návod k dotykovým gestům',
            show_start_tutorial: 'Ukázat Úvodní výuku',
            show_ui_intro: 'Ukázat Úvod do uživatelského rozhraní',
            questionnaire: 'Dotazník se zpětnou vazbou',
            change_logs: 'Změnit záznamy',
            contact: 'Kontaktujte nás',
            tutorial: 'Zahájit výuku k Delta Family Printing',
            design_market: 'Design Market',
            forum: 'Komunitní fórum',
            software_update: 'Aktualizace softwaru',
            bug_report: 'Nahlásit chybu',
            dashboard: 'Hlavní panel',
            machine_info: 'Informace o přístroji',
            network_testing: 'Testovat nastavení sítě',
            commands: 'Příkazy',
            update_firmware: 'Aktualizovat firmware',
            using_beam_studio_api: 'Využívání Beam Studio API',
            set_as_default: 'Nastavit jako výchozí',
            calibrate_beambox_camera: 'Kalibrovat kameru',
            calibrate_printer_module: 'Kalibrovat tiskový modul',
            calibrate_ir_module: 'Kalibrovat infračervený modul',
            calibrate_beambox_camera_borderless: 'Kalibrovat kameru (otevřená spodní část)',
            calibrate_diode_module: 'Kalibrovat modul laserové diody',
            manage_account: 'Nastavit Můj účet',
            sign_in: 'Přihlásit',
            sign_out: 'Odhlásit',
            account: 'Účet',
            my_account: 'Můj účet',
            download_log: 'Záznamy stažení',
            download_log_canceled: 'Stahování záznamů zrušeno',
            download_log_error: 'Nastala neznámá chyba, zkuste to prosím později',
            keyboard_shortcuts: 'Klávesové zkratky',
            log: {
                network: 'Síť',
                hardware: 'Hardware',
                discover: 'Objevit',
                usb: 'USB',
                usblist: 'Seznam USB',
                camera: 'Kamera',
                cloud: 'Cloud',
                player: 'Přehrávač',
                robot: 'Robot',
            },
            link: {
                help_center: 'https://helpcenter.flux3dp.com/',
                contact_us: 'https://flux3dp.zendesk.com/hc/en-us/requests/new',
                forum: 'https://www.facebook.com/groups/flux.laser/',
                downloads: 'https://flux3dp.com/downloads/',
                beam_studio_api: 'https://github.com/flux3dp/beam-studio/wiki/Beam-Studio-Easy-API',
                design_market: 'https://dmkt.io',
                shortcuts: 'https://support.flux3dp.com/hc/en-us/articles/10003978157455',
            },
            view: 'Zobrazit',
            zoom_in: 'Přiblížit',
            zoom_out: 'Oddálit',
            fit_to_window: 'Přizpůsobit na velikost okna',
            zoom_with_window: 'Automaticky přizpůsobit na velikost okna',
            borderless_mode: 'Mód bez okrajů',
            show_grids: 'Zobrazit mřížky',
            show_rulers: 'Zobrazit pravítka',
            show_layer_color: 'Použít barvu vrstvy',
            anti_aliasing: 'Vyhlazení',
            disassemble_use: 'Rozpojit',
            about: 'O Beam Studio',
            switch_to_beta: 'Přepnout na beta verzi',
            switch_to_latest: 'Přepnout na stabilní verzi',
            reload_app: 'Znovu načíst aplikaci',
            recent: 'Otevřít poslední',
            update: 'Kontrola aktualizací',
            dev_tool: 'Debug nástroj',
            camera_calibration_data: 'Kalibrační data fotoaparátu',
            upload_data: 'Nahrát data',
            download_data: 'Stáhnout data',
            tools: {
                title: 'Nástroje',
                material_test_generator: 'Generátor testování materiálu',
                qr_code_generator: 'Generátor QR kódu',
                boxgen: 'Generátor krabic',
            },
        },
        select_machine: 'Vybrat přístroj',
    },
    support: {
        no_webgl: 'WebGL není podporován. Použijte prosím jiná zařízení.',
        no_vcredist: 'Nainstalujte prosím Visual C++ Redistributable 2015<br/> Ke stažení na flux3dp.com',
    },
    generic_error: {
        UNKNOWN_ERROR: '[UE] Nastala neznámá chyba. Restartujte prosím Beam Studio a přístroj.',
        OPERATION_ERROR: '[OE] Nastal stavový konflikt, opakujte prosím akci.',
        SUBSYSTEM_ERROR: '[SE] Chyba při vykonávání úlohy strojového firmwaru. Restartujte prosím stroj.',
        UNKNOWN_COMMAND: '[UC] Aktualizujte prosím firmware zařízení.',
    },
    device_selection: {
        no_beambox: '#801 Nemůžeme najít v síti váš přístroj.\n Podívejte se prosím do tohoto <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/360001683556">návodu</a> pro řešení problému s připojením.',
        no_device_web: '#801  Zkontrolujte prosím stav vašeho přístroje nebo klikněte v dolní části na Nastavení přístroje k řádnému nastavení přístroje.',
        select_usb_device: 'Vyberte zařízení USB',
    },
    update: {
        update: 'Aktualizace',
        release_note: 'Poznámka k verzi:',
        firmware: {
            caption: 'Aktualizace firmwaru k tomuto přístroji je k dispozici',
            message_pattern_1: '"%s" je nyní připraven k aktualizaci firmwaru.',
            message_pattern_2: '%s firmware v%s je nyní k dispozici - Máte v%s.',
            latest_firmware: {
                caption: 'Aktualizace firmwaru přístroje.',
                message: 'Máte nejaktuálnější firmware přístroje.',
                still_update: 'AKTUALIZACE',
                cant_get_latest: 'Nepodařilo se získat informace k nejaktuálnějšímu firmwaru.',
            },
            confirm: 'UPLOAD',
            upload_file: 'Upload firmwaru (*.bin / *.fxfw)',
            update_success: 'Aktualizace firmwaru úspěšně nahrána',
            update_fail: '#822 Selhání při aktualizaci',
            too_old_for_web: 'Současná verze firmwaru vašeho přístroje je v%s.\nPokud chcete použít online verzi Beam Studia, aktualizujte prosím firmware přístroje na nejaktuálnější verzi.',
            force_update_message: '#814 Aktualizujte prosím svůj přístroj na nejaktuálnější verzi firmwaru.',
            firmware_too_old_update_by_sdcard: 'Verze firmwaru je příliš stará. Aktualizujte prosím firmware SD kartou.',
        },
        software: {
            checking: 'Kontroluji aktualizace',
            switch_version: 'Změnit verzi',
            check_update: 'Kontrola aktualizace',
            caption: 'Aktualizace softwaru pro Beam Studio je k dispozici.',
            downloading: 'V pozadí se stahují aktualizace, kliknutím na tlačítko "OK" můžete pokračovat ve své práci.',
            install_or_not: 'je připraven k aktualizaci. Chcete nyní restartovat a aktualizovat?',
            switch_or_not: 'je připraven k výměně. Chcete nyní restartovat a vyměnit?',
            available_update: 'Beam Studio v%s je nyní k dispozici. Máte v%s. Chcete stáhnout aktualizaci?',
            available_switch: 'Beam Studio v%s je nyní k dispozici. Máte v%s. Chcete přejít na tuto verzi?',
            not_found: 'Používáte nejaktuálnější verzi Beam Studio.',
            no_response: 'Nepodařilo se připojit k serveru, zkontrolujte prosím nastavení sítě.',
            switch_version_not_found: 'Vyměnitelná verze nenalezena.',
            yes: 'Ano',
            no: 'Ne',
            update_for_ador: 'Současná verze softwaru %s není kompatibilní, stáhněte prosím nejaktuálnější verzi Beam Studia pro Ador.',
        },
        updating: 'Aktualizuji...',
        skip: 'Přeskočit tuto verzi',
        preparing: 'Připravuji...',
        later: 'POZDĚJI',
        download: 'ONLINE AKTUALIZACE',
        cannot_reach_internet: '#823 Server není dosažitelný<br/> Zkontrolujte prosím internetové připojení',
        install: 'INSTALOVAT',
        upload: 'NAHRÁT',
    },
    topmenu: {
        version: 'Verze',
        credit: 'Beam Studio bylo vytvořeno díky open source projektu <a target="_blank" href="https://github.com/flux3dp/beam-studio">Beam Studio</a> a dalšímu <a target="_blank" href="https://flux3dp.com/credits/">open source softwaru</a>.',
        ok: 'OK',
        file: {
            label: 'Soubor',
            import: 'Importovat',
            save_fcode: 'Exportovat FLUX úlohu',
            save_scene: 'Uložit scénu',
            save_svg: 'Exportovat SVG',
            save_png: 'Exportovat PNG',
            save_jpg: 'Exportovat JPG',
            converting: 'Konvertovat na Obrázek...',
            all_files: 'Všechny soubory',
            svg_files: 'SVG',
            png_files: 'PNG',
            jpg_files: 'JPG',
            scene_files: 'Plocha Beam Studio',
            fcode_files: 'FLUX kód',
            clear_recent: 'Vyčistit nedávno otevřené',
            path_not_exit: 'Zdá se, že tato cesta už na disku neexistuje.',
        },
        device: {
            download_log_canceled: 'Stažení záznamu zrušeno.',
            download_log_error: 'Nastala neznámá chyba, zkuste to prosím znovu později.',
            log: {
                usblist: 'Seznam USB',
            },
            network_test: 'Otestovat síť',
        },
    },
    initialize: {
        // generic strings
        next: 'Další',
        start: 'Spustit',
        skip: 'Přeskočit',
        cancel: 'Zrušit',
        confirm: 'Potvrdit',
        connect: 'Připojit',
        back: 'Zpět',
        retry: 'Opakovat',
        no_machine: 'Nemám nyní přístroj.',
        // specific caption/content
        select_language: 'Vyberte jazyk',
        select_machine_type: 'Vyberte typ vašeho přístroje',
        select_connection_type: 'Jak se přejete připojit?',
        connection_types: {
            wifi: 'Wi-Fi',
            wired: 'Drátové připojení',
            ether2ether: 'Přímé připojení',
            usb: 'USB připojení',
        },
        connect_wifi: {
            title: 'Připojuji se k Wi-Fi',
            tutorial1: '1.Na dotykovém panelu klikněte na "Síť" > "Připojit k Wi-Fi".',
            tutorial1_ador: '1. Na dotykovém panelu klikněte na "PŘÍSTROJ" > "Síť" > "Připojit k Wi-Fi".',
            tutorial2: '2. Zvolte svoji Wi-Fi a připojte se k ní.',
            what_if_1: 'Co když nevidím svou Wi-Fi?',
            what_if_1_content: '1. Šifrovací mód Wi-Fi by měl být WPA2 či bez hesla.\n2. Šifrovací mód může být nastaven v administrační části Wi-Fi routeru. Pokud router nepodporuje WPA2 a potřebujete pomoc se zvolením správného routeru, kontaktujte prosím podporu FLUX.',
            what_if_2: 'Co když nevidím žádnou Wi-Fi?',
            what_if_2_content: '1. Ujistěte se, že je Wi-Fi adaptér správně zapojený.\n2. Pokud se na dotykové obrazovce nenachází žádná MAC adresa bezdrátové sítě, kontaktujte prosím podporu FLUX.\n3. Kanál Wi-Fi by měl být 2,4 GHz (5Ghz není podporován).',
        },
        connect_wired: {
            title: 'Připojení pomocí síťového kabelu',
            tutorial1: '1. Připojte se svým routerem k přístroji.',
            tutorial2: '2. Stiskněte "Síť", abyste získali IP k drátové síti.',
            tutorial2_ador: '2. Stiskněte "PŘÍSTROJ" > "Síť", abyste získali IP drátové sítě.',
            what_if_1: 'Co když je IP prázdná?',
            what_if_1_content: '1. Ujistěte se, že je síťový kabel správně zapojený.\n2. Pokud není na dotykové obrazovce žádná MAC adresa drátové sítě, kontaktujte prosím podporu FLUX.',
            what_if_2: 'Co když IP začíná 169?',
            what_if_2_content: '1. Pokud IP adresa začíná 169.254, mělo by se jednat o problém s nastavením DHCP. Kontaktujte prosím svého poskytovatele internetu pro další pomoc.\n2. Pokud se váš počítač připojuje k internetu přímo s pomocí PPPoE, změňte prosím router, aby se připojoval pomocí PPPoE a aktivujte u routeru funkci DHCP.',
        },
        connect_ethernet: {
            title: 'Přímé připojení',
            tutorial1: '1. Propojte přístroj a váš počítač síťovým kabelem.',
            tutorial2_1: '2. Postupujte ',
            tutorial2_a_text: 'podle tohoto návodu',
            tutorial2_a_href_mac: 'https://support.flux3dp.com/hc/en-us/articles/360001517076',
            tutorial2_a_href_win: 'https://support.flux3dp.com/hc/en-us/articles/360001507715',
            tutorial2_2: ' abyste ze svého počítače udělali router.',
            tutorial3: '3. Klikněte na Další.',
        },
        connect_usb: {
            title: 'USB připojení',
            title_sub: ' (Pouze HEXA & Ador)',
            tutorial1: 'Propojte USB kabelem svůj přístroj a počítač.',
            tutorial2: 'Klikněte na “Další”.',
            turn_off_machine: 'Vypněte přístroj.',
            turn_on_machine: 'Zapněte přístroj.',
            wait_for_turning_on: 'Klikněte na “Další”, až bude zahajovací proces hotový a uvidíte hlavní obrazovku.',
        },
        connect_machine_ip: {
            check_usb: 'Zkontrolujte USB připojení.',
            enter_ip: 'Zadejte IP adresu svého přístroje.',
            check_ip: 'Kontroluji dostupnost IP.',
            invalid_ip: 'Špatné IP: ',
            invalid_format: 'Špatný formát',
            starts_with_169254: 'Začíná 169.254',
            unreachable: 'Nedosažitelné IP',
            check_connection: 'Kontroluji připojení k přístroji',
            check_firmware: 'Kontroluji verzi firmwaru',
            check_camera: 'Kontroluji dostupnost kamery',
            retry: 'Opakovat',
            succeeded_message: 'Úspěšně připojeno 🎉',
            finish_setting: 'Pojďme tvořit!',
        },
        connecting: 'Připojuji...',
        setting_completed: {
            start: 'Začít',
            great: 'Vítejte v Beam Studio',
            setup_later: 'Vždy můžete svůj přístroj nastavit v záhlaví > "Přístroje" > "Nastavení přístroje"',
            back: 'Zpět',
            ok: 'ZAČÍT TVOŘIT',
        },
    },
    error_pages: {
        screen_size: 'Je možné, že Beam Studio nebude na vašem zařízení správně fungovat. Pro optimální zážitek by měla mít obrazovka minimální šířku 1024 pixelů.',
    },
    menu: {
        mm: 'mm',
        inches: 'palců',
    },
    settings: {
        on: 'Zap',
        off: 'Vyp',
        low: 'Nízké',
        high: 'Normální',
        caption: 'Nastavení',
        tabs: {
            general: 'Obecné',
            device: 'Přístroj',
        },
        ip: 'IP adresa přístroje',
        guess_poke: 'Hledat IP adresu přístroje',
        auto_connect: 'Automaticky vybrat jediný přístroj',
        wrong_ip_format: 'Špatné formáty IP',
        default_machine: 'Výchozí přístroj',
        default_machine_button: 'Prázdný',
        remove_default_machine_button: 'Odstranit',
        confirm_remove_default: 'Výchozí přístroj bude odstraněn.',
        reset: 'Restartovat Beam Studio',
        reset_now: 'Restartovat Beam Studio',
        confirm_reset: 'Potvrdit restart Beam Studia?',
        language: 'Jazyk',
        notifications: 'Oznámení na ploše',
        check_updates: 'Automatická kontrola',
        autosave_enabled: 'Automatické ukládání',
        autosave_path: 'Automatické místo ukládání',
        autosave_interval: 'Ukládat každé',
        autosave_number: 'Počet automaticky uložených pozic',
        autosave_path_not_correct: 'Zvolená cesta nenalezena.',
        preview_movement_speed: 'Rychlost pohybu v náhledu',
        slow: 'Pomalý',
        medium: 'Střední',
        fast: 'Rychlý',
        default_units: 'Výchozí jednotky',
        default_font_family: 'Výchozí font',
        default_font_style: 'Výchozí styl fontu',
        fast_gradient: 'Optimalizace rychlosti',
        engraving_direction: 'Směr',
        top_down: 'Seshora dolů',
        bottom_up: 'Zezdola nahoru',
        vector_speed_constraint: 'Omezení rychlosti (20 mm/s)',
        loop_compensation: 'Kompenzace smyčky',
        blade_radius: 'Poloměr ostří',
        blade_precut_switch: 'Předřez ostří',
        blade_precut_position: 'Pozice předřezu',
        default_beambox_model: 'Výchozí nastavení dokumentu',
        guides_origin: 'Počátky vodítek',
        guides: 'Vodítka',
        image_downsampling: 'Kvalita bitmapového náhledu',
        anti_aliasing: 'Vyhlazování',
        continuous_drawing: 'Souvislé kreslení',
        trace_output: 'Výstup obkreslování obrázku',
        single_object: 'Jeden objekt',
        grouped_objects: 'Skupina objektů',
        simplify_clipper_path: 'Optimalizovat vypočítanou cestu',
        enable_low_speed: 'Povolit pomalý pohyb',
        enable_custom_backlash: 'Povolit vlastní kompenzaci odporu',
        auto_switch_tab: 'Automatický přepínač panelu vrstev a objektů',
        custom_preview_height: 'Vlastní výška náhledu',
        mask: 'Výstřižek pracovní oblasti',
        text_path_calc_optimization: 'Optimalizace kalkulace cesty',
        font_substitute: 'Nahradit nepodporované znaky',
        font_convert: 'Konvertor textu na cestu',
        default_borderless_mode: 'Výchozí otevření spodní části',
        default_enable_autofocus_module: 'Výchozí autofokus',
        default_enable_diode_module: 'Výchozí diodový laser',
        diode_offset: 'Ofset diodového laseru',
        autofocus_offset: 'Ofset autofokusu',
        diode_one_way_engraving: 'Gravírování diodového laseru jedním směrem',
        diode_two_way_warning: 'Vysílání dvousměrového světla je rychlejší a pravděpodobně vygeneruje jisté nepřesnosti v pozici laserového gravírování. Doporučuje se nejprve otestovat.',
        share_with_flux: 'Analýza Share Beam Studio',
        none: 'Žádné',
        close: 'Zavřít',
        enabled: 'Povoleno',
        disabled: 'Zakázáno',
        cancel: 'Storno',
        done: 'Použít',
        module_offset_10w: 'Ofset 10W diodového laseru',
        module_offset_20w: 'Ofset 20W diodového laseru',
        module_offset_printer: 'Ofset tiskárny',
        module_offset_2w_ir: 'Ofset 2W infračerveného laseru',
        printer_advanced_mode: 'Pokročilý mód tiskárny',
        default_laser_module: 'Modul výchozího laseru',
        low_laser_for_preview: 'Laser pro rámování',
        groups: {
            general: 'Obecné',
            update: 'Softwarové aktualizace',
            connection: 'Připojení',
            autosave: 'Automatické ukládání',
            camera: 'Kamera',
            editor: 'Editor',
            engraving: 'Rastrování (Skenování)',
            path: 'Vektor (Obrys)',
            mask: 'Výstřižek pracovní oblasti',
            text_to_path: 'Text',
            modules: 'Doplněk',
            ador_modules: 'Moduly Ador',
            privacy: 'Soukromí',
        },
        notification_on: 'Zap',
        notification_off: 'Vyp',
        update_latest: 'Poslední',
        update_beta: 'Beta',
        help_center_urls: {
            connection: 'https://support.flux3dp.com/hc/en-us/sections/360000302135',
            image_downsampling: 'https://support.flux3dp.com/hc/en-us/articles/360004494995',
            anti_aliasing: 'https://support.flux3dp.com/hc/en-us/articles/360004408956',
            continuous_drawing: 'https://support.flux3dp.com/hc/en-us/articles/360004406496',
            simplify_clipper_path: 'https://support.flux3dp.com/hc/en-us/articles/360004407276',
            fast_gradient: 'https://support.flux3dp.com/hc/en-us/articles/360004496235',
            reverse_engraving: 'https://support.flux3dp.com/hc/en-us/articles/',
            vector_speed_constraint: 'https://support.flux3dp.com/hc/en-us/articles/360004496495',
            loop_compensation: 'https://support.flux3dp.com/hc/en-us/articles/360004408856',
            mask: 'https://support.flux3dp.com/hc/en-us/articles/360004408876',
            font_substitute: 'https://support.flux3dp.com/hc/en-us/articles/360004496575',
            font_convert: 'https://support.flux3dp.com/hc/en-us/articles/9132766761743',
            default_borderless_mode: 'https://support.flux3dp.com/hc/zh-tw/articles/360001104076',
            default_enable_autofocus_module: 'https://support.flux3dp.com/hc/en-us/articles/360001574536',
            default_enable_diode_module: 'https://support.flux3dp.com/hc/en-us/articles/360001568035',
        },
    },
    beambox: {
        tag: {
            g: 'Skupina',
            use: 'Import SVG',
            image: 'Obrázek',
            text: 'Text',
        },
        context_menu: {
            cut: 'Vyjmout',
            copy: 'Kopírovat',
            paste: 'Vložit',
            paste_in_place: 'Vložit na místo',
            duplicate: 'Duplikovat',
            delete: 'Smazat',
            group: 'Sloučit do skupiny',
            ungroup: 'Rozdělit skupinu',
            move_front: 'Přenést do popředí',
            move_up: 'Přenést blíž',
            move_down: 'Přenést do pozadí',
            move_back: 'Přenést dál',
        },
        popup: {
            select_import_method: 'Vyberte styl vrstvy:',
            select_import_module: 'Vyberte modul:',
            touchpad: 'TouchPad',
            mouse: 'Myš',
            layer_by_layer: 'Vrstva',
            layer_by_color: 'Barva',
            nolayer: 'Jedna vrstva',
            loading_image: 'Nahrávám obrázek, prosím vyčkejte...',
            no_support_text: 'Beam Studio v současnosti nepodporuje textové štítky. Převeďte prosím před importem text na cestu.',
            speed_too_high_lower_the_quality: 'Využívání příliš vysoké rychlosti může u tohoto rozlišení vyústit v nízkou kvalitu gravírovaného stínování.',
            both_power_and_speed_too_high: 'Využívání laseru nízké intenzity prodlouží životnost laserové trubice.\nA příliš vysoká rychlost může u tohoto rozlišení vyústit v nízkou kvalitu gravírovaného stínování.',
            too_fast_for_path: 'Využívání příliš vysoké rychlosti u vrstev obsahující objekty cest může vyústit v nízkou přesnost při řezání.\nNedoporučujeme při řezání využívat rychlost vyšší než 20 mm/s.',
            too_fast_for_path_and_constrain: 'Následující vrstvy: %s\nobsahují vektorové objekty cest a jejich rychlost převyšuje 20 mm/s.\nRychlost řezání vektorových objektů cest bude omezena na 20 mm/s.\nTento limit můžete odstranit v Nastavení.',
            should_update_firmware_to_continue: '#814 Váš firmware nevyužívá tuto verzi Beam Studia. Pro pokračování prosím aktualizujte firmware. (Menu > Přístroj > [Váš přístroj] > Aktualizovat firmware)',
            recommend_downgrade_software: 'Rozpoznali jsme starší verzi firmwaru. Aktivně adresujeme problémy s kompatibilitou, ale prozatím doporučujeme vrátit se k <a target="_blank" href="https://beamstudio.s3.ap-northeast-1.amazonaws.com/mac/Beam+Studio+1.9.5.dmg">Beam Studio 1.9.5</a>.',
            recommend_upgrade_firmware: 'Rozpoznali jsme starší verzi firmwaru. Aktivně adresujeme problémy s kompatibilitou, ale prozatím doporučujeme nainstalovat nejaktuálnější firmware.',
            still_continue: 'Pokračovat',
            more_than_two_object: 'Příliš mnoho objektů. Podpora pouze pro 2 objekty.',
            not_support_object_type: 'Nepodporovaný typ objektu.',
            select_first: 'Nejprve vyberte objekt.',
            select_at_least_two: 'Pro pokračování vyberte dva objekty',
            import_file_contain_invalid_path: '#808 Importovaný SVG soubor obsahuje nesprávnou cestu k obrázku. Ujistěte se prosím, že všechny soubory obrázků existují nebo do souboru vložte obrázek',
            import_file_error_ask_for_upload: 'Nepodařilo se importovat SVG soubor. Jste ochotní soubor poskytnout vývojářskému týmu pro nahlášení chyby?',
            upload_file_too_large: '#819 Soubor je pro nahrání příliš velký.',
            successfully_uploaded: 'Soubor byl úspěšně nahrán.',
            upload_failed: '#819 Nepodařilo se nahrát soubor.',
            or_turn_off_borderless_mode: ' Nebo vypněte mód otevřené spodní části.',
            svg_1_1_waring: 'Verze tohoto SVG souboru je 1.1. Může dojít k problémům s kompatibilitou.',
            svg_image_path_waring: 'Tento SVG soubor obsahuje <image> načtený z cesty souboru. Můžou nastat chyby při načítání.\nAbyste se tomuto riziku vyhnuli, používejte prosím při exportování SVG vložený obrázek.',
            dxf_version_waring: 'Verze tohoto DFX souboru není 2013, může dojít k problémům s kompatibilitou.',
            dont_show_again: 'Příště neukazovat.',
            convert_to_path_fail: 'Nepodařilo se převést na cestu.',
            save_unsave_changed: 'Přejete si uložit neuložené změny?',
            dxf_bounding_box_size_over: 'Velikost kresby je mimo pracovní oblast. Přesuňte prosím svou kresbu blíž k vodítku ve vašem CAD softwaru, nebo se ujistěte, že je jednotka správně nastavena.',
            progress: {
                uploading: 'Nahrávám',
                calculating: 'Vypočítávám',
            },
            backend_connect_failed_ask_to_upload: '#802 Objevují se chyby při snaze připojit se k backendu. Přejete si nahrát záznamy z hlášení o chybě?',
            backend_error_hint: 'Funkce nemusí správně pracovat kvůli chybě v backendu.',
            pdf2svg: {
                error_when_converting_pdf: '#824 Chyba při převádění PDF na SVG:',
                error_pdf2svg_not_found: '#825 Chyba: Příkaz pdf2svg nenalezen. Nainstalujte prosím pdf2svg se svým správcem balíčků (např.: "yum install pdf2svg" či "apt_get install pdf2svg").',
            },
            ungroup_use: 'Toto odstraní ze skupiny importované DXF nebo SVG. Protože může soubor obsahovat velký počet prvků, může rozdělování skupiny trvat. Opravdu si přejete pokračovat?',
            vectorize_shading_image: 'Obrázky s přechodem trvají delší dobu obkreslit a může dojít k zvýšenému hluku. Vypněte prosím obrázky s přechodem před zahájením.',
            change_workarea_before_preview: 'Pracovní oblast %s nesedí k momentálně nastavené pracovní oblasti. Chcete přepnout na momentální pracovní oblast?',
            bug_report: 'Nahlášení chyby',
            sentry: {
                title: 'Pojďme společně vylepšit Beam Studio',
                message: 'Souhlasíte s tím, že pokud nastane chyba, budou se vývojářům automaticky nahrávat relevantní informace?',
            },
            questionnaire: {
                caption: 'Pomozte nám vyplnit dotazník',
                message: 'Pomozte nám vyplnit dotazník, abychom mohli vylepšit Beam Studio',
                unable_to_get_url: 'Nepodařilo se získat odkaz k internetovému dotazníku. Potvrďte prosím své internetové připojení.',
                no_questionnaire_available: 'V současnosti není k dispozici žádný dotazník.',
            },
            facebook_group_invitation: {
                title: 'Přidejte se k oficiální uživatelské skupině FLUX',
                message: 'Přidejte se k naší oficiální facebookové skupině, kde se propojíte s dalšími uživateli FLUX a kde můžete diskutovat o FLUX laserech, sdílet své laserové výtvory a být v obraze ohledně našich produktů. Těšíme se na vás!',
                join_now: 'Jdu do toho',
                later: 'Možná později',
                already_joined: 'Už jsem členem',
                dont_show_again: 'Znovu neukazovat',
            },
            ai_credit: {
                relogin_to_use: 'K použití této funkce se prosím znovu přihlaste.',
                insufficient_credit: 'Došel vám kredit.',
                insufficient_credit_msg: 'Nemůžete použít %s. Přihlaste se do klientské zóny a zakupte AI kredit.',
                buy_link: 'https://member.flux3dp.com/en-US/credit',
                go: 'Spustit',
            },
            text_to_path: {
                caption: 'Konvertor textu na cestu 2.0',
                message: 'Beam Studio vám představuje nový konvertor textu na cestu (Converter 2.0), který přináší ještě spolehlivější výsledky!Přejete si na něho přepnout?\nSvoji volbu můžete později změnit ve vlastnostech v sekci Konvertor textu na cestu.',
            },
            auto_switch_tab: {
                title: 'Automatické přepínání panelu vrstev a objektů',
                message: 'Do Předvoleb byla přidána nová možnost pro automatické přepínání mezi panelem Vrstvy a panelem Objekty. Tato možnost je ve výchozím nastavení vypnutá. Chcete nyní povolit automatické přepínání?<br/>Toto nastavení můžete kdykoli změnit v Předvolbách.',
            },
        },
        zoom_block: {
            fit_to_window: 'Přizpůsobit oknu',
        },
        time_est_button: {
            calculate: 'Odhadnout délku',
            estimate_time: 'Odhadovaná délka:',
        },
        left_panel: {
            unpreviewable_area: 'Slepá oblast',
            diode_blind_area: 'Doplněk hybridního laseru - slepá oblast',
            borderless_blind_area: 'Ne-gravírovací oblast',
            borderless_preview: 'Náhled kamery s otevřenou spodní částí',
            rectangle: 'Obdélník',
            ellipse: 'Elipsa',
            line: 'Čára',
            image: 'Obrázek',
            text: 'Text',
            label: {
                cursor: 'Vybrat',
                photo: 'Obrázek',
                text: 'Text',
                line: 'Čára',
                rect: 'Obdélník',
                oval: 'Ovál',
                polygon: 'Polygon',
                pen: 'Propiska',
                shapes: 'Prvky',
                array: 'Pole',
                preview: 'Náhled kamery',
                trace: 'Obkreslit obrázek',
                end_preview: 'Konec náhledu',
                clear_preview: 'Vyčistit náhled',
                choose_camera: 'Kamera',
                live_feed: 'Živé vysílání',
                adjust_height: 'Přizpůsobit výšku',
                qr_code: 'QR kód',
                boxgen: 'Boxgen',
                my_cloud: 'Můj cloud',
                pass_through: 'Projít skrz',
                curve_engraving: {
                    title: '3D křivka',
                    exit: 'Konec',
                    select_area: 'Vybrat oblast',
                    preview_3d_curve: 'Náhled 3D křivky',
                    clear_area: 'Vyčistit vybranou oblast',
                },
            },
        },
        right_panel: {
            tabs: {
                layers: 'Vrstvy',
                objects: 'Objekty',
                path_edit: 'Upravit cestu',
            },
            layer_panel: {
                layer1: 'Vrstva 1',
                layer_bitmap: 'Bitmapa',
                layer_engraving: 'Gravírování',
                layer_cutting: 'Řezání',
                current_layer: 'Současná vrstva',
                move_elems_to: 'Přesunout prvky do:',
                notification: {
                    dupeLayerName: 'Tak už se nějaká vrstva jmenuje!',
                    newName: 'NOVÉ JMÉNO',
                    enterUniqueLayerName: 'Zadejte prosím unikátní jméno pro vrstvu',
                    enterNewLayerName: 'Zadejte prosím nové jméno pro vrstvu',
                    layerHasThatName: 'Tak už se vrstva jmenuje',
                    QmoveElemsToLayer: 'Přesunout vybrané prvky do vrstvy %s?',
                    moveElemFromPrintingLayerTitle: 'Přesunout vybraný prvek do %s a převést ho na laserový prvek?',
                    moveElemFromPrintingLayerMsg: 'Pozor: Pokud tuto operaci dokončíte, nastavení barev vybraného prvku bude odstraněno a přiřazeno podle %s.',
                    moveElemToPrintingLayerTitle: 'Přesunout vybraný prvek do %s a převést ho na tiskový prvek?',
                    moveElemToPrintingLayerMsg: 'Pozor: Pokud tuto operaci dokončíte, nastavení vybraného prvku bude odstraněno a přiřazeno podle %s.',
                    splitColorTitle: 'Přejete si rozšířit vybranou vrstvu do vrstev CMYK?',
                    splitColorMsg: 'Pozor: Pokud budete s touto operací pokračovat, nebudete se moci vrátit k původním barevným vrstvám.',
                    mergeLaserLayerToPrintingLayerTitle: 'Chcete spojit tyto vrstvy do jedné Tiskové vrstvy?',
                    mergeLaserLayerToPrintingLayerMsg: 'Pozor: Pokud tuto operaci dokončíte, nastavení laserové vrstvy bude odstraněno a přiřazeno podle současné vrstvy.',
                    mergePrintingLayerToLaserLayerTitle: 'Chcete tyto vrstvy spojit do jedné Laserové vrstvy?',
                    mergePrintingLayerToLaserLayerMsg: 'Pozor: Pokud tuto operaci dokončíte, nastavení barev tiskové vrstvy bude odstraněno a přiřazeno podle současné vrstvy.',
                },
                layers: {
                    layer: 'Vrstva',
                    layers: 'Vrstvy',
                    del: 'Smazat vrstvu',
                    move_down: 'Přesunout vrstvu dolů',
                    new: 'Nová vrstva',
                    rename: 'Přejmenovat vrstvu',
                    move_up: 'Přesunout vrstvu nahoru',
                    dupe: 'Duplikovat vrstvu',
                    lock: 'Uzamknout vrstvu',
                    unlock: 'Odemknout',
                    merge_down: 'Sloučit dolů',
                    merge_all: 'Sloučit vše',
                    merge_selected: 'Sloučit vybrané vrstvy',
                    move_elems_to: 'Přesunout prvky do:',
                    move_selected: 'Přesunout vybrané prvky do jiné vrstvy',
                    switchToFullColor: 'Přepnout na barevnou vrstvu',
                    switchToSingleColor: 'Přepnout na jednobarevnou vrstvu',
                    splitFullColor: 'Rozšířit vrstvu',
                    fullColor: 'Barevné',
                },
            },
            laser_panel: {
                preset_setting: 'Nastavení parametrů (%s)',
                multi_layer: 'Několik vrstev',
                parameters: 'Parametry',
                strength: 'Intenzita',
                pwm_advanced_setting: 'Nastavení Depth Mode Power (výkonu režimu hloubky)',
                pwm_advanced_desc: 'Nastavte minimální výkon pro režim hloubky.',
                pwm_advanced_hint: 'Toto nastavení platí konkrétně pro obrázek s barevným přechodem při používání Depth Mode (režimu hloubky).',
                low_power_warning: 'Laser nízké intenzity (pod 10 %) nemusí vyslat laserové světlo.',
                speed: 'Rychlost',
                speed_contrain_warning: 'Rychlost řezání objektů vektorových cest bude omezeno na 20 mm/s (0,79 in/s). Toto omezení můžete odstranit v Nastavení.',
                low_speed_warning: 'Nízká rychlost může způsobit pálení materiálu.',
                repeat: 'Přeskočit počítání',
                advanced: 'Pokročilé',
                lower_focus: 'Nižší zaostření',
                by: 'Podle',
                stepwise_focusing: 'Postupné ostření',
                single_color: 'Jednobarevné',
                lower_focus_desc: 'Snížení výšky zaostření o určitou vzdálenost po zaostření pro zlepšení řezného výkonu.',
                stepwise_focusing_desc: 'Postupné snižování zaostřovací vzdálenosti na základě výšky objektu během každého počítání průchodů.',
                single_color_desc: 'Použitelné pouze pro plně barevnou vrstvu a nelze je použít s rozšířenou jednobarevnou vrstvou.',
                focus_adjustment: 'Přizpůsobení fokusu',
                height: 'Výška objektu',
                z_step: 'Krok Z',
                diode: 'Diodový laser',
                backlash: 'Odpor',
                ink_saturation: 'Saturace',
                print_multipass: 'Multi-pass',
                white_ink: 'Bílý inkoust',
                white_ink_settings: 'Nastavení bílého inkoustu',
                color_adjustment: 'Přizpůsobení kanálu',
                color_adjustment_short: 'Kanál',
                halftone: 'Půltón',
                halftone_link: 'https://support.flux3dp.com/hc/en-us/articles/9402670389647',
                color_strength: 'Síla',
                times: 'krát',
                cut: 'Řezat',
                engrave: 'Gravírovat',
                more: 'Spravovat',
                apply: 'Použít',
                cancel: 'Storno',
                save: 'Uložit',
                custom_preset: 'Přizpůsobit',
                various_preset: 'Různé předvolby',
                module: 'Modul',
                slider: {
                    regular: 'Běžné',
                    low: 'Nízké',
                    very_low: 'Min',
                    high: 'Vysoké',
                    very_high: 'Max',
                    slow: 'Pomalé',
                    very_slow: 'Nejpomalejší',
                    fast: 'Rychlé',
                    very_fast: 'Nejrychlejší',
                },
                dropdown: {
                    parameters: 'Předvolby',
                    save: 'Přidat současné parametry',
                    mm: {
                        wood_3mm_cutting: 'Dřevo - 3mm řez',
                        wood_5mm_cutting: 'Dřevo - 5mm řez',
                        wood_7mm_cutting: 'Dřevo - 7mm řez',
                        wood_8mm_cutting: 'Dřevo - 8mm řez',
                        wood_10mm_cutting: 'Dřevo - 10mm řez',
                        wood_engraving: 'Dřevo - gravírování',
                        acrylic_3mm_cutting: 'Akryl - 3mm řez',
                        acrylic_5mm_cutting: 'Akryl - 5mm řez',
                        acrylic_8mm_cutting: 'Akryl - 8mm řez',
                        acrylic_10mm_cutting: 'Akryl - 10mm řez',
                        acrylic_engraving: 'Akryl - gravírování',
                        mdf_3mm_cutting: 'Novozélandská MDF - 3mm řez',
                        mdf_5mm_cutting: 'Novozélandská MDF - 5mm řez',
                        mdf_engraving: 'Novozélandská MDF - gravírování',
                        leather_3mm_cutting: 'Kůže - 3mm řez',
                        leather_5mm_cutting: 'Kůže - 5mm řez',
                        leather_engraving: 'Kůže - gravírování',
                        denim_1mm_cutting: 'Džínovina - 1mm řez',
                        fabric_3mm_cutting: 'Textilie - 3mm řez',
                        fabric_5mm_cutting: 'Textilie - 5mm řez',
                        fabric_engraving: 'Textilie - gravírování',
                        rubber_bw_engraving: 'Guma - gravírování',
                        glass_bw_engraving: 'Sklo - gravírování',
                        metal_bw_engraving: 'Kov - gravírování',
                        steel_engraving_spray_engraving: 'Ocel - gravírování',
                        stainless_steel_bw_engraving_diode: 'Nerezová ocel - gravírování (diodový laser)',
                        gold_engraving: 'Zlato - gravírování',
                        brass_engraving: 'Mosaz - gravírování',
                        ti_engraving: 'Titan - gravírování',
                        stainless_steel_engraving: 'Nerezová ocel - gravírování',
                        aluminum_engraving: 'Hliník - gravírování',
                        black_acrylic_3mm_cutting: 'Černý akryl - 3mm řez',
                        black_acrylic_5mm_cutting: 'Černý akryl - 5mm řez',
                        black_acrylic_engraving: 'Černý akryl - gravírování',
                        abs_engraving: 'ABS - gravírování',
                        silver_engraving: 'Stříbro - gravírování',
                        iron_engraving: 'Železo - gravírování',
                        fabric_printing: 'Textilie - tisk',
                        canvas_printing: 'Plátno - tisk',
                        cardstock_printing: 'Karton - tisk',
                        wood_printing: 'Dřevo - tisk',
                        bamboo_printing: 'Bambus - tisk',
                        cork_printing: 'Korek - tisk',
                        flat_stone_printing: 'Plochý kámen - tisk',
                        acrylic_printing: 'Akryl - tisk',
                        pc_printing: 'Polykarbonát - tisk',
                        stainless_steel_printing: 'Nerezová ocel - tisk',
                        gloss_leather_printing: 'Lesklá kůže - tisk',
                        glass_printing: 'Sklo - tisk',
                    },
                    inches: {
                        wood_3mm_cutting: 'Dřevo - 3mm řez',
                        wood_5mm_cutting: 'Dřevo - 5mm řez',
                        wood_7mm_cutting: 'Dřevo - 7mm řez',
                        wood_8mm_cutting: 'Dřevo - 8mm řez',
                        wood_10mm_cutting: 'Dřevo - 10mm řez',
                        wood_engraving: 'Dřevo - gravírování',
                        acrylic_3mm_cutting: 'Akryl - 3mm řez',
                        acrylic_5mm_cutting: 'Akryl - 5mm řez',
                        acrylic_8mm_cutting: 'Akryl - 8mm řez',
                        acrylic_10mm_cutting: 'Akryl - 10mm řez',
                        acrylic_engraving: 'Akryl - gravírování',
                        mdf_3mm_cutting: 'Novozélandský MDF - 3mm řez',
                        mdf_5mm_cutting: 'Novozélandský MDF - 5mm řez',
                        mdf_engraving: 'Novozélandský MDF - gravírování',
                        leather_3mm_cutting: 'Kůže - 3mm řez',
                        leather_5mm_cutting: 'Kůže - 5mm řez',
                        leather_engraving: 'Kůže - gravírování',
                        denim_1mm_cutting: 'Džínovina - 1mm řez',
                        fabric_3mm_cutting: 'Textilie - 3mm řez',
                        fabric_5mm_cutting: 'Textilie - 5mm řez',
                        fabric_engraving: 'Textilie - gravírování',
                        rubber_bw_engraving: 'Guma - gravírování',
                        glass_bw_engraving: 'Sklo - gravírování',
                        metal_bw_engraving: 'Kov - gravírování',
                        steel_engraving_spray_engraving: 'Ocel - gravírování',
                        stainless_steel_bw_engraving_diode: 'Nerezová ocel - gravírování (diodový laser)',
                        gold_engraving: 'Zlato - gravírování',
                        brass_engraving: 'Mosaz - gravírování',
                        ti_engraving: 'Titan - gravírování',
                        stainless_steel_engraving: 'Nerezová ocel - gravírování',
                        aluminum_engraving: 'Hliník - gravírování',
                        black_acrylic_3mm_cutting: 'Černý akryl - 3mm řez',
                        black_acrylic_5mm_cutting: 'Černý akryl - 5mm řez',
                        black_acrylic_engraving: 'Černý akryl - gravírování',
                        abs_engraving: 'ABS - gravírování',
                        silver_engraving: 'Stříbro - gravírování',
                        iron_engraving: 'Železo - gravírování',
                        fabric_printing: 'Textilie - tisk',
                        canvas_printing: 'Plátno - tisk',
                        cardstock_printing: 'Karton - tisk',
                        wood_printing: 'Dřevo - tisk',
                        bamboo_printing: 'Bambus - tisk',
                        cork_printing: 'Korek - tisk',
                        flat_stone_printing: 'Plochý kámen - tisk',
                        acrylic_printing: 'Akryl - tisk',
                        pc_printing: 'Polykarbonát - tisk',
                        stainless_steel_printing: 'Nerezová ocel - tisk',
                        gloss_leather_printing: 'Lesklá kůže - tisk',
                        glass_printing: 'Sklo - tisk',
                    },
                },
                laser_speed: {
                    text: 'Rychlost',
                    unit: 'mm/s',
                    fast: 'Rychlá',
                    slow: 'Pomalá',
                    min: 3,
                    max: 300,
                    step: 0.1,
                },
                power: {
                    text: 'Intenzita',
                    high: 'Vysoká',
                    low: 'Nízká',
                    min: 1,
                    max: 100,
                    step: 0.1,
                },
                ink_type: {
                    text: 'Typ inkoustu',
                    normal: 'Inkoust',
                    UV: 'UV inkoust',
                },
                para_in_use: 'Tento parametr je již používán.',
                do_not_adjust_default_para: 'Předvolby nemůžou být upravovány.',
                existing_name: 'Toto jméno je již používáno.',
                presets: 'Předvolba',
                preset_management: {
                    preset: 'Předvolba',
                    title: 'Spravovat parametry',
                    add_new: 'Přidat nový',
                    lower_focus_by: 'Snižte zaostření o',
                    save_and_exit: 'Uložit a ukončit',
                    delete: 'Smazat',
                    reset: 'Restartovat',
                    sure_to_reset: 'Odstraní se vaše osobní nastavení a resetují se všechny předvolby parametrů. Opravdu chcete pokračovat?',
                    show_all: 'Zobrazit vše',
                    laser: 'Laser',
                    print: 'Vytisknout',
                    export: 'Exportovat',
                    export_preset_title: 'Exportovat předvolby',
                    import: 'Importovat',
                    sure_to_import_presets: 'Přepíše vaše osobní nastavení a resetují se všechna tovární nastavení. Opravdu chcete pokračovat?',
                    new_preset_name: 'Název nové převolby',
                },
            },
            object_panel: {
                zoom: 'Zoom',
                group: 'Sloučit do skupiny',
                ungroup: 'Rozdělit skupinu',
                distribute: 'Rozšířit',
                hdist: 'Horizontálně rozšířit',
                vdist: 'Vertikálně rozšířit',
                align: 'Zarovnání',
                left_align: 'Zarovnání vlevo',
                center_align: 'Zarovnání na střed',
                right_align: 'Zarovnání vpravo',
                top_align: 'Zarovnání nahoru',
                middle_align: 'Zarovnání do středu',
                bottom_align: 'Zarovnání dolů',
                boolean: 'Boolean',
                union: 'Spojení',
                subtract: 'Ubrat',
                intersect: 'Protnout',
                difference: 'Rozdíl',
                flip: 'Převrátit',
                hflip: 'Převrátit horizontálně',
                vflip: 'Převrátit vertikálně',
                lock_aspect: 'Uzamknout aspekt',
                unlock_aspect: 'Odemknout aspekt',
                option_panel: {
                    fill: 'Výplň',
                    rounded_corner: 'Zaoblený roh',
                    sides: 'Strany',
                    font_family: 'Font',
                    font_style: 'Styl',
                    font_size: 'Velikost',
                    letter_spacing: 'Mezery mezi písmeny',
                    line_spacing: 'Řádkování',
                    vertical_text: 'Vertikální text',
                    start_offset: 'Ofset textu',
                    vertical_align: 'Zarovnání',
                    text_infill: 'Výplň textu',
                    path_infill: 'Výplň cesty',
                    shading: 'Barevný přechod',
                    pwm_engraving: 'Depth Mode (režim hloubky)',
                    pwm_engraving_link: 'https://support.flux3dp.com/hc/en-us/articles/10419884701327',
                    threshold: 'Hranice jasu',
                    threshold_short: 'Hranice',
                    stroke: 'Štětec',
                    stroke_color: 'Barva štětce',
                    stroke_width: 'Šířka štětce',
                    color: 'Barva',
                },
                actions_panel: {
                    replace_with: 'Nahradit s...',
                    replace_with_short: 'Nahradit',
                    trace: 'Stopa',
                    grading: 'Přechod',
                    brightness: 'Jas',
                    sharpen: 'Zaostřit',
                    crop: 'Oříznout',
                    bevel: 'Zkosit',
                    invert: 'Převrátit',
                    weld_text: 'Přivařit text',
                    convert_to_path: 'Převést na cestu',
                    fetching_web_font: 'Získávám online font...',
                    uploading_font_to_machine: 'Uploaduji font do přístroje...',
                    wait_for_parsing_font: 'Analyzuji font... Počkejte prosím chvíli.',
                    offset: 'Ofset',
                    array: 'Pole',
                    auto_fit: 'Automaticky přizpůsobit',
                    smart_nest: 'Smart Nest',
                    decompose_path: 'Rozložit',
                    disassemble_use: 'Rozebrat',
                    create_textpath: 'Vytvořit text na cestě',
                    create_textpath_short: 'Text na cestě',
                    detach_path: 'Rozložit text na cestě',
                    detach_path_short: 'Rozložit',
                    edit_path: 'Upravit cestu',
                    disassembling: 'Rozebírám...',
                    ungrouping: 'Rozděluji skupinu...',
                    simplify: 'Zjednodušit',
                    ai_bg_removal: 'Odstranění pozadí',
                    ai_bg_removal_short: 'Odstranění pozadí',
                    ai_bg_removal_reminder: 'Stisknutím tlačítka okamžitě zaplatíte 0,2 kreditu. Přejete si pokračovat?',
                    outline: 'Obrys',
                },
                path_edit_panel: {
                    node_type: 'TYP UZLU',
                    sharp: 'Ostrý',
                    round: 'Zaoblený',
                    connect: 'Připojit',
                    disconnect: 'Odpojit',
                    delete: 'Smazat',
                },
            },
        },
        bottom_right_panel: {
            convert_text_to_path_before_export: 'Převést text na cestu...',
            retreive_image_data: 'Získat data z obrázku...',
            export_file_error_ask_for_upload: 'Nepodařilo se exportovat úlohu. Jste ochotni při hlášení chyby poskytnout vývojářům pracovní oblast?',
        },
        image_trace_panel: {
            apply: 'Použít',
            back: 'Zpět',
            cancel: 'Storno',
            next: 'Další',
            brightness: 'Jas',
            contrast: 'Kontrast',
            threshold: 'Hranice',
            okay: 'OK',
            tuning: 'Parametry',
        },
        photo_edit_panel: {
            apply: 'Použít',
            back: 'Zpět',
            cancel: 'Storno',
            next: 'Další',
            sharpen: 'Zostřit',
            sharpness: 'Zaostření',
            radius: 'Poloměr',
            crop: 'Oříznout',
            aspect_ratio: 'Poměr stran',
            original: 'Originál',
            free: 'Volné',
            curve: 'Křivka',
            start: 'Začít',
            processing: 'Zpracovávám',
            invert: 'Převrátit barvy',
            okay: 'OK',
            compare: 'Porovnat',
            phote_edit: 'Úprava fotky',
            brightness_and_contrast: 'Jas / Kontrast',
            brightness: 'Jas',
            contrast: 'Kontrast',
            rotary_warped: 'Otočně Zkřivené',
            rotary_warped_link: 'https://support.flux3dp.com/hc/en-us/articles/10828006201103',
            diameter: 'Průměr',
            circumference: 'Obvod',
            warp: 'Zkřivit',
        },
        document_panel: {
            document_settings: 'Nastavení dokumentu',
            machine: 'Stroj',
            watts: 'Watty',
            workarea: 'Pracovní oblast',
            rotary_mode: 'Rotační',
            borderless_mode: 'Otevřít spodní část',
            engrave_dpi: 'Rozlišení',
            enable_diode: 'Diodový laser',
            enable_autofocus: 'Autofokus',
            extend_workarea: 'Rozšířit pracovní oblast',
            mirror: 'zrcadlo',
            pass_through: 'Projít skrz',
            pass_through_height_desc: 'Zadat délku objektu k rozšíření pracovní oblasti.',
            start_position: 'Počáteční pozice',
            start_from: 'Začít od',
            origin: 'Vodítka',
            current_position: 'Aktuální pozice',
            job_origin: 'Nastavování vodítek',
            add_on: 'Doplňky',
            low: 'Nízké',
            medium: 'Střední',
            high: 'Vysoké',
            ultra: 'Ultravysoké',
            enable: 'Povolit',
            disable: 'Zakázat',
            cancel: 'Storno',
            save: 'Uložit',
            notification: {
                changeFromPrintingWorkareaTitle: 'Chcete převést Tiskové vrstvy na Laserové vrstvy?',
            },
        },
        object_panels: {
            wait_for_parsing_font: 'Analyzuji font... Vyčkejte chvíli, prosím.',
            text_to_path: {
                font_substitute_pop: 'Váš text obsahuje znaky, které nepodporuje současný font. <br/>Přejete si použít jako náhradu <strong>%s</strong>?',
                check_thumbnail_warning: 'Některé texty byly změněny na jiné fonty při rozebírání textů na cesty a některé znaky se nemusely správně převést.\nPřed odesláním úlohy prosím znovu zkontrolujte náhledový obrázek.',
                error_when_parsing_text: 'Chyba při převádění textu na cestu.',
                retry: 'Znovu prosím opakujte nebo vyberte jiný font',
                use_current_font: 'Použít současný font',
            },
            lock_desc: 'Zachovat poměr šířky a výšky (SHIFT)',
        },
        tool_panels: {
            cancel: 'Storno',
            confirm: 'Potvrdit',
            grid_array: 'Vytvořit mřížkové pole',
            array_dimension: 'Dimenze pole',
            rows: 'Řádky',
            columns: 'Sloupce.',
            array_interval: 'Interval pole',
            dx: 'X',
            dy: 'Y',
            offset: 'Ofset',
            nest: 'Optimalizace uspořádání',
            _offset: {
                direction: 'Směr ofsetu',
                inward: 'Dovnitř',
                outward: 'Ven',
                dist: 'Vzdálenost ofsetu',
                corner_type: 'Roh',
                sharp: 'Ostrý',
                round: 'Zaoblený',
                fail_message: 'Nepodařil se ofset objektů.',
                not_support_message: 'Vybrané prvky obsahují nepodporované  SVG tagy\nObrázek, skupina, text a importovaný objekt.',
            },
            _nest: {
                start_nest: 'Uspořádat',
                stop_nest: 'Zastavit',
                end: 'Zavřít',
                spacing: 'Rozestupy',
                rotations: 'Možná rotace',
                no_element: 'Žádné prvky k uspořádání.',
            },
        },
        network_testing_panel: {
            network_testing: 'Testování sítě',
            local_ip: 'Místní IP adresa:',
            insert_ip: 'IP adresa cílového zařízení:',
            empty_ip: '#818 Zadejte prosím nejdříve IP adresu cílového zařízení.',
            start: 'Začít',
            end: 'Ukončit',
            testing: 'Testuji síť...',
            invalid_ip: '#818 Neplatná IP adresa',
            ip_startswith_169: '#843 IP adresa přístroje začíná 169.254',
            connection_quality: 'Kvalita připojení',
            average_response: 'Průměrná reakční doba',
            test_completed: 'Test dokončen',
            test_fail: 'Test selhal',
            cannot_connect_1: '#840 Nepodařilo se připojit k cílové IP.',
            cannot_connect_2: '#840 Nepodařilo se připojit k cílové IP. Ujistěte se prosím, že je cíl ve stejné síti.',
            network_unhealthy: '#841 Kvalita připojení <70 nebo průměrná reakční doba >100 ms',
            device_not_on_list: '#842 Přístroj není na seznamu, ale kvalita připojení je >70 a průměrná reakční doba je <100 ms.',
            hint_device_often_on_list: 'Přístroj se často nenalézá na seznamu?',
            link_device_often_on_list: 'https://support.flux3dp.com/hc/en-us/articles/360001841636',
            hint_connect_failed_when_sending_job: 'Nedaří se vám při odeslání úlohy připojit?',
            link_connect_failed_when_sending_job: 'https://support.flux3dp.com/hc/en-us/articles/360001841656',
            hint_connect_camera_timeout: 'Při zahajování náhledu kamery vyprší časový limit?',
            link_connect_camera_timeout: 'https://support.flux3dp.com/hc/en-us/articles/360001791895',
            cannot_get_local: 'Nepodařilo se připojit k místní IP adrese.',
            fail_to_start_network_test: '#817 Nepodařilo se zahájit testování sítě.',
            linux_permission_hint: 'Tato chyba se obvykle objevuje při nedostatku uživatelských práv.\nSpusťte prosím na terminálu "sudo beam-studio --no-sandbox", abyste získali práva a zahájili testování sítě.',
        },
        layer_color_config_panel: {
            layer_color_config: 'Nastavení barev vrstev',
            color: 'Barva',
            power: 'Intenzita',
            speed: 'Rychlost',
            repeat: 'Vykonate',
            add: 'Přidat',
            save: 'Uložit',
            cancel: 'Storno',
            default: 'Obnovení výchozího nastavení',
            add_config: 'Přidat barvu',
            in_use: 'Tato barva se již využívá.',
            no_input: 'Zadejte prosím platný hex kód barvy',
            sure_to_reset: 'Přijdete o všechny přizpůsobené parametry. Opravdu chcete restartovat do původního nastavení?',
            sure_to_delete: 'Určitě chcete smazat toto nastavení barev?',
        },
        rating_panel: {
            title: 'Užíváte si práci s Beam Studio?',
            description: 'Pokud se vám Beam Studio líbí, velice bychom ocenili, kdybyste nás ohodnotili.',
            dont_show_again: 'Příště již nezobrazovat.',
            thank_you: 'Děkujeme!',
        },
        svg_editor: {
            unnsupported_file_type: 'Tento typ souboru není přímo podporován. Převeďte prosím soubor na SVG či bitmapu.',
            unable_to_fetch_clipboard_img: 'Nepodařilo se získat obrázek ze schránky',
        },
        units: {
            walt: 'W',
            mm: 'mm',
        },
        path_preview: {
            play: 'Přehrát',
            pause: 'Pauza',
            stop: 'Zastavit',
            play_speed: 'Rychlost přehrávání',
            travel_path: 'Putovní cesta',
            invert: 'Převrátit',
            preview_info: 'Informace o náhledu',
            size: 'Velikost',
            estimated_time: 'Odhadovaná celková délka',
            cut_time: 'Snížit délku',
            rapid_time: 'Putovní délka',
            cut_distance: 'Vzdálenost řezu',
            rapid_distance: 'Vzdálenost cesty',
            current_position: 'Současná pozice',
            remark: '* * Všechny poskytované hodnoty jsou pouze odhadem.',
            start_here: 'Začít zde',
            end_preview: 'Ukončit náhled',
        },
        shapes_panel: {
            title: 'Prvky',
            basic: 'Základní',
            shape: 'Tvar',
            graphics: 'Grafika',
            arrow: 'Šíp',
            label: 'Štítek',
            decor: 'Výzdoba',
            circular: 'Kruhový',
            corner: 'Roh',
            line: 'Linka',
            photo: 'Fotka',
            ribbon: 'Stuha',
            speech: 'Řeč',
            text: 'Ohraničení textu',
            animals: 'Zvíře',
            birds: 'Pták',
            land: 'Země',
            sea: 'Moře',
            holidays: 'Prázdniny',
            celebration: 'Oslava',
            CNY: 'Čínský Nový rok',
            easter: 'Velikonoce',
            halloween: 'Halloween',
            valentines: 'Valentýn',
            Xmas: 'Vánoce',
            nature: 'Příroda',
            elements: 'Prvky',
            environment: 'Prostředí',
            plants: 'Rostlina',
            weather: 'Počasí',
        },
        announcement_panel: {
            title: 'Ohlášení',
            dont_show_again: 'Znovu již nezobrazovat',
        },
    },
    editor: {
        prespray_area: 'Primární oblast',
        opacity: 'Náhled průhlednosti',
        exposure: 'Náhled expozice',
    },
    flux_id_login: {
        connection_fail: '#847 Nepodařilo se připojit k členské službě FLUX.',
        login_success: 'Jste přihlášen.',
        login: 'Přihlásit',
        unlock_shape_library: 'Přihlaste se k odemčení databáze tvarů.',
        email: 'E-mail',
        password: 'Heslo',
        remember_me: 'Pamatuj si mě',
        forget_password: 'Zapomenuté heslo?',
        register: 'Vytvořte si svůj FLUX účet',
        offline: 'Práce off-line',
        work_offline: 'Práce off-line',
        incorrect: 'E-mailová adresa nebo heslo není správné.',
        not_verified: 'E-mailová adresa zatím nebyla ověřena.',
        new_to_flux: 'Začínáte s FLUX? Vytvořte si účet.',
        signup_url: 'https://id.flux3dp.com/user/login#up',
        lost_password_url: 'https://id.flux3dp.com/user/forgot-password',
        flux_plus: {
            explore_plans: 'Prozkoumat nabídky FLUX+',
            thank_you: 'Děkujeme, že jste naším cenným členem!',
            ai_credit_tooltip: 'Pro AI odstraňování pozadí',
            flux_credit_tooltip: 'Pro soubory z Design Market a AI odstraňování pozadí',
            goto_member_center: 'Jít do Členského centra',
            access_plus_feature_1: 'Přihlašujete se k',
            access_plus_feature_2: 'funkci.',
            access_plus_feature_note: 'Musíte být členem FLUX+, abyste měli přístup k této funkci.',
            access_monotype_feature: 'Nemáte doplněk Monotype Fonts.',
            access_monotype_feature_note: 'Musíte být členem FLUX+ nebo mít doplněk Monotype Fonts, abyste měli přístup k této funkci.',
            learn_more: 'Zjistit víc',
            get_addon: 'Získat doplněk',
            subscribe_now: 'Přihlásit se k odběru',
            website_url: 'https://flux3dp.com/subscription',
            member_center_url: 'https://member.flux3dp.com/en-US/subscription',
            features: {
                ai_bg_removal: 'Odstraňování pozadí',
                my_cloud: 'Neomezený úložný prostor v cloudu',
                boxgen: '3D Box generátor',
                dmkt: '1000+ designových souborů',
                monotype: '250+ premium fontů',
            },
        },
    },
    noun_project_panel: {
        login_first: 'Přihlaste se k odemčení databáze tvarů.',
        enjoy_shape_library: 'Užijte si databázi tvarů.',
        shapes: 'Tvary',
        elements: 'Prvky',
        recent: 'Nedávné',
        search: 'Hledat',
        clear: 'Vyčistit',
        export_svg_title: 'Nelze exportovat SVG',
        export_svg_warning: 'Tento projekt obsahuje objekty, které jsou chráněny právem duševního vlastnictví. Beam Studio proto během exportu automaticky vyjme tyto objekty. Stále si můžete uložit svůj projekt v Beam Studio Scene (ve formátu .beam), abyste si zachovali veškerá data. Stále si přejete exportovat?',
        learn_more: 'Zjistit víc',
    },
    change_logs: {
        change_log: 'Záznamy o změnách:',
        added: 'Přidáno:',
        fixed: 'Opraveno:',
        changed: 'Změněno:',
        see_older_version: 'Prohlédnout starší verze',
        help_center_url: 'https://support.flux3dp.com/hc/en-us/sections/360000421876',
    },
    select_device: {
        select_device: 'Vybrat přístroj',
        auth_failure: '#811 Chyba při ověřování',
        unable_to_connect: '#810 Nepodařilo se navázat stabilní připojení s přístrojem',
    },
    device: {
        pause: 'Pauza',
        paused: 'Pozastaveno',
        pausing: 'Pozastavuji',
        select_printer: 'Vyberte tiskárnu',
        retry: 'Opakovat',
        status: 'Stav',
        busy: 'Zaneprázdněn',
        ready: 'Připraven',
        reset: 'Restart (Kop)',
        abort: 'Zrušit',
        start: 'Začít',
        please_wait: 'Prosím vyčkejte...',
        quit: 'Konec',
        completing: 'Dokončuji',
        aborted: 'Zrušeno',
        completed: 'Dokončeno',
        aborting: 'Zrušeno',
        starting: 'Zahajuji',
        preparing: 'Připravuji',
        resuming: 'Obnovuji',
        scanning: 'Skenuji',
        occupied: 'Zachovávám',
        running: 'Pracuji',
        uploading: 'Nahrávám',
        processing: 'Zpracovávám',
        disconnectedError: {
            caption: 'Přístroj odpojen',
            message: 'Potvrďte prosím, jestli je dostupný síťový přístup k %s',
        },
        unknown: 'Neznámý',
        pausedFromError: 'Pozastaveno před chybou',
        model_name: 'Jméno modelu',
        IP: 'IP',
        serial_number: 'Sériové číslo',
        firmware_version: 'Verze firmwaru',
        UUID: 'UUID',
        select: 'Vybrat',
        deviceList: 'Seznam přístrojů',
        disable: 'Zakázat',
        enable: 'Povolit',
        submodule_type: 'Modul',
        cartridge_serial_number: 'Sériové číslo inkoustové kazety',
        ink_color: 'Barva inkoustu',
        ink_type: 'Typ inkoustu',
        ink_level: 'Úroveň inkoustu',
        close_door_to_read_cartridge_info: 'K získání informací o inkoustu prosím zavřete dvířka přístroje.',
        cartridge_info_read_failed: 'Ujistěte se prosím, že je inkoust zcela vložen. Pokuste se inkoust vyjmout a znovu vložit.',
        cartridge_info_verification_failed: 'Ověřte prosím, že je váš inkoust FLUX autentický.',
        toolhead_change: 'Změnit hlavu nástroje',
    },
    monitor: {
        monitor: 'MONITOR',
        go: 'Začít',
        resume: 'Obnovit',
        start: 'Začít',
        pause: 'Pauza',
        stop: 'Zastavit',
        record: 'Nahrát',
        camera: 'Kamera',
        taskTab: 'Úloha',
        connecting: 'Připojuji, prosím vyčkejte...',
        HARDWARE_ERROR_MAINBOARD_ERROR: '#401 Kritická chyba: Základní deska off-line. Kontaktujte prosím FLUX podporu.',
        HARDWARE_ERROR_SUBSYSTEM_ERROR: '#402 Kritická chyba: Subsystém neodpovídá. Kontaktujte prosím FLUX podporu.',
        HARDWARE_ERROR_PUMP_ERROR: '#900 Zkontrolujte prosím nádržku s vodou.',
        HARDWARE_ERROR_DOOR_OPENED: '#901 Pro pokračování prosím zavřete dvířka.',
        HARDWARE_ERROR_OVER_TEMPERATURE: '#902 Přehřátí. Vyčkejte prosím několik minut.',
        HARDWARE_ERROR_BOTTOM_OPENED: '#903 Spodní část otevřena. Pro pokračování prosím uzavřete spodní část.',
        HARDWARE_ERROR_PLATFORM_HOMING_FAILED: '#910 Restart osy Z neúspěšný',
        HARDWARE_ERROR_DRAWER_OPENED: '#911 Otevřená dvířka',
        HARDWARE_ERROR_FIRE_DETECTED: '#912 Anomálie senzoru plamene',
        HARDWARE_ERROR_AIR_ASSIST_ABNORMAL: '#913 Anomálie senzoru proudění vzduchu',
        HARDWARE_ERROR_ROTARY_NOT_DETECTED: '#914 Nebyl identifikován rotační modul',
        HARDWARE_ERROR_HOMING_PULLOFF_FAILED: '#920 Chyba Homing Pull-Off',
        HARDWARE_ERROR_HEADTYPE_MISMATCH: '#915 Nesprávný modul detekován. Pro pokračování prosím nainstalujte správný modul.',
        HARDWARE_ERROR_HEADTYPE_NONE: '#917 Modul nedetekován. Pro pokračování se prosím ujistěte, že je modul správně nainstalován.',
        HARDWARE_ERROR_HEADTYPE_UNKNOWN: '#918 Neznámý modul detekován. Pro pokračování prosím nainstalujte správný modul.',
        HARDWARE_ERROR_PRINTER_NO_RESPONSE: '#919 Tiskový modul neodpovídá.',
        USER_OPERATION_ROTARY_PAUSE: 'Přepněte prosím na rotační motor.',
        USER_OPERATION_REMOVE_CARTRIDGE: 'Pro pokračování prosím odstraňte kazetu.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_MISMATCH: '#915 Nesprávný modul detekován. Pro pokračování prosím nainstalujte správný modul.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_NONE: '#917 Modul nedetekován. Pro pokračování se prosím ujistěte, že je modul správně nainstalován.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_UNKNOWN: '#918 Neznámý modul detekován. Pro pokračování prosím nainstalujte správný modul.',
        USER_OPERATION_CHANGE_TOOLHEAD: 'Pro pokračování prosím nainstalujte správný modul.',
        USER_OPERATION_CHANGE_CARTRIDGE_CARTRIDGE_NOT_DETECTED: 'Pro pokračování prosím vložte kazetu.',
        USER_OPERATION_CHANGE_CARTRIDGE: 'Pro pokračování prosím vložte správnou kazetu.',
        USER_OPERATION: 'Pro pokračování prosím následujte instrukce na panelu zařízení.',
        RESOURCE_BUSY: 'Přístroj je zaneprázdněný.\nPokud nepracuje, přístroj prosím restartujte.',
        DEVICE_ERROR: 'Něco je v nepořádku\nRestartujte prosím přístroj',
        NO_RESPONSE: '#905 Chyba při připojování k základní desce.\nRestartujte prosím přístroj.',
        SUBSYSTEM_ERROR: '#402 Kritická chyba: Subsystém neodpovídá. Kontaktujte prosím FLUX podporu.',
        HARDWARE_FAILURE: 'Něco je v nepořádku\nRestartujte prosím přístroj',
        MAINBOARD_OFFLINE: '#905 Chyba při připojování k základní desce.\nRestartujte prosím přístroj.',
        bug_report: 'Hlášení chyby',
        processing: 'Zpracovávám',
        savingPreview: 'Generuji miniatury',
        hour: 'h',
        minute: 'm',
        second: 's',
        left: 'zbývá',
        temperature: 'Teplota',
        forceStop: 'Přejete si zrušit současnou úlohu?',
        upload: 'Nahrát',
        download: 'Stáhnout',
        relocate: 'Přemístit',
        cancel: 'Storno',
        prepareRelocate: 'Připravuji k přemístění',
        extensionNotSupported: 'Tento formát souboru není podporován',
        fileExistContinue: 'Soubor již existuje, přejete si ho nahradit?',
        confirmFileDelete: 'Určitě chcete tento soubor smazat?',
        ask_reconnect: 'Připojení k přístroji bylo narušeno. Přejete si znovu připojit?',
        task: {
            BEAMBOX: 'Laserové gravírování',
            'N/A': 'Volný mód',
        },
    },
    alert: {
        caption: 'Chyba',
        duplicated_preset_name: 'Duplikované jméno předvolby',
        info: 'INFO',
        warning: 'VAROVÁNÍ',
        error: 'JEJDA',
        instruction: 'Instrukce',
        oops: 'Jejky...',
        retry: 'Opakovat',
        abort: 'Zrušit',
        confirm: 'Potvrdit',
        cancel: 'Storno',
        close: 'Zavřít',
        ok: 'OK',
        ok2: 'OK',
        yes: 'Ano',
        no: 'Ne',
        stop: 'Zastavit',
        save: 'Uložit',
        dont_save: 'Neukládat',
        learn_more: 'Zjistit více',
    },
    caption: {
        connectionTimeout: 'Vypršel časový limit připojení',
    },
    message: {
        cancelling: 'Ruším...',
        connecting: 'Připojuji...',
        connectingMachine: 'Připojuji %s...',
        tryingToConenctMachine: 'Pokouším se připojit k přístroji...',
        connected: 'Připojeno',
        authenticating: 'Ověřuji...',
        enteringRawMode: 'Otevírám nezpracovaný mód...',
        endingRawMode: 'Ukončuji nezpracovaný mód...',
        enteringLineCheckMode: 'Otevírám mód kontroly linek...',
        endingLineCheckMode: 'Ukončuji mód kontroly linek...',
        exitingRotaryMode: 'Ukončuji rotační mód...',
        turningOffFan: 'Vypínám větrák...',
        turningOffAirPump: 'Vypínám vzdušnou pumpičku...',
        gettingLaserSpeed: 'Zjišťuji rychlost laserové hlavice...',
        settingLaserSpeed: 'Nastavuji rychlost laserové hlavice...',
        retrievingCameraOffset: 'Zjišťuji ofset kamery...',
        connectingCamera: 'Připojuji kameru...',
        homing: 'Navádím...',
        connectionTimeout: '#805 Vypršel časový limit připojení přístroje. Zkontrolujte prosím stav své sítě a indikátor Wi-Fi vašeho přístroje.',
        getProbePosition: 'Získávám pozici sondy...',
        device_not_found: {
            caption: 'Výchozí přístroj nenalezen',
            message: '#812 Zkontrolujte prosím indikátor Wi-Fi svého přístroje',
        },
        device_busy: {
            caption: 'Přístroj je zaneprázdněný',
            message: 'Přístroj vykonává jinou úlohu, zkuste to prosím později. Pokud přestane fungovat, restartujte prosím přístroj.',
        },
        device_is_used: 'Přístroj je momentálně využíván, chcete zrušit současnou úlohu?',
        monitor_too_old: {
            caption: 'Zastaralý firmware',
            content: '#814 Nainstalujte prosím nejaktuálnější firmware pomocí <a target="_blank" href="http://helpcenter.flux3dp.com/hc/en-us/articles/216251077">tohoto návodu</a>.',
        },
        unknown_error: '#821 Aplikace se setkala s neznámou chybou, využijte prosím Pomoc > Menu > Hlášení chyby.',
        unknown_device: '#826 Nepodařilo se připojit k přístroji. Ujistěte se prosím, že je USB připojen k přístroji.',
        unsupport_osx_version: 'Současná verze macOS #s nemusí podporovat některé funkce. Aktualizujte prosím na macOS 11+.',
        unsupport_win_version: 'Současná verze operačního systému #s nemusí podporovat některé funkce. Aktualizujte prosím na nejaktuálnější verzi.',
        need_password: 'K připojení k přístroji je zapotřebí heslo',
        unavailableWorkarea: '#804 Současná pracovní oblast přesahuje pracovní oblast tohoto přístroje. Zkontrolujte prosím pracovní oblast vybraného přístroje nebo vyberte pracovní oblast z Upravit > Nastavení dokumentu.',
        please_enter_dpi: 'Zadejte prosím jednotku svého souboru (v mm)',
        auth_error: '#820 Autorizační chyba: Aktualizujte prosím Beam Studio a firmware přístroje na nejnovější verzi.',
        usb_unplugged: 'USB připojení ztraceno. Zkontrolujte prosím připojení své USB.',
        uploading_fcode: 'Nahrávám FCode.',
        cant_connect_to_device: '#827 Nepodařilo se připojit k přístroji, zkontrolujte prosím své připojení',
        unable_to_find_machine: 'Nepodařilo se najít přístroj',
        disconnected: 'Připojení nestále, zkontrolujte prosím nastavení zařízení a zkuste to později.',
        unable_to_start: '#830 Nepodařilo se zahájit úlohu. Zkuste to prosím znovu. Pokud se to bude opakovat, nahlaste prosím chybu:\n',
        camera: {
            camera_cable_unstable: 'Bylo zjištěno, že kamera nestabilně přenáší fotografie. Stále můžete spustit náhled, ale může nastat problém s pomalým náhledem či vypršením časového limitu.',
            fail_to_transmit_image: '#845 Něco se nepodařilo s přenosem obrázku.Restartujte prosím svůj přístroj nebo Beam Studio. Pokud bude tato chyba přetrvávat, přečtěte si prosím <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/4402756056079">tento návod</a>.',
            ws_closed_unexpectly: '#844 Nečekaně bylo ukončeno spojení s kamerou přístroje. Pokud bude tato chyba přetrvávat, přečtěte si prosím <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/4402755805071">tento návod</a>.',
            continue_preview: 'Pokračovat',
            abort_preview: 'Zrušit',
        },
        preview: {
            camera_preview: 'Náhled kamery',
            auto_focus: 'Autofokus',
            auto_focus_instruction: 'Přesuňte prosím hlavici laserového modulu nad objekt a následujte instrukce k stisknutí AF pro fokus.',
            already_performed_auto_focus: 'Již jste použili autofokus. Použít existující hodnoty?',
            please_enter_height: 'Zadejte prosím výšku objektu, aby kamera zachytila přesný záběr.',
            apply: 'Použít',
            enter_manually: 'Vložit manuálně',
            adjust: 'Přizpůsobit',
            adjust_height_tooltip: 'Použijte zaškrtávací políčko k povolení úprav.',
        },
        unsupported_example_file: 'Vybraný ukázkový soubor není podporován současnou pracovní oblastí.',
        time_remaining: 'Zbývající čas:',
    },
    machine_status: {
        '-17': 'IO mód kazety',
        '-10': 'Udržovací mód',
        '-2': 'Skenuji',
        '-1': 'Udržuji',
        0: 'Nečinný',
        1: 'Zahajuji',
        2: 'ST_TRANSFORM',
        4: 'Zahajuji',
        6: 'Obnovuji',
        16: 'Pracuji',
        18: 'Obnovuji',
        32: 'Pozastaveno',
        36: 'Pozastaveno',
        38: 'Pozastavuji',
        48: 'Pozastaveno',
        50: 'Pozastavuji',
        64: 'Dokončeno',
        66: 'Dokončuji',
        68: 'Připravuji',
        128: 'Zrušeno',
        256: 'Alarm',
        512: 'Fatální',
        UNKNOWN: 'Neznámý',
    },
    calibration: {
        update_firmware_msg1: 'Váš firmware nepodporuje tuto funkci. Pro pokračování prosím aktualizujte firmware na novější verzi',
        update_firmware_msg2: 'nebo vyšší (Menu > Přístroj > [Váš přístroj] > Aktualizovat firmware)',
        camera_calibration: 'Kalibrace kamery ',
        diode_calibration: 'Kalibrace modulu diodového laseru',
        module_calibration_printer: 'Kalibrace tiskového modulu',
        module_calibration_2w_ir: 'Kalibrace infračerveného modulu',
        back: 'Zpět',
        next: 'Další',
        skip: 'Přeskočit',
        cancel: 'Storno',
        finish: 'Hotovo',
        do_engraving: 'Gravírovat',
        start_engrave: 'Začít Gravírovat',
        start_printing: 'Začít Tisk',
        ask_for_readjust: 'Přejete si přeskočit krok gravírování a fotit a kalibrovat přímo?',
        please_goto_beambox_first: 'Abyste tuto funkci mohli použít, přepněte prosím na mód gravírování ( Beambox ).',
        please_place_paper: 'Umístěte prosím A4 nebo bílý dopisový papír do levého horního rohu pracovní oblasti.',
        please_place_paper_center: 'Umístěte prosím A4 nebo bílý dopisový papír doprostřed pracovní oblasti.',
        please_place_dark_colored_paper: 'Umístěte prosím A4 nebo tmavý dopisový papír doprostřed pracovní oblasti.',
        please_refocus: {
            beambox: 'Přizpůsobte prosím platformu ke kontaktnímu místu (výška vypnutého akrylu)',
            beamo: 'Přizpůsobte prosím laserovou hadici, aby se nastavila na gravírovaný objekt (výška vypnutého akrylu)',
            beamo_af: 'Dvakrát prosím poklepejte na postranní tlačítko doplňku autofokus a nechte sondu, aby se jemně dotýkala materiálu.',
            hexa: 'Dvakrát klikněte na tlačítko přizpůsobení výšky, abyste zvedli nahoru voštinový stůl a sonda se dotýkala gravírovaného materiálu.',
        },
        without_af: 'Bez autofokusu.',
        with_af: 'S autofokusem.',
        dx: 'X',
        dy: 'Y',
        rotation_angle: 'Rotace',
        x_ratio: 'Poměr X',
        y_ratio: 'Poměr Y',
        show_last_config: 'Ukázat poslední výsledek',
        use_last_config: 'Použít hodnotu poslední kalibrace',
        taking_picture: 'Fotím...',
        analyze_result_fail: 'Nepodařilo se analyzovat zachycený obrázek. <br/>Ujistěte se prosím, že:<br/>1. Zachycený obrázek je celý zakryt bílým papírem.<br/>2. Platforma se řádně fokusuje.',
        drawing_calibration_image: 'Vykresluji kalibrační obrázek...',
        calibrate_done: 'Kalibrace dokončena. Při přesném fokusu je lepší přesnost kamery.',
        calibrate_done_diode: 'Kalibrace dokončena. Ofset modulu laserové diody byl uložen.',
        hint_red_square: 'Prosím zarovnejte vnější část červeného čtverce s ořízlým čtvercem.',
        hint_adjust_parameters: 'Použijte tyto parametry k přizpůsobení červeného čtverce',
        zendesk_link: 'https://support.flux3dp.com/hc/en-us/articles/360001811416',
        please_do_camera_calibration_and_focus: {
            beambox: 'Při kalibrování modulu laserové diody je potřeba kamera.\nUjistěte se prosím, že byla kamera tohoto stroje zkalibrována.\nPoté prosím upravte platformu na ústřední bod (výška vypnutého akrylu)',
            beamo: 'Při kalibrování modulu laserové diody je potřeba kamera.\nUjistěte se prosím, že byla kamera tohoto stroje zkalibrována.\nPoté prosím upravte laserovou hlavici, aby se fokusovala na gravírovaný objekt (výška vypnutého akrylu)',
        },
        downloading_pictures: 'Stahuji obrázky...',
        failed_to_download_pictures: '#848 Nepodařilo se stáhnout obrázky. Kontaktujte prosím FLUX podporu.',
        uploading_images: 'Nahrávám obrázky...',
        calculating_camera_matrix: 'Vypočítávám matici kamery...',
        calculating_regression_parameters: 'Vypočítávám regresní parametry...',
        failed_to_calibrate_camera: '#848 Nepodařilo se kalibrovat kameru. Kontaktujte prosím FLUX podporu.',
        failed_to_save_calibration_results: '#849 Nepodařilo se uložit výsledky kalibrace, zkuste to prosím znovu. Pokud bude problém přetrvávat, kontaktujte prosím FLUX podporu.',
        ador_autofocus_material: 'Tři sekundy držte stisknutou ikonu AF na hlavní stránce přístroje a nechte sondu, ať se jemně dotýká materiálu.',
        ador_autofocus_focusing_block: 'Tři sekundy držte stisknutou ikonu AF na hlavní stránce přístroje a nechte sondu, ať se dotýká fokusního bloku.',
        align_red_cross_cut: 'Zarovnejte prosím prostředek červeného kříže s vyříznutým křížem.',
        align_red_cross_print: 'Zarovnejte prosím prostředek červeného kříže s vytisknutým křížem.',
        retake: 'Znovu vyfotit',
        calibrate_camera_before_calibrate_modules: 'Nechte prosím znovu zkalibrovat kameru před kalibrací modulů.',
        check_checkpoint_data: 'Data kontrolního bodu',
        checking_checkpoint: 'Kontroluji data kontrolního bodu...',
        found_checkpoint: 'Ve vašem zařízení byla nalezena data kontrolního bodu. Přejete si je z kontrolního bodu obnovit?',
        use_old_camera_parameter: 'Přejete si použít současný parametr kamerových čoček?',
        downloading_checkpoint: 'Stahuji data kontrolního bodu...',
        failed_to_parse_checkpoint: 'Nepodařilo se rozebrat data kontrolního bodu.',
        check_device_pictures: 'Kontrola obrázků zařízení',
        checking_pictures: 'Kontroluji obrázky zařízení...',
        no_picutre_found: '#846 Vaše zařízení nemá dostupné žádné nezpracované fotky ke kalibraci. Kontaktujte prosím podporu FLUX.',
        calibrating_with_device_pictures: 'Kalibruji s obrázky zařízení...',
        failed_to_calibrate_with_pictures: '#847 Nepodařilo se kalibrovat s obrázky zařízení.',
        getting_plane_height: 'Získávám výšku plochy...',
        preparing_to_take_picture: 'Připravuji se k vyfocení...',
        put_paper: 'Vložte papír',
        put_paper_step1: 'Vložte prosím bílý papír formátu A4 nebo dopisu doprostřed pracovní oblasti.',
        put_paper_step2: 'Zajistěte čtyři rohy papíru, aby zůstal rovně položený.',
        put_paper_step3: 'Klikněte na "ZAČÍT GRAVÍROVAT"',
        solve_pnp_title: 'Zarovnat značkovací body.',
        solve_pnp_step1: 'Zarovnejte prosím gravírovací body podle počtu a očekávané pozice všech červených značkovacích bodů.',
        solve_pnp_step2: 'Pro opětovné zarovnání můžete stisknout "Znovu vyfotit" nebo manuálně přizpůsobit pozice značky.',
        align_olt: 'Pozice: Zarovnejte s vyrytým bodem nahoře vlevo venku.',
        align_ort: 'Pozice: Zarovnejte s vyrytým bodem nahoře vpravo venku.',
        align_olb: 'Pozice: Zarovnejte s vyrytým bodem dole vlevo venku.',
        align_orb: 'Pozice: Zarovnejte s vyrytým bodem dole vpravo venku.',
        align_ilt: 'Pozice: Zarovnejte s vyrytým bodem nahoře vlevo uvnitř.',
        align_irt: 'Pozice: Zarovnejte s vyrytým bodem nahoře vpravo uvnitř.',
        align_ilb: 'Pozice: Zarovnejte s vyrytým bodem dole vlevo uvnitř.',
        align_irb: 'Pozice: Zarovnejte s vyrytým bodem dole vpravo uvnitř.',
        elevate_and_cut: 'Zvednout a řezat',
        elevate_and_cut_step_1: 'Umístěte světlou dřevěnou desku velikosti A4 nebo obálky do pracovní oblasti pozvednuté na 20 mm.',
        elevate_and_cut_step_1_prism_lift: 'Použijte Ador Prism Lift o maximální délce 14 mm s  minimálně 6 mm širokou dřevěnou deskou.',
        with_prism_lift: 'S Ador Prism Lift',
        without_prism_lift: 'Bez Ador Prism Lift',
        camera_parameter_saved_successfully: 'Parametr kamery úspěšně uložen.',
        failed_to_save_camera_parameter: 'Nepodařilo se uložit parametr kamery.',
        failed_to_solve_pnp: 'Nepodařilo se vyřešit pozici kamery.',
        calibrating: 'Kalibruji...',
        moving_laser_head: 'Pohybuji laserovou hlavou...',
        failed_to_move_laser_head: 'Nepodařilo se pohnout laserovou hlavou.',
        put_chessboard: 'Umístěte šachovnici',
        put_chessboard_desc: 'Umístěte šachovnici do středu pracovní plochy.',
        put_chessboard_1: 'Přejděte na ovládací panel zařízení a stiskněte tlačítko AF pro automatické zaostření.',
        put_chessboard_2: 'Pohybujte šachovnicovým papírem nebo laserovou hlavou, dokud nebude potvrzeno, že celá šachovnice je v okně červeného rámečku živého náhledu.',
        put_chessboard_3: 'Klikněte pravým tlačítkem myši pro stažení obrázku šachovnice na aktuální pozici a zkontrolujte jeho jasnost.',
        failed_to_calibrate_chessboard: 'Kalibrace s obrázkem šachovnice se nezdařila.',
        calibrate_chessboard_success_msg: 'Úspěšně zachycena fotografie šachovnice.<br/>Skóre pro tuto fotografii je %s (%.2f).',
        res_excellent: 'Výborné',
        res_average: 'Průměrné',
        res_poor: 'Špatné',
        perform_autofocus_bb2: 'Přejděte na ovládací panel zařízení a stiskněte tlačítko AF pro automatické zaostření.',
    },
    input_machine_password: {
        require_password: '"%s" vyžaduje heslo',
        connect: 'PŘIPOJIT',
        password: 'Heslo',
    },
    tutorial: {
        skip: 'Přeskočit',
        welcome: 'VÍTEJTE',
        suggest_calibrate_camera_first: 'Doporučujeme uživatelům před každým náhledem nejprve kalibrovat kameru a znovu provést fokus pro optimální výsledky.<br/>Potvrďte prosím, že si nyní přejete kalibrovat.<br/>(Můžete toto přeskočit a později udělat přes "Menu" > "Přístroj" > [Váš přístroj] > "Kalibrovat kameru".)',
        camera_calibration_failed: 'Kalibrace kamery selhala',
        ask_retry_calibration: 'Přejete si znovu kalibrovat kameru?',
        needNewUserTutorial: 'Přejete si spustit výuku?<br/>(Můžete ji nyní přeskočit a později spustit přes "Pomoc" > "Ukázat Úvodní výuku".)',
        needNewInterfaceTutorial: 'Přejete si nyní spustit výuku pro nové rozhraní Beam Studio?<br/>(Můžete ji nyní přeskočit a později spustit přes "Pomoc" > "Úvod do uživatelského rozhraní".)',
        next: 'DALŠÍ',
        look_for_machine: 'Hledám přístroj pro výuku...',
        unable_to_find_machine: 'Nepodařilo se najít přístroj pro Výuku. Přejete si jít do nastavení připojení, opakovat nebo výuku přeskočit?',
        skip_tutorial: 'Přeskočil jste výuku. Můžete ji kdykoliv spustit přes "Pomoc" > "Ukázat Úvodní výuku"',
        set_connection: 'Nastavit připojení',
        retry: 'Opakovat',
        newUser: {
            draw_a_circle: 'Nakreslit kruh',
            drag_to_draw: 'Táhnout k nakreslení',
            infill: 'Zapnout výplň',
            switch_to_object_panel: 'Přepnout na panel objektů',
            switch_to_layer_panel: 'Přepnout na panel vrstev',
            set_preset_wood_engraving: 'Nastavit předvolbu: Dřevo - gravírování',
            set_preset_wood_cut: 'Nastavit předvolbu: Dřevo - řez',
            add_new_layer: 'Přidat novou vrstvu',
            draw_a_rect: 'Nakreslit obdélník',
            switch_to_preview_mode: 'Přepnout na mód náhledu',
            preview_the_platform: 'Náhled platformy',
            end_preview_mode: 'Ukončit mód náhledu',
            put_wood: '1. Vložte vzorek dřeva',
            adjust_focus: '2. Přizpůsobit fokus',
            close_cover: '3. Zavřít dvířka',
            send_the_file: 'Poslat soubor',
            end_alert: 'Jste si jistý, že chcete ukončit výuku?',
            please_select_wood_engraving: 'Vyberte prosím předvolbu "Dřevo - gravírování".',
            please_select_wood_cutting: 'Vyberte prosím předvolbu "Dřevo - řez".',
        },
        newInterface: {
            camera_preview: 'Náhled kamery',
            select_image_text: 'Výběr / Obrázek / Text',
            basic_shapes: 'Základní tvary',
            pen_tool: 'Nástroj propiska',
            add_new_layer: 'Přidat novou vrstvu',
            rename_by_double_click: 'Přejmenovat dvojitým kliknutím',
            drag_to_sort: 'Táhnout k seřazení',
            layer_controls: 'Pravé tlačítko k výběru Ovládání vrstev:\nDuplikovat / Sloučit / Zamknout / Smazat vrstvy',
            switch_between_layer_panel_and_object_panel: 'Přepínání mezi Panelem vrstev a Panelem objektů',
            align_controls: 'Ovládací prvky zarovnání',
            group_controls: 'Ovládací prvky skupin',
            shape_operation: 'Práce s tvary',
            flip: 'Převrátit',
            object_actions: 'Akce objektů',
            end_alert: 'Opravdu si přejete ukončit představení nového uživatelského rozhrání?',
            select_machine: 'Vyberte přístroj',
            start_work: 'Zahájit práci',
        },
        gesture: {
            pan: 'Posouvejte plátno dvěma prsty.',
            zoom: 'Udělejte "špetku" dvěma prsty k přiblížení a oddálení plátna.',
            click: 'Poklepejte k vybrání objektu.',
            drag: 'Přetáhněte k vybrání několika objektů.',
            hold: 'Stiskněte a držte k otevření kontextového menu.',
        },
        links: {
            adjust_focus: 'https://flux3dp.zendesk.com/hc/en-us/articles/360001684196',
        },
        tutorial_complete: 'To je z výuky vše. Nyní je čas tvořit!',
    },
    layer_module: {
        none: 'Žádný',
        general_laser: 'Laser',
        laser_10w_diode: '10W diodový laser',
        laser_20w_diode: '20W diodový laser',
        laser_2w_infrared: '2W infračervený laser',
        printing: 'Probíhá tisk',
        unknown: 'Neznámý modul',
        notification: {
            convertFromPrintingModuleTitle: 'Přejte si převést Tiskový modul na Laserový modul?',
            convertFromPrintingModuleMsg: 'Pozor: Pokud tuto operaci dokončíte, nastavení barev tiskové vrstvy bude odstraněno a nastaveno podle současné vrstvy.',
            convertFromLaserModuleTitle: 'Přejte si převést Laserový modul na Tiskový modul?',
            convertFromLaserModuleMsg: 'Pozor: Pokud tuto operaci dokončíte, nastavení laserové vrstvy bude odstraněno a nastaveno podle současné vrstvy.',
            importedDocumentContainsPrinting: 'Dokument obsahuje tiskovou vrstvu, přejete si změnit pracovní oblast na Ador?',
            printingLayersCoverted: 'Tiskové vrstvy byly převedeny na laserové vrstvy.',
            performPrintingCaliTitle: 'Proveďte kalibraci tiskového modulu',
            performPrintingCaliMsg: 'Klikněte na Potvrdit k provedení kalibrace, nebo vyberte kalibraci přes horní menu.<br />(Přístroj > [Jméno vašeho přístroje] > Kalibrovat tiskový modul)',
            performIRCaliTitle: 'Proveďte kalibraci infračerveného modulu',
            performIRCaliMsg: 'Klikněte na Potvrdit k provedení kalibrace, nebo vyberte kalibraci přes horní menu.<br />(Přístroj > [Jméno vašeho přístroje] > Kalibrovat infračervený modul)',
        },
        non_working_area: 'Nepracovní oblast',
    },
    qr_code_generator: {
        title: 'Generátor QR kódu',
        placeholder: 'Vložit odkaz nebo text',
        preview: 'Náhled',
        error_tolerance: 'Chyba v toleranci',
        error_tolerance_link: 'https://support.flux3dp.com/hc/en-us/articles/9113705072143',
        invert: 'Obrátit barvu pozadí',
    },
    boxgen: {
        title: 'BOXGEN',
        basic_box: 'Základní box',
        coming_soon: 'Již brzy',
        workarea: 'Pracovní oblast',
        max_dimension_tooltip: 'Maximální šířka/výška/hloubka je nastavena na %s.',
        volume: 'Objem',
        outer: 'Vnější',
        inner: 'Vnitřní',
        width: 'Šířka',
        height: 'Výška',
        depth: 'Hloubka',
        cover: 'Obal',
        thickness: 'Tloušťka',
        add_option: 'Přidat možnost',
        joints: 'Spoj',
        finger: 'Zakousnutí',
        finger_warning: 'Vnitřní strana výšky boxu musí být minimálně 6 mm (0,24 palce), aby byl kompatibilní se spojem Zakousnutí.',
        tSlot: 'Téčka',
        tSlot_warning: 'Výška strany boxu musí být minimálně 30 mm (1,18 palce), aby byl kompatibilní se spojování Téčky.',
        edge: 'Hrana',
        tCount: 'Počet Téček',
        tCount_tooltip: 'Počet Téček se vztahuje ke kratší straně. Jejich počet na delší straně je vypočítán na základě její délky.',
        tDiameter: 'Průměr Téčka',
        tLength: 'Výška Téček',
        continue_import: 'Pokračovat k Importu',
        customize: 'Přizpůsobit',
        merge: 'Sloučit',
        text_label: 'Popis',
        beam_radius: 'Kompenzace řezné spáry',
        beam_radius_warning: 'Odstranit kompenzaci řezné spáry, když jsou okraje a spoje boxu krátké, aby se zajistilo správné sestavení boxu',
        import: 'Importovat',
        cancel: 'Storno',
        reset: 'Restartovat',
        zoom: 'Zoom',
        control_tooltip: 'Levé tlačítko myši pro rotaci\nPosouvání pro zoom\nPravé tlačítko pro panorama',
        control_tooltip_touch: 'Táhněte pro rotaci\nŠpetka pro zoom\nDva prsty pro panorama',
    },
    my_cloud: {
        title: 'Můj cloud',
        loading_file: 'Načítám...',
        no_file_title: 'Pro zahájení uložte soubory do Mého cloudu',
        no_file_subtitle: 'Klikněte na Menu > "Soubor" > "Uložit do cloudu"',
        file_limit: 'Soubor zdarma',
        upgrade: 'Upgrade',
        sort: {
            most_recent: 'Nejnovější',
            oldest: 'Nejstarší',
            a_to_z: 'Jméno: A - Z',
            z_to_a: 'Jméno: Z - A',
        },
        action: {
            open: 'Otevřít',
            rename: 'Přejmenovat',
            duplicate: 'Duplikovat',
            download: 'Stáhnout',
            delete: 'Smazat',
            confirmFileDelete: 'Opravdu si přejete tento soubor smazat? Tato akce se nedá vrátit zpět.',
        },
        save_file: {
            choose_action: 'Uložit soubor:',
            save: 'Uložit',
            save_new: 'Uložit jako nový soubor',
            input_file_name: 'Vložit název souboru:',
            invalid_char: 'Neplatné znaky:',
            storage_limit_exceeded: 'Úložný prostor vašeho cloudu dosáhl maximální kapacity. Před uložením nových souborů prosím smažte nepotřebné soubory.',
        },
    },
    camera_data_backup: {
        title: 'Záloha dat z kamery',
        no_picture_found: 'V přístroji nebyly nalezeny žádné obrázky.',
        folder_not_exists: 'Vybraná složka neexistuje.',
        incorrect_folder: 'Nepodařilo se nahrát kalibrační data. Zkontrolujte prosím, jestli byla vybrána správná složka.',
        downloading_data: 'Stahuji data...',
        estimated_time_left: 'Zbývající délka:',
        uploading_data: 'Nahrávám data...',
        download_success: 'Data z kamery úspěšně stažena.',
        upload_success: 'Data z kamery úspěšně nahrána.',
    },
    insecure_websocket: {
        extension_detected: 'Zjištěno rozšíření Beam Studio Connect.',
        extension_detected_description: 'Zjistili jsme, že bylo nainstalováno rozšíření Beam Studio Connect. Klikněte prosím na Potvrdit, abyste se přesměrovali na HTTPS, nebo klikněte na Storno, abyste nadále používali HTTP.',
        extension_not_deteced: 'Nepodařilo se najít rozšíření Beam Studio Connect.',
        extension_not_deteced_description: 'Pro použití HTTPS prosím klikněte na Potvrdit, čímž se nainstaluje rozšíření Beam Studio Connect. Po instalaci prosím obnovte stránku, aby se rozšíření aktivovalo.<br/>Nebo klikněte na odkaz dole, abyste zjistili, jak na Chrome používat HTTP.',
        unsecure_url_help_center_link: '<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/9935859456271">Odkaz</a>',
    },
    curve_engraving: {
        measure_audofocus_area: 'Změřit oblast autofokusu',
        amount: 'Množství',
        gap: 'Mezera',
        rows: 'Řádky',
        coloumns: 'Sloupce',
        row_gap: 'Mezera mezi řádky',
        column_gap: 'Mezera mezi sloupci',
        set_object_height: 'Nastavit výšku objektu',
        set_object_height_desc: 'Změřit maximální tloušťku objektu.',
        measure_area_help: 'Jak vybrat oblast autofokusu pro 3D Curve?',
        reselect_area: 'Znovu vybrat oblast',
        start_autofocus: 'Zahájit autofokus',
        starting_measurement: 'Zahájit měření...',
        preview_3d_curve: 'Náhled 3D křivky',
        apply_arkwork: 'Použít artwork na 3D křivku',
        apply_camera: 'Použít obrázek z kamery na 3D křivku',
        click_to_select_point: 'Klikněte pro vybrání nebo odznačení bodů pro opětovné měření.',
        remeasure: 'Znovu změřit',
        remeasuring_points: 'Přeměřuji body...',
    },
    pass_through: {
        title: 'Režim procházení',
        help_text: 'Jak nastavit Procházení pro Ador?',
        help_link: 'https://support.flux3dp.com/hc/en-us/articles/10140002160399',
        object_length: 'Délka objektu',
        workarea_height: 'Pracovní oblast (Výška):',
        height_desc: 'Nastavit výšku všech sekcí pracovní oblasti.',
        ref_layer: 'Referenční vrstva',
        ref_layer_desc: 'Pozor: Výchozí hodnota referenční vrstvy je 0. Tato hodnota nebude použita, slouží pouze jako reference pro zarovnání.',
        ref_layer_name: 'Reference',
        guide_mark: 'Vodicí značka',
        guide_mark_length: 'Průměr:',
        guide_mark_x: 'Souřadnice X:',
        guide_mark_desc: 'Vodicí značky budou gravírovány jako referenční bod pro zarovnání kresby.',
        export: 'Export do pracovní oblasti',
        exporting: 'Exportuji...',
    },
    auto_fit: {
        title: 'Automatické přizpůsobení',
        step1: '1. Umístěte předmět do požadované polohy na jeden kus materiálu.',
        step2: '2. Stiskněte "Potvrdit" pro duplikování objektu na všechny podobné kusy materiálu v náhledu kamery.',
        preview_first: 'Nejprve proveďte náhled fotoaparátu.',
        failed_to_auto_fit: 'Automatické přizpůsobení se nezdařilo, zkontrolujte',
        error_tip1: '1. Je kresba umístěna na materiálu správně?',
        error_tip2: '2. Jsou obrysy materiálu dostatečně jasné pro rozpoznání?',
        learn_more: 'Přečtěte si, jak funguje automatické přizpůsobení',
        learn_more_url: 'https://support.flux3dp.com/hc/en-us/articles/10273384373775',
    },
    rotary_settings: {
        type: 'Typ',
        object_diameter: 'Průměr objektu',
        circumference: 'Obvod',
    },
    framing: {
        low_laser: 'Nízký laser',
        low_laser_desc: 'Nastavte hodnotu nízkého výkonu laseru pro úlohu rámování.',
        framing: 'Rámování',
        hull: 'Obrys',
        area_check: 'Kontrola oblasti',
        framing_desc: 'Náhled ohraničujícího rámečku objektu.',
        hull_desc: 'Zobrazuje tvar, který těsně kopíruje návrh, jako gumička obepínající objekt.',
        areacheck_desc: 'Zajišťuje bezpečnost pracovní oblasti náhledem na ohraničující rámeček objektu a zónu akcelerace laserové hlavy.',
        calculating_task: 'Výpočet úlohy...',
    },
    material_test_generator: {
        title: 'Generátor testování materiálu',
        table_settings: 'Nastavení tabulky',
        block_settings: 'Nastavení bloků',
        text_settings: 'Nastavení textu',
        preview: 'Náhled',
        export: 'Export',
        cut: 'Řez',
        engrave: 'Gravírování',
        columns: 'Sloupce',
        rows: 'Řádky',
        // table setting form
        parameter: 'Parametr',
        min: 'Min',
        max: 'Max',
        // block setting form
        count: 'Počet',
        size: 'Velikost (VxŠ)',
        spacing: 'Odstup',
    },
    web_cam: {
        no_permission: 'Beam Studio nemá oprávnění k přístupu k kameře. Ujistěte se, že je v nastavení prohlížeče nebo systému uděleno oprávnění pro Beam Studio.',
        no_device: 'Nelze detekovat kamerové zařízení. Znovu připojte kameru a zkuste to znovu.',
    },
};
export default lang;
