// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-components-beambox-right-panel-SelLayerBlock-module__container--KvUUX{background:#f8f8f8;padding:15px}.src-web-app-components-beambox-right-panel-SelLayerBlock-module__container--KvUUX .src-web-app-components-beambox-right-panel-SelLayerBlock-module__label--dbw0x{white-space:nowrap;margin:-15px -15px 15px -15px;display:block;padding:10px 15px;background:#f2f2f2;border-bottom:1px #e0e0e0 solid;color:#333;font-weight:bold}.src-web-app-components-beambox-right-panel-SelLayerBlock-module__container--KvUUX .src-web-app-components-beambox-right-panel-SelLayerBlock-module__select--dYlA\\+{display:block;background-color:#fff;width:100%;height:30px;padding:0 0 0 10px;background-position:calc(100% - 4px);cursor:pointer}", "",{"version":3,"sources":["webpack://./src/web/app/components/beambox/right-panel/SelLayerBlock.module.scss"],"names":[],"mappings":"AAAA,mFACE,kBAAA,CACA,YAAA,CAEA,kKACE,kBAAA,CACA,6BAAA,CACA,aAAA,CACA,iBAAA,CACA,kBAAA,CACA,+BAAA,CACA,UAAA,CACA,gBAAA,CAGF,oKACE,aAAA,CACA,qBAAA,CACA,UAAA,CACA,WAAA,CACA,kBAAA,CACA,oCAAA,CACA,cAAA","sourcesContent":[".container {\n  background: #f8f8f8;\n  padding: 15px;\n\n  .label {\n    white-space: nowrap;\n    margin: -15px -15px 15px -15px;\n    display: block;\n    padding: 10px 15px;\n    background: #f2f2f2;\n    border-bottom: 1px #e0e0e0 solid;\n    color: #333;\n    font-weight: bold;\n  }\n\n  .select {\n    display: block;\n    background-color: #ffffff;\n    width: 100%;\n    height: 30px;\n    padding: 0 0 0 10px;\n    background-position: calc(100% - 4px);\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "src-web-app-components-beambox-right-panel-SelLayerBlock-module__container--KvUUX",
	"label": "src-web-app-components-beambox-right-panel-SelLayerBlock-module__label--dbw0x",
	"select": "src-web-app-components-beambox-right-panel-SelLayerBlock-module__select--dYlA+"
};
export default ___CSS_LOADER_EXPORT___;
