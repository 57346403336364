var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useEffect, useMemo } from 'react';
import { Button, Modal } from 'antd';
import alertCaller from 'app/actions/alert-caller';
import deviceMaster from 'helpers/device-master';
import getLevelingData from 'app/actions/camera/preview-helper/getLevelingData';
import progressCaller from 'app/actions/progress-caller';
import useI18n from 'helpers/useI18n';
import { updateData } from 'helpers/camera-calibration-helper';
import { calibrateWithDevicePictures } from './utils';
const CheckPictures = ({ updateParam, onClose, onNext }) => {
    const progressId = useMemo(() => 'camera-check-pictures', []);
    const lang = useI18n();
    const calibrateDevicePictures = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        progressCaller.openNonstopProgress({
            id: progressId,
            message: lang.device.processing,
        });
        const levelingData = yield getLevelingData('hexa_platform');
        const refHeight = levelingData.A;
        Object.keys(levelingData).forEach((key) => {
            levelingData[key] = refHeight - levelingData[key];
        });
        try {
            progressCaller.update(progressId, {
                message: lang.calibration.calibrating_with_device_pictures,
            });
            const res = yield calibrateWithDevicePictures();
            if (res) {
                updateParam(Object.assign(Object.assign({}, res), { source: 'device', refHeight: 0, levelingData }));
                yield updateData(res);
                onNext();
            }
            else {
                onClose === null || onClose === void 0 ? void 0 : onClose(false);
            }
        }
        finally {
            progressCaller.popById(progressId);
        }
    }), [lang, onNext, onClose, progressId, updateParam]);
    const checkPictures = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        progressCaller.openNonstopProgress({
            id: progressId,
            message: lang.calibration.checking_pictures,
        });
        let hasPictures = false;
        try {
            const ls = yield deviceMaster.ls('camera_calib');
            hasPictures = ls.files.length > 0;
        }
        catch ( /* do nothing */_a) { /* do nothing */ }
        progressCaller.popById(progressId);
        if (hasPictures)
            calibrateDevicePictures();
        else {
            alertCaller.popUpError({ message: lang.calibration.no_picutre_found });
            onClose === null || onClose === void 0 ? void 0 : onClose(false);
        }
    }), [lang, progressId, onClose, calibrateDevicePictures]);
    useEffect(() => {
        checkPictures();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (React.createElement(Modal, { width: 400, open: true, centered: true, maskClosable: false, title: lang.calibration.check_device_pictures, closable: !!onClose, onCancel: () => onClose === null || onClose === void 0 ? void 0 : onClose(false), footer: [
            React.createElement(Button, { key: "yes", type: "primary", onClick: calibrateDevicePictures }, lang.alert.yes),
        ] }, lang.calibration.checking_pictures));
};
export default CheckPictures;
