var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export default {
    exists(path) {
        return false;
    },
    appendFile(filePath, data) {
    },
    copyFile(src, dest) {
    },
    writeFile(filePath, data) {
    },
    readFile(filePath, encoding) {
        return '';
    },
    isFile(input) {
        return false;
    },
    isDirectory(input) {
        return false;
    },
    rename(oldPath, newPath) {
        return Promise.resolve();
    },
    mkdir(path, isRecursive) {
        return __awaiter(this, void 0, void 0, function* () {
            return Promise.resolve('');
        });
    },
    writeStream(path, flags, data) {
    },
    join(...paths) {
        return '';
    },
    getPath(path) {
        return '';
    },
    readdirSync(path) {
        return [];
    },
    delete(path) { },
};
