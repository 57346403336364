import TopBarController from 'app/views/beambox/TopBar/contexts/TopBarController';
class CurrentFileManager {
    constructor() {
        this.isCloudFile = false;
        this.name = null;
        this.path = null;
        this.getName = () => this.name;
        this.getPath = () => this.path;
        this.extractFileName = (filepath) => {
            const splitPath = filepath.split(window.os === 'Windows' ? '\\' : '/');
            const fileName = splitPath[splitPath.length - 1];
            return fileName.slice(0, fileName.lastIndexOf('.')).replace(':', '/');
        };
        this.setFileName = (fileName, extractFromPath = false) => {
            const name = extractFromPath ? this.extractFileName(fileName) : fileName;
            this.name = name;
            TopBarController.updateTitle();
        };
        this.setLocalFile = (filepath) => {
            const fileName = this.extractFileName(filepath);
            this.name = fileName;
            this.path = filepath;
            this.isCloudFile = false;
            TopBarController.updateTitle();
        };
        this.setCloudFile = (file) => {
            this.name = file.name;
            this.path = file.uuid;
            this.isCloudFile = true;
            TopBarController.updateTitle();
        };
        this.setCloudUUID = (uuid) => {
            this.path = uuid;
            this.isCloudFile = !!uuid;
            // update cloud icon
            TopBarController.updateTitle();
        };
        this.clear = () => {
            this.name = null;
            this.path = null;
            this.isCloudFile = false;
            TopBarController.updateTitle();
        };
    }
}
// Singleton
const currentFileManager = new CurrentFileManager();
export default currentFileManager;
