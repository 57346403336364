import React from 'react';
import i18n from 'helpers/i18n';
import UnitInput from 'app/widgets/Unit-Input-v2';
const LANG = i18n.lang.beambox.tool_panels;
function NestRotationPanel({ rotations: rotationsProps, onValueChange }) {
    const [rotations, updateRotations] = React.useState(rotationsProps);
    const updateVal = (val) => {
        onValueChange(val);
        updateRotations(val);
    };
    return (React.createElement("div", { className: "tool-panel" },
        React.createElement("label", { className: "controls accordion" },
            React.createElement("input", { type: "checkbox", className: "accordion-switcher", defaultChecked: true }),
            React.createElement("p", { className: "caption" },
                LANG._nest.rotations,
                React.createElement("span", { className: "value" }, rotations.toString())),
            React.createElement("label", { className: "accordion-body" },
                React.createElement("div", null,
                    React.createElement("div", { className: "control nest-rotations" },
                        React.createElement(UnitInput, { min: 1, decimal: 0, unit: "", defaultValue: rotations, getValue: updateVal })))))));
}
export default NestRotationPanel;
