// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-components-boxgen-BoxSelector-module__selector--bX8Ge{flex:1;border-bottom:0}@media screen and (max-width: 600px){.src-web-app-components-boxgen-BoxSelector-module__selector--bX8Ge{width:250px;flex:none}}.src-web-app-components-boxgen-BoxSelector-module__container--3dT0W{display:flex;justify-content:center}", "",{"version":3,"sources":["webpack://./src/web/app/components/boxgen/BoxSelector.module.scss"],"names":[],"mappings":"AAAA,mEACE,MAAA,CACA,eAAA,CACA,qCAHF,mEAIE,WAAA,CACA,SAAA,CAAA,CAIF,oEACE,YAAA,CACA,sBAAA","sourcesContent":[".selector {\n  flex: 1;\n  border-bottom: 0;\n  @media screen and (max-width: 600px) {\n  width: 250px;\n  flex: none;\n  }\n}\n\n.container{\n  display: flex;\n  justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selector": "src-web-app-components-boxgen-BoxSelector-module__selector--bX8Ge",
	"container": "src-web-app-components-boxgen-BoxSelector-module__container--3dT0W"
};
export default ___CSS_LOADER_EXPORT___;
