var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { Button, Form, Input, InputNumber, Modal, Space, Table } from 'antd';
import { DeleteFilled, PlusCircleFilled } from '@ant-design/icons';
import { useContext, useEffect, useRef, useState } from 'react';
import Alert from 'app/actions/alert-caller';
import AlertConstants from 'app/constants/alert-constants';
import InputKeyWrapper, { setEditingInput, setStopEditingInput } from 'app/widgets/InputKeyWrapper';
import i18n from 'helpers/i18n';
import storage from 'implementations/storage';
import { DefaultColorConfigs } from 'app/constants/color-constants';
import AddColorConfigModal from '../dialogs/AddColorConfigModal';
const LANG = i18n.lang.beambox.layer_color_config_panel;
const formatHexColor = (input) => {
    const val = input.replace(/ +/, '');
    const matchHex6 = val.match(/#[0-9A-F]{6}\b/i);
    if (matchHex6) {
        return matchHex6[0].toUpperCase();
    }
    const matchHex3 = val.match(/#[0-9A-F]{3}\b/i);
    if (matchHex3) {
        return matchHex3[0].replace(/#([0-9A-F])([0-9A-F])([0-9A-F])/i, '#$1$1$2$2$3$3').toUpperCase();
    }
    const matchRGB = val.match(/(rgb)?\([0-9]{1,3},[0-9]{1,3},[0-9]{1,3}\)(?!.)/i);
    if (matchRGB) {
        const rgb = matchRGB[0].match(/[0-9]{1,3},[0-9]{1,3},[0-9]{1,3}/)[0].split(',');
        let hex = (parseInt(rgb[0], 10) * 65536
            + parseInt(rgb[1], 10) * 256
            + parseInt(rgb[2], 10)).toString(16);
        if (hex === 'NaN') {
            hex = '0';
        }
        while (hex.length < 6) {
            hex = `0${hex}`;
        }
        return `#${hex}`.toUpperCase();
    }
    return null;
};
const EditableContext = React.createContext(null);
const EditableRow = (_a) => {
    var { index } = _a, props = __rest(_a, ["index"]);
    const [form] = Form.useForm();
    return (React.createElement(Form, { form: form, component: false, size: "small" },
        React.createElement(EditableContext.Provider, { value: form },
            React.createElement("tr", Object.assign({}, props)))));
};
const EditableCell = (_a) => {
    var { title, editable, children, dataIndex, unit, min, max, record, validator, handleSave } = _a, restProps = __rest(_a, ["title", "editable", "children", "dataIndex", "unit", "min", "max", "record", "validator", "handleSave"]);
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
        var _a;
        if (editing) {
            (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, [editing]);
    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };
    const save = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const values = yield form.validateFields();
            toggleEdit();
            handleSave(Object.assign(Object.assign({}, record), values));
        }
        catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    });
    let childNode = children;
    if (editable) {
        childNode = editing ? (React.createElement(Form.Item, { style: { margin: 0 }, name: dataIndex, rules: [
                {
                    required: true,
                    message: `${title} is required.`,
                },
                {
                    validator: (_, value) => {
                        if (!validator)
                            return Promise.resolve();
                        if (formatHexColor(value)) {
                            return Promise.resolve();
                        }
                        return Promise.reject();
                    },
                },
            ] }, max ? (React.createElement(InputNumber, { size: "small", ref: inputRef, keyboard: true, onPressEnter: save, onBlur: save, min: min, max: max, formatter: (value) => `${value} ${unit}`, parser: (value) => Number(value === null || value === void 0 ? void 0 : value.replace(unit, '')) })) : (React.createElement(Input, { size: "small", ref: inputRef, onPressEnter: save, onFocus: () => setEditingInput(), onBlur: (e) => {
                setStopEditingInput();
                form.setFieldsValue({ [dataIndex]: formatHexColor(e.target.value) });
                save();
            }, suffix: unit })))) : (React.createElement("div", { className: "editable-cell-value-wrap", style: { paddingRight: 24 }, onClick: toggleEdit },
            children,
            "\u00A0",
            unit));
    }
    return (React.createElement("td", Object.assign({}, restProps),
        React.createElement(InputKeyWrapper, { inputRef: inputRef },
            React.createElement("div", { style: { display: 'flex', alignItems: 'center' } },
                dataIndex === 'color' && React.createElement("div", { style: { background: record.color }, className: "config-color-block" }),
                childNode))));
};
const LayerColorConfigPanel = (props) => {
    const [displayAddPanel, setDisplayAddPanel] = useState(false);
    const { onClose } = props;
    const layerColorConfigSettings = storage.get('layer-color-config');
    const initConfigs = (layerColorConfigSettings === null || layerColorConfigSettings === void 0 ? void 0 : layerColorConfigSettings.array) || [...DefaultColorConfigs];
    const [dataSource, setDataSource] = useState(initConfigs.map((config) => (Object.assign({ key: config.color }, config))));
    const handleDelete = (key) => {
        const newData = dataSource.filter((item) => item.key !== key);
        setDataSource(newData);
    };
    const handleSave = (row) => {
        const newData = [...dataSource];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, Object.assign(Object.assign({}, item), row));
        setDataSource(newData);
    };
    const columns = [
        {
            title: LANG.color,
            dataIndex: 'color',
            editable: true,
            validator: (value) => formatHexColor(value) || value,
            width: '130px',
        },
        {
            title: LANG.speed,
            dataIndex: 'speed',
            editable: true,
            min: 0,
            max: 900,
            unit: 'mm/s',
            width: '60px',
        },
        {
            title: LANG.power,
            dataIndex: 'power',
            editable: true,
            min: 0,
            max: 100,
            unit: '%',
            width: '60px',
        },
        {
            title: LANG.repeat,
            dataIndex: 'repeat',
            editable: true,
            min: 0,
            max: 999,
            unit: '',
            width: '60px',
        },
        {
            title: '',
            dataIndex: 'operation',
            render: (_, record) => (React.createElement(Button, { type: "text", onClick: () => handleDelete(record.key) },
                React.createElement(DeleteFilled, null))),
        },
    ].map((col) => {
        if (!col.editable) {
            return col;
        }
        return Object.assign(Object.assign({}, col), { onCell: (record) => (Object.assign(Object.assign({ record }, col), { handleSave })) });
    });
    const hasColor = (color) => dataSource.some((v) => v.color === color);
    const onResetDefault = () => {
        Alert.popUp({
            buttonType: AlertConstants.YES_NO,
            message: LANG.sure_to_reset,
            onYes: () => {
                setDataSource(DefaultColorConfigs.map((config) => (Object.assign({ key: config.color }, config))));
            },
        });
    };
    const onSave = () => {
        const backwardCompatibleConfigDict = {};
        dataSource.forEach((config, index) => {
            backwardCompatibleConfigDict[config.color] = index;
        });
        const configData = { array: dataSource, dict: backwardCompatibleConfigDict };
        console.log(configData);
        storage.set('layer-color-config', configData);
        onClose();
    };
    const renderFooter = () => [
        React.createElement(Button, { key: "reset", type: "dashed", onClick: onResetDefault }, LANG.default),
        React.createElement(Button, { key: "cancel", onClick: onClose }, LANG.cancel),
        React.createElement(Button, { key: "save", type: "primary", onClick: onSave }, LANG.save),
    ];
    const handleAddConfig = (config) => {
        if (!config.color) {
            Alert.popUp({
                type: AlertConstants.SHOW_POPUP_ERROR,
                message: LANG.no_input,
            });
        }
        else if (hasColor(config.color)) {
            Alert.popUp({
                type: AlertConstants.SHOW_POPUP_ERROR,
                message: LANG.in_use,
            });
        }
        else {
            setDataSource((prev) => [...prev, Object.assign({ key: config.color }, config)]);
            setDisplayAddPanel(false);
        }
    };
    const render = () => (React.createElement(Modal, { open: true, centered: true, onCancel: onClose, title: LANG.layer_color_config, footer: renderFooter() },
        React.createElement(Space, { direction: "vertical", style: { width: '100%' } },
            React.createElement(Button, { type: "primary", onClick: () => setDisplayAddPanel(true) },
                React.createElement(PlusCircleFilled, null),
                LANG.add_config),
            React.createElement(Table, { pagination: { pageSize: 8 }, size: "small", components: {
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    },
                }, rowClassName: () => 'editable-row', bordered: true, dataSource: dataSource, columns: columns }),
            displayAddPanel && (React.createElement(AddColorConfigModal, { onClose: () => setDisplayAddPanel(false), handleAddConfig: handleAddConfig })))));
    return render();
};
export default LayerColorConfigPanel;
