import storage from 'implementations/storage';
export default function getDefaultFont() {
    const { family, postscriptName } = storage.get('default-font');
    return {
        font_family: family,
        font_postscriptName: postscriptName,
        font_size: 14,
        fill: '#000000',
        fill_opacity: 0,
        text_anchor: 'start',
    };
}
