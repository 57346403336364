import React, { useMemo } from 'react';
import ColorPanel from 'app/views/beambox/Right-Panels/ColorPanel';
import ImageOptions from 'app/views/beambox/Right-Panels/Options-Blocks/ImageOptions';
import InFillBlock from 'app/views/beambox/Right-Panels/Options-Blocks/InFillBlock';
import MultiColorOptions from 'app/views/beambox/Right-Panels/Options-Blocks/MultiColorOptions';
import ObjectPanelItem from 'app/views/beambox/Right-Panels/ObjectPanelItem';
import PolygonOptions from 'app/views/beambox/Right-Panels/Options-Blocks/PolygonOptions';
import RectOptions from 'app/views/beambox/Right-Panels/Options-Blocks/RectOptions';
import TextOptions from 'app/views/beambox/Right-Panels/Options-Blocks/TextOptions';
import { getObjectLayer } from 'helpers/layer/layer-helper';
import { useIsMobile } from 'helpers/system-helper';
import styles from './OptionsPanel.module.scss';
function OptionsPanel({ elem, rx, polygonSides, updateObjectPanel, updateDimensionValues, }) {
    const isMobile = useIsMobile();
    let contents;
    const showColorPanel = useMemo(() => {
        var _a, _b;
        if (!elem)
            return false;
        if (!['rect', 'ellipse', 'path', 'text', 'polygon', 'g', 'use'].includes(elem === null || elem === void 0 ? void 0 : elem.tagName.toLowerCase()))
            return false;
        return ((_b = (_a = getObjectLayer(elem)) === null || _a === void 0 ? void 0 : _a.elem) === null || _b === void 0 ? void 0 : _b.getAttribute('data-fullcolor')) === '1';
    }, [elem]);
    if (elem) {
        const tagName = elem.tagName.toLowerCase();
        if (tagName === 'rect') {
            contents = [
                React.createElement(RectOptions, { key: "rect", elem: elem, rx: rx, updateDimensionValues: updateDimensionValues }),
                showColorPanel ? (React.createElement(ColorPanel, { key: "color", elem: elem })) : (React.createElement(InFillBlock, { key: "fill", elem: elem })),
            ];
        }
        else if (tagName === 'polygon') {
            contents = [
                React.createElement(PolygonOptions, { key: "polygon", elem: elem, polygonSides: polygonSides }),
                showColorPanel ? (React.createElement(ColorPanel, { key: "color", elem: elem })) : (React.createElement(InFillBlock, { key: "fill", elem: elem })),
            ];
        }
        else if (tagName === 'text') {
            contents = [
                React.createElement(TextOptions, { key: "text", elem: elem, textElement: elem, updateObjectPanel: updateObjectPanel, updateDimensionValues: updateDimensionValues, showColorPanel: showColorPanel }),
                // eslint-disable-next-line no-nested-ternary
                showColorPanel ? (React.createElement(ColorPanel, { key: "color", elem: elem })) : isMobile ? (React.createElement(InFillBlock, { key: "fill", elem: elem })) : null,
            ];
        }
        else if (tagName === 'image' || tagName === 'img') {
            if (elem.getAttribute('data-fullcolor') === '1')
                contents = [];
            else
                contents = [React.createElement(ImageOptions, { key: "image", elem: elem, updateObjectPanel: updateObjectPanel })];
        }
        else if (tagName === 'g' && elem.getAttribute('data-textpath-g')) {
            const textElem = elem.querySelector('text');
            contents = [
                React.createElement(TextOptions, { key: "textpath", isTextPath: true, elem: elem, textElement: textElem, updateObjectPanel: updateObjectPanel, updateDimensionValues: updateDimensionValues }),
            ];
        }
        else if (tagName === 'g') {
            contents = [
                showColorPanel ? (React.createElement(MultiColorOptions, { key: "multi-color", elem: elem })) : (React.createElement(InFillBlock, { key: "infill", elem: elem })),
            ];
        }
        else if (tagName === 'use') {
            contents = [showColorPanel ? React.createElement(MultiColorOptions, { key: "multi-color", elem: elem }) : null];
        }
        else {
            contents = [
                showColorPanel ? (React.createElement(ColorPanel, { key: "color", elem: elem })) : (React.createElement(InFillBlock, { key: "infill", elem: elem })),
            ];
        }
    }
    return isMobile ? (React.createElement("div", { className: styles.container },
        React.createElement(ObjectPanelItem.Divider, null), contents === null || contents === void 0 ? void 0 :
        contents.reverse())) : (React.createElement(React.Fragment, null, (contents) && (React.createElement("div", { className: styles.panel },
        React.createElement("div", { className: styles.title }, "OPTIONS"),
        contents))));
}
export default OptionsPanel;
