import React, { useEffect, useMemo } from 'react';
import curveEngravingModeController from 'app/actions/canvas/curveEngravingModeController';
import eventEmitterFactory from 'helpers/eventEmitterFactory';
import LeftPanelButton from 'app/components/beambox/left-panel/LeftPanelButton';
import LeftPanelIcons from 'app/icons/left-panel/LeftPanelIcons';
import useForceUpdate from 'helpers/use-force-update';
import useI18n from 'helpers/useI18n';
import { getSVGAsync } from 'helpers/svg-editor-helper';
let svgCanvas;
getSVGAsync((globalSVG) => {
    svgCanvas = globalSVG.Canvas;
});
// TODO add unit tests
const CurveEngravingTool = ({ className }) => {
    const forceUpdate = useForceUpdate();
    const canvasEventEmitter = useMemo(() => eventEmitterFactory.createEventEmitter('canvas'), []);
    useEffect(() => {
        canvasEventEmitter.on('CURVE_ENGRAVING_AREA_SET', forceUpdate);
        return () => {
            canvasEventEmitter.removeListener('CURVE_ENGRAVING_AREA_SET', forceUpdate);
        };
    }, [canvasEventEmitter, forceUpdate]);
    const lang = useI18n().beambox.left_panel.label;
    const currentCursorMode = svgCanvas.getMode();
    // TODO: add i18n
    return (React.createElement("div", { className: className },
        React.createElement(LeftPanelButton, { id: "back", icon: React.createElement(LeftPanelIcons.Back, null), title: lang.curve_engraving.exit, onClick: () => curveEngravingModeController.backToPreview() }),
        React.createElement(LeftPanelButton, { id: "cursor", icon: React.createElement(LeftPanelIcons.Cursor, null), title: lang.cursor, active: currentCursorMode === 'select', onClick: () => {
                curveEngravingModeController.toCanvasSelectMode();
                forceUpdate();
            } }),
        React.createElement(LeftPanelButton, { id: "curve-select", icon: React.createElement(LeftPanelIcons.CurveSelect, null), title: lang.curve_engraving.select_area, active: currentCursorMode === 'curve-engraving', onClick: () => {
                curveEngravingModeController.toAreaSelectMode();
                forceUpdate();
            } }),
        React.createElement(LeftPanelButton, { id: "curve-preview", icon: React.createElement(LeftPanelIcons.CuverPreview, null), title: lang.curve_engraving.preview_3d_curve, disabled: !curveEngravingModeController.hasArea(), onClick: () => curveEngravingModeController.preview() }),
        React.createElement(LeftPanelButton, { id: "delete", icon: React.createElement(LeftPanelIcons.Delete, null), title: lang.curve_engraving.clear_area, onClick: () => curveEngravingModeController.clearArea() })));
};
export default CurveEngravingTool;
