import eventEmitterFactory from 'helpers/eventEmitterFactory';
const SHOW_CROPPER = 'SHOW_CROPPER';
const DRAW_GUIDE_LINES = 'DRAW_GUIDE_LINES';
const eventEmitter = eventEmitterFactory.createEventEmitter();
export default {
    onCropperShown(callback) {
        eventEmitter.on(SHOW_CROPPER, callback);
    },
    onDrawGuideLines(callback) {
        eventEmitter.on(DRAW_GUIDE_LINES, callback);
    },
    removeCropperShownListener(callback) {
        eventEmitter.removeListener(SHOW_CROPPER, callback);
    },
    emitShowCropper() {
        eventEmitter.emit(SHOW_CROPPER);
    },
    emitDrawGuideLines() {
        eventEmitter.emit(DRAW_GUIDE_LINES);
    },
};
