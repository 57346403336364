var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, Modal, Row } from 'antd';
import alertCaller from 'app/actions/alert-caller';
import alertConstants from 'app/constants/alert-constants';
import dialog from 'implementations/dialog';
import deviceMaster from 'helpers/device-master';
import progressCaller from 'app/actions/progress-caller';
import useI18n from 'helpers/useI18n';
import { calibrateChessboard, startFisheyeCalibrate } from 'helpers/camera-calibration-helper';
import styles from './CalibrateChessBoard.module.scss';
import { getMaterialHeight, prepareToTakePicture } from './utils';
const CalibrateChessBoard = ({ updateParam, onClose, onBack, onNext }) => {
    const lang = useI18n();
    const progressId = useMemo(() => 'ador-calibration-v2', []);
    const [res, setRes] = useState(null);
    useEffect(() => () => URL.revokeObjectURL(res === null || res === void 0 ? void 0 : res.imgUrl), [res]);
    const objectHeight = useRef(0);
    const initSetup = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        progressCaller.openNonstopProgress({
            id: progressId,
            message: lang.calibration.getting_plane_height,
        });
        const height = yield getMaterialHeight('A');
        progressCaller.update(progressId, { message: lang.calibration.preparing_to_take_picture });
        yield prepareToTakePicture();
        console.log('height', height);
        objectHeight.current = height;
        progressCaller.update(progressId, { message: lang.calibration.taking_picture });
        try {
            yield deviceMaster.connectCamera();
        }
        finally {
            progressCaller.popById(progressId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), []);
    const handleCalibrate = (retryTimes = 0) => __awaiter(void 0, void 0, void 0, function* () {
        progressCaller.openNonstopProgress({
            id: progressId,
            message: lang.calibration.taking_picture,
        });
        const { imgBlob } = (yield deviceMaster.takeOnePicture()) || {};
        if (!imgBlob) {
            if (retryTimes < 3)
                handleCalibrate(retryTimes + 1);
            else
                alertCaller.popUpError({ message: 'Unable to get image' });
        }
        else {
            try {
                yield startFisheyeCalibrate();
                const calibrateRes = yield calibrateChessboard(imgBlob, objectHeight.current);
                console.log(calibrateRes);
                let displayBlob = imgBlob;
                if (calibrateRes.success === false) {
                    if (retryTimes < 3)
                        handleCalibrate(retryTimes + 1);
                    else
                        alertCaller.popUpError({
                            message: `Failed to get correct corners ${calibrateRes.data.reason}`,
                        });
                }
                else {
                    displayBlob = calibrateRes.blob;
                    if (calibrateRes.data.ret > 3) {
                        alertCaller.popUp({
                            type: alertConstants.WARNING,
                            message: `Large deviation: ${calibrateRes.data.ret}, please chessboard.`,
                        });
                    }
                }
                setRes({
                    success: calibrateRes.success,
                    origBlob: imgBlob,
                    imgblob: displayBlob,
                    imgUrl: URL.createObjectURL(displayBlob),
                    data: calibrateRes.success ? calibrateRes.data : null,
                });
            }
            catch (err) {
                alertCaller.popUpError({ message: err.message || 'Fail to find corners' });
            }
        }
        progressCaller.popById(progressId);
    });
    useEffect(() => {
        initSetup().then(() => {
            handleCalibrate();
        });
        return () => deviceMaster.disconnectCamera();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleNext = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!res.success)
            return;
        updateParam({
            k: res.data.k,
            d: res.data.d,
            rvec: res.data.rvec,
            tvec: res.data.tvec,
            refHeight: 0,
            // Assuming chessboard is flat
            levelingData: { A: 0, B: 0, C: 0, D: 0, E: 0, F: 0, G: 0, H: 0, I: 0 },
            source: 'device',
        });
        const ls = yield deviceMaster.ls('camera_calib');
        if (ls.files.length > 0) {
            const override = yield new Promise((resolve) => {
                alertCaller.popUp({
                    type: alertConstants.WARNING,
                    message: 'Do you want to override the device images?',
                    buttonType: alertConstants.CONFIRM_CANCEL,
                    onConfirm: () => resolve(true),
                    onCancel: () => resolve(false),
                });
            });
            if (!override) {
                onNext();
                return;
            }
            for (let i = 0; i < ls.files.length; i += 1) {
                // eslint-disable-next-line no-await-in-loop
                yield deviceMaster.deleteFile('camera_calib', ls.files[i]);
            }
        }
        yield deviceMaster.uploadToDirectory(res.origBlob, 'camera_calib', `pic_${objectHeight.current.toFixed(1)}_top_left.jpg`);
        onNext();
    }), [updateParam, onNext, res]);
    const handleDownloadImage = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const tFile = lang.topmenu.file;
        dialog.writeFileDialog(() => res.imgblob, 'Download Image', 'chessboard.jpg', [
            {
                name: window.os === 'MacOS' ? `${tFile.jpg_files} (*.jpg)` : tFile.jpg_files,
                extensions: ['jpg'],
            },
            { name: tFile.all_files, extensions: ['*'] },
        ]);
    }), [res === null || res === void 0 ? void 0 : res.imgblob, lang]);
    const renderFooter = () => [
        React.createElement(Button, { onClick: onBack, key: "back" }, lang.buttons.back),
        React.createElement(Button, { onClick: () => handleCalibrate(0), key: "retry" }, "Retry"),
        (res === null || res === void 0 ? void 0 : res.imgblob) ? (React.createElement(Button, { onClick: handleDownloadImage, key: "download" }, "Download Image")) : null,
        React.createElement(Button, { onClick: handleNext, disabled: !(res === null || res === void 0 ? void 0 : res.success), key: "next", type: "primary" }, lang.buttons.next),
    ].filter((btn) => btn);
    return (React.createElement(Modal, { open: true, centered: true, onCancel: () => onClose(false), title: lang.calibration.camera_calibration, footer: renderFooter, closable: true, maskClosable: false },
        "Calibrate Chessboard",
        React.createElement(Row, { gutter: [16, 0] },
            React.createElement(Col, { span: 18 },
                React.createElement("div", { className: styles['img-container'] },
                    React.createElement("img", { src: res === null || res === void 0 ? void 0 : res.imgUrl }))),
            React.createElement(Col, { span: 6 },
                React.createElement("div", null,
                    "Object Height: ",
                    objectHeight.current),
                (res === null || res === void 0 ? void 0 : res.success) && (React.createElement("div", null,
                    React.createElement("div", null,
                        React.createElement("span", null, "Ret: "),
                        React.createElement("span", null, res.data.ret.toFixed(2)))))))));
};
export default CalibrateChessBoard;
