var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import constant from 'app/actions/beambox/constant';
import deviceMaster from 'helpers/device-master';
export const getAdorPaddingAccel = (device) => __awaiter(void 0, void 0, void 0, function* () {
    if (!constant.adorModels.includes(device === null || device === void 0 ? void 0 : device.model))
        return null;
    try {
        yield deviceMaster.select(device);
        const deviceDetailInfo = yield deviceMaster.getDeviceDetailInfo();
        const xAcc = parseInt(deviceDetailInfo.x_acc, 10);
        // handle nan and 0
        return Number.isNaN(xAcc) || !xAcc ? null : xAcc;
    }
    catch (error) {
        console.error(error);
        return null;
    }
});
export default {
    getAdorPaddingAccel,
};
