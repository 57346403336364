/* eslint-disable import/order */
import AbstractMenu from 'helpers/menubar/AbstractMenu';
import eventEmitterFactory from 'helpers/eventEmitterFactory';
class Menu extends AbstractMenu {
    constructor() {
        super();
        this.eventEmitter = eventEmitterFactory.createEventEmitter('top-bar-menu');
    }
    init() {
        this.initMenuEvents();
    }
    enable(items) {
        this.eventEmitter.emit('ENABLE_MENU_ITEM', items);
    }
    disable(items) {
        this.eventEmitter.emit('DISABLE_MENU_ITEM', items);
    }
    updateLanguage() {
        this.eventEmitter.emit('UPDATE_LANGUAGE');
    }
}
export default new Menu();
