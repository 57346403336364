var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { sprintf } from 'sprintf-js';
import Alert from 'app/actions/alert-caller';
import AlertConstants from 'app/constants/alert-constants';
import BeamboxPreference from 'app/actions/beambox/beambox-preference';
import changeWorkarea from 'app/svgedit/operations/changeWorkarea';
import i18n from 'helpers/i18n';
import { allWorkareas } from 'app/constants/workarea-constants';
const LANG = i18n.lang;
const showResizeAlert = (device) => __awaiter(void 0, void 0, void 0, function* () {
    if (!allWorkareas.has(device.model))
        return true;
    return new Promise((resolve) => {
        Alert.popUp({
            message: sprintf(LANG.beambox.popup.change_workarea_before_preview, device.name),
            buttonType: AlertConstants.YES_NO,
            onYes: () => {
                BeamboxPreference.write('model', device.model);
                changeWorkarea(device.model);
                resolve(true);
            },
            onNo: () => resolve(false),
        });
    });
});
export default showResizeAlert;
