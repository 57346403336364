const fontNameMap = {
    'Noto Sans TC': '思源黑體 TC',
    'Noto Sans HK': '思源黑體 HK',
    'Noto Sans SC': '思源黑体 SC',
    'Noto Sans JP': '源ノ角ゴシックJP',
    'Noto Sans KR': '본고딕 KR',
    'Noto Serif TC': '思源宋體 TC',
    'Noto Serif SC': '思源宋体 SC',
    'Noto Serif JP': '源ノ明朝 JP',
    'Noto Serif KR': '본명조 KR',
    yomogi: 'よもぎフォント',
    'Dela Gothic One': 'デラゴシック',
    'potta One': 'ポッタ One',
    'train One': 'トレイン One',
    STICK: 'ステッキ',
    'reggae One': 'レゲエ One',
    'rocknRoll One': 'ロックンロール One',
    DotGothic16: 'ドットゴシック 16',
    'Shippori Mincho': 'しっぽり明朝',
    'Shippori Mincho B1': 'しっぽり明朝 B1',
    'Kiwi Maru': 'キウイ丸',
    'sawarabi Gothic': 'さわらびゴシック',
    'hachi maru pop': 'はちまるポップ',
    'Yusei Magic': '油性マジック',
    'Otomanopee One': 'GL-オとマのペ',
    'New Tegomin': 'ニューテゴミン',
    'ZCOOL xiaowei': '站酷小薇体',
    'ZCOOL QingKe HuangYou': '站酷庆科黄油体',
    'Ma Shan Zheng': '马善政毛笔楷书',
    'ZCOOL kuaile': '站酷快乐体',
    'long cang': '龙藏体',
    'Liu Jian Mao Cao': '流江毛草',
    'Zhi Mang Xing': '钟齐志莽行书',
    'TW-MOE-Std-Kai': '楷書',
    'GenYoGothic TW': '源様黑體',
    'GenYoGothic JP': '源様ゴシック',
    'GenRyuMin TW': '源流明體',
    'GenRyuMin JP': '源流明朝',
    'GenWanMin TW': '源雲明體',
    'GenWanMin JP': '源雲明朝',
    'GenSekiGothic TW': '源石黑體',
    'GenSekiGothic JP': '源石ゴシック',
    'GenSenRounded TW': '源泉圓體 ',
    'GenSenRounded JP': '源泉丸ゴシック',
    'Gen Shin Gothic': '源真ゴシック',
    'Gen Shin Gothic P': '源真ゴシック P',
    'Gen Shin Gothic Monospace': '源真ゴシック等幅',
    'Taipei Sans TC Beta': '台北黑體',
    'GenEi Gothic M': '源暎ゴシックM',
    'Chiron Sans HK Pro': '昭源黑體',
    'Huayuan Gothic': '樺源黑體',
    'Glow Sans TC': '未来熒黑',
    'Glow Sans SC': '未来荧黑',
    'Glow Sans J': 'ヒカリ角ゴ',
    '07TetsubinGothic': '鉄瓶ゴシック',
    Togalite: 'トガリテ',
    'Senobi Gothic': 'せのびゴシック',
    Genkaimincho: '源界明朝',
    SoukouMincho: '装甲明朝',
    Isego: '異世ゴ',
    isemin: '異世明',
    HanaMinPlus: '花園明朝',
    IPAexMincho: 'IPA 明朝',
    'I.Ming': '一點明',
    'Koku Mincho Regular': '刻明朝',
    'Oradano-mincho-GSRR': 'Oradano 明朝 GSRR',
    'Huiwen-mincho': '匯文明朝',
    'Swei Gothic CJK TC': '獅尾圓體 TC',
    'Swei Gothic CJK JP': '獅尾圓體 JP',
    'Swei Gothic CJK SC': '狮尾圆体 SC',
    'Gen Jyuu Gothic': '源柔ゴシック',
    'Corporate Logo Rounded ver2': 'コーポレート・ロゴ',
    'jf-openhuninn-1.1': 'jf open 粉圓',
    irohamaru: 'いろはマル',
    'timemachine wa': 'タイムマシンわ号',
    SetoFont: '瀨戶字型',
    NaikaiFont: '內海字體',
    內海字體JP: '內海字體',
    mamelon: 'マメロン',
    'ronde-B': 'ロンド B スクエア',
    'Nagurigaki Crayon': '殴り書きクレヨン',
    'Tanuki Permanent Marker': 'たぬき油性マジック',
    AsobiMemogaki: '遊びメモ書き',
    'mukasi mukasi': '昔々ふぉんと',
    ShigotoMemogaki: '仕事メモ書き',
    DartsFont: 'ダーツフォント',
    Mushin: '無心',
    '851MkPOP': '851 マカポップ',
    '001Shirokuma': 'しろくまフォント',
    kawaiitegakimoji: 'KAWAII 手書き文字',
    Aoyagireisyosimo2: '青柳隷書しも',
    AoyagiKouzanFontT: '青柳衡山フォントT',
    Bakudai: '莫大毛筆字體',
    'Klee One': 'クレー One',
    Kaisotai: '廻想体',
    makinas: 'マキナス',
    '851Gkktt': '851 ゴチカクット',
    '851CHIKARA-DZUYOKU-KANA-B': '851 チカラヅヨク',
    'Chogokuboso Gothic': '超極細ゴシック',
    BoutiqueBitmap7x7: '精品點陣體7×7',
};
export default fontNameMap;
