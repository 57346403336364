import React, { useEffect, useRef } from 'react';
const HorizontalScrollContainer = ({ className, children }) => {
    const divRef = useRef(null);
    const handleDivWheel = (e) => {
        const currentTarget = e.currentTarget;
        if (Math.abs(e.deltaY) > Math.abs(e.deltaX)) {
            const scrollBefore = currentTarget.scrollLeft;
            currentTarget.scrollLeft += e.deltaY;
            if (scrollBefore !== currentTarget.scrollLeft) {
                e.preventDefault();
                e.stopPropagation();
            }
        }
    };
    useEffect(() => {
        const div = divRef.current;
        div === null || div === void 0 ? void 0 : div.addEventListener('wheel', handleDivWheel, false);
        return () => {
            div === null || div === void 0 ? void 0 : div.removeEventListener('wheel', handleDivWheel);
        };
    });
    return (React.createElement("div", { className: className, ref: divRef }, children));
};
export default HorizontalScrollContainer;
