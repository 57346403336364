import ColorAdjustment from './color-adjustment.svg';
import Filter from './filter.svg';
import Plus from './plus.svg';
import Settings from './settings.svg';
export default {
    ColorAdjustment,
    Filter,
    Plus,
    Settings,
};
