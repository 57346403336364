import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ConfigProvider, Modal, InputNumber, Slider } from 'antd';
import history from 'app/svgedit/history/history';
import undoManager from 'app/svgedit/history/undoManager';
import useI18n from 'helpers/useI18n';
import { ConfigModalBlock } from 'app/constants/antd-config';
import { getLayerByName } from 'helpers/layer/layer-helper';
import { writeDataLayer } from 'helpers/layer/layer-config-helper';
import ConfigPanelContext from './ConfigPanelContext';
import styles from './AdvancedPowerPanel.module.scss';
// TODO: add test
const AdvancedPowerPanel = ({ onClose }) => {
    const t = useI18n().beambox.right_panel.laser_panel;
    const { dispatch, initState, selectedLayers, state } = useContext(ConfigPanelContext);
    const [draftValue, setDraftValue] = useState({
        minPower: state.minPower,
    });
    const [displayValue, setDisplayValue] = useState(draftValue);
    useEffect(() => setDisplayValue(draftValue), [draftValue]);
    const power = state.power.value;
    const handleSave = () => {
        const newState = Object.assign({}, state);
        const batchCmd = new history.BatchCommand('Change power advanced setting');
        selectedLayers.forEach((layerName) => {
            const layer = getLayerByName(layerName);
            if (state.minPower.value !== draftValue.minPower.value ||
                state.minPower.hasMultiValue !== draftValue.minPower.hasMultiValue) {
                writeDataLayer(layer, 'minPower', draftValue.minPower.value, { batchCmd });
                newState.minPower = draftValue.minPower;
            }
        });
        batchCmd.onAfter = initState;
        undoManager.addCommandToHistory(batchCmd);
        dispatch({ type: 'update', payload: newState });
        onClose();
    };
    const handleValueChange = useCallback((key, value, display = false) => {
        if (display)
            setDisplayValue((cur) => (Object.assign(Object.assign({}, cur), { [key]: { value, hasMultiValue: false } })));
        else
            setDraftValue((cur) => (Object.assign(Object.assign({}, cur), { [key]: { value, hasMultiValue: false } })));
    }, []);
    return (React.createElement(Modal, { centered: true, open: true, maskClosable: false, width: 320, onOk: handleSave, onCancel: onClose, cancelText: t.cancel, okText: t.save, title: t.pwm_advanced_setting },
        React.createElement(ConfigProvider, { theme: ConfigModalBlock },
            React.createElement("div", { className: styles.desc },
                t.pwm_advanced_desc,
                React.createElement("div", { className: styles.gray }, t.pwm_advanced_hint)),
            React.createElement("div", { className: styles.block },
                React.createElement("div", { className: styles.header },
                    React.createElement("span", { className: styles.input },
                        React.createElement(InputNumber, { size: "small", value: draftValue.minPower.value, controls: false, min: 0, max: power, precision: 0, onChange: (val) => handleValueChange('minPower', val) }),
                        React.createElement("span", { className: styles.unit }, "%")),
                    React.createElement("span", { className: styles.input },
                        React.createElement(InputNumber, { disabled: true, size: "small", value: power, controls: false, min: 0, max: power, precision: 0 }),
                        React.createElement("span", { className: styles.unit }, "%"))),
                React.createElement(Slider, { className: styles['one-side-slider'], min: 0, max: power, step: 1, range: true, value: [displayValue.minPower.value, power], onAfterChange: (values) => handleValueChange('minPower', values[0]), onChange: (values) => handleValueChange('minPower', values[0], true), tooltip: {
                        formatter: (v) => `${v}%`,
                    } }),
                React.createElement("div", { className: styles.footer },
                    React.createElement("span", null, "Min"),
                    React.createElement("span", null, "Max"))))));
};
export default AdvancedPowerPanel;
