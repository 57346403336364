var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import eventEmitterFactory from 'helpers/eventEmitterFactory';
import findDefs from 'app/svgedit/utils/findDef';
import NS from 'app/constants/namespaces';
import svgStringToCanvas from 'helpers/image/svgStringToCanvas';
import touchEvents from 'app/svgedit/touchEvents';
import wheelEventHandlerGenerator from 'app/svgedit/interaction/wheelEventHandler';
import workareaManager from 'app/svgedit/workarea';
import styles from './PassThrough.module.scss';
const zoomBlockEventEmitter = eventEmitterFactory.createEventEmitter('zoom-block');
class PassThroughCanvas {
    constructor() {
        this.zoomRatio = 1;
        this.canvasExpansion = 3; // extra space
        this.clear = () => {
            var _a;
            (_a = this.root) === null || _a === void 0 ? void 0 : _a.remove();
            this.root = null;
            this.svgcontent = null;
            this.background = null;
            this.container = null;
        };
        this.render = (container) => {
            this.width = workareaManager.width;
            this.height = workareaManager.height;
            this.container = container;
            this.root = document.createElementNS(NS.SVG, 'svg');
            this.root.setAttribute('xlinkns', NS.XLINK);
            this.root.setAttribute('xmlns', NS.SVG);
            this.background = document.createElementNS(NS.SVG, 'svg');
            const backgroundRect = document.createElementNS(NS.SVG, 'rect');
            backgroundRect.setAttribute('x', '0');
            backgroundRect.setAttribute('y', '0');
            backgroundRect.setAttribute('width', '100%');
            backgroundRect.setAttribute('height', '100%');
            backgroundRect.setAttribute('fill', '#fff');
            backgroundRect.setAttribute('stroke', '#000');
            backgroundRect.setAttribute('stroke-width', '1');
            backgroundRect.setAttribute('vector-effect', 'non-scaling-stroke');
            this.background.classList.add(styles.background);
            this.background.appendChild(backgroundRect);
            const canvasGrid = document.querySelector('#canvasGrid');
            if (canvasGrid)
                this.background.appendChild(canvasGrid.cloneNode(true));
            this.root.appendChild(this.background);
            this.container.appendChild(this.root);
            this.initPassThroughContainer();
            const svgcontent = document.getElementById('svgcontent');
            if (svgcontent) {
                this.svgcontent = svgcontent.cloneNode(true);
                this.svgcontent.id = '#pass-through-svgcontent';
                this.root.appendChild(this.svgcontent);
            }
            this.resetView();
            const wheelHandler = wheelEventHandlerGenerator(() => this.zoomRatio, this.zoom, {
                maxZoom: 10,
                getCenter: (e) => { var _a, _b; return ({ x: (_a = e.layerX) !== null && _a !== void 0 ? _a : e.clientX, y: (_b = e.layerY) !== null && _b !== void 0 ? _b : e.clientY }); },
            });
            this.container.addEventListener('wheel', wheelHandler);
            if (navigator.maxTouchPoints > 1) {
                touchEvents.setupCanvasTouchEvents(this.container, this.container, () => { }, () => { }, () => { }, () => { }, this.zoom);
            }
        };
        this.initPassThroughContainer = () => {
            this.passThroughContainer = document.createElementNS(NS.SVG, 'svg');
            this.passThroughContainer.setAttribute('viewBox', `0 0 ${this.width} ${this.height}`);
            this.passThroughContainer.classList.add(styles.passthrough);
            this.background.appendChild(this.passThroughContainer);
            this.passThroughSeparator = document.createElementNS(NS.SVG, 'g');
            this.passThroughSeparator.classList.add(styles.separator);
            this.passThroughContainer.appendChild(this.passThroughSeparator);
            this.passThroughGuideStart = document.createElementNS(NS.SVG, 'path');
            this.passThroughGuideStart.classList.add(styles.guide);
            this.passThroughContainer.appendChild(this.passThroughGuideStart);
            this.passThroughGuideEnd = document.createElementNS(NS.SVG, 'path');
            this.passThroughGuideEnd.classList.add(styles.guide);
            this.passThroughContainer.appendChild(this.passThroughGuideEnd);
        };
        this.zoom = (zoomRatio, staticPoint) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
            const targetZoom = Math.max(0.05, zoomRatio);
            const oldZoomRatio = this.zoomRatio;
            this.zoomRatio = targetZoom;
            const w = this.width * targetZoom;
            const h = this.height * targetZoom;
            const rootW = w * this.canvasExpansion;
            const rootH = h * this.canvasExpansion;
            const expansionRatio = (this.canvasExpansion - 1) / 2;
            const x = this.width * targetZoom * expansionRatio;
            const y = this.height * targetZoom * expansionRatio;
            (_a = this.root) === null || _a === void 0 ? void 0 : _a.setAttribute('x', x.toString());
            (_b = this.root) === null || _b === void 0 ? void 0 : _b.setAttribute('y', y.toString());
            (_c = this.root) === null || _c === void 0 ? void 0 : _c.setAttribute('width', rootW.toString());
            (_d = this.root) === null || _d === void 0 ? void 0 : _d.setAttribute('height', rootH.toString());
            (_e = this.background) === null || _e === void 0 ? void 0 : _e.setAttribute('x', x.toString());
            (_f = this.background) === null || _f === void 0 ? void 0 : _f.setAttribute('y', y.toString());
            (_g = this.background) === null || _g === void 0 ? void 0 : _g.setAttribute('width', w.toString());
            (_h = this.background) === null || _h === void 0 ? void 0 : _h.setAttribute('height', h.toString());
            (_j = this.svgcontent) === null || _j === void 0 ? void 0 : _j.setAttribute('x', x.toString());
            (_k = this.svgcontent) === null || _k === void 0 ? void 0 : _k.setAttribute('y', y.toString());
            (_l = this.svgcontent) === null || _l === void 0 ? void 0 : _l.setAttribute('width', w.toString());
            (_m = this.svgcontent) === null || _m === void 0 ? void 0 : _m.setAttribute('height', h.toString());
            // eslint-disable-next-line no-param-reassign
            staticPoint = staticPoint !== null && staticPoint !== void 0 ? staticPoint : {
                x: this.container.clientWidth / 2,
                y: this.container.clientHeight / 2,
            };
            const oldScroll = { x: this.container.scrollLeft, y: this.container.scrollTop };
            const zoomChanged = targetZoom / oldZoomRatio;
            this.container.scrollLeft = (oldScroll.x + staticPoint.x) * zoomChanged - staticPoint.x;
            this.container.scrollTop = (oldScroll.y + staticPoint.y) * zoomChanged - staticPoint.y;
            zoomBlockEventEmitter.emit('UPDATE_ZOOM_BLOCK');
        };
        this.zoomIn = (ratio = 1.1) => {
            this.zoom(this.zoomRatio * ratio);
        };
        this.zoomOut = (ratio = 1.1) => {
            this.zoom(this.zoomRatio / ratio);
        };
        this.resetView = () => {
            const { width, height } = this;
            const { clientWidth, clientHeight } = this.container;
            const workareaToDimensionRatio = Math.min(clientWidth / width, clientHeight / height);
            const zoomLevel = workareaToDimensionRatio * 0.95;
            const workAreaWidth = width * zoomLevel;
            const workAreaHeight = height * zoomLevel;
            const offsetX = (clientWidth - workAreaWidth) / 2;
            const offsetY = (clientHeight - workAreaHeight) / 2;
            this.zoom(zoomLevel);
            const x = parseFloat(this.background.getAttribute('x'));
            const y = parseFloat(this.background.getAttribute('y'));
            const defaultScroll = {
                x: (x - offsetX) / zoomLevel,
                y: (y - offsetY) / zoomLevel,
            };
            this.container.scrollLeft = defaultScroll.x * zoomLevel;
            this.container.scrollTop = defaultScroll.y * zoomLevel;
        };
        this.setPassThroughHeight = (val) => {
            var _a, _b;
            this.passThroughHeight = val;
            if (this.passThroughSeparator) {
                this.passThroughSeparator.innerHTML = '';
                for (let i = 0; i < Math.ceil(this.height / this.passThroughHeight); i += 1) {
                    const start = i * this.passThroughHeight;
                    const end = Math.min(start + this.passThroughHeight, this.height);
                    const line = document.createElementNS(NS.SVG, 'line');
                    line.setAttribute('x1', '-50');
                    line.setAttribute('x2', (this.width + 50).toString());
                    line.setAttribute('y1', end.toString());
                    line.setAttribute('y2', end.toString());
                    this.passThroughSeparator.appendChild(line);
                    const text = document.createElementNS(NS.SVG, 'text');
                    text.setAttribute('x', '-50');
                    text.setAttribute('y', ((start + end) / 2).toString());
                    text.textContent = `Work Area ${i + 1}`;
                    this.passThroughSeparator.appendChild(text);
                }
            }
            (_a = this.passThroughGuideEnd) === null || _a === void 0 ? void 0 : _a.setAttribute('y1', this.passThroughHeight.toString());
            (_b = this.passThroughGuideEnd) === null || _b === void 0 ? void 0 : _b.setAttribute('y2', this.passThroughHeight.toString());
        };
        this.setGuideMark = (show, x, width) => {
            if (!show) {
                this.passThroughGuideStart.style.display = 'none';
                this.passThroughGuideEnd.style.display = 'none';
            }
            else {
                this.passThroughGuideStart.style.display = 'block';
                this.passThroughGuideEnd.style.display = 'block';
                const left = (x - width / 2).toFixed(2);
                const right = (x + width / 2).toFixed(2);
                const halfHeight = width / Math.sqrt(3);
                const startMid = 0;
                const startTop = (startMid - halfHeight).toFixed(2);
                const startBottom = (startMid + halfHeight).toFixed(2);
                const endMid = this.passThroughHeight;
                const endTop = (endMid - halfHeight).toFixed(2);
                const endBottom = (endMid + halfHeight).toFixed(2);
                this.passThroughGuideStart.setAttribute('d', `M ${left} ${startMid} L ${right} ${startTop} L ${right} ${startBottom} L ${left} ${startMid} Z`);
                this.passThroughGuideEnd.setAttribute('d', `M ${left} ${endMid} L ${right} ${endTop} L ${right} ${endBottom} L ${left} ${endMid} Z`);
            }
        };
        /**
         * generateRefImage
         * @param refHeight height of the reference image (px)
         */
        this.generateRefImage = (refHeight, downScale = 5) => __awaiter(this, void 0, void 0, function* () {
            var _a;
            const clonedDefs = (_a = findDefs()) === null || _a === void 0 ? void 0 : _a.cloneNode(true);
            if (clonedDefs) {
                const uses = this.svgcontent.querySelectorAll('use');
                const promises = [];
                uses.forEach((use) => {
                    const href = use.getAttributeNS(NS.XLINK, 'href');
                    if (href) {
                        const symbol = clonedDefs.querySelector(href);
                        const image = symbol === null || symbol === void 0 ? void 0 : symbol.querySelector('image');
                        const imageHref = image === null || image === void 0 ? void 0 : image.getAttribute('href');
                        if (imageHref === null || imageHref === void 0 ? void 0 : imageHref.startsWith('blob:file://')) {
                            promises.push(
                            // eslint-disable-next-line no-async-promise-executor
                            new Promise((resolve) => __awaiter(this, void 0, void 0, function* () {
                                const response = yield fetch(imageHref);
                                const blob = yield response.blob();
                                const reader = new FileReader();
                                reader.onload = () => {
                                    image.setAttribute('href', reader.result);
                                    resolve();
                                };
                                reader.readAsDataURL(blob);
                            })));
                        }
                    }
                });
                yield Promise.allSettled(promises);
            }
            const svgString = `
    <svg
      width="${this.width}"
      height="${this.height}"
      viewBox="0 0 ${this.width} ${this.height}"
      xmlns:svg="http://www.w3.org/2000/svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      ${(clonedDefs === null || clonedDefs === void 0 ? void 0 : clonedDefs.outerHTML) || ''}
      ${this.svgcontent.innerHTML}
    </svg>`;
            const canvas = yield svgStringToCanvas(svgString, Math.round(this.width / downScale), Math.round(this.height / downScale));
            const refImages = [];
            for (let i = 0; i < Math.ceil(this.height / this.passThroughHeight); i += 1) {
                if (i === 0)
                    refImages.push(null);
                else {
                    const y = Math.round((i * this.passThroughHeight - refHeight) / downScale);
                    const height = Math.round(refHeight / downScale);
                    const refCanvas = document.createElement('canvas');
                    refCanvas.width = canvas.width;
                    refCanvas.height = height;
                    const refCtx = refCanvas.getContext('2d');
                    refCtx.drawImage(canvas, 0, y, canvas.width, height, 0, 0, canvas.width, height);
                    refImages.push(refCanvas.toDataURL('image/png'));
                }
            }
            return refImages;
        });
    }
}
const canvasManager = new PassThroughCanvas();
export default canvasManager;
