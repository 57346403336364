import React from 'react';
import { Button, Modal } from 'antd';
import { sprintf } from 'sprintf-js';
import i18n from 'helpers/i18n';
const LANG = i18n.lang.update;
const FirmwareUpdate = ({ deviceName = '', deviceModel = '', currentVersion = '', latestVersion = '', releaseNote = '', onDownload = () => { }, onClose = () => { }, onInstall = () => { }, }) => {
    const handleUpload = () => {
        onInstall();
        onClose();
    };
    const handleDownload = () => {
        onDownload();
        onClose();
    };
    return (React.createElement(Modal, { open: true, centered: true, title: LANG.firmware.caption, onCancel: onClose, footer: [
            React.createElement(Button, { key: "later", onClick: onClose }, LANG.later),
            React.createElement(Button, { key: "upload", onClick: handleUpload }, LANG.upload),
            React.createElement(Button, { key: "download", type: "primary", onClick: handleDownload }, LANG.download),
        ] },
        React.createElement("div", { className: "update-wrapper" },
            React.createElement("article", { className: "update-brief" },
                React.createElement("p", null, sprintf(LANG.firmware.message_pattern_1, deviceName)),
                React.createElement("p", null, sprintf(LANG.firmware.message_pattern_2, deviceModel, latestVersion, currentVersion))),
            React.createElement("h4", { className: "release-note-caption" }, LANG.release_note),
            React.createElement("div", { className: "release-note-content", dangerouslySetInnerHTML: {
                    __html: releaseNote,
                } }))));
};
export default FirmwareUpdate;
