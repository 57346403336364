var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import dialogCaller from 'app/actions/dialog-caller';
import i18n from 'helpers/i18n';
import storage from 'implementations/storage';
import { axiosFluxId } from 'helpers/api/flux-id';
const getRecord = () => storage.get('announcement-record');
const setRecord = (record) => {
    storage.set('announcement-record', record);
};
const setNotShowing = (id) => {
    const record = getRecord();
    setRecord(Object.assign(Object.assign({}, record), { isIgnored: [...record.isIgnored, id] }));
};
const setDefaultRatingRecord = () => {
    const defaultRecord = {
        times: 1,
        isIgnored: [],
    };
    setRecord(defaultRecord);
};
const showAnnouncement = (isNewUser) => __awaiter(void 0, void 0, void 0, function* () {
    const record = getRecord();
    if (record.skip)
        return;
    const lang = i18n.getActiveLang();
    let query = `locale=${lang}&times=${record.times}`;
    if (record.isIgnored.length > 0) {
        query += `&ignored=${record.isIgnored.join(',')}`;
    }
    if (isNewUser) {
        query += `&new_user=${isNewUser}`;
    }
    const res = yield axiosFluxId(`api/beam-studio/announcements?${query}`);
    if (!(res === null || res === void 0 ? void 0 : res.data))
        return;
    const { announcement } = res.data;
    if (announcement) {
        dialogCaller.showAnnouncementDialog(announcement);
    }
});
const init = (isNewUser) => {
    if (!storage.isExisting('announcement-record')) {
        setDefaultRatingRecord();
    }
    else {
        const record = getRecord();
        setRecord(Object.assign(Object.assign({}, record), { times: record.times + 1 }));
    }
    showAnnouncement(isNewUser);
};
export default {
    init,
    setNotShowing,
};
