/* eslint-disable react/require-default-props */
import React from 'react';
import TestState from 'app/constants/connection-test';
import useI18n from 'helpers/useI18n';
import styles from './TestInfo.module.scss';
const TestInfo = ({ testState, connectionCountDown, firmwareVersion = '' }) => {
    const lang = useI18n();
    const t = lang.initialize.connect_machine_ip;
    const renderIpTestInfo = () => {
        if (testState === TestState.NONE)
            return null;
        let status = 'OK ✅';
        if (testState === TestState.IP_TESTING)
            status = '';
        else if (testState === TestState.IP_FORMAT_ERROR) {
            status = `${t.invalid_ip}${t.invalid_format}`;
        }
        else if (testState === TestState.IP_UNREACHABLE)
            status = t.unreachable;
        return React.createElement("div", { id: "ip-test-info", className: styles.info }, `${t.check_ip}... ${status}`);
    };
    const renderConnectionTestInfo = () => {
        if (testState < TestState.CONNECTION_TESTING)
            return null;
        let status = 'OK ✅';
        if (testState === TestState.CONNECTION_TESTING)
            status = `${connectionCountDown}s`;
        else if (testState === TestState.CONNECTION_TEST_FAILED)
            status = 'Fail';
        return (React.createElement("div", { id: "machine-test-info", className: styles.info }, `${t.check_connection}... ${status}`));
    };
    const renderFirmwareVersion = () => {
        if (testState < TestState.CAMERA_TESTING)
            return null;
        return React.createElement("div", { className: styles.info }, `${t.check_firmware}... ${firmwareVersion}`);
    };
    const renderCameraTesting = () => {
        if (testState < TestState.CAMERA_TESTING)
            return null;
        let status = '';
        if (testState === TestState.TEST_COMPLETED)
            status = 'OK ✅';
        else if (testState === TestState.CAMERA_TEST_FAILED)
            status = 'Fail';
        return React.createElement("div", { className: styles.info }, `${t.check_camera}... ${status}`);
    };
    return (React.createElement("div", { className: styles.container },
        renderIpTestInfo(),
        renderConnectionTestInfo(),
        renderFirmwareVersion(),
        renderCameraTesting(),
        testState === TestState.TEST_COMPLETED && (React.createElement("div", { className: styles.info }, t.succeeded_message))));
};
export default TestInfo;
