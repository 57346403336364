import classNames from 'classnames';
import React from 'react';
import i18n from 'helpers/i18n';
import SelectView from 'app/widgets/Select';
const LANG = i18n.lang.beambox.tool_panels;
function OffsetCornerPanel({ cornerType: cornerTypeProps, onValueChange }) {
    const [cornerType, updateCornerType] = React.useState(cornerTypeProps);
    const [isCollapsed, updateIsCollapsed] = React.useState(false);
    const updateOffsetCorner = (val) => {
        onValueChange(val);
        updateCornerType(val);
    };
    const getOffsetCornerText = () => ({
        sharp: LANG._offset.sharp,
        round: LANG._offset.round,
    }[cornerType]);
    const options = [
        {
            value: 'sharp',
            label: LANG._offset.sharp,
            selected: cornerType === 'sharp',
        },
        {
            value: 'round',
            label: LANG._offset.round,
            selected: cornerType === 'round',
        },
    ];
    return (React.createElement("div", { className: "tool-panel" },
        React.createElement("label", { className: "controls accordion" },
            React.createElement("input", { type: "checkbox", className: "accordion-switcher", defaultChecked: true }),
            React.createElement("p", { className: "caption", onClick: () => updateIsCollapsed(!isCollapsed) },
                LANG._offset.corner_type,
                React.createElement("span", { className: "value" }, getOffsetCornerText())),
            React.createElement("div", { className: classNames('tool-panel-body', { collapsed: isCollapsed }) },
                React.createElement("div", { className: "control offset-corner" },
                    React.createElement(SelectView, { id: "select-offset-corner", options: options, onChange: (e) => updateOffsetCorner(e.target.value) }))))));
}
export default OffsetCornerPanel;
