import * as React from 'react';
import classNames from 'classnames';
function isValueValid(value) {
    if (typeof value === 'string') {
        return value.length > 0 && !Number.isNaN(Number(value));
    }
    return true;
}
class SliderControl extends React.Component {
    constructor(props) {
        super(props);
        this.fireChange = (newValue) => {
            const { id, onChange } = this.props;
            onChange(id, newValue);
        };
        this.getValidatedValue = (value) => {
            const { max, min } = this.props;
            const { lastValidValue } = this.state;
            if (!isValueValid(value)) {
                return lastValidValue;
            }
            const validatedValue = Number(value);
            return Math.max(min, Math.min(max, validatedValue));
        };
        this.handleSliderChange = (value) => {
            const { doOnlyOnMouseUp = false } = this.props;
            this.setState({
                inputValue: value,
                sliderValue: value,
                lastValidValue: value,
            }, () => {
                if (!doOnlyOnMouseUp) {
                    this.fireChange(value);
                }
            });
        };
        this.handleSliderMouseUp = (value) => {
            const { doOnlyOnMouseUp = false } = this.props;
            if (doOnlyOnMouseUp) {
                this.fireChange(value);
            }
        };
        this.handleInputBlur = () => {
            const { doOnlyOnBlur = false } = this.props;
            const { lastValidValue, inputValue } = this.state;
            if (isValueValid(inputValue)) {
                const validatedValue = this.getValidatedValue(inputValue);
                if (doOnlyOnBlur) {
                    this.setState({
                        inputValue: validatedValue,
                        sliderValue: validatedValue,
                        lastValidValue: validatedValue,
                    });
                    this.fireChange(validatedValue);
                }
            }
            else {
                this.setState({
                    inputValue: lastValidValue,
                    sliderValue: lastValidValue,
                });
                if (!doOnlyOnBlur) {
                    this.fireChange(lastValidValue);
                }
            }
        };
        this.handleEditValue = (e) => {
            const newValue = e.target.value;
            this.setState({ inputValue: newValue });
            if (isValueValid(newValue)) {
                const { doOnlyOnBlur = false } = this.props;
                const validatedValue = this.getValidatedValue(newValue);
                if (!doOnlyOnBlur) {
                    this.setState({
                        lastValidValue: validatedValue,
                        sliderValue: validatedValue,
                        inputValue: newValue,
                    });
                    this.fireChange(newValue);
                    return;
                }
                this.setState({
                    sliderValue: validatedValue,
                    inputValue: newValue,
                });
            }
        };
        const { default: defaultValue } = this.props;
        this.state = {
            inputValue: defaultValue,
            sliderValue: defaultValue,
            lastValidValue: defaultValue,
        };
    }
    shouldComponentUpdate(nextProps, nextState) {
        const { sliderValue } = this.state;
        const newPropIsDifferent = nextProps.default !== sliderValue;
        const newStateIsDifferent = sliderValue !== nextState.sliderValue;
        return newPropIsDifferent || newStateIsDifferent;
    }
    render() {
        const { id, unit = '', label = '', min, max, step, } = this.props;
        const unitClass = classNames('control', 'pull-right', `unit-${unit}`);
        const { sliderValue, inputValue } = this.state;
        return (React.createElement("div", { className: "controls" },
            React.createElement("div", { className: "label pull-left" }, label),
            React.createElement("div", { className: unitClass },
                React.createElement("div", { className: "slider-container" },
                    React.createElement("input", { className: "slider", type: "range", min: min, max: max, step: step, value: sliderValue, onChange: (e) => this.handleSliderChange(e.target.value), onMouseUp: (e) => this.handleSliderMouseUp(e.target.value) })),
                React.createElement("input", { id: id, type: "text", value: inputValue, onChange: this.handleEditValue, onFocus: this.handleEditValue, onBlur: this.handleInputBlur, onKeyDown: (e) => e.stopPropagation() }))));
    }
}
export default SliderControl;
