var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { Button, Space, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import browser from 'implementations/browser';
import FluxIcons from 'app/icons/flux/FluxIcons';
import isFluxPlusActive from 'helpers/is-flux-plus-active';
import useI18n from 'helpers/useI18n';
import { getCurrentUser, signOut } from 'helpers/api/flux-id';
import { useIsMobile } from 'helpers/system-helper';
import FluxPlusModal from './FluxPlusModal';
import styles from './FluxCredit.module.scss';
const FluxCredit = ({ onClose }) => {
    var _a, _b;
    const LANG = useI18n();
    const lang = LANG.flux_id_login;
    const isMobile = useIsMobile();
    const { email, info } = getCurrentUser();
    return (React.createElement(FluxPlusModal, { onClose: onClose },
        React.createElement("div", { className: styles.content },
            !isMobile && React.createElement("img", { src: "core-img/flux-plus/man-high-five.jpg", alt: lang.login_success }),
            React.createElement("div", { className: styles.head },
                React.createElement("div", { className: styles.title }, lang.login_success),
                React.createElement("div", { className: styles.subtitle }, lang.flux_plus.thank_you)),
            React.createElement("div", { className: styles.info },
                React.createElement("div", null,
                    lang.email,
                    ": ",
                    React.createElement("span", { className: styles.email }, email)),
                ((_a = info === null || info === void 0 ? void 0 : info.subscription) === null || _a === void 0 ? void 0 : _a.is_valid) && (React.createElement("div", null,
                    React.createElement(Tooltip, { title: lang.flux_plus.flux_credit_tooltip },
                        React.createElement(QuestionCircleOutlined, null)),
                    "FLUX Credit:",
                    React.createElement(FluxIcons.FluxCredit, null),
                    React.createElement("span", { className: styles['flux-credit'] }, ((_b = info === null || info === void 0 ? void 0 : info.subscription) === null || _b === void 0 ? void 0 : _b.credit) || 0))),
                React.createElement("div", null,
                    React.createElement(Tooltip, { title: lang.flux_plus.ai_credit_tooltip },
                        React.createElement(QuestionCircleOutlined, null)),
                    "AI Credit:",
                    React.createElement(FluxIcons.AICredit, null),
                    React.createElement("span", { className: styles['ai-credit'] }, (info === null || info === void 0 ? void 0 : info.credit) || 0))),
            React.createElement(Space, { className: styles.footer, direction: "vertical" },
                isFluxPlusActive && (React.createElement(Button, { block: true, type: "primary", onClick: () => browser.open(lang.flux_plus.member_center_url) }, lang.flux_plus.goto_member_center)),
                React.createElement(Button, { block: true, type: "default", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        yield signOut();
                        onClose();
                    }) }, LANG.topbar.menu.sign_out)))));
};
export default FluxCredit;
