// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-widgets-UnitInput-module__input--gMA9-.src-web-app-widgets-UnitInput-module__underline--0JVOS{border:1px solid #e6e6e6;border-width:0 0 1px}.src-web-app-widgets-UnitInput-module__input--gMA9- .src-web-app-widgets-UnitInput-module__unit--vrhSa{margin-left:-21px;font-size:12px;color:#bbb}", "",{"version":3,"sources":["webpack://./src/web/app/widgets/UnitInput.module.scss"],"names":[],"mappings":"AACE,2GACE,wBAAA,CACA,oBAAA,CAGF,uGACE,iBAAA,CACA,cAAA,CACA,UAAA","sourcesContent":[".input {\n  &.underline {\n    border: 1px solid #e6e6e6;\n    border-width: 0 0 1px;\n  }\n\n  .unit {\n    margin-left: -21px;\n    font-size: 12px;\n    color: #bbbbbb;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "src-web-app-widgets-UnitInput-module__input--gMA9-",
	"underline": "src-web-app-widgets-UnitInput-module__underline--0JVOS",
	"unit": "src-web-app-widgets-UnitInput-module__unit--vrhSa"
};
export default ___CSS_LOADER_EXPORT___;
