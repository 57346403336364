var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import eventEmitterFactory from 'helpers/eventEmitterFactory';
import RightPanelController from 'app/views/beambox/Right-Panels/contexts/RightPanelController';
import { getSVGAsync } from 'helpers/svg-editor-helper';
import { PanelType } from 'app/constants/right-panel-types';
import { TutorialCallbacks } from 'app/constants/tutorial-constants';
let svgCanvas;
getSVGAsync((globalSVG) => { svgCanvas = globalSVG.Canvas; });
export const TutorialContext = React.createContext({});
export const eventEmitter = eventEmitterFactory.createEventEmitter();
export class TutorialContextProvider extends React.Component {
    constructor(props) {
        super(props);
        this.handleCallback = (callbackId) => __awaiter(this, void 0, void 0, function* () {
            if (callbackId === TutorialCallbacks.SELECT_DEFAULT_RECT)
                this.selectDefaultRect();
            else if (callbackId === TutorialCallbacks.SCROLL_TO_PARAMETER)
                yield this.scrollToParameterSelect();
            else if (callbackId === TutorialCallbacks.SCROLL_TO_ADD_LAYER)
                this.scrollToAddLayerButton();
            else
                console.log('Unknown callback id', callbackId);
        });
        this.selectDefaultRect = () => {
            if (this.defaultRect) {
                this.clearDefaultRect();
            }
            const defaultRect = svgCanvas.addSvgElementFromJson({
                element: 'rect',
                curStyles: false,
                attr: {
                    x: -1000,
                    y: -1000,
                    width: 100,
                    height: 100,
                    stroke: '#000',
                    id: svgCanvas.getNextId(),
                    'fill-opacity': 0,
                    opacity: 1,
                },
            });
            this.defaultRect = defaultRect;
            svgCanvas.selectOnly([defaultRect], true);
            RightPanelController.setPanelType(PanelType.Object);
        };
        this.scrollToParameterSelect = () => __awaiter(this, void 0, void 0, function* () {
            var _a;
            RightPanelController.setPanelType(PanelType.Layer);
            yield new Promise((resolve) => setTimeout(resolve, 100));
            (_a = document.querySelector('#layer-parameters')) === null || _a === void 0 ? void 0 : _a.scrollIntoView();
        });
        this.scrollToAddLayerButton = () => {
            RightPanelController.setPanelType(PanelType.Layer);
            document.querySelector('#layer-and-laser-panel').scrollTop = 0;
        };
        this.clearDefaultRect = () => {
            if (this.defaultRect) {
                this.defaultRect.remove();
                svgCanvas.clearSelection();
                RightPanelController.setPanelType(PanelType.Layer);
            }
        };
        this.handleNextStep = () => __awaiter(this, void 0, void 0, function* () {
            const { currentStep } = this.state;
            const { dialogStylesAndContents, onClose } = this.props;
            if (dialogStylesAndContents[currentStep].callback) {
                console.log(dialogStylesAndContents[currentStep].callback);
                yield this.handleCallback(dialogStylesAndContents[currentStep].callback);
            }
            if (currentStep + 1 < dialogStylesAndContents.length) {
                this.setState({ currentStep: currentStep + 1 });
            }
            else {
                onClose();
            }
        });
        this.getNextStepRequirement = (response) => {
            const { currentStep } = this.state;
            const { dialogStylesAndContents } = this.props;
            const { nextStepRequirement } = dialogStylesAndContents[currentStep];
            response.nextStepRequirement = nextStepRequirement;
        };
        this.state = {
            currentStep: 0,
        };
        eventEmitter.on('HANDLE_NEXT_STEP', this.handleNextStep.bind(this));
        eventEmitter.on('GET_NEXT_STEP_REQUIREMENT', this.getNextStepRequirement.bind(this));
    }
    componentWillUnmount() {
        eventEmitter.removeAllListeners();
        this.clearDefaultRect();
    }
    render() {
        const { hasNextButton, dialogStylesAndContents, children, } = this.props;
        const { currentStep, } = this.state;
        const { handleNextStep, } = this;
        return (React.createElement(TutorialContext.Provider, { value: {
                hasNextButton,
                dialogStylesAndContents,
                currentStep,
                handleNextStep,
            } }, children));
    }
}
