var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import communicator from 'implementations/communicator';
import i18n from 'helpers/i18n';
import isWeb from 'helpers/is-web';
import { selectUsbDevice } from 'app/components/dialogs/UsbDeviceSelector';
const askForPermission = () => __awaiter(void 0, void 0, void 0, function* () {
    if (isWeb()) {
        try {
            const stream = yield navigator.mediaDevices.getUserMedia({ video: true });
            stream.getTracks().forEach((track) => {
                track.stop();
            });
            return true;
        }
        catch (error) {
            return false;
        }
    }
    const res = communicator.sendSync('ASK_FOR_PERMISSION', 'camera');
    return res;
});
const listDevices = () => __awaiter(void 0, void 0, void 0, function* () {
    let devices = yield navigator.mediaDevices.enumerateDevices();
    devices = devices.filter((device) => device.kind === 'videoinput' && device.label.startsWith('USB'));
    return devices;
});
const getDevice = (id) => __awaiter(void 0, void 0, void 0, function* () {
    const devices = yield listDevices();
    if (devices.length === 0)
        return null;
    if (devices.length === 1)
        return devices[0];
    if (id) {
        const device = devices.find((val) => val.deviceId === id);
        if (device)
            return device;
    }
    return selectUsbDevice(devices, listDevices);
});
export class WebCamConnection {
    /**
     *
     * @param opts
     * @param opts.video - The video element to display the webcam feed
     * @param opts.deviceId - The device ID of the webcam to connect to,
     *  if not provided, the user will be prompted to select a device
     * @param opts.width - The width of the video feed
     * @param opts.height - The height of the video feed
     */
    constructor({ video, width = 2400, height = 1600, }) {
        this.ended = false;
        this.connect = (deviceId) => __awaiter(this, void 0, void 0, function* () {
            var _a;
            if (deviceId === void 0) { deviceId = (_a = this.device) === null || _a === void 0 ? void 0 : _a.deviceId; }
            const t = i18n.lang.web_cam;
            const permission = yield askForPermission();
            if (!permission) {
                throw new Error(t.no_permission);
            }
            const device = yield getDevice(deviceId);
            if (!device) {
                throw new Error(t.no_device);
            }
            this.device = device;
            this.stream = yield navigator.mediaDevices.getUserMedia({
                video: { deviceId: device.deviceId, width: this.width, height: this.height },
            });
            if (!this.stream)
                return false;
            const videoTracks = this.stream.getVideoTracks();
            if (videoTracks.length === 0)
                return false;
            videoTracks[0].addEventListener('ended', () => {
                console.log('Camera stream ended');
                this.disconnectWebcam();
                if (!this.ended) {
                    this.connect(this.device.deviceId);
                    this.reconnectInterval = setInterval(() => {
                        this.connect(this.device.deviceId);
                    }, 3000);
                }
            });
            this.video.srcObject = this.stream;
            yield this.video.play();
            clearInterval(this.reconnectInterval);
            return true;
        });
        this.getPicture = ({ flip = true } = {}) => __awaiter(this, void 0, void 0, function* () {
            const canvas = document.createElement('canvas');
            canvas.width = this.video.videoWidth;
            canvas.height = this.video.videoHeight;
            const context = canvas.getContext('2d');
            if (flip) {
                context.save();
                context.translate(canvas.width, canvas.height);
                context.scale(-1, -1);
            }
            context.drawImage(this.video, 0, 0, canvas.width, canvas.height);
            context.restore();
            return new Promise((resolve) => {
                canvas.toBlob((blob) => {
                    resolve(blob);
                });
            });
        });
        this.disconnectWebcam = () => {
            var _a;
            this.video.pause();
            (_a = this.stream) === null || _a === void 0 ? void 0 : _a.getTracks().forEach((track) => {
                track.stop();
            });
            this.video.srcObject = null;
        };
        this.end = () => {
            this.ended = true;
            clearInterval(this.reconnectInterval);
            this.disconnectWebcam();
        };
        this.video = video || document.createElement('video');
        this.width = width;
        this.height = height;
    }
}
const connectWebcam = (opts = {}) => __awaiter(void 0, void 0, void 0, function* () {
    const { timeout = 5000 } = opts;
    const connection = new WebCamConnection(opts);
    const start = Date.now();
    let error;
    while (Date.now() - start < timeout) {
        try {
            // eslint-disable-next-line no-await-in-loop
            const res = yield connection.connect(opts.deviceId);
            if (res)
                return connection;
        }
        catch (err) {
            console.error(err);
            error = err;
        }
        // eslint-disable-next-line no-await-in-loop
        yield new Promise((resolve) => setTimeout(resolve, 500));
    }
    connection.end();
    if (error)
        throw error;
    return null;
});
export default { connectWebcam, getDevice };
