var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import EventEmitter from 'eventemitter3';
import arrayBuffer from 'helpers/arrayBuffer';
import Websocket from 'helpers/websocket';
class CameraTransformAPI extends EventEmitter {
    constructor() {
        super();
        this.setFisheyeParam = (param) => __awaiter(this, void 0, void 0, function* () {
            const data = JSON.stringify(param, (key, val) => {
                if (typeof val === 'number') {
                    return Math.round(val * 1e6) / 1e6;
                }
                return val;
            });
            return new Promise((resolve, reject) => {
                this.removeCommandListeners();
                this.setDefaultErrorResponse(reject);
                this.setDefaultFatalResponse(reject);
                this.on('message', (response) => {
                    const { status } = response;
                    if (status === 'ok') {
                        this.removeCommandListeners();
                        resolve(true);
                    }
                    else {
                        console.error('Failed to set fisheye matrix', response);
                        resolve(false);
                    }
                });
                this.ws.send(`set_fisheye_matrix ${data}`);
            });
        });
        this.setFisheyeGrid = (grid) => __awaiter(this, void 0, void 0, function* () {
            const data = JSON.stringify(grid);
            return new Promise((resolve, reject) => {
                this.removeCommandListeners();
                this.setDefaultErrorResponse(reject);
                this.setDefaultFatalResponse(reject);
                this.on('message', (response) => {
                    const { status } = response;
                    if (status === 'ok') {
                        this.removeCommandListeners();
                        resolve(true);
                    }
                    else {
                        console.error('Failed to set fisheye matrix', response);
                        resolve(false);
                    }
                });
                this.ws.send(`set_fisheye_grid ${data}`);
            });
        });
        this.transformImage = (image) => __awaiter(this, void 0, void 0, function* () {
            const data = yield arrayBuffer(image);
            return new Promise((resolve, reject) => {
                this.removeCommandListeners();
                this.setDefaultErrorResponse(reject);
                this.setDefaultFatalResponse(reject);
                this.on('message', (response) => __awaiter(this, void 0, void 0, function* () {
                    if (response.status === 'continue') {
                        this.ws.send(data);
                    }
                    if (response instanceof Blob) {
                        this.removeCommandListeners();
                        resolve(response);
                    }
                }));
                this.ws.send(`transform_image ${data.byteLength}`);
            });
        });
        this.close = () => {
            this.ws.close(false);
        };
        this.ws = Websocket({
            method: 'camera-transform',
            onMessage: (data) => {
                this.emit('message', data);
            },
            onError: (response) => {
                this.emit('error', response);
            },
            onFatal: (response) => {
                this.emit('fatal', response);
            },
        });
    }
    isAlive() {
        return this.ws.currentState === 1;
    }
    removeCommandListeners() {
        this.removeAllListeners('message');
        this.removeAllListeners('error');
        this.removeAllListeners('fatal');
    }
    setDefaultErrorResponse(reject, timeoutTimer) {
        this.on('error', (response) => {
            if (timeoutTimer)
                clearTimeout(timeoutTimer);
            this.removeCommandListeners();
            reject(response.info);
        });
    }
    setDefaultFatalResponse(reject, timeoutTimer) {
        this.on('fatal', (response) => {
            if (timeoutTimer)
                clearTimeout(timeoutTimer);
            this.removeCommandListeners();
            console.log(response);
            if (response.error) {
                if (response.error.join)
                    reject(response.error.join(''));
                else
                    reject(response.error);
            }
            else {
                reject();
            }
        });
    }
}
export default CameraTransformAPI;
