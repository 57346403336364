import React, { useContext, useMemo } from 'react';
import configOptions from 'app/constants/config-options';
import useI18n from 'helpers/useI18n';
import ConfigPanelContext from './ConfigPanelContext';
import ConfigSlider from './ConfigSlider';
import ConfigValueDisplay from './ConfigValueDisplay';
import styles from './WhiteInkSettingsModal.module.scss';
// TODO: add test
const WhiteInkMultipass = ({ value, hasMultiValue, onChange }) => {
    const MIN_VALUE = 1;
    const MAX_VALUE = 10;
    const lang = useI18n();
    const t = lang.beambox.right_panel.laser_panel;
    const { simpleMode = true } = useContext(ConfigPanelContext);
    const sliderOptions = useMemo(() => (simpleMode ? configOptions.whiteMultipassOptions : null), [simpleMode]);
    return (React.createElement("div", { className: styles.panel },
        React.createElement("span", { className: styles.title }, t.print_multipass),
        React.createElement(ConfigValueDisplay, { inputId: 'white-multipass-input', type: 'modal', max: MAX_VALUE, min: MIN_VALUE, value: value, hasMultiValue: hasMultiValue, onChange: onChange, options: sliderOptions }),
        React.createElement(ConfigSlider, { id: 'white-multipass-slider', max: MAX_VALUE, min: MIN_VALUE, value: value, onChange: onChange, options: sliderOptions })));
};
export default WhiteInkMultipass;
