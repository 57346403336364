import NS from 'app/constants/namespaces';
const findDefs = () => {
    let svgElement = document.getElementById('svg_defs');
    if (!svgElement) {
        const svgCanvas = document.getElementById('svgcanvas');
        const svgdoc = svgCanvas.ownerDocument;
        svgElement = svgdoc.createElementNS(NS.SVG, 'svg');
        svgElement.setAttribute('id', 'svg_defs');
        svgCanvas.appendChild(svgElement);
    }
    const res = svgElement.getElementsByTagNameNS(NS.SVG, 'defs');
    let defs;
    if (res.length > 0) {
        [defs] = res;
    }
    else {
        defs = svgElement.ownerDocument.createElementNS(NS.SVG, 'defs');
        svgElement.insertBefore(defs, svgElement.firstChild);
    }
    return defs;
};
export default findDefs;
