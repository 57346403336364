// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-widgets-FullWindowPanel-Footer-module__footer--06nqu{display:flex;justify-content:flex-end}.src-web-app-widgets-FullWindowPanel-Footer-module__footer--06nqu.src-web-app-widgets-FullWindowPanel-Footer-module__mobile--aukdO{padding:0 12px 12px 12px}.src-web-app-widgets-FullWindowPanel-Footer-module__footer--06nqu:not(.src-web-app-widgets-FullWindowPanel-Footer-module__mobile--aukdO){padding:12px 24px;border-top:1px solid #ddd}", "",{"version":3,"sources":["webpack://./src/web/app/widgets/FullWindowPanel/Footer.module.scss"],"names":[],"mappings":"AAAA,kEACE,YAAA,CACA,wBAAA,CAEA,mIACE,wBAAA,CAGF,yIACE,iBAAA,CACA,yBAAA","sourcesContent":[".footer {\n  display: flex;\n  justify-content: flex-end;\n\n  &.mobile {\n    padding: 0 12px 12px 12px\n  }\n\n  &:not(.mobile) {\n    padding: 12px 24px;\n    border-top: 1px solid #ddd;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "src-web-app-widgets-FullWindowPanel-Footer-module__footer--06nqu",
	"mobile": "src-web-app-widgets-FullWindowPanel-Footer-module__mobile--aukdO"
};
export default ___CSS_LOADER_EXPORT___;
