export const v2Add = (a, b) => ({
    x: a.x + b.x,
    y: a.y + b.y,
});
export const v2Sum = (...vectors) => {
    const res = { x: 0, y: 0 };
    for (let i = 0; i < vectors.length; i += 1) {
        res.x += vectors[i].x;
        res.y += vectors[i].y;
    }
    return res;
};
export const v2Sub = (a, b) => ({
    x: a.x - b.x,
    y: a.y - b.y,
});
export const v2Length = (v) => Math.hypot(v.x, v.y);
export const v2Normalize = (v) => {
    const len = v2Length(v);
    return len >= 1e-5 ? {
        x: v.x / len,
        y: v.y / len,
    } : { x: 0, y: 0 };
};
export const v2Negate = (v) => ({ x: -v.x, y: -v.y });
// eslint-disable-next-line max-len
export const v2Scale = (v, scale) => ({ x: v.x * scale, y: v.y * scale });
export const v2Distance = (a, b) => v2Length(v2Sub(a, b));
export const v2Dot = (a, b) => a.x * b.x + a.y * b.y;
export const v2Angle = (a, b) => {
    const la = v2Length(a);
    const lb = v2Length(b);
    return la * lb === 0 ? null : Math.acos(v2Dot(a, b) / (la * lb));
};
