import React, { useContext, useMemo, useState } from 'react';
import { Col, ConfigProvider, Modal, Row } from 'antd';
import useI18n from 'helpers/useI18n';
import { ColorRatioModalBlock } from 'app/constants/antd-config';
import { getLayerByName } from 'helpers/layer/layer-helper';
import { PrintingColors } from 'app/constants/color-constants';
import { writeDataLayer } from 'helpers/layer/layer-config-helper';
import ConfigPanelContext from './ConfigPanelContext';
import ModalBlock from './ModalBlock';
// TODO: add test
const ColorRationModal = ({ fullColor, onClose }) => {
    const t = useI18n().beambox.right_panel.laser_panel;
    const { dispatch, selectedLayers, state } = useContext(ConfigPanelContext);
    const [draftValue, setDraftValue] = useState({
        cRatio: state.cRatio,
        mRatio: state.mRatio,
        yRatio: state.yRatio,
        kRatio: state.kRatio,
        printingStrength: state.printingStrength,
    });
    const handleSave = () => {
        const newState = Object.assign({}, state);
        const keys = fullColor ? ['cRatio', 'mRatio', 'yRatio', 'kRatio'] : ['printingStrength'];
        selectedLayers.forEach((layerName) => {
            const layer = getLayerByName(layerName);
            keys.forEach((key) => {
                if (state[key].value !== draftValue[key].value ||
                    state[key].hasMultiValue !== draftValue[key].hasMultiValue) {
                    writeDataLayer(layer, key, draftValue[key].value);
                    newState[key] = draftValue[key];
                }
            });
        });
        dispatch({ type: 'update', payload: newState });
        onClose();
    };
    const handleValueChange = (key, value) => {
        setDraftValue((cur) => (Object.assign(Object.assign({}, cur), { [key]: { value, hasMultiValue: false } })));
    };
    const colorLayer = useMemo(() => state.color.hasMultiValue
        ? undefined
        : {
            [PrintingColors.CYAN]: 'c',
            [PrintingColors.MAGENTA]: 'm',
            [PrintingColors.YELLOW]: 'y',
            [PrintingColors.BLACK]: 'k',
        }[state.color.value] || undefined, [state.color.hasMultiValue, state.color.value]);
    return (React.createElement(Modal, { centered: true, open: true, maskClosable: false, width: fullColor ? 600 : 300, onOk: handleSave, onCancel: onClose, cancelText: t.cancel, okText: t.save, title: t.color_adjustment },
        React.createElement(ConfigProvider, { theme: ColorRatioModalBlock }, fullColor ? (React.createElement(React.Fragment, null,
            React.createElement(Row, { gutter: [10, 0] },
                React.createElement(Col, { span: 12 },
                    React.createElement(ModalBlock, { color: "c", title: "Cyan", label: t.color_strength, value: draftValue.cRatio.value, setValue: (val) => handleValueChange('cRatio', val) })),
                React.createElement(Col, { span: 12 },
                    React.createElement(ModalBlock, { color: "m", title: "Magenta", label: t.color_strength, value: draftValue.mRatio.value, setValue: (val) => handleValueChange('mRatio', val) })),
                React.createElement(Col, { span: 12 },
                    React.createElement(ModalBlock, { color: "y", title: "Yellow", label: t.color_strength, value: draftValue.yRatio.value, setValue: (val) => handleValueChange('yRatio', val) })),
                React.createElement(Col, { span: 12 },
                    React.createElement(ModalBlock, { color: "k", title: "Black", label: t.color_strength, value: draftValue.kRatio.value, setValue: (val) => handleValueChange('kRatio', val) }))))) : (React.createElement(ModalBlock, { label: t.color_strength, value: draftValue.printingStrength.value, setValue: (val) => handleValueChange('printingStrength', val), color: colorLayer })))));
};
export default ColorRationModal;
