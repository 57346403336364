// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-components-dialogs-camera-AdorCalibrationV2-StepElevate-module__container--JNDx4 .src-web-app-components-dialogs-camera-AdorCalibrationV2-StepElevate-module__tab--odUe0{padding:10px 0}.src-web-app-components-dialogs-camera-AdorCalibrationV2-StepElevate-module__container--JNDx4 .src-web-app-components-dialogs-camera-AdorCalibrationV2-StepElevate-module__steps--kfwQ3{margin:0}.src-web-app-components-dialogs-camera-AdorCalibrationV2-StepElevate-module__container--JNDx4 .src-web-app-components-dialogs-camera-AdorCalibrationV2-StepElevate-module__video--YN1yM{width:100%;min-height:225px}", "",{"version":3,"sources":["webpack://./src/web/app/components/dialogs/camera/AdorCalibrationV2/StepElevate.module.scss"],"names":[],"mappings":"AACE,sLACE,cAAA,CAGF,wLACE,QAAA,CAGF,wLACE,UAAA,CACA,gBAAA","sourcesContent":[".container {\n  .tab {\n    padding: 10px 0;\n  }\n\n  .steps {\n    margin: 0;\n  }\n\n  .video {\n    width: 100%;\n    min-height: 225px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "src-web-app-components-dialogs-camera-AdorCalibrationV2-StepElevate-module__container--JNDx4",
	"tab": "src-web-app-components-dialogs-camera-AdorCalibrationV2-StepElevate-module__tab--odUe0",
	"steps": "src-web-app-components-dialogs-camera-AdorCalibrationV2-StepElevate-module__steps--kfwQ3",
	"video": "src-web-app-components-dialogs-camera-AdorCalibrationV2-StepElevate-module__video--YN1yM"
};
export default ___CSS_LOADER_EXPORT___;
