import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, Segmented } from 'antd';
import useI18n from 'helpers/useI18n';
import styles from './StepElevate.module.scss';
const StepElevate = ({ onNext, onBack, onClose }) => {
    const lang = useI18n().calibration;
    const [withPrismLift, setWithPrismLift] = useState(false);
    const videoRef = useRef(null);
    useEffect(() => {
        var _a;
        (_a = videoRef.current) === null || _a === void 0 ? void 0 : _a.load();
    }, [withPrismLift]);
    return (React.createElement(Modal, { width: 400, open: true, centered: true, maskClosable: false, title: lang.elevate_and_cut, className: styles.container, closable: !!onClose, onCancel: () => onClose === null || onClose === void 0 ? void 0 : onClose(false), footer: [
            React.createElement(Button, { key: "back", onClick: onBack }, lang.back),
            React.createElement(Button, { key: "next", type: "primary", onClick: onNext }, lang.start_engrave),
        ] },
        React.createElement("div", { className: styles.tab },
            React.createElement(Segmented, { block: true, options: [{ value: 0, label: lang.without_prism_lift }, { value: 1, label: lang.with_prism_lift }], onChange: (v) => setWithPrismLift(v === 1) })),
        React.createElement("ol", { className: styles.steps },
            React.createElement("li", null, withPrismLift ? lang.elevate_and_cut_step_1_prism_lift : lang.elevate_and_cut_step_1),
            React.createElement("li", null, lang.put_paper_step3)),
        React.createElement("video", { className: styles.video, ref: videoRef, autoPlay: true, loop: true, muted: true },
            React.createElement("source", { src: `video/ador-calibration-2/${withPrismLift ? 'prism-lift' : 'wood'}.webm`, type: "video/webm" }),
            React.createElement("source", { src: `video/ador-calibration-2/${withPrismLift ? 'prism-lift' : 'wood'}.mp4`, type: "video/mp4" }))));
};
export default StepElevate;
