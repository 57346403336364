import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { InputNumber, Slider } from 'antd';
import styles from './ModalBlock.module.scss';
const ModalBlock = ({ title, label, value, setValue, color, max = 200, min = 0, }) => {
    const [display, setDisplay] = useState(value);
    useEffect(() => setDisplay(value), [value]);
    return (React.createElement("div", { className: classNames(styles.block, styles[color]) },
        title && React.createElement("div", null, title),
        React.createElement("div", { className: styles.header },
            React.createElement("span", { className: styles.label }, label),
            React.createElement("span", { className: styles.input },
                React.createElement(InputNumber, { size: "small", value: value, controls: false, min: min, max: max, onChange: setValue }),
                React.createElement("span", { className: styles.unit }, "%"))),
        React.createElement(Slider, { min: min, max: max, step: 1, value: display, onAfterChange: setValue, onChange: (v) => setDisplay(v), tooltip: {
                formatter: (v) => `${v}%`,
            } })));
};
export default ModalBlock;
