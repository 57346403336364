import Account from './account.svg';
import Camera from './camera.svg';
import CloudFile from './cloud-file.svg';
import Document from './document.svg';
import Frame from './frame.svg';
import Go from './go.svg';
import PathPreview from './path-preview.svg';
import Redo from './redo.svg';
import SelectMachine from './select-machine.svg';
import Trash from './trash.svg';
import Undo from './undo.svg';
export default {
    Account,
    Camera,
    CloudFile,
    Document,
    Frame,
    Go,
    PathPreview,
    Redo,
    SelectMachine,
    Trash,
    Undo,
};
