export var Mode;
(function (Mode) {
    Mode["WORKING"] = "WORKING";
    Mode["PREVIEW"] = "PREVIEW";
    Mode["FILE"] = "FILE";
    Mode["FILE_PREVIEW"] = "FILE_PREVIEW";
    Mode["CAMERA"] = "CAMERA";
    Mode["CAMERA_RELOCATE"] = "CAMERA_RELOCATE";
})(Mode || (Mode = {}));
export var ItemType;
(function (ItemType) {
    ItemType["FILE"] = "FILE";
    ItemType["FOLDER"] = "FOLDER";
})(ItemType || (ItemType = {}));
export default {
    Mode,
    ItemType,
};
