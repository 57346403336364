const lang = {
    global: {
        cancel: 'キャンセル',
        back: '戻る',
    },
    general: {
        processing: '処理中...',
        choose_folder: 'フォルダーの選択',
    },
    buttons: {
        next: '次へ',
        back: '戻る',
        done: '完了',
        back_to_beam_studio: 'Beam Studio に戻る',
    },
    topbar: {
        untitled: '無題',
        titles: {
            settings: '環境設定',
        },
        export: 'ゴー',
        preview: 'プレビュー',
        preview_title: 'プレビュー',
        curve_engrave: '3D 曲線',
        task_preview: 'パスプレビュー',
        frame_task: 'フレームプレビュー',
        borderless: '（ボーダーレス）',
        tag_names: {
            rect: '矩形',
            ellipse: '楕円',
            path: 'パス',
            polygon: 'ポリゴン',
            image: 'イメージ',
            text: 'テキスト',
            text_path: 'パステキスト',
            pass_through_object: 'オブジェクトを通過',
            line: 'ライン',
            g: 'グループ',
            multi_select: '複数オブジェクト',
            use: 'インポートされたオブジェクト',
            svg: 'SVGオブジェクト',
            dxf: 'DXFオブジェクト',
            no_selection: '選択なし',
        },
        alerts: {
            start_preview_timeout: '#803 プレビューモードの開始時にタイムアウトが発生しました。マシンまたはBeam Studioを再起動してください。このエラーが続く場合は、この<a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">ガイド</a>に従ってください。',
            fail_to_start_preview: '#803 プレビューモードを開始できません。マシンまたはBeam Studioを再起動してください。このエラーが続く場合は、この<a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">ガイド</a>に従ってください。',
            fail_to_connect_with_camera: '#803 マシンカメラとの接続を確立できませんでした。マシンまたはBeam Studioを再起動してください。このエラーが続く場合は、この<a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">ガイド</a>に従ってください。',
            add_content_first: '最初にオブジェクトを追加してください',
            headtype_mismatch: 'モジュールの種類が正しくありません。',
            headtype_none: 'モジュールが検出されませんでした。',
            headtype_unknown: '不明なモジュールが検出されました。',
            install_correct_headtype: '10W/20Wダイオードレーザーモジュールを正しく取り付けて、フレームを実行するための低レーザーを有効にしてください。',
            door_opened: 'ドアカバーを閉じて、フレームを実行するための低レーザーを有効にしてください。',
            fail_to_get_door_status: 'ドアカバーが閉まっていることを確認して、フレームを実行するための低レーザーを有効にしてください。',
            QcleanScene: '描画をクリアしますか？<br/>これにより、元に戻す履歴も消去されます！',
            power_too_high: 'パワーが高すぎる',
            power_too_high_msg: 'より低いレーザー出力（70％未満）を使用すると、レーザー管の寿命が延びます。\n「了解」と入力して続行します。',
            power_too_high_confirm: '了解',
            pwm_unavailable: '深度モードは、ファームウェアバージョン 4.3.4 / 5.3.4 以上が必要です。今すぐファームウェアを更新しますか？',
            job_origin_unavailable: 'ジョブの原点を設定するには、ファームウェアバージョン4.3.5 / 5.3.5以上が必要です。今すぐファームウェアを更新しますか？',
            job_origin_warning: '現在、「現在位置」を開始地点として使用しています。衝突を避けるために、レーザーヘッドが正しい位置に移動していることを確認してください。',
        },
        hint: {
            polygon: '+ / - キーを押して、辺を増減します。',
        },
        menu: {
            preferences: '好み',
            hide: 'Beam Studioを隠す',
            hideothers: 'その他を隠す',
            service: 'サービス',
            quit: '終了',
            window: 'ウィンドウ',
            minimize: '最小化',
            close: 'ウィンドウを閉じる',
            file: 'ファイル',
            edit: '編集',
            help: 'ヘルプ',
            open: '開く',
            samples: 'サンプル',
            import_hello_beamo: 'beamoの例',
            import_hello_beambox: 'Beamboxの例',
            import_material_testing_old: '材料彫りテスト - クラシック',
            import_material_testing_simple_cut: '材料切りテスト - シンプル',
            import_material_testing_cut: '材料切りテスト',
            import_material_testing_engrave: '材料彫りテスト',
            import_material_testing_line: '材料ラインテスト',
            import_material_printing_test: '材料プリントテスト',
            import_ador_laser_example: 'Ador レーザーの例',
            import_ador_printing_example_single: 'Ador プリントの例 - シングルカラー',
            import_ador_printing_example_full: 'Ador プリントの例 - フルカラー',
            import_acrylic_focus_probe: 'アクリルフォーカスプローブ - 3mm',
            export_to: 'エクスポート',
            export_flux_task: 'FLUXタスク',
            export_BVG: 'BVG',
            export_SVG: 'SVG',
            export_PNG: 'PNG',
            export_JPG: 'JPG',
            save_scene: 'シーンを保存',
            save_as: '名前を付けて保存',
            save_to_cloud: 'クラウドに保存',
            about_beam_studio: 'Beam Studioについて',
            undo: '元に戻す',
            redo: 'やり直し',
            cut: '切り取り',
            copy: 'コピー',
            paste: '貼り付け',
            paste_in_place: '所定の位置に貼り付け',
            group: 'グループ化',
            ungroup: 'グループ化解除',
            delete: '削除',
            duplicate: '複製',
            offset: 'オフセット',
            scale: '拡大縮小',
            rotate: '回転',
            reset: 'リセット',
            align_center: '中央揃え',
            photo_edit: '画像',
            svg_edit: 'SVG',
            path: 'パス',
            decompose_path: '離散パスを分解する',
            object: 'オブジェクト',
            layer_setting: 'レイヤー',
            layer_color_config: 'カラー設定',
            image_sharpen: 'シャープ化',
            image_crop: 'クロップ',
            image_invert: '色を反転',
            image_stamp: '面取りを生成',
            image_vectorize: 'ベクトル化',
            image_curve: 'カーブ',
            align_to_edges: '頂点にスナップ',
            document_setting: 'ドキュメント設定',
            document_setting_short: 'ドキュメント',
            rotary_setup: 'ロータリー',
            clear_scene: '新しいファイル',
            machines: 'マシン',
            add_new_machine: 'マシン設定',
            help_center: 'ヘルプセンター',
            show_gesture_tutorial: 'タッチスクリーンジェスチャー説明',
            show_start_tutorial: '初めてのチュートリアルを表示',
            show_ui_intro: 'インターフェイスの紹介を表示',
            questionnaire: 'フィードバックアンケート',
            change_logs: '変更ログ',
            contact: 'お問い合わせ',
            tutorial: 'デルタファミリープリントのチュートリアルを開始',
            design_market: 'Design Market',
            forum: 'コミュニティフォーラム',
            software_update: 'ソフトウェア更新',
            bug_report: 'バグ報告',
            dashboard: 'ダッシュボード',
            machine_info: 'マシン情報',
            network_testing: 'ネットワーク設定をテスト',
            commands: 'コマンド',
            update_firmware: 'ファームウェアをアップデート',
            using_beam_studio_api: 'Beam Studio APIを使用する',
            set_as_default: 'デフォルトに設定',
            calibrate_beambox_camera: 'カメラを校正',
            calibrate_printer_module: '印刷モジュールを校正',
            calibrate_ir_module: '赤外線モジュールを校正',
            calibrate_beambox_camera_borderless: 'カメラを校正（ボーダーレス）',
            calibrate_diode_module: 'ダイオードレーザーモジュールを校正',
            manage_account: 'アカウントを管理する',
            sign_in: 'サインイン',
            sign_out: 'サインアウト',
            account: 'アカウント',
            my_account: 'マイアカウント',
            download_log: 'ログをダウンロード',
            download_log_canceled: 'ログのダウンロードがキャンセルされました',
            download_log_error: '不明のエラーが発生しました、後ほど再試行してください',
            keyboard_shortcuts: 'ショートカットキー',
            log: {
                network: 'ネットワーク',
                hardware: 'ハードウェア',
                discover: 'Discover',
                usb: 'USB',
                usblist: 'USBリスト',
                camera: 'カメラ',
                cloud: 'クラウド',
                player: 'プレイヤー',
                robot: 'ロボット',
            },
            link: {
                help_center: 'https://helpcenter.flux3dp.com/',
                contact_us: 'https://flux3dp.zendesk.com/hc/en-us/requests/new',
                forum: 'https://www.facebook.com/groups/flux.laser/',
                downloads: 'https://flux3dp.com/downloads/',
                beam_studio_api: 'https://github.com/flux3dp/beam-studio/wiki/Beam-Studio-Easy-API',
                design_market: 'https://dmkt.io',
                shortcuts: 'https://support.flux3dp.com/hc/en-us/articles/10003978157455',
            },
            view: '見る',
            zoom_in: 'ズームイン',
            zoom_out: 'ズームアウト',
            fit_to_window: 'ウィンドウサイズに合わせる',
            zoom_with_window: '自動的にウィンドウサイズに合わせる',
            borderless_mode: 'ボーダーレスモード',
            show_grids: 'グリッドを表示',
            show_rulers: '定規を表示',
            show_layer_color: 'レイヤーカラーを表示',
            anti_aliasing: 'アンチエイリアス',
            disassemble_use: '逆アセンブル',
            about: 'Beam Studioについて',
            switch_to_beta: 'ベータリリースに切り替える',
            switch_to_latest: '安定版リリースに切り替える',
            reload_app: 'アプリをリロード',
            recent: '最近使用したファイルを開く',
            update: 'アップデートを確認',
            dev_tool: 'デバッグツール',
            camera_calibration_data: 'カメラキャリブレーションデータ',
            upload_data: 'データをアップロード',
            download_data: 'データをダウンロード',
            tools: {
                title: 'ツール',
                material_test_generator: '材料テストジェネレーター',
                qr_code_generator: 'QRコードジェネレーター',
                boxgen: 'Boxgen',
            },
        },
        select_machine: 'マシンの選択',
    },
    support: {
        no_webgl: 'WebGLはサポートされていません。他のデバイスを使用してください。',
        no_vcredist: '「Visual C++ Redistributable 2015」をインストールしてください。<br/>それはflux3dp.comでダウンロードできます',
    },
    generic_error: {
        UNKNOWN_ERROR: '[UE] Beam Studioを再起動してください',
        OPERATION_ERROR: '[OE] ステータスの競合が発生しました。アクションを再試行してください。',
        SUBSYSTEM_ERROR: '[SE] マシンファームウェアがタスクを実行しているときにエラーが発生しました。マシンを再起動してください',
        UNKNOWN_COMMAND: '[UC] 「マシンファームウェア」をアップデートしてください',
    },
    device_selection: {
        no_beambox: '#801 お使いのマシンがネットワーク上で見つかりません。\n<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/360001683556">このガイド</a>に従って、接続の問題をトラブルシューティングしてください。',
        no_device_web: '#801 マシンのステータスを確認するか、下の「マシン設定」をクリックしてマシンを設定してください。',
        select_usb_device: 'USBデバイスを選択',
    },
    update: {
        update: 'アップデート',
        release_note: 'リリースノート：',
        firmware: {
            caption: 'このマシンのファームウェアアップデートがご利用いただけます',
            message_pattern_1: '「%s」は現在、ファームウェアアップデートの準備ができています。',
            message_pattern_2: '%sファームウェアv%sは現在ご利用いただけます - v%sがあります。',
            latest_firmware: {
                caption: 'マシンのファームウェアアップデート',
                message: 'あなたは最新のマシンファームウェアを使用しています',
                still_update: 'アップデート',
                cant_get_latest: '最新のファームウェアの情報を取得できません。',
            },
            confirm: 'アップロード',
            upload_file: 'ファームウェアのアップロード（*.bin / *.fxfw）',
            update_success: 'ファームウェアアップデートが正常にアップロードされました',
            update_fail: '#822 アップデート失敗',
            too_old_for_web: '現在のファームウェアバージョンはv%sです。\nオンライン版Beam Studioを使用されたい場合、最新バージョンに更新してください。',
            force_update_message: '#814 最新のファームウェアバージョンにアップデートしてください。',
            firmware_too_old_update_by_sdcard: 'ファームウェアバージョンが古すぎます。SDカードを使用してファームウェアを更新してください。',
        },
        software: {
            checking: 'アップデートの確認中',
            switch_version: 'バージョンを切り替える',
            check_update: 'アップデートを確認',
            caption: 'Beam Studioのソフトウェアアップデートがご利用いただけます',
            downloading: 'アップデートのダウンロード中…',
            install_or_not: 'アップデートがダウンロードされました。今すぐ再起動してインストールしますか？',
            switch_or_not: 'がダウンロードされました。今すぐ再起動してインストールしますか？',
            available_update: 'Beam Studio v%s が現在、ご利用いただけます\nv%sがあります。\nアップデートをダウンロードしますか？',
            available_switch: 'Beam Studio v%s が現在、ご利用いただけます\nv%sがあります。\nこのバージョンを切り替えますか？',
            not_found: 'あなたは、最新バージョンのBeam Studioを使用しています。',
            no_response: 'サーバーに接続できませんでした。ネットワーク設定を確認してください。',
            switch_version_not_found: '切り替えられるバージョンが見つかりません',
            yes: 'はい',
            no: 'いいえ',
            update_for_ador: '現在のソフトウェアバージョン %s は古いです。Ador用のBeam Studioの最新バージョンをダウンロードしてください。',
        },
        updating: 'アップデート中…',
        skip: 'このバージョンをスキップ',
        preparing: '準備中…',
        later: '後で',
        download: 'オンラインアップデート',
        cannot_reach_internet: '#823 サーバーにアクセスできません。<br/>インターネット接続を確認してください',
        install: 'インストール',
        upload: 'アップロード',
    },
    topmenu: {
        version: 'バージョン',
        credit: 'Beam Studioの開発は<a target="_blank" href="https://github.com/flux3dp/beam-studio">Beam Studio</a>オープンソースプロジェクトと他の<a target="_blank" href="https://flux3dp.com/credits/">オープンソースソフトウェア</a>の協力で完成されたものです。',
        ok: 'OK',
        file: {
            label: 'ファイル',
            import: 'インポート',
            save_fcode: 'FLUXタスクをエクスポート',
            save_scene: 'シーンを保存',
            save_svg: 'SVGをエクスポート',
            save_png: 'PNGをエクスポート',
            save_jpg: 'JPGをエクスポート',
            converting: '画像に変換中…',
            all_files: 'すべてのファイル',
            svg_files: 'SVG',
            png_files: 'PNG',
            jpg_files: 'JPG',
            scene_files: 'Beam Studioシーン',
            fcode_files: 'FLUXコード',
            clear_recent: '最近開いた項目をクリア',
            path_not_exit: 'このパスは、デスク上にもう存在しないようです。',
        },
        device: {
            download_log_canceled: 'ログのダウンロードをキャンセルしました',
            download_log_error: '不明なエラーが発生しました。後で再試行してください',
            log: {
                usblist: 'USBリスト',
            },
            network_test: 'ネットワークをテスト',
        },
    },
    initialize: {
        // generic strings
        next: '次へ',
        start: '開始',
        skip: 'スキップ',
        cancel: 'キャンセル',
        confirm: '確認する',
        connect: '接続する',
        back: '戻る',
        retry: '再試行する',
        no_machine: '私は今マシンを持っていません。',
        // specific caption/content
        select_language: '言語を選択',
        select_machine_type: 'マシンを選択してください',
        select_connection_type: 'どのように接続しますか',
        connection_types: {
            wifi: 'Wi-Fi',
            wired: '有線ネットワーク',
            ether2ether: '直接接続',
            usb: 'USB接続',
        },
        connect_wifi: {
            title: 'Wi-Fiで接続する',
            tutorial1: '1. タッチパネル > [インターネット]> [WiFi 設定] をクリックしてください。',
            tutorial1_ador: '1. タッチパネル > [マシン] > [インターネット]> [WiFi 設定] をクリックしてください。',
            tutorial2: '2. 使用したい Wi-Fi を選択して接続します。',
            what_if_1: '接続したい Wi-Fi が見えません',
            what_if_1_content: '1. Wi-Fi 暗号化モードは WPA2 またはパスワードなしである必要があります。\n2. 暗号化モードは、 Wi-Fi ルーターの管理インターフェイスで設定できます。ルーターが WPA2 をサポートしておらず、適切なルーターの選択についてサポートが必要な場合は、 FLUX サポートにお問い合わせください。',
            what_if_2: 'Wi-Fi が見えません',
            what_if_2_content: '1. Wi-Fi ドングルが完全に差し込まれていることを確認してください。\n2. タッチパネルに無線接続の MAC アドレスがない場合は、 FLUX サポートに連絡してください。\n3. Wi-Fi チャネルは 2.4Ghz である必要があります（ 5Ghz はサポートされていません）。',
        },
        connect_wired: {
            title: '有線ネットワークで接続する',
            tutorial1: '1. マシンをルーターに接続する.',
            tutorial2: '2. 「インターネット」を押して有線ネットワークIPを取得します。',
            tutorial2_ador: '2. 「マシン」 >「インターネット」を押して有線ネットワークIPを取得します。',
            what_if_1: 'IP が空の場合',
            what_if_1_content: '1. イーサネットケーブルが完全に差し込まれていることを確認します。\n2. タッチパネルに有線接続の MAC アドレスがない場合は、 FLUX サポートに連絡してください。',
            what_if_2: 'IP が 169 で始まる場合',
            what_if_2_content: '1. IP アドレスが 169.254 で始まる場合は、 DHCP 設定の問題であるはずです。詳細については、ISP（インターネットサービスプロバイダー）にお問い合わせください。\n2. コンピューターが PPPoE を使用して直接インターネットに接続している場合は、ルーターを使用して PPPoE を使用して接続し、ルーターで DHCP 機能を有効にしてください。',
        },
        connect_ethernet: {
            title: '直接接続',
            tutorial1: '1. イーサネットケーブルでマシンをコンピュータに接続してください。',
            tutorial2_1: '2. この',
            tutorial2_a_text: 'ガイド',
            tutorial2_a_href_mac: 'https://support.flux3dp.com/hc/en-us/articles/360001517076',
            tutorial2_a_href_win: 'https://support.flux3dp.com/hc/en-us/articles/360001507715',
            tutorial2_2: 'に従ってコンピュータをルーターにしてください。',
            tutorial3: '3. 「次へ」をクリックしてください。',
        },
        connect_usb: {
            title: 'USB接続',
            title_sub: '（HEXA と Ador 専用）',
            tutorial1: 'USBケーブルでコンピュータに機器を接続してください。',
            tutorial2: '「次へ」をクリックしてください。',
            turn_off_machine: 'マシンの電源を切ってください。',
            turn_on_machine: 'マシンの電源を入れてください。',
            wait_for_turning_on: '起動プロセスが完了し、メイン画面にアクセスした後、 「次へ」をクリックしてください。',
        },
        connect_machine_ip: {
            check_usb: 'USB接続の確認',
            enter_ip: 'マシン IP を入力してください',
            check_ip: 'IP 可用性の確認しています',
            invalid_ip: 'IPが無効です：',
            invalid_format: '無効な形式',
            starts_with_169254: '169.254で始まる',
            unreachable: 'IP 到達不能',
            check_connection: 'マシン接続の確認しています',
            check_firmware: 'ファームウェアバージョンの確認しています',
            check_camera: 'カメラの可用性を確認しています',
            retry: '再試行',
            succeeded_message: '接続に成功しました 🎉',
            finish_setting: '作り始めます！',
        },
        connecting: '接続中…',
        setting_completed: {
            start: '開始',
            great: 'Beam Studioへようこそ',
            setup_later: 'タイトルバ >「マシン」>「マシン設定」からいつでもマシンをセットアップできます。',
            back: '戻る',
            ok: '作成を開始',
        },
    },
    error_pages: {
        screen_size: 'Please note that Beam Studio may not work optimally on your device. For an optimal experience, it should be a device with a screen width of at least 1024 pixels width.',
    },
    menu: {
        mm: 'mm',
        inches: 'インチ',
    },
    settings: {
        on: 'オン',
        off: 'オフ',
        low: '低',
        high: '普通',
        caption: '設定',
        tabs: {
            general: '一般',
            device: 'マシン',
        },
        ip: 'マシンIPアドレス',
        guess_poke: 'マシンIPを自動的に検索',
        auto_connect: '自動的に唯一のマシンを選択する',
        wrong_ip_format: '間違ったIP形式',
        default_machine: 'デフォルトマシン',
        default_machine_button: '空いている',
        remove_default_machine_button: '削除する',
        confirm_remove_default: 'デフォルトマシンが削除されます。',
        reset: 'Beam Studioをリセット',
        reset_now: 'Beam Studioをリセット',
        confirm_reset: 'Beam Studioのリセットを確認しますか？',
        language: '言語',
        notifications: 'デスクトップ通知',
        check_updates: '自動チェック',
        autosave_enabled: '自動保存',
        autosave_path: '自動保存位置',
        autosave_interval: '保存間隔',
        autosave_number: '自動保存の数',
        autosave_path_not_correct: '指定されたパスが見つかりません',
        preview_movement_speed: 'プレビューの移動速度',
        slow: '遅い',
        medium: '中くらい',
        fast: '速い',
        default_units: 'デフォルト単位',
        default_font_family: 'デフォルトのフォント',
        default_font_style: 'デフォルトのフォントスタイル',
        fast_gradient: '速度の最適化',
        engraving_direction: '彫刻方向',
        top_down: '上から下へ',
        bottom_up: '下から上へ',
        vector_speed_constraint: '速度制限（20mm/s）',
        loop_compensation: 'ループ補正',
        blade_radius: 'Blade Radius',
        blade_precut_switch: 'Blade Precut',
        blade_precut_position: 'Precut Position',
        default_beambox_model: 'デフォルト作業エリア',
        guides_origin: 'ガイドの原点',
        guides: 'ガイド',
        image_downsampling: 'ビットマップのプレビュー品質',
        anti_aliasing: 'アンチエイリアス',
        continuous_drawing: '連続描画',
        trace_output: 'ベクトル化/トレース画像出力',
        single_object: '単一のオブジェクト',
        grouped_objects: 'グループ化されたオブジェクト',
        simplify_clipper_path: '計算されたパスを最適化',
        enable_low_speed: '低速モードを有効にする',
        enable_custom_backlash: 'カスタムバックラッシュを有効にする',
        auto_switch_tab: 'レイヤーとオブジェクトパネルの自動切り替え',
        custom_preview_height: 'カスタムプレビュー高さ',
        mask: 'ワークエリアのクリッピング',
        text_path_calc_optimization: 'パス計算の最適化',
        font_substitute: 'サポートされていない文字を置き換える',
        font_convert: 'テキストからパスへの変換機能',
        default_borderless_mode: 'オープンボトム既定',
        default_enable_autofocus_module: 'オートフォーカス既定',
        default_enable_diode_module: 'ダイオードレーザー既定',
        diode_offset: 'ダイオードレーザーオフセット',
        autofocus_offset: 'オートフォーカスオフセット',
        diode_one_way_engraving: 'ダイオードレーザー一方向彫刻',
        diode_two_way_warning: '二方向で光を発するので、速度が一段と速くなりますが、レーザー照射位置に誤差が生じる可能性があります。そのため、事前に他の材料でテストしておいでください。',
        share_with_flux: 'Beam Studio Analyticsを共有する',
        none: 'なし',
        close: '閉じる',
        enabled: '有効',
        disabled: '無効',
        cancel: 'キャンセル',
        done: '適用する',
        module_offset_10w: '10Wダイオードレーザーオフセット',
        module_offset_20w: '20Wダイオードレーザーオフセット',
        module_offset_printer: 'プリンターオフセット',
        module_offset_2w_ir: '2W赤外線レーザーオフセット',
        printer_advanced_mode: 'プリンターの高級なモード',
        default_laser_module: 'デフォルトレーザーモジュール',
        low_laser_for_preview: 'フレームプレビューのレーザー',
        groups: {
            general: '一般',
            update: 'ソフトウェア更新',
            connection: '接続',
            autosave: '自動保存',
            camera: 'カメラ',
            editor: 'エディター',
            path: 'パス（線分）',
            engraving: '彫刻（スキャン）',
            mask: 'ワークエリアのクリッピング',
            text_to_path: 'テキスト',
            modules: '拡張機能',
            ador_modules: 'Ador モジュール',
            privacy: 'プライバシー',
        },
        notification_on: 'オン',
        notification_off: 'オフ',
        update_latest: '最新',
        update_beta: 'ベータ',
        help_center_urls: {
            connection: 'https://support.flux3dp.com/hc/en-us/sections/360000302135',
            image_downsampling: 'https://support.flux3dp.com/hc/en-us/articles/360004494995',
            anti_aliasing: 'https://support.flux3dp.com/hc/en-us/articles/360004408956',
            continuous_drawing: 'https://support.flux3dp.com/hc/en-us/articles/360004406496',
            simplify_clipper_path: 'https://support.flux3dp.com/hc/en-us/articles/360004407276',
            fast_gradient: 'https://support.flux3dp.com/hc/en-us/articles/360004496235',
            reverse_engraving: 'https://support.flux3dp.com/hc/en-us/articles/',
            vector_speed_constraint: 'https://support.flux3dp.com/hc/en-us/articles/360004496495',
            loop_compensation: 'https://support.flux3dp.com/hc/en-us/articles/360004408856',
            mask: 'https://support.flux3dp.com/hc/en-us/articles/360004408876',
            font_substitute: 'https://support.flux3dp.com/hc/en-us/articles/360004496575',
            font_convert: 'https://support.flux3dp.com/hc/en-us/articles/9132766761743',
            default_borderless_mode: 'https://support.flux3dp.com/hc/zh-tw/articles/360001104076',
            default_enable_autofocus_module: 'https://support.flux3dp.com/hc/en-us/articles/360001574536',
            default_enable_diode_module: 'https://support.flux3dp.com/hc/en-us/articles/360001568035',
        },
    },
    beambox: {
        tag: {
            g: 'グループ',
            use: 'Import Svg',
            image: '画像',
            text: 'テキスト',
        },
        context_menu: {
            cut: '切り取り',
            copy: 'コピー',
            paste: '貼り付け',
            paste_in_place: '所定の位置に貼り付け',
            duplicate: '複製',
            delete: '削除',
            group: 'グループ化',
            ungroup: 'グループ化解除',
            move_front: '最前に持ってくる',
            move_up: '前に持ってくる',
            move_down: '後に送る',
            move_back: '最後に送る',
        },
        popup: {
            select_import_method: '積層スタイルを選択：',
            select_import_module: 'モジュールを選択：',
            touchpad: 'タッチパッド',
            mouse: 'マウス',
            layer_by_layer: '層',
            layer_by_color: '色',
            nolayer: '単一層',
            loading_image: '画像を読み込んでいます。お待ちください…',
            no_support_text: 'Beam Studioは現在、テキストタグをサポートしていません。インポートする前にテキストをパスに変換してください。',
            speed_too_high_lower_the_quality: 'この解像度で速すぎる速度を使用すると、シェーディング彫刻の品質が低下する場合があります。',
            both_power_and_speed_too_high: 'より低いレーザー出力を使用すると、レーザー管の寿命が延びます。\nまた、この解像度で速度が速すぎると、シェーディング彫刻の品質が低下する場合があります。',
            too_fast_for_path: 'パスオブジェクトを含む層で速すぎる速度を使用すると、切断時に精度が低下する場合があります。\n切断時に 20mm/s より速い速度を使用することはお勧めしません。',
            too_fast_for_path_and_constrain: '次のレイヤー：％s \nベクトルパスオブジェクトが含まれていて、速度が 20mm/sを超えています。\nベクトルパスオブジェクトの切断速度は 20mm/s に制限されます。\nこの制限は[好み]で削除できます。',
            should_update_firmware_to_continue: '#814 ご使用のファームウェアは、Beam Studioの一部の改善をサポートしていません。パフォーマンスとユーザー体験を向上させるには、ファームウェアをアップデートして続行してください。（メニュー>マシン>[お使いのマシン]>ファームウェアのアップデート',
            recommend_downgrade_software: '古いファームウェアバージョンが検出されました。私たちは現在、互換性の問題に積極的に取り組んでいますが、今のところ<a target="_blank" href="https://beamstudio.s3.ap-northeast-1.amazonaws.com/mac/Beam+Studio+1.9.5.dmg">Beam Studio 1.9.5</a>に戻すことをお勧めします。',
            recommend_upgrade_firmware: '古いファームウェアバージョンを検出しました。現在、互換性の問題に積極的に取り組んでいますが、今のところ、最新のファームウェアにアップデートすることをお勧めします。',
            still_continue: '続行',
            more_than_two_object: 'オブジェクトが多すぎます。2つのオブジェクトのみをサポートします',
            not_support_object_type: 'オブジェクトタイプをサポートしていません',
            select_first: '最初にオブジェクトを選択します。',
            select_at_least_two: '2つのオブジェクトを選択して続行します',
            import_file_contain_invalid_path: '#808 インポートされたSVGファイルに無効は画像パスが含まれています。すべての画像ファイルが存在することを確認するか、ファイルに画像を埋め込んでください',
            import_file_error_ask_for_upload: 'インポートされたSVGファイルに失敗しました。バグレポート用のチームを開発するためのファイルを提供してもよろしいですか？',
            upload_file_too_large: '#819 ファイルが大きすぎてアップロードできません。',
            successfully_uploaded: 'ファイルのアップロードに成功しました。',
            upload_failed: '#819 ファイルのアップロードに失敗しました。',
            or_turn_off_borderless_mode: ' または、ボーダーレスモードをオフにします。',
            svg_1_1_waring: 'このSVGファイルのバージョンはv 1.1です。非互換性の問題が発生する可能性があります。',
            svg_image_path_waring: 'このSVGファイルには、ファイルパスからロードされた<image>が含まれています。ロード時に失敗する可能性があります。\nこの可能性を回避するには、SVGをエクスポートするときに埋め込み画像（embed）を使用してください。',
            dxf_version_waring: 'このDXFファイルのバージョンは2013ではありません。非互換性の問題が発生する可能性があります。',
            dont_show_again: '次回から表示しない',
            convert_to_path_fail: 'パスへの変換に失敗しました。',
            save_unsave_changed: '未保存の変更を保存しますか？',
            dxf_bounding_box_size_over: '図面サイズがワークエリア外です。図面をCADソフトウェアの原点に近づけるか、単位が正しく設定されていることを確認してください。',
            progress: {
                uploading: 'アップロード中',
                calculating: '計算中',
            },
            backend_connect_failed_ask_to_upload: '#802 バックエンドに接続しようとすると、エラーが発生し続けます。バグレポートログをアップロードしますか？',
            backend_error_hint: 'バックエンドのエラーのため、機能が正しく動作しない可能性があります。',
            pdf2svg: {
                error_when_converting_pdf: '#824 エラー：PDFをSVGに変換するときのエラー：',
                error_pdf2svg_not_found: '#825 エラー：コマンド pdf2svg が見つかりません。パッケージマネージャーで pdf2svg をインストールしてください（例："yum install pdf2svg" or "apt-get install pdf2svg"）。',
            },
            ungroup_use: 'これにより、インポートされた DXF または SVG がグループ解除されます。ファイルには大量の要素が含まれている可能性があるため、グループ解除に時間がかかる場合があります。続行してもよろしいですか？',
            vectorize_shading_image: 'シェーディング画像はベクトル化に時間がかかり、ノイズが発生しやすくなります。実行する前に画像のシェーディングをオフにしてください。',
            change_workarea_before_preview: '%sのワークエリアが、現在設定されているワークエリアと一致しません。現在のワークエリアを切り替えますか？',
            bug_report: 'バグ報告',
            sentry: {
                title: '一緒にBeamStudioをもっと良くしましょう',
                message: 'エラーが発生したときに、関連情報を開発チームに自動的にアップロードすることに同意しますか？',
            },
            questionnaire: {
                caption: 'アンケートへの記入にご協力ください',
                message: 'Beam Studioを改善するために、アンケートへの記入にご協力ください。',
                unable_to_get_url: 'インターネット経由でアンケートへのリンクを取得できませんでした。インターネット接続を確認してください。',
                no_questionnaire_available: '現在、利用可能なアンケートはありません。',
            },
            facebook_group_invitation: {
                title: 'FLUX公式ユーザーグループへの参加',
                message: '公式Facebookグループに参加して、他のFLUXレーザーユーザーとつながり、FLUXレーザーについて語り合い、レーザーアート作品を共有し、製品に関する最新情報を入手しませんか？ご参加をお待ちしております！',
                join_now: '今すぐ参加する',
                later: '後で参加する',
                already_joined: 'すでに参加済み',
                dont_show_again: '今後、表示しない',
            },
            ai_credit: {
                relogin_to_use: 'この機能を使用するには再度ログインしてください。',
                insufficient_credit: 'クレジットが不足しています',
                insufficient_credit_msg: '%s は使用できません。メンバー センターにアクセスして AI Credit を購入してください。',
                buy_link: 'https://member.flux3dp.com/en-US/credit',
                go: '行く',
            },
            text_to_path: {
                caption: 'テキストからパスへの変換 2.0',
                message: 'Beam Studioに新しいテキストからパスへの変換機能(変換 2.0)が追加されました。これにより、より信頼できる結果が得られます。今すぐ変換を切り替えますか?\n「テキストからパスへの変換」の設定セクションから、後でこの設定を調整することもできます。',
            },
            auto_switch_tab: {
                title: 'レイヤーとオブジェクトパネルの自動切り替え',
                message: 'レイヤーパネルとオブジェクトパネルの間を自動的に切り替える新しいオプションが環境設定に追加されました。このオプションはデフォルトではオフになっています。自動切り替えを今すぐオンにしますか？ <br/>この設定はいつでも環境設定で変更できます。',
            },
        },
        zoom_block: {
            fit_to_window: 'ウィンドウに合わせる',
        },
        time_est_button: {
            calculate: '時間を計算',
            estimate_time: '推定所要時間：',
        },
        left_panel: {
            unpreviewable_area: 'ブラインドエリア',
            diode_blind_area: 'ハイブリッドアドオンのブラインドエリア',
            borderless_blind_area: '非彫刻エリア',
            borderless_preview: 'ボーダーレスモードのカメラプレビュー',
            rectangle: '長方形',
            ellipse: '楕円',
            line: 'ライン',
            image: '画像',
            text: 'テキスト',
            label: {
                cursor: '選択する',
                photo: '画像',
                text: 'テキスト',
                line: 'ライン',
                rect: '長方形',
                oval: '楕円形',
                polygon: '多角形',
                pen: 'ペン',
                shapes: '要素',
                array: '配列',
                preview: 'カメラプレビュー',
                trace: 'トレース画像',
                end_preview: 'プレビューを終了',
                clear_preview: 'プレビューをクリア',
                choose_camera: 'カメラ',
                live_feed: 'ライブフィード',
                adjust_height: '高さ調整',
                qr_code: 'QRコード',
                boxgen: 'Boxgen',
                my_cloud: 'マイクラウド',
                pass_through: 'パススルーモード',
                curve_engraving: {
                    title: '3D 曲線',
                    exit: '終了',
                    select_area: 'エリアを選択',
                    preview_3d_curve: '3Dカーブのプレビュー',
                    clear_area: '選択したエリアをクリア',
                },
            },
        },
        right_panel: {
            tabs: {
                layers: 'レイヤ',
                objects: 'オブジェクト',
                path_edit: 'パス編集',
            },
            layer_panel: {
                layer1: 'レイヤ 1',
                layer_bitmap: 'ビットマップ',
                layer_engraving: '彫る',
                layer_cutting: '切る',
                current_layer: '現在のレイヤー',
                move_elems_to: '移動先レイヤ:',
                notification: {
                    dupeLayerName: '同名のレイヤーが既に存在します。',
                    newName: '新しい名前',
                    enterUniqueLayerName: '新規レイヤの一意な名前を入力してください。',
                    enterNewLayerName: 'レイヤの新しい名前を入力してください。',
                    layerHasThatName: '既に同名が付いています。',
                    QmoveElemsToLayer: "選択した要素をレイヤー '%s' に移動しますか？",
                    moveElemFromPrintingLayerTitle: '選択した要素を %s に移動し、レーザー要素に変換しますか？',
                    moveElemFromPrintingLayerMsg: 'この操作を完了すると、選択した要素の色設定が削除され、%s に設定されます。',
                    moveElemToPrintingLayerTitle: '選択した要素を %s に移動し、印刷要素に変換しますか？',
                    moveElemToPrintingLayerMsg: 'この操作を完了すると、選択した要素の設定が削除され、%s に設定されます。',
                    splitColorTitle: '選択したレイヤーを CMYK レイヤーに展開しますか？',
                    splitColorMsg: 'この操作を続行すると、元のカラーレイヤに戻ることはできなくなります。',
                    mergeLaserLayerToPrintingLayerTitle: 'レイヤーを1つの印刷レイヤーにマージしますか？',
                    mergeLaserLayerToPrintingLayerMsg: 'この手順を完了すると、レーザーレイヤーの設定が削除され、現在のレイヤーに設定されますので、注意してください。',
                    mergePrintingLayerToLaserLayerTitle: 'これらのレイヤーを1つのレーザーレイヤーに統合しますか？',
                    mergePrintingLayerToLaserLayerMsg: 'この手順を完了すると、印刷レイヤーのカラー設定が削除され、現在のレイヤーに設定されますので、注意してください。',
                },
                layers: {
                    layer: 'レイヤ',
                    layers: 'Layers',
                    del: 'レイヤの削除',
                    move_down: 'レイヤを下へ移動',
                    new: '新規レイヤ',
                    rename: 'レイヤの名前を変更',
                    move_up: 'レイヤを上へ移動',
                    dupe: 'レイヤーを複製',
                    lock: 'レイヤーをロック',
                    unlock: '解除する',
                    merge_down: 'マージダウン',
                    merge_all: 'すべてをマージ',
                    merge_selected: '選択したレイヤーをマージ',
                    move_elems_to: '移動先レイヤ:',
                    move_selected: '選択対象を別のレイヤに移動',
                    switchToFullColor: 'フルカラーレイヤーに切り替える',
                    switchToSingleColor: 'シングルカラーレイヤーに切り替える',
                    splitFullColor: 'レイヤーを展開',
                    fullColor: 'フルカラー',
                },
            },
            laser_panel: {
                preset_setting: 'パラメータ設定 （%s）',
                multi_layer: '複数のレイヤ',
                parameters: 'パラメーター',
                strength: '出力',
                pwm_advanced_setting: '深度モードの電力設定',
                pwm_advanced_desc: '深度モードの最小電力を設定してください。',
                pwm_advanced_hint: 'この設定は、深度モードを使用する際のグラデーション画像に特に適用されます。',
                low_power_warning: 'レーザー出力が低い (10% 未満) と、レーザー光が放出されない場合があります。',
                speed: '速度',
                speed_contrain_warning: 'ベクトルパスオブジェクトの切断速度は 20mm/s (0.79in/s) に制限されます。この制限は[好み]で削除できます。',
                low_speed_warning: '低速では、材料が焼き付く可能性があります。',
                repeat: 'パス数',
                advanced: '詳細設定',
                lower_focus: 'フォーカスを下げる',
                by: '',
                stepwise_focusing: 'ステップフォーカシング',
                single_color: '単色',
                lower_focus_desc: '切断性能を向上させるために、フォーカス後に特定の距離だけ焦点高さを下げます。',
                stepwise_focusing_desc: '各パス数の間、オブジェクトの高さに基づいて焦点距離を段階的に下げます。',
                single_color_desc: 'フルカラーレイヤーにのみ適用され、拡張された単色レイヤーでは使用できません。',
                focus_adjustment: 'フォーカスを調整する',
                height: '高さ',
                z_step: 'Z Step',
                diode: 'ダイオードレーザー',
                backlash: 'バックラッシュ',
                ink_saturation: '飽和',
                print_multipass: '複数の印刷',
                white_ink: 'ホワイトインク',
                white_ink_settings: 'ホワイトインク設定',
                color_adjustment: 'カラーバージョン調整',
                color_adjustment_short: 'カラーバージョン',
                halftone: 'ハーフトーン',
                halftone_link: 'https://support.flux3dp.com/hc/en-us/articles/9402670389647',
                color_strength: '強度',
                times: '回',
                cut: 'カット',
                engrave: '彫刻する',
                more: '管理する',
                apply: '適用する',
                cancel: 'キャンセル',
                save: '保存する',
                custom_preset: 'カスタム',
                various_preset: '複数のプリセット',
                module: 'モジュール',
                slider: {
                    regular: '標準',
                    low: '低',
                    very_low: '最小',
                    high: '高',
                    very_high: '最大',
                    slow: '遅い',
                    very_slow: '最遅',
                    fast: '速い',
                    very_fast: '最速',
                },
                dropdown: {
                    parameters: 'パラメーター…',
                    save: '現在のパラメーターを追加',
                    mm: {
                        wood_3mm_cutting: '木材 - 3mm 切断',
                        wood_5mm_cutting: '木材 - 5mm 切断',
                        wood_7mm_cutting: '木材 - 7mm 切断',
                        wood_8mm_cutting: '木材 - 8mm 切断',
                        wood_10mm_cutting: '木材 - 10mm 切断',
                        wood_engraving: '木材 - 彫刻',
                        acrylic_3mm_cutting: 'アクリル - 3mm 切断',
                        acrylic_5mm_cutting: 'アクリル - 5mm 切断',
                        acrylic_8mm_cutting: 'アクリル - 8mm 切断',
                        acrylic_10mm_cutting: 'アクリル - 10mm 切断',
                        acrylic_engraving: 'アクリル - 彫刻',
                        mdf_3mm_cutting: 'ニュージーランドのMDF - 3mm 切断',
                        mdf_5mm_cutting: 'ニュージーランドのMDF - 5mm 切断',
                        mdf_engraving: 'ニュージーランドのMDF - 彫刻',
                        leather_3mm_cutting: 'レザー - 3mm 切断',
                        leather_5mm_cutting: 'レザー - 5mm 切断',
                        leather_engraving: 'レザー - 彫刻',
                        denim_1mm_cutting: 'デニム - 1mm 切断',
                        fabric_3mm_cutting: '布地 - 3mm 切断',
                        fabric_5mm_cutting: '布地 - 5mm 切断',
                        fabric_engraving: '布地 - 彫刻',
                        rubber_bw_engraving: 'ゴム - 彫刻',
                        glass_bw_engraving: 'ガラス - 彫刻',
                        metal_bw_engraving: '金属 - 彫刻',
                        steel_engraving_spray_engraving: '金属 - 彫刻',
                        stainless_steel_bw_engraving_diode: '金属 - 彫刻 (ダイオードレーザー)',
                        gold_engraving: '金 - 彫刻',
                        brass_engraving: '黄銅 - 彫刻',
                        ti_engraving: 'チタン - 彫刻',
                        stainless_steel_engraving: 'ステンレス鋼 - 彫刻',
                        aluminum_engraving: 'アルミニウム - 彫刻',
                        black_acrylic_3mm_cutting: 'ブラックアクリル - 3mm 切断',
                        black_acrylic_5mm_cutting: 'ブラックアクリル - 5mm 切断',
                        black_acrylic_engraving: 'ブラックアクリル - 彫刻',
                        abs_engraving: 'ABSプラスチック - 彫刻',
                        silver_engraving: '銀 - 彫刻',
                        iron_engraving: '鉄 - 彫刻',
                        fabric_printing: '布地 - 印刷',
                        canvas_printing: 'キャンバス - 印刷',
                        cardstock_printing: 'カード紙 - 印刷',
                        wood_printing: '木材 - 印刷',
                        bamboo_printing: '竹 - 印刷',
                        cork_printing: 'コルク - 印刷',
                        flat_stone_printing: '平石 - 印刷',
                        acrylic_printing: 'アクリル - 印刷',
                        pc_printing: 'PC - 印刷',
                        stainless_steel_printing: 'ステンレス鋼 - 印刷',
                        gloss_leather_printing: 'グロス・レザー - 印刷',
                        glass_printing: 'ガラス - 印刷',
                    },
                    inches: {
                        wood_3mm_cutting: "木材 - 0.1'' 切断",
                        wood_5mm_cutting: "木材 - 0.2'' 切断",
                        wood_7mm_cutting: "木材 - 0.3'' 切断",
                        wood_8mm_cutting: "木材 - 0.3'' 切断",
                        wood_10mm_cutting: "木材 - 0.4'' 切断",
                        wood_engraving: '木材 - 彫刻',
                        acrylic_3mm_cutting: "アクリル - 0.1'' 切断",
                        acrylic_5mm_cutting: "アクリル - 0.2'' 切断",
                        acrylic_8mm_cutting: "アクリル - 0.3'' 切断",
                        acrylic_10mm_cutting: "アクリル - 0.4'' 切断",
                        acrylic_engraving: 'アクリル - 彫刻',
                        mdf_3mm_cutting: "ニュージーランドのMDF - 0.1'' 切断",
                        mdf_5mm_cutting: "ニュージーランドのMDF - 0.2'' 切断",
                        mdf_engraving: 'ニュージーランドのMDF - 彫刻',
                        leather_3mm_cutting: "レザー - 0.1'' 切断",
                        leather_5mm_cutting: "レザー - 0.2'' 切断",
                        leather_engraving: 'レザー - 彫刻',
                        denim_1mm_cutting: "デニム - 0.04'' 切断",
                        fabric_3mm_cutting: "布地 - 0.1'' 切断",
                        fabric_5mm_cutting: "布地 - 0.2'' 切断",
                        fabric_engraving: '布地 - 彫刻',
                        rubber_bw_engraving: 'ゴム - 彫刻',
                        glass_bw_engraving: 'ガラス - 彫刻',
                        metal_bw_engraving: '金属 - 彫刻',
                        steel_engraving_spray_engraving: '金属 - 彫刻',
                        stainless_steel_bw_engraving_diode: '金属 - 彫刻 (ダイオードレーザー)',
                        gold_engraving: '金 - 彫刻',
                        brass_engraving: '黄銅 - 彫刻',
                        ti_engraving: 'チタン - 彫刻',
                        stainless_steel_engraving: 'ステンレス鋼 - 彫刻',
                        aluminum_engraving: 'アルミニウム - 彫刻',
                        black_acrylic_3mm_cutting: "ブラックアクリル - 0.1'' 切断",
                        black_acrylic_5mm_cutting: "ブラックアクリル - 0.2'' 切断",
                        black_acrylic_engraving: 'ブラックアクリル - 彫刻',
                        abs_engraving: 'ABSプラスチック - 彫刻',
                        silver_engraving: '銀 - 彫刻',
                        iron_engraving: '鉄 - 彫刻',
                        fabric_printing: '布地 - 印刷',
                        canvas_printing: 'キャンバス - 印刷',
                        cardstock_printing: 'カード紙 - 印刷',
                        wood_printing: '木材 - 印刷',
                        bamboo_printing: '竹 - 印刷',
                        cork_printing: 'コルク - 印刷',
                        flat_stone_printing: '平石 - 印刷',
                        acrylic_printing: 'アクリル - 印刷',
                        pc_printing: 'PC - 印刷',
                        stainless_steel_printing: 'ステンレス鋼 - 印刷',
                        gloss_leather_printing: 'グロス・レザー - 印刷',
                        glass_printing: 'ガラス - 印刷',
                    },
                },
                laser_speed: {
                    text: '速度',
                    unit: 'mm/秒',
                    fast: '高速',
                    slow: '低速',
                    min: 3,
                    max: 300,
                    step: 0.1,
                },
                power: {
                    text: '出力',
                    high: '高',
                    low: '低',
                    min: 1,
                    max: 100,
                    step: 0.1,
                },
                ink_type: {
                    text: 'インクの種類',
                    normal: '普通のインク',
                    UV: 'UVインク',
                },
                para_in_use: 'このパラメーターは使用中です。',
                do_not_adjust_default_para: 'デフォルトのパラメーターは調整できません。',
                existing_name: 'このパラメーター名は使用されています。',
                presets: 'プリセット',
                preset_management: {
                    preset: 'プリセット',
                    title: 'パラメーターを管理する',
                    add_new: '新規追加',
                    lower_focus_by: 'フォーカスを下げる',
                    save_and_exit: '保存して終了',
                    delete: '削除する',
                    reset: 'リセット',
                    sure_to_reset: 'これによりカスタマイズしたパラメータが削除され、すべてのプリセットがリセットされます。続行してもよろしいですか？',
                    show_all: 'すべて表示',
                    laser: 'レーザー',
                    print: '印刷',
                    export: '書き出す',
                    export_preset_title: 'パラメータのエクスポート',
                    import: 'インポート',
                    sure_to_import_presets: 'これによりプリセットの配置が読み込まれ、カスタマイズしたパラメータが上書きされます。続行してもよろしいですか？',
                    new_preset_name: '新しいパラメータ名',
                },
            },
            object_panel: {
                zoom: 'ズーム',
                group: 'グループ化',
                ungroup: 'グループ化解除',
                distribute: '配置',
                hdist: '水平配置',
                vdist: '垂直配置',
                align: '整列',
                left_align: '左に整列',
                center_align: '中央に整列',
                right_align: '右に整列',
                top_align: '上部に整列',
                middle_align: '中間に整列',
                bottom_align: '下部に整列',
                boolean: 'ブール',
                union: 'ユニオン',
                subtract: '引く',
                intersect: '交差',
                difference: '差',
                flip: 'フリップ',
                hflip: '左右反転',
                vflip: '上下反転',
                lock_aspect: '縦横比を固定',
                unlock_aspect: '縦横比をロック解除',
                option_panel: {
                    fill: 'インフィル',
                    rounded_corner: '角丸',
                    sides: '辺の数',
                    font_family: 'フォント',
                    font_style: 'スタイル',
                    font_size: 'サイズ',
                    letter_spacing: '文字間隔',
                    line_spacing: '行間',
                    vertical_text: '縦書き',
                    start_offset: 'テキストオフセット',
                    vertical_align: '整列',
                    text_infill: 'テキストインフィル',
                    path_infill: 'パスインフィル',
                    shading: 'シェーディング',
                    pwm_engraving: '深度モード',
                    pwm_engraving_link: 'https://support.flux3dp.com/hc/en-us/articles/10419884701327',
                    threshold: 'しきい値',
                    threshold_short: 'しきい値',
                    stroke: 'フレーム',
                    stroke_color: 'ボーダの色',
                    stroke_width: '境界線の幅',
                    color: '色',
                },
                actions_panel: {
                    replace_with: '置き換える',
                    replace_with_short: '置き換える',
                    trace: 'ベクトル化',
                    grading: 'カーブ',
                    brightness: '輝度',
                    sharpen: 'シャープ化',
                    crop: 'クロップ',
                    bevel: '面取りを生成',
                    invert: '色を反転',
                    weld_text: 'テキストを結合',
                    convert_to_path: 'パスに変換',
                    fetching_web_font: 'ウェブフォント読み込み中…',
                    uploading_font_to_machine: 'フォントアップロード中…',
                    wait_for_parsing_font: 'フォントの解析中です… 少々お待ちください',
                    offset: 'オフセット',
                    array: '配列',
                    auto_fit: '自動フィット',
                    smart_nest: 'スマートネスト',
                    decompose_path: '離散パスを分解する',
                    disassemble_use: '逆アセンブル',
                    create_textpath: 'パステキスト作成',
                    create_textpath_short: 'パステキスト',
                    detach_path: 'パステキスト作成',
                    detach_path_short: '解散',
                    edit_path: 'パス編集',
                    disassembling: '逆アセンブル中…',
                    ungrouping: 'グループ解除中…',
                    simplify: '簡略化',
                    ai_bg_removal: 'AI 背景リムーバー',
                    ai_bg_removal_short: '背景削除',
                    ai_bg_removal_reminder: 'ボタンを押すと、すぐに 0.2 クレジットが使用されます。続行しますか?',
                    outline: '輪郭描画',
                },
                path_edit_panel: {
                    node_type: 'ノードタイプ',
                    sharp: '鋭い',
                    round: '滑らか',
                    connect: '接続する ',
                    disconnect: '切断する',
                    delete: '削除',
                },
            },
        },
        bottom_right_panel: {
            convert_text_to_path_before_export: 'テキストをパスに変換…',
            retreive_image_data: '画像データを読み出す…',
            export_file_error_ask_for_upload: 'Failed to export task. Are you willing to provide working scene to develop team for bug report?',
        },
        image_trace_panel: {
            apply: '適用する',
            back: '戻る',
            cancel: 'キャンセル',
            next: '次へ',
            brightness: '輝度',
            contrast: 'コントラスト',
            threshold: 'しきい値',
            okay: 'OK',
            tuning: 'パラメーター',
        },
        photo_edit_panel: {
            apply: '適用する',
            back: '戻る',
            cancel: 'キャンセル',
            next: '次へ',
            sharpen: 'シャープにする',
            sharpness: 'シャープネス',
            radius: '半径',
            crop: '切り抜き',
            aspect_ratio: 'アスペクト比',
            original: '元のサイズ',
            free: '任意',
            curve: '曲線',
            start: '開始',
            processing: '処理中',
            invert: '色を反転',
            okay: 'OK',
            compare: '比較する',
            phote_edit: '写真編集',
            brightness_and_contrast: '輝度 / コントラスト',
            brightness: '輝度',
            contrast: 'コントラスト',
            rotary_warped: '回転ゆがみ',
            rotary_warped_link: 'https://support.flux3dp.com/hc/en-us/articles/10828006201103',
            diameter: '直径',
            circumference: '円周',
            warp: 'ゆがみ',
        },
        document_panel: {
            document_settings: 'ドキュメント設定',
            machine: '機械',
            watts: 'ワット',
            workarea: '作業領域',
            rotary_mode: 'ロータリー',
            borderless_mode: 'ボーダーレスモード',
            engrave_dpi: '解像度',
            enable_diode: 'ダイオードレーザー',
            enable_autofocus: 'オートフォーカス',
            extend_workarea: '作業エリアを拡張する',
            mirror: 'ミラー',
            pass_through: 'パススルーモード',
            pass_through_height_desc: '作業エリアを拡張するためにオブジェクトの長さを入力してください。',
            start_position: '開始位置',
            start_from: '開始位置',
            origin: '原点',
            current_position: '現在の位置',
            job_origin: 'ジョブの原点',
            add_on: 'アドオン',
            low: '低',
            medium: '中',
            high: '高',
            ultra: '超高',
            enable: '有効',
            disable: '無効',
            cancel: 'キャンセル',
            save: '保存する',
            notification: {
                changeFromPrintingWorkareaTitle: '印刷レイヤーをレーザーレイヤーに変換しますか？',
            },
        },
        object_panels: {
            wait_for_parsing_font: 'フォントの解析中です… 少々お待ちください',
            text_to_path: {
                font_substitute_pop: 'テキストに現在のフォントではサポートされていない文字が含まれています。<br/>代わりに<strong>%s</strong>を使用しますか?',
                check_thumbnail_warning: 'テキストをパスに解析するときに一部の書体が他のフォントに変更され、一部の文字が正常に変換されないことがあります.\nタスクを送信する前に、プレビュー画像をもう一度確認してください。',
                error_when_parsing_text: 'テキストをパスに変換するときにエラーが発生しました',
                use_current_font: '現在のフォントを使用',
                retry: '後で再試行するか、別のフォントを選択してください',
            },
            lock_desc: '幅と高さの比率を保持（SHIFT）',
        },
        tool_panels: {
            cancel: 'キャンセル',
            confirm: '確認する',
            grid_array: 'グリッド配列を作成',
            array_dimension: '配列の次元',
            rows: '行',
            columns: '列',
            array_interval: '配列の間隔',
            dx: 'X',
            dy: 'Y',
            offset: 'オフセット',
            nest: '整列の最適化',
            _offset: {
                direction: 'オフセット方向',
                inward: '内向き',
                outward: '外向き',
                dist: 'オフセット距離',
                corner_type: 'コーナー',
                sharp: 'シャープ',
                round: 'ラウンド',
                fail_message: 'オブジェクトのオフセットに失敗しました。',
                not_support_message: 'サポートされていないSVGタグを含む選択された要素：\nイメージ、グループ、テキスト、インポートされたオブジェクト。',
            },
            _nest: {
                start_nest: '整列開始',
                stop_nest: '整列停止',
                end: '終了する',
                spacing: '間隔',
                rotations: 'Possible Rotation',
                no_element: '整列できるオブジェクトがありません',
            },
        },
        network_testing_panel: {
            network_testing: 'ネットワーク試験',
            local_ip: 'ローカルIPアドレス：',
            insert_ip: 'ターゲットデバイスのIPアドレス：',
            empty_ip: '#818 最初にターゲットデバイスのIPを入力してください。',
            start: '開始',
            end: '終了',
            testing: 'ネットワークの試験中…',
            invalid_ip: '#818 無効なIPアドレス',
            ip_startswith_169: '#843 マシンのIPアドレスは169.254で始まります',
            connection_quality: '接続品質',
            average_response: '平均応答時間',
            test_completed: 'テストが完了しました',
            test_fail: 'テストに失敗しました',
            cannot_connect_1: '#840 ターゲットIPに接続することができません。',
            cannot_connect_2: '#840 ターゲットIPに接続することができません。ターゲットが同じネットワークにあることを確認してください。',
            network_unhealthy: '#841 接続品質 <　70 または 平均応答時間 > 100ms',
            device_not_on_list: '#842 マシンはリストに含まれていませんが、接続品質 > 70、平均応答時間 <100ms',
            hint_device_often_on_list: 'マシンがリストに見つからないことがよくありますか？',
            link_device_often_on_list: 'https://support.flux3dp.com/hc/en-us/articles/360001841636',
            hint_connect_failed_when_sending_job: 'タスクの送信時に接続に失敗しましたか？',
            link_connect_failed_when_sending_job: 'https://support.flux3dp.com/hc/en-us/articles/360001841656',
            hint_connect_camera_timeout: 'カメラのプレビューを開始するとタイムアウトが発生しますか？',
            link_connect_camera_timeout: 'https://support.flux3dp.com/hc/en-us/articles/360001791895',
            cannot_get_local: 'ローカルIPアドレスへのアクセスに失敗しました。',
            fail_to_start_network_test: '#817 ネットワーク試験を開始できません。',
            linux_permission_hint: 'このエラーは通常、権限が不十分なために発生します。\nターミナルで「sudo beam-studio --no-sandbox」を実行して、権限を取得し、ネットワークテストを実行してください。',
        },
        layer_color_config_panel: {
            layer_color_config: 'レイヤーの色設定',
            color: '色',
            power: '出力',
            speed: '速度',
            repeat: '実行',
            add: '追加する',
            save: '保存する',
            cancel: 'キャンセルする',
            default: 'デフォルトにリセット',
            add_config: '色を追加',
            in_use: 'この色は使用中です。',
            no_input: '16進数の有効なカラーコードを入力してください。',
            sure_to_reset: 'カスタマイズされたパラメータはすべて失われます。本当にデフォルトにリセットしますか？',
            sure_to_delete: 'この色の設定を削除してもよろしいですか？',
        },
        rating_panel: {
            title: 'Beam Studio は好きですか？',
            description: 'Beam Studio が気に入ったら、評価していただければ幸いです。',
            dont_show_again: '次回から表示しない',
            thank_you: 'ありがとうございました',
        },
        svg_editor: {
            unnsupported_file_type: 'ファイルタイプは直接サポートされていません。ファイルをSVGまたはビットマップに変換してください',
            unable_to_fetch_clipboard_img: '画像をクリップボードから転送失敗しました。',
        },
        units: {
            walt: 'W',
            mm: 'mm',
        },
        path_preview: {
            play: '再生',
            pause: '一時停止',
            stop: '停止',
            play_speed: '再生スピード',
            travel_path: '運行軌道',
            invert: '色反転',
            preview_info: 'プレビュー',
            size: 'サイズ',
            estimated_time: '推定合計時間',
            cut_time: '切断時間',
            rapid_time: '快速移動時間',
            cut_distance: '切断距離',
            rapid_distance: '快速移動距離',
            current_position: '現在位置',
            remark: '＊全てのパラメータは推定値であり、ご参考までに。',
            start_here: 'ここから始める',
            end_preview: 'プレビュー終了',
        },
        shapes_panel: {
            title: '要素',
            basic: '基本',
            shape: '形状',
            graphics: 'グラフィックス',
            arrow: '矢印',
            label: 'ラベル',
            decor: 'デコ',
            circular: '円形',
            corner: 'コーナー',
            line: '線',
            photo: '写真',
            ribbon: 'リボン',
            speech: 'スピーチ',
            text: 'テキストフレーム',
            animals: '動物',
            birds: '鳥',
            land: '陸',
            sea: '海',
            holidays: '祝日',
            celebration: 'お祝い',
            CNY: '春節',
            easter: 'イースター',
            halloween: 'ハロウィン',
            valentines: 'バレンタインデー',
            Xmas: 'クリスマス',
            nature: '自然',
            elements: '要素',
            environment: '環境',
            plants: '植物',
            weather: '天気',
        },
        announcement_panel: {
            title: 'お知らせ',
            dont_show_again: '次回から表示しない',
        },
    },
    editor: {
        prespray_area: 'プライミングエリア',
        opacity: 'プレビューの不透明度',
        exposure: 'プレビュー露出',
    },
    flux_id_login: {
        connection_fail: '#847 FLUXメンバーサービスへの接続に失敗しました。',
        login_success: '正常にログインしました。',
        login: 'ログイン',
        unlock_shape_library: 'ログインして形状データベースのロックを解除します。',
        email: 'Eメール',
        password: 'パスワード',
        remember_me: 'ログインしたままにする',
        forget_password: 'パスワードをお忘れの場合',
        register: 'FLUXアカウントを作成する',
        offline: 'オフライン作業',
        work_offline: 'オフライン作業',
        incorrect: 'メールアドレスまたはパスワードが正しくありません。',
        not_verified: 'メールアドレスが未認証です。',
        new_to_flux: 'FLUXは初めてですか？ アカウントの作成してください。',
        signup_url: 'https://id.flux3dp.com/user/login#up',
        lost_password_url: 'https://id.flux3dp.com/user/forgot-password',
        flux_plus: {
            explore_plans: 'FLUX+ プランを見る',
            thank_you: 'ご利用いただきありがとうございます!',
            ai_credit_tooltip: 'AI バックグラウンド除去のため',
            flux_credit_tooltip: 'デザイン マーケットのファイルと AI バックグラウンド除去のため',
            goto_member_center: 'メンバーセンターへ移動',
            access_plus_feature_1: 'アクセスしている機能。',
            access_plus_feature_2: 'この機能。',
            access_plus_feature_note: 'この機能をご利用いただくには FLUX+ メンバーシップが必要です。',
            access_monotype_feature: 'Monotype フォント アドオンがありません。',
            access_monotype_feature_note: 'この機能をご利用いただくには FLUX+ Pro メンバーシップまたは Monotype フォント アドオンが必要です。',
            learn_more: '詳細を見る',
            get_addon: 'アドオンを取得',
            subscribe_now: '今すぐ購読する',
            website_url: 'https://flux3dp.com/subscription',
            member_center_url: 'https://member.flux3dp.com/en-US/subscription',
            features: {
                ai_bg_removal: 'AI背景除去',
                my_cloud: '無制限のクラウドストレージ',
                boxgen: '3Dボックスジェネレーター',
                dmkt: '1000以上のデザインテンプレート',
                monotype: '250以上のプレミアムフォント',
            },
        },
    },
    noun_project_panel: {
        login_first: 'ログインして形状データベースのロックを解除します。',
        enjoy_shape_library: '形状データベースをお楽しみください。',
        shapes: '形',
        elements: '要素',
        recent: '最近使用された',
        search: '搜尋',
        clear: '消去',
        export_svg_title: 'SVGをエクスポートできません',
        export_svg_warning: 'このプロジェクトには、知的財産法によって保護されているオブジェクトが含まれています。したがって、Beam Studioは、エクスポート中にこれらのオブジェクトを自動的に除外します。プロジェクトをBeam Studioシーン（.beam形式）内に保存して、すべてのデータを保持することもできます。それでもエクスポートしますか？',
        learn_more: 'もっと詳しく知る',
    },
    change_logs: {
        change_log: '変更ログ：',
        added: '追加：',
        fixed: '修正：',
        changed: '変更：',
        see_older_version: '以前のバージョンを見る',
        help_center_url: 'https://support.flux3dp.com/hc/en-us/sections/360000421876',
    },
    select_device: {
        select_device: 'デバイスを選択',
        auth_failure: '#811 認証失敗',
        unable_to_connect: '#810 マシンとの安定した接続を構築できません',
    },
    device: {
        pause: '一時停止',
        paused: '一時停止中',
        pausing: '一時停止処理中',
        select_printer: 'プリンターを選択',
        retry: '再試行',
        status: 'ステータス',
        busy: 'ビジー',
        ready: '準備完了',
        reset: 'リセット（Kick）',
        abort: 'アボート',
        start: '開始',
        please_wait: 'お待ちください…',
        quit: '終了する',
        completing: '完了処理中',
        aborted: 'アボートしました',
        completed: '完了済み',
        aborting: 'アボート処理中',
        starting: '開始処理中',
        preparing: '準備中',
        resuming: '再開中',
        scanning: 'スキャン中',
        occupied: '維持中',
        running: '作業中',
        uploading: 'アップロード中',
        processing: '処理中',
        disconnectedError: {
            caption: 'マシンが切断されました',
            message: '%sのネットワークアクセスが利用可能かどうか確認してください',
        },
        unknown: '不明',
        pausedFromError: 'エラーにより一時停止しました',
        model_name: 'モデル名',
        IP: 'IP',
        serial_number: 'マシン番号',
        firmware_version: 'ファームウェアバージョン',
        UUID: 'UUID',
        select: '選択する',
        deviceList: 'マシンリスト',
        disable: '無効にする',
        enable: '有効にする',
        submodule_type: 'モジュール',
        cartridge_serial_number: 'インクカートリッジ製造番号',
        ink_color: 'インキ色',
        ink_type: 'インクタイプ',
        ink_level: 'インク残量',
        close_door_to_read_cartridge_info: 'インク情報にアクセスするには、マシンのドアカバーを閉じてください。',
        cartridge_info_read_failed: 'インクカートリッジが完全に挿入されていることを確認してください。インクを取り出して再度取り付けてみてください。',
        cartridge_info_verification_failed: 'ご使用のインクがFLUX正規品であることを確認してください。',
        toolhead_change: 'ツールヘッドを交換',
    },
    monitor: {
        monitor: 'モニター',
        go: '開始',
        resume: '再開',
        start: '開始',
        pause: '一時停止',
        stop: '停止',
        record: '記録',
        camera: 'カメラ',
        taskTab: 'タスク',
        connecting: '接続しています。お待ちください…',
        HARDWARE_ERROR_MAINBOARD_ERROR: '#401 重大なエラー：メインボードがオフラインです。 FLUXサポートにお問い合わせください。',
        HARDWARE_ERROR_SUBSYSTEM_ERROR: '#402 重大なエラー：サブシステムが応答しません。 FLUXサポートにお問い合わせください。',
        HARDWARE_ERROR_PUMP_ERROR: '#900 水槽にご確認ください。',
        HARDWARE_ERROR_DOOR_OPENED: '#901 続行するにはドアを閉じてください。',
        HARDWARE_ERROR_OVER_TEMPERATURE: '#902 過熱。数分お待ちください。',
        HARDWARE_ERROR_BOTTOM_OPENED: '#903 ボトムカバーオープン。ドアを閉めて続行します。',
        HARDWARE_ERROR_PLATFORM_HOMING_FAILED: '#910 Z軸リセットに失敗しました',
        HARDWARE_ERROR_DRAWER_OPENED: '#911 引き出しが開きました',
        HARDWARE_ERROR_FIRE_DETECTED: '#912 火炎センサー異常',
        HARDWARE_ERROR_AIR_ASSIST_ABNORMAL: '#913 エアフローセンサー異常',
        HARDWARE_ERROR_ROTARY_NOT_DETECTED: '#914 ロータリーモジュールが検出されません',
        HARDWARE_ERROR_HOMING_PULLOFF_FAILED: '#920 Homing Pull-Off Error',
        HARDWARE_ERROR_HEADTYPE_MISMATCH: '#915 正しくないモジュールが検出されました。続行するには正しいモジュールをインストールしてください。',
        HARDWARE_ERROR_HEADTYPE_NONE: '#917 モジュールが検出されません。続行するにはモジュールが正しくインストールされていることを確認してください。',
        HARDWARE_ERROR_HEADTYPE_UNKNOWN: '#918 未知のモジュールが検出されました。続行するには正しいモジュールをインストールしてください。',
        HARDWARE_ERROR_PRINTER_NO_RESPONSE: '#919 プリンターモジュールが応答しません。',
        USER_OPERATION_ROTARY_PAUSE: 'ロータリーモーターに切り替えてください',
        USER_OPERATION_REMOVE_CARTRIDGE: '続行するためにカートリッジを取り外してください。',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_MISMATCH: '#915 正しくないモジュールが検出されました。続行するには正しいモジュールをインストールしてください。',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_NONE: '#917 モジュールが検出されません。続行するにはモジュールが正しくインストールされていることを確認してください。',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_UNKNOWN: '#918 未知のモジュールが検出されました。続行するには正しいモジュールをインストールしてください。',
        USER_OPERATION_CHANGE_TOOLHEAD: '続行するには正しいモジュールをインストールしてください。',
        USER_OPERATION_CHANGE_CARTRIDGE_CARTRIDGE_NOT_DETECTED: '続行するにはカートリッジを挿入してください。',
        USER_OPERATION_CHANGE_CARTRIDGE: '続行するには正しいカートリッジを挿入してください。',
        USER_OPERATION: '続行するためには、デバイスパネルの指示に従ってください。',
        RESOURCE_BUSY: 'マシンはビジーです\n実行されていない場合は、マシンを再起動してください',
        DEVICE_ERROR: '何かがうまくいかなかった\nマシンを再起動してください',
        NO_RESPONSE: '#905 メインボードへの接続中にエラーが発生しました。\nマシンを再起動してください。',
        SUBSYSTEM_ERROR: '#402 重大なエラー：サブシステムが応答しません。 FLUXサポートにお問い合わせください。',
        HARDWARE_FAILURE: '何かがうまくいかなかった\nマシンを再起動してください',
        MAINBOARD_OFFLINE: '#905 メインボードへの接続中にエラーが発生しました。\nマシンを再起動してください。',
        bug_report: 'バグ報告',
        processing: '処理中',
        savingPreview: 'サムネイルの生成中',
        hour: 'h',
        minute: 'm',
        second: 's',
        left: '左',
        temperature: '温度',
        forceStop: '現在のタスクをアボートしますか？',
        upload: 'アップロード',
        download: 'ダウンロード',
        relocate: '再配置',
        cancel: 'キャンセル',
        prepareRelocate: '再配置の準備',
        extensionNotSupported: 'このファイル形式はサポートされていません',
        fileExistContinue: 'ファイルはすでに存在します。ファイルを置き換えますか？',
        confirmFileDelete: 'このファイルを削除してもよろしいですか？',
        ask_reconnect: 'マシンとの接続が切断されました。再接続しますか？',
        task: {
            BEAMBOX: 'レーザー彫刻',
            'N/A': 'フリーモード',
        },
    },
    alert: {
        caption: 'エラー',
        duplicated_preset_name: '重複したプリセット名',
        info: '情報',
        warning: '警告',
        error: 'おっと',
        instruction: 'ヒント',
        oops: 'おっと',
        retry: '再試行',
        abort: 'アボート',
        confirm: '確認する',
        cancel: 'キャンセル',
        close: '閉じる',
        ok: 'OK',
        ok2: 'はい',
        yes: 'はい',
        no: 'いいえ',
        stop: '停止',
        save: '保存する',
        dont_save: '保存しない',
        learn_more: 'もっと詳しく知る',
    },
    caption: {
        connectionTimeout: '接続タイムアウト',
    },
    message: {
        cancelling: 'キャンセル中…',
        connecting: '接続中…',
        connectingMachine: '%sを接続しています…',
        tryingToConenctMachine: 'マシンに接続しようとしています…',
        connected: '接続済み',
        authenticating: '認証中…',
        enteringRawMode: 'RAWモードの準備中…',
        endingRawMode: 'RAWモードを終了中…',
        enteringLineCheckMode: 'ラインチェックモードに入る中…',
        endingLineCheckMode: 'ラインチェックモードを終了中…',
        exitingRotaryMode: 'ロータリーモードを終了中…',
        turningOffFan: 'ファンをオフにする…',
        turningOffAirPump: 'エアポンプをオフにする…',
        gettingLaserSpeed: 'レーザーヘッド速度の読み取り中…',
        settingLaserSpeed: 'レーザーヘッド速度を設定中…',
        retrievingCameraOffset: 'カメラオフセットの読み取り中…',
        connectingCamera: 'カメラを接続中…',
        homing: 'ホーミング…',
        connectionTimeout: '#805 デバイス接続タイムアウト，ネットワークの状態とマシンのWi-Fiインジケーターを確認してください。',
        getProbePosition: 'プローブ位置の取得中...',
        device_not_found: {
            caption: '出るフォトのマシンが見つかりません',
            message: '#812 マシンのWi-Fiインジケーターを確認してください',
        },
        device_busy: {
            caption: 'マシンビジー状態',
            message: 'マシンは別のタスクを実行しています。後で再試行してください。動作しなくなった場合は、マシンを再起動してください。',
        },
        device_is_used: 'マシンは使用中です。現在のタスクをアボートしますか？',
        monitor_too_old: {
            caption: '古いファームウェア',
            content: '#814 <a target="_blank" href="http://helpcenter.flux3dp.com/hc/en-us/articles/216251077">このガイド</a>を使って、最新のファームウェアをインストールしてください。',
        },
        unknown_error: '#821 アプリケーションで不明なエラーが発生しました。[ヘルプ]>[メニュー]>[バグレポート]を使用してください。',
        unknown_device: '#826 マシンに接続できません。USBがマシンに接続されていることを確認してください',
        unsupport_osx_version: '現在のmacOSバージョン%sは一部の機能をサポートしていない可能性があります。macOS 11+に更新してください。',
        unsupport_win_version: '現在の Windows OSバージョンX ％s は一部の機能をサポートしていない場合があります。最新のバージョンに更新してください。',
        need_password: 'マシンに接続するには、パスワードが必要です',
        unavailableWorkarea: '#804 セットした作業領域がマシンの作業領域を超えます。マシンの作業領域を確認や[編集]>[ドキュメント設定]で作業領域をセットくださいい。',
        please_enter_dpi: 'ファイルの単位を入力してください (in mm)',
        auth_error: '#820 認証エラー：Beam Studioとマシンのファームウェアを最新バージョンに更新してください。',
        usb_unplugged: 'USB接続が失われました。USB接続を確認してください。',
        uploading_fcode: 'FCodeのアップロード中',
        cant_connect_to_device: '#827 マシンを接続できません。接続を確認してください',
        unable_to_find_machine: 'マシンを見つけることができません',
        disconnected: '接続が不安定です。デバイスの接続を確認して、後で再試行してください',
        unable_to_start: '#830 タスクを開始することができません。これが再び起きた場合は、バグレポートでご連絡ください：\n',
        camera: {
            camera_cable_unstable: 'カメラが写真を不安定に転送していることが検出されました。プレビューは引き続き正常に実行できますが、プレビューが遅い、またはタイムアウトになる問題がある可能性があります。',
            fail_to_transmit_image: '#845 画像の送信に不具合が発生しました。マシンを再起動してください。このエラーが続く場合は、この<a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/4402756056079">ガイド</a>に従ってください。',
            ws_closed_unexpectly: '#844 マシンカメラへの接続が予期せず閉じられました。このエラーが続く場合は、この<a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/4402755805071">ガイド</a>に従ってください。',
            continue_preview: '継続する',
            abort_preview: '中止する',
        },
        preview: {
            camera_preview: 'カメラプレビュー',
            auto_focus: 'オートフォーカス',
            auto_focus_instruction: 'モジュールヘッドをオブジェクトの上に移動し、アニメーションの指示に従ってAFを押してフォーカスしてください。',
            already_performed_auto_focus: 'すでにオートフォーカスを行っています。既存の値を使用しますか？',
            please_enter_height: '正確なカメラショットを撮影するために、物体の高さを入力してください。',
            apply: '適用する',
            enter_manually: '手動で入力',
            adjust: '調整',
            adjust_height_tooltip: '編集を有効にするにはチェックボックスをクリックしてください。',
        },
        unsupported_example_file: '選択したサンプルファイルは現在の作業領域ではサポートされていません。',
        time_remaining: '残り時間：',
    },
    machine_status: {
        '-17': 'Cartridge IO Mode',
        '-10': '維持モード',
        '-2': 'スキャン中',
        '-1': '維持中',
        0: 'アイドル状態',
        1: '初期化中',
        2: 'ST_TRANSFORM',
        4: '開始処理中',
        6: '再開中',
        16: '作業中',
        18: '再開中',
        32: '一時停止中',
        36: '一時停止中',
        38: '一時停止処理中',
        48: '一時停止中',
        50: '一時停止処理中',
        64: '完了済み',
        66: '完了処理中',
        68: '準備中',
        128: 'アボートしました',
        256: 'Alarm',
        512: 'Fatal',
        UNKNOWN: '不明',
    },
    calibration: {
        update_firmware_msg1: 'ファームウェアはこの機能をサポートしていません。ファームウェアを v',
        update_firmware_msg2: 'またはそれ以上にアップデートして。続行してください。（メニュー > マシン > [お使いのマシン] >ファームウェアのアップデート）',
        camera_calibration: 'カメラの較正',
        diode_calibration: 'ダイオードレーザーモジュールの較正',
        module_calibration_printer: 'プリンターモジュールのキャリブレーション',
        module_calibration_2w_ir: '赤外線モジュールのキャリブレーション',
        back: '戻る',
        next: '次へ',
        skip: 'スキップ',
        cancel: 'キャンセル',
        finish: '完了済み',
        do_engraving: '彫刻をする',
        start_engrave: '彫刻開始',
        start_printing: '印刷開始',
        ask_for_readjust: '彫刻ステップをスキップして写真を撮り、キャリブレーションを行いますか？',
        please_goto_beambox_first: 'この機能を使用するには、彫刻モード（Beambox）に切り替えてください。',
        please_place_paper: 'ワークエリアの左上隅にA4またはレターサイズの白い用紙を置いてください',
        please_place_paper_center: 'A4 サイズまたはレターサイズの白紙を作業エリアの中央に置いてください。',
        please_place_dark_colored_paper: 'A4 サイズまたはレターサイズの黒紙を作業エリアの中央に置いてください。',
        please_refocus: {
            beambox: 'プラットフォームを焦点に合わせてください（折り返されたアクリルの高さ）',
            beamo: 'レーザーヘッドを調整して、彫刻オブジェクトに焦点を合わせます（折り返されたアクリルの高さ',
            beamo_af: 'オートフォーカスアドオンのサイドボタンをダブルタップして、プローブを素材にそっと触れさせます。',
            hexa: 'Double click the height adjustment button to rise the honeycomb table up and make the probe touch the engraving material.',
        },
        without_af: 'オートフォーカスなし',
        with_af: 'オートフォーカスオン',
        dx: 'X',
        dy: 'Y',
        rotation_angle: '回転',
        x_ratio: 'X比',
        y_ratio: 'Y比',
        show_last_config: '前回の結果を表示',
        use_last_config: '前回の校正値を使用する',
        taking_picture: '写真撮影中…',
        analyze_result_fail: 'キャプチャした画像を分析することができません。<br/>次のことを確認してください：<br/>1. キャプチャした画像が白い紙で完全に覆われている。<br/>2. プラットフォームは適切にフォーカスされている。',
        drawing_calibration_image: '較正画像の描画中…',
        calibrate_done: '較正が完了しました。正確に焦点を合わせると、カメラの精度が向上します。',
        calibrate_done_diode: '較正が完了しました。ダイオードレーザーオフセットを保存しました。',
        hint_red_square: '赤い枠の外側をカットした枠に合わせてください。',
        hint_adjust_parameters: 'これらのパラメーターを使用して、赤い正方形を調整します',
        zendesk_link: 'https://support.flux3dp.com/hc/en-us/articles/360001811416',
        please_do_camera_calibration_and_focus: {
            beambox: 'ダイオードレーザーモジュールを校正する場合、カメラが必要です。\nプラットフォームを焦点に合わせてください（折り返されたアクリルの高さ）',
            beamo: 'ダイオードレーザーモジュールを校正する場合、カメラが必要です。\nレーザーヘッドを調整して、彫刻オブジェクトに焦点を合わせます（折り返されたアクリルの高さ）',
        },
        downloading_pictures: '画像をダウンロード中...',
        failed_to_download_pictures: '#848 画像のダウンロードに失敗しました。FLUX サポートにご連絡ください。 ',
        uploading_images: '画像をアップロード中...',
        calculating_camera_matrix: 'カメラ行列の計算中...',
        calculating_regression_parameters: '回帰パラメータの計算中...',
        failed_to_calibrate_camera: '#848 カメラのキャリブレーションに失敗しました。FLUX サポートにご連絡ください。',
        failed_to_save_calibration_results: '#849 キャリブレーション結果の保存に失敗しました。再試行してください。これが続く場合、FLUX サポートにご連絡ください。',
        ador_autofocus_material: 'マシンのメインページで「AF」アイコンを3秒間押して、プローブが材料に軽く触れるのを許してください。',
        ador_autofocus_focusing_block: 'マシンのメインページで「AF」アイコンを3秒間押し、プローブをフォーカシングブロックに触れさせてください。',
        align_red_cross_cut: '赤い十字の中心を切断された十字と合わせてください。',
        align_red_cross_print: '赤い十字の中心を印刷された十字と合わせてください。',
        retake: '写真を撮り直す',
        calibrate_camera_before_calibrate_modules: 'モジュールのキャリブレーションを行う前にカメラのキャリブレーションを行ってください。',
        check_checkpoint_data: 'チェックポイントデータ',
        checking_checkpoint: 'チェックポイントデータをチェックしています...',
        found_checkpoint: 'デバイスにチェックポイントデータが見つかりました。 チェックポイントから復元しますか？',
        use_old_camera_parameter: '現在のカメラレンズパラメータを使用しますか？',
        downloading_checkpoint: 'チェックポイントデータのダウンロード中...',
        failed_to_parse_checkpoint: 'チェックポイントデータの解析に失敗しました。',
        check_device_pictures: 'デバイスの写真を確認',
        checking_pictures: 'デバイスの写真を確認しています...',
        no_picutre_found: '#846 あなたのデバイスには、キャリブレーション用の利用可能な生の写真がありません。FLUXサポートにお問い合わせください。',
        calibrating_with_device_pictures: 'デバイスの写真でキャリブレーション中...',
        failed_to_calibrate_with_pictures: '#847 デバイスの写真でのキャリブレーションに失敗しました。',
        getting_plane_height: '平面の高さを取得しています...',
        preparing_to_take_picture: '写真撮影の準備中...',
        put_paper: '紙を置く',
        put_paper_step1: 'A4またはレターサイズの白い紙を作業領域の中心に置いてください。',
        put_paper_step2: '紙の四隅を固定して、平らになるようにします。',
        put_paper_step3: '「彫刻開始」をクリックしてください。',
        solve_pnp_title: 'マーカーポイントを整列',
        solve_pnp_step1: '各赤いマーカーポイントの番号と予想される位置に従って彫刻ポイントを配置してください。',
        solve_pnp_step2: '「写真を撮り直す」を押してマーカーの位置をリセットするか、手動で調整するか、マーカーポイントを整列してください。',
        align_olt: '位置: 外側の左上の彫刻ポイントに合わせてください。',
        align_ort: '位置: 外側の右上の彫刻ポイントに合わせてください。',
        align_olb: '位置: 外側の左下の彫刻ポイントに合わせてください。',
        align_orb: '位置: 外側の右下の彫刻ポイントに合わせてください。',
        align_ilt: '位置: 内側の左上の彫刻ポイントに合わせてください。',
        align_irt: '位置: 内側の右上の彫刻ポイントに合わせてください。',
        align_ilb: '位置: 内側の左下の彫刻ポイントに合わせてください。',
        align_irb: '位置: 内側の右下の彫刻ポイントに合わせてください。',
        elevate_and_cut: '高さを上げて切り取る',
        elevate_and_cut_step_1: 'A4サイズの明るい木材を作業スペースの中心に置き、20mmに高くします。',
        elevate_and_cut_step_1_prism_lift: 'Ador Prism Liftを最大14mmの長さで、少なくとも6mmの厚さの木材と一緒に使用します。',
        with_prism_lift: 'Ador Prism Liftで',
        without_prism_lift: 'Ador Prism Liftなし',
        camera_parameter_saved_successfully: 'カメラパラメータを正常に保存しました。',
        failed_to_save_camera_parameter: 'カメラパラメータの保存に失敗しました。',
        failed_to_solve_pnp: 'カメラの位置を解決できませんでした。',
        calibrating: 'キャリブレーション中...',
        moving_laser_head: 'レーザーヘッドを移動中...',
        failed_to_move_laser_head: 'レーザーヘッドの移動に失敗しました。',
        put_chessboard: 'チェスボードを配置',
        put_chessboard_desc: 'チェスボードを作業エリアの中心に配置してください。',
        put_chessboard_1: '機械のコントロールパネルに移動し、AFを押してオートフォーカスを実行してください。',
        put_chessboard_2: 'チェッカーボード用紙またはレーザーヘッドを移動して、ライブプレビューウィンドウ内の赤い枠内にチェッカーボード全体が確認されるまで移動してください。',
        put_chessboard_3: '現在の位置でチェスボード画像をダウンロードするには右クリックし、画像の鮮明さを確認してください。',
        failed_to_calibrate_chessboard: 'チェッカーボード画像でのキャリブレーションに失敗しました。',
        calibrate_chessboard_success_msg: 'チェスボードの写真を正常にキャプチャしました。<br/>この写真のスコアは %s (%.2f) です。',
        res_excellent: '優秀',
        res_average: '普通',
        res_poor: '悪い',
        perform_autofocus_bb2: '機械のコントロールパネルに移動し、AFを押してオートフォーカスを実行してください。',
    },
    input_machine_password: {
        require_password: '「%s」にはパスワードが必要です。',
        connect: '接続する',
        password: 'パスワード',
    },
    tutorial: {
        skip: 'スキップ',
        welcome: 'ようこそ',
        suggest_calibrate_camera_first: '最適な結果を得るために、ユーザーには各プレビューの前にカメラを最初にキャリブレートし、再びフォーカスを合わせることをお勧めします。<br/>キャリブレーションを実行するかどうかを確認しますか？<br/>([メニュー] > [マシン] > [あなたのマシン] > "カメラのキャリブレーション"をクリックして、後で実行するかどうかを確認することもできます。)',
        camera_calibration_failed: 'カメラの較正に失敗しました',
        ask_retry_calibration: 'カメラをもう一度キャリブレーションしますか？',
        needNewUserTutorial: 'チュートリアルを開始しますか？<br/>([ヘルプ] > [スタートチュートリアルを表示]をクリックして、後で開始することもできます。)',
        needNewInterfaceTutorial: 'Beam Studioの新しいインターフェースのチュートリアルを開始しますか？<br/>([ヘルプ] > [インターフェース紹介を表示]をクリックして、後で開始することもできます。)',
        next: '次ヘ',
        look_for_machine: 'チュートリアル用のマシンを探しています',
        unable_to_find_machine: 'チュートリアル用のマシンが見つかりません。接続設定ページに移動しますか？再試行するか、チュートリアルをスキップしますか？',
        skip_tutorial: 'チュートリアルをスキップしました。 [ヘルプ]> [初めてのチュートリアルを表示]をクリックすると、いつでもチュートリアルを開始できます',
        set_connection: '接続を設定',
        retry: 'リトライ',
        newUser: {
            draw_a_circle: '円を描く',
            drag_to_draw: 'ドラッグして描画',
            infill: 'インフィルをオンにする',
            switch_to_object_panel: 'オブジェクトパネルに切り替える',
            switch_to_layer_panel: 'レイヤーパネルに切り替え',
            set_preset_wood_engraving: 'プリセットの設定：木材 - 彫刻',
            set_preset_wood_cut: 'プリセットの設定：木材 - 切断',
            add_new_layer: '新しいレイヤーを追加する',
            draw_a_rect: '長方形を描く',
            switch_to_preview_mode: 'プレビューモードに切り替え',
            preview_the_platform: 'プラットフォームをプレビューする',
            end_preview_mode: 'プレビューモードを終了する',
            put_wood: '1. サンプル木材を入れます',
            adjust_focus: '2. フォーカスを調整する',
            close_cover: '3. カバーを閉じます',
            send_the_file: 'ファイルを送信',
            end_alert: 'チュートリアルを終了してもよろしいですか？',
            please_select_wood_engraving: '「木材 - 彫刻」プリセットを選択してください。',
            please_select_wood_cutting: '「木材 - 切断」プリセットを選択してください。',
        },
        newInterface: {
            camera_preview: 'カメラプレビュー',
            select_image_text: '選択 / 画像 / テキスト',
            basic_shapes: '基本形状',
            pen_tool: 'ペンツール',
            add_new_layer: '新しいレイヤーを追加',
            rename_by_double_click: 'ダブルクリックで名前を変更',
            drag_to_sort: 'ドラッグして並べ替える',
            layer_controls: '右クリックしてレイヤーコントロールを呼び出す:\nレイヤーの複製 / マージ / ロック / 削除',
            switch_between_layer_panel_and_object_panel: 'レイヤーパネルとオブジェクトパネルを切り替える',
            align_controls: '整列管理',
            group_controls: 'グループ管理',
            shape_operation: '形状操作',
            flip: 'フリップ',
            object_actions: 'オブジェクトアクション',
            end_alert: '新しいインターフェースの紹介を終了してもよろしいですか？',
            select_machine: 'マシンの選択',
            start_work: '作業開始',
        },
        gesture: {
            pan: '二本指でキャンバスをスクロールします。',
            zoom: '二本指を閉じてキャンバスを縮小し、広げてキャンバスを拡大します。',
            click: 'タッチしてオブジェクトを選択します。',
            drag: 'ドラッグして複数のオブジェクトを選択します。',
            hold: 'ロングプレスしてコンテキストメニューを開きます。',
        },
        links: {
            adjust_focus: 'https://flux3dp.zendesk.com/hc/en-us/articles/360001684196',
        },
        tutorial_complete: '紹介が終わりました、作っていきましょう！',
    },
    layer_module: {
        none: 'なし',
        general_laser: 'レーザー',
        laser_10w_diode: '10Wダイオードレーザー',
        laser_20w_diode: '20Wダイオードレーザー',
        laser_2w_infrared: '2W赤外線レーザー',
        printing: '印刷',
        unknown: '不明なモジュール',
        notification: {
            convertFromPrintingModuleTitle: '印刷モジュールをレーザーモジュールに変換しますか？',
            convertFromPrintingModuleMsg: 'この操作を完了すると、印刷レイヤーの色設定が削除され、現在のレイヤに設定されます。',
            convertFromLaserModuleTitle: 'レーザーモジュールを印刷モジュールに変換しますか？',
            convertFromLaserModuleMsg: 'この操作を完了すると、レーザーレイヤの設定が削除され、現在のレイヤに設定されます。',
            importedDocumentContainsPrinting: 'このドキュメントには印刷レイヤーが含まれています。Adorの作業領域に切り替えますか？',
            printingLayersCoverted: '印刷レイヤーはレーザーレイヤーに変換されました。',
            performPrintingCaliTitle: 'プリンターモジュールのキャリブレーション実行',
            performPrintingCaliMsg: '「確認」をクリックしてキャリブレーションを実行する、または上部メニューからアクセスしてください。<br />（機器 > [ご使用の機器名] > プリンターモジュールのキャリブレーション',
            performIRCaliTitle: '赤外線モジュールのキャリブレーション実行',
            performIRCaliMsg: '「確認」をクリックしてキャリブレーションを実行する、または上部メニューからアクセスしてください。<br />（機器 > [ご使用の機器名] > 赤外線モジュールのキャリブレーション）',
        },
        non_working_area: '非作業エリア',
    },
    qr_code_generator: {
        title: 'QRコードジェネレーター',
        placeholder: 'リンクまたはテキストを入力してください',
        preview: 'プレビュー',
        error_tolerance: 'エラー許容度',
        error_tolerance_link: 'https://support.flux3dp.com/hc/en-us/articles/9113705072143',
        invert: '背景色を反転',
    },
    boxgen: {
        title: 'BOXGEN',
        basic_box: '基本ボックス',
        coming_soon: '近日公開',
        workarea: '作業エリア',
        max_dimension_tooltip: '最大幅/高さ/奥行きの設定は %s です。',
        volume: '体積',
        outer: '外側',
        inner: '内側',
        width: '幅',
        height: '高さ',
        depth: '奥行き',
        cover: 'カバー',
        thickness: '厚さ',
        add_option: 'オプションを追加',
        joints: 'ジョイント',
        finger: 'フィンガー',
        finger_warning: 'フィンガージョイントとの互換性のために、ボックス内の長さは最低6mm必要です。',
        tSlot: 'T スロット',
        tSlot_warning: 'Tスロットジョイントとの互換性のためには、ボックス側面の長さが最低30mm必要です。',
        edge: 'エッジ',
        tCount: 'T の数',
        tCount_tooltip: 'Tスロットの数は短辺に適用されます。長辺の数は、長さに基づいて計算されます。',
        tDiameter: 'T の直径',
        tLength: 'T の長さ',
        continue_import: 'インポートを続行する',
        customize: 'カスタマイズ',
        merge: 'マージ',
        text_label: 'ラベル',
        beam_radius: 'レーザー半径補正',
        beam_radius_warning: '箱の端や接合部が短い場合は、組み立てを確実にするためにカーブ補正を削除してください',
        import: 'インポート',
        cancel: 'キャンセル',
        reset: 'リセット',
        zoom: 'ズーム',
        control_tooltip: '左クリックで回転\nスクロールでズーム\n右クリックでパン',
        control_tooltip_touch: 'ドラッグで回転\nピンチでズーム\n2本指でパン',
    },
    my_cloud: {
        title: 'マイクラウド',
        loading_file: '読み込み中...',
        no_file_title: 'マイクラウドにファイルを保存して開始します。',
        no_file_subtitle: 'メニュー > 「ファイル」 > 「クラウドに保存」 に移動します。',
        file_limit: '無料ファイル',
        upgrade: 'アップグレード',
        sort: {
            most_recent: '最新の',
            oldest: '最古の',
            a_to_z: '名前: A - Z',
            z_to_a: '名前: Z - A',
        },
        action: {
            open: '開く',
            rename: '名前の変更',
            duplicate: '複製',
            download: 'ダウンロード',
            delete: '削除',
            confirmFileDelete: 'このファイルを削除してもよろしいですか?この操作は元に戻せません。',
        },
        save_file: {
            choose_action: 'ファイルを保存:',
            save: '保存',
            save_new: '新規ファイルとして保存',
            input_file_name: 'ファイル名を入力:',
            invalid_char: '無効な文字:',
            storage_limit_exceeded: 'クラウドストレージの上限に達しました。新しいファイルを保存する前に、不要なファイルを削除してください。',
        },
    },
    camera_data_backup: {
        title: 'カメラデータのバックアップ',
        no_picture_found: 'マシン内に画像が見つかりません。',
        folder_not_exists: '選択したフォルダは存在しません。',
        incorrect_folder: 'キャリブレーションデータのアップロードに失敗しました。選択したフォルダが正しいかどうかを確認してください。',
        downloading_data: 'データをダウンロード中...',
        estimated_time_left: '推定残り時間：',
        uploading_data: 'データをアップロード中...',
        download_success: 'カメラデータのダウンロードに成功しました。',
        upload_success: 'カメラデータのアップロードに成功しました。',
    },
    insecure_websocket: {
        extension_detected: 'Beam Studio Connect 拡張機能が検出されました',
        extension_detected_description: 'Beam Studio Connect 拡張機能がインストールされていることが検出されました。HTTPS にリダイレクトするには「確認」をクリックするか、HTTP を継続するには「キャンセル」をクリックしてください。',
        extension_not_deteced: 'Beam Studio Connect 拡張機能を検出できません',
        extension_not_deteced_description: 'HTTPSを使用するには、「確認」をクリックしてBeam Studio Connect拡張機能をインストールしてください。拡張機能をインストールした後、ページをリフレッシュして有効にしてください。<br/>それ以外の場合は、下のリンクをクリックしてChromeでHTTPを使用する方法を確認してください。',
        unsecure_url_help_center_link: '<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/9935859456271">リンク</a>',
    },
    curve_engraving: {
        measure_audofocus_area: 'オートフォーカスエリアを測定',
        amount: '数量',
        gap: 'ギャップ',
        rows: '行',
        coloumns: '列',
        row_gap: '行間',
        column_gap: '列間',
        set_object_height: 'オブジェクトの高さを設定',
        set_object_height_desc: 'オブジェクトの最大厚さを測定します。',
        measure_area_help: '3Dカーブ用のオートフォーカスエリアをどのように選択しますか？',
        reselect_area: 'エリアを再選択',
        start_autofocus: 'オートフォーカスを開始',
        starting_measurement: '測定開始...',
        preview_3d_curve: '3Dカーブのプレビュー',
        apply_arkwork: '3Dカーブにアートワークを適用',
        apply_camera: '3Dカーブにカメラ画像を適用',
        click_to_select_point: '再度測定するためにポイントを選択または選択解除するにはクリックしてください。',
        remeasure: '再測定',
        remeasuring_points: 'ポイントを再測定しています...',
    },
    pass_through: {
        title: 'パススルーモード',
        help_text: 'Adorのパススルーモードの設定方法',
        help_link: 'https://support.flux3dp.com/hc/en-us/articles/10140002160399',
        object_length: 'オブジェクトの長さ',
        workarea_height: '作業エリア（高さ）:',
        height_desc: '各作業エリアセクションの高さを設定します。',
        ref_layer: '参照レイヤー:',
        ref_layer_desc: '参照レイヤーの実行はデフォルトで0に設定されています。 実行されることはなく、整列参照用です。',
        ref_layer_name: '参照レイヤー',
        guide_mark: 'ガイドマーク',
        guide_mark_length: '直径:',
        guide_mark_x: 'X座標:',
        guide_mark_desc: 'ガイドマークはアートワークを整列させるための基準点として刻まれます。',
        export: '作業エリアにエクスポート',
        exporting: 'エクスポート中...',
    },
    auto_fit: {
        title: '自動フィット',
        step1: '1. 1つの材料上で、オブジェクトを所望の位置に配置してください。',
        step2: '2. "確認"を押して、カメラプレビューの中にいる同様のすべての材料にオブジェクトを複製してください。',
        preview_first: '自動フィットの前にカメラプレビューを行ってください。',
        failed_to_auto_fit: '自動フィットに失敗しました、次の項目を確認してください:',
        error_tip1: '1. アートワークが素材に正しく配置されていますか？',
        error_tip2: '2. 素材の輪郭は認識できるほど明確ですか？',
        learn_more: '自動フィットについて。',
        learn_more_url: 'https://support.flux3dp.com/hc/en-us/articles/10273384373775',
    },
    rotary_settings: {
        type: '種類',
        object_diameter: 'オブジェクトの直径',
        circumference: '円周',
    },
    framing: {
        low_laser: '低出力レーザー',
        low_laser_desc: 'フレーミング作業用に低出力のレーザー値を設定します。',
        framing: 'フレーミング',
        hull: '凸包',
        area_check: 'エリアチェック',
        framing_desc: 'オブジェクトの境界ボックスをプレビューします。',
        hull_desc: 'オブジェクトの周りに巻かれた輪ゴムのように、デザインに密接に従う形状をプレビューします。',
        areacheck_desc: '作業エリアが安全であることを、オブジェクトのバウンディングボックスとレーザーヘッドの加速ゾーンをプレビューして確認します。',
        calculating_task: 'タスクを計算中...',
    },
    material_test_generator: {
        title: '材料テストジェネレーター',
        table_settings: 'テーブル設定',
        block_settings: 'ブロック設定',
        text_settings: 'テキスト設定',
        preview: 'プレビュー',
        export: 'エクスポート',
        cut: '切断',
        engrave: '彫刻',
        columns: '列',
        rows: '行',
        parameter: 'パラメータ',
        min: '最小',
        max: '最大',
        count: '数量',
        size: 'サイズ (H×W)',
        spacing: '間隔',
    },
    web_cam: {
        no_permission: 'Beam Studioにはカメラにアクセスする許可がありません。ブラウザ設定またはシステム設定でBeam Studioに許可が付与されていることを確認してください。',
        no_device: 'カメラデバイスを検出できません。カメラを再接続して、もう一度お試しください。',
    },
};
export default lang;
