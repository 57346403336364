export default {
    type: () => (''),
    arch: () => (''),
    release: () => (''),
    networkInterfaces: () => ({
        en0: [],
    }),
    process: {
        exec: (command) => Promise.resolve({
            stdout: '',
            stderr: '',
        }),
        execFile: (file, args) => Promise.resolve({
            stderr: '',
        }),
        execSync: (command) => { },
    },
};
