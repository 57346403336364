var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/**
 * open-file-helper: handle open file with Beam Stuido
 * Sending event to main process to get the file path
 * and then open the file
 */
import communicator from 'implementations/communicator';
import { getSVGAsync } from 'helpers/svg-editor-helper';
let svgEditor;
getSVGAsync((globalSVG) => {
    svgEditor = globalSVG.Editor;
});
const loadOpenFile = () => __awaiter(void 0, void 0, void 0, function* () {
    const path = communicator.sendSync('GET_OPEN_FILE');
    if (path) {
        const fetchPath = path.replace(/#/g, '%23');
        const resp = yield fetch(fetchPath);
        const fileBlob = yield resp.blob();
        const file = new File([fileBlob], path, { type: fileBlob.type });
        svgEditor.handleFile(file);
    }
});
export default {
    loadOpenFile,
};
