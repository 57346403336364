var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import deviceMaster from 'helpers/device-master';
import { ItemType } from 'app/constants/monitor-constants';
import { MonitorContext } from 'app/contexts/MonitorContext';
import styles from './FileItem.module.scss';
const maxFileNameLength = 12;
const DEFAULT_IMAGE = 'img/ph_s.png';
const onImageError = (evt) => {
    // eslint-disable-next-line no-param-reassign
    evt.currentTarget.src = 'img/ph_s.png';
};
const FileItem = ({ path, fileName }) => {
    const [state, setState] = useState({});
    useEffect(() => {
        const getStates = () => __awaiter(void 0, void 0, void 0, function* () {
            const res = yield deviceMaster.fileInfo(path, fileName);
            let imgSrc;
            if (res && res[2] instanceof Blob) {
                imgSrc = URL.createObjectURL(res[2]);
            }
            if (state.imgSrc)
                URL.revokeObjectURL(state.imgSrc);
            setState({
                imgSrc,
                fileInfo: res,
            });
        });
        getStates();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path, fileName]);
    const { highlightedItem, onHighlightItem, onSelectFile, onDeleteFile } = useContext(MonitorContext);
    const { imgSrc, fileInfo } = state;
    const isSelected = highlightedItem.name === fileName && highlightedItem.type === ItemType.FILE;
    return (React.createElement("div", { title: fileName, className: classNames(styles.container), "data-test-key": fileName, "data-filename": fileName, onClick: () => onHighlightItem({ name: fileName, type: ItemType.FILE }), onDoubleClick: () => onSelectFile(fileName, fileInfo) },
        React.createElement("div", { className: classNames(styles['img-container'], { [styles.selected]: isSelected }) },
            React.createElement("img", { src: imgSrc || DEFAULT_IMAGE, onError: onImageError }),
            React.createElement("i", { className: classNames('fa', 'fa-times-circle-o'), onClick: onDeleteFile })),
        React.createElement("div", { className: classNames(styles.name, { [styles.selected]: isSelected }) }, fileName.length > maxFileNameLength
            ? `${fileName.substring(0, maxFileNameLength)}...`
            : fileName)));
};
export default FileItem;
