import classNames from 'classnames';
import React, { useContext } from 'react';
import { ItemType } from 'app/constants/monitor-constants';
import { MonitorContext } from 'app/contexts/MonitorContext';
import styles from './DirectoryItem.module.scss';
const DirectoryItem = ({ name }) => {
    const { highlightedItem, onHighlightItem, onSelectFolder } = useContext(MonitorContext);
    const isSelected = highlightedItem.type === ItemType.FOLDER && highlightedItem.name === name;
    const handleClick = () => onHighlightItem({ name, type: ItemType.FOLDER });
    const handleDoubleClick = () => onSelectFolder(name);
    return (React.createElement("div", { className: styles.container, onClick: handleClick, onDoubleClick: handleDoubleClick },
        React.createElement("div", { className: classNames(styles['img-container'], { [styles.selected]: isSelected }) },
            React.createElement("img", { src: "img/folder.svg" })),
        React.createElement("div", { className: classNames(styles.name, { [styles.selected]: isSelected }) }, name)));
};
export default DirectoryItem;
