// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-widgets-FullWindowPanel-BackButton-module__enhancer--CkDPY.src-web-app-widgets-FullWindowPanel-BackButton-module__button--ZxtF-{display:flex;align-items:center;width:fit-content;gap:4px;margin:12px;color:#7c7c7c;font-size:14px}.src-web-app-widgets-FullWindowPanel-BackButton-module__enhancer--CkDPY.src-web-app-widgets-FullWindowPanel-BackButton-module__button--ZxtF- svg{font-size:30px}.src-web-app-widgets-FullWindowPanel-BackButton-module__enhancer--CkDPY.src-web-app-widgets-FullWindowPanel-BackButton-module__button--ZxtF-.src-web-app-widgets-FullWindowPanel-BackButton-module__desktop--MGxd5:not(.src-web-app-widgets-FullWindowPanel-BackButton-module__windows--HeAB4){margin-top:42px;margin-bottom:0}", "",{"version":3,"sources":["webpack://./src/web/app/widgets/FullWindowPanel/BackButton.module.scss"],"names":[],"mappings":"AACE,6IACE,YAAA,CACA,kBAAA,CACA,iBAAA,CACA,OAAA,CACA,WAAA,CACA,aAAA,CACA,cAAA,CAEA,iJACE,cAAA,CAIA,+RACE,eAAA,CACA,eAAA","sourcesContent":[".enhancer {\n  &.button {\n    display: flex;\n    align-items: center;\n    width: fit-content;\n    gap: 4px;\n    margin: 12px;\n    color: #7c7c7c;\n    font-size: 14px;\n\n    svg {\n      font-size: 30px;\n    }\n\n    &.desktop {\n      &:not(.windows) {\n        margin-top: 42px;\n        margin-bottom: 0;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"enhancer": "src-web-app-widgets-FullWindowPanel-BackButton-module__enhancer--CkDPY",
	"button": "src-web-app-widgets-FullWindowPanel-BackButton-module__button--ZxtF-",
	"desktop": "src-web-app-widgets-FullWindowPanel-BackButton-module__desktop--MGxd5",
	"windows": "src-web-app-widgets-FullWindowPanel-BackButton-module__windows--HeAB4"
};
export default ___CSS_LOADER_EXPORT___;
