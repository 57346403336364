import Color from './color.svg';
import Infill from './infill.svg';
import InfillPartial from './infill-partial.svg';
import Left from './left.svg';
import LetterSpacing from './letter-spacing.svg';
import LineSpacing from './line-spacing.svg';
import PolygonSide from './polygon-side.svg';
import RoundedCorner from './rounded-corner.svg';
import Stroke from './stroke.svg';
import Threshold from './threshold.svg';
import VerticalText from './vertical-text.svg';
export default {
    Color,
    Infill,
    InfillPartial,
    Left,
    LetterSpacing,
    LineSpacing,
    PolygonSide,
    RoundedCorner,
    Stroke,
    Threshold,
    VerticalText,
};
