var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable no-console */
import EventEmitter from 'eventemitter3';
import Websocket from 'helpers/websocket';
class OpenCVWebSocket extends EventEmitter {
    constructor() {
        super();
        this.ws = Websocket({
            method: 'opencv',
            onMessage: (data) => {
                this.emit('message', data);
            },
            onError: (response) => {
                this.emit('error', response);
            },
            onFatal: (response) => {
                this.emit('fatal', response);
            },
        });
    }
    removeCommandListeners() {
        this.removeAllListeners('message');
        this.removeAllListeners('error');
        this.removeAllListeners('fatal');
    }
    setDefaultErrorResponse(reject, timeoutTimer) {
        this.on('error', (response) => {
            if (timeoutTimer)
                clearTimeout(timeoutTimer);
            this.removeCommandListeners();
            reject(response);
        });
    }
    setDefaultFatalResponse(reject, timeoutTimer) {
        this.on('fatal', (response) => {
            if (timeoutTimer)
                clearTimeout(timeoutTimer);
            this.removeCommandListeners();
            reject(response);
        });
    }
    uploadUrl(url) {
        return __awaiter(this, void 0, void 0, function* () {
            const resp = yield fetch(url);
            const blob = yield resp.blob();
            const data = yield blob.arrayBuffer();
            const res = yield this.upload(data, url);
            return res;
        });
    }
    upload(data, url) {
        return new Promise((resolve, reject) => {
            this.removeCommandListeners();
            this.setDefaultErrorResponse(reject);
            this.setDefaultFatalResponse(reject);
            this.on('message', (response) => {
                const { status } = response;
                if (['ok', 'fail', 'none'].includes(status)) {
                    this.removeCommandListeners();
                    resolve(response);
                }
                else if (status === 'continue') {
                    this.ws.send(data);
                }
                else {
                    console.log('strange message from /ws/opencv', response);
                }
            });
            this.ws.send(`upload ${url} ${data.byteLength}`);
        });
    }
    sharpen(imgUrl, sharpness, radius) {
        return new Promise((resolve, reject) => {
            this.removeCommandListeners();
            const setMessageHandler = () => {
                this.setDefaultErrorResponse(reject);
                this.setDefaultFatalResponse(reject);
                this.on('message', (response) => __awaiter(this, void 0, void 0, function* () {
                    if (response.status === 'continue') {
                        this.emit('message', response);
                    }
                    else if (response.status === 'need_upload') {
                        try {
                            yield this.uploadUrl(imgUrl);
                        }
                        catch (error) {
                            reject(error);
                        }
                        setMessageHandler();
                        this.ws.send(`sharpen ${imgUrl} ${sharpness} ${radius}`);
                    }
                    if (response instanceof Blob) {
                        this.removeCommandListeners();
                        resolve(response);
                    }
                }));
            };
            setMessageHandler();
            this.ws.send(`sharpen ${imgUrl} ${sharpness} ${radius}`);
        });
    }
}
export default OpenCVWebSocket;
