import React, { useEffect } from 'react';
import classNames from 'classnames';
import { Button, ConfigProvider, Divider, Space } from 'antd';
import eventEmitterFactory from 'helpers/eventEmitterFactory';
import FloatingPanel from 'app/widgets/FloatingPanel';
import i18n from 'helpers/i18n';
import PathEditIcons from 'app/icons/path-edit-panel/PathEditIcons';
import useForceUpdate from 'helpers/use-force-update';
import { getSVGAsync } from 'helpers/svg-editor-helper';
import { textButtonTheme } from 'app/constants/antd-config';
import { TrashIcon } from 'app/icons/icons';
import { useIsMobile } from 'helpers/system-helper';
import styles from './PathEditPanel.module.scss';
let svgedit;
let svgEditor;
let svgCanvas;
getSVGAsync((globalSVG) => {
    svgedit = globalSVG.Edit;
    svgEditor = globalSVG.Editor;
    svgCanvas = globalSVG.Canvas;
});
const rightPanelEventEmitter = eventEmitterFactory.createEventEmitter('right-panel');
const LANG = i18n.lang.beambox.right_panel.object_panel.path_edit_panel;
const LINKTYPE_CORNER = 0;
const LINKTYPE_SMOOTH = 1; // same direction, different dist
const LINKTYPE_SYMMETRIC = 2; // same direction, same dist
const PanelContent = ({ isMobile = false }) => {
    const forceUpdate = useForceUpdate();
    const onNodeTypeChange = (newType) => {
        svgedit.path.path.setSelectedNodeType(newType);
    };
    useEffect(() => {
        rightPanelEventEmitter.on('UPDATE_PATH_EDIT_PANEL', forceUpdate);
        return () => {
            rightPanelEventEmitter.off('UPDATE_PATH_EDIT_PANEL', forceUpdate);
        };
    }, [forceUpdate]);
    const currentPath = svgedit.path.path;
    let containsSharpNodes = false;
    let containsRoundNodes = false;
    const isDisabled = !currentPath || currentPath.selected_pts.length === 0;
    let selectedNodeTypes = [];
    const selectedNodes = currentPath === null || currentPath === void 0 ? void 0 : currentPath.selected_pts.map((index) => currentPath.nodePoints[index]).filter((point) => point);
    if (currentPath) {
        containsSharpNodes = selectedNodes.some((node) => node.isSharp());
        containsRoundNodes = selectedNodes.some((node) => node.isRound());
        selectedNodes.forEach((node) => {
            if (node) {
                selectedNodeTypes.push(node.linkType);
            }
        });
        selectedNodeTypes = [...new Set(selectedNodeTypes)];
        selectedNodeTypes.sort();
        if (selectedNodeTypes.length > 1) {
            selectedNodeTypes = [];
        }
    }
    const canConnect = (selectedNodes === null || selectedNodes === void 0 ? void 0 : selectedNodes.length) === 2 && selectedNodes.every((point) => !point.prevSeg || !point.nextSeg);
    const canDisconnect = (selectedNodes === null || selectedNodes === void 0 ? void 0 : selectedNodes.length) === 1 && selectedNodes[0].prev && selectedNodes[0].next;
    const canDelete = (selectedNodes === null || selectedNodes === void 0 ? void 0 : selectedNodes.length) > 0;
    const buttonShape = isMobile ? 'round' : 'default';
    return (React.createElement("div", { className: styles['node-type-panel'] },
        !isMobile && React.createElement("div", { className: styles.title }, LANG.node_type),
        React.createElement(ConfigProvider, { theme: textButtonTheme },
            React.createElement(ConfigProvider, { theme: { token: { controlHeight: isMobile ? 30 : 24, borderRadius: 4 } } },
                React.createElement(Space.Compact, { block: true }, [
                    { title: 'Corner', value: LINKTYPE_CORNER, icon: React.createElement(PathEditIcons.Corner, null) },
                    { title: 'Smooth', value: LINKTYPE_SMOOTH, icon: React.createElement(PathEditIcons.Smooth, null) },
                    { title: 'Symmetry', value: LINKTYPE_SYMMETRIC, icon: React.createElement(PathEditIcons.Symmetry, null) },
                ].map(({ title, value, icon }) => (React.createElement(Button, { className: classNames(styles['compact-button'], {
                        [styles.active]: selectedNodeTypes.includes(value),
                    }), key: title, title: title, icon: icon, onClick: () => onNodeTypeChange(value), shape: buttonShape, disabled: isDisabled }))))),
            React.createElement(Divider, { className: styles.divider }),
            React.createElement(Space, { className: styles.actions, wrap: true },
                React.createElement(Button, { className: styles.button, disabled: !containsRoundNodes, onClick: () => svgCanvas.pathActions.setSharp(), shape: buttonShape, icon: React.createElement(PathEditIcons.Sharp, null), block: true },
                    React.createElement("span", { className: styles.label }, LANG.sharp)),
                React.createElement(Button, { className: styles.button, disabled: !containsSharpNodes, onClick: () => svgCanvas.pathActions.setRound(), shape: buttonShape, icon: React.createElement(PathEditIcons.Round, null), block: true },
                    React.createElement("span", { className: styles.label }, LANG.round)),
                React.createElement(Button, { className: styles.button, disabled: !canConnect, onClick: svgCanvas.pathActions.connectNodes, shape: buttonShape, icon: React.createElement(PathEditIcons.Connect, null), block: true },
                    React.createElement("span", { className: styles.label }, LANG.connect)),
                React.createElement(Button, { className: styles.button, disabled: !canDisconnect, onClick: svgCanvas.pathActions.disconnectNode, shape: buttonShape, icon: React.createElement(PathEditIcons.Disconnect, null), block: true },
                    React.createElement("span", { className: styles.label }, LANG.disconnect)),
                isMobile && (React.createElement(Button, { className: styles.button, disabled: !canDelete, onClick: svgEditor.deleteSelected, shape: buttonShape, icon: React.createElement(TrashIcon, null), block: true },
                    React.createElement("span", { className: styles.label }, LANG.delete)))))));
};
function PathEditPanel() {
    const isMobile = useIsMobile();
    if (!svgCanvas || !svgedit)
        return null;
    return isMobile ? (React.createElement(FloatingPanel, { className: styles.panel, anchors: [0, 280], title: i18n.lang.beambox.right_panel.tabs.path_edit, onClose: () => svgCanvas.pathActions.toSelectMode(svgedit.path.path.elem) },
        React.createElement(PanelContent, { isMobile: true }))) : (React.createElement("div", { id: "pathedit-panel", className: styles.panel },
        React.createElement(PanelContent, null)));
}
export default PathEditPanel;
