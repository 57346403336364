var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import appendUseElement from 'app/svgedit/operations/import/appendUseElement';
import history from 'app/svgedit/history/history';
import layerModuleHelper from 'helpers/layer-module/layer-module-helper';
import parseSvg from 'app/svgedit/operations/parseSvg';
import symbolMaker from 'helpers/symbol-maker';
import updateElementColor from 'helpers/color/updateElementColor';
import { getObjectLayer, removeDefaultLayerIfEmpty } from 'helpers/layer/layer-helper';
import { getSVGAsync } from 'helpers/svg-editor-helper';
let svgedit;
let svgCanvas;
getSVGAsync((globalSVG) => {
    svgCanvas = globalSVG.Canvas;
    svgedit = globalSVG.Edit;
});
const importSvgString = (xmlString, args = {}) => __awaiter(void 0, void 0, void 0, function* () {
    const { type = 'nolayer', layerName, parentCmd, targetModule = layerModuleHelper.getDefaultLaserModule(), } = args;
    const batchCmd = new history.BatchCommand('Import Image');
    function setDataXform(use_el, it) {
        const bb = svgedit.utilities.getBBox(use_el);
        let dataXform = '';
        if (it) {
            dataXform = `x=0 y=0 width=${bb.width} height=${bb.height}`;
        }
        else {
            $.each(bb, (key, value) => {
                dataXform = `${dataXform}${key}=${value} `;
            });
        }
        use_el.setAttribute('data-xform', dataXform);
        return use_el;
    }
    const newDoc = svgedit.utilities.text2xml(xmlString);
    svgCanvas.prepareSvg(newDoc);
    const svg = document.adoptNode(newDoc.documentElement);
    const { symbols } = parseSvg(batchCmd, svg, type);
    const results = (yield Promise.all(symbols.map((symbol) => __awaiter(void 0, void 0, void 0, function* () { return appendUseElement(symbol, { type, layerName, targetModule }); })))).filter((res) => res === null || res === void 0 ? void 0 : res.element);
    const commands = results.map(({ command }) => command);
    commands.forEach((cmd) => {
        if (cmd)
            batchCmd.addSubCommand(cmd);
    });
    const useElements = results.map(({ element }) => element);
    useElements.forEach((elem) => {
        elem.addEventListener('mouseover', svgCanvas.handleGenerateSensorArea);
        elem.addEventListener('mouseleave', svgCanvas.handleGenerateSensorArea);
    });
    useElements.forEach((element) => setDataXform(element, type === 'image-trace'));
    yield Promise.all(useElements.map((element) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const refId = svgCanvas.getHref(element);
        const symbol = document.querySelector(refId);
        const layer = (_a = getObjectLayer(element)) === null || _a === void 0 ? void 0 : _a.elem;
        const imageSymbol = yield symbolMaker.makeImageSymbol(symbol, {
            fullColor: (layer === null || layer === void 0 ? void 0 : layer.getAttribute('data-fullcolor')) === '1',
        });
        svgCanvas.setHref(element, `#${imageSymbol.id}`);
        if (svgCanvas.isUsingLayerColor) {
            updateElementColor(element);
        }
    })));
    if (useElements.length > 0) {
        const cmd = removeDefaultLayerIfEmpty();
        if (cmd)
            batchCmd.addSubCommand(cmd);
    }
    if (!batchCmd.isEmpty()) {
        if (parentCmd)
            parentCmd.addSubCommand(batchCmd);
        else
            svgCanvas.addCommandToHistory(batchCmd);
    }
    svgCanvas.call('changed', [document.getElementById('svgcontent')]);
    return useElements[useElements.length - 1];
});
export default importSvgString;
