var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useState } from 'react';
import * as THREE from 'three';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import CanvasController from './CanvasController';
const Camera = ({ zoomKey, zoomRatio = 1.5 }) => {
    const currentZoomKey = useRef(zoomKey);
    const [position, setPosition] = useState(null);
    useFrame(({ camera }) => {
        if (zoomKey !== currentZoomKey.current) {
            if (zoomKey === 0) {
                setPosition(null);
            }
            else if (zoomKey > 0) {
                setPosition(new THREE.Vector3(camera.position.x / zoomRatio, camera.position.y / zoomRatio, camera.position.z / zoomRatio));
            }
            else {
                setPosition(new THREE.Vector3(camera.position.x * zoomRatio, camera.position.y * zoomRatio, camera.position.z * zoomRatio));
            }
            currentZoomKey.current = zoomKey;
        }
        if (position) {
            const dist = camera.position.distanceTo(position);
            if (dist < 1)
                setPosition(null);
            else
                camera.position.lerp(position, 0.1);
        }
    });
    return null;
};
const ThreeCanvas = (_a) => {
    var { children, withControler = true, orbitControls } = _a, props = __rest(_a, ["children", "withControler", "orbitControls"]);
    const [resetKey, setResetKey] = useState(0);
    const [zoomKey, setZoomKey] = useState(0);
    return (React.createElement(React.Fragment, null,
        withControler && React.createElement(CanvasController, { setResetKey: setResetKey, setZoomKey: setZoomKey }),
        React.createElement(Canvas, Object.assign({ key: resetKey }, props),
            children, orbitControls !== null && orbitControls !== void 0 ? orbitControls : React.createElement(OrbitControls, { dampingFactor: 0.3 }),
            React.createElement(Camera, { zoomKey: zoomKey }))));
};
export default ThreeCanvas;
