import beamboxPreference from 'app/actions/beambox/beambox-preference';
import { CHUCK_ROTARY_DIAMETER, RotaryType } from 'app/constants/add-on';
const getRotaryRatio = (supportInfo) => {
    var _a, _b;
    let ratio = 1;
    if (beamboxPreference.read('rotary-type') === RotaryType.Chuck && ((_a = supportInfo.rotary) === null || _a === void 0 ? void 0 : _a.chuck)) {
        const objectDiameter = beamboxPreference.read('rotary-chuck-obj-d') || CHUCK_ROTARY_DIAMETER;
        ratio = CHUCK_ROTARY_DIAMETER / objectDiameter;
    }
    if ((_b = supportInfo.rotary) === null || _b === void 0 ? void 0 : _b.mirror) {
        const mirror = !!beamboxPreference.read('rotary-mirror');
        const { defaultMirror } = supportInfo.rotary;
        if (mirror !== defaultMirror)
            ratio *= -1;
    }
    return ratio;
};
export default getRotaryRatio;
