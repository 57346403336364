// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-components-dialogs-camera-common-CheckpointData-module__spinner--GWCIw{width:100%}", "",{"version":3,"sources":["webpack://./src/web/app/components/dialogs/camera/common/CheckpointData.module.scss"],"names":[],"mappings":"AAAA,oFACE,UAAA","sourcesContent":[".spinner {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner": "src-web-app-components-dialogs-camera-common-CheckpointData-module__spinner--GWCIw"
};
export default ___CSS_LOADER_EXPORT___;
