var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import imageProcessor from 'implementations/imageProcessor';
import jimpHelper from 'helpers/jimp-helper';
export const cropPreprocess = (blobUrl) => __awaiter(void 0, void 0, void 0, function* () {
    const image = yield jimpHelper.urlToImage(blobUrl);
    const { width: originalWidth, height: originalHeight } = image.bitmap;
    let result = blobUrl;
    if (Math.max(originalWidth, originalHeight) > 600) {
        if (originalWidth >= originalHeight) {
            image.resize(600, imageProcessor.AUTO);
        }
        else {
            image.resize(imageProcessor.AUTO, 600);
        }
        result = yield jimpHelper.imageToUrl(image);
    }
    const { width, height } = image.bitmap;
    const dimension = { x: 0, y: 0, width, height };
    return {
        blobUrl: result,
        dimension,
        originalWidth,
        originalHeight,
    };
});
export default {
    cropPreprocess,
};
