/**
 * macOS identifier to monitor size (diagonal in inch)
 * ref: https://support.apple.com/zh-tw/HT201300,
 * https://support.apple.com/zh-tw/HT201608,
 * https://support.apple.com/zh-tw/HT201862,
 * https://support.apple.com/zh-tw/HT201634
 */
export default {
    'MacBookPro4,1': 15.4,
    'MacBookPro5,1': 15.4,
    'MacBookPro5,2': 17,
    'MacBookPro5,3': 15.4,
    'MacBookPro5,5': 13.3,
    'MacBookPro6,1': 17,
    'MacBookPro6,2': 15.4,
    'MacBookPro7,1': 13.3,
    'MacBookPro8,1': 13.3,
    'MacBookPro8,2': 15.4,
    'MacBookPro8,3': 17,
    'MacBookPro9,1': 15.4,
    'MacBookPro9,2': 13.3,
    'MacBookPro10,1': 15.4,
    'MacBookPro10,2': 13.3,
    'MacBookPro11,1': 13.3,
    'MacBookPro11,2': 15.4,
    'MacBookPro11,3': 15.4,
    'MacBookPro11,4': 15.4,
    'MacBookPro11,5': 15.4,
    'MacBookPro12,1': 13.3,
    'MacBookPro13,1': 13.3,
    'MacBookPro13,2': 13.3,
    'MacBookPro13,3': 15.4,
    'MacBookPro14,1': 13.3,
    'MacBookPro14,2': 13.3,
    'MacBookPro14,3': 15.4,
    'MacBookPro15,1': 15.4,
    'MacBookPro15,2': 13.3,
    'MacBookPro15,4': 13.3,
    'MacBookPro16,1': 16,
    'MacBookPro16,2': 13.3,
    'MacBookPro16,3': 13.3,
    'MacBookPro17,1': 13.3,
    'MacBookPro18,1': 16.2,
    'MacBookPro18,2': 16.2,
    'MacBookPro18,3': 14.2,
    'MacBookPro18,4': 16.2,
    'Mac14,7': 13.3,
    'Mac14,6': 16.2,
    'Mac14,10': 16.2,
    'Mac14,5': 14.2,
    'Mac14,9': 14.2,
    'Mac15,7': 16.2,
    'Mac15,9': 16.2,
    'Mac15,11': 16.2,
    'Mac15,6': 14.2,
    'Mac15,8': 14.2,
    'Mac15,10': 14.2,
    'Mac15,3': 14.2,
    'MacBook5,2': 13.3,
    'MacBook6,1': 13.3,
    'MacBook7,1': 13.3,
    'MacBook8,1': 12,
    'MacBook9,1': 12,
    'MacBook10,1': 12,
    'MacBookAir3,1': 11.6,
    'MacBookAir3,2': 13.3,
    'MacBookAir4,1': 11.6,
    'MacBookAir4,2': 13.3,
    'MacBookAir5,1': 11.6,
    'MacBookAir5,2': 13.3,
    'MacBookAir6,1': 11.6,
    'MacBookAir6,2': 13.3,
    'MacBookAir7,1': 11.6,
    'MacBookAir7,2': 13.3,
    'MacBookAir8,1': 13.3,
    'MacBookAir8,2': 13.3,
    'MacBookAir9,1': 13.3,
    'MacBookAir10,1': 13.3,
    'Mac14,2': 13.6,
    'Mac14,15': 15.3,
    'Mac15,12': 13.6,
    'Mac15,13': 15.3,
    'iMac9,1': 20,
    'iMac10,1': 21.5,
    'iMac11,2': 21.5,
    'iMac11,3': 27,
    'iMac12,1': 21.5,
    'iMac12,2': 27,
    'iMac13,1': 21.5,
    'iMac13,2': 27,
    'iMac14,1': 21.5,
    'iMac14,2': 27,
    'iMac14,4': 21.5,
    'iMac15,1': 27,
    'iMac16,1': 21.5,
    'iMac16,2': 21.5,
    'iMac17,1': 27,
    'iMac18,1': 27,
    'iMac18,2': 21.5,
    'iMac18,3': 27,
    'iMac19,1': 27,
    'iMac19,2': 21.5,
    'iMac20,1': 27,
    'iMac20,2': 27,
    'iMac21,1': 24,
    'iMac21,2': 24,
    'Mac15,4': 24,
    'Mac15,5': 24,
};
