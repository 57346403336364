var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { Button, Modal, Slider } from 'antd';
import FnWrapper from 'app/actions/beambox/svgeditor-function-wrapper';
import ImageData from 'helpers/image-data';
import i18n from 'helpers/i18n';
import PreviewModeBackgroundDrawer from 'app/actions/beambox/preview-mode-background-drawer';
import traceAndImportPath from 'helpers/image-trace-panel/trace-and-import-path';
import styles from './StepTune.module.scss';
const LANG = i18n.lang.beambox.image_trace_panel;
const MODAL_PADDING_X = 80;
const MODAL_PADDING_Y = 210;
function StepTune({ imageUrl, cropData, onGoBack, onClose }) {
    const [threshold, setThreshold] = useState(128);
    const [previewImgBase64, setPreviewImgBase64] = useState('');
    const generatePreviewImgUrl = (val) => {
        ImageData(imageUrl, {
            width: 0,
            height: 0,
            grayscale: {
                is_rgba: true,
                is_shading: false,
                threshold: val,
                is_svg: false,
            },
            onComplete: (result) => setPreviewImgBase64(result.pngBase64),
        });
    };
    // listen event on slide onAfterChange but not here to avoid massive calculation
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => generatePreviewImgUrl(threshold), []);
    const handleOk = () => __awaiter(this, void 0, void 0, function* () {
        const { minX, minY } = PreviewModeBackgroundDrawer.getCoordinates();
        const dimension = {
            x: cropData.x + minX,
            y: cropData.y + minY,
            width: cropData.width,
            height: cropData.height,
        };
        yield traceAndImportPath(previewImgBase64, dimension);
        FnWrapper.insertImage(imageUrl, dimension, threshold);
        onClose();
    });
    const { width, height } = cropData;
    const maxWidth = window.innerWidth - MODAL_PADDING_X;
    const maxHieght = window.innerHeight - MODAL_PADDING_Y;
    const isWideImage = width / maxWidth > height / maxHieght;
    const renderFooter = () => (React.createElement(React.Fragment, null,
        React.createElement(Button, { onClick: onClose }, LANG.cancel),
        React.createElement(Button, { onClick: onGoBack }, LANG.back),
        React.createElement(Button, { type: "primary", onClick: handleOk }, LANG.next)));
    return (React.createElement(Modal, { centered: true, open: true, closable: false, maskClosable: false, width: isWideImage ? maxWidth : undefined, footer: renderFooter() },
        React.createElement("div", null,
            React.createElement("img", { id: "tunedImage", src: previewImgBase64, style: isWideImage ? { width: `${maxWidth}px` } : { height: `${maxHieght}px` } })),
        React.createElement("div", null,
            React.createElement("div", { className: styles.title }, LANG.tuning),
            React.createElement("div", null,
                React.createElement("h5", { className: styles.subtitle }, LANG.threshold),
                React.createElement(Slider, { id: "threshold", min: 0, max: 255, step: 1, value: threshold, onChange: (val) => setThreshold(val), onAfterChange: (val) => generatePreviewImgUrl(val) })))));
}
export default StepTune;
