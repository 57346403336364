var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import EventEmitter from 'eventemitter3';
import { sprintf } from 'sprintf-js';
import alertCaller from 'app/actions/alert-caller';
import beamboxPreference from 'app/actions/beambox/beambox-preference';
import checkDeviceStatus from 'helpers/check-device-status';
import constant, { promarkModels } from 'app/actions/beambox/constant';
import deviceMaster from 'helpers/device-master';
import exportFuncs from 'app/actions/beambox/export-funcs';
import findDefs from 'app/svgedit/utils/findDef';
import getJobOrigin from 'helpers/job-origin';
import getRotaryRatio from 'helpers/device/get-rotary-ratio';
import getUtilWS from 'helpers/api/utils-ws';
import i18n from 'helpers/i18n';
import LayerModule from 'app/constants/layer-module/layer-modules';
import MessageCaller, { MessageLevel } from 'app/actions/message-caller';
import NS from 'app/constants/namespaces';
import rotaryAxis from 'app/actions/canvas/rotary-axis';
import svgStringToCanvas from 'helpers/image/svgStringToCanvas';
import symbolMaker from 'helpers/symbol-maker';
import versionChecker from 'helpers/version-checker';
import workareaManager from 'app/svgedit/workarea';
import { fetchFraming } from 'app/actions/beambox/export-funcs-swiftray';
import { getAllLayers } from 'helpers/layer/layer-helper';
import { getSupportInfo } from 'app/constants/add-on';
import { getSVGAsync } from 'helpers/svg-editor-helper';
// TODO: add unit test
export var FramingType;
(function (FramingType) {
    FramingType[FramingType["Framing"] = 1] = "Framing";
    FramingType[FramingType["Hull"] = 2] = "Hull";
    FramingType[FramingType["AreaCheck"] = 3] = "AreaCheck";
})(FramingType || (FramingType = {}));
let svgCanvas;
getSVGAsync((globalSVG) => {
    svgCanvas = globalSVG.Canvas;
});
const getCoords = (mm) => {
    const coords = {
        minX: undefined,
        minY: undefined,
        maxX: undefined,
        maxY: undefined,
    };
    const { width: workareaWidth, height: fullHeight, expansion } = workareaManager;
    const workareaHeight = fullHeight - expansion[0] - expansion[1];
    const allLayers = getAllLayers();
    const { dpmm } = constant;
    allLayers.forEach((layer) => {
        if (layer.getAttribute('display') === 'none')
            return;
        const bboxs = svgCanvas.getVisibleElementsAndBBoxes([layer]);
        bboxs.forEach(({ bbox }) => {
            const { x, y } = bbox;
            const right = x + bbox.width;
            const bottom = y + bbox.height;
            if (right < 0 || bottom < 0 || x > workareaWidth || y > workareaHeight)
                return;
            if (coords.minX === undefined || x < coords.minX)
                coords.minX = x;
            if (coords.minY === undefined || y < coords.minY)
                coords.minY = y;
            if (coords.maxX === undefined || right > coords.maxX)
                coords.maxX = right;
            if (coords.maxY === undefined || bottom > coords.maxY)
                coords.maxY = bottom;
        });
    });
    if (coords.minX !== undefined) {
        const ratio = mm ? dpmm : 1;
        coords.minX = Math.max(coords.minX, 0) / ratio;
        coords.minY = Math.max(coords.minY, 0) / ratio;
        coords.maxX = Math.min(coords.maxX, workareaWidth) / ratio;
        coords.maxY = Math.min(coords.maxY, workareaHeight) / ratio;
    }
    return coords;
};
const getCanvasImage = () => __awaiter(void 0, void 0, void 0, function* () {
    symbolMaker.switchImageSymbolForAll(false);
    const allLayers = getAllLayers().map((layer) => layer.cloneNode(true));
    symbolMaker.switchImageSymbolForAll(true);
    allLayers.forEach((layer) => {
        const images = layer.querySelectorAll('image');
        images.forEach((image) => {
            const x = image.getAttribute('x');
            const y = image.getAttribute('y');
            const width = image.getAttribute('width');
            const height = image.getAttribute('height');
            const transform = image.getAttribute('transform');
            const rect = document.createElementNS(NS.SVG, 'rect');
            if (x)
                rect.setAttribute('x', x);
            if (y)
                rect.setAttribute('y', y);
            if (width)
                rect.setAttribute('width', width);
            if (height)
                rect.setAttribute('height', height);
            if (transform)
                rect.setAttribute('transform', transform);
            image.replaceWith(rect);
        });
    });
    const { width, height } = workareaManager;
    const svgDefs = findDefs();
    const svgString = `
    <svg
      width="${width}"
      height="${height}"
      viewBox="0 0 ${width} ${height}"
      xmlns:svg="http://www.w3.org/2000/svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      ${svgDefs.outerHTML}
      ${allLayers.map((layer) => layer.outerHTML).join('')}
    </svg>`;
    const canvas = yield svgStringToCanvas(svgString, width, height);
    const ctx = canvas.getContext('2d');
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, width, height);
    const blob = yield new Promise((resolve) => canvas.toBlob(resolve));
    return blob;
});
const getConvexHull = (imgBlob) => __awaiter(void 0, void 0, void 0, function* () {
    const utilWS = getUtilWS();
    const res = yield utilWS.getConvexHull(imgBlob);
    return res;
});
const getAreaCheckTask = (device, jobOrigin) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const metadata = yield exportFuncs.getMetadata(device);
        if (metadata === null || metadata === void 0 ? void 0 : metadata.max_x) {
            // compensate job origin
            const { x = 0, y = 0 } = jobOrigin || {};
            const minX = parseFloat(metadata.min_x) + x;
            const minY = parseFloat(metadata.min_y) + y;
            const maxX = parseFloat(metadata.max_x) + x;
            const maxY = parseFloat(metadata.max_y) + y;
            const res = [
                [minX, minY],
                [maxX, minY],
                [maxX, maxY],
                [minX, maxY],
                [minX, minY],
            ];
            return res;
        }
        return [];
    }
    catch (err) {
        console.error('Failed to get task metadata', err);
        return [];
    }
});
class FramingTaskManager extends EventEmitter {
    constructor(device) {
        super();
        this.device = null;
        this.isAdor = false;
        this.isPromark = false;
        this.isWorking = false;
        this.interrupted = false;
        this.rotaryInfo = null;
        this.jobOrigin = null;
        this.movementFeedrate = 6000; // mm/min
        this.lowPower = 0;
        this.taskCache = {};
        this.taskPoints = [];
        this.resetEnabledInfo = () => {
            this.enabledInfo = {
                lineCheckMode: false,
                rotary: false,
                redLight: false,
                '24v': false,
            };
        };
        this.changeWorkingStatus = (status) => {
            this.isWorking = status;
            this.emit('status-change', status);
        };
        this.startPromarkFraming = () => __awaiter(this, void 0, void 0, function* () {
            if (this.isWorking)
                return;
            this.changeWorkingStatus(true);
            const deviceStatus = yield checkDeviceStatus(this.device);
            if (!deviceStatus)
                return;
            console.log('start framing upload');
            yield fetchFraming();
            console.log('start framing');
            yield deviceMaster.startFraming();
        });
        this.stopPromarkFraming = () => __awaiter(this, void 0, void 0, function* () {
            if (!this.isWorking)
                return;
            yield deviceMaster.stopFraming();
            this.changeWorkingStatus(false);
        });
        this.moveTo = ({ x, y, a, f = this.movementFeedrate, wait, }) => __awaiter(this, void 0, void 0, function* () {
            let xDist = 0;
            let yDist = 0;
            const moveTarget = { x, y, a, f };
            if (moveTarget.x !== undefined) {
                if (this.jobOrigin)
                    moveTarget.x -= this.jobOrigin.x;
                xDist = moveTarget.x - this.curPos.x;
                this.curPos.x = moveTarget.x;
            }
            if (moveTarget.y !== undefined) {
                if (this.enabledInfo.rotary) {
                    moveTarget.y =
                        this.rotaryInfo.yRatio * (moveTarget.y - this.rotaryInfo.y) + this.rotaryInfo.y;
                }
                if (this.jobOrigin)
                    moveTarget.y -= this.jobOrigin.y;
                yDist = moveTarget.y - this.curPos.y;
                this.curPos.y = moveTarget.y;
            }
            else if (moveTarget.a !== undefined) {
                if (this.enabledInfo.rotary) {
                    moveTarget.a =
                        this.rotaryInfo.yRatio * (moveTarget.a - this.rotaryInfo.y) + this.rotaryInfo.y;
                }
                if (this.jobOrigin)
                    moveTarget.a -= this.jobOrigin.y;
                yDist = moveTarget.a - this.curPos.a;
                this.curPos.a = moveTarget.a;
            }
            yield deviceMaster.rawMove(moveTarget);
            if (wait) {
                const totalDist = Math.sqrt(Math.pow(xDist, 2) + Math.pow(yDist, 2));
                const time = (totalDist / f) * 60 * 1000;
                yield new Promise((resolve) => setTimeout(resolve, time));
            }
        });
        this.generateTaskPoints = (type) => __awaiter(this, void 0, void 0, function* () {
            if (this.taskCache[type])
                return this.taskCache[type];
            if (type === FramingType.Framing) {
                const coords = getCoords(true);
                if (coords.minX === undefined)
                    return [];
                const res = [
                    [coords.minX, coords.minY],
                    [coords.maxX, coords.minY],
                    [coords.maxX, coords.maxY],
                    [coords.minX, coords.maxY],
                    [coords.minX, coords.minY],
                ];
                this.taskCache[type] = res;
                return res;
            }
            if (type === FramingType.Hull) {
                const image = yield getCanvasImage();
                const points = yield getConvexHull(image);
                const res = points.map(([x, y]) => [
                    x / constant.dpmm,
                    y / constant.dpmm,
                ]);
                res.push(res[0]);
                this.taskCache[type] = res;
                return res;
            }
            if (type === FramingType.AreaCheck) {
                const res = yield getAreaCheckTask(this.device, this.jobOrigin);
                if (res.length > 0)
                    this.taskCache[type] = res;
                return res;
            }
            throw new Error('Not implemented');
        });
        this.initTask = () => __awaiter(this, void 0, void 0, function* () {
            const selectRes = yield deviceMaster.select(this.device);
            if (!selectRes.success)
                return;
            const deviceStatus = yield checkDeviceStatus(this.device);
            if (!deviceStatus)
                return;
            const { lang } = i18n;
            this.emit('message', sprintf(lang.message.connectingMachine, this.device.name));
            this.resetEnabledInfo();
            this.curPos = { x: 0, y: 0, a: 0 };
            this.rotaryInfo = null;
            const rotaryMode = beamboxPreference.read('rotary_mode');
            const supportInfo = getSupportInfo(this.device.model);
            if (rotaryMode && supportInfo.rotary) {
                const y = rotaryAxis.getPosition(true);
                this.rotaryInfo = { y, yRatio: getRotaryRatio(supportInfo) };
                if (this.isAdor)
                    this.rotaryInfo.useAAxis = true;
            }
        });
        this.setLowPowerValue = (settingValue) => __awaiter(this, void 0, void 0, function* () {
            this.lowPower = 0;
            if (constant.adorModels.includes(this.device.model) && settingValue > 0) {
                const t = i18n.lang.topbar.alerts;
                let warningMessage = '';
                const deviceDetailInfo = yield deviceMaster.getDeviceDetailInfo();
                const headType = parseInt(deviceDetailInfo.head_type, 10);
                if ([LayerModule.LASER_10W_DIODE, LayerModule.LASER_20W_DIODE].includes(headType)) {
                    this.lowPower = settingValue * 10; // mapping 0~100 to 0~1000
                }
                else if (headType === 0) {
                    warningMessage = t.headtype_none + t.install_correct_headtype;
                }
                else if ([LayerModule.LASER_1064, LayerModule.PRINTER].includes(headType)) {
                    warningMessage = t.headtype_mismatch + t.install_correct_headtype;
                }
                else {
                    warningMessage = t.headtype_unknown + t.install_correct_headtype;
                }
                if (this.lowPower > 0) {
                    try {
                        const res = yield deviceMaster.getDoorOpen();
                        const isDoorOpened = res.value === '1';
                        if (isDoorOpened) {
                            warningMessage = t.door_opened;
                        }
                    }
                    catch (error) {
                        console.error(error);
                        warningMessage = t.fail_to_get_door_status;
                    }
                }
                if (warningMessage) {
                    MessageCaller.openMessage({
                        key: 'low-laser-warning',
                        level: MessageLevel.INFO,
                        content: warningMessage,
                    });
                }
            }
        });
        this.setupTask = () => __awaiter(this, void 0, void 0, function* () {
            const { lang } = i18n;
            this.emit('message', lang.message.enteringRawMode);
            yield deviceMaster.enterRawMode();
            this.emit('message', lang.message.exitingRotaryMode);
            yield deviceMaster.rawSetRotary(false);
            this.emit('message', lang.message.homing);
            if (this.isAdor && this.rotaryInfo)
                yield deviceMaster.rawHomeZ();
            if (this.jobOrigin) {
                yield deviceMaster.rawUnlock();
                yield deviceMaster.rawSetOrigin();
            }
            else
                yield deviceMaster.rawHome();
            if ((!this.isAdor && this.vc.meetRequirement('MAINTAIN_WITH_LINECHECK')) ||
                (this.isAdor && this.vc.meetRequirement('ADOR_RELEASE'))) {
                yield deviceMaster.rawStartLineCheckMode();
                this.enabledInfo.lineCheckMode = true;
            }
            this.emit('message', lang.message.turningOffFan);
            yield deviceMaster.rawSetFan(false);
            this.emit('message', lang.message.turningOffAirPump);
            yield deviceMaster.rawSetAirPump(false);
            if (!this.isAdor)
                yield deviceMaster.rawSetWaterPump(false);
            this.emit('close-message');
            if (this.rotaryInfo) {
                const { y } = this.rotaryInfo;
                if (this.isAdor) {
                    if (this.taskPoints.length > 0) {
                        const [fistPoint] = this.taskPoints;
                        yield this.moveTo({ x: fistPoint[0] });
                    }
                    yield this.moveTo({ y });
                    yield deviceMaster.rawMoveZRelToLastHome(0);
                }
                else if (this.jobOrigin)
                    yield this.moveTo({ x: this.jobOrigin[0], y });
                else
                    yield this.moveTo({ x: 0, y });
                yield deviceMaster.rawSetRotary(true);
                this.curPos.a = y;
                this.enabledInfo.rotary = true;
            }
        });
        this.endTask = () => __awaiter(this, void 0, void 0, function* () {
            if (deviceMaster.currentControlMode === 'raw') {
                const { device, enabledInfo } = this;
                const supportInfo = getSupportInfo(device.model);
                if (enabledInfo.lineCheckMode)
                    yield deviceMaster.rawEndLineCheckMode();
                if (enabledInfo.rotary)
                    yield deviceMaster.rawSetRotary(false);
                if (supportInfo.redLight && enabledInfo.redLight)
                    yield deviceMaster.rawSetRedLight(false);
                yield deviceMaster.rawSetLaser({ on: false, s: 0 });
                if (enabledInfo['24v'])
                    yield deviceMaster.rawSet24V(false);
                yield deviceMaster.rawLooseMotor();
                yield deviceMaster.endRawMode();
            }
        });
        this.performTask = () => __awaiter(this, void 0, void 0, function* () {
            const { taskPoints, device, jobOrigin, rotaryInfo } = this;
            if (taskPoints.length === 0)
                return;
            const supportInfo = getSupportInfo(device.model);
            const yKey = (rotaryInfo === null || rotaryInfo === void 0 ? void 0 : rotaryInfo.useAAxis) ? 'a' : 'y';
            yield this.moveTo({ x: taskPoints[0][0], [yKey]: taskPoints[0][1], wait: true });
            if (this.interrupted)
                return;
            if (supportInfo.redLight) {
                yield deviceMaster.rawSetRedLight(true);
                this.enabledInfo.redLight = true;
            }
            else if (this.lowPower > 0) {
                yield deviceMaster.rawSetLaser({ on: true, s: this.lowPower });
                yield deviceMaster.rawSet24V(true);
                this.enabledInfo['24v'] = true;
            }
            if (this.interrupted)
                return;
            for (let i = 1; i < taskPoints.length; i += 1) {
                if (this.interrupted)
                    return;
                // eslint-disable-next-line no-await-in-loop
                yield this.moveTo({ x: taskPoints[i][0], [yKey]: taskPoints[i][1] });
            }
            if (this.interrupted)
                return;
            if (rotaryInfo) {
                if (supportInfo.redLight) {
                    yield deviceMaster.rawSetRedLight(false);
                }
                else if (this.lowPower > 0)
                    yield deviceMaster.rawSetLaser({ on: false, s: 0 });
                yield this.moveTo({ [yKey]: rotaryInfo.y });
                yield deviceMaster.rawSetRotary(false);
                this.enabledInfo.rotary = false;
            }
            if (this.interrupted)
                return;
            if (jobOrigin) {
                yield this.moveTo({ x: jobOrigin.x, y: jobOrigin.y });
            }
        });
        this.startFraming = (type, opts) => __awaiter(this, void 0, void 0, function* () {
            // Go to Promark logic
            if (this.isWorking)
                return;
            if (this.isPromark) {
                yield this.startPromarkFraming();
                return;
            }
            this.emit('message', i18n.lang.framing.calculating_task);
            this.taskPoints = yield this.generateTaskPoints(type);
            if (this.taskPoints.length === 0) {
                MessageCaller.openMessage({
                    key: 'no-element-to-frame',
                    level: MessageLevel.INFO,
                    content: i18n.lang.topbar.alerts.add_content_first,
                    duration: 3,
                });
                return;
            }
            try {
                this.changeWorkingStatus(true);
                this.interrupted = false;
                yield this.initTask();
                if (this.interrupted)
                    return;
                const { lowPower = 0 } = opts;
                yield this.setLowPowerValue(lowPower);
                if (this.interrupted)
                    return;
                yield this.setupTask();
                if (this.interrupted)
                    return;
                yield this.performTask();
            }
            catch (error) {
                console.error(error);
                alertCaller.popUp({ message: `Failed to start framing: ${error}` });
            }
            finally {
                yield this.endTask();
                this.emit('close-message');
                this.changeWorkingStatus(false);
            }
        });
        this.stopFraming = () => __awaiter(this, void 0, void 0, function* () {
            if (this.isPromark) {
                yield this.stopPromarkFraming();
                return;
            }
            if (!this.isWorking)
                return;
            this.interrupted = true;
        });
        this.device = device;
        this.resetEnabledInfo();
        this.vc = versionChecker(device.version);
        this.isAdor = constant.adorModels.includes(device.model);
        this.isPromark = promarkModels.has(device.model);
        if (beamboxPreference.read('enable-job-origin') &&
            this.vc.meetRequirement(this.isAdor ? 'ADOR_JOB_ORIGIN' : 'JOB_ORIGIN')) {
            this.jobOrigin = getJobOrigin();
        }
        else
            this.jobOrigin = null;
    }
}
export default FramingTaskManager;
