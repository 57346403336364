import React from 'react';
import { Button } from 'antd';
import browser from 'implementations/browser';
import dialogCaller from 'app/actions/dialog-caller';
import FluxIcons from 'app/icons/flux/FluxIcons';
import useI18n from 'helpers/useI18n';
import { getCurrentUser } from 'helpers/api/flux-id';
import FluxPlusModal from './FluxPlusModal';
import styles from './FluxPlusWarning.module.scss';
const FluxPlusWarning = ({ onClose, monotype = false }) => {
    const lang = useI18n().flux_id_login;
    const user = getCurrentUser();
    const note = monotype
        ? lang.flux_plus.access_monotype_feature_note
        : lang.flux_plus.access_plus_feature_note;
    return (React.createElement(FluxPlusModal, { onClose: onClose },
        React.createElement("div", { className: styles['flux-plus'] },
            React.createElement("img", { src: "core-img/flux-plus/man-guess.jpg", alt: note }),
            React.createElement("div", { className: styles.text }, monotype ? (lang.flux_plus.access_monotype_feature) : (React.createElement(React.Fragment, null,
                React.createElement("div", null, lang.flux_plus.access_plus_feature_1),
                React.createElement(FluxIcons.FluxPlusLogo, null),
                lang.flux_plus.access_plus_feature_2))),
            React.createElement("div", { className: styles.buttons },
                React.createElement(Button, { block: true, type: "primary", onClick: () => browser.open(lang.flux_plus.member_center_url) }, monotype ? lang.flux_plus.get_addon : lang.flux_plus.subscribe_now),
                !user && (React.createElement(Button, { block: true, type: "default", onClick: () => {
                        onClose();
                        dialogCaller.showLoginDialog();
                    } }, lang.login))),
            React.createElement("div", { className: styles.note }, note))));
};
export default FluxPlusWarning;
