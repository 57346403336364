import classNames from 'classnames';
import React, { useContext, useEffect, useRef } from 'react';
import { SwipeAction } from 'antd-mobile';
import ColorPicker from 'app/widgets/ColorPicker';
import constant from 'app/actions/beambox/constant';
import LayerModule from 'app/constants/layer-module/layer-modules';
import LayerPanelIcons from 'app/icons/layer-panel/LayerPanelIcons';
import ObjectPanelIcons from 'app/icons/object-panel/ObjectPanelIcons';
import useWorkarea from 'helpers/hooks/useWorkarea';
import { deleteLayerByName, getAllLayerNames, getLayerElementByName, setLayerLock, } from 'helpers/layer/layer-helper';
import { getData } from 'helpers/layer/layer-config-helper';
import { getSVGAsync } from 'helpers/svg-editor-helper';
import { LayerPanelContext } from 'app/views/beambox/Right-Panels/contexts/LayerPanelContext';
import { useIsMobile } from 'helpers/system-helper';
import styles from './LayerList.module.scss';
let svgCanvas;
getSVGAsync((globalSVG) => {
    svgCanvas = globalSVG.Canvas;
});
const renderDragBar = () => React.createElement("div", { key: "drag-bar", className: styles['drag-bar'] });
const LayerList = ({ draggingDestIndex, onLayerClick, highlightLayer, onLayerDragStart, onlayerDragEnd, onLayerTouchStart, onLayerTouchMove, onLayerTouchEnd, onSensorAreaDragEnter, onLayerCenterDragEnter, onLayerDoubleClick, onLayerColorChange, setLayerVisibility, unLockLayers, }) => {
    const { selectedLayers, setSelectedLayers, forceUpdate } = useContext(LayerPanelContext);
    const items = [];
    const drawing = svgCanvas.getCurrentDrawing();
    const currentLayerName = drawing.getCurrentLayerName();
    const isMobile = useIsMobile();
    const ref = useRef(null);
    useEffect(() => {
        if (ref.current && draggingDestIndex !== null) {
            ref.current.close();
        }
    }, [ref, draggingDestIndex, selectedLayers]);
    const workarea = useWorkarea();
    const isAnyLayerMissing = drawing.all_layers.some((layer) => {
        // eslint-disable-next-line no-underscore-dangle
        if (!layer.group_.parentNode)
            return true;
        return false;
    });
    if (isAnyLayerMissing)
        drawing.identifyLayers();
    const allLayerNames = getAllLayerNames();
    if (draggingDestIndex === allLayerNames.length)
        items.push(renderDragBar());
    const shouldShowModuleIcon = constant.adorModels.includes(workarea);
    for (let i = allLayerNames.length - 1; i >= 0; i -= 1) {
        const layerName = allLayerNames[i];
        const layer = getLayerElementByName(layerName);
        if (layer) {
            const isLocked = layer.getAttribute('data-lock') === 'true';
            const isFullColor = layer.getAttribute('data-fullcolor') === '1';
            const isSelected = selectedLayers.includes(layerName);
            const isVis = drawing.getLayerVisibility(layerName);
            const module = getData(layer, 'module');
            const isRef = getData(layer, 'ref');
            let moduleIcon = null;
            if (isRef)
                moduleIcon = React.createElement(LayerPanelIcons.Ref, null);
            else if (shouldShowModuleIcon)
                moduleIcon =
                    module === LayerModule.PRINTER ? React.createElement(LayerPanelIcons.Print, null) : React.createElement(LayerPanelIcons.Laser, null);
            const layerItem = (React.createElement("div", { key: layerName, "data-testid": layerName, "data-layer": layerName, className: classNames('layer-item', styles.item, {
                    [styles.selected]: isSelected,
                    [styles.locked]: isLocked,
                    [styles.current]: currentLayerName === layerName,
                }), onClick: (e) => onLayerClick(e, layerName), onMouseOver: () => highlightLayer(layerName), onMouseOut: () => highlightLayer(), draggable: true, onDragStart: (e) => onLayerDragStart(layerName, e), onDragEnd: onlayerDragEnd, onTouchStart: (e) => {
                    onLayerTouchStart(layerName, e, isMobile ? 100 : 800);
                }, onTouchMove: onLayerTouchMove, onTouchEnd: onLayerTouchEnd, onFocus: () => { }, onBlur: () => { } },
                React.createElement("div", { className: styles['drag-sensor-area'], "data-index": i + 1, onDragEnter: () => onSensorAreaDragEnter(i + 1) }),
                React.createElement("div", { className: styles.row, "data-layer": layerName, onDragEnter: () => onLayerCenterDragEnter(layerName) },
                    React.createElement("div", { className: styles.color }, isFullColor ? (React.createElement(LayerPanelIcons.FullColor, null)) : (React.createElement(ColorPicker, { initColor: drawing.getLayerColor(layerName), forPrinter: module === LayerModule.PRINTER, triggerSize: "small", onChange: (color) => onLayerColorChange(layerName, color) }))),
                    moduleIcon && (React.createElement("div", { className: styles.module }, moduleIcon)),
                    React.createElement("div", { id: `layerdoubleclick-${i}`, className: classNames(styles.name, {
                            [styles['with-module']]: shouldShowModuleIcon,
                        }), onDoubleClick: (e) => {
                            if (!isMobile && !e.ctrlKey && !e.shiftKey && !e.metaKey)
                                onLayerDoubleClick();
                        } }, layerName),
                    isMobile && (React.createElement("div", { id: `layerlock-${i}`, className: styles.lock, onClick: (e) => {
                            if (isLocked) {
                                e.stopPropagation();
                                unLockLayers(layerName);
                            }
                        } },
                        React.createElement("img", { src: "img/right-panel/icon-layerlock.svg", alt: "lock-icon" }))),
                    React.createElement("div", { id: `layervis-${i}`, className: styles.vis, onClick: (e) => {
                            e.stopPropagation();
                            setLayerVisibility(layerName);
                        } }, isVis ? React.createElement(LayerPanelIcons.Visible, null) : React.createElement(LayerPanelIcons.Invisible, null)),
                    isMobile && (React.createElement("div", null,
                        React.createElement(LayerPanelIcons.Move, null))),
                    !isMobile && (React.createElement("div", { id: `layerlock-${i}`, className: styles.lock, onClick: (e) => {
                            if (isLocked) {
                                e.stopPropagation();
                                unLockLayers(layerName);
                            }
                        } },
                        React.createElement("img", { src: "img/right-panel/icon-layerlock.svg", alt: "lock-icon" })))),
                React.createElement("div", { className: styles['drag-sensor-area'], "data-index": i, onDragEnter: () => onSensorAreaDragEnter(i) })));
            if (!isMobile) {
                items.push(layerItem);
            }
            else {
                const leftActions = isMobile
                    ? [
                        {
                            key: 'lock',
                            text: isLocked ? React.createElement(LayerPanelIcons.Unlock, null) : React.createElement(LayerPanelIcons.Lock, null),
                            color: 'warning',
                            onClick: () => {
                                setLayerLock(layerName, !isLocked);
                                // let SwipeAction close before force update
                                setTimeout(forceUpdate);
                            },
                        },
                    ]
                    : undefined;
                const rightActions = isMobile
                    ? [
                        {
                            key: 'delete',
                            text: React.createElement(ObjectPanelIcons.Trash, null),
                            color: 'danger',
                            onClick: () => {
                                deleteLayerByName(layerName);
                                setSelectedLayers([]);
                            },
                        },
                    ]
                    : undefined;
                items.push(React.createElement(SwipeAction, { key: layerName, ref: isSelected && layerName === selectedLayers[0] ? ref : undefined, leftActions: leftActions, rightActions: rightActions, onActionsReveal: () => setSelectedLayers([layerName]) }, layerItem));
            }
            if (draggingDestIndex === i) {
                items.push(renderDragBar());
            }
        }
    }
    return (React.createElement("div", { id: "layerlist", className: styles.list, onDragOver: (e) => e.preventDefault() }, items));
};
export default LayerList;
