var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { memo, useCallback, useContext, useEffect, useRef, useState } from 'react';
import alertCaller from 'app/actions/alert-caller';
import DeviceConstants from 'app/constants/device-constants';
import DeviceMaster from 'helpers/device-master';
import { MonitorContext } from 'app/contexts/MonitorContext';
import Breadcrumbs from './widgets/Breadcrumbs';
import DirectoryItem from './widgets/DirectoryItem';
import FileItem from './widgets/FileItem';
import styles from './MonitorFilelist.module.scss';
const MonitorFilelist = ({ path }) => {
    const containerRef = useRef(null);
    const doUsbExist = useRef(undefined);
    const [contents, setContents] = useState({
        directories: [], files: []
    });
    const { shouldUpdateFileList, setShouldUpdateFileList, uploadFile } = useContext(MonitorContext);
    const preventDefaultEvent = useCallback((e) => e.preventDefault(), []);
    const handleContainerDrop = (e) => {
        const [file] = e.dataTransfer.files;
        if (file && file.name.endsWith('.fc'))
            uploadFile(file);
    };
    const checkUsbDirectoryExistance = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield DeviceMaster.ls('USB');
            doUsbExist.current = true;
        }
        catch (error) {
            doUsbExist.current = false;
        }
    });
    const updateContents = () => __awaiter(void 0, void 0, void 0, function* () {
        const res = yield DeviceMaster.ls(path);
        if (res.error && res.error !== DeviceConstants.NOT_EXIST) {
            alertCaller.popUpError({ id: 'ls error', message: res.error });
            setContents({ path, directories: [], files: [] });
            return;
        }
        if (doUsbExist.current === undefined && path === '')
            yield checkUsbDirectoryExistance();
        if (!doUsbExist.current && path === '') {
            const i = res.directories.indexOf('USB');
            if (i >= 0)
                res.directories.splice(i, 1);
        }
        setContents({ path, directories: res.directories, files: res.files });
    });
    useEffect(() => {
        updateContents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path]);
    useEffect(() => {
        if (shouldUpdateFileList) {
            setShouldUpdateFileList(false);
            updateContents();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldUpdateFileList]);
    const { path: contentsPath, directories, files } = contents;
    return (React.createElement("div", null,
        React.createElement(Breadcrumbs, null),
        React.createElement("div", { className: styles.container, ref: containerRef, onDragEnter: preventDefaultEvent, onDragOver: preventDefaultEvent, onDrop: handleContainerDrop },
            contentsPath === path ? directories.map((folder) => (React.createElement(DirectoryItem, { key: `${path}/${folder}`, name: folder }))) : null,
            contentsPath === path ? files.map((file) => (React.createElement(FileItem, { key: `${path}/${file}`, path: path, fileName: file }))) : null)));
};
export default memo(MonitorFilelist);
