var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classNames from 'classnames';
import React from 'react';
import { ResizableBox } from 'react-resizable';
import AddLayerButton from 'app/components/beambox/right-panel/AddLayerButton';
import Alert from 'app/actions/alert-caller';
import ConfigPanel from 'app/views/beambox/Right-Panels/ConfigPanel/ConfigPanel';
import changeLayersColor from 'helpers/layer/changeLayersColor';
import Dialog from 'app/actions/dialog-caller';
import DragImage from 'app/components/beambox/right-panel/DragImage';
import eventEmitterFactory from 'helpers/eventEmitterFactory';
import FloatingPanel from 'app/widgets/FloatingPanel';
import HistoryCommandFactory from 'app/svgedit/history/HistoryCommandFactory';
import i18n from 'helpers/i18n';
import LayerContextMenu from 'app/views/beambox/Right-Panels/LayerPanel/LayerContextMenu';
import LayerList from 'app/views/beambox/Right-Panels/LayerPanel/LayerList';
import LayerPanelIcons from 'app/icons/layer-panel/LayerPanelIcons';
import layoutConstants from 'app/constants/layout-constants';
import ObjectPanelItem from 'app/views/beambox/Right-Panels/ObjectPanelItem';
import RightPanelController from 'app/views/beambox/Right-Panels/contexts/RightPanelController';
import SelLayerBlock from 'app/components/beambox/right-panel/SelLayerBlock';
import storage from 'implementations/storage';
import { ContextMenuTrigger } from 'helpers/react-contextmenu';
import { cloneLayerConfig } from 'helpers/layer/layer-config-helper';
import { highlightLayer, moveLayersToPosition, setLayersLock } from 'helpers/layer/layer-helper';
import { getSVGAsync } from 'helpers/svg-editor-helper';
import { LayerPanelContext } from 'app/views/beambox/Right-Panels/contexts/LayerPanelContext';
import { isMobile } from 'helpers/system-helper';
import styles from './LayerPanel.module.scss';
let svgCanvas;
getSVGAsync((globalSVG) => {
    svgCanvas = globalSVG.Canvas;
});
const LANG = i18n.lang.beambox.right_panel.layer_panel;
const minLayerHeight = 100;
const defaultLayerHeight = layoutConstants.layerListHeight;
const layerPanelEventEmitter = eventEmitterFactory.createEventEmitter('layer-panel');
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Handle = React.forwardRef((props, ref) => {
    const { handleAxis } = props, eventHandlers = __rest(props, ["handleAxis"]);
    return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    React.createElement("div", Object.assign({ className: styles.handle, ref: ref }, eventHandlers),
        React.createElement(LayerPanelIcons.Handle, null)));
});
class LayerPanel extends React.PureComponent {
    constructor(props) {
        super(props);
        this.draggingScrollDirection = 0;
        this.isDoingTutorial = false;
        this.currentHeight = defaultLayerHeight;
        this.oldHeight = defaultLayerHeight;
        this.startTutorial = () => {
            this.isDoingTutorial = true;
            this.oldHeight = this.currentHeight;
            this.currentHeight = defaultLayerHeight;
            layerPanelEventEmitter.once('endTutorial', this.endTutorial);
            this.forceUpdate();
        };
        this.endTutorial = () => {
            this.isDoingTutorial = false;
            this.currentHeight = this.oldHeight;
            this.forceUpdate();
        };
        this.savePanelHeight = () => {
            storage.set('layer-panel-height', this.isDoingTutorial ? this.oldHeight : this.currentHeight);
        };
        this.unLockLayers = (layerName) => {
            const { selectedLayers, setSelectedLayers } = this.context;
            if (selectedLayers.includes(layerName)) {
                setLayersLock(selectedLayers, false);
                this.forceUpdate();
            }
            else {
                setLayersLock([layerName], false);
                setSelectedLayers([layerName]);
            }
        };
        this.renameLayer = () => {
            const { setSelectedLayers } = this.context;
            const drawing = svgCanvas.getCurrentDrawing();
            const oldName = drawing.getCurrentLayerName();
            Dialog.promptDialog({
                caption: LANG.notification.newName,
                defaultValue: oldName,
                onYes: (newName) => {
                    if (!newName || oldName === newName) {
                        return;
                    }
                    if (svgCanvas.getCurrentDrawing().hasLayer(newName)) {
                        Alert.popUp({
                            id: 'dupli_layer_name',
                            message: LANG.notification.enterUniqueLayerName,
                        });
                        return;
                    }
                    svgCanvas.renameCurrentLayer(newName);
                    cloneLayerConfig(oldName, newName);
                    setSelectedLayers([newName]);
                },
            });
        };
        this.initMultiSelectedLayer = () => {
            if (!svgCanvas) {
                return;
            }
            const drawing = svgCanvas.getCurrentDrawing();
            const currentLayerName = drawing.getCurrentLayerName();
            if (currentLayerName) {
                const { setSelectedLayers } = this.context;
                setSelectedLayers([currentLayerName]);
            }
        };
        this.selectOnlyLayer = (layerName) => {
            const { setSelectedLayers } = this.context;
            svgCanvas.clearSelection();
            const drawing = svgCanvas.getCurrentDrawing();
            const res = drawing.setCurrentLayer(layerName);
            if (res) {
                setSelectedLayers([layerName]);
            }
        };
        this.toggleLayerSelected = (layerName) => {
            const { selectedLayers, setSelectedLayers } = this.context;
            const newSelectedLayers = [...selectedLayers];
            const drawing = svgCanvas.getCurrentDrawing();
            const index = newSelectedLayers.findIndex((name) => name === layerName);
            if (index >= 0) {
                if (newSelectedLayers.length > 1) {
                    newSelectedLayers.splice(index, 1);
                    drawing.setCurrentLayer(newSelectedLayers[0]);
                }
            }
            else {
                newSelectedLayers.push(layerName);
                drawing.setCurrentLayer(layerName);
            }
            setSelectedLayers(newSelectedLayers);
        };
        this.toggleContiguousSelectedUntil = (layerName) => {
            var _a, _b;
            const drawing = svgCanvas.getCurrentDrawing();
            const currentLayer = drawing.getCurrentLayerName();
            // eslint-disable-next-line no-underscore-dangle
            const allLayers = (_b = (_a = drawing.all_layers) === null || _a === void 0 ? void 0 : _a.map((layer) => layer.name_)) !== null && _b !== void 0 ? _b : [];
            let [startIndex, endIndex] = [-1, -1];
            for (let i = 0; i < allLayers.length; i += 1) {
                if (allLayers[i] === currentLayer) {
                    startIndex = i;
                }
                if (allLayers[i] === layerName) {
                    endIndex = i;
                }
                if (startIndex > -1 && endIndex > -1)
                    break;
            }
            if (startIndex < 0 || endIndex < 0)
                return;
            const { selectedLayers, setSelectedLayers } = this.context;
            const newSelectedLayers = [...selectedLayers];
            const isLayerSelected = newSelectedLayers.includes(layerName);
            for (let i = startIndex; i !== endIndex; endIndex > startIndex ? (i += 1) : (i -= 1)) {
                const index = newSelectedLayers.findIndex((name) => name === allLayers[i]);
                if (isLayerSelected && index >= 0) {
                    newSelectedLayers.splice(index, 1);
                }
                else if (!isLayerSelected && index < 0) {
                    newSelectedLayers.push(allLayers[i]);
                }
            }
            if (!newSelectedLayers.includes(layerName)) {
                newSelectedLayers.push(layerName);
            }
            drawing.setCurrentLayer(layerName);
            setSelectedLayers(newSelectedLayers);
        };
        this.setLayerColor = (layerName, newColor) => {
            const { selectedLayers, forceUpdateSelectedLayers } = this.context;
            const targets = selectedLayers.includes(layerName) ? selectedLayers : [layerName];
            const cmd = changeLayersColor(targets, newColor);
            if (cmd && !cmd.isEmpty())
                svgCanvas.addCommandToHistory(cmd);
            forceUpdateSelectedLayers();
        };
        this.setLayerVisibility = (layerName) => {
            const drawing = svgCanvas.getCurrentDrawing();
            const isVis = drawing.getLayerVisibility(layerName);
            const { selectedLayers } = this.context;
            const batchCmd = HistoryCommandFactory.createBatchCommand('Set Layers Visibility');
            if (selectedLayers.includes(layerName)) {
                for (let i = 0; i < selectedLayers.length; i += 1) {
                    svgCanvas.setLayerVisibility(selectedLayers[i], !isVis, { parentCmd: batchCmd });
                }
            }
            else {
                svgCanvas.setLayerVisibility(layerName, !isVis, { parentCmd: batchCmd });
            }
            if (!batchCmd.isEmpty())
                svgCanvas.addCommandToHistory(batchCmd);
            this.forceUpdate();
        };
        this.onLayerDragStart = (layerName, e) => {
            var _a;
            const dragImage = document.getElementById('drag-image');
            (_a = e === null || e === void 0 ? void 0 : e.dataTransfer) === null || _a === void 0 ? void 0 : _a.setDragImage(dragImage, 0, 0);
            const { selectedLayers, setSelectedLayers } = this.context;
            if (!selectedLayers.includes(layerName)) {
                setSelectedLayers([layerName]);
            }
            this.setState({
                draggingLayer: layerName,
            });
            console.log('onLayerDragStart', layerName);
        };
        this.onLayerCenterDragEnter = (layerName) => {
            const { selectedLayers } = this.context;
            if (selectedLayers.includes(layerName)) {
                this.setState({ draggingDestIndex: undefined });
            }
        };
        this.onSensorAreaDragEnter = (index) => {
            const { draggingDestIndex } = this.state;
            if (index !== draggingDestIndex) {
                this.setState({ draggingDestIndex: index });
            }
        };
        this.onLayerDragEnd = () => {
            const { draggingDestIndex } = this.state;
            const { selectedLayers } = this.context;
            if (draggingDestIndex !== null) {
                moveLayersToPosition(selectedLayers, draggingDestIndex);
                svgCanvas.sortTempGroupByLayer();
            }
            this.setState({
                draggingLayer: null,
                draggingDestIndex: null,
            });
        };
        this.preventDefault = (e) => {
            e.preventDefault();
        };
        this.draggingScroll = () => {
            const layerListContainer = this.layerListContainerRef.current;
            if (this.draggingScrollDirection !== 0 && layerListContainer) {
                if (this.draggingScrollDirection > 0) {
                    layerListContainer.scrollTop += 10;
                }
                else {
                    layerListContainer.scrollTop -= 10;
                }
            }
        };
        this.onLayerTouchStart = (layerName, e, delay = 800) => {
            if (this.currentTouchID === null) {
                this.currentTouchID = e.changedTouches[0].identifier;
                this.firstTouchInfo = {
                    pageX: e.changedTouches[0].pageX,
                    pageY: e.changedTouches[0].pageY,
                };
                this.startDragTimer = setTimeout(() => {
                    this.onLayerDragStart(layerName);
                    this.startDragTimer = null;
                    document.addEventListener('touchmove', this.preventDefault, { passive: false });
                    this.draggingScrollTimer = setInterval(this.draggingScroll, 100);
                }, delay);
            }
        };
        this.onLayerTouchMove = (e) => {
            const touch = Array.from(e.changedTouches).find((t) => t.identifier === this.currentTouchID);
            if (touch) {
                const { draggingLayer } = this.state;
                if (draggingLayer) {
                    const layerListContainer = this.layerListContainerRef.current;
                    const { top, height } = layerListContainer.getBoundingClientRect();
                    if (touch.pageY < top) {
                        this.draggingScrollDirection = -1;
                    }
                    else if (touch.pageY > top + height) {
                        this.draggingScrollDirection = 1;
                    }
                    else {
                        this.draggingScrollDirection = 0;
                        const elem = document
                            .elementsFromPoint(touch.pageX, touch.pageY)
                            .find((ele) => ele.hasAttribute('data-index') || ele.hasAttribute('data-layer'));
                        if (elem) {
                            if (elem.className.includes('drag-sensor-area')) {
                                const index = Number(elem.getAttribute('data-index'));
                                this.onSensorAreaDragEnter(index);
                            }
                            else if (elem.className.includes('row')) {
                                const name = elem.getAttribute('data-layer');
                                this.onLayerCenterDragEnter(name);
                            }
                        }
                    }
                }
                else if (this.startDragTimer) {
                    const { pageX, pageY } = this.firstTouchInfo;
                    if (Math.hypot(touch.pageX - pageX, touch.pageY - pageY) > 10) {
                        clearTimeout(this.startDragTimer);
                        this.startDragTimer = null;
                    }
                }
            }
        };
        this.onLayerTouchEnd = (e) => {
            const touch = Array.from(e.changedTouches).find((t) => t.identifier === this.currentTouchID);
            if (touch) {
                if (this.startDragTimer) {
                    clearTimeout(this.startDragTimer);
                    this.startDragTimer = null;
                }
                if (this.draggingScrollTimer) {
                    clearTimeout(this.draggingScrollTimer);
                    this.draggingScrollTimer = null;
                }
                const { draggingLayer } = this.state;
                this.currentTouchID = null;
                if (draggingLayer) {
                    document.removeEventListener('touchmove', this.preventDefault);
                    this.onLayerDragEnd();
                }
            }
        };
        this.layerDoubleClick = () => {
            this.renameLayer();
        };
        this.handleLayerClick = (e, layerName) => {
            const isCtrlOrCmd = (window.os === 'MacOS' && e.metaKey) || (window.os !== 'MacOS' && e.ctrlKey);
            if (e.button === 0) {
                if (isCtrlOrCmd) {
                    this.toggleLayerSelected(layerName);
                }
                else if (e.shiftKey) {
                    this.toggleContiguousSelectedUntil(layerName);
                }
                else {
                    this.selectOnlyLayer(layerName);
                }
            }
            else if (e.button === 2) {
                const { selectedLayers } = this.context;
                if (!selectedLayers.includes(layerName)) {
                    this.selectOnlyLayer(layerName);
                }
            }
        };
        this.state = {
            draggingDestIndex: null,
        };
        this.layerListContainerRef = React.createRef();
        this.currentTouchID = null;
        const initHeight = storage.get('layer-panel-height') || defaultLayerHeight;
        this.currentHeight = initHeight;
        window.addEventListener('beforeunload', () => {
            this.savePanelHeight();
        });
        layerPanelEventEmitter.on('startTutorial', this.startTutorial);
    }
    componentDidMount() {
        const { selectedLayers } = this.context;
        if (selectedLayers.length === 0) {
            this.initMultiSelectedLayer();
        }
    }
    componentDidUpdate() {
        const { hide } = this.props;
        if (hide)
            return;
        const { selectedLayers } = this.context;
        if (selectedLayers.length === 0) {
            this.initMultiSelectedLayer();
        }
    }
    componentWillUnmount() {
        this.savePanelHeight();
        layerPanelEventEmitter.off('startTutorial', this.startTutorial);
    }
    renderLayerPanel() {
        const { draggingDestIndex, draggingLayer } = this.state;
        const { selectedLayers, setSelectedLayers } = this.context;
        const drawing = svgCanvas.getCurrentDrawing();
        const isTouchable = navigator.maxTouchPoints >= 1;
        return (React.createElement("div", { id: "layerpanel", className: styles['layer-panel'], onMouseOut: () => highlightLayer(), onBlur: () => { } },
            React.createElement(ContextMenuTrigger, { id: "layer-contextmenu", holdToDisplay: isTouchable ? 1000 : -1, holdToDisplayMouse: -1, hideOnLeaveHoldPosition: true },
                React.createElement("div", { id: "layerlist_container", className: styles['layerlist-container'], ref: this.layerListContainerRef },
                    React.createElement(LayerList, { draggingDestIndex: draggingDestIndex, onLayerClick: this.handleLayerClick, highlightLayer: highlightLayer, onLayerDragStart: this.onLayerDragStart, onlayerDragEnd: this.onLayerDragEnd, onLayerTouchStart: this.onLayerTouchStart, onLayerTouchMove: this.onLayerTouchMove, onLayerTouchEnd: this.onLayerTouchEnd, onSensorAreaDragEnter: this.onSensorAreaDragEnter, onLayerCenterDragEnter: this.onLayerCenterDragEnter, onLayerDoubleClick: this.layerDoubleClick, onLayerColorChange: this.setLayerColor, setLayerVisibility: this.setLayerVisibility, unLockLayers: this.unLockLayers }))),
            !isMobile() && (React.createElement(React.Fragment, null,
                React.createElement(DragImage, { selectedLayers: selectedLayers, draggingLayer: draggingLayer }),
                React.createElement(LayerContextMenu, { drawing: drawing, selectOnlyLayer: this.selectOnlyLayer, renameLayer: this.renameLayer }),
                React.createElement(AddLayerButton, { setSelectedLayers: setSelectedLayers })))));
    }
    render() {
        if (!svgCanvas) {
            setTimeout(() => {
                this.forceUpdate();
            }, 50);
            return null;
        }
        const { setSelectedLayers } = this.context;
        const drawing = svgCanvas.getCurrentDrawing();
        // eslint-disable-next-line no-underscore-dangle
        const layerNames = drawing.all_layers.map((layer) => layer.name_);
        const { hide } = this.props;
        return (React.createElement("div", { id: "layer-and-laser-panel", className: classNames(styles.container, { [styles.hide]: hide }) }, isMobile() ? (React.createElement(React.Fragment, null,
            React.createElement(FloatingPanel, { className: styles['floating-panel'], anchors: [
                    0,
                    328,
                    window.innerHeight * 0.6,
                    window.innerHeight - layoutConstants.menuberHeight,
                ], title: LANG.layers.layer, fixedContent: React.createElement(AddLayerButton, { setSelectedLayers: setSelectedLayers }), onClose: () => RightPanelController.setDisplayLayer(false), forceClose: hide },
                React.createElement(ObjectPanelItem.Mask, null),
                this.renderLayerPanel()),
            React.createElement("div", { className: styles['layer-bottom-bar'] },
                React.createElement(ConfigPanel, { UIType: "panel-item" }),
                React.createElement(LayerContextMenu, { drawing: drawing, selectOnlyLayer: this.selectOnlyLayer, renameLayer: this.renameLayer })))) : (React.createElement(React.Fragment, null,
            React.createElement(ResizableBox, { axis: "y", height: this.currentHeight, minConstraints: [NaN, minLayerHeight], onResize: (_, { size }) => {
                    if (!this.isDoingTutorial)
                        this.currentHeight = size.height;
                }, handle: React.createElement(Handle, null) }, this.renderLayerPanel()),
            React.createElement(SelLayerBlock, { layerNames: layerNames }),
            React.createElement(ConfigPanel, null)))));
    }
}
LayerPanel.contextType = LayerPanelContext;
export default LayerPanel;
