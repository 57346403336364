var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable @typescript-eslint/no-unused-vars */
import alertCaller from 'app/actions/alert-caller';
import alertConfig from 'helpers/api/alert-config';
import alertConstants from 'app/constants/alert-constants';
import beamboxPreference from 'app/actions/beambox/beambox-preference';
import deviceMaster from 'helpers/device-master';
import i18n from 'helpers/i18n';
import { getSupportInfo } from 'app/constants/add-on';
import { getWorkarea } from 'app/constants/workarea-constants';
import { PreviewSpeedLevel } from 'app/actions/beambox/constant';
class BasePreviewManager {
    constructor(device) {
        this.isFullScreen = false;
        this.ended = false;
        this.lastPosition = [0, 0];
        this.maxMovementSpeed = [18000, 6000]; // mm/min, speed cap of machine
        this.setup = () => __awaiter(this, void 0, void 0, function* () {
            throw new Error('Method not implemented.');
        });
        this.end = () => __awaiter(this, void 0, void 0, function* () {
            this.ended = true;
            try {
                const res = yield deviceMaster.select(this.device);
                deviceMaster.disconnectCamera();
                if (res.success)
                    deviceMaster.kick();
            }
            catch (error) {
                console.error('Failed to end PreviewManager', error);
            }
        });
        this.preview = (x, y) => __awaiter(this, void 0, void 0, function* () {
            throw new Error('Method not implemented.');
        });
        this.previewRegion = (x1, y1, x2, y2) => __awaiter(this, void 0, void 0, function* () {
            throw new Error('Method not implemented.');
        });
        this.getMovementSpeed = () => {
            // fixed to 3600 for diode laser
            if (beamboxPreference.read('enable-diode') && getSupportInfo(this.workarea).hybridLaser)
                return 3600;
            const previewMovementSpeedLevel = beamboxPreference.read('preview_movement_speed_level');
            if (previewMovementSpeedLevel === PreviewSpeedLevel.FAST)
                return 12000;
            if (previewMovementSpeedLevel === PreviewSpeedLevel.MEDIUM)
                return 9000;
            return 6000;
        };
        /**
         * constrain the preview area
         * @param x x in px
         * @param y y in px
         */
        this.constrainPreviewXY = (x, y) => {
            const { pxWidth: width, pxHeight, pxDisplayHeight } = this.workareaObj;
            const height = pxDisplayHeight !== null && pxDisplayHeight !== void 0 ? pxDisplayHeight : pxHeight;
            return {
                x: Math.min(Math.max(x, 0), width),
                y: Math.min(Math.max(y, 0), height),
            };
        };
        this.device = device;
        // or use device.model?
        this.workarea = beamboxPreference.read('workarea');
        this.workareaObj = getWorkarea(this.workarea);
    }
    /**
     * getPhotoAfterMoveTo
     * @param movementX x in mm
     * @param movementY y in mm
     * @returns image blob url of the photo taken
     */
    getPhotoAfterMoveTo(movementX, movementY) {
        return __awaiter(this, void 0, void 0, function* () {
            const moveRes = yield this.moveTo(movementX, movementY);
            if (!moveRes)
                return null;
            const imgUrl = yield this.getPhotoFromMachine();
            return imgUrl;
        });
    }
    /**
     * Use raw command to move the camera to the target position
     * and wait an estimated time for the camera to take a stable picture
     * @param movementX x in mm
     * @param movementY y in mm
     */
    moveTo(movementX, movementY) {
        return __awaiter(this, void 0, void 0, function* () {
            const selectRes = yield deviceMaster.select(this.device);
            if (!selectRes.success)
                return false;
            const control = yield deviceMaster.getControl();
            if (control.getMode() !== 'raw')
                yield deviceMaster.enterRawMode();
            if (!this.movementSpeed)
                this.movementSpeed = this.getMovementSpeed();
            const movement = { f: this.movementSpeed, x: movementX, y: movementY };
            yield deviceMaster.rawMove(movement);
            const [lastX, lastY] = this.lastPosition;
            const [distX, distY] = [Math.abs(movementX - lastX), Math.abs(movementY - lastY)];
            const totalDist = Math.hypot(distX, distY);
            // the actual speed is limited by maxSpeedX and maxSpeedY
            const [maxSpeedX, maxSpeedY] = this.maxMovementSpeed;
            let timeToWait = Math.max(distX / maxSpeedX, distY / maxSpeedY, totalDist / this.movementSpeed) * 60000; // min => ms
            // wait for moving camera to take a stable picture, this value need to be optimized
            timeToWait *= 1.2;
            timeToWait += 100;
            this.lastPosition = [movementX, movementY];
            yield new Promise((r) => setTimeout(r, timeToWait));
            return true;
        });
    }
    getPhotoFromMachine() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const { lang } = i18n;
            const { imgBlob, needCameraCableAlert } = (_a = (yield deviceMaster.takeOnePicture())) !== null && _a !== void 0 ? _a : {};
            if (!imgBlob) {
                throw new Error(lang.message.camera.ws_closed_unexpectly);
            }
            else if (needCameraCableAlert && !alertConfig.read('skip_camera_cable_alert')) {
                const shouldContinue = yield new Promise((resolve) => {
                    alertCaller.popUp({
                        id: 'camera_cable_alert',
                        message: lang.message.camera.camera_cable_unstable,
                        type: alertConstants.SHOW_POPUP_WARNING,
                        checkbox: {
                            text: lang.beambox.popup.dont_show_again,
                            callbacks: () => alertConfig.write('skip_camera_cable_alert', true),
                        },
                        buttonLabels: [lang.message.camera.abort_preview, lang.message.camera.continue_preview],
                        callbacks: [() => resolve(false), () => resolve(true)],
                        primaryButtonIndex: 1,
                    });
                });
                if (!shouldContinue) {
                    this.end();
                    return null;
                }
            }
            const imgUrl = URL.createObjectURL(imgBlob);
            return imgUrl;
        });
    }
}
export default BasePreviewManager;
