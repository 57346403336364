import React from 'react';
import browser from 'implementations/browser';
import changelog from 'implementations/changelog';
import i18n from 'helpers/i18n';
import isWeb from 'helpers/is-web';
import { Button, Modal } from 'antd';
const LANG = i18n.lang.change_logs;
function ChangeLog({ onClose }) {
    const renderChangeLogs = () => {
        const CHANGES = i18n.getActiveLang().startsWith('zh') ? changelog.CHANGES_TW : changelog.CHANGES_EN;
        const logs = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const key of Object.keys(CHANGES)) {
            if (CHANGES[key].length > 0) {
                logs.push(React.createElement("strong", { className: "change-log-category", key: key }, LANG[key]));
                for (let i = 0; i < CHANGES[key].length; i += 1) {
                    logs.push(React.createElement("div", { className: "change-log-item", key: `${key}-${i}` },
                        React.createElement("span", { className: "index" }, `${i + 1}.`),
                        React.createElement("span", { className: "log" }, CHANGES[key][i])));
                }
            }
        }
        return logs;
    };
    const changeLogs = renderChangeLogs();
    if (changeLogs.length === 0) {
        onClose();
        return null;
    }
    const renderVersion = () => {
        const { version } = window.FLUX;
        if (isWeb())
            return null;
        return (React.createElement("div", { className: "app" },
            `📖 Beam Studio ${version.replace('-', ' ')} `,
            LANG.change_log));
    };
    const handleLink = () => {
        browser.open(LANG.help_center_url);
    };
    return (React.createElement(Modal, { open: true, centered: true, title: renderVersion(), onCancel: onClose, footer: [
            React.createElement(Button, { key: "older-version", onClick: handleLink }, LANG.see_older_version),
            React.createElement(Button, { type: "primary", key: "ok", onClick: onClose }, "OK"),
        ] },
        React.createElement("div", { className: "change-log-container" }, changeLogs)));
}
export default ChangeLog;
