import classNames from 'classnames';
import React, { useContext } from 'react';
import { FullWindowPanelContext } from 'app/widgets/FullWindowPanel/FullWindowPanel';
import styles from './Sider.module.scss';
const Sider = ({ className = '', children }) => {
    const { isDesktop, isWindows, isMobile } = useContext(FullWindowPanelContext);
    return (React.createElement("div", { className: classNames(styles.sider, {
            [styles.mobile]: isMobile,
            [styles.windows]: isWindows,
            [styles.desktop]: isDesktop,
        }, className) }, children));
};
export default Sider;
