import React, { useContext, useMemo } from 'react';
import beamboxStore from 'app/stores/beambox-store';
import constant from 'app/actions/beambox/constant';
import curveEngravingModeController from 'app/actions/canvas/curveEngravingModeController';
import isDev from 'helpers/is-dev';
import LeftPanelButton from 'app/components/beambox/left-panel/LeftPanelButton';
import LeftPanelIcons from 'app/icons/left-panel/LeftPanelIcons';
import localeHelper from 'helpers/locale-helper';
import PreviewModeBackgroundDrawer from 'app/actions/beambox/preview-mode-background-drawer';
import PreviewModeController from 'app/actions/beambox/preview-mode-controller';
import useI18n from 'helpers/useI18n';
import useForceUpdate from 'helpers/use-force-update';
import useWorkarea from 'helpers/hooks/useWorkarea';
import { CanvasContext } from 'app/contexts/CanvasContext';
import { getSVGAsync } from 'helpers/svg-editor-helper';
let svgCanvas;
getSVGAsync((globalSVG) => {
    svgCanvas = globalSVG.Canvas;
});
const PreviewToolButtonGroup = ({ className }) => {
    const lang = useI18n().beambox.left_panel;
    const workarea = useWorkarea();
    const isAdorSeries = useMemo(() => constant.adorModels.includes(workarea), [workarea]);
    const forceUpdate = useForceUpdate();
    const { setupPreviewMode, endPreviewMode } = useContext(CanvasContext);
    const startImageTrace = () => {
        endPreviewMode();
        svgCanvas.clearSelection();
        beamboxStore.emitShowCropper();
    };
    const clearPreview = () => {
        if (!PreviewModeBackgroundDrawer.isClean()) {
            PreviewModeBackgroundDrawer.resetCoordinates();
            PreviewModeBackgroundDrawer.clear();
        }
    };
    const isCanvasEmpty = PreviewModeController.isDrawing || PreviewModeBackgroundDrawer.isClean();
    const isLiveMode = PreviewModeController.isLiveModeOn();
    const isPreviewMode = PreviewModeController.isPreviewMode();
    return (React.createElement("div", { className: className },
        React.createElement(LeftPanelButton, { id: "preview-back", icon: React.createElement(LeftPanelIcons.Back, null), title: lang.label.end_preview, onClick: endPreviewMode }),
        React.createElement(LeftPanelButton, { id: "preview-shoot", icon: React.createElement(LeftPanelIcons.Shoot, null), title: lang.label.preview, active: true, onClick: () => {
                if (!PreviewModeController.isPreviewMode()) {
                    setupPreviewMode();
                }
            } }),
        isAdorSeries && !localeHelper.isNorthAmerica && (React.createElement(LeftPanelButton, { id: "preview-live", icon: React.createElement(LeftPanelIcons.Live, null), title: lang.label.live_feed, active: isLiveMode, disabled: !isPreviewMode, onClick: () => {
                if (PreviewModeController.isPreviewMode()) {
                    PreviewModeController.toggleFullWorkareaLiveMode();
                    forceUpdate();
                }
            } })),
        React.createElement(LeftPanelButton, { id: "image-trace", icon: React.createElement(LeftPanelIcons.Trace, null), title: lang.label.trace, disabled: isCanvasEmpty, onClick: startImageTrace }),
        isAdorSeries && (React.createElement(LeftPanelButton, { id: "adjust-height", icon: React.createElement(LeftPanelIcons.AdjustHeight, null), title: lang.label.adjust_height, disabled: !isPreviewMode, onClick: () => {
                if (PreviewModeController.isPreviewMode())
                    PreviewModeController.resetFishEyeObjectHeight();
            } })),
        React.createElement(LeftPanelButton, { id: "clear-preview", icon: React.createElement(LeftPanelIcons.Delete, null), title: lang.label.clear_preview, disabled: isCanvasEmpty, onClick: clearPreview }),
        isDev() && (React.createElement(LeftPanelButton, { id: "curve-engrave", icon: React.createElement(LeftPanelIcons.CurveEngrave, null), title: lang.label.curve_engraving.title, onClick: () => curveEngravingModeController.start() }))));
};
export default PreviewToolButtonGroup;
