import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'antd';
import i18n from 'helpers/i18n';
function MediaTutorial({ data, onClose }) {
    const LANG = i18n.lang.buttons;
    const [step, setStep] = useState(0);
    const videoRef = useRef();
    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.load();
        }
    }, [step]);
    const { mediaSources, isVideo, description } = data[step];
    const mediaContent = () => {
        if (isVideo) {
            return (React.createElement("video", { autoPlay: true, loop: true, muted: true, ref: videoRef, playsInline: true }, mediaSources.map(({ src, type }) => React.createElement("source", { key: src, src: src, type: type }))));
        }
        return (React.createElement("img", { src: mediaSources[0].src }));
    };
    const footer = [];
    if (step !== 0) {
        footer.push(React.createElement(Button, { key: "back", onClick: () => setStep(step - 1) }, LANG.back));
    }
    if (step === data.length - 1) {
        footer.push(React.createElement(Button, { key: "done", type: "primary", onClick: onClose }, LANG.done));
    }
    else {
        footer.push(React.createElement(Button, { key: "next", type: "primary", onClick: () => setStep(step + 1) }, LANG.next));
    }
    return (React.createElement(Modal, { open: true, centered: true, onCancel: onClose, footer: footer },
        React.createElement("div", { className: "media-tutorial" },
            React.createElement("div", { className: "media-container" }, mediaContent()),
            React.createElement("div", { className: "description" }, description),
            React.createElement("div", { className: "step" }, `${step + 1}/${data.length}`))));
}
export default MediaTutorial;
