import Delete from './delete.svg';
import Diff from './diff.svg';
import Duplicate from './duplicate.svg';
import Group from './group.svg';
import HAlignLeft from './halign-left.svg';
import HAlignMid from './halign-mid.svg';
import HAlignRight from './halign-right.svg';
import HDist from './hdist.svg';
import Intersect from './intersect.svg';
import Minus from './minus.svg';
import Parameter from './parameter.svg';
import Plus from './plus.svg';
import Subtract from './subtract.svg';
import Trash from './trash.svg';
import Ungroup from './ungroup.svg';
import Union from './union.svg';
import VAlignBot from './valign-bot.svg';
import VAlignMid from './valign-mid.svg';
import VAlignTop from './valign-top.svg';
import VDist from './vdist.svg';
export default {
    Delete,
    Diff,
    Duplicate,
    Group,
    HAlignLeft,
    HAlignMid,
    HAlignRight,
    HDist,
    Intersect,
    Minus,
    Parameter,
    Plus,
    Subtract,
    Trash,
    Ungroup,
    Union,
    VAlignBot,
    VAlignMid,
    VAlignTop,
    VDist,
};
