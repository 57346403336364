/* eslint-disable no-restricted-syntax */
import { MENU_SHOW, MENU_HIDE } from './actions';
import { uniqueId, hasOwnProp, canUseDOM } from './helpers';
class GlobalEventListener {
    constructor() {
        this.handleShowEvent = (event) => {
            for (const id in this.callbacks) {
                if (hasOwnProp(this.callbacks, id))
                    this.callbacks[id].show(event);
            }
        };
        this.handleHideEvent = (event) => {
            for (const id in this.callbacks) {
                if (hasOwnProp(this.callbacks, id))
                    this.callbacks[id].hide(event);
            }
        };
        this.register = (showCallback, hideCallback) => {
            const id = uniqueId();
            this.callbacks[id] = {
                show: showCallback,
                hide: hideCallback,
            };
            return id;
        };
        this.unregister = (id) => {
            if (id && this.callbacks[id]) {
                delete this.callbacks[id];
            }
        };
        this.callbacks = {};
        if (canUseDOM) {
            window.addEventListener(MENU_SHOW, this.handleShowEvent);
            window.addEventListener(MENU_HIDE, this.handleHideEvent);
        }
    }
}
export default new GlobalEventListener();
