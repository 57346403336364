import React, { useState } from 'react';
import { Menu as TopBarMenu, MenuItem, MenuDivider, SubMenu } from '@szhsin/react-menu';
import BeamboxPreference from 'app/actions/beambox/beambox-preference';
import browser from 'implementations/browser';
import constant from 'app/actions/beambox/constant';
import Discover from 'helpers/api/discover';
import eventEmitterFactory from 'helpers/eventEmitterFactory';
import hotkeys from 'app/constants/hotkeys';
import i18n from 'helpers/i18n';
import { modelsWithModules } from 'app/constants/layer-module/layer-modules';
import { useIsMobile } from 'helpers/system-helper';
let discover;
export default function Menu({ email }) {
    const eventEmitter = React.useMemo(() => eventEmitterFactory.createEventEmitter('top-bar-menu'), []);
    const [devices, setDevices] = useState(Array());
    const [shouldShowRulers, changeShouldShowRulers] = useState(BeamboxPreference.read('show_rulers'));
    const [shouldShowGrids, changeShouldShowGrids] = useState(BeamboxPreference.read('show_grids'));
    const [shouldUseLayerColor, changeShouldUseLayerColor] = useState(BeamboxPreference.read('use_layer_color'));
    const [isUsingAntiAliasing, setIsUsingAntiAliasing] = useState(BeamboxPreference.read('anti-aliasing'));
    const [shouldAlignToEdges, changeShouldAlignToEdges] = useState(BeamboxPreference.read('show_align_lines'));
    const [shouldZoomWithWindow, changeShouldZoomWithWindow] = useState(BeamboxPreference.read('zoom_with_window'));
    const [duplicateDisabled, setDuplicateDisabled] = useState(true);
    const [svgEditDisabled, setSvgEditDisabled] = useState(true);
    const [decomposePathDisabled, setDecomposePathDisabled] = useState(true);
    const [groupDisabled, setGroupDisabled] = useState(true);
    const [ungroupDisabled, setUngroupDisabled] = useState(true);
    const [imageEditDisabled, setImageEditDisabled] = useState(true);
    const menuItemUpdater = {
        DUPLICATE: setDuplicateDisabled,
        SVG_EDIT: setSvgEditDisabled,
        DECOMPOSE_PATH: setDecomposePathDisabled,
        GROUP: setGroupDisabled,
        UNGROUP: setUngroupDisabled,
        PHOTO_EDIT: setImageEditDisabled,
    };
    const isMobile = useIsMobile();
    React.useEffect(() => {
        eventEmitter.on('ENABLE_MENU_ITEM', (items) => {
            var _a;
            for (let i = 0; i < items.length; i += 1) {
                const item = items[i];
                (_a = menuItemUpdater[item]) === null || _a === void 0 ? void 0 : _a.call(menuItemUpdater, false);
            }
        });
        eventEmitter.on('DISABLE_MENU_ITEM', (items) => {
            var _a;
            for (let i = 0; i < items.length; i += 1) {
                const item = items[i];
                (_a = menuItemUpdater[item]) === null || _a === void 0 ? void 0 : _a.call(menuItemUpdater, true);
            }
        });
        return () => {
            eventEmitter.removeListener('ENABLE_MENU_ITEM');
            eventEmitter.removeListener('DISABLE_MENU_ITEM');
        };
    });
    React.useEffect(() => {
        discover = Discover('top-bar-menu', (newDevices) => {
            newDevices.sort((a, b) => (a.name >= b.name ? 1 : -1));
            if (newDevices.map((d) => d.name).join('') !== devices.map((d) => d.name).join('')) {
                setDevices(newDevices);
            }
        });
        return () => {
            discover.removeListener('top-bar-menu');
        };
    }, [devices]);
    const menuCms = i18n.lang.topbar.menu;
    const callback = (id, deviceSerial) => {
        eventEmitter.emit('MENU_CLICK', null, { id, serial: deviceSerial });
    };
    const openPage = (url) => browser.open(url);
    const hotkey = (action) => (React.createElement(React.Fragment, null,
        React.createElement("span", { className: "action" }, menuCms[action]),
        React.createElement("span", { className: "hotkey" }, hotkeys[action].representation)));
    const deviceMenus = () => {
        const submenus = [];
        for (let i = 0; i < devices.length; i += 1) {
            const { model, name, serial } = devices[i];
            const hasModules = modelsWithModules.has(model);
            submenus.push(React.createElement(SubMenu, { label: name, key: serial },
                React.createElement(MenuItem, { onClick: () => callback('DASHBOARD', serial) }, menuCms.dashboard),
                React.createElement(MenuItem, { onClick: () => callback('MACHINE_INFO', serial) }, menuCms.machine_info),
                React.createElement(MenuDivider, null),
                React.createElement(MenuItem, { onClick: () => callback('CALIBRATE_BEAMBOX_CAMERA', serial), disabled: isMobile },
                    menuCms.calibrate_beambox_camera,
                    " ",
                    isMobile && '(PC Only)'),
                hasModules && (React.createElement(MenuItem, { onClick: () => callback('CALIBRATE_PRINTER_MODULE', serial), disabled: isMobile }, menuCms.calibrate_printer_module)),
                hasModules && (React.createElement(MenuItem, { onClick: () => callback('CALIBRATE_IR_MODULE', serial), disabled: isMobile }, menuCms.calibrate_ir_module)),
                model === 'fbm1' ? (React.createElement(MenuItem, { onClick: () => callback('CALIBRATE_BEAMBOX_CAMERA_BORDERLESS', serial), disabled: isMobile },
                    menuCms.calibrate_beambox_camera_borderless,
                    " ",
                    isMobile && '(PC Only)')) : null,
                model === 'fbm1' ? (React.createElement(MenuItem, { onClick: () => callback('CALIBRATE_DIODE_MODULE', serial), disabled: isMobile },
                    menuCms.calibrate_diode_module,
                    " ",
                    isMobile && '(PC Only)')) : null,
                !constant.adorModels.includes(model) && React.createElement(MenuDivider, null),
                React.createElement(MenuItem, { onClick: () => callback('UPDATE_FIRMWARE', serial) }, menuCms.update_firmware),
                React.createElement(SubMenu, { label: menuCms.download_log },
                    React.createElement(MenuItem, { onClick: () => callback('LOG_NETWORK', serial) }, menuCms.log.network),
                    React.createElement(MenuItem, { onClick: () => callback('LOG_HARDWARE', serial) }, menuCms.log.hardware),
                    React.createElement(MenuItem, { onClick: () => callback('LOG_DISCOVER', serial) }, menuCms.log.discover),
                    React.createElement(MenuItem, { onClick: () => callback('LOG_USB', serial) }, menuCms.log.usb),
                    React.createElement(MenuItem, { onClick: () => callback('LOG_USBLIST', serial) }, menuCms.log.usblist),
                    React.createElement(MenuItem, { onClick: () => callback('LOG_CAMERA', serial) }, menuCms.log.camera),
                    React.createElement(MenuItem, { onClick: () => callback('LOG_CLOUD', serial) }, menuCms.log.cloud),
                    React.createElement(MenuItem, { onClick: () => callback('LOG_PLAYER', serial) }, menuCms.log.player),
                    React.createElement(MenuItem, { onClick: () => callback('LOG_ROBOT', serial) }, menuCms.log.robot))));
        }
        return submenus;
    };
    return (React.createElement(TopBarMenu, { menuButton: React.createElement("div", { className: "menu-btn-container" },
            React.createElement("img", { className: "icon", src: "img/logo-line.svg" }),
            React.createElement("img", { className: "icon-arrow", src: "img/icon-arrow-d.svg" })) },
        React.createElement(SubMenu, { label: menuCms.file },
            React.createElement(MenuItem, { onClick: () => callback('CLEAR_SCENE') }, hotkey('clear_scene')),
            React.createElement(MenuItem, { onClick: () => callback('OPEN') }, menuCms.open),
            React.createElement(MenuDivider, null),
            React.createElement(MenuItem, { onClick: () => callback('SAVE_SCENE') }, hotkey('save_scene')),
            React.createElement(MenuItem, { onClick: () => callback('SAVE_AS') }, hotkey('save_as')),
            React.createElement(MenuItem, { onClick: () => callback('SAVE_TO_CLOUD') }, menuCms.save_to_cloud),
            React.createElement(MenuDivider, null),
            React.createElement(SubMenu, { label: menuCms.samples },
                React.createElement(MenuItem, { onClick: () => callback('IMPORT_EXAMPLE_ADOR_LASER') }, menuCms.import_ador_laser_example),
                React.createElement(MenuItem, { onClick: () => callback('IMPORT_EXAMPLE_ADOR_PRINT_SINGLE') }, menuCms.import_ador_printing_example_single),
                React.createElement(MenuItem, { onClick: () => callback('IMPORT_EXAMPLE_ADOR_PRINT_FULL') }, menuCms.import_ador_printing_example_full),
                React.createElement(MenuItem, { onClick: () => callback('IMPORT_EXAMPLE') }, menuCms.import_hello_beamo),
                React.createElement(MenuItem, { onClick: () => callback('IMPORT_HELLO_BEAMBOX') }, menuCms.import_hello_beambox),
                React.createElement(MenuItem, { onClick: () => callback('IMPORT_MATERIAL_TESTING_ENGRAVE') }, menuCms.import_material_testing_engrave),
                React.createElement(MenuItem, { onClick: () => callback('IMPORT_MATERIAL_TESTING_OLD') }, menuCms.import_material_testing_old),
                React.createElement(MenuItem, { onClick: () => callback('IMPORT_MATERIAL_TESTING_CUT') }, menuCms.import_material_testing_cut),
                React.createElement(MenuItem, { onClick: () => callback('IMPORT_MATERIAL_TESTING_SIMPLECUT') }, menuCms.import_material_testing_simple_cut),
                React.createElement(MenuItem, { onClick: () => callback('IMPORT_MATERIAL_TESTING_LINE') }, menuCms.import_material_testing_line),
                React.createElement(MenuItem, { onClick: () => callback('IMPORT_MATERIAL_TESTING_PRINT') }, menuCms.import_material_printing_test),
                React.createElement(MenuItem, { onClick: () => callback('IMPORT_ACRYLIC_FOCUS_PROBE') }, menuCms.import_acrylic_focus_probe)),
            React.createElement(MenuDivider, null),
            React.createElement(SubMenu, { label: menuCms.export_to },
                React.createElement(MenuItem, { onClick: () => callback('EXPORT_BVG') }, menuCms.export_BVG),
                React.createElement(MenuItem, { onClick: () => callback('EXPORT_SVG') }, menuCms.export_SVG),
                React.createElement(MenuItem, { onClick: () => callback('EXPORT_PNG') }, menuCms.export_PNG),
                React.createElement(MenuItem, { onClick: () => callback('EXPORT_JPG') }, menuCms.export_JPG),
                React.createElement(MenuItem, { onClick: () => callback('EXPORT_FLUX_TASK') }, hotkey('export_flux_task'))),
            React.createElement(MenuDivider, null),
            React.createElement(MenuItem, { onClick: () => callback('PREFERENCE') }, hotkey('preferences'))),
        React.createElement(SubMenu, { label: menuCms.edit },
            React.createElement(MenuItem, { onClick: () => callback('UNDO') }, hotkey('undo')),
            React.createElement(MenuItem, { onClick: () => callback('REDO') }, hotkey('redo')),
            React.createElement(MenuDivider, null),
            React.createElement(MenuItem, { onClick: () => callback('CUT') }, hotkey('cut')),
            React.createElement(MenuItem, { onClick: () => callback('COPY') }, hotkey('copy')),
            React.createElement(MenuItem, { onClick: () => callback('PASTE') }, hotkey('paste')),
            React.createElement(MenuItem, { onClick: () => callback('PASTE_IN_PLACE') }, hotkey('paste_in_place')),
            React.createElement(MenuItem, { disabled: duplicateDisabled, onClick: () => callback('DUPLICATE') }, hotkey('duplicate')),
            React.createElement(MenuDivider, null),
            React.createElement(MenuItem, { disabled: groupDisabled, onClick: () => callback('GROUP') }, hotkey('group')),
            React.createElement(MenuItem, { disabled: ungroupDisabled, onClick: () => callback('UNGROUP') }, hotkey('ungroup')),
            React.createElement(MenuDivider, null),
            React.createElement(SubMenu, { label: menuCms.path },
                React.createElement(MenuItem, { onClick: () => callback('OFFSET') }, menuCms.offset),
                React.createElement(MenuItem, { disabled: decomposePathDisabled, onClick: () => callback('DECOMPOSE_PATH') }, menuCms.decompose_path)),
            React.createElement(SubMenu, { disabled: imageEditDisabled, label: menuCms.photo_edit },
                React.createElement(MenuItem, { onClick: () => callback('IMAGE_SHARPEN') }, menuCms.image_sharpen),
                React.createElement(MenuItem, { onClick: () => callback('IMAGE_CROP') }, menuCms.image_crop),
                React.createElement(MenuItem, { onClick: () => callback('IMAGE_INVERT') }, menuCms.image_invert),
                React.createElement(MenuItem, { onClick: () => callback('IMAGE_STAMP') }, menuCms.image_stamp),
                React.createElement(MenuItem, { onClick: () => callback('IMAGE_VECTORIZE') }, menuCms.image_vectorize),
                React.createElement(MenuItem, { onClick: () => callback('IMAGE_CURVE') }, menuCms.image_curve)),
            React.createElement(SubMenu, { disabled: svgEditDisabled, label: menuCms.svg_edit },
                React.createElement(MenuItem, { onClick: () => callback('DISASSEMBLE_USE') }, menuCms.disassemble_use)),
            React.createElement(SubMenu, { label: menuCms.layer_setting },
                React.createElement(MenuItem, { onClick: () => callback('LAYER_COLOR_CONFIG') }, menuCms.layer_color_config)),
            React.createElement(MenuDivider, null),
            React.createElement(MenuItem, { onClick: () => callback('DOCUMENT_SETTING') }, menuCms.document_setting),
            React.createElement(MenuItem, { onClick: () => callback('ROTARY_SETUP') }, menuCms.rotary_setup)),
        React.createElement(SubMenu, { label: menuCms.view },
            React.createElement(MenuItem, { className: "rc-menu__item--type-checkbox", onClick: () => callback('ZOOM_IN') }, hotkey('zoom_in')),
            React.createElement(MenuItem, { className: "rc-menu__item--type-checkbox", onClick: () => callback('ZOOM_OUT') }, hotkey('zoom_out')),
            React.createElement(MenuItem, { className: "rc-menu__item--type-checkbox", onClick: () => callback('FITS_TO_WINDOW') }, menuCms.fit_to_window),
            React.createElement(MenuItem, { type: "checkbox", onClick: () => {
                    callback('ZOOM_WITH_WINDOW');
                    changeShouldZoomWithWindow(!shouldZoomWithWindow);
                }, checked: shouldZoomWithWindow }, menuCms.zoom_with_window),
            React.createElement(MenuDivider, null),
            React.createElement(MenuItem, { type: "checkbox", onClick: () => {
                    callback('SHOW_GRIDS');
                    changeShouldShowGrids(!shouldShowGrids);
                }, checked: shouldShowGrids }, menuCms.show_grids),
            React.createElement(MenuItem, { type: "checkbox", onClick: () => {
                    callback('SHOW_RULERS');
                    changeShouldShowRulers(!shouldShowRulers);
                }, checked: shouldShowRulers }, menuCms.show_rulers),
            React.createElement(MenuItem, { type: "checkbox", onClick: () => {
                    callback('SHOW_LAYER_COLOR');
                    changeShouldUseLayerColor(!shouldUseLayerColor);
                }, checked: shouldUseLayerColor }, menuCms.show_layer_color),
            React.createElement(MenuItem, { type: "checkbox", onClick: () => {
                    callback('ALIGN_TO_EDGES');
                    changeShouldAlignToEdges(!shouldAlignToEdges);
                }, checked: shouldAlignToEdges }, menuCms.align_to_edges),
            React.createElement(MenuItem, { type: "checkbox", onClick: () => {
                    callback('ANTI_ALIASING');
                    setIsUsingAntiAliasing(!isUsingAntiAliasing);
                }, checked: isUsingAntiAliasing }, menuCms.anti_aliasing)),
        React.createElement(SubMenu, { label: menuCms.machines },
            React.createElement(MenuItem, { onClick: () => callback('ADD_NEW_MACHINE') }, hotkey('add_new_machine')),
            React.createElement(MenuItem, { onClick: () => callback('NETWORK_TESTING') }, menuCms.network_testing),
            deviceMenus()),
        React.createElement(SubMenu, { label: menuCms.tools.title },
            React.createElement(MenuItem, { onClick: () => callback('MATERIAL_TEST_GENERATOR') }, menuCms.tools.material_test_generator),
            React.createElement(MenuItem, { onClick: () => callback('QR_CODE_GENERATOR') }, menuCms.tools.qr_code_generator),
            React.createElement(MenuItem, { onClick: () => callback('BOX_GEN') }, menuCms.tools.boxgen)),
        React.createElement(SubMenu, { label: menuCms.account },
            email == null ? (React.createElement(MenuItem, { onClick: () => callback('SIGN_IN') }, menuCms.sign_in)) : (React.createElement(MenuItem, { onClick: () => callback('SIGN_OUT') }, `${menuCms.sign_out} (${email})`)),
            React.createElement(MenuItem, { onClick: () => openPage(menuCms.link.design_market) }, menuCms.design_market),
            React.createElement(MenuItem, { disabled: email === null, onClick: () => callback('MANAGE_ACCOUNT') }, menuCms.manage_account)),
        React.createElement(SubMenu, { label: menuCms.help },
            React.createElement(MenuItem, { onClick: () => callback('ABOUT_BEAM_STUDIO') }, menuCms.about_beam_studio),
            !isMobile && (React.createElement(MenuItem, { onClick: () => callback('START_TUTORIAL') }, menuCms.show_start_tutorial)),
            !isMobile && (React.createElement(MenuItem, { onClick: () => callback('START_UI_INTRO') }, menuCms.show_ui_intro)),
            React.createElement(MenuItem, { onClick: () => callback('START_GESTURE_INTRO') }, menuCms.show_gesture_tutorial),
            React.createElement(MenuItem, { onClick: () => callback('QUESTIONNAIRE') }, menuCms.questionnaire),
            React.createElement(MenuItem, { onClick: () => callback('CHANGE_LOGS') }, menuCms.change_logs),
            React.createElement(MenuItem, { onClick: () => openPage(menuCms.link.help_center) }, menuCms.help_center),
            React.createElement(MenuItem, { onClick: () => openPage(menuCms.link.shortcuts) }, menuCms.keyboard_shortcuts),
            React.createElement(MenuItem, { onClick: () => openPage(menuCms.link.contact_us) }, menuCms.contact),
            React.createElement(MenuDivider, null),
            React.createElement(MenuItem, { onClick: () => openPage(menuCms.link.forum) }, menuCms.forum))));
}
