import storage from 'implementations/storage';
const get = (serial, key) => {
    var _a, _b;
    const store = (_b = (_a = storage.get('promark-store')) === null || _a === void 0 ? void 0 : _a[serial]) !== null && _b !== void 0 ? _b : {};
    return (key ? store[key] : store);
};
const set = (serial, key, data) => {
    var _a;
    const store = (_a = storage.get('promark-store')) !== null && _a !== void 0 ? _a : {};
    store[serial] = Object.assign(Object.assign({}, store[serial]), { [key]: data });
    storage.set('promark-store', store);
};
export default {
    get,
    set,
};
