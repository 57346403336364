/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import classNames from 'classnames';
import AdorModule from 'app/components/settings/AdorModule';
import AutoSave from 'app/components/settings/AutoSave';
import autoSaveHelper from 'helpers/auto-save-helper';
import BeamboxPreference from 'app/actions/beambox/beambox-preference';
import Camera from 'app/components/settings/Camera';
import Connection from 'app/components/settings/Connection';
import Editor from 'app/components/settings/Editor';
import Engraving from 'app/components/settings/Engraving';
import Experimental from 'app/components/settings/Experimental';
import General from 'app/components/settings/General';
import i18n from 'helpers/i18n';
import isWeb from 'helpers/is-web';
import Mask from 'app/components/settings/Mask';
import Module from 'app/components/settings/Module';
import onOffOptionFactory from 'app/components/settings/onOffOptionFactory';
import Path from 'app/components/settings/Path';
import Privacy from 'app/components/settings/Privacy';
import settings from 'app/app-settings';
import storage from 'implementations/storage';
import TextToPath from 'app/components/settings/TextToPath';
import Update from 'app/components/settings/Update';
import { OptionValues } from 'app/constants/enums';
class Settings extends React.PureComponent {
    constructor(props) {
        super(props);
        this.changeActiveLang = (e) => {
            const target = e.currentTarget;
            i18n.setActiveLang(target.value);
            this.setState({
                lang: i18n.lang,
            });
        };
        this.updateConfigChange = (key, newVal) => {
            let val = newVal;
            if (!Number.isNaN(Number(val))) {
                val = Number(val);
            }
            this.configChanges[key] = val;
            this.forceUpdate();
        };
        this.getConfigEditingValue = (key) => {
            if (key in this.configChanges) {
                return this.configChanges[key];
            }
            return storage.get(key);
        };
        this.updateBeamboxPreferenceChange = (key, newVal) => {
            let val = newVal;
            if (val === OptionValues.TRUE) {
                val = true;
            }
            else if (val === OptionValues.FALSE) {
                val = false;
            }
            this.beamboxPreferenceChanges[key] = val;
            this.forceUpdate();
        };
        this.getBeamboxPreferenceEditingValue = (key) => {
            if (key in this.beamboxPreferenceChanges) {
                return this.beamboxPreferenceChanges[key];
            }
            return BeamboxPreference.read(key);
        };
        this.resetBS = () => {
            const { lang } = this.state;
            // eslint-disable-next-line no-alert
            if (window.confirm(lang.settings.confirm_reset)) {
                storage.clearAllExceptIP();
                localStorage.clear();
                autoSaveHelper.useDefaultConfig();
                window.location.hash = '#';
                window.location.reload();
            }
        };
        this.handleDone = () => {
            const { editingAutosaveConfig } = this.state;
            let keys = Object.keys(this.configChanges);
            for (let i = 0; i < keys.length; i += 1) {
                const key = keys[i];
                storage.set(key, this.configChanges[key]);
            }
            keys = Object.keys(this.beamboxPreferenceChanges);
            for (let i = 0; i < keys.length; i += 1) {
                const key = keys[i];
                BeamboxPreference.write(key, this.beamboxPreferenceChanges[key]);
            }
            autoSaveHelper.setConfig(editingAutosaveConfig);
            window.location.hash = '#studio/beambox';
            window.location.reload();
        };
        this.handleCancel = () => {
            i18n.setActiveLang(this.origLang);
            window.location.hash = '#studio/beambox';
            window.location.reload();
        };
        this.onOffOptionFactory = (isOnSelected, onValue, offValue, onLabel, offLabel) => {
            const { lang } = this.state;
            return onOffOptionFactory(isOnSelected, {
                onValue,
                offValue,
                onLabel,
                offLabel,
                lang,
            });
        };
        this.state = {
            lang: i18n.lang,
            editingAutosaveConfig: autoSaveHelper.getConfig(),
            selectedModel: BeamboxPreference.read('model') || 'fbm1',
            warnings: {},
        };
        this.origLang = i18n.getActiveLang();
        this.beamboxPreferenceChanges = {};
        this.configChanges = {};
    }
    render() {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { supported_langs } = settings.i18n;
        const { lang, selectedModel, editingAutosaveConfig, warnings } = this.state;
        const isNotificationOn = this.getConfigEditingValue('notification') === 1;
        const notificationOptions = this.onOffOptionFactory(isNotificationOn, 1, 0, lang.settings.notification_on, lang.settings.notification_off);
        const isAutoCheckUpdateOn = this.getConfigEditingValue('auto_check_update') !== 0;
        const updateNotificationOptions = this.onOffOptionFactory(isAutoCheckUpdateOn, 1, 0, lang.settings.notification_on, lang.settings.notification_off);
        const isGuessingPokeOn = this.getConfigEditingValue('guessing_poke') !== 0;
        const guessingPokeOptions = this.onOffOptionFactory(isGuessingPokeOn, 1, 0);
        const isAutoConnectOn = this.getConfigEditingValue('auto_connect') !== 0;
        const autoConnectOptions = this.onOffOptionFactory(isAutoConnectOn, 1, 0);
        const isSentryEnabled = this.getConfigEditingValue('enable-sentry') === 1;
        const enableSentryOptions = this.onOffOptionFactory(isSentryEnabled, 1, 0);
        const isCustomPrevHeightEnabled = this.getBeamboxPreferenceEditingValue('enable-custom-preview-height');
        const enableCustomPreviewHeightOptions = this.onOffOptionFactory(isCustomPrevHeightEnabled);
        const isMultipassCompensationEnabled = this.getBeamboxPreferenceEditingValue('multipass-compensation') !== false;
        const multipassCompensationOptions = this.onOffOptionFactory(isMultipassCompensationEnabled);
        const oneWayPrintingEnabled = this.getBeamboxPreferenceEditingValue('one-way-printing') !== false;
        const oneWayPrintingOptions = this.onOffOptionFactory(oneWayPrintingEnabled);
        const autoSaveOptions = this.onOffOptionFactory(editingAutosaveConfig.enabled);
        const isAllValid = !warnings || Object.keys(warnings).length === 0;
        const web = isWeb();
        const defaultUnit = this.getConfigEditingValue('default-units');
        return (React.createElement("div", { className: "studio-container settings-studio" },
            React.createElement("div", { className: "settings-gradient-overlay" }),
            React.createElement("div", { className: "form general" },
                React.createElement(General, { isWeb: web, supportedLangs: supported_langs, notificationOptions: notificationOptions, changeActiveLang: this.changeActiveLang, updateConfigChange: this.updateConfigChange }),
                React.createElement(Update, { isWeb: web, updateNotificationOptions: updateNotificationOptions, updateConfigChange: this.updateConfigChange }),
                React.createElement(Connection, { originalIP: this.getConfigEditingValue('poke-ip-addr'), guessingPokeOptions: guessingPokeOptions, autoConnectOptions: autoConnectOptions, updateConfigChange: this.updateConfigChange }),
                React.createElement(AutoSave, { isWeb: web, autoSaveOptions: autoSaveOptions, editingAutosaveConfig: editingAutosaveConfig, warnings: warnings, updateState: (state) => this.setState(state) }),
                React.createElement(Camera, { enableCustomPreviewHeightOptions: enableCustomPreviewHeightOptions, getBeamboxPreferenceEditingValue: this.getBeamboxPreferenceEditingValue, updateBeamboxPreferenceChange: this.updateBeamboxPreferenceChange }),
                React.createElement(Editor, { defaultUnit: defaultUnit, selectedModel: selectedModel, getBeamboxPreferenceEditingValue: this.getBeamboxPreferenceEditingValue, updateConfigChange: this.updateConfigChange, updateBeamboxPreferenceChange: this.updateBeamboxPreferenceChange, updateModel: (newModel) => this.setState({ selectedModel: newModel }) }),
                React.createElement(Engraving, { getBeamboxPreferenceEditingValue: this.getBeamboxPreferenceEditingValue, updateBeamboxPreferenceChange: this.updateBeamboxPreferenceChange }),
                React.createElement(Path, { selectedModel: selectedModel, defaultUnit: defaultUnit, loopCompensation: this.getConfigEditingValue('loop_compensation'), getBeamboxPreferenceEditingValue: this.getBeamboxPreferenceEditingValue, updateConfigChange: this.updateConfigChange, updateBeamboxPreferenceChange: this.updateBeamboxPreferenceChange }),
                React.createElement(Mask, { getBeamboxPreferenceEditingValue: this.getBeamboxPreferenceEditingValue, updateBeamboxPreferenceChange: this.updateBeamboxPreferenceChange }),
                React.createElement(TextToPath, { getBeamboxPreferenceEditingValue: this.getBeamboxPreferenceEditingValue, updateBeamboxPreferenceChange: this.updateBeamboxPreferenceChange }),
                React.createElement(Module, { defaultUnit: defaultUnit, selectedModel: selectedModel, getBeamboxPreferenceEditingValue: this.getBeamboxPreferenceEditingValue, updateBeamboxPreferenceChange: this.updateBeamboxPreferenceChange }),
                React.createElement(AdorModule, { defaultUnit: defaultUnit, selectedModel: selectedModel, getBeamboxPreferenceEditingValue: this.getBeamboxPreferenceEditingValue, updateBeamboxPreferenceChange: this.updateBeamboxPreferenceChange }),
                React.createElement(Privacy, { enableSentryOptions: enableSentryOptions, updateConfigChange: this.updateConfigChange }),
                React.createElement(Experimental, { multipassCompensationOptions: multipassCompensationOptions, oneWayPrintingOptions: oneWayPrintingOptions, updateBeamboxPreferenceChange: this.updateBeamboxPreferenceChange }),
                React.createElement("div", { className: "font5", onClick: this.resetBS },
                    React.createElement("b", null, lang.settings.reset_now)),
                React.createElement("div", { className: "clearfix" }),
                React.createElement("div", { className: classNames('btn btn-done', { disabled: !isAllValid }), onClick: this.handleDone }, lang.settings.done),
                React.createElement("div", { className: "btn btn-cancel", onClick: this.handleCancel }, lang.settings.cancel))));
    }
}
export default Settings;
