import classNames from 'classnames';
import React, { useContext } from 'react';
import { FullWindowPanelContext } from 'app/widgets/FullWindowPanel/FullWindowPanel';
import styles from './Footer.module.scss';
const Footer = ({ className = '', children }) => {
    const { isDesktop, isWindows, isMobile } = useContext(FullWindowPanelContext);
    return (React.createElement("div", { className: classNames(styles.footer, {
            [styles.mobile]: isMobile,
            [styles.windows]: isWindows,
            [styles.desktop]: isDesktop,
        }, className) }, children));
};
export default Footer;
