export default {
    i18n: {
        default_lang: 'en',
        supported_langs: {
            cs: 'Czech',
            da: 'Dansk',
            de: 'Deutsch',
            el: 'Ελληνικά',
            en: 'English',
            es: 'Español',
            fi: 'Suomi',
            fr: 'Français',
            id: 'Bahasa Indonesia',
            it: 'Italiano',
            ja: '日本語',
            kr: '한국어',
            ms: 'Melayu',
            nl: 'Nederlands',
            no: 'Norsk',
            pl: 'Polski',
            pt: 'Português',
            se: 'Svenska',
            th: 'ภาษาไทย',
            vi: 'Tiếng Việt',
            'zh-cn': '简体中文',
            'zh-tw': '繁體中文',
        },
    },
    laser_default: {
        material: {
            value: 'custom',
            label: 'Custom',
            data: {
                laser_speed: 3,
                power: 255,
            },
        },
        objectHeight: 0,
        isShading: true,
    },
};
