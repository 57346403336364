var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-console */
import { sprintf } from 'sprintf-js';
import Alert from 'app/actions/alert-caller';
import AlertConstants from 'app/constants/alert-constants';
import checkSoftwareForAdor from 'helpers/check-software';
import constant, { promarkModels } from 'app/actions/beambox/constant';
import DeviceConstants from 'app/constants/device-constants';
import Dialog from 'app/actions/dialog-caller';
import InputLightBoxConstants from 'app/constants/input-lightbox-constants';
import Progress from 'app/actions/progress-caller';
import storage from 'implementations/storage';
import { ConnectionError } from 'app/constants/connection-constants';
import Camera from './api/camera';
import Control from './api/control';
import Discover from './api/discover';
import i18n from './i18n';
import promarkDataStore from './device/promark-data-store';
import SwiftrayControl from './api/swiftray-control';
import Touch from './api/touch';
import VersionChecker from './version-checker';
import { swiftrayClient } from './api/swiftray-client';
let { lang } = i18n;
const updateLang = () => {
    lang = i18n.lang;
};
class DeviceMaster {
    constructor() {
        this.unnotifiedDeviceUUIDs = [];
        this.scanDeviceError = (devices) => {
            devices.forEach((info) => {
                const deviceConn = this.getDeviceByUUID(info.uuid);
                if (typeof deviceConn.errors === 'string') {
                    if (deviceConn.errors !== info.error_label && info.error_label) {
                        Alert.popUp({
                            type: AlertConstants.SHOW_POPUP_ERROR,
                            message: `${info.name}: ${info.error_label}`,
                        });
                        deviceConn.errors = info.error_label;
                    }
                    else if (!info.error_label) {
                        deviceConn.errors = [];
                    }
                }
                else {
                    deviceConn.errors = [];
                }
                const { PAUSED_FROM_RUNNING, COMPLETED, ABORTED } = DeviceConstants.status;
                if ([PAUSED_FROM_RUNNING, COMPLETED, ABORTED].includes(info.st_id)) {
                    if (this.unnotifiedDeviceUUIDs.find((uuid) => uuid === info.uuid)) {
                        let message = '';
                        if (deviceConn.info.st_id === DeviceConstants.status.COMPLETED) {
                            message = `${lang.device.completed}`;
                        }
                        else if (deviceConn.info.st_id === DeviceConstants.status.ABORTED) {
                            message = `${lang.device.aborted}`;
                        }
                        else {
                            if (!info.error_label) {
                                return;
                            }
                            message = `${lang.device.pausedFromError}`;
                            message = deviceConn.info.error_label === '' ? '' : message;
                        }
                        const index = this.unnotifiedDeviceUUIDs.findIndex((uuid) => uuid === info.uuid);
                        this.unnotifiedDeviceUUIDs.splice(index, 1);
                        if (storage.get('notification') === 1) {
                            Notification.requestPermission((permission) => {
                                if (permission === 'granted') {
                                    const notification = new Notification(deviceConn.info.name, {
                                        icon: 'img/icon-home-s.png',
                                        body: message,
                                    });
                                    console.log(notification);
                                }
                            });
                        }
                    }
                }
                else if ([DeviceConstants.status.RUNNING].includes(info.st_id)) {
                    if (!this.unnotifiedDeviceUUIDs.find((uuid) => uuid === info.uuid)) {
                        this.unnotifiedDeviceUUIDs.push(info.uuid);
                    }
                }
            });
        };
        this.doCalibration = (fcodeSource) => __awaiter(this, void 0, void 0, function* () {
            const vc = VersionChecker(this.currentDevice.info.version);
            let blob;
            if (fcodeSource) {
                const resp = yield fetch(fcodeSource);
                blob = yield resp.blob();
            }
            else {
                // fake data to upload for swiftray
                blob = new Blob(['f']);
            }
            if (vc.meetRequirement('RELOCATE_ORIGIN')) {
                yield this.setOriginX(0);
                yield this.setOriginY(0);
            }
            try {
                // Stop if there is any task running
                yield this.stop();
            }
            catch (_a) {
                // ignore
            }
            try {
                yield this.go(blob);
            }
            catch (_b) {
                throw Error('UPLOAD_FAILED');
            }
            Progress.openSteppingProgress({
                id: 'cali-task',
                message: lang.calibration.drawing_calibration_image,
                onCancel: () => __awaiter(this, void 0, void 0, function* () {
                    yield this.stop();
                    yield this.quit();
                }),
            });
            const onProgress = (progress) => Progress.update('cali-task', {
                percentage: Math.round(progress * 100),
            });
            try {
                yield this.waitTillCompleted(onProgress);
                Progress.popById('cali-task');
            }
            catch (err) {
                Progress.popById('cali-task');
                throw err; // Error while running test
            }
        });
        // update functions
        this.updateFirmware = (file, onProgress) => __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            if (onProgress) {
                controlSocket.setProgressListener(onProgress);
            }
            return controlSocket.addTask(controlSocket.fwUpdate, file);
        });
        this.uploadFisheyeParams = (data, onProgress) => __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            if (onProgress) {
                controlSocket.setProgressListener(onProgress);
            }
            return controlSocket.addTask(controlSocket.uploadFisheyeParams, data);
        });
        /**
         * @deprecated Use V2 calibration functions instead so we don't have to set 3d rotation
         */
        this.updateFisheye3DRotation = (data) => __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            return controlSocket.addTask(controlSocket.updateFisheye3DRotation, data);
        });
        updateLang();
        this.deviceConnections = new Map();
        this.discoveredDevices = [];
        Discover('device-master', (devices) => {
            this.discoveredDevices = devices;
            this.scanDeviceError(devices);
        });
    }
    // device are stored in array _devices
    getAvailableDevices() {
        return this.discoveredDevices;
    }
    get currentControlMode() {
        if (this.currentDevice && this.currentDevice.control) {
            return this.currentDevice.control.getMode();
        }
        return null;
    }
    getDeviceByUUID(uuid) {
        if (!this.deviceConnections.get(uuid)) {
            this.deviceConnections.set(uuid, {
                info: {
                    uuid,
                },
                control: null,
                errors: null,
                camera: null,
                cameraNeedsFlip: null,
            });
        }
        const matchedInfo = this.discoveredDevices.filter((d) => d.uuid === uuid);
        if (matchedInfo[0]) {
            Object.assign(this.deviceConnections.get(uuid).info, matchedInfo[0]);
        }
        return this.deviceConnections.get(uuid);
    }
    createDeviceControlSocket(uuid) {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = new Control(uuid);
            yield controlSocket.connect();
            return controlSocket;
        });
    }
    createDeviceControlSocketSwiftray(uuid) {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = new SwiftrayControl(uuid);
            yield controlSocket.connect();
            return controlSocket;
        });
    }
    setDeviceControlDefaultCloseListener(deviceInfo) {
        const { uuid } = deviceInfo;
        const device = this.deviceConnections.get(uuid);
        if (!device || !device.control) {
            console.warn(`Control socket of ${uuid} does not exist`);
            return;
        }
        const controlSocket = device.control;
        controlSocket.removeAllListeners('close');
        controlSocket.on('close', () => {
            this.closeConnection(uuid);
        });
    }
    setDeviceControlReconnectOnClose(deviceInfo) {
        const { uuid } = deviceInfo;
        const device = this.deviceConnections.get(uuid);
        if (!device || !device.control) {
            console.warn(`Control socket of ${uuid} does not exist`);
            return;
        }
        const controlSocket = device.control;
        controlSocket.removeAllListeners('close');
        controlSocket.on('close', () => __awaiter(this, void 0, void 0, function* () {
            console.log(`Reconnecting ${uuid}`);
            const mode = controlSocket.getMode();
            const { isLineCheckMode, lineNumber } = controlSocket;
            const res = yield this.select(deviceInfo);
            if (res && res.success) {
                if (mode === 'maintain') {
                    yield this.enterMaintainMode();
                }
                else if (mode === 'raw') {
                    yield this.enterRawMode();
                    controlSocket.isLineCheckMode = isLineCheckMode;
                    controlSocket.lineNumber = lineNumber;
                }
                if (device.camera !== null) {
                    yield this.connectCamera();
                }
                this.setDeviceControlReconnectOnClose(deviceInfo);
            }
            else {
                console.error('Error when reconnect to device', res.error);
                this.closeConnection(uuid);
            }
        }));
    }
    showAuthDialog(uuid) {
        return __awaiter(this, void 0, void 0, function* () {
            // return authed or not
            const device = this.getDeviceByUUID(uuid);
            const authResult = yield new Promise((resolve) => {
                Dialog.showInputLightbox('auth', {
                    caption: sprintf(lang.input_machine_password.require_password, device.info.name),
                    inputHeader: lang.input_machine_password.password,
                    confirmText: lang.input_machine_password.connect,
                    type: InputLightBoxConstants.TYPE_PASSWORD,
                    onSubmit: (password) => __awaiter(this, void 0, void 0, function* () {
                        resolve(yield this.auth(device.info.uuid, password));
                    }),
                    onCancel: () => {
                        resolve({ success: false, data: 'cancel', password: '' });
                    },
                });
            });
            if (authResult.success) {
                device.info.plaintext_password = authResult.password;
                return true;
            }
            if (authResult.data !== 'cancel') {
                const message = authResult.data.reachable
                    ? lang.select_device.auth_failure
                    : lang.select_device.unable_to_connect;
                Alert.popById('device-auth-fail');
                Alert.popUp({
                    id: 'device-auth-fail',
                    message,
                    type: AlertConstants.SHOW_POPUP_ERROR,
                });
                // Display the dialog again
                const res = yield this.showAuthDialog(device.info.uuid);
                return res;
            }
            return false;
        });
    }
    auth(uuid, password) {
        return __awaiter(this, void 0, void 0, function* () {
            Progress.openNonstopProgress({
                id: 'device-master-auth',
                message: lang.message.authenticating,
                timeout: 30000,
            });
            const res = yield new Promise((resolve) => {
                Touch({
                    onError: (data) => {
                        Progress.popById('device-master-auth');
                        resolve({ success: false, data, password });
                    },
                    onSuccess: (data) => {
                        Progress.popById('device-master-auth');
                        resolve({ success: true, data, password });
                    },
                    onFail: (data) => {
                        Progress.popById('device-master-auth');
                        resolve({ success: false, data, password });
                    },
                }).send(uuid, password || '');
            });
            return res;
        });
    }
    select(deviceInfo) {
        return __awaiter(this, void 0, void 0, function* () {
            console.log('selecting ', deviceInfo);
            if (deviceInfo.source === 'swiftray') {
                return this.selectDeviceWithSwiftray(deviceInfo);
            }
            return this.selectDeviceWithGhost(deviceInfo);
        });
    }
    selectDeviceWithGhost(deviceInfo) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            // Match the device from the newest received device list
            if (!deviceInfo || !checkSoftwareForAdor(deviceInfo)) {
                return { success: false };
            }
            const { uuid } = deviceInfo;
            // kill existing camera connection
            if (((_b = (_a = this.currentDevice) === null || _a === void 0 ? void 0 : _a.info) === null || _b === void 0 ? void 0 : _b.uuid) !== uuid) {
                this.disconnectCamera();
            }
            const device = this.getDeviceByUUID(uuid);
            console.log('Selecting', deviceInfo, device);
            Progress.openNonstopProgress({
                id: 'select-device',
                message: sprintf(lang.message.connectingMachine, device.info.name || deviceInfo.name),
                timeout: 30000,
            });
            if (device.control && device.control.isConnected) {
                try {
                    // Update device status
                    if (device.control.getMode() !== 'raw') {
                        const controlSocket = device.control;
                        const info = yield controlSocket.addTask(controlSocket.report);
                        Object.assign(device.info, info.device_status);
                    }
                    this.currentDevice = device;
                    Progress.popById('select-device');
                    return { success: true };
                }
                catch (e) {
                    yield device.control.killSelf();
                }
            }
            try {
                const controlSocket = yield this.createDeviceControlSocket(uuid);
                device.control = controlSocket;
                this.setDeviceControlDefaultCloseListener(deviceInfo);
                this.currentDevice = device;
                console.log(`Connected to ${uuid}`);
                Progress.popById('select-device');
                return {
                    success: true,
                };
            }
            catch (e) {
                let error = e;
                Progress.popById('select-device');
                console.error(error);
                if (e.error)
                    error = e.error;
                let errorCode = '';
                if (typeof error === 'string') {
                    errorCode = error.replace(/^.*:\s+(\w+)$/g, '$1').toUpperCase();
                }
                // AUTH_FAILED seems to not be used by firmware and fluxghost anymore. Keep it just in case.
                if ([ConnectionError.AUTH_ERROR, ConnectionError.AUTH_FAILED].includes(errorCode)) {
                    return yield this.runAuthProcess(uuid, device, deviceInfo);
                }
                this.popConnectionError(uuid, errorCode);
                return {
                    success: false,
                    error: errorCode,
                };
            }
            finally {
                Progress.popById('select-device');
            }
        });
    }
    selectDeviceWithSwiftray(deviceInfo) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function* () {
            // Match the device from the newest received device list
            if (!deviceInfo)
                return { success: false };
            const { uuid } = deviceInfo;
            // kill existing camera connection
            if (((_b = (_a = this.currentDevice) === null || _a === void 0 ? void 0 : _a.info) === null || _b === void 0 ? void 0 : _b.uuid) !== uuid) {
                this.disconnectCamera();
            }
            const device = this.getDeviceByUUID(uuid);
            console.log('Selecting', deviceInfo, device);
            Progress.openNonstopProgress({
                id: 'select-device',
                message: sprintf(lang.message.connectingMachine, device.info.name || deviceInfo.name),
                timeout: 30000,
            });
            try {
                const controlSocket = yield this.createDeviceControlSocketSwiftray(uuid);
                device.control = controlSocket;
                this.setDeviceControlDefaultCloseListener(deviceInfo);
                this.currentDevice = device;
                console.log(`Connected to ${uuid}`);
                // In order to update serial
                const res = yield swiftrayClient.listDevices();
                if (res.success) {
                    const newInfo = (_c = res.devices) === null || _c === void 0 ? void 0 : _c.find((d) => d.uuid === uuid);
                    if (newInfo) {
                        device.info = newInfo;
                        Object.assign(deviceInfo, newInfo);
                    }
                }
                if (promarkModels.has(device.info.model)) {
                    const correction = promarkDataStore.get(device.info.serial, 'lensCorrection');
                    console.log('Applying', correction);
                    if (correction) {
                        yield controlSocket.addTask(controlSocket.setLensCorrection, correction.x, correction.y);
                    }
                }
                Progress.popById('select-device');
                return {
                    success: true,
                };
            }
            catch (e) {
                let error = e;
                Progress.popById('select-device');
                console.error(error);
                if (e.error)
                    error = e.error;
                let errorCode = '';
                if (typeof error === 'string') {
                    errorCode = error.replace(/^.*:\s+(\w+)$/g, '$1').toUpperCase();
                }
                // AUTH_FAILED seems to not be used by firmware and fluxghost anymore. Keep it just in case.
                if ([ConnectionError.AUTH_ERROR, ConnectionError.AUTH_FAILED].includes(errorCode)) {
                    return yield this.runAuthProcess(uuid, device, deviceInfo);
                }
                this.popConnectionError(uuid, errorCode);
                return {
                    success: false,
                    error: errorCode,
                };
            }
            finally {
                Progress.popById('select-device');
            }
        });
    }
    runAuthProcess(uuid, device, deviceInfo) {
        return __awaiter(this, void 0, void 0, function* () {
            if (device.info.password) {
                const authed = yield this.showAuthDialog(uuid);
                if (authed) {
                    const selectionResultAfterAuthed = yield this.select(deviceInfo);
                    return selectionResultAfterAuthed;
                }
                return { success: false };
            }
            Progress.openNonstopProgress({
                id: 'select-device',
                message: sprintf(lang.message.connectingMachine, device.info.name),
                timeout: 30000,
            });
            const authResult = yield this.auth(uuid);
            if (!authResult.success) {
                Progress.popById('select-device');
                Alert.popUp({
                    id: 'auth-error-with-diff-computer',
                    message: lang.message.auth_error,
                    type: AlertConstants.SHOW_POPUP_ERROR,
                });
                return { success: false };
            }
            const selectionResultAfterAuthed = yield this.select(deviceInfo);
            return selectionResultAfterAuthed;
        });
    }
    popConnectionError(uuid, errorCode) {
        let errCaption = '';
        let errMessage = lang.message.unknown_error;
        switch (errorCode) {
            case ConnectionError.TIMEOUT:
                errMessage = lang.message.connectionTimeout;
                break;
            case ConnectionError.FLUXMONITOR_VERSION_IS_TOO_OLD:
                errMessage = lang.message.monitor_too_old.content;
                errCaption = lang.message.monitor_too_old.caption;
                break;
            case ConnectionError.NOT_FOUND:
                errMessage = lang.message.unable_to_find_machine;
                break;
            case ConnectionError.DISCONNECTED:
                errMessage = `#891 ${lang.message.disconnected}`;
                if (this.discoveredDevices.some((d) => d.uuid === uuid)) {
                    errMessage = `#892 ${lang.message.disconnected}`;
                }
                break;
            case ConnectionError.UNKNOWN_DEVICE:
                errMessage = lang.message.unknown_device;
                break;
            default:
                errMessage = `${lang.message.unknown_error} ${errorCode}`;
        }
        Alert.popById('connection-error');
        Alert.popUp({
            id: 'connection-error',
            caption: errCaption,
            message: errMessage,
            type: AlertConstants.SHOW_POPUP_ERROR,
        });
    }
    getControl() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.currentDevice) {
                return null;
            }
            const controlSocket = this.currentDevice.control;
            if (controlSocket)
                return controlSocket;
            const res = yield this.reconnect();
            if (res.success) {
                return this.currentDevice.control;
            }
            return null;
        });
    }
    reconnect() {
        return __awaiter(this, void 0, void 0, function* () {
            this.deviceConnections.delete(this.currentDevice.info.uuid);
            try {
                yield this.currentDevice.control.killSelf();
            }
            catch (e) {
                console.error(`currentDevice.control.killSelf error ${e}`);
            }
            const res = yield this.select(this.currentDevice.info);
            return res;
        });
    }
    closeConnection(uuid) {
        const device = this.getDeviceByUUID(uuid);
        if (device.control) {
            try {
                // Warning: access to private property
                device.control.connection.close();
            }
            catch (e) {
                console.error('Error when close control connection', e);
            }
        }
        device.control = null;
    }
    // Player functions
    go(data, onProgress) {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            if (!data || !(data instanceof Blob)) {
                return DeviceConstants.READY;
            }
            if (onProgress) {
                controlSocket.setProgressListener(onProgress);
            }
            yield controlSocket.addTask(controlSocket.upload, data);
            yield controlSocket.addTask(controlSocket.start);
            return null;
        });
    }
    goFromFile(path, fileName) {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            const selectResult = yield controlSocket.addTask(controlSocket.select, path, fileName);
            if (selectResult.status.toUpperCase() === DeviceConstants.OK) {
                const startResult = yield controlSocket.addTask(controlSocket.start);
                return startResult;
            }
            return { status: 'error' };
        });
    }
    resume() {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            return controlSocket.addTask(controlSocket.resume);
        });
    }
    pause() {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            return controlSocket.addTask(controlSocket.pause);
        });
    }
    stop() {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            return controlSocket.addTask(controlSocket.abort);
        });
    }
    restart() {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            return controlSocket.addTask(controlSocket.restart);
        });
    }
    quit() {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            return controlSocket.addTask(controlSocket.quit);
        });
    }
    quitTask() {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            return controlSocket.addTask(controlSocket.quitTask);
        });
    }
    kick() {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            return controlSocket.addTask(controlSocket.kick);
        });
    }
    startFraming() {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = (yield this.getControl());
            return controlSocket.addTask(controlSocket.startFraming);
        });
    }
    stopFraming() {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = (yield this.getControl());
            return controlSocket.addTask(controlSocket.stopFraming);
        });
    }
    // Calibration and Machine test functions
    waitTillCompleted(onProgress) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                let statusChanged = false;
                const statusCheckInterval = setInterval(() => __awaiter(this, void 0, void 0, function* () {
                    const controlSocket = yield this.getControl();
                    const r = yield controlSocket.addTask(controlSocket.report);
                    const { st_id: stId, error, prog } = r.device_status;
                    if (stId === 64) {
                        clearInterval(statusCheckInterval);
                        yield new Promise((resolve2) => setTimeout(resolve2, 2000));
                        try {
                            yield this.quit();
                            resolve(null);
                        }
                        catch (err) {
                            console.error(err);
                            reject(Error('Quit failed'));
                        }
                    }
                    else if ((stId === 128 || stId === 48 || stId === 36) && error && error.length > 0) {
                        // Error occured
                        clearInterval(statusCheckInterval);
                        reject(error);
                    }
                    else if (stId === 128) {
                        clearInterval(statusCheckInterval);
                        reject(error);
                    }
                    else if (stId === 0) {
                        // Resolve if the status was running and some how skipped the completed part
                        if (statusChanged) {
                            clearInterval(statusCheckInterval);
                            resolve(null);
                        }
                    }
                    else {
                        statusChanged = true;
                        if (prog)
                            onProgress === null || onProgress === void 0 ? void 0 : onProgress(prog);
                    }
                }), 2000);
            });
        });
    }
    runBeamboxCameraTest() {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield fetch('fcode/beam-series-camera.fc');
            const blob = yield res.blob();
            const vc = VersionChecker(this.currentDevice.info.version);
            if (vc.meetRequirement('RELOCATE_ORIGIN')) {
                yield this.setOriginX(0);
                yield this.setOriginY(0);
            }
            try {
                yield this.go(blob);
            }
            catch (_a) {
                throw Error('UPLOAD_FAILED');
            }
            Progress.openSteppingProgress({
                id: 'camera-cali-task',
                message: lang.calibration.drawing_calibration_image,
            });
            const onProgress = (progress) => Progress.update('camera-cali-task', {
                percentage: Math.round(progress * 100),
            });
            try {
                yield this.waitTillCompleted(onProgress);
                Progress.popById('camera-cali-task');
            }
            catch (err) {
                Progress.popById('camera-cali-task');
                throw err; // Error while running test
            }
        });
    }
    doDiodeCalibrationCut() {
        return __awaiter(this, void 0, void 0, function* () {
            const vc = VersionChecker(this.currentDevice.info.version);
            const fcode = vc.meetRequirement('CALIBRATION_MODE')
                ? 'fcode/beam-series-diode-c-mode.fc'
                : 'fcode/beam-series-diode.fc';
            const res = yield fetch(fcode);
            const blob = yield res.blob();
            if (vc.meetRequirement('RELOCATE_ORIGIN')) {
                yield this.setOriginX(0);
                yield this.setOriginY(0);
            }
            try {
                yield this.go(blob);
            }
            catch (_a) {
                throw Error('UPLOAD_FAILED');
            }
            Progress.openSteppingProgress({
                id: 'diode-cali-task',
                message: lang.calibration.drawing_calibration_image,
            });
            const onProgress = (progress) => Progress.update('diode-cali-task', {
                percentage: Math.round(progress * 100),
            });
            try {
                yield this.waitTillCompleted(onProgress);
                Progress.popById('diode-cali-task');
            }
            catch (err) {
                // Error while running test
                Progress.popById('diode-cali-task');
                throw err;
            }
        });
    }
    doAdorCalibrationCut() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.doCalibration('fcode/ador-camera-v1.fc');
        });
    }
    doAdorCalibrationV2(step = 1, withPitch = false) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.doCalibration(`fcode/ador-camera-v2-${step}${withPitch && step === 1 ? '-p' : ''}.fc`);
        });
    }
    doAdorPrinterCalibration() {
        return __awaiter(this, void 0, void 0, function* () {
            // using offset [0, -13.37]
            yield this.doCalibration('fcode/ador-printer.fc');
        });
    }
    doAdorIRCalibration() {
        return __awaiter(this, void 0, void 0, function* () {
            // using offset [0, 26.95]
            yield this.doCalibration('fcode/ador-ir.fc');
        });
    }
    doBB2Calibration() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.doCalibration('fcode/bb2-calibration.fc');
        });
    }
    doPromarkCalibration() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.doCalibration();
        });
    }
    // fs functions
    ls(path) {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            return controlSocket.addTask(controlSocket.ls, path);
        });
    }
    lsusb() {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            return controlSocket.addTask(controlSocket.lsusb);
        });
    }
    fileInfo(path, fileName) {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            return controlSocket.addTask(controlSocket.fileInfo, path, fileName);
        });
    }
    deleteFile(path, fileName) {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            return controlSocket.addTask(controlSocket.deleteFile, `${path}/${fileName}`);
        });
    }
    uploadToDirectory(data, path, fileName, onProgress) {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            if (onProgress) {
                controlSocket.setProgressListener(onProgress);
            }
            const res = yield controlSocket.addTask(controlSocket.upload, data, path, fileName);
            return res;
        });
    }
    downloadFile(path, fileName, onProgress) {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            if (onProgress) {
                controlSocket.setProgressListener(onProgress);
            }
            return controlSocket.addTask(controlSocket.downloadFile, `${path}/${fileName}`, onProgress);
        });
    }
    downloadLog(log, onProgress = () => { }) {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            if (onProgress) {
                controlSocket.setProgressListener(onProgress);
            }
            return controlSocket.downloadLog(log);
        });
    }
    fetchCameraCalibImage(fileName, onProgress = () => { }) {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            if (onProgress) {
                controlSocket.setProgressListener(onProgress);
            }
            return controlSocket.fetchCameraCalibImage(fileName);
        });
    }
    fetchFisheyeParams() {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            return controlSocket.fetchFisheyeParams();
        });
    }
    fetchFisheye3DRotation() {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            return controlSocket.fetchFisheye3DRotation();
        });
    }
    fetchAutoLevelingData(dataType) {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            return controlSocket.fetchAutoLevelingData(dataType);
        });
    }
    getLogsTexts(logs, onProgress = () => { }) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = {};
            for (let i = 0; i < logs.length; i += 1) {
                const log = logs[i];
                try {
                    // eslint-disable-next-line no-await-in-loop
                    const logFile = yield this.downloadLog(log, onProgress);
                    res[log] = logFile;
                }
                catch (e) {
                    console.error(`Failed to get ${log}`, e);
                }
            }
            return res;
        });
    }
    enterCartridgeIOMode() {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            return controlSocket.addTask(controlSocket.enterCartridgeIOMode);
        });
    }
    endCartridgeIOMode() {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            return controlSocket.addTask(controlSocket.endCartridgeIOMode);
        });
    }
    getCartridgeChipData() {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            return controlSocket.addTask(controlSocket.getCartridgeChipData);
        });
    }
    cartridgeIOJsonRpcReq(method, params) {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            return controlSocket.addTask(controlSocket.cartridgeIOJsonRpcReq, method, params);
        });
    }
    // Maintain mode functions
    enterMaintainMode() {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            const vc = VersionChecker(this.currentDevice.info.version);
            if (vc.meetRequirement('RELOCATE_ORIGIN')) {
                yield this.setOriginX(0);
                yield this.setOriginY(0);
            }
            return controlSocket.addTask(controlSocket.enterMaintainMode);
        });
    }
    endMaintainMode() {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            return controlSocket.addTask(controlSocket.endMaintainMode);
        });
    }
    maintainMove(args) {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            const result = yield controlSocket.addTask(controlSocket.maintainMove, args);
            if (result && result.status === 'ok') {
                return;
            }
            console.warn('maintainMove Result', result);
        });
    }
    maintainHome() {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            return controlSocket.addTask(controlSocket.maintainHome);
        });
    }
    maintainCloseFan() {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            return controlSocket.addTask(controlSocket.maintainCloseFan);
        });
    }
    // Raw mode functions
    enterRawMode() {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            return controlSocket.addTask(controlSocket.enterRawMode);
        });
    }
    endRawMode() {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            return controlSocket.addTask(controlSocket.endRawMode);
        });
    }
    rawHome() {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            return controlSocket.addTask(controlSocket.rawHome);
        });
    }
    rawHomeZ() {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            return controlSocket.addTask(controlSocket.rawHome, true);
        });
    }
    rawUnlock() {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            return controlSocket.addTask(controlSocket.rawUnlock);
        });
    }
    rawMoveZRelToLastHome(z = 0) {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            return controlSocket.addTask(controlSocket.rawMoveZRelToLastHome, z);
        });
    }
    rawStartLineCheckMode() {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            return controlSocket.addTask(controlSocket.rawStartLineCheckMode);
        });
    }
    rawEndLineCheckMode() {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            return controlSocket.addTask(controlSocket.rawEndLineCheckMode);
        });
    }
    rawMove(args) {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            return controlSocket.addTask(controlSocket.rawMove, args);
        });
    }
    rawSetRotary(on) {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            const fcodeVersion = constant.fcodeV2Models.has(this.currentDevice.info.model) ? 2 : 1;
            return controlSocket.addTask(controlSocket.rawSetRotary, on, fcodeVersion);
        });
    }
    rawSetWaterPump(on) {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            const fcodeVersion = constant.fcodeV2Models.has(this.currentDevice.info.model) ? 2 : 1;
            return controlSocket.addTask(controlSocket.rawSetWaterPump, on, fcodeVersion);
        });
    }
    rawSetFan(on) {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            const fcodeVersion = constant.fcodeV2Models.has(this.currentDevice.info.model) ? 2 : 1;
            return controlSocket.addTask(controlSocket.rawSetFan, on, fcodeVersion);
        });
    }
    rawSetAirPump(on) {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            const fcodeVersion = constant.fcodeV2Models.has(this.currentDevice.info.model) ? 2 : 1;
            return controlSocket.addTask(controlSocket.rawSetAirPump, on, fcodeVersion);
        });
    }
    rawLooseMotor() {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            const vc = VersionChecker(this.currentDevice.info.version);
            if (!vc.meetRequirement('B34_LOOSE_MOTOR')) {
                // TODO: 3.3.0 is pretty old, hope we can remove this check in the future
                return controlSocket.addTask(controlSocket.rawLooseMotorOld);
            }
            const fcodeVersion = constant.fcodeV2Models.has(this.currentDevice.info.model) ? 2 : 1;
            return controlSocket.addTask(controlSocket.rawLooseMotor, fcodeVersion);
        });
    }
    rawSetLaser(args) {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            return controlSocket.addTask(controlSocket.rawSetLaser, args);
        });
    }
    rawSetRedLight(on) {
        return __awaiter(this, void 0, void 0, function* () {
            if (constant.fcodeV2Models.has(this.currentDevice.info.model)) {
                const controlSocket = yield this.getControl();
                return controlSocket.addTask(controlSocket.rawSetRedLight, on);
            }
            return false;
        });
    }
    rawSet24V(on) {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            return controlSocket.addTask(controlSocket.rawSet24V, on);
        });
    }
    rawAutoFocus() {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            return controlSocket.addTask(controlSocket.rawAutoFocus);
        });
    }
    rawGetProbePos() {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            return controlSocket.addTask(controlSocket.rawGetProbePos);
        });
    }
    rawGetLastPos() {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            return controlSocket.addTask(controlSocket.rawGetLastPos);
        });
    }
    rawMeasureHeight({ baseZ, relZ, timeout = 18000, }) {
        return __awaiter(this, void 0, void 0, function* () {
            const { model } = this.currentDevice.info;
            if (model === 'ado1') {
                yield this.rawAutoFocus();
                const { didAf, z } = yield this.rawGetProbePos();
                const res = didAf ? z : null;
                if (typeof baseZ === 'number')
                    yield this.rawMove({ z: baseZ });
                else if (res && relZ)
                    yield this.rawMove({ z: Math.max(0, res - relZ) });
                return res;
            }
            // Hexa only
            const controlSocket = yield this.getControl();
            const res = yield controlSocket.addTask(controlSocket.rawMeasureHeight, baseZ, timeout);
            if (res && typeof baseZ !== 'number' && relZ) {
                yield this.rawMove({ z: Math.max(0, res - relZ) });
            }
            return res;
        });
    }
    rawSetOrigin() {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            const vc = VersionChecker(this.currentDevice.info.version);
            const isV2 = constant.fcodeV2Models.has(this.currentDevice.info.model);
            if (!vc.meetRequirement(isV2 ? 'ADOR_JOB_ORIGIN' : 'JOB_ORIGIN')) {
                return null;
            }
            const res = yield controlSocket.addTask(controlSocket.rawSetOrigin, isV2 ? 2 : 1);
            return res;
        });
    }
    // Get, Set functions
    getLaserPower() {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            return controlSocket.addTask(controlSocket.getLaserPower);
        });
    }
    setLaserPower(power) {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            return controlSocket.addTask(controlSocket.setLaserPower, power);
        });
    }
    setLaserPowerTemp(power) {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            return controlSocket.addTask(controlSocket.setLaserPowerTemp, power);
        });
    }
    getLaserSpeed() {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            return controlSocket.addTask(controlSocket.getLaserSpeed);
        });
    }
    setLaserSpeed(speed) {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            return controlSocket.addTask(controlSocket.setLaserSpeed, speed);
        });
    }
    setLaserSpeedTemp(speed) {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            return controlSocket.addTask(controlSocket.setLaserSpeedTemp, speed);
        });
    }
    getFan() {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            return controlSocket.addTask(controlSocket.getFan);
        });
    }
    setFan(fanSpeed) {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            return controlSocket.addTask(controlSocket.setFan, fanSpeed);
        });
    }
    setFanTemp(fanSpeed) {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            return controlSocket.addTask(controlSocket.setFanTemp, fanSpeed);
        });
    }
    setOriginX(x = 0) {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            const vc = VersionChecker(this.currentDevice.info.version);
            if (vc.meetRequirement('RELOCATE_ORIGIN')) {
                return controlSocket.addTask(controlSocket.setOriginX, x);
            }
            console.warn('This device does not support command setOriginX');
            return null;
        });
    }
    setOriginY(y = 0) {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            const vc = VersionChecker(this.currentDevice.info.version);
            if (vc.meetRequirement('RELOCATE_ORIGIN')) {
                return controlSocket.addTask(controlSocket.setOriginY, y);
            }
            console.warn('This device does not support command setOriginY');
            return null;
        });
    }
    getDoorOpen() {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            return controlSocket.addTask(controlSocket.getDoorOpen);
        });
    }
    getDeviceSetting(name) {
        return __awaiter(this, void 0, void 0, function* () {
            const { currentDevice } = this;
            const controlSocket = yield this.getControl();
            const res = yield controlSocket.addTask(controlSocket.getDeviceSetting, name);
            if (currentDevice.cameraNeedsFlip === null &&
                ['camera_offset', 'camera_offset_borderless'].includes(name)) {
                if (res.status === 'ok' && !currentDevice.info.model.includes('delta-')) {
                    this.checkCameraNeedFlip(res.value);
                }
            }
            return res;
        });
    }
    setDeviceSetting(name, value) {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            if (value === 'delete') {
                return controlSocket.addTask(controlSocket.deleteDeviceSetting, name);
            }
            return controlSocket.addTask(controlSocket.setDeviceSetting, name, value);
        });
    }
    getDeviceDetailInfo() {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            return controlSocket.addTask(controlSocket.deviceDetailInfo);
        });
    }
    getReport() {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            const result = yield controlSocket.addTask(controlSocket.report);
            const s = result.device_status;
            // Force update st_label for a backend inconsistancy
            if (s.st_id === DeviceConstants.status.ABORTED) {
                s.st_label = 'ABORTED';
            }
            return s;
        });
    }
    getPreviewInfo() {
        return __awaiter(this, void 0, void 0, function* () {
            const controlSocket = yield this.getControl();
            return controlSocket.addTask(controlSocket.getPreview);
        });
    }
    // Camera functions
    checkCameraNeedFlip(cameraOffset) {
        const { currentDevice } = this;
        currentDevice.cameraNeedsFlip = !!Number((/F:\s?(-?\d+\.?\d+)/.exec(cameraOffset) || ['', ''])[1]);
        return currentDevice.cameraNeedsFlip;
    }
    connectCamera(shouldCrop = true) {
        return __awaiter(this, void 0, void 0, function* () {
            const { currentDevice } = this;
            if (currentDevice.cameraNeedsFlip === null) {
                if (constant.fcodeV2Models.has(currentDevice.info.model)) {
                    currentDevice.cameraNeedsFlip = false;
                }
                else if (currentDevice.control && currentDevice.control.getMode() === '') {
                    yield this.getDeviceSetting('camera_offset');
                }
            }
            currentDevice.camera = new Camera(shouldCrop, currentDevice.cameraNeedsFlip);
            yield currentDevice.camera.createWs(currentDevice.info);
        });
    }
    /**
     * After setting fisheyeParam the photos from machine be applied with camera matrix
     * @param setCrop defines whether to crop the photo using cx, cy
     */
    setFisheyeMatrix(matrix, setCrop) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.currentDevice.camera.setFisheyeMatrix(matrix, setCrop);
            return res;
        });
    }
    setFisheyeParam(data) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.currentDevice.camera.setFisheyeParam(data);
            return res;
        });
    }
    setFisheyeObjectHeight(height) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.currentDevice.camera.setFisheyeObjectHeight(height);
            return res;
        });
    }
    setFisheyePerspectiveGrid(data) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.currentDevice.camera.setFisheyePerspectiveGrid(data);
            return res;
        });
    }
    setFisheyeLevelingData(data) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.currentDevice.camera.setFisheyeLevelingData(data);
            return res;
        });
    }
    set3dRotation(data) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.currentDevice.camera.set3dRotation(data);
            return res;
        });
    }
    takeOnePicture(opts = {}) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            const { timeout = 30 } = opts;
            const startTime = Date.now();
            const cameraFishEyeSetting = (_a = this.currentDevice.camera) === null || _a === void 0 ? void 0 : _a.getFisheyeSetting();
            const fisheyeRotation = (_b = this.currentDevice.camera) === null || _b === void 0 ? void 0 : _b.getRotationAngles();
            let lastErr = null;
            while (Date.now() - startTime < timeout * 1000) {
                try {
                    // eslint-disable-next-line no-await-in-loop
                    const res = yield this.currentDevice.camera.oneShot();
                    if (res)
                        return res;
                }
                catch (err) {
                    console.log('Error when getting camera image', err);
                    lastErr = err;
                }
                this.disconnectCamera();
                yield this.connectCamera();
                if (cameraFishEyeSetting) {
                    if (cameraFishEyeSetting.matrix) {
                        yield this.setFisheyeMatrix(cameraFishEyeSetting.matrix, cameraFishEyeSetting.shouldCrop);
                    }
                    else if (cameraFishEyeSetting.param) {
                        yield this.setFisheyeParam(cameraFishEyeSetting.param);
                        if (cameraFishEyeSetting.objectHeight)
                            yield this.setFisheyeObjectHeight(cameraFishEyeSetting.objectHeight);
                        if (cameraFishEyeSetting.levelingData)
                            yield this.setFisheyeLevelingData(cameraFishEyeSetting.levelingData);
                    }
                }
                // eslint-disable-next-line no-await-in-loop
                if (fisheyeRotation)
                    yield this.set3dRotation(fisheyeRotation);
            }
            if (lastErr)
                throw lastErr;
            return null;
        });
    }
    streamCamera(shouldCrop = true) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.connectCamera(shouldCrop);
            // return an instance of RxJS Observable.
            return this.currentDevice.camera.getLiveStreamSource();
        });
    }
    disconnectCamera() {
        var _a;
        if ((_a = this.currentDevice) === null || _a === void 0 ? void 0 : _a.camera) {
            this.currentDevice.camera.closeWs();
            this.currentDevice.camera = null;
        }
    }
    getDiscoveredDevice(key, value, callback) {
        console.log(key, value, this.discoveredDevices);
        const matchedDevice = this.discoveredDevices.filter((d) => d[key] === value);
        if (matchedDevice.length > 0) {
            callback.onSuccess(matchedDevice[0]);
            return;
        }
        if (callback.timeout > 0) {
            setTimeout(() => {
                const newCallback = Object.assign(Object.assign({}, callback), { timeout: callback.timeout - 500 });
                this.getDiscoveredDevice(key, value, newCallback);
            }, 500);
        }
        else {
            callback.onTimeout();
        }
    }
    existDevice(serial) {
        return this.discoveredDevices.some((device) => device.serial === serial);
    }
}
const deviceMaster = new DeviceMaster();
// // eslint-disable-next-line @typescript-eslint/dot-notation
// window['deviceMaster'] = deviceMaster;
export default deviceMaster;
