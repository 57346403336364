// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-views-beambox-Right-Panels-ConfigPanel-ConfigSlider-module__container--KFq9n .ant-slider,.src-web-app-views-beambox-Right-Panels-ConfigPanel-ConfigSlider-module__container--KFq9n .ant-slider:hover{margin:5px 0}.src-web-app-views-beambox-Right-Panels-ConfigPanel-ConfigSlider-module__container--KFq9n .ant-slider .ant-slider-rail,.src-web-app-views-beambox-Right-Panels-ConfigPanel-ConfigSlider-module__container--KFq9n .ant-slider:hover .ant-slider-rail{background-blend-mode:overlay,normal;background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0.5)),linear-gradient(to right, #89d307, #9ede05 59%, #fff600 71%, #c50101)}.src-web-app-views-beambox-Right-Panels-ConfigPanel-ConfigSlider-module__container--KFq9n.src-web-app-views-beambox-Right-Panels-ConfigPanel-ConfigSlider-module__limit--5xCjJ .ant-slider .ant-slider-rail{background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0.5)),linear-gradient(to right, #89d307 0%, #9ede05 2%, #fff600 4%, #c50101 6%)}", "",{"version":3,"sources":["webpack://./src/web/app/views/beambox/Right-Panels/ConfigPanel/ConfigSlider.module.scss"],"names":[],"mappings":"AACE,kNACE,YAAA,CAEA,oPACE,oCAAA,CACA,+JAAA,CAOA,4MACE,mKAAA","sourcesContent":[".container {\n  :global(.ant-slider), :global(.ant-slider:hover) {\n    margin: 5px 0;\n\n    :global(.ant-slider-rail) {\n      background-blend-mode: overlay, normal;\n      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0.5)),\n        linear-gradient(to right, #89d307, #9ede05 59%, #fff600 71%, #c50101);\n    }\n  }\n\n  &.limit {\n    :global(.ant-slider) {\n      :global(.ant-slider-rail) {\n        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0.5)),\n          linear-gradient(to right, #89d307 0%, #9ede05 2%, #fff600 4%, #c50101 6%);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "src-web-app-views-beambox-Right-Panels-ConfigPanel-ConfigSlider-module__container--KFq9n",
	"limit": "src-web-app-views-beambox-Right-Panels-ConfigPanel-ConfigSlider-module__limit--5xCjJ"
};
export default ___CSS_LOADER_EXPORT___;
