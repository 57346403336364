class LocalStorage {
    // eslint-disable-next-line class-methods-use-this
    get(name) {
        let item = window.localStorage.getItem(name || '');
        item = item || '';
        if (!item)
            return '';
        try {
            const parsedItem = JSON.parse(item);
            return parsedItem;
        }
        catch (ex) {
            console.warn(`Unable to parse ${item} of key: ${name}`);
        }
        return item;
    }
    set(name, val) {
        window.localStorage.setItem(name, JSON.stringify(val));
        return this;
    }
    removeAt(name) {
        window.localStorage.removeItem(name);
        return this;
    }
    clearAll() {
        window.localStorage.clear();
        return this;
    }
    clearAllExceptIP() {
        const ip = this.get('poke-ip-addr');
        this.clearAll();
        this.set('poke-ip-addr', ip);
        return this;
    }
    // eslint-disable-next-line class-methods-use-this
    isExisting(key) {
        return window.localStorage.getItem(key) !== null;
    }
}
const storage = new LocalStorage();
export default storage;
