import i18n from 'helpers/i18n';
import { OptionValues } from 'app/constants/enums';
const onOffOptionFactory = (isOnSelected, opts) => {
    const { onValue, offValue, onLabel, offLabel, lang = i18n.lang } = opts !== null && opts !== void 0 ? opts : {};
    return [
        {
            value: (onValue !== null && onValue !== void 0 ? onValue : OptionValues.TRUE),
            label: onLabel || (lang === null || lang === void 0 ? void 0 : lang.settings.on) || 'On',
            selected: isOnSelected,
        },
        {
            value: (offValue !== null && offValue !== void 0 ? offValue : OptionValues.FALSE),
            label: offLabel || (lang === null || lang === void 0 ? void 0 : lang.settings.off) || 'Off',
            selected: !isOnSelected,
        },
    ];
};
export default onOffOptionFactory;
