import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Badge } from 'antd';
import { TabBar } from 'antd-mobile';
import beamboxStore from 'app/stores/beambox-store';
import browser from 'implementations/browser';
import createNewText from 'app/svgedit/text/createNewText';
import dialogCaller from 'app/actions/dialog-caller';
import eventEmitterFactory from 'helpers/eventEmitterFactory';
import FluxIcons from 'app/icons/flux/FluxIcons';
import FnWrapper from 'app/actions/beambox/svgeditor-function-wrapper';
import historyUtils from 'app/svgedit/history/utils';
import LeftPanelIcons from 'app/icons/left-panel/LeftPanelIcons';
import ObjectPanelController from 'app/views/beambox/Right-Panels/contexts/ObjectPanelController';
import PreviewModeBackgroundDrawer from 'app/actions/beambox/preview-mode-background-drawer';
import PreviewModeController from 'app/actions/beambox/preview-mode-controller';
import RightPanelController from 'app/views/beambox/Right-Panels/contexts/RightPanelController';
import TabBarIcons from 'app/icons/tab-bar/TabBarIcons';
import TopBarIcons from 'app/icons/top-bar/TopBarIcons';
import useI18n from 'helpers/useI18n';
import workareaManager from 'app/svgedit/workarea';
import { DmktIcon } from 'app/icons/icons';
import { CanvasContext, CanvasMode } from 'app/contexts/CanvasContext';
import { getCurrentUser } from 'helpers/api/flux-id';
import { getSVGAsync } from 'helpers/svg-editor-helper';
import { showPassThrough } from 'app/components/pass-through/PassThrough';
import { useIsMobile } from 'helpers/system-helper';
import styles from './CanvasTabBar.module.scss';
const events = eventEmitterFactory.createEventEmitter('canvas');
const rightPanelEventEmitter = eventEmitterFactory.createEventEmitter('right-panel');
let svgCanvas;
getSVGAsync((globalSVG) => {
    svgCanvas = globalSVG.Canvas;
});
const CanvasTabBar = () => {
    var _a, _b, _c;
    const isMobile = useIsMobile();
    const lang = useI18n();
    const isSubscribed = (_c = (_b = (_a = getCurrentUser()) === null || _a === void 0 ? void 0 : _a.info) === null || _b === void 0 ? void 0 : _b.subscription) === null || _c === void 0 ? void 0 : _c.is_valid;
    const { mode, endPreviewMode, changeToPreviewMode, setupPreviewMode } = useContext(CanvasContext);
    const isPreviewing = mode === CanvasMode.Preview;
    const [activeKey, setActiveKey] = useState('none');
    const resetActiveKey = useCallback(() => {
        setActiveKey('none');
    }, []);
    useEffect(() => {
        const handler = (val) => {
            setActiveKey((cur) => {
                if (val) {
                    if (cur !== 'layer')
                        return 'layer';
                    return cur;
                }
                if (cur === 'layer')
                    return 'none';
                return cur;
            });
        };
        rightPanelEventEmitter.on('DISPLAY_LAYER', handler);
        return () => {
            rightPanelEventEmitter.off('DISPLAY_LAYER', handler);
        };
    }, []);
    if (!isMobile)
        return null;
    const tabs = [
        {
            key: 'camera',
            title: lang.beambox.left_panel.label.choose_camera,
            icon: React.createElement(TopBarIcons.Camera, null),
        },
        {
            key: 'image',
            title: lang.beambox.left_panel.label.photo,
            icon: React.createElement(TabBarIcons.Photo, null),
        },
        {
            key: 'cloud',
            title: lang.beambox.left_panel.label.my_cloud,
            icon: React.createElement(LeftPanelIcons.Cloud, null),
            badge: isSubscribed,
        },
        {
            key: 'shape',
            title: lang.beambox.left_panel.label.shapes,
            icon: React.createElement(TabBarIcons.Shape, null),
        },
        {
            key: 'text',
            title: lang.beambox.left_panel.label.text,
            icon: React.createElement(TabBarIcons.Text, null),
        },
        {
            key: 'layer',
            title: lang.topbar.menu.layer_setting,
            icon: (React.createElement("div", { onClick: () => {
                    if (activeKey === 'layer') {
                        RightPanelController.setDisplayLayer(false);
                    }
                } },
                React.createElement(TabBarIcons.Layers, null))),
        },
        {
            key: 'pen',
            title: lang.beambox.left_panel.label.pen,
            icon: React.createElement(TabBarIcons.Draw, null),
        },
        {
            key: 'boxgen',
            title: lang.beambox.left_panel.label.boxgen,
            icon: React.createElement(TabBarIcons.Boxgen, null),
        },
        {
            key: 'document',
            title: lang.topbar.menu.document_setting_short,
            icon: React.createElement(TabBarIcons.Document, null),
        },
        {
            key: 'qrcode',
            title: lang.beambox.left_panel.label.qr_code,
            icon: React.createElement(LeftPanelIcons.QRCode, null),
        },
        {
            key: 'dmkt',
            title: 'DMKT',
            icon: React.createElement(DmktIcon, { style: { fontSize: 40 } }),
        },
        {
            key: 'passthrough',
            title: lang.beambox.left_panel.label.pass_through,
            icon: React.createElement(LeftPanelIcons.PassThrough, null),
        },
        {
            key: '',
            title: '',
            icon: React.createElement("div", { className: styles.sep }),
        },
        {
            key: 'undo',
            title: lang.topbar.menu.undo,
            icon: React.createElement(TopBarIcons.Undo, null),
        },
        {
            key: 'redo',
            title: lang.topbar.menu.redo,
            icon: React.createElement(TopBarIcons.Redo, null),
        },
    ];
    const handleTabClick = (key) => {
        svgCanvas.setMode('select');
        if (key === 'layer') {
            RightPanelController.setDisplayLayer(true);
        }
        else
            RightPanelController.setDisplayLayer(false);
        if (key === 'camera') {
            changeToPreviewMode();
            if (!PreviewModeController.isPreviewMode())
                setupPreviewMode();
            setActiveKey('choose-preview-device');
            setTimeout(resetActiveKey, 300);
        }
        else if (key === 'image') {
            FnWrapper.importImage();
            setTimeout(resetActiveKey, 300);
        }
        else if (key === 'text') {
            events.once('addText', (newText) => {
                workareaManager.zoom((window.innerWidth / newText.getBBox().width) * 0.8);
                newText.scrollIntoView({ block: 'center', inline: 'center' });
                resetActiveKey();
            });
            createNewText(100, 100, { text: 'Text', addToHistory: true, isToSelect: true });
        }
        else if (key === 'pen') {
            events.once('addPath', resetActiveKey);
            FnWrapper.insertPath();
        }
        else if (key === 'undo') {
            historyUtils.undo();
            setTimeout(resetActiveKey, 300);
        }
        else if (key === 'redo') {
            historyUtils.redo();
            setTimeout(resetActiveKey, 300);
        }
        else if (key === 'shape') {
            dialogCaller.showShapePanel(resetActiveKey);
        }
        else if (key === 'document') {
            dialogCaller.showDocumentSettings();
            setTimeout(resetActiveKey, 300);
        }
        else if (key === 'dmkt') {
            browser.open(lang.topbar.menu.link.design_market);
            setTimeout(resetActiveKey, 300);
        }
        else if (key === 'cloud') {
            dialogCaller.showMyCloud(resetActiveKey);
        }
        else if (key === 'passthrough') {
            showPassThrough(resetActiveKey);
        }
    };
    const previewTabItems = [
        {
            key: 'end-preview',
            title: lang.beambox.left_panel.label.end_preview,
            icon: React.createElement(TopBarIcons.Camera, null),
        },
        {
            key: 'choose-preview-device',
            title: lang.beambox.left_panel.label.choose_camera,
            icon: React.createElement(TabBarIcons.Shoot, null),
        },
        {
            key: 'image-trace',
            title: lang.beambox.left_panel.label.trace,
            icon: React.createElement(TabBarIcons.Trace, null),
            disabled: PreviewModeController.isDrawing || PreviewModeBackgroundDrawer.isClean(),
        },
        {
            key: 'clear-preview',
            title: lang.beambox.left_panel.label.clear_preview,
            icon: React.createElement(TabBarIcons.Trash, null),
            disabled: PreviewModeController.isDrawing || PreviewModeBackgroundDrawer.isClean(),
        },
    ];
    const handlePreviewTabClick = (key) => {
        if (key === 'end-preview') {
            endPreviewMode();
        }
        else if (key === 'choose-preview-device') {
            if (!PreviewModeController.isPreviewMode()) {
                setupPreviewMode();
            }
        }
        else if (key === 'image-trace') {
            endPreviewMode();
            beamboxStore.emitShowCropper();
        }
        else if (key === 'clear-preview') {
            if (!PreviewModeBackgroundDrawer.isClean()) {
                PreviewModeBackgroundDrawer.resetCoordinates();
                PreviewModeBackgroundDrawer.clear();
            }
        }
        setTimeout(resetActiveKey, 300);
    };
    return (React.createElement("div", { id: "mobile-tab-bar", className: styles.container, onClick: () => ObjectPanelController.updateActiveKey(null) },
        React.createElement("div", { style: { width: 'fit-content' } },
            React.createElement(TabBar, { activeKey: activeKey, onChange: (key) => {
                    setActiveKey(key);
                    if (isPreviewing)
                        handlePreviewTabClick(key);
                    else
                        handleTabClick(key);
                } }, (isPreviewing ? previewTabItems : tabs).map((item) => (React.createElement(TabBar.Item, { key: item.key, icon: React.createElement(Badge, { className: styles.badge, count: item.badge ? React.createElement(FluxIcons.FluxPlus, { className: styles['flux-plus'] }) : 0, offset: [-4, 6] }, item.icon), title: item.title, "aria-disabled": item.disabled || false })))))));
};
export default CanvasTabBar;
