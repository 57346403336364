var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { filter, interval, map, mergeMap, scan } from 'rxjs';
import { LoadingOutlined } from '@ant-design/icons';
import { Modal, Spin } from 'antd';
import useI18n from 'helpers/useI18n';
import { addDialogComponent, isIdExist, popDialogById } from 'app/actions/dialog-controller';
import styles from './UsbDeviceSelector.module.scss';
const UsbDeviceSelector = ({ initList, updateList, onSelect, onClose }) => {
    const lang = useI18n();
    const [list, setList] = useState(initList);
    useEffect(() => {
        let subscription;
        if (updateList) {
            subscription = interval(3000)
                .pipe(map(() => updateList()))
                .pipe(mergeMap((p, index) => __awaiter(void 0, void 0, void 0, function* () {
                const devices = yield p;
                return { index, devices };
            })))
                .pipe(scan((acc, { index, devices }) => {
                if (index <= acc.index)
                    return { index: acc.index };
                return { index, devices };
            }, { index: -1 }))
                .pipe(filter(({ devices }) => devices !== undefined))
                .subscribe(({ devices }) => setList(devices));
        }
        return () => {
            subscription === null || subscription === void 0 ? void 0 : subscription.unsubscribe();
        };
    }, [updateList]);
    return (React.createElement(Modal, { open: true, closable: false, centered: true, onCancel: () => {
            onSelect(null);
            onClose();
        }, width: 415, footer: null, title: lang.device_selection.select_usb_device },
        React.createElement("div", { className: styles['device-list'] },
            React.createElement("ul", null, list.length > 0 ? (list.map((device) => (React.createElement("li", { key: `${device.deviceId}-${device.label}`, onClick: () => {
                    onSelect(device);
                    onClose();
                }, "data-testid": device.deviceId },
                React.createElement("label", { className: styles.name }, device.label))))) : (React.createElement(Spin, { className: styles.spinner, indicator: React.createElement(LoadingOutlined, { className: styles.icon, spin: true }) }))))));
};
export default UsbDeviceSelector;
export const selectUsbDevice = (initList = [], updateList) => __awaiter(void 0, void 0, void 0, function* () {
    if (isIdExist('USB_DEVICE_SELECTOR'))
        return null;
    return new Promise((resolve) => {
        addDialogComponent('USB_DEVICE_SELECTOR', React.createElement(UsbDeviceSelector, { initList: initList, updateList: updateList, onSelect: (device) => resolve(device), onClose: () => popDialogById('USB_DEVICE_SELECTOR') }));
    });
});
