// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-components-beambox-DpiInfo-module__container--8FrXV{position:fixed;bottom:20px;left:175px;height:20px;font-size:16px;z-index:1;color:#838383}.src-web-app-components-beambox-DpiInfo-module__container--8FrXV.src-web-app-components-beambox-DpiInfo-module__mobile--gwNy6{left:25px;bottom:110px}", "",{"version":3,"sources":["webpack://./src/web/app/components/beambox/DpiInfo.module.scss"],"names":[],"mappings":"AAAA,iEACE,cAAA,CACA,WAAA,CACA,UAAA,CACA,WAAA,CACA,cAAA,CACA,SAAA,CACA,aAAA,CAEA,8HACE,SAAA,CACA,YAAA","sourcesContent":[".container {\n  position: fixed;\n  bottom: 20px;\n  left: 175px;\n  height: 20px;\n  font-size: 16px;\n  z-index: 1;\n  color: #838383;\n\n  &.mobile {\n    left: 25px;\n    bottom: 110px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "src-web-app-components-beambox-DpiInfo-module__container--8FrXV",
	"mobile": "src-web-app-components-beambox-DpiInfo-module__mobile--gwNy6"
};
export default ___CSS_LOADER_EXPORT___;
