import React, { useMemo, useState } from 'react';
import { Button, Input, Modal, Space, Typography } from 'antd';
import currentFileManager from 'app/svgedit/currentFileManager';
import useI18n from 'helpers/useI18n';
const SaveFileModal = ({ onClose, uuid }) => {
    const LANG = useI18n();
    const lang = LANG.my_cloud.save_file;
    const [isEditingName, setIsEditingName] = useState(!uuid);
    const [fileName, setFileName] = useState((currentFileManager.getName() || LANG.topbar.untitled).split('/').pop());
    const slashError = useMemo(() => fileName.includes('/'), [fileName]);
    const error = slashError || !fileName;
    return isEditingName ? (React.createElement(Modal, { title: LANG.topbar.menu.save_to_cloud, onOk: () => onClose(fileName), onCancel: () => onClose(null, true), okButtonProps: { disabled: error }, width: 350, centered: true, open: true },
        React.createElement(Input, { size: "small", value: fileName, status: error ? 'error' : undefined, onChange: (e) => setFileName(e.target.value), maxLength: 255 }),
        slashError && (React.createElement("div", null,
            lang.invalid_char,
            " ",
            React.createElement(Typography.Text, { keyboard: true }, "/"))))) : (React.createElement(Modal, { title: lang.choose_action, onCancel: () => onClose(null, true), footer: React.createElement(Space, null,
            React.createElement(Button, { type: "primary", onClick: () => setIsEditingName(true) }, lang.save_new),
            React.createElement(Button, { type: "primary", onClick: () => onClose(null) }, lang.save)), width: 350, centered: true, open: true }));
};
export default SaveFileModal;
