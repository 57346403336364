import BeamboxGlobalInteraction from 'app/actions/beambox/beambox-global-interaction';
import eventEmitterFactory from 'helpers/eventEmitterFactory';
const canvasEventEmitter = eventEmitterFactory.createEventEmitter('canvas');
export const setSelectedElement = (elem) => {
    if (!elem) {
        BeamboxGlobalInteraction.onObjectBlur();
    }
    else {
        BeamboxGlobalInteraction.onObjectBlur();
        BeamboxGlobalInteraction.onObjectFocus([elem]);
    }
    canvasEventEmitter.emit('SET_SELECTED_ELEMENT', elem);
};
export const addImage = () => {
    canvasEventEmitter.emit('ADD_IMAGE');
};
export const addLine = (line) => {
    canvasEventEmitter.emit('addLine', line);
};
export const addPath = (path) => {
    canvasEventEmitter.emit('addPath', path);
};
const setColorPreviewing = (val) => {
    canvasEventEmitter.emit('SET_COLOR_PREVIEWING', val);
};
const setupPreviewMode = (opts = {}) => {
    canvasEventEmitter.emit('SETUP_PREVIEW_MODE', opts);
};
const setPathEditing = (val) => {
    canvasEventEmitter.emit('SET_PATH_EDITING', val);
};
const updateContext = () => {
    canvasEventEmitter.emit('UPDATE_CONTEXT');
};
export default {
    addImage,
    addLine,
    addPath,
    setColorPreviewing,
    setPathEditing,
    setSelectedElement,
    setupPreviewMode,
    updateContext,
};
