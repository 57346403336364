export const adorPnPPoints = [
    [155, 90],
    [275, 90],
    [155, 210],
    [275, 210],
    [185, 120],
    [245, 120],
    [185, 180],
    [245, 180],
];
export const bb2PnPPoints = [
    [-60, 10],
    [60, 10],
    [-60, 90],
    [60, 90],
    [-30, 30],
    [30, 30],
    [-30, 70],
    [30, 70],
];
export const promarkPnPPoints = {
    110: [
        [5, 5],
        [105, 5],
        [5, 105],
        [105, 105],
        [35, 35],
        [75, 35],
        [35, 75],
        [75, 75],
    ],
    150: [
        [25, 25],
        [125, 25],
        [25, 125],
        [125, 125],
        [55, 55],
        [95, 55],
        [55, 95],
        [95, 95],
    ],
    220: [
        [40, 40],
        [180, 40],
        [40, 180],
        [180, 180],
        [80, 80],
        [140, 80],
        [80, 140],
        [140, 140],
    ],
};
export default {
    adorPnPPoints,
    bb2PnPPoints,
    promarkPnPPoints,
};
