import classNames from 'classnames';
import React from 'react';
import { Badge } from 'antd';
import FluxIcons from 'app/icons/flux/FluxIcons';
import styles from './LeftPanelButton.module.scss';
function LeftPanelButton({ id, title, icon, onClick, active = false, disabled = false, showBadge = false, }) {
    return (React.createElement("div", { id: id, className: classNames(styles.container, {
            [styles.active]: active,
            [styles.disabled]: disabled,
        }), title: title, onClick: disabled ? undefined : onClick },
        React.createElement(Badge, { className: styles.badge, count: showBadge ? React.createElement(FluxIcons.FluxPlus, { className: styles['flux-plus'] }) : 0, offset: [-4, 6] }, icon)));
}
export default LeftPanelButton;
