var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import Draggable from 'react-draggable';
import React, { useRef, useState } from 'react';
import { Modal } from 'antd';
import layoutConstants from 'app/constants/layout-constants';
const DraggableModal = (props) => {
    const { children, title, modalRender = (modal) => modal, defaultPosition = { x: 0, y: -300 } } = props, restProps = __rest(props, ["children", "title", "modalRender", "defaultPosition"]);
    const [disabled, setDisabled] = useState(true);
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
    const draggleRef = useRef(null);
    const onStart = (_event, uiData) => {
        var _a;
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = (_a = draggleRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
        if (!targetRect)
            return;
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y + layoutConstants.topBarHeight,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };
    return (React.createElement(Modal, Object.assign({ title: React.createElement("div", { style: { width: '100%', cursor: 'move' }, onMouseOver: () => {
                if (disabled)
                    setDisabled(false);
            }, onMouseOut: () => {
                setDisabled(true);
            }, 
            // fix eslintjsx-a11y/mouse-events-have-key-events
            // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
            onFocus: () => { }, onBlur: () => { } }, title), modalRender: (modal) => (React.createElement(Draggable, { disabled: disabled, defaultPosition: defaultPosition, bounds: bounds, nodeRef: draggleRef, onStart: onStart },
            React.createElement("div", { ref: draggleRef }, modalRender(modal)))) }, restProps), children));
};
export default DraggableModal;
