import Array from './array.svg';
import ArrayMobile from './array-mobile.svg';
import AutoFit from './auto-fit.svg';
import BackgroungRemoval from './background-removal.svg';
import BackgroungRemovalMobile from './background-removal-mobile.svg';
import Bevel from './bevel.svg';
import BevelMobile from './bevel-mobile.svg';
import Brightness from './brightness.svg';
import ConvertToPath from './convert-to-path.svg';
import ConvertToPathMobile from './convert-to-path-mobile.svg';
import CreateTextpath from './create-textpath.svg';
import Crop from './crop.svg';
import Decompose from './decompose.svg';
import DecomposeMobile from './decompose-mobile.svg';
import DecomposeTextpath from './decompose-textpath.svg';
import Delete from './delete.svg';
import Disassemble from './disassemble.svg';
import DisassembleMobile from './disassemble-mobile.svg';
import EditPath from './edit-path.svg';
import EditPathMobile from './edit-path-mobile.svg';
import Grading from './grading.svg';
import Invert from './invert.svg';
import Offset from './offset.svg';
import Outline from './outline.svg';
import Replace from './replace.svg';
import ReplaceMobile from './replace-mobile.svg';
import RotaryWarped from './rotary-warped.svg';
import Sharpen from './sharpen.svg';
import SharpenMobile from './sharpen-mobile.svg';
import Simplify from './simplify.svg';
import SimplifyMobile from './simplify-mobile.svg';
import SmartNest from './smart-nest.svg';
import Trace from './trace.svg';
import WeldText from './weld-text.svg';
export default {
    Array,
    ArrayMobile,
    AutoFit,
    BackgroungRemoval,
    BackgroungRemovalMobile,
    Bevel,
    BevelMobile,
    Brightness,
    ConvertToPath,
    ConvertToPathMobile,
    CreateTextpath,
    Crop,
    Decompose,
    DecomposeMobile,
    DecomposeTextpath,
    Disassemble,
    DisassembleMobile,
    EditPath,
    EditPathMobile,
    Grading,
    Outline,
    Delete,
    Invert,
    Offset,
    Replace,
    ReplaceMobile,
    RotaryWarped,
    Sharpen,
    SharpenMobile,
    Simplify,
    SimplifyMobile,
    SmartNest,
    Trace,
    WeldText,
};
