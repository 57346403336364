// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-components-beambox-right-panel-AddLayerButton-module__btn--i7y6f{position:absolute;cursor:pointer;padding:0;bottom:4px;right:4px;font-size:24px;z-index:1;background:none;border:none}@media screen and (max-width: 600px){.src-web-app-components-beambox-right-panel-AddLayerButton-module__btn--i7y6f{top:8px;left:8px;z-index:999;width:30px;height:30px;font-size:28px}}", "",{"version":3,"sources":["webpack://./src/web/app/components/beambox/right-panel/AddLayerButton.module.scss"],"names":[],"mappings":"AAAA,8EACE,iBAAA,CACA,cAAA,CACA,SAAA,CACA,UAAA,CACA,SAAA,CACA,cAAA,CACA,SAAA,CACA,eAAA,CACA,WAAA,CAGF,qCACE,8EACE,OAAA,CACA,QAAA,CACA,WAAA,CACA,UAAA,CACA,WAAA,CACA,cAAA,CAAA","sourcesContent":[".btn {\n  position: absolute;\n  cursor: pointer;\n  padding: 0;\n  bottom: 4px;\n  right: 4px;\n  font-size: 24px;\n  z-index: 1;\n  background: none;\n  border: none;\n}\n\n@media screen and (max-width: 600px) {\n  .btn {\n    top: 8px;\n    left: 8px;\n    z-index: 999;\n    width: 30px;\n    height: 30px;\n    font-size: 28px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": "src-web-app-components-beambox-right-panel-AddLayerButton-module__btn--i7y6f"
};
export default ___CSS_LOADER_EXPORT___;
