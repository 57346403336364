// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-views-beambox-ImageTracePanel-StepTune-module__title--dbluc{color:#626262;font-size:18px;font-weight:500;letter-spacing:.5px}.src-web-app-views-beambox-ImageTracePanel-StepTune-module__subtitle--a4d0V{margin:4px}", "",{"version":3,"sources":["webpack://./src/web/app/views/beambox/ImageTracePanel/StepTune.module.scss"],"names":[],"mappings":"AAAA,yEACE,aAAA,CACA,cAAA,CACA,eAAA,CACA,mBAAA,CAGF,4EACE,UAAA","sourcesContent":[".title {\n  color: #626262;\n  font-size: 18px;\n  font-weight: 500;\n  letter-spacing: 0.5px;\n}\n\n.subtitle {\n  margin: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "src-web-app-views-beambox-ImageTracePanel-StepTune-module__title--dbluc",
	"subtitle": "src-web-app-views-beambox-ImageTracePanel-StepTune-module__subtitle--a4d0V"
};
export default ___CSS_LOADER_EXPORT___;
