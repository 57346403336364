var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import React, { forwardRef, useCallback, useRef, useImperativeHandle } from 'react';
import { ConfigProvider, InputNumber } from 'antd';
import styles from './UnitInput.module.scss';
/**
 * Unit Input by Antd InputNumber
 * using formatter and parser to display unit
 * if isInch is true, the unit will be inch but the value will still be mm,
 * the transfer will be handled by formatter and parser
 */
const UnitInput = forwardRef((_a, outerRef) => {
    var { unit, isInch, onBlur, onChange, theme, underline, precision = 4, fireOnChange = false } = _a, props = __rest(_a, ["unit", "isInch", "onBlur", "onChange", "theme", "underline", "precision", "fireOnChange"]);
    const inputRef = useRef(null);
    const valueRef = useRef(); // for onChange
    useImperativeHandle(outerRef, () => inputRef.current, []);
    const formatter = useCallback((value) => {
        let newVal = typeof value === 'string' ? parseFloat(value) : value;
        if (isInch) {
            newVal /= 25.4;
        }
        return String(Math.round(newVal * Math.pow(10, precision)) / Math.pow(10, precision));
    }, [isInch, precision]);
    const parser = useCallback((value) => parseFloat(value.trim().replaceAll(',', '.')) * (isInch ? 25.4 : 1), [isInch]);
    const handleValueChange = useCallback((value) => {
        // Only trigger onChange if the value has changed
        if (value !== valueRef.current && !Number.isNaN(value)) {
            valueRef.current = value; // Update the previous value
            onChange === null || onChange === void 0 ? void 0 : onChange(value);
        }
    }, [onChange]);
    const handlePressEnter = useCallback(() => {
        var _a;
        handleValueChange(parser((_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.value));
    }, [handleValueChange, parser]);
    const handleBlur = useCallback((e) => {
        var _a;
        handleValueChange(parser((_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.value));
        onBlur === null || onBlur === void 0 ? void 0 : onBlur(e);
    }, [handleValueChange, parser, onBlur]);
    const handleStep = useCallback((value) => {
        handleValueChange(value);
    }, [handleValueChange]);
    return (React.createElement("div", { className: classNames(styles.input, { [styles.underline]: underline }) },
        React.createElement(ConfigProvider, { theme: theme },
            React.createElement(InputNumber, Object.assign({ ref: inputRef, onPressEnter: handlePressEnter }, props, { onBlur: handleBlur, onChange: fireOnChange ? onChange : undefined, onStep: handleStep, formatter: formatter, parser: parser })),
            unit && React.createElement("span", { className: styles.unit }, unit))));
});
export default UnitInput;
