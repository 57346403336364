export const modelsSupportUsb = new Set(['fhexa1', 'ado1']);
export const adorModels = new Set(['ado1', 'fad1']);
export const promarkModels = new Set(['fpm1']);
export var PreviewSpeedLevel;
(function (PreviewSpeedLevel) {
    PreviewSpeedLevel[PreviewSpeedLevel["SLOW"] = 1] = "SLOW";
    PreviewSpeedLevel[PreviewSpeedLevel["MEDIUM"] = 2] = "MEDIUM";
    PreviewSpeedLevel[PreviewSpeedLevel["FAST"] = 3] = "FAST";
})(PreviewSpeedLevel || (PreviewSpeedLevel = {}));
export default {
    dpmm: 10,
    camera: {
        imgWidth: 640,
        imgHeight: 280,
        offsetX_ideal: 20,
        offsetY_ideal: 30,
        scaleRatio_ideal: (585 / 720) * 2,
        calibrationPicture: {
            centerX: 90,
            centerY: 90,
            size: 25, // mm
        },
    },
    borderless: {
        safeDistance: {
            X: 40, // mm
        },
    },
    diode: {
        calibrationPicture: {
            offsetX: 69,
            offsetY: 6,
            centerX: 159,
            centerY: 96, // mm
        },
        limitX: 50,
        limitY: 10,
        defaultOffsetX: 70,
        defaultOffsetY: 7,
        safeDistance: {
            X: 50,
            Y: 15, // mm
        },
    },
    allowedWorkarea: {
        fbb2: ['fbb2'],
        'laser-b2': ['fhexa1', 'fbb1p', 'fbb1b', 'fbm1'],
        'laser-b1': ['fhexa1', 'fbb1p', 'fbb1b', 'fbm1'],
        fhexa1: ['fhexa1', 'fbb1p', 'fbb1b', 'fbm1'],
        fbb1p: ['fbb1p', 'fbb1b', 'fbm1'],
        fbb1b: ['fbb1b', 'fbm1'],
        fbm1: ['fbm1'],
        ado1: ['ado1', 'fad1'],
        fad1: ['ado1', 'fad1'],
        fpm1: ['fpm1'],
        flv1: ['flv1'],
    },
    adorModels: ['ado1', 'fad1'],
    highPowerModels: ['fhexa1', 'ado1', 'flv1', 'fpm1'],
    fcodeV2Models: new Set(['ado1', 'fbb2']),
    dpiValueMap: {
        low: 125,
        medium: 250,
        high: 500,
        ultra: 1000,
    },
};
