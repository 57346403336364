var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useContext } from 'react';
import { Button } from 'antd';
import BackButton from 'app/widgets/FullWindowPanel/BackButton';
import Footer from 'app/widgets/FullWindowPanel/Footer';
import FullWindowPanel from 'app/widgets/FullWindowPanel/FullWindowPanel';
import Header from 'app/widgets/FullWindowPanel/Header';
import LeftPanelIcons from 'app/icons/left-panel/LeftPanelIcons';
import Sider from 'app/widgets/FullWindowPanel/Sider';
import useI18n from 'helpers/useI18n';
import { addDialogComponent, isIdExist, popDialogById } from 'app/actions/dialog-controller';
import { useIsMobile } from 'helpers/system-helper';
import Canvas from './Canvas';
import Controls from './Controls';
import { PassThroughContext, PassThroughProvider } from './PassThroughContext';
const PassThrough = ({ onClose }) => {
    const lang = useI18n();
    const isMobile = useIsMobile();
    const tPassThrough = useI18n().pass_through;
    const { handleExport } = useContext(PassThroughContext);
    const button = (React.createElement(Button, { type: "primary", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
            yield handleExport();
            onClose === null || onClose === void 0 ? void 0 : onClose();
        }) }, tPassThrough.export));
    return (React.createElement(FullWindowPanel, { onClose: onClose, mobileTitle: tPassThrough.title, renderMobileFixedContent: () => React.createElement(Canvas, null), renderMobileContents: () => (React.createElement(React.Fragment, null,
            React.createElement(Controls, null),
            React.createElement(Footer, null, button))), renderContents: () => (React.createElement(React.Fragment, null,
            React.createElement(Sider, null,
                React.createElement(BackButton, { onClose: onClose }, lang.buttons.back_to_beam_studio),
                React.createElement(Header, { icon: React.createElement(LeftPanelIcons.PassThrough, null), title: tPassThrough.title }),
                React.createElement(Controls, null),
                React.createElement(Footer, null, button)),
            !isMobile && React.createElement(Canvas, null))) }));
};
export default PassThrough;
export const showPassThrough = (onClose) => {
    const dialogId = 'pass-through';
    if (isIdExist(dialogId))
        popDialogById(dialogId);
    return new Promise((resolve) => {
        addDialogComponent(dialogId, React.createElement(PassThroughProvider, null,
            React.createElement(PassThrough, { onClose: () => {
                    resolve(null);
                    popDialogById(dialogId);
                    onClose === null || onClose === void 0 ? void 0 : onClose();
                } })));
    });
};
