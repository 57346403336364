import eventEmitterFactory from 'helpers/eventEmitterFactory';
export var MessageLevel;
(function (MessageLevel) {
    MessageLevel[MessageLevel["OPEN"] = 0] = "OPEN";
    MessageLevel[MessageLevel["SUCCESS"] = 1] = "SUCCESS";
    MessageLevel[MessageLevel["ERROR"] = 2] = "ERROR";
    MessageLevel[MessageLevel["INFO"] = 3] = "INFO";
    MessageLevel[MessageLevel["WARNING"] = 4] = "WARNING";
    MessageLevel[MessageLevel["LOADING"] = 5] = "LOADING";
})(MessageLevel || (MessageLevel = {}));
const eventEmitter = eventEmitterFactory.createEventEmitter('alert-progress');
const MessageCaller = {
    openMessage: (args) => new Promise((resolve) => {
        eventEmitter.emit('OPEN_MESSAGE', Object.assign({}, args), resolve);
    }),
    closeMessage: (id) => {
        eventEmitter.emit('CLOSE_MESSAGE', id);
    },
    checkIdExist: (id) => {
        const response = {
            result: false,
        };
        eventEmitter.emit('CHECK_PROGRESS_EXIST', id, response);
        return response.result;
    },
};
export default MessageCaller;
