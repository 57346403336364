var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { saveAs } from 'file-saver';
// window.showOpenFilePicker and window.showSaveFilePicker are another options.
// But they are not supported by Firefox currently.
const mimetypeMap = {
    json: 'application/json',
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    svg: 'image/svg+xml',
    txt: 'text/plain',
};
const input = document.createElement('input');
input.setAttribute('id', 'file-input');
document.body.append(input);
let lastPromiseResolve;
const openFileDialog = (options) => {
    // TODO: defaultPath and contrain to file or directory
    if (lastPromiseResolve) {
        lastPromiseResolve({ canceled: true });
    }
    const { filters } = options;
    input.setAttribute('type', 'file');
    if (filters) {
        let acceptAll = false;
        // Accept all file for pads
        if ('ontouchstart' in window && ['MacOS', 'others'].includes(window.os)) {
            acceptAll = true;
        }
        const accept = [];
        for (let i = 0; i < filters.length; i += 1) {
            const filter = filters[i];
            for (let j = 0; j < filter.extensions.length; j += 1) {
                const extension = filter.extensions[j];
                if (extension === '*') {
                    acceptAll = true;
                    break;
                }
                else if (extension) {
                    accept.push(`.${extension}`);
                }
            }
            if (acceptAll) {
                break;
            }
        }
        if (!acceptAll) {
            input.setAttribute('accept', accept.join(','));
        }
    }
    return new Promise((resolve) => {
        input.value = '';
        lastPromiseResolve = resolve;
        input.onchange = () => {
            const { files, value } = input;
            lastPromiseResolve = null;
            resolve({
                filelist: files,
                value,
            });
        };
        // input.addEventListener('')
        input.click();
    });
};
export default {
    showSaveDialog(title, defaultPath, filters) {
        return __awaiter(this, void 0, void 0, function* () {
            return Promise.resolve(null);
        });
    },
    writeFileDialog(getContent, title, defaultPath, filters) {
        return __awaiter(this, void 0, void 0, function* () {
            const content = yield getContent();
            let dataType;
            let fileName = defaultPath || '';
            if (filters && filters.length > 0) {
                const extension = filters[0].extensions[0];
                if (extension && extension !== '*') {
                    if (extension in mimetypeMap) {
                        dataType = mimetypeMap[extension];
                    }
                    else if (!fileName.includes('.')) {
                        fileName = `${fileName}.${extension}`;
                    }
                }
            }
            const data = new Blob([content], { type: dataType });
            if (fileName.startsWith('.')) {
                fileName = ` ${fileName}`;
            }
            saveAs(data, fileName);
            return null;
        });
    },
    showOpenDialog(options) {
        return __awaiter(this, void 0, void 0, function* () {
            const { value } = yield openFileDialog(options);
            return {
                canceled: false,
                filePaths: value ? [value] : [],
            };
        });
    },
    getFileFromDialog(options) {
        return __awaiter(this, void 0, void 0, function* () {
            const { filelist, canceled } = yield openFileDialog(options);
            if (canceled || !filelist)
                return null;
            return filelist[0];
        });
    },
};
