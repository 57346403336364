var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import EventEmitter from 'eventemitter3';
import ClipperWorker from './clipper.worker';
import getClipperLib from './getClipperLib';
class ClipperBase extends EventEmitter {
    constructor(type, ...args) {
        super();
        this.workerMsgId = 0;
        this.sendMessageToWorker = (cmd, data) => __awaiter(this, void 0, void 0, function* () {
            if (!this.worker)
                return null;
            this.workerMsgId += 1;
            const id = this.workerMsgId;
            const response = yield new Promise((resolve) => {
                this.once(`message_${id}`, resolve);
                this.worker.postMessage({ id, cmd, data });
            });
            return response;
        });
        this.addPaths = (path, joinType, endType) => __awaiter(this, void 0, void 0, function* () {
            if (this.worker) {
                yield this.sendMessageToWorker('addPaths', { path, joinType, endType });
                return;
            }
            this.instance.AddPaths(path, joinType, endType);
        });
        this.execute = (...args) => __awaiter(this, void 0, void 0, function* () {
            if (this.worker) {
                const res = yield this.sendMessageToWorker('execute', { args });
                return res;
            }
            this.instance.Execute(args);
            const res = this.type === 'offset' ? args[0] : args[1];
            return res;
        });
        this.terminate = () => {
            if (this.worker)
                this.worker.terminate();
        };
        this.type = type;
        if (window.Worker) {
            this.worker = new ClipperWorker();
            this.worker.onmessage = (e) => {
                console.log('ClipperBase Worker Message:', e.data);
                const { id, data } = e.data;
                this.emit(`message_${id}`, data);
            };
            this.worker.onerror = (e) => {
                this.emit(`error_${this.workerMsgId}`, e);
            };
            if (type === 'offset')
                this.worker.postMessage({ cmd: 'initOffset', data: { args } });
            else
                this.worker.postMessage({ cmd: 'initClipper', data: { args } });
        }
        else {
            const ClipperLib = getClipperLib();
            if (type === 'offset')
                this.instance = new ClipperLib.ClipperOffset(...args);
            else
                this.instance = new ClipperLib.Clipper(...args);
        }
    }
}
export default ClipperBase;
