import React, { memo, useContext, useEffect, useState } from 'react';
import browser from 'implementations/browser';
import dialogCaller from 'app/actions/dialog-caller';
import eventEmitterFactory from 'helpers/eventEmitterFactory';
import FnWrapper from 'app/actions/beambox/svgeditor-function-wrapper';
import LeftPanelIcons from 'app/icons/left-panel/LeftPanelIcons';
import LeftPanelButton from 'app/components/beambox/left-panel/LeftPanelButton';
import useI18n from 'helpers/useI18n';
import { CanvasContext } from 'app/contexts/CanvasContext';
import { getCurrentUser } from 'helpers/api/flux-id';
import { showPassThrough } from 'app/components/pass-through/PassThrough';
const eventEmitter = eventEmitterFactory.createEventEmitter('drawing-tool');
const DrawingToolButtonGroup = ({ className }) => {
    var _a, _b, _c;
    const lang = useI18n();
    const tLeftPanel = lang.beambox.left_panel;
    const { hasPassthroughExtension } = useContext(CanvasContext);
    const [activeButton, setActiveButton] = useState('Cursor');
    const isSubscribed = (_c = (_b = (_a = getCurrentUser()) === null || _a === void 0 ? void 0 : _a.info) === null || _b === void 0 ? void 0 : _b.subscription) === null || _c === void 0 ? void 0 : _c.is_valid;
    const renderToolButton = (id, icon, label, onClick, showBadge) => (React.createElement(LeftPanelButton, { id: `left-${id}`, active: activeButton === id, title: label, icon: icon, onClick: () => {
            setActiveButton(id);
            onClick();
        }, showBadge: showBadge }));
    useEffect(() => {
        eventEmitter.on('SET_ACTIVE_BUTTON', setActiveButton);
        return () => {
            eventEmitter.removeListener('SET_ACTIVE_BUTTON');
        };
    }, []);
    return (React.createElement("div", { className: className },
        renderToolButton('Cursor', React.createElement(LeftPanelIcons.Cursor, null), `${tLeftPanel.label.cursor} (V)`, FnWrapper.useSelectTool),
        renderToolButton('Photo', React.createElement(LeftPanelIcons.Photo, null), `${tLeftPanel.label.photo} (I)`, FnWrapper.importImage),
        renderToolButton('MyCloud', React.createElement(LeftPanelIcons.Cloud, null), tLeftPanel.label.my_cloud, () => dialogCaller.showMyCloud(FnWrapper.useSelectTool), isSubscribed),
        renderToolButton('Text', React.createElement(LeftPanelIcons.Text, null), `${tLeftPanel.label.text} (T)`, FnWrapper.insertText),
        renderToolButton('Element', React.createElement(LeftPanelIcons.Element, null), `${tLeftPanel.label.shapes} (E)`, () => dialogCaller.showShapePanel(FnWrapper.useSelectTool)),
        renderToolButton('Rectangle', React.createElement(LeftPanelIcons.Rect, null), `${tLeftPanel.label.rect} (M)`, FnWrapper.insertRectangle),
        renderToolButton('Ellipse', React.createElement(LeftPanelIcons.Oval, null), `${tLeftPanel.label.oval} (C)`, FnWrapper.insertEllipse),
        renderToolButton('Polygon', React.createElement(LeftPanelIcons.Polygon, null), tLeftPanel.label.polygon, FnWrapper.insertPolygon),
        renderToolButton('Line', React.createElement(LeftPanelIcons.Line, null), `${tLeftPanel.label.line} (\\)`, FnWrapper.insertLine),
        renderToolButton('Pen', React.createElement(LeftPanelIcons.Draw, null), `${tLeftPanel.label.pen} (P)`, FnWrapper.insertPath),
        renderToolButton('DM', React.createElement(LeftPanelIcons.DM, null), 'Design Market', () => browser.open(lang.topbar.menu.link.design_market)),
        hasPassthroughExtension &&
            renderToolButton('PassThrough', React.createElement(LeftPanelIcons.PassThrough, null), tLeftPanel.label.pass_through, () => showPassThrough(FnWrapper.useSelectTool))));
};
export default memo(DrawingToolButtonGroup);
