var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from 'react';
import classNames from 'classnames';
import EventEmitter from 'eventemitter3';
import defaultIcons from 'app/constants/noun-project-constants';
import DraggableWindow from 'app/widgets/DraggableWindow';
import i18n from 'helpers/i18n';
import importSvg from 'app/svgedit/operations/import/importSvg';
import Modal from 'app/widgets/Modal';
import readBitmapFile from 'app/svgedit/operations/import/readBitmapFile';
import storage from 'implementations/storage';
import { fluxIDEvents, getNPIconByID, getNPIconsByTerm } from 'helpers/api/flux-id';
import { getSVGAsync } from 'helpers/svg-editor-helper';
let svgEditor;
getSVGAsync((globalSVG) => {
    svgEditor = globalSVG.Editor;
});
const LANG = i18n.lang.noun_project_panel;
const INFO_DIALOG_WIDTH = 250;
const INFO_DIALOG_HEIGHT = 60;
var Tabs;
(function (Tabs) {
    Tabs[Tabs["LIBRARY"] = 0] = "LIBRARY";
    Tabs[Tabs["HISTORY"] = 1] = "HISTORY";
})(Tabs || (Tabs = {}));
let cacheTotalLength = 0;
const searchResultCache = {};
const searchTermHistory = [];
const CACHE_SIZE_LIMIT = 20;
const updateCache = (icons, term, page) => {
    const addToCache = (icons, term, page) => {
        if (!searchResultCache[term]) {
            searchResultCache[term] = [];
        }
        if (page >= searchResultCache[term].length) {
            cacheTotalLength += 1;
        }
        searchResultCache[term][page] = icons;
    };
    const updateTermHistory = (term) => {
        const index = searchTermHistory.findIndex((t) => t === term);
        if (index >= 0) {
            searchTermHistory.splice(index, 1);
        }
        searchTermHistory.push(term);
    };
    const checkAndClearOversizedCache = () => {
        if (cacheTotalLength > CACHE_SIZE_LIMIT) {
            if (searchTermHistory.length > 1) {
                const oldestTerm = searchTermHistory.shift();
                cacheTotalLength -= searchResultCache[oldestTerm].length;
                delete searchResultCache[oldestTerm];
            }
        }
    };
    addToCache(icons, term, page);
    updateTermHistory(term);
    checkAndClearOversizedCache();
};
export const NounProjectPanelController = new EventEmitter();
class NounProjectPanel extends React.Component {
    constructor(props) {
        super(props);
        this.fetchingTerm = '';
        this.fetchedPage = 0;
        this.handleNext = () => __awaiter(this, void 0, void 0, function* () {
            const { term, resultIcons } = this.state;
            let hasNext = false;
            try {
                const icons = yield this.getIconsByTerm(term, resultIcons.length);
                if (icons) {
                    resultIcons.push(icons);
                    if (icons.length > 48) {
                        hasNext = true;
                    }
                }
                this.setState({ resultIcons, hasNext });
            }
            catch (e) {
                console.error('When handling next:\n', e);
                console.log('Retry in 0.3s');
                setTimeout(() => {
                    this.handleNext();
                }, 300);
            }
        });
        const nounProjectHistory = storage.get('noun-project-history') || [];
        this.state = {
            currentTab: Tabs.LIBRARY,
            term: '',
            isSearching: false,
            hadErrorWhenSearching: false,
            resultIcons: [],
            historyIcons: nounProjectHistory,
            highLightedIndex: -1,
            hasNext: false,
            showInfoModal: false,
            infoModalX: 0,
            infoModalY: 0,
        };
        this.scrollPosition = {};
        this.searchInput = React.createRef();
        this.contentContainer = React.createRef();
    }
    componentDidMount() {
        fluxIDEvents.on('logged-out', this.props.onClose);
        NounProjectPanelController.on('insertIcon', (icon) => this.handleInsert(icon));
    }
    componentWillUnmount() {
        fluxIDEvents.removeListener('logged-out', this.props.onClose);
        NounProjectPanelController.removeAllListeners();
    }
    renderTabs() {
        const { currentTab } = this.state;
        const changeTab = (tab) => {
            if (tab !== currentTab) {
                this.scrollPosition[currentTab] = this.contentContainer.current.scrollTop;
                this.setState({
                    highLightedIndex: -1,
                    currentTab: tab,
                }, () => {
                    this.contentContainer.current.scrollTop = this.scrollPosition[tab];
                });
            }
        };
        return (React.createElement("div", { className: "tabs" },
            React.createElement("div", { className: classNames('tab', { active: currentTab === Tabs.LIBRARY }), onClick: () => changeTab(Tabs.LIBRARY) }, LANG.elements),
            React.createElement("div", { className: classNames('tab', { active: currentTab === Tabs.HISTORY }), onClick: () => changeTab(Tabs.HISTORY) }, LANG.recent)));
    }
    renderHeader() {
        const { currentTab } = this.state;
        if (currentTab === Tabs.LIBRARY) {
            return this.renderSearchInput();
        }
        return null;
    }
    renderSearchInput() {
        const { term } = this.state;
        return (React.createElement("div", { className: "search-panel" },
            React.createElement("img", { className: "search-icon", src: "img/noun-project-panel/icon-search.svg", draggable: "false" }),
            React.createElement("input", { type: "text", id: "search-input", ref: this.searchInput, placeholder: LANG.search, defaultValue: term, onKeyDown: (e) => this.handleSearchInputKeyDown(e) }),
            React.createElement("div", { className: classNames('cancel-button', { hide: term === '' }), onClick: () => this.onCancelSearch() },
                React.createElement("img", { src: "img/icon-clear.svg", title: LANG.clear }))));
    }
    handleSearchInputKeyDown(e) {
        return __awaiter(this, void 0, void 0, function* () {
            e.stopPropagation();
            if (e.key === 'Enter') {
                this.handleSearch();
            }
        });
    }
    handleSearch() {
        return __awaiter(this, void 0, void 0, function* () {
            let searchingTerm = this.searchInput.current.value;
            const { term, hadErrorWhenSearching } = this.state;
            if (!searchingTerm || term === searchingTerm && !hadErrorWhenSearching)
                return;
            if (searchingTerm in searchResultCache) {
                this.getIconsFromCache(searchingTerm);
                return;
            }
            this.setState({
                term: '',
                isSearching: true,
                hadErrorWhenSearching: false,
                resultIcons: [],
                highLightedIndex: -1,
            });
            try {
                const resultIcons = [];
                const icons = yield this.getIconsByTerm(searchingTerm, 0);
                let hasNext = false;
                if (icons) {
                    resultIcons.push(icons);
                    if (icons.length > 48) {
                        hasNext = true;
                    }
                }
                this.contentContainer.current.scrollTop = 0;
                this.setState({
                    term: searchingTerm,
                    isSearching: false,
                    resultIcons,
                    hasNext,
                }, () => {
                    setTimeout(() => {
                        this.contentContainer.current.scrollTop = 0;
                    }, 30);
                });
            }
            catch (e) {
                console.log(e.message);
                console.log(`Error when search ${searchingTerm}`, e);
                this.setState({
                    term: searchingTerm,
                    isSearching: false,
                    hadErrorWhenSearching: true,
                    hasNext: false,
                });
            }
        });
    }
    getIconsFromCache(term) {
        const resultIcons = [...searchResultCache[term]];
        const hasNext = resultIcons[resultIcons.length - 1].length > 48;
        this.fetchedPage = resultIcons.length - 1;
        this.fetchingTerm = term;
        this.contentContainer.current.scrollTop = 0;
        this.setState({
            term,
            resultIcons,
            highLightedIndex: -1,
            hasNext,
        });
    }
    getIconsByTerm(term, page) {
        return __awaiter(this, void 0, void 0, function* () {
            if (term === this.fetchingTerm && page <= this.fetchedPage) {
                return;
            }
            this.fetchingTerm = term;
            this.fetchedPage = page;
            const data = yield getNPIconsByTerm(term, page * 48);
            if (!data || data.status !== 'ok') {
                const message = data.message ? `${data.info}: ${data.message}` : data.info;
                throw message || 'Failed to get icons';
            }
            updateCache(data.icons, term, page);
            return data.icons;
        });
    }
    getIconById(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const data = yield getNPIconByID(id);
            if (!data || data.status !== 'ok') {
                const message = data.message ? `${data.info}: ${data.message}` : data.info;
                throw message || 'Failed to get icons';
            }
            return data.icon;
        });
    }
    onCancelSearch() {
        this.searchInput.current.value = '';
        this.setState({
            term: '',
            resultIcons: [],
            hadErrorWhenSearching: false,
            highLightedIndex: -1,
            hasNext: false,
        }, () => {
            this.scrollPosition[Tabs.LIBRARY] = 0;
            this.contentContainer.current.scrollTop = 0;
        });
    }
    renderContent() {
        const { currentTab } = this.state;
        let content;
        if (currentTab === Tabs.LIBRARY) {
            content = this.renderLibraryContent();
        }
        else if (currentTab === Tabs.HISTORY) {
            content = this.renderHistoryContent();
        }
        return (React.createElement("div", { className: classNames('content-container', { 'no-header': currentTab === Tabs.HISTORY }), ref: this.contentContainer, onScroll: (e) => this.onSearchResultScroll(e) }, content));
    }
    renderLibraryContent() {
        const { isSearching, hadErrorWhenSearching, resultIcons, hasNext, term } = this.state;
        if (isSearching) {
            return this.renderSearchingContent();
        }
        else if (term === '') {
            return this.renderIconList(defaultIcons, false);
        }
        else if (resultIcons.length === 0) {
            if (isSearching) {
            }
            else if (hadErrorWhenSearching) {
                return this.renderSearchError();
            }
            return this.renderSearchNoResult();
        }
        console.log(resultIcons);
        const icons = [];
        resultIcons.forEach((result) => {
            icons.push(...result.slice(0, 48));
        });
        return this.renderIconList(icons, hasNext);
    }
    renderHistoryContent() {
        const { historyIcons } = this.state;
        return this.renderIconList([...historyIcons].reverse(), false);
    }
    renderSearchingContent() {
        return this.renderNoIconContent('', classNames('spinner-roller'));
    }
    renderSearchError() {
        const { term } = this.state;
        return this.renderNoIconContent(`Error occured when search "${term}", click to retry`, '', () => this.handleSearch());
    }
    renderSearchNoResult() {
        const { term } = this.state;
        return this.renderNoIconContent(`We didn't find any icons for "${term}"`);
    }
    renderNoIconContent(text, className = '', onClick = null) {
        return (React.createElement("div", { className: 'no-icon-results' },
            React.createElement("div", { className: className, onClick: onClick }, text)));
    }
    renderIconList(icons, hasNext) {
        const { highLightedIndex } = this.state;
        const iconCells = [];
        for (let i = 0; i < icons.length; i++) {
            iconCells.push(this.renderIconCell(i, icons[i], highLightedIndex === i));
        }
        let loadingIndicator = hasNext ? (React.createElement("div", { className: 'loading-placeholder' },
            React.createElement("div", { className: 'dot-flashing' }))) : null;
        return (React.createElement("div", { className: 'icons-list', onClick: () => this.onHighlight(-1) },
            iconCells,
            loadingIndicator));
    }
    renderIconCell(index, icon, isHighlighted) {
        return (React.createElement("div", { className: classNames('icon-cell', { highlight: isHighlighted }), key: icon.id, onClick: (e) => {
                e.stopPropagation();
                this.onHighlight(index);
            }, onDoubleClick: () => this.handleInsert(icon), onDragStart: (e) => this.onIconDragStart(e, icon) },
            React.createElement("img", { src: icon.preview_url_84 })));
    }
    onHighlight(index) {
        const { highLightedIndex } = this.state;
        if (index !== highLightedIndex) {
            this.setState({ highLightedIndex: index });
        }
    }
    handleInsert(icon) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                console.log(icon);
                if (icon.icon_url) {
                    console.log(icon.icon_url);
                    const expireTime = Number(/\?Expires=(\d+)/.exec(icon.icon_url)[1]);
                    if (Date.now() / 1000 > expireTime) {
                        const updatedIcon = yield this.getIconById(icon.id);
                        console.log(updatedIcon);
                        Object.assign(icon, updatedIcon);
                    }
                    const response = yield fetch(icon.icon_url);
                    console.log(response);
                    const blob = yield response.blob();
                    importSvg(blob, {
                        isFromNounProject: true,
                    });
                }
                else {
                    const response = yield fetch(icon.preview_url);
                    const blob = yield response.blob();
                    readBitmapFile(blob);
                }
            }
            catch (e) {
                console.log(e);
            }
            this.addToHistory(icon);
        });
    }
    addToHistory(icon) {
        const { historyIcons, currentTab, highLightedIndex } = this.state;
        for (let i = 0; i < historyIcons.length; i++) {
            if (historyIcons[i].id === icon.id) {
                historyIcons.splice(i, 1);
                break;
            }
        }
        historyIcons.push(icon);
        if (historyIcons.length > 50) {
            historyIcons.splice(0, 1);
        }
        storage.set('noun-project-history', historyIcons);
        this.setState({
            historyIcons,
            highLightedIndex: currentTab === Tabs.HISTORY ? 0 : highLightedIndex,
        });
    }
    onIconDragStart(e, icon) {
        const dt = e.dataTransfer;
        dt.setData('text/noun-project-icon', JSON.stringify(icon));
    }
    onSearchResultScroll(e) {
        return __awaiter(this, void 0, void 0, function* () {
            const { resultIcons, isSearching, hasNext } = this.state;
            if (!hasNext || isSearching || resultIcons.length <= this.fetchedPage)
                return;
            const eventTarget = e.target;
            if (eventTarget.scrollTop + eventTarget.offsetHeight >= eventTarget.scrollHeight - 80) {
                console.log('fetching new icon', resultIcons.length);
                yield this.handleNext();
            }
        });
    }
    getHighlightedIcon() {
        const { highLightedIndex, term, resultIcons, historyIcons, currentTab } = this.state;
        let highLightedIcon = null;
        if (highLightedIndex >= 0) {
            if (currentTab === Tabs.LIBRARY) {
                if (term === '') {
                    highLightedIcon = defaultIcons[highLightedIndex];
                }
                else {
                    const page = Math.floor(highLightedIndex / 48);
                    const index = highLightedIndex % 48;
                    highLightedIcon = resultIcons[page][index];
                }
            }
            else if (currentTab === Tabs.HISTORY) {
                const reversedIndex = historyIcons.length - highLightedIndex - 1;
                highLightedIcon = historyIcons[reversedIndex];
            }
        }
        return highLightedIcon;
    }
    renderFooter() {
        const highLightedIcon = this.getHighlightedIcon();
        return (React.createElement("div", { className: 'footer' },
            React.createElement("div", { className: classNames('term') }, highLightedIcon ? highLightedIcon.term : ''),
            React.createElement("div", { className: classNames('info', { disabled: !highLightedIcon }), title: 'info', onClick: (e) => this.onInfoClick(e) },
                React.createElement("img", { className: 'search-icon', src: `img/icon-info.svg`, draggable: 'false' }))));
    }
    onInfoClick(e) {
        this.setState({ showInfoModal: true, infoModalX: e.clientX, infoModalY: e.clientY });
    }
    renderInfoModal() {
        const { showInfoModal, infoModalX, infoModalY } = this.state;
        if (!showInfoModal)
            return;
        const highLightedIcon = this.getHighlightedIcon();
        if (!highLightedIcon)
            return;
        const infoWindowStyle = {};
        if (innerWidth - infoModalX > INFO_DIALOG_WIDTH) {
            infoWindowStyle.left = infoModalX;
        }
        else {
            infoWindowStyle.right = innerWidth - infoModalX;
        }
        if (innerHeight - infoModalY > INFO_DIALOG_HEIGHT) {
            infoWindowStyle.top = infoModalY;
        }
        else {
            infoWindowStyle.bottom = innerHeight - infoModalY;
        }
        return (React.createElement(Modal, { onClose: () => this.setState({ showInfoModal: false }) },
            React.createElement("div", { className: classNames('info-window'), style: infoWindowStyle },
                React.createElement("div", { className: classNames('term') }, `Name: ${highLightedIcon.term}`),
                React.createElement("div", { className: classNames('id') }, `ID: ${highLightedIcon.id}`),
                React.createElement("div", { className: classNames('author') }, `Author: ${highLightedIcon.uploader.name}`))));
    }
    render() {
        const { onClose } = this.props;
        return (React.createElement("div", { className: classNames('noun-project-panel-container') },
            React.createElement(DraggableWindow, { title: LANG.shapes, containerClass: classNames('noun-project-panel'), defaultPosition: { x: 50, y: 50 }, onClose: () => onClose() },
                React.createElement("div", null,
                    this.renderTabs(),
                    this.renderHeader(),
                    this.renderContent(),
                    this.renderFooter())),
            this.renderInfoModal()));
    }
}
;
export default NounProjectPanel;
