var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import dialog from 'implementations/dialog';
import fs from 'implementations/fileSystem';
import styles from './PathInput.module.scss';
export var InputType;
(function (InputType) {
    InputType[InputType["FILE"] = 0] = "FILE";
    InputType[InputType["FOLDER"] = 1] = "FOLDER";
    InputType[InputType["BOTH"] = 2] = "BOTH";
})(InputType || (InputType = {}));
const propertiesMap = {
    0: ['openFile'],
    1: ['openDirectory', 'createDirectory', 'promptToCreate'],
    2: ['openFile', 'openDirectory', 'createDirectory', 'promptToCreate'],
};
const PathInput = ({ buttonTitle, className, defaultValue = '', getValue, forceValidValue = true, type, }) => {
    const [displayValue, setDisplayValue] = useState(defaultValue);
    const [savedValue, setSavedValue] = useState(defaultValue);
    const inputEl = useRef(null);
    useEffect(() => {
        setDisplayValue(defaultValue);
        setSavedValue(defaultValue);
    }, [defaultValue]);
    const validateValue = (val) => {
        if (fs.exists(val)) {
            if (type === InputType.BOTH)
                return true;
            return ((type === InputType.FILE && fs.isFile(val)) ||
                (type === InputType.FOLDER && fs.isDirectory(val)));
        }
        return false;
    };
    const updateValue = () => {
        const isValid = validateValue(displayValue);
        if (!forceValidValue || isValid) {
            if (displayValue !== savedValue) {
                setSavedValue(displayValue);
                getValue(displayValue, isValid);
            }
        }
        else {
            setDisplayValue(savedValue);
        }
    };
    const handleChange = (e) => {
        const target = e.target;
        setDisplayValue(target.value);
    };
    const handleKeyUp = (e) => {
        // backspace somehow does not trigger onchange event
        const target = e.target;
        if (target.value !== displayValue) {
            setDisplayValue(target.value);
        }
    };
    const setValueFromDialog = () => __awaiter(void 0, void 0, void 0, function* () {
        const properties = propertiesMap[type];
        const option = {
            properties,
            defaultPath: savedValue,
        };
        const { filePaths, canceled } = yield dialog.showOpenDialog(option);
        if (!canceled) {
            const isValid = validateValue(filePaths[0]);
            if (!forceValidValue || isValid) {
                setSavedValue(filePaths[0]);
                setDisplayValue(filePaths[0]);
                getValue(filePaths[0], isValid);
            }
        }
    });
    return (React.createElement("div", { className: classNames(styles.container, className) },
        React.createElement("div", { className: styles.btn, title: buttonTitle, onClick: setValueFromDialog },
            React.createElement("img", { src: "img/right-panel/icon-import.svg" })),
        React.createElement("input", { id: "location-input", type: "text", value: displayValue, onBlur: updateValue, onChange: handleChange, onKeyUp: handleKeyUp, ref: inputEl })));
};
export default PathInput;
