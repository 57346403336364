// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-views-beambox-Right-Panels-DimensionPanel-FlipButtons-module__container--9KHgZ{display:flex;align-items:center;gap:4px}", "",{"version":3,"sources":["webpack://./src/web/app/views/beambox/Right-Panels/DimensionPanel/FlipButtons.module.scss"],"names":[],"mappings":"AAAA,4FACE,YAAA,CACA,kBAAA,CACA,OAAA","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  gap: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "src-web-app-views-beambox-Right-Panels-DimensionPanel-FlipButtons-module__container--9KHgZ"
};
export default ___CSS_LOADER_EXPORT___;
