// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-widgets-FullWindowPanel-FullWindowPanel-module__container--D2FSF{display:flex}@media screen and (min-width: 601px){.src-web-app-widgets-FullWindowPanel-FullWindowPanel-module__container--D2FSF{position:relative;z-index:1;height:100vh;width:100vw;background-color:#fff}}@media screen and (max-width: 600px){.src-web-app-widgets-FullWindowPanel-FullWindowPanel-module__container--D2FSF{flex-direction:column}}.src-web-app-widgets-FullWindowPanel-FullWindowPanel-module__container--D2FSF div[class*=scroll-content]{margin-bottom:0;padding-bottom:0}.src-web-app-widgets-FullWindowPanel-FullWindowPanel-module__container--D2FSF.src-web-app-widgets-FullWindowPanel-FullWindowPanel-module__desktop--WQqcK{z-index:1000}.src-web-app-widgets-FullWindowPanel-FullWindowPanel-module__container--D2FSF.src-web-app-widgets-FullWindowPanel-FullWindowPanel-module__desktop--WQqcK.src-web-app-widgets-FullWindowPanel-FullWindowPanel-module__windows--xOcyK{max-height:calc(100vh - 30px)}", "",{"version":3,"sources":["webpack://./src/web/app/widgets/FullWindowPanel/FullWindowPanel.module.scss"],"names":[],"mappings":"AAAA,8EACE,YAAA,CACA,qCAFF,8EAGI,iBAAA,CACA,SAAA,CACA,YAAA,CACA,WAAA,CACA,qBAAA,CAAA,CAEF,qCATF,8EAUI,qBAAA,CAAA,CAGF,yGACE,eAAA,CACA,gBAAA,CAGF,yJACE,YAAA,CAEA,oOAEE,6BAAA","sourcesContent":[".container {\n  display: flex;\n  @media screen and (min-width: 601px) {\n    position: relative;\n    z-index: 1;\n    height: 100vh;\n    width: 100vw;\n    background-color: #ffffff;\n  }\n  @media screen and (max-width: 600px) {\n    flex-direction: column;\n  }\n\n  div[class*=\"scroll-content\"] {\n    margin-bottom: 0;\n    padding-bottom: 0;\n  }\n\n  &.desktop {\n    z-index: 1000;\n\n    &.windows {\n      // Windows desktop titlebar = 30px\n      max-height: calc(100vh - 30px);\n    }\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "src-web-app-widgets-FullWindowPanel-FullWindowPanel-module__container--D2FSF",
	"desktop": "src-web-app-widgets-FullWindowPanel-FullWindowPanel-module__desktop--WQqcK",
	"windows": "src-web-app-widgets-FullWindowPanel-FullWindowPanel-module__windows--xOcyK"
};
export default ___CSS_LOADER_EXPORT___;
