import React from 'react';
import eventEmitterFactory from 'helpers/eventEmitterFactory';
export const TimeEstimationButtonContext = React.createContext({
    estimatedTime: null,
    setEstimatedTime: () => { },
});
const timeEstimationButtonEventEmitter = eventEmitterFactory.createEventEmitter('time-estimation-button');
export class TimeEstimationButtonContextProvider extends React.Component {
    constructor(props) {
        super(props);
        this.setEstimatedTime = (newTime) => {
            const { estimatedTime } = this.state;
            if (newTime !== estimatedTime) {
                this.setState({ estimatedTime: newTime });
            }
        };
        this.state = {
            estimatedTime: null,
        };
    }
    componentDidMount() {
        timeEstimationButtonEventEmitter.on('SET_ESTIMATED_TIME', this.setEstimatedTime.bind(this));
    }
    componentWillUnmount() {
        timeEstimationButtonEventEmitter.removeAllListeners();
    }
    render() {
        const { setEstimatedTime } = this;
        const { estimatedTime } = this.state;
        const { children } = this.props;
        return (React.createElement(TimeEstimationButtonContext.Provider, { value: {
                setEstimatedTime,
                estimatedTime,
            } }, children));
    }
}
