import * as React from 'react';
import { Form, Input, Modal } from 'antd';
import { useRef, useState } from 'react';
import Constants from 'app/constants/input-lightbox-constants';
import i18n from 'helpers/i18n';
const INPUT_TYPE_MAP = {
    [Constants.TYPE_TEXT]: 'text',
    [Constants.TYPE_NUMBER]: 'number',
    [Constants.TYPE_PASSWORD]: 'password',
    [Constants.TYPE_FILE]: 'file',
};
const InputLightBox = (props) => {
    const inputRef = useRef();
    const [allowSubmit, setAllowSubmit] = useState(false);
    const { onClose, onSubmit, caption, inputHeader, defaultValue, confirmText, type, maxLength, } = props;
    const onCancel = (e) => {
        e.preventDefault();
        onClose('cancel');
    };
    const processData = (e) => {
        e.preventDefault();
        let returnValue;
        const inputElement = inputRef.current.input;
        if (Constants.TYPE_FILE === type) {
            returnValue = inputElement.files;
        }
        else {
            returnValue = inputElement.value;
        }
        const result = onSubmit(returnValue);
        console.info('Submit result', result);
        onClose('submit');
    };
    const handleKeyDown = (e) => {
        e.stopPropagation();
    };
    const inputKeyUp = (e) => {
        const target = e.currentTarget;
        const targetFiles = target.files || [];
        setAllowSubmit(target.value.length > 0 || (targetFiles.length || 0) > 0);
    };
    const inputType = INPUT_TYPE_MAP[type] || 'text';
    return (React.createElement(Modal, { open: true, centered: true, title: caption, cancelText: i18n.lang.alert.cancel, onCancel: (e) => onCancel(e), okText: confirmText || i18n.lang.alert.confirm, onOk: (e) => processData(e), okButtonProps: { disabled: !allowSubmit } },
        React.createElement(Form, null,
            React.createElement("p", null, inputHeader),
            React.createElement(Form.Item, null,
                React.createElement(Input, { type: inputType, ref: inputRef, defaultValue: defaultValue, autoFocus: true, onKeyDown: (e) => handleKeyDown(e), onKeyUp: inputKeyUp, onChange: inputKeyUp, maxLength: maxLength })))));
};
export default InputLightBox;
