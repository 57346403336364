var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import deviceMaster from 'helpers/device-master';
import progressCaller from 'app/actions/progress-caller';
import i18n from 'helpers/i18n';
// TODO: move this 2 function to camera-preview-helpers
import { getPerspectivePointsZ3Regression, interpolatePointsFromHeight, } from 'helpers/camera-calibration-helper';
import { getWorkarea } from 'app/constants/workarea-constants';
import FisheyePreviewManagerBase from './FisheyePreviewManagerBase';
import getAutoFocusPosition from './getAutoFocusPosition';
import getLevelingData from './getLevelingData';
import getHeight from './getHeight';
import loadCamera3dRotation from './loadCamera3dRotation';
import rawAndHome from './rawAndHome';
class FisheyePreviewManagerV1 extends FisheyePreviewManagerBase {
    constructor(device, params) {
        super();
        this.version = 1;
        this.support3dRotation = true;
        this.update3DRotation = (newData) => __awaiter(this, void 0, void 0, function* () {
            const dhChanged = this.rotationData && this.rotationData.dh !== newData.dh;
            const { device, objectHeight } = this;
            console.log('Applying', newData);
            const { rx, ry, rz, sh, ch, tx = 0, ty = 0 } = newData;
            const workarea = getWorkarea(device.model, 'ado1');
            const z = workarea.deep - objectHeight;
            const rotationZ = sh * (z + ch);
            this.rotationData = Object.assign({}, newData);
            yield deviceMaster.set3dRotation({ rx, ry, rz, h: rotationZ, tx, ty });
            if (dhChanged)
                yield this.onObjectHeightChanged();
        });
        this.calculatePerspectivePoints = () => {
            const { device, params, levelingData: baseLevelingData, levelingOffset, rotationData, objectHeight, } = this;
            const { heights, center, points, z3regParam } = params;
            const workarea = getWorkarea(device.model, 'ado1');
            let finalHeight = objectHeight;
            console.log('Use Height: ', objectHeight);
            if (rotationData === null || rotationData === void 0 ? void 0 : rotationData.dh)
                finalHeight += rotationData.dh;
            console.log('After applying 3d rotation dh: ', finalHeight);
            const levelingData = Object.assign({}, baseLevelingData);
            const keys = Object.keys(levelingData);
            keys.forEach((key) => {
                var _a;
                levelingData[key] += (_a = levelingOffset[key]) !== null && _a !== void 0 ? _a : 0;
            });
            let perspectivePoints;
            if (points && heights) {
                [perspectivePoints] = points;
                perspectivePoints = interpolatePointsFromHeight(finalHeight !== null && finalHeight !== void 0 ? finalHeight : 0, heights, points, {
                    chessboard: [48, 36],
                    workarea: [workarea.width, workarea.height],
                    center,
                    levelingOffsets: levelingData,
                });
            }
            else if (z3regParam) {
                perspectivePoints = getPerspectivePointsZ3Regression(finalHeight !== null && finalHeight !== void 0 ? finalHeight : 0, z3regParam, {
                    chessboard: [48, 36],
                    workarea: [workarea.width, workarea.height],
                    center,
                    levelingOffsets: levelingData,
                });
            }
            return perspectivePoints;
        };
        this.onObjectHeightChanged = () => __awaiter(this, void 0, void 0, function* () {
            const { params } = this;
            const perspectivePoints = this.calculatePerspectivePoints();
            const { k, d, center } = params;
            yield deviceMaster.setFisheyeMatrix({ k, d, center, points: perspectivePoints }, true);
        });
        this.device = device;
        this.params = params;
    }
    setupFisheyePreview(args = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            const { device } = this;
            const { lang } = i18n;
            const { progressId } = args;
            if (!progressId)
                progressCaller.openNonstopProgress({ id: this.progressId });
            progressCaller.update(progressId || this.progressId, { message: 'Fetching leveling data...' });
            const levelingData = yield getLevelingData('hexa_platform');
            const bottomCoverLevelingData = yield getLevelingData('bottom_cover');
            this.levelingOffset = yield getLevelingData('offset');
            const deviceRotationData = yield loadCamera3dRotation();
            const rotationData = Object.assign(Object.assign({}, deviceRotationData), { tx: 0, ty: 0 });
            const keys = Object.keys(levelingData);
            keys.forEach((key) => {
                levelingData[key] -= bottomCoverLevelingData[key];
            });
            progressCaller.update(progressId || this.progressId, {
                message: lang.message.getProbePosition,
            });
            yield rawAndHome(progressId || this.progressId);
            const height = yield getHeight(device, progressId || this.progressId);
            if (typeof height !== 'number') {
                if (!progressId)
                    progressCaller.popById(this.progressId);
                return false;
            }
            progressCaller.openNonstopProgress({
                id: progressId || this.progressId,
                message: lang.message.getProbePosition,
            });
            this.objectHeight = height;
            const autoFocusRefKey = yield getAutoFocusPosition(device);
            const refHeight = levelingData[autoFocusRefKey];
            keys.forEach((key) => {
                levelingData[key] = Math.round((levelingData[key] - refHeight) * 1000) / 1000;
            });
            this.levelingData = levelingData;
            progressCaller.update(progressId || this.progressId, { message: lang.message.endingRawMode });
            yield deviceMaster.endRawMode();
            if (deviceRotationData)
                yield this.update3DRotation(rotationData);
            yield this.onObjectHeightChanged();
            if (!progressId)
                progressCaller.popById(this.progressId);
            return true;
        });
    }
}
export default FisheyePreviewManagerV1;
