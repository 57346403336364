import classNames from 'classnames';
import React from 'react';
import i18n from 'helpers/i18n';
import storage from 'implementations/storage';
import UnitInput from 'app/widgets/Unit-Input-v2';
const LANG = i18n.lang.beambox.tool_panels;
function OffsetDistancePanel({ distance: distanceProps, onValueChange }) {
    const [distance, updateDistance] = React.useState(distanceProps);
    const [isCollapsed, updateIsCollapsed] = React.useState(false);
    const updateDist = (val) => {
        onValueChange(val);
        updateDistance(val);
    };
    const getValueCaption = () => {
        const units = storage.get('default-units') || 'mm';
        return units === 'inches' ? `${Number(distance / 25.4).toFixed(3)}\"` : `${distance} mm`;
    };
    return (React.createElement("div", { className: "tool-panel" },
        React.createElement("label", { className: "controls accordion" },
            React.createElement("input", { type: "checkbox", className: "accordion-switcher", defaultChecked: true }),
            React.createElement("p", { className: "caption", onClick: () => updateIsCollapsed(!isCollapsed) },
                LANG._offset.dist,
                React.createElement("span", { className: "value" }, getValueCaption())),
            React.createElement("div", { className: classNames('tool-panel-body', { collapsed: isCollapsed }) },
                React.createElement("div", null,
                    React.createElement("div", { className: "control offset-dist" },
                        React.createElement(UnitInput, { min: 0, unit: "mm", defaultValue: distance, getValue: (val) => updateDist(val) })))))));
}
export default OffsetDistancePanel;
