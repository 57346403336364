const dpi = 72;
const svgUnitScaling = 254 / dpi; // 本來 72 個點代表 1 inch, 現在 254 個點代表 1 inch.
const unitMap = {
    inch: 25.4 * 10,
    cm: 10 * 10,
    mm: 10,
    px: svgUnitScaling,
    pt: 1,
    text: 1, // self made
};
const timeUnitMap = {
    h: 60 * 60,
    m: 60,
    s: 1,
    ms: 0.001,
};
const convertUnit = (val, to, from = 'pt') => {
    if (to === from || !unitMap[to]) {
        return val;
    }
    return (val * unitMap[from]) / unitMap[to];
};
const convertTimeUnit = (val, to, from = 's') => {
    if (to === from || !timeUnitMap[to]) {
        return val;
    }
    return (val * timeUnitMap[from]) / timeUnitMap[to];
};
export default {
    unitMap,
    timeUnitMap,
    convertUnit,
    convertTimeUnit,
};
