var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import deviceMaster from 'helpers/device-master';
const getLevelingData = (type) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const data = yield deviceMaster.fetchAutoLevelingData(type);
        return data;
    }
    catch (e) {
        console.log('Failed to getLevelingData', e);
        return { A: 0, B: 0, C: 0, D: 0, E: 0, F: 0, G: 0, H: 0, I: 0 };
    }
});
export default getLevelingData;
