var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Button, ConfigProvider, Switch } from 'antd';
import eventEmitterFactory from 'helpers/eventEmitterFactory';
import FluxIcons from 'app/icons/flux/FluxIcons';
import fontHelper from 'helpers/fonts/fontHelper';
import FontFuncs from 'app/actions/beambox/font-funcs';
import history from 'app/svgedit/history/history';
import ObjectPanelItem from 'app/views/beambox/Right-Panels/ObjectPanelItem';
import OptionPanelIcons from 'app/icons/option-panel/OptionPanelIcons';
import progressCaller from 'app/actions/progress-caller';
import selector from 'app/svgedit/selector';
import textEdit from 'app/svgedit/text/textedit';
import textPathEdit, { VerticalAlign } from 'app/actions/beambox/textPathEdit';
import i18n from 'helpers/i18n';
import InFillBlock from 'app/views/beambox/Right-Panels/Options-Blocks/InFillBlock';
import Select from 'app/widgets/AntdSelect';
import StartOffsetBlock from 'app/views/beambox/Right-Panels/Options-Blocks/TextOptions/StartOffsetBlock';
import VerticalAlignBlock from 'app/views/beambox/Right-Panels/Options-Blocks/TextOptions/VerticalAlignBlock';
import UnitInput from 'app/widgets/Unit-Input-v2';
import { getCurrentUser } from 'helpers/api/flux-id';
import { getSVGAsync } from 'helpers/svg-editor-helper';
import { iconButtonTheme, selectTheme } from 'app/constants/antd-config';
import { isMobile } from 'helpers/system-helper';
import styles from './TextOptions.module.scss';
let svgCanvas;
getSVGAsync((globalSVG) => {
    svgCanvas = globalSVG.Canvas;
});
const eventEmitter = eventEmitterFactory.createEventEmitter('font');
const LANG = i18n.lang.beambox.right_panel.object_panel.option_panel;
const isLocalFont = (font) => 'path' in font;
const TextOptions = ({ elem, textElement, isTextPath, updateObjectPanel, updateDimensionValues, showColorPanel, }) => {
    const [availableFontFamilies, setAvailableFontFamilies] = useState([]);
    const [state, setState] = useState({
        id: '',
        fontFamily: '',
        fontStyle: '',
        fontSize: 200,
        letterSpacing: 0,
        lineSpacing: 1,
        isVerti: false,
        startOffset: 0,
        verticalAlign: VerticalAlign.MIDDLE,
    });
    const { fontFamily } = state;
    const [styleOptions, setStyleOptions] = useState([]);
    const getFontFamilies = () => __awaiter(void 0, void 0, void 0, function* () {
        const families = FontFuncs.requestAvailableFontFamilies();
        setAvailableFontFamilies(families);
    });
    useEffect(() => {
        eventEmitter.on('GET_MONOTYPE_FONTS', getFontFamilies);
        return () => {
            eventEmitter.removeListener('GET_MONOTYPE_FONTS');
        };
    }, []);
    useEffect(() => {
        const getStateFromElem = () => __awaiter(void 0, void 0, void 0, function* () {
            const elemId = textElement.getAttribute('id');
            if (elemId === state.id)
                return;
            const postscriptName = textEdit.getFontPostscriptName(textElement);
            let font;
            if (postscriptName) {
                font = FontFuncs.getFontOfPostscriptName(postscriptName);
                if (!textElement.getAttribute('font-style')) {
                    textElement.setAttribute('font-style', font.italic ? 'italic' : 'normal');
                }
                if (!textElement.getAttribute('font-weight')) {
                    textElement.setAttribute('font-weight', font.weight ? font.weight : 'normal');
                }
            }
            else {
                const family = textEdit.getFontFamilyData(textElement);
                const weight = textEdit.getFontWeight(textElement);
                const italic = textEdit.getItalic(textElement);
                font = FontFuncs.requestFontByFamilyAndStyle({ family, weight, italic });
            }
            // eslint-disable-next-line no-console
            console.log(font);
            const sanitizedDefaultFontFamily = (() => {
                // use these font if postscriptName cannot find in user PC
                const fontFamilyFallback = [
                    'PingFang TC',
                    'Arial',
                    'Times New Roman',
                    'Ubuntu',
                    'Noto Sans',
                ];
                const sanitizedFontFamily = [font.family, ...fontFamilyFallback].find((f) => availableFontFamilies.includes(f));
                return sanitizedFontFamily;
            })();
            if (sanitizedDefaultFontFamily !== font.family) {
                // eslint-disable-next-line no-console
                console.log(`unsupported font ${font.family}, fallback to ${sanitizedDefaultFontFamily}`);
                textEdit.setFontFamily(sanitizedDefaultFontFamily, true);
                const newFont = FontFuncs.requestFontsOfTheFontFamily(sanitizedDefaultFontFamily)[0];
                textEdit.setFontPostscriptName(newFont.postscriptName, true);
            }
            updateDimensionValues({ fontStyle: font.style });
            let startOffset;
            let verticalAlign;
            if (textElement.getAttribute('data-textpath')) {
                const textPath = textElement.querySelector('textPath');
                if (textPath) {
                    // Use parseInt parse X% to number X
                    startOffset = parseInt(textPath.getAttribute('startOffset'), 10);
                    const alignmentBaseline = textPath.getAttribute('alignment-baseline');
                    if (alignmentBaseline === 'middle')
                        verticalAlign = VerticalAlign.MIDDLE;
                    else if (alignmentBaseline === 'top')
                        verticalAlign = VerticalAlign.TOP;
                    else
                        verticalAlign = VerticalAlign.BOTTOM;
                }
            }
            setState({
                id: elemId,
                fontFamily: sanitizedDefaultFontFamily,
                fontStyle: font.style,
                fontSize: Number(textElement.getAttribute('font-size')),
                letterSpacing: textEdit.getLetterSpacing(textElement),
                lineSpacing: parseFloat(textElement.getAttribute('data-line-spacing') || '1'),
                isVerti: textElement.getAttribute('data-verti') === 'true',
                startOffset,
                verticalAlign,
            });
        });
        if (availableFontFamilies.length > 0)
            getStateFromElem();
        else
            getFontFamilies();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [textElement, availableFontFamilies]);
    useEffect(() => {
        const getStyleOptions = (family) => {
            const fontStyles = FontFuncs.requestFontsOfTheFontFamily(family).map((f) => f.style);
            const options = fontStyles.map((option) => ({ value: option, label: option }));
            setStyleOptions(options);
        };
        getStyleOptions(fontFamily);
    }, [fontFamily]);
    const waitForWebFont = (fontLoadedPromise) => __awaiter(void 0, void 0, void 0, function* () {
        yield progressCaller.openNonstopProgress({
            id: 'load-font',
            caption: i18n.lang.beambox.right_panel.object_panel.actions_panel.fetching_web_font,
        });
        yield document.fonts.ready;
        if (fontLoadedPromise) {
            yield fontLoadedPromise;
        }
        selector.getSelectorManager().resizeSelectors([elem]);
        progressCaller.popById('load-font');
    });
    const handleFontFamilyChange = (newFamily) => __awaiter(void 0, void 0, void 0, function* () {
        let family = newFamily;
        if (typeof newFamily === 'object') {
            family = newFamily.value;
        }
        const newFont = FontFuncs.requestFontsOfTheFontFamily(family)[0];
        const { success, fontLoadedPromise } = yield fontHelper.applyMonotypeStyle(newFont, getCurrentUser());
        if (!success)
            return;
        const batchCmd = new history.BatchCommand('Change Font family');
        let cmd = textEdit.setFontPostscriptName(newFont.postscriptName, true, [textElement]);
        batchCmd.addSubCommand(cmd);
        cmd = textEdit.setItalic(newFont.italic, true, textElement);
        batchCmd.addSubCommand(cmd);
        cmd = textEdit.setFontWeight(newFont.weight, true, textElement);
        batchCmd.addSubCommand(cmd);
        if (fontHelper.usePostscriptAsFamily(newFont)) {
            cmd = textEdit.setFontFamily(newFont.postscriptName, true, [textElement]);
            batchCmd.addSubCommand(cmd);
            cmd = textEdit.setFontFamilyData(family, true, [textElement]);
            batchCmd.addSubCommand(cmd);
        }
        else {
            cmd = textEdit.setFontFamily(family, true, [textElement]);
            batchCmd.addSubCommand(cmd);
        }
        svgCanvas.undoMgr.addCommandToHistory(batchCmd);
        if (!isLocalFont(newFont)) {
            yield waitForWebFont(fontLoadedPromise);
        }
        const newStyle = newFont.style;
        updateDimensionValues({ fontStyle: newStyle });
        setState(Object.assign(Object.assign({}, state), { fontFamily: family, fontStyle: newStyle }));
        updateObjectPanel();
    });
    const renderFontFamilyBlock = () => {
        const renderOption = (option) => {
            const src = fontHelper.getWebFontPreviewUrl(option.value);
            if (src) {
                return (React.createElement("div", { className: styles['family-option'] },
                    React.createElement("div", { className: styles['img-container'] },
                        React.createElement("img", { src: src, alt: option.label, draggable: "false" })),
                    src.includes('monotype') && React.createElement(FluxIcons.FluxPlus, null)));
            }
            return React.createElement("div", { style: { fontFamily: `'${option.value}'`, maxHeight: 24 } }, option.label);
        };
        const options = availableFontFamilies.map((option) => {
            const fontName = FontFuncs.fontNameMap.get(option);
            const label = renderOption({
                value: option,
                label: typeof fontName === 'string' ? fontName : option,
            });
            return { value: option, label };
        });
        if (isMobile()) {
            return (React.createElement(ObjectPanelItem.Select, { id: "font_family", selected: { value: fontFamily, label: fontFamily }, options: options, onChange: handleFontFamilyChange, label: LANG.font_family }));
        }
        const isOnlyOneOption = options.length === 1;
        return (React.createElement(Select, { className: styles['font-family'], popupClassName: styles['font-family-dropdown'], title: LANG.font_family, value: { value: fontFamily }, options: options, onChange: (value) => handleFontFamilyChange(value), onKeyDown: (e) => e.stopPropagation(), dropdownMatchSelectWidth: false, disabled: isOnlyOneOption, filterOption: (input, option) => {
                if (option === null || option === void 0 ? void 0 : option.value) {
                    const searchKey = input.toLowerCase();
                    if (option.value.toLowerCase().includes(searchKey))
                        return true;
                    const fontName = FontFuncs.fontNameMap.get(option.value) || '';
                    if (fontName.toLowerCase().includes(searchKey))
                        return true;
                }
                return false;
            }, placement: "bottomRight", showSearch: true }));
    };
    const handleFontStyleChange = (val) => __awaiter(void 0, void 0, void 0, function* () {
        const font = FontFuncs.requestFontByFamilyAndStyle({
            family: fontFamily,
            style: val,
        });
        const { success, fontLoadedPromise } = yield fontHelper.applyMonotypeStyle(font, getCurrentUser());
        if (!success)
            return;
        const batchCmd = new history.BatchCommand('Change Font Style');
        let cmd = textEdit.setFontPostscriptName(font.postscriptName, true, [textElement]);
        batchCmd.addSubCommand(cmd);
        if (fontHelper.usePostscriptAsFamily(font)) {
            cmd = textEdit.setFontFamily(font.postscriptName, true, [textElement]);
            batchCmd.addSubCommand(cmd);
        }
        cmd = textEdit.setItalic(font.italic, true, textElement);
        batchCmd.addSubCommand(cmd);
        cmd = textEdit.setFontWeight(font.weight, true, textElement);
        batchCmd.addSubCommand(cmd);
        svgCanvas.undoMgr.addCommandToHistory(batchCmd);
        if (!isLocalFont(font)) {
            yield waitForWebFont(fontLoadedPromise);
        }
        updateDimensionValues({ fontStyle: val });
        setState(Object.assign(Object.assign({}, state), { fontStyle: val }));
        updateObjectPanel();
    });
    const renderFontStyleBlock = () => {
        const { fontStyle } = state;
        if (isMobile()) {
            return (React.createElement(ObjectPanelItem.Select, { id: "font_style", selected: { value: fontStyle, label: fontStyle }, options: styleOptions, onChange: handleFontStyleChange, label: LANG.font_style }));
        }
        const isOnlyOneOption = styleOptions.length === 1;
        return (React.createElement(Select, { className: styles['font-style'], title: LANG.font_style, value: fontStyle, options: styleOptions, onChange: (value) => handleFontStyleChange(value), onKeyDown: (e) => e.stopPropagation(), dropdownMatchSelectWidth: false, disabled: isOnlyOneOption }));
    };
    const handleFontSizeChange = (val) => {
        textEdit.setFontSize(val, [textElement]);
        setState(Object.assign(Object.assign({}, state), { fontSize: val }));
    };
    const renderFontSizeBlock = () => {
        const { fontSize } = state;
        return isMobile() ? (React.createElement(ObjectPanelItem.Number, { id: "font_size", label: LANG.font_size, value: fontSize, min: 1, updateValue: handleFontSizeChange, unit: "px", decimal: 0 })) : (React.createElement("div", { className: styles['font-size'], title: LANG.font_size },
            React.createElement(UnitInput, { id: "font_size", min: 1, unit: "px", decimal: 0, className: { 'option-input': true }, defaultValue: fontSize, getValue: (val) => handleFontSizeChange(val) })));
    };
    const handleLetterSpacingChange = (val) => {
        textEdit.setLetterSpacing(val, textElement);
        setState(Object.assign(Object.assign({}, state), { letterSpacing: val }));
    };
    const renderLetterSpacingBlock = () => {
        const { letterSpacing } = state;
        return isMobile() ? (React.createElement(ObjectPanelItem.Number, { id: "letter_spacing", label: LANG.letter_spacing, value: letterSpacing, updateValue: handleLetterSpacingChange, unit: "em" })) : (React.createElement("div", { className: styles.spacing },
            React.createElement("div", { className: styles.label, title: LANG.letter_spacing },
                React.createElement(OptionPanelIcons.LetterSpacing, null)),
            React.createElement(UnitInput, { id: "letter_spacing", unit: "", step: 0.05, className: { 'option-input': true }, defaultValue: letterSpacing, getValue: (val) => handleLetterSpacingChange(val) })));
    };
    const handleLineSpacingChange = (val) => {
        textEdit.setLineSpacing(val);
        setState(Object.assign(Object.assign({}, state), { lineSpacing: val }));
    };
    const renderLineSpacingBlock = () => {
        const { lineSpacing } = state;
        return isMobile() ? (React.createElement(ObjectPanelItem.Number, { id: "line_spacing", label: LANG.line_spacing, value: lineSpacing, min: 0.8, updateValue: handleLineSpacingChange, unit: "", decimal: 1 })) : (React.createElement("div", { className: styles.spacing },
            React.createElement("div", { className: styles.label, title: LANG.line_spacing },
                React.createElement(OptionPanelIcons.LineSpacing, null)),
            React.createElement(UnitInput, { id: "line_spacing", unit: "", min: 0.8, step: 0.1, decimal: 1, className: { 'option-input': true }, defaultValue: lineSpacing, getValue: (val) => handleLineSpacingChange(val) })));
    };
    const handleVerticalTextClick = () => {
        const { isVerti } = state;
        textEdit.setIsVertical(!isVerti);
        setState(Object.assign(Object.assign({}, state), { isVerti: !isVerti }));
    };
    const renderVerticalTextSwitch = () => {
        const { isVerti } = state;
        return isMobile() ? (React.createElement(ObjectPanelItem.Item, { id: "vertical-text", content: React.createElement(Switch, { checked: isVerti }), label: LANG.vertical_text, onClick: handleVerticalTextClick })) : (React.createElement(ConfigProvider, { theme: iconButtonTheme },
            React.createElement(Button, { id: "vertical-text", type: "text", className: classNames(styles['vertical-text'], { [styles.active]: isVerti }), title: LANG.vertical_text, icon: React.createElement(OptionPanelIcons.VerticalText, null), onClick: handleVerticalTextClick })));
    };
    const handleStartOffsetChange = (val) => {
        textPathEdit.setStartOffset(val, textElement);
        setState(Object.assign(Object.assign({}, state), { startOffset: val }));
    };
    const handleVerticalAlignChange = (val) => {
        textPathEdit.setVerticalAlign(textElement, val);
        setState(Object.assign(Object.assign({}, state), { verticalAlign: val }));
    };
    const renderMultiLineTextOptions = () => (React.createElement(React.Fragment, null,
        renderLineSpacingBlock(),
        renderLetterSpacingBlock(),
        renderVerticalTextSwitch(),
        !showColorPanel && !isMobile() && React.createElement(InFillBlock, { elem: elem })));
    const renderTextPathOptions = () => {
        const path = elem.querySelector('path');
        const { startOffset, verticalAlign } = state;
        return (React.createElement(React.Fragment, null,
            renderLetterSpacingBlock(),
            React.createElement(StartOffsetBlock, { value: startOffset, onValueChange: handleStartOffsetChange }),
            React.createElement(VerticalAlignBlock, { value: verticalAlign, onValueChange: handleVerticalAlignChange }),
            React.createElement(InFillBlock, { elem: textElement, label: LANG.text_infill }),
            React.createElement(InFillBlock, { elem: path, label: LANG.path_infill, id: "path_infill" })));
    };
    return isMobile() ? (React.createElement(React.Fragment, null,
        renderFontFamilyBlock(),
        renderFontStyleBlock(),
        renderFontSizeBlock(),
        isTextPath ? renderTextPathOptions() : renderMultiLineTextOptions())) : (React.createElement(ConfigProvider, { theme: selectTheme },
        React.createElement("div", { className: styles.panel },
            renderFontFamilyBlock(),
            React.createElement("div", { className: styles.row },
                renderFontSizeBlock(),
                renderFontStyleBlock()),
            isTextPath ? (renderTextPathOptions()) : (React.createElement("div", { className: styles.row }, renderMultiLineTextOptions())))));
};
export default TextOptions;
