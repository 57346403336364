export default {
    INFO: 'INFO',
    WARNING: 'WARNING',
    WARNING_WITH_CHECKBOX: 'WARNING_WITH_CHECKBOX',
    ERROR: 'ERROR',
    YES_NO: 'YES_NO',
    CONFIRM_CANCEL: 'CONFIRM_CANCEL',
    RETRY_CANCEL: 'RETRY_CANCEL',
    RETRY_ABORT_CANCEL: 'RETRY_ABORT_CANCEL',
    CUSTOM: 'CUSTOM',
    CUSTOM_GROUP: 'CUSTOM_GROUP',
    CUSTOM_CANCEL: 'CUSTOM_CANCEL',
    SHOW_INFO: 'SHOW_INFO',
    SHOW_WARNING: 'SHOW_WARNING',
    SHOW_ERROR: 'SHOW_ERROR',
    SHOW_POPUP_INFO: 'SHOW_POPUP_INFO',
    SHOW_POPUP_WARNING: 'SHOW_POPUP_WARNING',
    SHOW_POPUP_CHECKBOX_WARNING: 'SHOW_POPUP_CHECKBOX_WARNING',
    SHOW_POPUP_ERROR: 'SHOW_POPUP_ERROR',
    SHOW_POPUP_INSTRUCTION: 'SHOW_POPUP_INSTRUCTION',
    SHOW_POPUP_RETRY: 'SHOW_POPUP_RETRY',
    SHOW_POPUP_RETRY_ABORT: 'SHOW_POPUP_RETRY_ABORT',
    SHOW_POPUP_YES_NO: 'SHOW_POPUP_YES_NO',
    SHOW_POPUP_CUSTOM: 'SHOW_POPUP_CUSTOM',
    SHOW_POPUP_CUSTOM_GROUP: 'SHOW_POPUP_CUSTOM_GROUP',
    SHOW_POPUP_CUSTOM_CANCEL: 'SHOW_POPUP_CUSTOM_CANCEL',
    SHOW_POPUP_QUESTION: 'SHOW_POPUP_QUESTION',
    SHOW_POPUP_UPDATE: 'SHOW_POPUP_UPDATE',
    SHOW_POPUP_DEVICE_BUSY: 'SHOW_POPUP_DEVICE_BUSY',
    SHOW_HEAD_TEMPERATURE: 'SHOW_HEAD_TEMPERATURE',
    NOTIFY_RETRY: 'NOTIFY_RETRY',
    NOTIFY_ABORT: 'NOTIFY_ABORT',
    NOTIFY_YES: 'NOTIFY_YES',
    NOTIFY_CANCEL: 'NOTIFY_CANCEL',
    NOTIFY_CUSTOM: 'NOTIFY_CUSTOM',
    NOTIFY_CUSTOM_GROUP: 'NOTIFY_CUSTOM_GROUP',
    NOTIFY_ANSWER: 'NOTIFY_ANSWER',
    QUESTION: 'QUESTION',
    CLOSE_NOTIFICATION: 'CLOSE_NOTIFICATION',
    CLOSE_POPUP: 'CLOSE_POPUP',
    FINISH: 'FINISH',
    SHOW_POPUP_CHANGE_FILAMENT: 'SHOW_POPUP_CHANGE_FILAMENT',
};
export const HELP_CENTER_URLS = {
    801: 'https://support.flux3dp.com/hc/en-us/articles/360001809676',
    802: 'https://support.flux3dp.com/hc/en-us/articles/360001809776',
    803: 'https://support.flux3dp.com/hc/en-us/articles/360001791895',
    804: 'https://support.flux3dp.com/hc/en-us/articles/360001792035',
    805: 'https://support.flux3dp.com/hc/en-us/articles/360001811076',
    806: 'https://support.flux3dp.com/hc/en-us/articles/360001811236',
    807: 'https://support.flux3dp.com/hc/en-us/articles/360001792895',
    808: 'https://support.flux3dp.com/hc/en-us/articles/360001811276',
    809: 'https://support.flux3dp.com/hc/en-us/articles/360001792915',
    810: 'https://support.flux3dp.com/hc/en-us/articles/360001811316',
    811: 'https://support.flux3dp.com/hc/en-us/articles/360001792955',
    812: 'https://support.flux3dp.com/hc/en-us/articles/360001811336',
    813: 'https://support.flux3dp.com/hc/en-us/articles/360001811356',
    814: 'https://support.flux3dp.com/hc/en-us/articles/360001811376',
    815: 'https://support.flux3dp.com/hc/en-us/articles/360001811416',
    816: 'https://support.flux3dp.com/hc/en-us/articles/360001811456',
    817: 'https://support.flux3dp.com/hc/en-us/articles/360001811476',
    818: 'https://support.flux3dp.com/hc/en-us/articles/360001793055',
    819: 'https://support.flux3dp.com/hc/en-us/articles/360001793755',
    820: 'https://support.flux3dp.com/hc/en-us/articles/360001812496',
    821: 'https://support.flux3dp.com/hc/en-us/articles/360001793775',
    822: 'https://support.flux3dp.com/hc/en-us/articles/360001793795',
    823: 'https://support.flux3dp.com/hc/en-us/articles/360001812656',
    824: 'https://support.flux3dp.com/hc/en-us/articles/360001794515',
    825: 'https://support.flux3dp.com/hc/en-us/articles/360001814476',
    826: 'https://support.flux3dp.com/hc/en-us/articles/360001795275',
    827: 'https://support.flux3dp.com/hc/en-us/articles/360001814656',
    828: 'https://support.flux3dp.com/hc/en-us/articles/360001814676',
    829: 'https://support.flux3dp.com/hc/en-us/articles/360001795295',
    830: 'https://support.flux3dp.com/hc/en-us/articles/360001795415',
    840: 'https://support.flux3dp.com/hc/en-us/articles/4402728633487',
    841: 'https://support.flux3dp.com/hc/en-us/articles/4402728736271',
    842: 'https://support.flux3dp.com/hc/en-us/articles/4402728833807',
    843: 'https://support.flux3dp.com/hc/en-us/articles/4402854473615',
    844: 'https://support.flux3dp.com/hc/en-us/articles/4402755805071',
    845: 'https://support.flux3dp.com/hc/en-us/articles/4402756056079',
    846: 'https://support.flux3dp.com/hc/en-us/articles/4402861605775',
    847: 'https://support.flux3dp.com/hc/en-us/articles/4402854250127',
};
