var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import alertCaller from 'app/actions/alert-caller';
import deviceMaster from 'helpers/device-master';
import i18n from 'helpers/i18n';
import MessageCaller, { MessageLevel } from 'app/actions/message-caller';
import PreviewModeBackgroundDrawer from 'app/actions/beambox/preview-mode-background-drawer';
import progressCaller from 'app/actions/progress-caller';
import BasePreviewManager from './BasePreviewManager';
import FisheyePreviewManagerV1 from './FisheyePreviewManagerV1';
import FisheyePreviewManagerV2 from './FisheyePreviewManagerV2';
// TODO: Add tests
class AdorPreviewManager extends BasePreviewManager {
    constructor(device) {
        super(device);
        this.isFullScreen = true;
        this.setup = (args) => __awaiter(this, void 0, void 0, function* () {
            const { lang } = i18n;
            const { progressId } = args || {};
            if (progressId)
                this.progressId = progressId;
            try {
                progressCaller.openNonstopProgress({
                    id: this.progressId,
                    message: lang.message.connectingCamera,
                });
                yield deviceMaster.connectCamera();
                let params;
                try {
                    params = yield deviceMaster.fetchFisheyeParams();
                }
                catch (err) {
                    console.log('Fail to fetchFisheyeParams', err === null || err === void 0 ? void 0 : err.message);
                    throw new Error('Unable to get fisheye parameters, please make sure you have calibrated the camera');
                }
                if (!('v' in params)) {
                    this.fisheyeManager = new FisheyePreviewManagerV1(this.device, params);
                }
                else if (params.v === 2) {
                    this.fisheyeManager = new FisheyePreviewManagerV2(this.device, params);
                }
                const res = yield this.fisheyeManager.setupFisheyePreview({ progressId: this.progressId });
                return res;
            }
            catch (error) {
                console.error(error);
                if (error.message && error.message.startsWith('Camera WS')) {
                    alertCaller.popUpError({
                        message: `${lang.topbar.alerts.fail_to_connect_with_camera}<br/>${error.message || ''}`,
                    });
                }
                else {
                    alertCaller.popUpError({
                        message: `${lang.topbar.alerts.fail_to_start_preview}<br/>${error.message || ''}`,
                    });
                }
                return false;
            }
            finally {
                if (deviceMaster.currentControlMode === 'raw') {
                    yield deviceMaster.rawLooseMotor();
                    progressCaller.update(this.progressId, { message: lang.message.endingRawMode });
                    yield deviceMaster.endRawMode();
                }
                progressCaller.popById(this.progressId);
            }
        });
        this.end = () => __awaiter(this, void 0, void 0, function* () { });
        this.reloadLevelingOffset = () => __awaiter(this, void 0, void 0, function* () {
            var _a;
            yield ((_a = this.fisheyeManager) === null || _a === void 0 ? void 0 : _a.reloadLevelingOffset());
        });
        this.resetObjectHeight = () => __awaiter(this, void 0, void 0, function* () { var _b, _c; return (_c = (yield ((_b = this.fisheyeManager) === null || _b === void 0 ? void 0 : _b.resetObjectHeight()))) !== null && _c !== void 0 ? _c : false; });
        this.preview = () => __awaiter(this, void 0, void 0, function* () {
            try {
                MessageCaller.openMessage({
                    key: 'full-area-preview',
                    content: i18n.lang.topbar.preview,
                    level: MessageLevel.LOADING,
                    duration: 20,
                });
                const imgUrl = yield this.getPhotoFromMachine();
                yield new Promise((resolve) => {
                    PreviewModeBackgroundDrawer.drawFullWorkarea(imgUrl, resolve);
                });
                MessageCaller.openMessage({
                    key: 'full-area-preview',
                    level: MessageLevel.SUCCESS,
                    content: 'Successfully previewed',
                    duration: 3,
                });
                return true;
            }
            catch (error) {
                MessageCaller.closeMessage('full-area-preview');
                throw error;
            }
        });
        this.previewRegion = () => __awaiter(this, void 0, void 0, function* () {
            const res = yield this.preview();
            return res;
        });
        this.previewFullWorkarea = () => __awaiter(this, void 0, void 0, function* () {
            const res = yield this.preview();
            return res;
        });
        this.progressId = 'ador-preview-manager';
    }
}
export default AdorPreviewManager;
