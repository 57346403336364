var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import beamboxPreference from 'app/actions/beambox/beambox-preference';
import deviceMaster from 'helpers/device-master';
import i18n from 'helpers/i18n';
import progressCaller from 'app/actions/progress-caller';
const PROGRESS_ID = 'raw-and-home';
const rawAndHome = (progressId) => __awaiter(void 0, void 0, void 0, function* () {
    const { lang } = i18n;
    if (!progressId)
        progressCaller.openNonstopProgress({ id: PROGRESS_ID });
    progressCaller.update(progressId || PROGRESS_ID, { message: lang.message.enteringRawMode });
    yield deviceMaster.enterRawMode();
    progressCaller.update('preview-mode-controller', { message: lang.message.exitingRotaryMode });
    yield deviceMaster.rawSetRotary(false);
    const rotaryMode = beamboxPreference.read('rotary_mode');
    progressCaller.update('preview-mode-controller', { message: lang.message.homing });
    if (rotaryMode)
        yield deviceMaster.rawHomeZ();
    yield deviceMaster.rawHome();
    if (rotaryMode)
        yield deviceMaster.rawMoveZRelToLastHome(0);
    yield deviceMaster.rawLooseMotor();
    if (!progressId)
        progressCaller.popById(PROGRESS_ID);
});
export default rawAndHome;
