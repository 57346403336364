import classNames from 'classnames';
import React, { useMemo } from 'react';
import i18n from 'helpers/i18n';
import isWeb from 'helpers/is-web';
function SidePanel({ size, estTime, lightTime, rapidTime, cutDist, rapidDist, currentPosition, handleStartHere, isStartHereEnabled, togglePathPreview, }) {
    const LANG = i18n.lang.beambox.path_preview;
    const renderDataBlock = (label, value) => (React.createElement("div", { className: "data-block" },
        React.createElement("div", { className: "item" }, label),
        React.createElement("div", { className: "value" }, value)));
    const sideClass = useMemo(() => classNames({
        short: window.os === 'Windows' && !isWeb(),
        wide: window.os !== 'MacOS',
    }), []);
    return (React.createElement("div", { id: "path-preview-side-panel", className: sideClass },
        React.createElement("div", { className: "title" }, LANG.preview_info),
        React.createElement("div", { className: "datas" },
            renderDataBlock(LANG.size, size),
            renderDataBlock(LANG.estimated_time, estTime),
            renderDataBlock(LANG.cut_time, lightTime),
            renderDataBlock(LANG.rapid_time, rapidTime),
            renderDataBlock(LANG.cut_distance, cutDist),
            renderDataBlock(LANG.rapid_distance, rapidDist),
            renderDataBlock(LANG.current_position, currentPosition)),
        React.createElement("div", { className: "remark" }, LANG.remark),
        React.createElement("div", { className: "buttons" },
            React.createElement("div", { className: classNames('btn btn-default primary', { disabled: !isStartHereEnabled }), onClick: isStartHereEnabled ? handleStartHere : null }, LANG.start_here),
            React.createElement("div", { className: "btn btn-default", onClick: togglePathPreview }, LANG.end_preview))));
}
export default SidePanel;
