var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import findDefs from 'app/svgedit/utils/findDef';
import svgStringToCanvas from 'helpers/image/svgStringToCanvas';
import symbolMaker from 'helpers/symbol-maker';
import workareaManager from 'app/svgedit/workarea';
// TODO: Add unit tests
const getCanvasImage = (x, y, width, height) => __awaiter(void 0, void 0, void 0, function* () {
    const svgContent = document.getElementById('svgcontent');
    const bbox = { x, y, width, height };
    if (bbox.width <= 0 || bbox.height <= 0)
        return null;
    bbox.width = Math.min(bbox.width, workareaManager.width);
    bbox.height = Math.min(bbox.height, workareaManager.height);
    const svgDefs = findDefs();
    const clonedSvgContent = svgContent.cloneNode(true);
    const useElements = clonedSvgContent.querySelectorAll('use');
    useElements.forEach((useElement) => symbolMaker.switchImageSymbol(useElement, false));
    const svgString = `
    <svg
      width="${bbox.width}"
      height="${bbox.height}"
      viewBox="${bbox.x} ${bbox.y} ${bbox.width} ${bbox.height}"
      xmlns:svg="http://www.w3.org/2000/svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      ${svgDefs.outerHTML}
      ${clonedSvgContent.innerHTML}
    </svg>`;
    const canvas = yield svgStringToCanvas(svgString, bbox.width, bbox.height);
    const imageBitmap = yield createImageBitmap(canvas);
    return imageBitmap;
});
export default getCanvasImage;
