import imageData from 'helpers/image-data';
const calculateBase64 = (blobUrl, isShading, threshold, isFullColor = false) => new Promise((resolve) => {
    imageData(blobUrl, {
        grayscale: isFullColor ? undefined : {
            is_rgba: true,
            is_shading: isShading,
            threshold,
            is_svg: false,
        },
        isFullResolution: true,
        onComplete: (result) => {
            resolve(result.pngBase64);
        },
    });
});
export default calculateBase64;
