import React, { useState } from 'react';
import { Checkbox, Input, Modal, QRCode, Radio } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import browser from 'implementations/browser';
import FnWrapper from 'app/actions/beambox/svgeditor-function-wrapper';
import useI18n from 'helpers/useI18n';
import styles from './QRCodeGenerator.module.scss';
const QRCodeGenerator = ({ onClose }) => {
    const LANG = useI18n();
    const lang = LANG.qr_code_generator;
    const [text, setText] = useState('');
    const [errorLevel, setErrorLevel] = useState('L');
    const [isInvert, setIsInvert] = useState(false);
    const handleOk = () => {
        const canvas = document.querySelector(`.${styles.qrcode} canvas`);
        const url = canvas.toDataURL('image/png', 1);
        FnWrapper.insertImage(url, { x: 0, y: 0, width: 500, height: 500 }, 127, {
            useCurrentLayer: true,
            ratioFixed: true,
        });
        onClose();
    };
    return (React.createElement(Modal, { open: true, centered: true, title: lang.title, onCancel: onClose, onOk: handleOk, okButtonProps: { disabled: !text }, cancelText: LANG.alert.cancel, okText: LANG.alert.confirm },
        React.createElement(Input.TextArea, { className: styles.input, rows: 5, maxLength: 200, value: text, onKeyDown: (e) => e.stopPropagation(), onChange: (e) => setText(e.target.value), placeholder: lang.placeholder, showCount: true }),
        React.createElement("div", { className: styles.content },
            React.createElement("div", { className: styles['qrcode-container'] }, text ? (React.createElement(QRCode, { className: styles.qrcode, value: text, size: 1000, errorLevel: errorLevel, color: isInvert ? 'white' : 'black', bgColor: isInvert ? 'black' : 'transparent' })) : (React.createElement("div", { className: styles.placeholder }, lang.preview))),
            React.createElement("div", { className: styles.settings },
                React.createElement("div", { className: styles.label },
                    lang.error_tolerance,
                    ' ',
                    React.createElement(InfoCircleOutlined, { onClick: () => browser.open(lang.error_tolerance_link) })),
                React.createElement(Radio.Group, { value: errorLevel, onChange: (e) => setErrorLevel(e.target.value), options: [
                        { label: '7%', value: 'L' },
                        { label: '15%', value: 'M' },
                        { label: '20%', value: 'Q' },
                        { label: '30%', value: 'H' },
                    ] }),
                React.createElement(Checkbox, { className: styles.checkbox, checked: isInvert, onChange: () => setIsInvert(!isInvert) }, lang.invert)))));
};
export default QRCodeGenerator;
