import classNames from 'classnames';
import React, { useMemo } from 'react';
import browser from 'implementations/browser';
import useI18n from 'helpers/useI18n';
import styles from './ConnectEthernet.module.scss';
const ConnectEthernet = () => {
    const lang = useI18n().initialize;
    const { model } = useMemo(() => {
        const queryString = window.location.hash.split('?')[1] || '';
        const urlParams = new URLSearchParams(queryString);
        return {
            model: urlParams.get('model'),
        };
    }, []);
    const guideHref = window.os === 'MacOS'
        ? lang.connect_ethernet.tutorial2_a_href_mac
        : lang.connect_ethernet.tutorial2_a_href_win;
    const handleNext = () => {
        const urlParams = new URLSearchParams({ model, wired: '1' });
        const queryString = urlParams.toString();
        window.location.hash = `#initialize/connect/connect-machine-ip?${queryString}`;
    };
    return (React.createElement("div", { className: styles.container },
        React.createElement("div", { className: styles['top-bar'] }),
        React.createElement("div", { className: styles.btns },
            React.createElement("div", { className: styles.btn, onClick: () => window.history.back() }, lang.back),
            React.createElement("div", { className: classNames(styles.btn, styles.primary), onClick: handleNext }, lang.next)),
        React.createElement("div", { className: styles.main },
            React.createElement("div", { className: styles.image },
                React.createElement("div", { className: classNames(styles.circle, styles.c1) }),
                React.createElement("img", { src: "img/init-panel/icon-dual-cable.svg", draggable: "false" }),
                React.createElement("div", { className: classNames(styles.circle, styles.c2) })),
            React.createElement("div", { className: styles.text },
                React.createElement("div", { className: styles.title }, lang.connect_ethernet.title),
                React.createElement("div", { className: classNames(styles.contents, styles.tutorial) },
                    React.createElement("div", null, lang.connect_ethernet.tutorial1),
                    React.createElement("div", null,
                        lang.connect_ethernet.tutorial2_1,
                        React.createElement("span", { className: styles.link, onClick: () => browser.open(guideHref) }, lang.connect_ethernet.tutorial2_a_text),
                        lang.connect_ethernet.tutorial2_2),
                    React.createElement("div", null, lang.connect_ethernet.tutorial3))))));
};
export default ConnectEthernet;
