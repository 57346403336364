import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import dialog from 'app/actions/dialog-caller';
import InitializeIcons from 'app/icons/initialize/InitializeIcons';
import storage from 'implementations/storage';
import useI18n from 'helpers/useI18n';
import windowLocationReload from 'app/actions/windowLocation';
import styles from './SelectMachineModel.module.scss';
const SelectMachineModel = () => {
    const isNewUser = useMemo(() => !storage.get('printer-is-ready'), []);
    const lang = useI18n().initialize;
    const handleBtnClick = useCallback(() => {
        if (isNewUser)
            storage.set('new-user', true);
        storage.set('printer-is-ready', true);
        dialog.showLoadingWindow();
        window.location.hash = '#studio/beambox';
        windowLocationReload();
    }, [isNewUser]);
    const handleNextClick = (model) => {
        window.location.hash = `#initialize/connect/select-connection-type?model=${model}`;
    };
    return (React.createElement("div", { className: styles.container },
        React.createElement("div", { className: styles['top-bar'] }),
        React.createElement("div", { className: styles.btns },
            React.createElement("div", { className: styles.btn, onClick: handleBtnClick }, isNewUser ? lang.skip : lang.back)),
        React.createElement("div", { className: styles.main },
            React.createElement("h1", { className: styles.title }, lang.select_machine_type),
            React.createElement("div", { className: styles.btns },
                React.createElement("div", { className: styles.btn, onClick: () => handleNextClick('ado1') },
                    React.createElement(InitializeIcons.Ador, { className: styles.icon }),
                    React.createElement("div", { className: styles.label }, "Ador")),
                React.createElement("div", { className: styles.btn, onClick: () => handleNextClick('fbm1') },
                    React.createElement(InitializeIcons.Beamo, { className: styles.icon }),
                    React.createElement("div", { className: styles.label }, "beamo")),
                React.createElement("div", { className: styles.btn, onClick: () => handleNextClick('fbb1p') },
                    React.createElement(InitializeIcons.Beambox, { className: styles.icon }),
                    React.createElement("div", { className: classNames(styles.label, styles.bb) }, "Beambox Series")),
                React.createElement("div", { className: styles.btn, onClick: () => handleNextClick('fhexa1') },
                    React.createElement(InitializeIcons.Hexa, { className: styles.icon }),
                    React.createElement("div", { className: styles.label }, "HEXA"))))));
};
export default SelectMachineModel;
