import React, { useState } from 'react';
import { Checkbox, Form, Modal, Rate } from 'antd';
import i18n from 'helpers/i18n';
import RatingHelper from 'helpers/rating-helper';
const LANG = i18n.lang.beambox.rating_panel;
const RatingPanel = ({ onClose, onSubmit }) => {
    const [star, setStar] = useState(0);
    const [isFinished, setIsFinished] = useState(false);
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const handleSubmit = (score) => {
        onSubmit(score);
        setIsFinished(true);
    };
    const handleCancel = () => {
        if (isCheckboxChecked) {
            RatingHelper.setNotShowing();
        }
        onClose();
    };
    return (React.createElement(Modal, { open: true, centered: true, title: `👨‍🚀 ${LANG.title}`, onCancel: handleCancel, onOk: () => (isFinished ? handleCancel() : handleSubmit(star)) }, isFinished ? (React.createElement("strong", null,
        "\uD83D\uDE4F",
        LANG.thank_you)) : (React.createElement("div", { className: "main-content" },
        React.createElement("div", null, LANG.description),
        React.createElement(Rate, { onChange: (val) => setStar(val) }),
        React.createElement(Form, null,
            React.createElement(Form.Item, { label: LANG.dont_show_again },
                React.createElement(Checkbox, { checked: isCheckboxChecked, onChange: (e) => setIsCheckboxChecked(e.target.checked) })))))));
};
export default RatingPanel;
