const lang = {
    global: {
        cancel: 'Cancel',
        back: 'Back',
    },
    general: {
        processing: 'Processing...',
        choose_folder: 'Choose Folder',
    },
    buttons: {
        next: 'Next',
        back: 'Back',
        done: 'Done',
        back_to_beam_studio: 'Back to Beam Studio',
    },
    topbar: {
        untitled: 'Untitled',
        titles: {
            settings: 'Preferences',
        },
        export: 'GO',
        preview: 'PREVIEW',
        preview_title: 'Preview',
        curve_engrave: '3D Curve',
        task_preview: 'Path preview',
        frame_task: 'Running Frame',
        borderless: '(OPEN BOTTOM)',
        tag_names: {
            rect: 'Rectangle',
            ellipse: 'Oval',
            path: 'Path',
            polygon: 'Polygon',
            image: 'Image',
            text: 'Text',
            text_path: 'Text on Path',
            pass_through_object: 'Pass Through Object',
            line: 'Line',
            g: 'Group',
            multi_select: 'Multiple Objects',
            use: 'Imported Object',
            svg: 'SVG Object',
            dxf: 'DXF Object',
            no_selection: 'No Selection',
        },
        alerts: {
            start_preview_timeout: '#803 Timeout has occurred when starting preview mode. Please Restart your machine or Beam Studio. If this error persists, please follow <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">this guide</a>.',
            fail_to_start_preview: '#803 Fail to start preview mode. Please Restart your machine or Beam Studio. If this error persists, please follow <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">this guide</a>.',
            fail_to_connect_with_camera: '#803 Failed to establish connection with machine camera. Please Restart your machine or Beam Studio. If this error persists, please follow <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">this guide</a>.',
            add_content_first: 'Please add objects first',
            headtype_mismatch: 'Incorrect module detected. ',
            headtype_none: 'Module not detected. ',
            headtype_unknown: 'Unknown module detected. ',
            install_correct_headtype: 'Please install 10W/20W diode laser modules properly to enable low laser for running frame.',
            door_opened: 'Please close the door cover to enable low laser for running frame.',
            fail_to_get_door_status: 'Please make sure the door cover is closed to enable low laser for running frame.',
            QcleanScene: 'Do you want to clear the drawing?<br/>This will also erase your undo history!',
            power_too_high: 'POWER TOO HIGH',
            power_too_high_msg: 'Using lower laser power (under 70%) will extends laser tube\'s lifetime.\nEnter "NOTED" to proceed.',
            power_too_high_confirm: 'NOTED',
            pwm_unavailable: 'Depth mode requires firmware version 4.3.4 / 5.3.4 or above. Would you like to update the firmware now?',
            job_origin_unavailable: 'Setting job Origin requires firmware version 4.3.5 / 5.3.5 or above. Would you like to update the firmware now?',
            job_origin_warning: 'You are currently using the “current position” as the starting point. Please ensure the laser head is moved to the correct position to avoid collisions.',
        },
        hint: {
            polygon: 'Press + / - key to increase / decrease sides.',
        },
        menu: {
            preferences: 'Preferences',
            hide: 'Hide Beam Studio',
            hideothers: 'Hide Others',
            service: 'Services',
            quit: 'Quit',
            window: 'Window',
            minimize: 'Minimize',
            close: 'Close Window',
            file: 'File',
            edit: 'Edit',
            help: 'Help',
            open: 'Open',
            samples: 'Examples',
            import_hello_beamo: 'Example of beamo',
            import_hello_beambox: 'Example of beambox',
            import_material_testing_old: 'Material Engraving Test - Classic',
            import_material_testing_simple_cut: 'Material Cutting Test - Simple',
            import_material_testing_cut: 'Material Cutting Test',
            import_material_testing_engrave: 'Material Engraving Test',
            import_material_testing_line: 'Material Line Test',
            import_material_printing_test: 'Material Printing Test',
            import_ador_laser_example: 'Example of Ador Laser',
            import_ador_printing_example_single: 'Example of Ador Printing - Single Color',
            import_ador_printing_example_full: 'Example of Ador Printing - Full Color',
            import_acrylic_focus_probe: 'Acrylic Focus Probe - 3mm',
            export_to: 'Export To...',
            export_flux_task: 'FLUX task',
            export_BVG: 'BVG',
            export_SVG: 'SVG',
            export_PNG: 'PNG',
            export_JPG: 'JPG',
            save_scene: 'Save',
            save_as: 'Save As...',
            save_to_cloud: 'Save to Cloud',
            about_beam_studio: 'About Beam Studio',
            undo: 'Undo',
            redo: 'Redo',
            cut: 'Cut',
            copy: 'Copy',
            paste: 'Paste',
            paste_in_place: 'Paste in Place',
            group: 'Group',
            ungroup: 'Ungroup',
            delete: 'Delete',
            duplicate: 'Duplicate',
            offset: 'Offset',
            scale: 'Scale',
            rotate: 'Rotate',
            reset: 'Reset',
            align_center: 'Align Center',
            photo_edit: 'Image',
            svg_edit: 'SVG',
            path: 'Path',
            decompose_path: 'Decompose',
            object: 'Object',
            layer_setting: 'Layer',
            layer_color_config: 'Color Settings',
            image_sharpen: 'Sharpen',
            image_crop: 'Crop',
            image_invert: 'Invert',
            image_stamp: 'Bevel',
            image_vectorize: 'Trace',
            image_curve: 'Curve',
            align_to_edges: 'Snap To Vertices',
            document_setting: 'Document Settings',
            document_setting_short: 'Document',
            rotary_setup: 'Rotary Setup',
            clear_scene: 'New',
            machines: 'Machines',
            add_new_machine: 'Machine Setup',
            help_center: 'Help Center',
            show_gesture_tutorial: 'Hand Gesture Introduction',
            show_start_tutorial: 'Show Start Tutorial',
            show_ui_intro: 'Show Interface Introduction',
            questionnaire: 'Feedback Questionnaire',
            change_logs: 'Change Logs',
            contact: 'Contact Us',
            tutorial: 'Start Delta Family Printing Tutorial',
            design_market: 'Design Market',
            forum: 'Community Forum',
            software_update: 'Software Update',
            bug_report: 'Bug Report',
            dashboard: 'Dashboard',
            machine_info: 'Machine Info',
            network_testing: 'Test Network Settings',
            commands: 'Commands',
            update_firmware: 'Update Firmware',
            using_beam_studio_api: 'Using Beam Studio API',
            set_as_default: 'Set as Default',
            calibrate_beambox_camera: 'Calibrate Camera',
            calibrate_printer_module: 'Calibrate Printing Module',
            calibrate_ir_module: 'Calibrate Infrared Module',
            calibrate_beambox_camera_borderless: 'Calibrate Camera (Open Bottom)',
            calibrate_diode_module: 'Calibrate Diode Laser Module',
            manage_account: 'Manage My Account',
            sign_in: 'Sign In',
            sign_out: 'Sign Out',
            account: 'Account',
            my_account: 'My Account',
            download_log: 'Download Logs',
            download_log_canceled: 'Log download canceled',
            download_log_error: 'Unknown error occurred, please try it again later',
            keyboard_shortcuts: 'Keyboard shortcuts',
            log: {
                network: 'Network',
                hardware: 'Hardware',
                discover: 'Discover',
                usb: 'USB',
                usblist: 'USB List',
                camera: 'Camera',
                cloud: 'Cloud',
                player: 'Player',
                robot: 'Robot',
            },
            link: {
                help_center: 'https://helpcenter.flux3dp.com/',
                contact_us: 'https://flux3dp.zendesk.com/hc/en-us/requests/new',
                forum: 'https://www.facebook.com/groups/flux.laser/',
                downloads: 'https://flux3dp.com/downloads/',
                beam_studio_api: 'https://github.com/flux3dp/beam-studio/wiki/Beam-Studio-Easy-API',
                design_market: 'https://dmkt.io',
                shortcuts: 'https://support.flux3dp.com/hc/en-us/articles/10003978157455',
            },
            view: 'View',
            zoom_in: 'Zoom In',
            zoom_out: 'Zoom Out',
            fit_to_window: 'Fit to Window Size',
            zoom_with_window: 'Auto Fit to Window Size',
            borderless_mode: 'Borderless Mode',
            show_grids: 'Show Grids',
            show_rulers: 'Show Rulers',
            show_layer_color: 'Use Layer Color',
            anti_aliasing: 'Anti-Aliasing',
            disassemble_use: 'Disassemble',
            about: 'About Beam Studio',
            switch_to_beta: 'Switch To Beta Release',
            switch_to_latest: 'Switch To Stable Release',
            reload_app: 'Reload App',
            recent: 'Open Recent',
            update: 'Check Update',
            dev_tool: 'Debug Tool',
            camera_calibration_data: 'Camera Calibration Data',
            upload_data: 'Upload Data',
            download_data: 'Download Data',
            tools: {
                title: 'Tools',
                material_test_generator: 'Material Test Generator',
                qr_code_generator: 'QR Code Generator',
                boxgen: 'Boxgen',
            },
        },
        select_machine: 'Select a machine',
    },
    support: {
        no_webgl: 'WebGL is not supported. Please use other devices.',
        no_vcredist: 'Please install Visual C++ Redistributable 2015<br/>That can be downloaded on flux3dp.com',
    },
    generic_error: {
        UNKNOWN_ERROR: '[UE] Unknown error occurred. Please restart Beam Studio and the machine.',
        OPERATION_ERROR: '[OE] A status conflict occurred, please retry the action.',
        SUBSYSTEM_ERROR: '[SE] Error when machine firmware executing the task. Please restart the machine.',
        UNKNOWN_COMMAND: '[UC] Please update the Device Firmware',
    },
    device_selection: {
        no_beambox: '#801 We can\'t find your machine on the network.\nKindly follow the <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/360001683556">guide</a> to troubleshoot the connection problem.',
        no_device_web: '#801 Please check the status of your machine, or click on ‘Machine Setup’ below to set up machine.',
        select_usb_device: 'Select USB Device',
    },
    update: {
        update: 'Update',
        release_note: 'Release Note:',
        firmware: {
            caption: 'A Firmware Update to the machine is available',
            message_pattern_1: '"%s" is now ready for firmware update.',
            message_pattern_2: '%s Firmware v%s is now available - You have v%s.',
            latest_firmware: {
                caption: 'Machine firmware Update',
                message: 'You have the latest Machine firmware',
                still_update: 'UPDATE',
                cant_get_latest: 'Unable to get information of the latest firmware.',
            },
            confirm: 'UPLOAD',
            upload_file: 'Firmware upload (*.bin / *.fxfw)',
            update_success: 'Firmware update successfully uploaded',
            update_fail: '#822 Update Fail',
            too_old_for_web: 'Your current machine firmware version is v%s.\nIf you want to use the online version of the Beam Studio, please update the machine firmware to the latest version.',
            force_update_message: '#814 Please update your machine to the latest firmware version.',
            firmware_too_old_update_by_sdcard: 'The firmware version is too old. Please update the firmware using an SD card.',
        },
        software: {
            checking: 'Checking for Update',
            switch_version: 'Switch Version',
            check_update: 'Check for Update',
            caption: 'A Software Update to Beam Studio is available',
            downloading: 'Downloading updates in the background, you can click "OK" to continue your work.',
            install_or_not: 'is ready for update. Would you like restart and update now?',
            switch_or_not: 'is ready for switch. Would you like restart and switch now?',
            available_update: 'Beam Studio v%s is available now. You have v%s. Do you want to download the update?',
            available_switch: 'Beam Studio v%s is available now. You have v%s. Do you want to switch to this version?',
            not_found: 'You are using the latest version Beam Studio.',
            no_response: 'Failed to connect to server, please check you network setting.',
            switch_version_not_found: 'Switchable Version not found.',
            yes: 'Yes',
            no: 'No',
            update_for_ador: 'The current software version %s is incompatible, please download the latest version of Beam Studio for Ador.',
        },
        updating: 'Updating...',
        skip: 'Skip This Version',
        preparing: 'Preparing...',
        later: 'LATER',
        download: 'ONLINE UPDATE',
        cannot_reach_internet: '#823 Server is unreachable<br/>Please checking internet connection',
        install: 'INSTALL',
        upload: 'UPLOAD',
    },
    topmenu: {
        version: 'Version',
        credit: 'Beam Studio is made possible by the <a target="_blank" href="https://github.com/flux3dp/beam-studio">Beam Studio</a> open source project and other <a target="_blank" href="https://flux3dp.com/credits/">open source software</a>.',
        ok: 'OK',
        file: {
            label: 'File',
            import: 'Import',
            save_fcode: 'Export FLUX Task',
            save_scene: 'Save Scene',
            save_svg: 'Export SVG',
            save_png: 'Export PNG',
            save_jpg: 'Export JPG',
            converting: 'Converting to Image...',
            all_files: 'All Files',
            svg_files: 'SVG',
            png_files: 'PNG',
            jpg_files: 'JPG',
            scene_files: 'Beam Studio Scene',
            fcode_files: 'FLUX Code',
            clear_recent: 'Clear Recently Opened',
            path_not_exit: 'This path does not seem to exist anymore on disk.',
        },
        device: {
            download_log_canceled: 'Log download canceled',
            download_log_error: 'Unknown error occurred, please try it again later',
            log: {
                usblist: 'USB List',
            },
            network_test: 'Test Network',
        },
    },
    initialize: {
        // generic strings
        next: 'Next',
        start: 'Start',
        skip: 'Skip',
        cancel: 'Cancel',
        confirm: 'Confirm',
        connect: 'Connect',
        back: 'Back',
        retry: 'Retry',
        no_machine: "I don't have a machine now.",
        // specific caption/content
        select_language: 'Select Language',
        select_machine_type: 'Select Your Machine Type',
        select_connection_type: 'How do you wish to connect?',
        connection_types: {
            wifi: 'Wi-Fi',
            wired: 'Wired Network',
            ether2ether: 'Direct Connection',
            usb: 'USB Connection',
        },
        connect_wifi: {
            title: 'Connecting to Wi-Fi',
            tutorial1: '1. Go to Touch Panel > Click "Network" > "Connect to WiFi".',
            tutorial1_ador: '1. Go to Touch Panel > Click "MACHINE" > Click "Network" > "Connect to WiFi".',
            tutorial2: '2. Select and connect your preferred Wi-Fi.',
            what_if_1: "What if I don't see my Wi-Fi?",
            what_if_1_content: '1. The Wi-Fi encryption mode should be WPA2 or no password.\n2. The encryption mode can be set in the Wi-Fi router administration interface. If the router doesn’t support WPA2 and you need help picking out the right router, please contact FLUX Support.',
            what_if_2: "What if I don't see any Wi-Fi?",
            what_if_2_content: '1. Make sure the Wi-Fi dongle is fully plugged in.\n2. If there is no MAC Address of the wireless network on the touchscreen, please contact FLUX Support.\n3. The Wi-Fi channel should be 2.4Ghz (5Ghz is not supported).',
        },
        connect_wired: {
            title: 'Connecting to Wired Network',
            tutorial1: '1. Connect the machine with your router.',
            tutorial2: '2. Press "Network" to get the wired network IP.',
            tutorial2_ador: '2. Press "MACHINE" > "Network" to get the wired network IP.',
            what_if_1: 'What if the IP is empty?',
            what_if_1_content: '1. Make sure the Ethernet Cable is fully plugged in.\n2. If there is no MAC Address of the wired network on the touchscreen, please contact FLUX Support.',
            what_if_2: 'What if the IP starts with 169?',
            what_if_2_content: '1. If the IP address starts with 169.254, it should be a DHCP setting issue, please contact your ISP (internet service provider) for further assistance.\n2. If your computer connects to the internet directly using PPPoE, please change to using the router to connect using PPPoE, and enable DHCP feature in the router.',
        },
        connect_ethernet: {
            title: 'Direct Connection',
            tutorial1: '1. Connect the machine with your computer with ethernet cable.',
            tutorial2_1: '2. Follow ',
            tutorial2_a_text: 'this guide',
            tutorial2_a_href_mac: 'https://support.flux3dp.com/hc/en-us/articles/360001517076',
            tutorial2_a_href_win: 'https://support.flux3dp.com/hc/en-us/articles/360001507715',
            tutorial2_2: ' to make your computer as a router.',
            tutorial3: '3. Click Next.',
        },
        connect_usb: {
            title: 'USB Connection',
            title_sub: ' (HEXA & Ador Only)',
            tutorial1: 'Connect the machine with your computer with USB cable.',
            tutorial2: "Click 'Next'.",
            turn_off_machine: 'Turn off the machine.',
            turn_on_machine: 'Turn on the machine.',
            wait_for_turning_on: "Click 'Next' after the startup process is complete and you have entered the main screen.",
        },
        connect_machine_ip: {
            check_usb: 'Check USB Connection',
            enter_ip: 'Enter Your Machine IP',
            check_ip: 'Checking IP availability',
            invalid_ip: 'IP Invalid: ',
            invalid_format: 'Invalid format',
            starts_with_169254: 'Starts with 169.254',
            unreachable: 'IP unreachable',
            check_connection: 'Checking Machine Connection',
            check_firmware: 'Checking firmware version',
            check_camera: 'Checking camera availability',
            retry: 'Retry',
            succeeded_message: 'Successfully connected 🎉',
            finish_setting: 'Start Creating!',
        },
        connecting: 'Connecting...',
        setting_completed: {
            start: 'Start',
            great: 'Welcome to Beam Studio',
            setup_later: 'You can always set up your machine from Titlebar > "Machines" > "Machine Setup"',
            back: 'Back',
            ok: 'START CREATING',
        },
    },
    error_pages: {
        screen_size: 'Please note that Beam Studio may not work optimally on your device. For an optimal experience, it should be a device with a screen width of at least 1024 pixels width.',
    },
    menu: {
        mm: 'mm',
        inches: 'Inches',
    },
    settings: {
        on: 'On',
        off: 'Off',
        low: 'Low',
        high: 'Normal',
        caption: 'Settings',
        tabs: {
            general: 'General',
            device: 'Machine',
        },
        ip: 'Machine IP Address',
        guess_poke: 'Search for machine IP address',
        auto_connect: 'Automatically select the only machine',
        wrong_ip_format: 'Wrong IP Formats',
        default_machine: 'Default Machine',
        default_machine_button: 'Empty',
        remove_default_machine_button: 'Remove',
        confirm_remove_default: 'Default machine is going to be removed.',
        reset: 'Reset Beam Studio',
        reset_now: 'Reset Beam Studio',
        confirm_reset: 'Confirm reset Beam Studio?',
        language: 'Language',
        notifications: 'Desktop Notifications',
        check_updates: 'Auto Check',
        autosave_enabled: 'Auto Save',
        autosave_path: 'Auto Save Location',
        autosave_interval: 'Save Every',
        autosave_number: 'Number of Auto Save',
        autosave_path_not_correct: 'Specified path not found.',
        preview_movement_speed: 'Preview Movement Speed',
        slow: 'Slow',
        medium: 'Medium',
        fast: 'Fast',
        default_units: 'Default Units',
        default_font_family: 'Default Font',
        default_font_style: 'Default Font Style',
        fast_gradient: 'Speed Optimization',
        engraving_direction: 'Direction',
        top_down: 'Top-down',
        bottom_up: 'Bottom-up',
        vector_speed_constraint: 'Speed Limit (20mm/s)',
        loop_compensation: 'Loop Compensation',
        blade_radius: 'Blade Radius',
        blade_precut_switch: 'Blade Precut',
        blade_precut_position: 'Precut Position',
        default_beambox_model: 'Default Document Setting',
        guides_origin: 'Guides Origin',
        guides: 'Guides',
        image_downsampling: 'Bitmap Preview Quality',
        anti_aliasing: 'Anti-Aliasing',
        continuous_drawing: 'Continuous Drawing',
        trace_output: 'Image Trace Output',
        single_object: 'Single Object',
        grouped_objects: 'Grouped Objects',
        simplify_clipper_path: 'Optimize the Calculated Path',
        enable_low_speed: 'Enable slow speed movement',
        enable_custom_backlash: 'Enable Custom Backlash Compensation',
        auto_switch_tab: 'Auto Switch Layer and Object Panel',
        custom_preview_height: 'Custom Preview Height',
        mask: 'Workarea Clipping',
        text_path_calc_optimization: 'Path Calculation Optimization',
        font_substitute: 'Substitute Unsupported Characters',
        font_convert: 'Text-to-Path Converter',
        default_borderless_mode: 'Open Bottom Default',
        default_enable_autofocus_module: 'Autofocus Default',
        default_enable_diode_module: 'Diode Laser Default',
        diode_offset: 'Diode Laser Offset',
        autofocus_offset: 'Autofocus Offset',
        diode_one_way_engraving: 'Diode Laser One Way Engraving',
        diode_two_way_warning: 'Bidirectional light emitting is faster and probably generate some imprecision on laser engraving position. Recommend to test first.',
        share_with_flux: 'Share Beam Studio Analytics',
        none: 'None',
        close: 'Close',
        enabled: 'Enabled',
        disabled: 'Disabled',
        cancel: 'Cancel',
        done: 'Apply',
        module_offset_10w: '10W Diode Laser Offset',
        module_offset_20w: '20W Diode Laser Offset',
        module_offset_printer: 'Printer Offset',
        module_offset_2w_ir: '2W Infrared Laser Offset',
        printer_advanced_mode: 'Printer Advanced Mode',
        default_laser_module: 'Default Laser Module',
        low_laser_for_preview: 'Laser for Running Frame',
        groups: {
            general: 'General',
            update: 'Software Updates',
            connection: 'Connection',
            autosave: 'Auto Save',
            camera: 'Camera',
            editor: 'Editor',
            engraving: 'Rastering (Scanning)',
            path: 'Vector (Outlines)',
            mask: 'Workarea Clipping',
            text_to_path: 'Text',
            modules: 'Add-on',
            ador_modules: 'Ador Modules',
            privacy: 'Privacy',
        },
        notification_on: 'On',
        notification_off: 'Off',
        update_latest: 'Latest',
        update_beta: 'Beta',
        help_center_urls: {
            connection: 'https://support.flux3dp.com/hc/en-us/sections/360000302135',
            image_downsampling: 'https://support.flux3dp.com/hc/en-us/articles/360004494995',
            anti_aliasing: 'https://support.flux3dp.com/hc/en-us/articles/360004408956',
            continuous_drawing: 'https://support.flux3dp.com/hc/en-us/articles/360004406496',
            simplify_clipper_path: 'https://support.flux3dp.com/hc/en-us/articles/360004407276',
            fast_gradient: 'https://support.flux3dp.com/hc/en-us/articles/360004496235',
            reverse_engraving: 'https://support.flux3dp.com/hc/en-us/articles/',
            vector_speed_constraint: 'https://support.flux3dp.com/hc/en-us/articles/360004496495',
            loop_compensation: 'https://support.flux3dp.com/hc/en-us/articles/360004408856',
            mask: 'https://support.flux3dp.com/hc/en-us/articles/360004408876',
            font_substitute: 'https://support.flux3dp.com/hc/en-us/articles/360004496575',
            font_convert: 'https://support.flux3dp.com/hc/en-us/articles/9132766761743',
            default_borderless_mode: 'https://support.flux3dp.com/hc/zh-tw/articles/360001104076',
            default_enable_autofocus_module: 'https://support.flux3dp.com/hc/en-us/articles/360001574536',
            default_enable_diode_module: 'https://support.flux3dp.com/hc/en-us/articles/360001568035',
        },
    },
    beambox: {
        tag: {
            g: 'Group',
            use: 'Import SVG',
            image: 'Image',
            text: 'Text',
        },
        context_menu: {
            cut: 'Cut',
            copy: 'Copy',
            paste: 'Paste',
            paste_in_place: 'Paste in Place',
            duplicate: 'Duplicate',
            delete: 'Delete',
            group: 'Group',
            ungroup: 'Ungroup',
            move_front: 'Bring to Front',
            move_up: 'Bring Forward',
            move_down: 'Send Backward',
            move_back: 'Send to Back',
        },
        popup: {
            select_import_method: 'Select layering style:',
            select_import_module: 'Select Module:',
            touchpad: 'TouchPad',
            mouse: 'Mouse',
            layer_by_layer: 'Layer',
            layer_by_color: 'Color',
            nolayer: 'Single Layer',
            loading_image: 'Loading image, please wait...',
            no_support_text: 'Beam Studio does not support text tag currently. Please transfer text to path before importing.',
            speed_too_high_lower_the_quality: 'Using too high speed at this resolution may result in the lower quality of shading engraving.',
            both_power_and_speed_too_high: "Using lower laser power will extends laser tube's lifetime.\nAlso, too high speed at this resolution may result in the lower quality of shading engraving.",
            too_fast_for_path: "Using too high speed in layers containing path objects may result in lower precision when cutting.\nWe don't recommend using speed faster than 20 mm/s when cutting.",
            too_fast_for_path_and_constrain: 'Following layers: %s\ncontain vector path objects, and have speed exceeding 20 mm/s.\nThe cutting speed of vector path objects will be constrained to 20 mm/s.\nYou can remove this limit at Preferences Settings.',
            should_update_firmware_to_continue: '#814 Your firmware does not support this version of Beam Studio. Kindly update firmware to continue. (Menu > Machine > [Your Machine] > Update Firmware)',
            recommend_downgrade_software: 'We detected an older firmware version. We are actively addressing compatibility issues, but for now, we recommend reverting to <a target="_blank" href="https://beamstudio.s3.ap-northeast-1.amazonaws.com/mac/Beam+Studio+1.9.5.dmg">Beam Studio 1.9.5</a>.',
            recommend_upgrade_firmware: 'We detected an older firmware version. We are actively addressing compatibility issues, but for now, we recommend updating to the latest firmware.',
            still_continue: 'Continue',
            more_than_two_object: 'Too many objects. Only support for 2 objects',
            not_support_object_type: 'Not support object type',
            select_first: 'Select an object first.',
            select_at_least_two: 'Select two objects to proceed',
            import_file_contain_invalid_path: '#808 Imported SVG file contains invalid image path. Please make sure all image files exist or embed image in the file',
            import_file_error_ask_for_upload: 'Failed to Imported SVG file. Are you willing to provide file to develop team for bug report ?',
            upload_file_too_large: '#819 File is too large for upload.',
            successfully_uploaded: 'File upload succeeded.',
            upload_failed: '#819 File upload failed.',
            or_turn_off_borderless_mode: ' Or turn off Open Bottom mode.',
            svg_1_1_waring: 'The version of this SVG file is v 1.1, there might be potential incompatibility problems.',
            svg_image_path_waring: 'This SVG file contains <image> loading from file path. This might cause fails when loading.\nTo avoid this risk, Please kindly use embed image when exporting SVG.',
            dxf_version_waring: 'The version of this DXF file is not 2013, there might be potential incompatibility problems.',
            dont_show_again: "Don't Show this next time.",
            convert_to_path_fail: 'Failed to convert to path.',
            save_unsave_changed: 'Do you want to save unsaved changes?',
            dxf_bounding_box_size_over: 'Drawing size is out of workarea. Please move your drawing closer to origin in your CAD software, or make sure that the unit is set correctly.',
            progress: {
                uploading: 'Uploading',
                calculating: 'Calculating',
            },
            backend_connect_failed_ask_to_upload: '#802 Errors keeps occurring when trying to connect to the backend. Do you want to upload your bug report log?',
            backend_error_hint: 'Features might be not working properly due to backend error.',
            pdf2svg: {
                error_when_converting_pdf: '#824 Error when converting PDF to SVG:',
                error_pdf2svg_not_found: '#825 Error: Command pdf2svg not found. Please install pdf2svg with your package manager (e.g., "yum install pdf2svg" or "apt-get install pdf2svg").',
            },
            ungroup_use: 'This will ungroup imported DXF or SVG. Because the file might contain a great number of elements, it might take time to ungroup. Are you sure to proceed?',
            vectorize_shading_image: 'Gradient images takes more time to trace and are prone to noise. Please kindly turn off the image gradient before executing.',
            change_workarea_before_preview: 'The workarea of %s does not match the currently set workarea. Do you want to switch current workarea?',
            bug_report: 'Bug Report',
            sentry: {
                title: "Let's make Beam Studio better together",
                message: 'Do you agree to automatically upload relevant information to the development team when errors occur?',
            },
            questionnaire: {
                caption: 'Help Us Fill out the Questionnaire',
                message: 'Help us fill out the questionnaire to make Beam Studio better.',
                unable_to_get_url: 'Failed to get the link to the questionnaire via internet. Please confirm your internet connection.',
                no_questionnaire_available: 'There is no questionnaire available right now.',
            },
            facebook_group_invitation: {
                title: 'Join FLUX Official User Group',
                message: "Join our official Facebook group to connect with other FLUX laser users, discuss FLUX lasers, share laser artwork, and stay up to date with the latest updates on our products. We can't wait to see you there!",
                join_now: 'Count Me In',
                later: 'Maybe Later',
                already_joined: 'Already Joined',
                dont_show_again: "Don't show this again",
            },
            ai_credit: {
                relogin_to_use: 'Please re-login to use this function.',
                insufficient_credit: 'You are out of Credit',
                insufficient_credit_msg: 'You can not use the %s. Go to member center and purchase AI Credit.',
                buy_link: 'https://member.flux3dp.com/en-US/credit',
                go: 'Go',
            },
            text_to_path: {
                caption: 'Text-to-Path Converter 2.0',
                message: "Beam Studio now introduces a new text-to-path converter (Converter 2.0), which produces more dependable results! Would you like to switch to it now? \nYou can also adjust this setting later in the 'Text-to-Path Converter' section within the preferences.",
            },
            auto_switch_tab: {
                title: 'Auto Switching Layer and Object Panel',
                message: 'A new option to automatically switch between the Layers panel and the Objects panel has been added to Preferences. This option is disabled by default. Would you like to enable automatic switching now?<br/>You can change this setting anytime in Preferences.',
            },
        },
        zoom_block: {
            fit_to_window: 'Fit to Window',
        },
        time_est_button: {
            calculate: 'Estimate time',
            estimate_time: 'Estimated Time:',
        },
        left_panel: {
            unpreviewable_area: 'Blind Area',
            diode_blind_area: 'Hybrid Laser Add-On Blind Area',
            borderless_blind_area: 'Non-engraving Area',
            borderless_preview: 'Open Bottom Mode Camera Preview',
            rectangle: 'Rectangle',
            ellipse: 'Ellipse',
            line: 'Line',
            image: 'Image',
            text: 'Text',
            label: {
                cursor: 'Select',
                photo: 'Image',
                text: 'Text',
                line: 'Line',
                rect: 'Rectangle',
                oval: 'Oval',
                polygon: 'Polygon',
                pen: 'Pen',
                shapes: 'Elements',
                array: 'Array',
                preview: 'Camera Preview',
                trace: 'Trace Image',
                end_preview: 'End Preview',
                clear_preview: 'Clear Preview',
                choose_camera: 'Camera',
                live_feed: 'Live Feed',
                adjust_height: 'Adjust Height',
                qr_code: 'QR Code',
                boxgen: 'Boxgen',
                my_cloud: 'My Cloud',
                pass_through: 'Pass Through',
                curve_engraving: {
                    title: '3D Curve',
                    exit: 'Exit',
                    select_area: 'Select Area',
                    preview_3d_curve: 'Preview 3D Curve',
                    clear_area: 'Clear Selected Area',
                },
            },
        },
        right_panel: {
            tabs: {
                layers: 'Layers',
                objects: 'Objects',
                path_edit: 'Path Edit',
            },
            layer_panel: {
                layer1: 'Layer 1',
                layer_bitmap: 'Bitmap',
                layer_engraving: 'Engraving',
                layer_cutting: 'Cutting',
                current_layer: 'Current Layer',
                move_elems_to: 'Move elements to:',
                notification: {
                    dupeLayerName: 'There is already a layer named that!',
                    newName: 'NEW NAME',
                    enterUniqueLayerName: 'Please enter a unique layer name',
                    enterNewLayerName: 'Please enter the new layer name',
                    layerHasThatName: 'Layer already has that name',
                    QmoveElemsToLayer: "Move selected elements to layer '%s'?",
                    moveElemFromPrintingLayerTitle: 'Move selected element to %s and convert it into laser element?',
                    moveElemFromPrintingLayerMsg: 'Please note that if you complete this operation, the color settings of the selected element will be removed and set according to %s.',
                    moveElemToPrintingLayerTitle: 'Move selected element to %s and convert it into printing element?',
                    moveElemToPrintingLayerMsg: 'Please note that if you complete this operation, the settings of the selected element will be removed and set according to %s.',
                    splitColorTitle: 'Do you want to expand the selected layer into CMYK layers?',
                    splitColorMsg: 'Please notice that if you continue with this operation, you will not be able to fall back to the original color layers.',
                    mergeLaserLayerToPrintingLayerTitle: 'Do you want to merge these layers into one Printing layer?',
                    mergeLaserLayerToPrintingLayerMsg: 'Please be aware that if you complete this operation, the settings of the laser layer will be removed and set according to the current layer.',
                    mergePrintingLayerToLaserLayerTitle: 'Do you want to merge these layers into one Laser layer?',
                    mergePrintingLayerToLaserLayerMsg: 'Please be aware that if you complete this operation, the color settings of the printing layer will be removed and set according to the current layer.',
                },
                layers: {
                    layer: 'Layer',
                    layers: 'Layers',
                    del: 'Delete Layer',
                    move_down: 'Move Layer Down',
                    new: 'New Layer',
                    rename: 'Rename Layer',
                    move_up: 'Move Layer Up',
                    dupe: 'Duplicate Layer',
                    lock: 'Lock Layer',
                    unlock: 'Unlock',
                    merge_down: 'Merge Down',
                    merge_all: 'Merge All',
                    merge_selected: 'Merge Selected Layers',
                    move_elems_to: 'Move elements to:',
                    move_selected: 'Move selected elements to a different layer',
                    switchToFullColor: 'Switch to full color layer',
                    switchToSingleColor: 'Switch to single color layer',
                    splitFullColor: 'Expand Layer',
                    fullColor: 'Full color',
                },
            },
            laser_panel: {
                preset_setting: 'Parameter Settings (%s)',
                multi_layer: 'Multiple Layers',
                parameters: 'Parameters',
                strength: 'Power',
                pwm_advanced_setting: 'Depth Mode Power Settings',
                pwm_advanced_desc: 'Set a minimum power for Depth Mode.',
                pwm_advanced_hint: 'This setting applies specifically to gradient images when using Depth Mode.',
                low_power_warning: 'Lower laser power (under 10%) might not emit the laser light.',
                speed: 'Speed',
                speed_contrain_warning: 'The cutting speed of vector path objects will be constrained to 20 mm/s (0.79in/s).You can remove this limit at Preferences Settings.',
                low_speed_warning: 'Low speed might cause burning of the material.',
                repeat: 'Pass Count',
                advanced: 'Advanced',
                lower_focus: 'Lower Focus',
                by: 'By',
                stepwise_focusing: 'Stepwise Focusing',
                single_color: 'Single Color',
                lower_focus_desc: 'Lowering the focus height by a specific distance after focusing to improve cutting performance.',
                stepwise_focusing_desc: "Gradually lowering the focus distance based on the object's height during each pass count.",
                single_color_desc: 'Only applicable to full color layer and cannot be used with expanded single color layer.',
                focus_adjustment: 'Focus Adjustment',
                height: 'Object Height',
                z_step: 'Z Step',
                diode: 'Diode Laser',
                backlash: 'Backlash',
                ink_saturation: 'Saturation',
                print_multipass: 'Multi-pass',
                white_ink: 'White Ink',
                white_ink_settings: 'White Ink Settings',
                color_adjustment: 'Channel Adjustment',
                color_adjustment_short: 'Channel',
                halftone: 'Halftone',
                halftone_link: 'https://support.flux3dp.com/hc/en-us/articles/9402670389647',
                color_strength: 'Strength',
                times: 'times',
                cut: 'Cut',
                engrave: 'Engrave',
                more: 'Manage',
                apply: 'Apply',
                cancel: 'Cancel',
                save: 'Save',
                custom_preset: 'Custom',
                various_preset: 'Various Presets',
                module: 'Module',
                slider: {
                    regular: 'Regular',
                    low: 'Low',
                    very_low: 'Min',
                    high: 'High',
                    very_high: 'Max',
                    slow: 'Slow',
                    very_slow: 'Slowest',
                    fast: 'Fast',
                    very_fast: 'Fastest',
                },
                dropdown: {
                    parameters: 'Presets',
                    save: 'Add current parameters',
                    mm: {
                        wood_3mm_cutting: 'Wood - 3mm Cutting',
                        wood_5mm_cutting: 'Wood - 5mm Cutting',
                        wood_7mm_cutting: 'Wood - 7mm Cutting',
                        wood_8mm_cutting: 'Wood - 8mm Cutting',
                        wood_10mm_cutting: 'Wood - 10mm Cutting',
                        wood_engraving: 'Wood - Engraving',
                        acrylic_3mm_cutting: 'Acrylic - 3mm Cutting',
                        acrylic_5mm_cutting: 'Acrylic - 5mm Cutting',
                        acrylic_8mm_cutting: 'Acrylic - 8mm Cutting',
                        acrylic_10mm_cutting: 'Acrylic - 10mm Cutting',
                        acrylic_engraving: 'Acrylic - Engraving',
                        mdf_3mm_cutting: 'New Zealand MDF - 3mm Cutting',
                        mdf_5mm_cutting: 'New Zealand MDF - 5mm Cutting',
                        mdf_engraving: 'New Zealand MDF - Engraving',
                        leather_3mm_cutting: 'Leather - 3mm Cutting',
                        leather_5mm_cutting: 'Leather - 5mm Cutting',
                        leather_engraving: 'Leather - Engraving',
                        denim_1mm_cutting: 'Denim - 1mm Cutting',
                        fabric_3mm_cutting: 'Fabric - 3mm Cutting',
                        fabric_5mm_cutting: 'Fabric - 5mm Cutting',
                        fabric_engraving: 'Fabric - Engraving',
                        rubber_bw_engraving: 'Rubber - Engraving',
                        glass_bw_engraving: 'Glass - Engraving',
                        metal_bw_engraving: 'Metal - Engraving',
                        steel_engraving_spray_engraving: 'Metal - Engraving',
                        stainless_steel_bw_engraving_diode: 'Metal - Engraving (Diode Laser)',
                        gold_engraving: 'Gold - Engraving',
                        brass_engraving: 'Brass - Engraving',
                        ti_engraving: 'Titanium - Engraving',
                        stainless_steel_engraving: 'Stainless Steel - Engraving',
                        aluminum_engraving: 'Aluminum - Engraving',
                        black_acrylic_3mm_cutting: 'Black Acrylic - 3mm Cutting',
                        black_acrylic_5mm_cutting: 'Black Acrylic - 5mm Cutting',
                        black_acrylic_engraving: 'Black Acrylic - Engraving',
                        abs_engraving: 'ABS - Engraving',
                        silver_engraving: 'Silver - Engraving',
                        iron_engraving: 'Iron - Engraving',
                        fabric_printing: 'Fabric - Printing',
                        canvas_printing: 'Canvas - Printing',
                        cardstock_printing: 'Cardstock - Printing',
                        wood_printing: 'Wood - Printing',
                        bamboo_printing: 'Bamboo - Printing',
                        cork_printing: 'Cork - Printing',
                        flat_stone_printing: 'Flat Stone - Printing',
                        acrylic_printing: 'Acrylic - Printing',
                        pc_printing: 'PC - Printing',
                        stainless_steel_printing: 'Stainless Steel - Printing',
                        gloss_leather_printing: 'Gloss Leather - Printing',
                        glass_printing: 'Glass - Printing',
                    },
                    inches: {
                        wood_3mm_cutting: "Wood - 0.1'' Cutting",
                        wood_5mm_cutting: "Wood - 0.2'' Cutting",
                        wood_7mm_cutting: "Wood - 0.3'' Cutting",
                        wood_8mm_cutting: "Wood - 0.3'' Cutting",
                        wood_10mm_cutting: "Wood - 0.4'' Cutting",
                        wood_engraving: 'Wood - Engraving',
                        acrylic_3mm_cutting: "Acrylic - 0.1'' Cutting",
                        acrylic_5mm_cutting: "Acrylic - 0.2'' Cutting",
                        acrylic_8mm_cutting: "Acrylic - 0.3'' Cutting",
                        acrylic_10mm_cutting: "Acrylic - 0.4'' Cutting",
                        acrylic_engraving: 'Acrylic - Engraving',
                        mdf_3mm_cutting: "New Zealand MDF - 0.1'' Cutting",
                        mdf_5mm_cutting: "New Zealand MDF - 0.2'' Cutting",
                        mdf_engraving: 'New Zealand MDF - Engraving',
                        leather_3mm_cutting: "Leather - 0.1'' Cutting",
                        leather_5mm_cutting: "Leather - 0.2'' Cutting",
                        leather_engraving: 'Leather - Engraving',
                        denim_1mm_cutting: "Denim - 0.04'' Cutting",
                        fabric_3mm_cutting: "Fabric - 0.1'' Cutting",
                        fabric_5mm_cutting: "Fabric - 0.2''Cutting",
                        fabric_engraving: 'Fabric - Engraving',
                        rubber_bw_engraving: 'Rubber - Engraving',
                        glass_bw_engraving: 'Glass - Engraving',
                        metal_bw_engraving: 'Metal - Engraving',
                        steel_engraving_spray_engraving: 'Metal - Engraving',
                        stainless_steel_bw_engraving_diode: 'Metal - Engraving (Diode Laser)',
                        gold_engraving: 'Gold - Engraving',
                        brass_engraving: 'Brass - Engraving',
                        ti_engraving: 'Titanium - Engraving',
                        stainless_steel_engraving: 'Stainless Steel - Engraving',
                        aluminum_engraving: 'Aluminum - Engraving',
                        black_acrylic_3mm_cutting: "Black Acrylic - 0.1'' Cutting",
                        black_acrylic_5mm_cutting: "Black Acrylic - 0.2'' Cutting",
                        black_acrylic_engraving: 'Black Acrylic - Engraving',
                        abs_engraving: 'ABS - Engraving',
                        silver_engraving: 'Silver - Engraving',
                        iron_engraving: 'Iron - Engraving',
                        fabric_printing: 'Fabric - Printing',
                        canvas_printing: 'Canvas - Printing',
                        cardstock_printing: 'Cardstock - Printing',
                        wood_printing: 'Wood - Printing',
                        bamboo_printing: 'Bamboo - Printing',
                        cork_printing: 'Cork - Printing',
                        flat_stone_printing: 'Flat Stone - Printing',
                        acrylic_printing: 'Acrylic - Printing',
                        pc_printing: 'PC - Printing',
                        stainless_steel_printing: 'Stainless Steel - Printing',
                        gloss_leather_printing: 'Gloss Leather - Printing',
                        glass_printing: 'Glass - Printing',
                    },
                },
                laser_speed: {
                    text: 'Speed',
                    unit: 'mm/s',
                    fast: 'Fast',
                    slow: 'Slow',
                    min: 3,
                    max: 300,
                    step: 0.1,
                },
                power: {
                    text: 'Power',
                    high: 'High',
                    low: 'Low',
                    min: 1,
                    max: 100,
                    step: 0.1,
                },
                ink_type: {
                    text: 'Ink Type',
                    normal: 'Ink',
                    UV: 'UV Ink',
                },
                para_in_use: 'This parameter is being used.',
                do_not_adjust_default_para: 'Presets can not be adjusted.',
                existing_name: 'This name has been used.',
                presets: 'Preset',
                preset_management: {
                    preset: 'Preset',
                    title: 'Manage Parameters',
                    add_new: 'Add New',
                    lower_focus_by: 'Lower focus by',
                    save_and_exit: 'Save and Exit',
                    delete: 'Delete',
                    reset: 'Reset',
                    sure_to_reset: 'This will delete your customized parameters and reset all presets, are you sure to proceed?',
                    show_all: 'Show All',
                    laser: 'Laser',
                    print: 'Print',
                    export: 'Export',
                    export_preset_title: 'Export Presets',
                    import: 'Import',
                    sure_to_import_presets: 'This will load arrangement of presets and override customized parameters, are you sure to proceed?',
                    new_preset_name: 'New Preset Name',
                },
            },
            object_panel: {
                zoom: 'Zoom',
                group: 'Group',
                ungroup: 'Ungroup',
                distribute: 'Distribute',
                hdist: 'Horizontal Distribute',
                vdist: 'Vertical Distribute',
                align: 'Align',
                left_align: 'Left Align',
                center_align: 'Center Align',
                right_align: 'Right Align',
                top_align: 'Top Align',
                middle_align: 'Middle Align',
                bottom_align: 'Bottom Align',
                boolean: 'Boolean',
                union: 'Union',
                subtract: 'Subtract',
                intersect: 'Intersect',
                difference: 'Difference',
                flip: 'Flip',
                hflip: 'Horizontal Flip',
                vflip: 'Vertical Flip',
                lock_aspect: 'Lock Aspect',
                unlock_aspect: 'Unlock Aspect',
                option_panel: {
                    fill: 'Infill',
                    rounded_corner: 'Rounded corner',
                    sides: 'Sides',
                    font_family: 'Font',
                    font_style: 'Style',
                    font_size: 'Size',
                    letter_spacing: 'Letter spacing',
                    line_spacing: 'Line spacing',
                    vertical_text: 'Vertical text',
                    start_offset: 'Text Offset',
                    vertical_align: 'Alignment',
                    text_infill: 'Text Infill',
                    path_infill: 'Path Infill',
                    shading: 'Gradient',
                    pwm_engraving: 'Depth Mode',
                    pwm_engraving_link: 'https://support.flux3dp.com/hc/en-us/articles/10419884701327',
                    threshold: 'Threshold brightness',
                    threshold_short: 'Threshold',
                    stroke: 'Stroke',
                    stroke_color: 'Stroke Color',
                    stroke_width: 'Stroke Width',
                    color: 'Color',
                },
                actions_panel: {
                    replace_with: 'Replace With...',
                    replace_with_short: 'Replace',
                    trace: 'Trace',
                    grading: 'Grading',
                    brightness: 'Brightness',
                    sharpen: 'Sharpen',
                    crop: 'Crop',
                    bevel: 'Bevel',
                    invert: 'Invert',
                    weld_text: 'Weld Text',
                    convert_to_path: 'Convert to Path',
                    fetching_web_font: 'Getting the online font...',
                    uploading_font_to_machine: 'Uploading font to the machine...',
                    wait_for_parsing_font: 'Parsing font... Please wait a second',
                    offset: 'Offset',
                    array: 'Array',
                    auto_fit: 'Auto Fit',
                    smart_nest: 'Smart Nest',
                    decompose_path: 'Decompose',
                    disassemble_use: 'Disassemble',
                    create_textpath: 'Create Text on Path',
                    create_textpath_short: 'Path Text',
                    detach_path: 'Decompose Text on Path',
                    detach_path_short: 'Decompose',
                    edit_path: 'Edit Path',
                    disassembling: 'Disassembling...',
                    ungrouping: 'Ungrouping...',
                    simplify: 'Simplify',
                    ai_bg_removal: 'Background Removal',
                    ai_bg_removal_short: 'BG Removal',
                    ai_bg_removal_reminder: 'Pressing the button will immediately use 0.2 Credit, do you want to continue?',
                    outline: 'Outline',
                },
                path_edit_panel: {
                    node_type: 'NODE TYPE',
                    sharp: 'Sharp',
                    round: 'Round',
                    connect: 'Connect',
                    disconnect: 'Disconnect',
                    delete: 'Delete',
                },
            },
        },
        bottom_right_panel: {
            convert_text_to_path_before_export: 'Convert Text to Path...',
            retreive_image_data: 'Retrieve Image Data...',
            export_file_error_ask_for_upload: 'Failed to export task. Are you willing to provide working scene to develop team for bug report?',
        },
        image_trace_panel: {
            apply: 'Apply',
            back: 'Back',
            cancel: 'Cancel',
            next: 'Next',
            brightness: 'Brightness',
            contrast: 'Contrast',
            threshold: 'Threshold',
            okay: 'Okay',
            tuning: 'Parameters',
        },
        photo_edit_panel: {
            apply: 'Apply',
            back: 'Back',
            cancel: 'Cancel',
            next: 'Next',
            sharpen: 'Sharpen',
            sharpness: 'Sharpness',
            radius: 'Radius',
            crop: 'Crop',
            aspect_ratio: 'Aspect Ratio',
            original: 'Original',
            free: 'Free',
            curve: 'Curve',
            start: 'Start',
            processing: 'Processing',
            invert: 'Invert Color',
            okay: 'Okay',
            compare: 'Compare',
            phote_edit: 'Photo Edit',
            brightness_and_contrast: 'Brightness / Contrast',
            brightness: 'Brightness',
            contrast: 'Contrast',
            rotary_warped: 'Rotary Warped',
            rotary_warped_link: 'https://support.flux3dp.com/hc/en-us/articles/10828006201103',
            diameter: 'Diameter',
            circumference: 'Circumference',
            warp: 'Warp',
        },
        document_panel: {
            document_settings: 'Document Settings',
            machine: 'Machine',
            watts: 'Watts',
            workarea: 'Working Area',
            rotary_mode: 'Rotary',
            borderless_mode: 'Open Bottom',
            engrave_dpi: 'Resolution',
            enable_diode: 'Diode Laser',
            enable_autofocus: 'Autofocus',
            extend_workarea: 'Extend Workarea',
            mirror: 'Mirror',
            pass_through: 'Pass Through',
            pass_through_height_desc: 'Enter the length of the object to extend work area.',
            start_position: 'Start Position',
            start_from: 'Start From',
            origin: 'Origin',
            current_position: 'Current Position',
            job_origin: 'Job Origin',
            add_on: 'Add-ons',
            low: 'Low',
            medium: 'Medium',
            high: 'High',
            ultra: 'Ultra High',
            enable: 'Enable',
            disable: 'Disable',
            cancel: 'Cancel',
            save: 'Save',
            notification: {
                changeFromPrintingWorkareaTitle: 'Do you want to convert the Printing Layers into Laser Layers?',
            },
        },
        object_panels: {
            wait_for_parsing_font: 'Parsing font... Please wait a second',
            text_to_path: {
                font_substitute_pop: 'Your text contains characters which are not supported by current font. <br/>Would you like to use <strong>%s</strong> as substitution?',
                check_thumbnail_warning: 'Some texts were changed to other fonts when parsing texts to paths and some character may not converted normally.\nPlease check preview image again before sending task.',
                error_when_parsing_text: 'Error when converting text to path',
                retry: 'Please retry later or choose another font',
                use_current_font: 'Use Current Font',
            },
            lock_desc: 'Preserve the ratio of width and height (SHIFT)',
        },
        tool_panels: {
            cancel: 'Cancel',
            confirm: 'Confirm',
            grid_array: 'Create Grid Array',
            array_dimension: 'Array Dimension',
            rows: 'Rows',
            columns: 'Cols.',
            array_interval: 'Array Interval',
            dx: 'X',
            dy: 'Y',
            offset: 'Offset',
            nest: 'Arrangement Optimization',
            _offset: {
                direction: 'Offset Direction',
                inward: 'Inward',
                outward: 'Outward',
                dist: 'Offset Distance',
                corner_type: 'Corner',
                sharp: 'Sharp',
                round: 'Round',
                fail_message: 'Failed to Offset Objects.',
                not_support_message: 'Selected elements containing unsupported SVG tag：\nImage, Group, Text and Imported Object.',
            },
            _nest: {
                start_nest: 'Arrange',
                stop_nest: 'Stop',
                end: 'Close',
                spacing: 'Spacing',
                rotations: 'Possible Rotation',
                no_element: 'There is no element to arrange.',
            },
        },
        network_testing_panel: {
            network_testing: 'Network Testing',
            local_ip: 'Local IP address:',
            insert_ip: 'Target device IP address:',
            empty_ip: '#818 Please enter target device IP first.',
            start: 'Start',
            end: 'End',
            testing: 'Testing Network...',
            invalid_ip: '#818 Invalid IP address',
            ip_startswith_169: '#843 Machine IP address starts with 169.254',
            connection_quality: 'Connection Quality',
            average_response: 'Average Response Time',
            test_completed: 'Test Completed',
            test_fail: 'Test Failed',
            cannot_connect_1: '#840 Fail to connect to target IP.',
            cannot_connect_2: '#840 Fail to connect to target IP. Please make sure that the target is in the same network.',
            network_unhealthy: '#841 Connection quality <70 or average response time >100ms',
            device_not_on_list: '#842 The machine is not on the list, but the connection Quality is >70 and the average response time is <100ms',
            hint_device_often_on_list: 'The machine is often not found on the list?',
            link_device_often_on_list: 'https://support.flux3dp.com/hc/en-us/articles/360001841636',
            hint_connect_failed_when_sending_job: 'Failed to connect when sending a job?',
            link_connect_failed_when_sending_job: 'https://support.flux3dp.com/hc/en-us/articles/360001841656',
            hint_connect_camera_timeout: 'Timeout occurs when starting camera preview?',
            link_connect_camera_timeout: 'https://support.flux3dp.com/hc/en-us/articles/360001791895',
            cannot_get_local: 'Access to local IP address failed.',
            fail_to_start_network_test: '#817 Fail to start network testing.',
            linux_permission_hint: 'This error usually occurs due to insufficient permissions.\nKindly run "sudo beam-studio --no-sandbox" in terminal to obtain permissions and perform network testing.',
        },
        layer_color_config_panel: {
            layer_color_config: 'Layer Color Configurations',
            color: 'Color',
            power: 'Power',
            speed: 'Speed',
            repeat: 'Execute',
            add: 'Add',
            save: 'Save',
            cancel: 'Cancel',
            default: 'Reset To Default',
            add_config: 'Add Color',
            in_use: 'This color is in use.',
            no_input: 'Please input valid hex color code.',
            sure_to_reset: 'You will lose all customized parameters, are you sure to reset to default?',
            sure_to_delete: 'Are you sure to delete this color setting?',
        },
        rating_panel: {
            title: 'Enjoy Beam Studio?',
            description: 'If you like Beam Studio, we would greatly appreciate it if you could rate us.',
            dont_show_again: "Don't Show this next time.",
            thank_you: 'Thank You!',
        },
        svg_editor: {
            unnsupported_file_type: 'The file type is not directly supported. Please convert the file into SVG or bitmap',
            unable_to_fetch_clipboard_img: 'Failed to fetch image from your clipboard',
        },
        units: {
            walt: 'W',
            mm: 'mm',
        },
        path_preview: {
            play: 'Play',
            pause: 'Pause',
            stop: 'Stop',
            play_speed: 'Playback Speed',
            travel_path: 'Travel Path',
            invert: 'Invert',
            preview_info: 'Preview Information',
            size: 'Size',
            estimated_time: 'Total Time Estimated',
            cut_time: 'Cut Time',
            rapid_time: 'Travel Time',
            cut_distance: 'Cut Distance',
            rapid_distance: 'Travel Distance',
            current_position: 'Current Position',
            remark: '* All pieces of information are estimated value for reference.',
            start_here: 'Start Here',
            end_preview: 'End Preview',
        },
        shapes_panel: {
            title: 'Elements',
            basic: 'Basic',
            shape: 'Shape',
            graphics: 'Graphics',
            arrow: 'Arrow',
            label: 'Label',
            decor: 'Decor',
            circular: 'Circular',
            corner: 'Corner',
            line: 'Line',
            photo: 'Photo',
            ribbon: 'Ribbon',
            speech: 'Speech',
            text: 'Text Frame',
            animals: 'Animal',
            birds: 'Bird',
            land: 'Land',
            sea: 'Sea',
            holidays: 'Holiday',
            celebration: 'Celebration',
            CNY: 'Chinese New Year',
            easter: 'Easter',
            halloween: 'Halloween',
            valentines: 'Valentines',
            Xmas: 'Christmas',
            nature: 'Nature',
            elements: 'Elements',
            environment: 'Environment',
            plants: 'Plant',
            weather: 'Weather',
        },
        announcement_panel: {
            title: 'Announcement',
            dont_show_again: "Don't show again",
        },
    },
    editor: {
        prespray_area: 'Priming Area',
        opacity: 'Preview Opacity',
        exposure: 'Preview Exposure',
    },
    flux_id_login: {
        connection_fail: '#847 Failed to connect to FLUX member service.',
        login_success: 'You’re signed in.',
        login: 'Sign In',
        unlock_shape_library: 'Login to unlock the shapes database.',
        email: 'Email',
        password: 'Password',
        remember_me: 'Remember me',
        forget_password: 'Forgot Password?',
        register: 'Create Your FLUX Account',
        offline: 'Work Offline',
        work_offline: 'Work Offline',
        incorrect: 'Email address or password is not correct.',
        not_verified: 'The email address has not been verified yet.',
        new_to_flux: 'New to FLUX? Create an account.',
        signup_url: 'https://id.flux3dp.com/user/login#up',
        lost_password_url: 'https://id.flux3dp.com/user/forgot-password',
        flux_plus: {
            explore_plans: 'Explore FLUX+ Plans',
            thank_you: 'Thanks for being a valued member!',
            ai_credit_tooltip: 'For AI background removal',
            flux_credit_tooltip: 'For Design Market files and AI background removal',
            goto_member_center: 'Go to Member Center',
            access_plus_feature_1: 'You are accessing a',
            access_plus_feature_2: 'feature.',
            access_plus_feature_note: 'You must have FLUX+ membership to access this feature.',
            access_monotype_feature: 'You do not have Monotype Fonts Add-on.',
            access_monotype_feature_note: 'You must have FLUX+ Pro membership or Monotype Fonts Add-on to access this feature.',
            learn_more: 'Learn More',
            get_addon: 'Get Add-on',
            subscribe_now: 'Subscribe now',
            website_url: 'https://flux3dp.com/subscription',
            member_center_url: 'https://member.flux3dp.com/en-US/subscription',
            features: {
                ai_bg_removal: 'AI Background Removal',
                my_cloud: 'Unlimited Cloud Storage',
                boxgen: '3D Box Generator',
                dmkt: '1000+ Design Files',
                monotype: '250+ Premium Fonts',
            },
        },
    },
    noun_project_panel: {
        login_first: 'Login to unlock the shapes database.',
        enjoy_shape_library: 'Enjoy the shapes database.',
        shapes: 'Shapes',
        elements: 'Elements',
        recent: 'Recent',
        search: 'Search',
        clear: 'Clear',
        export_svg_title: 'Unable to Export SVG',
        export_svg_warning: 'This project contains objects that are protected by the intellectual property law. Therefore Beam Studio will automatically exclude these objects during the export. You can still save your project within Beam Studio Scene (.beam format) to keep your entire data. Do you still want to export?',
        learn_more: 'Learn More',
    },
    change_logs: {
        change_log: 'Change Logs:',
        added: 'Added:',
        fixed: 'Fixed:',
        changed: 'Changed:',
        see_older_version: 'See Older Versions',
        help_center_url: 'https://support.flux3dp.com/hc/en-us/sections/360000421876',
    },
    select_device: {
        select_device: 'Select Device',
        auth_failure: '#811 Authentication fail',
        unable_to_connect: '#810 Unable to build a stable connection with machine',
    },
    device: {
        pause: 'Pause',
        paused: 'Paused',
        pausing: 'Pausing',
        select_printer: 'Select Printer',
        retry: 'Retry',
        status: 'Status',
        busy: 'Busy',
        ready: 'Ready',
        reset: 'Reset (Kick)',
        abort: 'Abort',
        start: 'Start',
        please_wait: 'Please Wait...',
        quit: 'Quit',
        completing: 'Completing',
        aborted: 'Aborted',
        completed: 'Completed',
        aborting: 'Aborting',
        starting: 'Starting',
        preparing: 'Preparing',
        resuming: 'Resuming',
        scanning: 'Scanning',
        occupied: 'Maintaining',
        running: 'Working',
        uploading: 'Uploading',
        processing: 'Processing',
        disconnectedError: {
            caption: 'Machine disconnected',
            message: 'Please confirm if network access of %s is available',
        },
        unknown: 'Unknown',
        pausedFromError: 'Paused from error',
        model_name: 'Model Name',
        IP: 'IP',
        serial_number: 'Serial Number',
        firmware_version: 'Firmware Version',
        UUID: 'UUID',
        select: 'Select',
        deviceList: 'Machine List',
        disable: 'Disable',
        enable: 'Enable',
        submodule_type: 'Module',
        cartridge_serial_number: 'Ink cartridge serial number',
        ink_color: 'Ink color',
        ink_type: 'Ink type',
        ink_level: 'Ink level',
        close_door_to_read_cartridge_info: "To access ink information, please close the machine's door cover.",
        cartridge_info_read_failed: 'Please ensure that the ink is fully inserted. Try to remove the ink and install it back.',
        cartridge_info_verification_failed: 'Please verify that your ink is FLUX authentic.',
        toolhead_change: 'Change Toolhead',
    },
    monitor: {
        monitor: 'MONITOR',
        go: 'Start',
        resume: 'Resume',
        start: 'Start',
        pause: 'Pause',
        stop: 'Stop',
        record: 'Record',
        camera: 'Camera',
        taskTab: 'Task',
        connecting: 'Connecting, please wait...',
        HARDWARE_ERROR_MAINBOARD_ERROR: '#401 Critical Error: Mainboard offline. Please contact FLUX Support.',
        HARDWARE_ERROR_SUBSYSTEM_ERROR: '#402 Critical Error: Subsystem no response. Please contact FLUX Support.',
        HARDWARE_ERROR_PUMP_ERROR: '#900 Please check with your water tank.',
        HARDWARE_ERROR_DOOR_OPENED: '#901 Close the door to continue.',
        HARDWARE_ERROR_OVER_TEMPERATURE: '#902 Overheated. Please wait for a few minutes.',
        HARDWARE_ERROR_BOTTOM_OPENED: '#903 Bottom Opened. Close the bottom to continue.',
        HARDWARE_ERROR_PLATFORM_HOMING_FAILED: '#910 Z-axis reset failed',
        HARDWARE_ERROR_DRAWER_OPENED: '#911 Drawer opened',
        HARDWARE_ERROR_FIRE_DETECTED: '#912 Flame sensor anomalous',
        HARDWARE_ERROR_AIR_ASSIST_ABNORMAL: '#913 Airflow sensor anomalous',
        HARDWARE_ERROR_ROTARY_NOT_DETECTED: '#914 No rotary module detected',
        HARDWARE_ERROR_HOMING_PULLOFF_FAILED: '#920 Homing Pull-Off Error',
        HARDWARE_ERROR_HEADTYPE_MISMATCH: '#915 Incorrect module detected. Please install the correct module to continue.',
        HARDWARE_ERROR_HEADTYPE_NONE: '#917 Module not detected. Please ensure the module is installed properly to continue.',
        HARDWARE_ERROR_HEADTYPE_UNKNOWN: '#918 Unknown module detected. Please install the correct module to continue.',
        HARDWARE_ERROR_PRINTER_NO_RESPONSE: '#919 Printer module no response.',
        USER_OPERATION_ROTARY_PAUSE: 'Please switch to the rotary motor.',
        USER_OPERATION_REMOVE_CARTRIDGE: 'Please remove the cartridge to continue.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_MISMATCH: '#915 Incorrect module detected. Please install the correct module to continue.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_NONE: '#917 Module not detected. Please ensure the module is installed properly to continue.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_UNKNOWN: '#918 Unknown module detected. Please install the correct module to continue.',
        USER_OPERATION_CHANGE_TOOLHEAD: 'Please install the correct module to continue.',
        USER_OPERATION_CHANGE_CARTRIDGE_CARTRIDGE_NOT_DETECTED: 'Please insert the cartridge to continue.',
        USER_OPERATION_CHANGE_CARTRIDGE: 'Please insert the correct cartridge to continue.',
        USER_OPERATION: 'Please follow the instructions on the device panel to continue.',
        RESOURCE_BUSY: 'The machine is busy\nIf it is not running, please restart the machine',
        DEVICE_ERROR: 'Something went wrong\nPlease restart the machine',
        NO_RESPONSE: '#905 Error when connecting to the mainboard.\nPlease restart the machine.',
        SUBSYSTEM_ERROR: '#402 Critical Error: Subsystem no response. Please contact FLUX Support.',
        HARDWARE_FAILURE: 'Something went wrong\nPlease restart the machine',
        MAINBOARD_OFFLINE: '#905 Error when connecting to the mainboard.\nPlease restart the machine. ',
        bug_report: 'Bug Report',
        processing: 'Processing',
        savingPreview: 'Generating thumbnails',
        hour: 'h',
        minute: 'm',
        second: 's',
        left: 'left',
        temperature: 'Temperature',
        forceStop: 'Do you wish to abort current task?',
        upload: 'Upload',
        download: 'Download',
        relocate: 'Relocate',
        cancel: 'Cancel',
        prepareRelocate: 'Preparing for Relocate',
        extensionNotSupported: 'This file format is not supported',
        fileExistContinue: 'File already exists, do you want to replace it?',
        confirmFileDelete: 'Are you sure you want to delete this file?',
        ask_reconnect: 'The connection with the machine has broken. Do you want to reconnect?',
        task: {
            BEAMBOX: 'Laser Engraving',
            'N/A': 'Free Mode',
        },
    },
    alert: {
        caption: 'Error',
        duplicated_preset_name: 'Duplicated preset name',
        info: 'INFO',
        warning: 'WARNING',
        error: 'UH-OH',
        instruction: 'Instruction',
        oops: 'Oops...',
        retry: 'Retry',
        abort: 'Abort',
        confirm: 'Confirm',
        cancel: 'Cancel',
        close: 'Close',
        ok: 'OK',
        ok2: 'OK',
        yes: 'Yes',
        no: 'No',
        stop: 'Stop',
        save: 'Save',
        dont_save: "Don't Save",
        learn_more: 'Learn More',
    },
    caption: {
        connectionTimeout: 'Connection timeout',
    },
    message: {
        cancelling: 'Cancelling...',
        connecting: 'Connecting...',
        connectingMachine: 'Connecting %s...',
        tryingToConenctMachine: 'Trying to connect to machine...',
        connected: 'Connected',
        authenticating: 'Authenticating...',
        enteringRawMode: 'Entering raw mode...',
        endingRawMode: 'Exiting raw mode...',
        enteringLineCheckMode: 'Entering line check mode...',
        endingLineCheckMode: 'Exiting line check mode...',
        exitingRotaryMode: 'Exiting rotary mode...',
        turningOffFan: 'Turning off fan...',
        turningOffAirPump: 'Turning off air pump...',
        gettingLaserSpeed: 'Reading laser head speed...',
        settingLaserSpeed: 'Setting laser head speed...',
        retrievingCameraOffset: 'Reading camera offset...',
        connectingCamera: 'Connecting camera...',
        homing: 'Homing...',
        connectionTimeout: "#805 Device Connection Timeout. Please check your network state and your machine's Wi-Fi indicator.",
        getProbePosition: 'Getting Probe Position...',
        device_not_found: {
            caption: 'Default Machine not found',
            message: "#812 Please check your machine's Wi-Fi indicator",
        },
        device_busy: {
            caption: 'Machine Busy',
            message: 'The machine is executing another task, try again later. If it stops working, please restart the machine.',
        },
        device_is_used: 'The machine is being used, do you want to abort current task?',
        monitor_too_old: {
            caption: 'Firmware Outdated',
            content: '#814 Please install the latest firmware with <a target="_blank" href="http://helpcenter.flux3dp.com/hc/en-us/articles/216251077">this guide</a>.',
        },
        unknown_error: '#821 The application has encountered an unknown error, please use Help > Menu > Bug Report.',
        unknown_device: '#826 Cannot connect to the machine, please make sure USB is attached to the machine',
        unsupport_osx_version: 'Current macOS Version %s may not support some functions. Please kindly update to macOS 11+.',
        unsupport_win_version: 'Current OS Version %s may not support some functions. Please kindly update to the latest version.',
        need_password: 'Need Password to Connect to the Machine',
        unavailableWorkarea: '#804 Current workarea exceeds the workarea of this machine. Please check the workarea of selected machine or set workarea from Edit > Document Setting.',
        please_enter_dpi: 'Please enter the Unit of your file (in mm)',
        auth_error: '#820 Auth Error: Please update Beam Studio and machine firmware to latest version.',
        usb_unplugged: 'USB connection is lost. Please check your USB connection',
        uploading_fcode: 'Uploading FCode',
        cant_connect_to_device: '#827 Unable to connect the machine, please check your connection',
        unable_to_find_machine: 'Unable to find machine ',
        disconnected: 'Connection unstable, Please check device connection and try again later',
        unable_to_start: '#830 Unable to start the task. Please try again. If this happens again, please contact us with bug report:\n',
        camera: {
            camera_cable_unstable: 'It is detected that the camera is transferring photos unstably. Preview can still be performed normally, but there may be the problem of slow preview or timeout.',
            fail_to_transmit_image: '#845 Something went wrong with image transmission. Please restart your machine or Beam Studio. If this error persists, please follow <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/4402756056079">this guide</a>.',
            ws_closed_unexpectly: '#844 Connection to machine camera has been closed unexpectedly. If this error persists, please follow <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/4402755805071">this guide</a>.',
            continue_preview: 'Continue',
            abort_preview: 'Abort',
        },
        preview: {
            camera_preview: 'Camera Preview',
            auto_focus: 'Auto Focus',
            auto_focus_instruction: 'Please move laser module head above the object, and follow the animation instructions to press AF to focus.',
            already_performed_auto_focus: 'You have already performed autofocus, use existing values?',
            please_enter_height: 'Please enter height of object. In order to capture precise camera shot.',
            apply: 'Apply',
            enter_manually: 'Enter Manually',
            adjust: 'Adjust',
            adjust_height_tooltip: 'Click the checkbox to enable editing.',
        },
        unsupported_example_file: 'The selected example file is not supported by current workarea.',
        time_remaining: 'Time remaining:',
    },
    machine_status: {
        '-17': 'Cartridge IO Mode',
        '-10': 'Maintain mode',
        '-2': 'Scanning',
        '-1': 'Maintaining',
        0: 'Idle',
        1: 'Initiating',
        2: 'ST_TRANSFORM',
        4: 'Starting',
        6: 'Resuming',
        16: 'Working',
        18: 'Resuming',
        32: 'Paused',
        36: 'Paused',
        38: 'Pausing',
        48: 'Paused',
        50: 'Pausing',
        64: 'Completed',
        66: 'Completing',
        68: 'Preparing',
        128: 'Aborted',
        256: 'Alarm',
        512: 'Fatal',
        UNKNOWN: 'Unknown',
    },
    calibration: {
        update_firmware_msg1: 'Your firmware does not support this function. Please update firmware to v',
        update_firmware_msg2: 'or above to continue。 (Menu > Machine > [Your Machine] > Update Firmware)',
        camera_calibration: 'Camera Calibration',
        diode_calibration: 'Diode Laser Module Calibration',
        module_calibration_printer: 'Printer Module Calibration',
        module_calibration_2w_ir: 'Infrared Module Calibration',
        back: 'Back',
        next: 'Next',
        skip: 'Skip',
        cancel: 'Cancel',
        finish: 'Done',
        do_engraving: 'Do Engraving',
        start_engrave: 'Start Engrave',
        start_printing: 'Start Printing',
        ask_for_readjust: 'Do your want to skip the engraving step and then take photo and calibrate directly?',
        please_goto_beambox_first: 'Please switch to Engraving Mode ( Beambox ) in order to use this feature.',
        please_place_paper: 'Please place an A4 or Letter size white paper at left-top corner of workarea.',
        please_place_paper_center: 'Please place an A4 or Letter size white paper at the center of workarea.',
        please_place_dark_colored_paper: 'Please place an A4 or Letter size dark-colored paper in the center of the workarea.',
        please_refocus: {
            beambox: 'Kindly adjust the platform to the focal point (the height of turned down acrylic)',
            beamo: 'Kindly adjust the laser head to focus on the engraving object (the height of turned down acrylic)',
            beamo_af: 'Kindly double-tap the side button of the autofocus add-on and let the probe touch the material softly.',
            hexa: 'Double click the height adjustment button to rise the honeycomb table up and make the probe touch the engraving material.',
        },
        without_af: 'Without Autofocus',
        with_af: 'With Autofocus',
        dx: 'X',
        dy: 'Y',
        rotation_angle: 'Rotation',
        x_ratio: 'X Ratio',
        y_ratio: 'Y Ratio',
        show_last_config: 'Show Last Result',
        use_last_config: 'Use Last Calibration Value',
        taking_picture: 'Taking Picture...',
        analyze_result_fail: 'Fail to analyze captured image.<br/>Please make sure:<br/>1. Captured picture fully covered with white paper.<br/>2. The platform is focus properly.',
        drawing_calibration_image: 'Drawing calibration image...',
        calibrate_done: 'Calibration done. Better camera accuracy is given when focus precisely.',
        calibrate_done_diode: 'Calibration done. Offset of diode laser module has been saved.',
        hint_red_square: 'Please align the outside of the red square with the cut square.',
        hint_adjust_parameters: 'Use these parameters to adjust the red square',
        zendesk_link: 'https://support.flux3dp.com/hc/en-us/articles/360001811416',
        please_do_camera_calibration_and_focus: {
            beambox: 'When calibrating diode laser module, camera is needed.\nPlease make sure camera of this machine has been calibrated.\nAnd kindly adjust the platform to the focal point (the height of turned down acrylic)',
            beamo: 'When calibrating diode laser module, camera is needed.\nPlease make sure camera of this machine has been calibrated.\nAnd kindly adjust the laser head to focus on the engraving object (the height of turned down acrylic)',
        },
        downloading_pictures: 'Downloading Pictures...',
        failed_to_download_pictures: '#848 Failed to download pictures, please contact FLUX Support.',
        uploading_images: 'Uploading Images...',
        calculating_camera_matrix: 'Calculating Camera Matrix...',
        calculating_regression_parameters: 'Calculating Regression Parameters...',
        failed_to_calibrate_camera: '#848 Failed to calibrate camera, please contact FLUX Support.',
        failed_to_save_calibration_results: '#849 Failed to save calibration results, please try again. If this keeps happening, please contact FLUX Support.',
        ador_autofocus_material: 'Press the “AF” icon on the main page of the machine for 3 seconds and let the probe touch the material softly.',
        ador_autofocus_focusing_block: 'Press the "AF" icon on the main page of the machine for 3 seconds and let the probe touch the Focusing block.',
        align_red_cross_cut: 'Please align the middle of the red cross with the cut cross.',
        align_red_cross_print: 'Please align the middle of the red cross with the printed cross.',
        retake: 'Retake Picture',
        calibrate_camera_before_calibrate_modules: 'Please perform camera calibration before calibrating modules.',
        check_checkpoint_data: 'Checkpoint Data',
        checking_checkpoint: 'Checking checkpoint data...',
        found_checkpoint: 'Checkpoint data is found in your device. Do you want to recover from the checkpoint?',
        use_old_camera_parameter: 'Do you want to use current camera lens parameter?',
        downloading_checkpoint: 'Downloading checkpoint data...',
        failed_to_parse_checkpoint: 'Failed to parse the checkpoint data.',
        check_device_pictures: 'Check Device Pictures',
        checking_pictures: 'Checking device pictures...',
        no_picutre_found: '#846 Your device does not have any available raw photos for calibration. Please contact FLUX support.',
        calibrating_with_device_pictures: 'Calibrating with device pictures...',
        failed_to_calibrate_with_pictures: '#847 Failed to calibrate with device pictures.',
        getting_plane_height: 'Getting Plane Height...',
        preparing_to_take_picture: 'Preparing to take picture...',
        put_paper: 'Put Paper',
        put_paper_step1: 'Please place an A4 or Letter size white paper at the center of workarea.',
        put_paper_step2: 'Secure the four corners of the paper to ensure it lays flat.',
        put_paper_step3: 'Click "START ENGRAVE"',
        solve_pnp_title: 'Align Marker Points',
        solve_pnp_step1: 'Please align the engraving points according to the number and expected position of each red marker point.',
        solve_pnp_step2: 'You can press "Retake Picture" to realign or manually adjust the marker positions.',
        align_olt: 'Position: Align with the engraved point on the outer top left.',
        align_ort: 'Position: Align with the engraved point on the outer top right.',
        align_olb: 'Position: Align with the engraved point on the outer bottom left.',
        align_orb: 'Position: Align with the engraved point on the outer bottom right.',
        align_ilt: 'Position: Align with the engraved point on the inner top left.',
        align_irt: 'Position: Align with the engraved point on the inner top right.',
        align_ilb: 'Position: Align with the engraved point on the inner bottom left.',
        align_irb: 'Position: Align with the engraved point on the inner bottom right.',
        elevate_and_cut: 'Elevate and Cut',
        elevate_and_cut_step_1: 'Place an A4 or Letter size light-colored wooden in the workspace center, elevated to 20mm.',
        elevate_and_cut_step_1_prism_lift: 'Use Ador Prism Lift at 14mm max length with a wooden at least 6mm thick.',
        with_prism_lift: 'With Ador Prism Lift',
        without_prism_lift: 'Without Ador Prism Lift',
        camera_parameter_saved_successfully: 'Camera parameter saved successfully.',
        failed_to_save_camera_parameter: 'Failed to save camera parameter.',
        failed_to_solve_pnp: 'Failed to solve camera position.',
        calibrating: 'Calibrating...',
        moving_laser_head: 'Moving the laser head...',
        failed_to_move_laser_head: 'Failed to move the laser head.',
        put_chessboard: 'Place Chessboard',
        put_chessboard_desc: 'Please place the chessboard in the center of workarea.',
        put_chessboard_1: 'Please go to the machine control panel and press AF to perform autofocus.',
        put_chessboard_2: 'Please move the chessboard paper or the laser head until the entire chessboard is confirmed to be within the red frame in the live preview window.',
        put_chessboard_3: 'Right-click to download the checkerboard image at the current location and check its clarity.',
        failed_to_calibrate_chessboard: 'Failed to calibrate with the chessboard picture.',
        calibrate_chessboard_success_msg: 'Successfully captured the checkerboard photo.<br/>The result for this photo is %s (%.2f).',
        res_excellent: 'Excellent',
        res_average: 'Average',
        res_poor: 'Poor',
        perform_autofocus_bb2: 'Please go to the machine control panel and press AF to perform autofocus.',
    },
    input_machine_password: {
        require_password: '"%s" requires a password',
        connect: 'CONNECT',
        password: 'Password',
    },
    tutorial: {
        skip: 'Skip',
        welcome: 'WELCOME',
        suggest_calibrate_camera_first: 'We advise users to calibrate the camera initially and refocus before each preview for optimal results.<br/>Please confirm to conduct calibration now?<br/>(You can skip it now and do it later by clicking on "Menu" > "Machine" > [Your Machine] > "Calibrate Camera".)',
        camera_calibration_failed: 'Camera Calibration Failed',
        ask_retry_calibration: 'Would you like to calibrate the camera again?',
        needNewUserTutorial: 'Would you like to start a tutorial?<br/>(You can skip it now and start it later by clicking on "Help" > "Show Start Tutorial".)',
        needNewInterfaceTutorial: 'Would you like to start a tutorial for Beam Studio\'s new interface?<br/>(You can skip it now and start it later by clicking on "Help" > "Show Interface Introduction".)',
        next: 'NEXT',
        look_for_machine: 'Searching machine for tutorial...',
        unable_to_find_machine: 'Unable to find machine for Tutorial. Do you want to go to connection setting page, retry or skip tutorial?',
        skip_tutorial: 'You have skipped the tutorial. You can always start tutorial by click "Help" > "Show Start Tutorial"',
        set_connection: 'Set Connection',
        retry: 'Retry',
        newUser: {
            draw_a_circle: 'Draw a Circle',
            drag_to_draw: 'Drag to Draw',
            infill: 'Turn on Infill',
            switch_to_object_panel: 'Switch to Object Panel',
            switch_to_layer_panel: 'Switch to Layer Panel',
            set_preset_wood_engraving: 'Set Preset: Wood - Engraving',
            set_preset_wood_cut: 'Set Preset: Wood - Cutting',
            add_new_layer: 'Add a New Layer',
            draw_a_rect: 'Draw a Rectangle',
            switch_to_preview_mode: 'Switch to Preview Mode',
            preview_the_platform: 'Preview the Platform',
            end_preview_mode: 'End Preview Mode',
            put_wood: '1. Put the sample wood',
            adjust_focus: '2. Adjust the focus',
            close_cover: '3. Close the cover',
            send_the_file: 'Send the File',
            end_alert: 'Are you sure to end tutorial?',
            please_select_wood_engraving: 'Kindly select "Wood - Engraving" Preset.',
            please_select_wood_cutting: 'Kindly select "Wood - Cutting" Preset.',
        },
        newInterface: {
            camera_preview: 'Camera Preview',
            select_image_text: 'Select / Image / Text',
            basic_shapes: 'Basic Shapes',
            pen_tool: 'Pen Tool',
            add_new_layer: 'Add New Layer',
            rename_by_double_click: 'Rename by double click',
            drag_to_sort: 'Drag to sort',
            layer_controls: 'Right Click to select Layer Controls:\nDuplicate / Merge / Lock / Delete Layers',
            switch_between_layer_panel_and_object_panel: 'Switch between Layer Panel and Object Panel',
            align_controls: 'Align Controls',
            group_controls: 'Group Controls',
            shape_operation: 'Shape Operation',
            flip: 'Flip',
            object_actions: 'Object Actions',
            end_alert: 'Are you sure to end new UI introduction?',
            select_machine: 'Select a machine',
            start_work: 'Start Work',
        },
        gesture: {
            pan: 'Scroll the canvas with two fingers.',
            zoom: 'Pinch with two fingers to zoom in/out the canvas.',
            click: 'Tap to select the object.',
            drag: 'Drag to select the multiple objects.',
            hold: 'Press and hold to open the context menu.',
        },
        links: {
            adjust_focus: 'https://flux3dp.zendesk.com/hc/en-us/articles/360001684196',
        },
        tutorial_complete: "That's all for the tutorial. Now it's time to create!",
    },
    layer_module: {
        none: 'None',
        general_laser: 'Laser',
        laser_10w_diode: '10W Diode Laser',
        laser_20w_diode: '20W Diode Laser',
        laser_2w_infrared: '2W Infrared Laser',
        printing: 'Printing',
        unknown: 'Unknown Module',
        notification: {
            convertFromPrintingModuleTitle: 'Do you want to convert the Printing module into Laser module?',
            convertFromPrintingModuleMsg: 'Please be aware that if you complete this operation, the color settings of the printing layer will be removed and set according to the current layer.',
            convertFromLaserModuleTitle: 'Do you want to convert the Laser module into Printing module?',
            convertFromLaserModuleMsg: 'Please be aware that if you complete this operation, the settings of the laser layer will be removed and set according to the current layer.',
            importedDocumentContainsPrinting: 'The document contains printing layer, would you like to change workarea to Ador?',
            printingLayersCoverted: 'The printing layers have been converted into laser layers.',
            performPrintingCaliTitle: 'Perform the printing module calibration',
            performPrintingCaliMsg: 'Click “Confirm” to execute the calibration, or access calibration through the top menu.<br />(Machine > [Your Machine Name] >  Calibrate Printing Module)',
            performIRCaliTitle: 'Perform the Infrared module calibration',
            performIRCaliMsg: 'Click “Confirm” to execute the calibration, or access calibration through the top menu.<br />(Machine > [Your Machine Name] >  Calibrate Infrared Module)',
        },
        non_working_area: 'Non-Working Area',
    },
    qr_code_generator: {
        title: 'QR Code Generator',
        placeholder: 'Input a link or text',
        preview: 'Preview',
        error_tolerance: 'Error Tolerance',
        error_tolerance_link: 'https://support.flux3dp.com/hc/en-us/articles/9113705072143',
        invert: 'Invert background color',
    },
    boxgen: {
        title: 'BOXGEN',
        basic_box: 'Basic Box',
        coming_soon: 'Coming Soon',
        workarea: 'Workarea',
        max_dimension_tooltip: 'Max width/height/depth setting is %s. ',
        volume: 'Volume',
        outer: 'Outer',
        inner: 'Inner',
        width: 'Width',
        height: 'Height',
        depth: 'Depth',
        cover: 'Cover',
        thickness: 'Thickness',
        add_option: 'Add Option',
        joints: 'Joint',
        finger: 'Finger',
        finger_warning: 'The internal side length of the box need to be at least 6mm (0.24 inches)  to be compatible with Finger joining.',
        tSlot: 'T-Slot',
        tSlot_warning: 'The box side length needs to be at least 30mm (1.18 inches) to be compatible with T-slot joining.',
        edge: 'Edge',
        tCount: 'T Count',
        tCount_tooltip: 'The T slot count applies to the short side; the quantity on the long side is calculated based on its length.',
        tDiameter: 'T Diameter',
        tLength: 'T Length',
        continue_import: 'Continue to Import',
        customize: 'Customize',
        merge: 'Merge',
        text_label: 'Label',
        beam_radius: 'Kerf compensation',
        beam_radius_warning: 'Remove Kerf compensation when the edges or joints of the box are short to ensure box assembly',
        import: 'Import',
        cancel: 'Cancel',
        reset: 'Reset',
        zoom: 'Zoom',
        control_tooltip: 'Left mouse to rotate\nScroll to zoom\nRight mouse to pan',
        control_tooltip_touch: 'Drag to rotate\nPinch to zoom\nTwo-finger to pan',
    },
    my_cloud: {
        title: 'My Cloud',
        loading_file: 'Loading...',
        no_file_title: 'Save files to My Cloud to get started.',
        no_file_subtitle: 'Go to Menu > "File" > "Save to Cloud"',
        file_limit: 'Free file',
        upgrade: 'Upgrade',
        sort: {
            most_recent: 'Most Recent',
            oldest: 'Oldest',
            a_to_z: 'Name: A - Z',
            z_to_a: 'Name: Z - A',
        },
        action: {
            open: 'Open',
            rename: 'Rename',
            duplicate: 'Duplicate',
            download: 'Download',
            delete: 'Delete',
            confirmFileDelete: 'Are you sure you want to delete this file? This action cannot be undone.',
        },
        save_file: {
            choose_action: 'Save File:',
            save: 'Save',
            save_new: 'Save as new file',
            input_file_name: 'Input file name:',
            invalid_char: 'Invalid characters:',
            storage_limit_exceeded: 'Your cloud storage has reached upper limit. Please delete any unnecessary files before saving new ones.',
        },
    },
    camera_data_backup: {
        title: 'Camera Data Backup',
        no_picture_found: 'No picture found in the machine.',
        folder_not_exists: 'Selected folder does not exist.',
        incorrect_folder: 'Failed to upload of calibration data. Please check if the folder you selected is correct.',
        downloading_data: 'Downloading data...',
        estimated_time_left: 'Estimated time left:',
        uploading_data: 'Uploading data...',
        download_success: 'Successfully downloaded camera data.',
        upload_success: 'Successfully uploaded camera data.',
    },
    insecure_websocket: {
        extension_detected: 'Beam Studio Connect Extension Detected',
        extension_detected_description: 'We have detected that you have installed the Beam Studio Connect extension. Please click ‘Confirm’ to redirect to HTTPS, or click ‘Cancel’ to continue using HTTP.',
        extension_not_deteced: 'Unable to Detect Beam Studio Connect Extension',
        extension_not_deteced_description: 'To use HTTPS, please click ‘Confirm’ to install the Beam Studio Connect extension. After installing the extension, please refresh the page to activate it.<br/>Otherwise, click the link below to see how to use HTTP on Chrome.',
        unsecure_url_help_center_link: '<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/9935859456271">Link</a>',
    },
    curve_engraving: {
        measure_audofocus_area: 'Measure Autofocus Area',
        amount: 'Amount',
        gap: 'Gap',
        rows: 'Rows',
        coloumns: 'Columns',
        row_gap: 'Row Gap',
        column_gap: 'Column Gap',
        set_object_height: 'Set Object Height',
        set_object_height_desc: 'Measure the max thickness of the object.',
        measure_area_help: 'How to select the autofocus area for 3D Curve?',
        reselect_area: 'Re-select Area',
        start_autofocus: 'Start Autofocus',
        starting_measurement: 'Starting Measurement...',
        preview_3d_curve: 'Preview 3D Curve',
        apply_arkwork: 'Apply Artwork on 3D Curve',
        apply_camera: 'Apply Camera Image on 3D Curve',
        click_to_select_point: 'Click to select or unselect points to measure again.',
        remeasure: 'Re-measure',
        remeasuring_points: 'Remeasuring Points...',
    },
    pass_through: {
        title: 'Pass Through Mode',
        help_text: 'How to setup Pass Through for Ador?',
        help_link: 'https://support.flux3dp.com/hc/en-us/articles/10140002160399',
        object_length: 'Object Length',
        workarea_height: 'Work Area (Height):',
        height_desc: "Set each work area section's height.",
        ref_layer: 'Reference Layer',
        ref_layer_desc: 'Please note that the execution of the reference layer is set to 0 by default. It will not be executed but is only for alignment reference.',
        ref_layer_name: 'Reference',
        guide_mark: 'Guide Mark',
        guide_mark_length: 'Diameter:',
        guide_mark_x: 'X Coordinate:',
        guide_mark_desc: 'Guide marks will be engraved as a reference point to align the artwork.',
        export: 'Export to Work Area',
        exporting: 'Exporting...',
    },
    auto_fit: {
        title: 'Auto Fit',
        step1: '1. Place the object in the desired position on one piece of material.',
        step2: '2. Press "Confirm" to duplicate the object onto all similar pieces of material in camera preview.',
        preview_first: 'Please perform the camera preview first.',
        failed_to_auto_fit: 'Failed to auto fit, please check:',
        error_tip1: '1. Is the artwork placed on the material correctly?',
        error_tip2: '2. Are the material contours clear enough for recognition?',
        learn_more: 'Learn how auto fit works.',
        learn_more_url: 'https://support.flux3dp.com/hc/en-us/articles/10273384373775',
    },
    rotary_settings: {
        type: 'Type',
        object_diameter: 'Object Diameter',
        circumference: 'Circumference',
    },
    framing: {
        low_laser: 'Low Laser',
        low_laser_desc: 'Set a low-power laser value for the framing task.',
        framing: 'Framing',
        hull: 'Hull',
        area_check: 'Area Check',
        framing_desc: "Previews the object's bounding box.",
        hull_desc: 'Previews a shape that closely follows the design, like a rubber band wrapped around the object.',
        areacheck_desc: "Ensures the work area is safe by previewing the object's bounding box and the laser head's acceleration zone.",
        calculating_task: 'Calculating task...',
    },
    material_test_generator: {
        title: 'Material Test Generator',
        table_settings: 'Table Settings',
        block_settings: 'Block Settings',
        text_settings: 'Text Settings',
        preview: 'Preview',
        export: 'Export',
        cut: 'Cut',
        engrave: 'Engrave',
        columns: 'Columns',
        rows: 'Rows',
        // table setting form
        parameter: 'Parameter',
        min: 'Min',
        max: 'Max',
        // block setting form
        count: 'Count',
        size: 'Size (HxW)',
        spacing: 'Spacing',
    },
    web_cam: {
        no_permission: 'Beam Studio does not have permission to access the camera. Please make sure the permission is granted to Beam Studio in system settings or browser settings.',
        no_device: 'Unable to detect camera device. Please reconnect the camera and try again.',
    },
};
export default lang;
