import updateLayerColor from 'helpers/color/updateLayerColor';
import { getSVGAsync } from 'helpers/svg-editor-helper';
let svgCanvas;
getSVGAsync((globalSVG) => {
    svgCanvas = globalSVG.Canvas;
});
const toggleFullColorLayer = (layer, opts = {}) => {
    const { val } = opts;
    const origVal = layer.getAttribute('data-fullcolor') === '1';
    const targetVal = val !== null && val !== void 0 ? val : !origVal;
    console.log('Toggle Full Color Layer', layer, 'from', origVal, 'to', targetVal);
    if (targetVal === origVal)
        return null;
    svgCanvas.undoMgr.beginUndoableChange('data-fullcolor', [layer]);
    if (targetVal)
        layer.setAttribute('data-fullcolor', '1');
    else
        layer.removeAttribute('data-fullcolor');
    const cmd = svgCanvas.undoMgr.finishUndoableChange();
    updateLayerColor(layer);
    cmd.onAfter = () => updateLayerColor(layer);
    return cmd;
};
export default toggleFullColorLayer;
