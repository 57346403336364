import * as React from 'react';
import classNames from 'classnames';
import shortcuts from '../../helpers/shortcuts';
class View extends React.Component {
    constructor() {
        super(...arguments);
        this.onOpen = () => {
            const { onOpen = () => { } } = this.props;
            if (onOpen) {
                onOpen(this);
            }
        };
        this.onEscapeOnBackground = (e) => {
            const { disabledEscapeOnBackground = false, onClose = () => { } } = this.props;
            if (!disabledEscapeOnBackground) {
                onClose(e);
            }
        };
    }
    componentDidMount() {
        const { disabledEscapeOnBackground = false, onClose = () => { }, } = this.props;
        this.onOpen();
        shortcuts.on(['esc'], (e) => {
            if (!disabledEscapeOnBackground) {
                onClose(e);
            }
        });
    }
    componentWillUnmount() {
        shortcuts.off(['esc']);
        if (window['svgEditor']) {
            shortcuts.on(['esc'], window['svgEditor'].clickSelect);
        }
    }
    render() {
        const { className = {}, children, content = React.createElement("div", null) } = this.props;
        className['modal-window'] = true;
        return (React.createElement("div", { className: classNames(className) },
            React.createElement("div", { className: "modal-background", onClick: this.onEscapeOnBackground }),
            React.createElement("div", { className: "modal-body" }, children || content)));
    }
}
export default View;
