import alertConstants from 'app/constants/alert-constants';
import eventEmitterFactory from 'helpers/eventEmitterFactory';
const eventEmitter = eventEmitterFactory.createEventEmitter('alert-progress');
const popUp = (args) => {
    if (!eventEmitter.listenerCount('POP_UP')) {
        setTimeout(() => popUp(args), 100);
    }
    eventEmitter.emit('POP_UP', args);
};
export default {
    popUp,
    popUpError: (args) => {
        popUp(Object.assign(Object.assign({}, args), { type: alertConstants.SHOW_POPUP_ERROR }));
    },
    popById: (id) => {
        eventEmitter.emit('POP_BY_ID', id);
    },
    checkIdExist: (id) => {
        const response = {
            idExist: false,
        };
        eventEmitter.emit('CHECK_ID_EXIST', id, response);
        return response.idExist;
    },
};
