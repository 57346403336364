import classNames from 'classnames';
import React, { useContext } from 'react';
import { FullWindowPanelContext } from 'app/widgets/FullWindowPanel/FullWindowPanel';
import styles from './Header.module.scss';
const Header = ({ icon, title, className = '', children }) => {
    // For future need to use context
    useContext(FullWindowPanelContext);
    return (React.createElement("div", { className: classNames(styles.header, className) },
        icon && React.createElement("div", { className: styles.icon }, icon),
        title && React.createElement("div", { className: styles.title }, title),
        children));
};
export default Header;
