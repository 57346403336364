var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { createContext, useState } from 'react';
import DeviceMaster from 'helpers/device-master';
import PreviewModeController from 'app/actions/beambox/preview-mode-controller';
import storage from 'implementations/storage';
import versionChecker from 'helpers/version-checker';
import { DEFAULT_CAMERA_OFFSET, STEP_ASK_READJUST, STEP_PUT_PAPER, } from 'app/constants/camera-calibration-constants';
export const CalibrationContext = createContext({
    borderless: false,
    cameraPosition: { x: 0, y: 0 },
    setCameraPosition: () => { },
    calibratedMachines: [],
    setCalibratedMachines: () => { },
    currentOffset: null,
    setCurrentOffset: () => { },
    currentStep: STEP_PUT_PAPER,
    setCurrentStep: () => { },
    lastConfig: null,
    setLastConfig: () => { },
    gotoNextStep: () => { },
    onClose: () => { },
    imgBlobUrl: '',
    setImgBlobUrl: () => { },
    originFanSpeed: 100,
    setOriginFanSpeed: () => { },
    device: null,
    unit: 'mm',
});
let calibratedMachines = [];
export function CalibrationProvider({ children, borderless, device, onClose, }) {
    const didCalibrate = calibratedMachines.includes(device.uuid);
    const initStep = didCalibrate ? STEP_ASK_READJUST : STEP_PUT_PAPER;
    const [currentStep, setCurrentStep] = useState(initStep);
    const [currentOffset, setCurrentOffset] = useState(DEFAULT_CAMERA_OFFSET);
    const [lastConfig, setLastConfig] = useState(DEFAULT_CAMERA_OFFSET);
    const setCalibratedMachines = (newList) => {
        calibratedMachines = newList;
    };
    const [imgBlobUrl, setImgBlobUrl] = useState('');
    const [cameraPosition, setCameraPosition] = useState({ x: 0, y: 0 });
    const [originFanSpeed, setOriginFanSpeed] = useState(1000);
    const unit = storage.get('default-units') || 'mm';
    const wrappedOnClose = (completed) => __awaiter(this, void 0, void 0, function* () {
        onClose(completed);
        yield PreviewModeController.end();
        const tempCmdAvailable = versionChecker(device === null || device === void 0 ? void 0 : device.version).meetRequirement('TEMP_I2C_CMD');
        if (originFanSpeed && !tempCmdAvailable) {
            yield DeviceMaster.setFan(originFanSpeed);
        }
    });
    return (React.createElement(CalibrationContext.Provider, { value: {
            borderless,
            device,
            currentStep,
            setCurrentStep,
            cameraPosition,
            setCameraPosition,
            currentOffset,
            setCurrentOffset,
            lastConfig,
            setLastConfig,
            calibratedMachines,
            setCalibratedMachines,
            imgBlobUrl,
            setImgBlobUrl,
            originFanSpeed,
            setOriginFanSpeed,
            gotoNextStep: setCurrentStep,
            unit,
            onClose: wrappedOnClose,
        } }, children));
}
