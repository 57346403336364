import Symmetry from './symmetry.svg';
import Corner from './corner.svg';
import Smooth from './smooth.svg';
import Connect from './connect.svg';
import Disconnect from './disconnect.svg';
import Sharp from './sharp.svg';
import Round from './round.svg';
export default {
    Symmetry,
    Sharp,
    Round,
    Connect,
    Disconnect,
    Corner,
    Smooth,
};
