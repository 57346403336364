import React, { useContext, useEffect } from 'react';
import { Modal } from 'antd';
import { SpinLoading } from 'antd-mobile';
import useI18n from 'helpers/useI18n';
import { AlertProgressContext } from 'app/contexts/AlertProgressContext';
import styles from './AlertAndProgress.module.scss';
const NonStopProgress = ({ data }) => {
    const lang = useI18n();
    const { popById } = useContext(AlertProgressContext);
    const { key, id, caption, timeout, onCancel } = data;
    useEffect(() => {
        if (timeout)
            setTimeout(() => popById(id), timeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (React.createElement(Modal, { open: true, className: styles.nonstop, key: `${key}-${id}`, style: {
            minWidth: 150,
        }, width: "fit-content", onCancel: () => {
            popById(id);
            onCancel();
        }, centered: true, closable: false, maskClosable: false, cancelText: lang.alert.cancel, cancelButtonProps: onCancel ? undefined : { style: { display: 'none' } }, okButtonProps: { style: { display: 'none' } } },
        React.createElement("div", null,
            React.createElement("div", { className: styles['spinner-container'] },
                React.createElement(SpinLoading, { color: "primary", style: { '--size': '48px' } })),
            React.createElement("div", { className: styles.caption }, caption))));
};
export default NonStopProgress;
