var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import alertCaller from 'app/actions/alert-caller';
import CameraTransformAPI from 'helpers/api/camera-transform';
import i18n from 'helpers/i18n';
import MessageCaller, { MessageLevel } from 'app/actions/message-caller';
import PreviewModeBackgroundDrawer from 'app/actions/beambox/preview-mode-background-drawer';
import progressCaller from 'app/actions/progress-caller';
import promarkDataStore from 'helpers/device/promark-data-store';
import webcamHelper from 'helpers/webcam-helper';
import workareaManager from 'app/svgedit/workarea';
import { getWorkarea } from 'app/constants/workarea-constants';
import BasePreviewManager from './BasePreviewManager';
// TODO: Add tests
class PromarkPreviewManager extends BasePreviewManager {
    constructor(device) {
        super(device);
        this.isFullScreen = true;
        this.setup = (args) => __awaiter(this, void 0, void 0, function* () {
            const { lang } = i18n;
            const { progressId } = args || {};
            if (progressId)
                this.progressId = progressId;
            try {
                progressCaller.openNonstopProgress({
                    id: this.progressId,
                    message: lang.message.connectingCamera,
                });
                this.fisheyeParams = promarkDataStore.get(this.device.serial, 'cameraParameters');
                if (!this.fisheyeParams) {
                    throw new Error('Unable to get fisheye parameters, please make sure you have calibrated the camera');
                }
                yield this.setupAPI();
                this.webCamConnection = yield webcamHelper.connectWebcam();
                return !!this.webCamConnection;
            }
            catch (error) {
                console.error(error);
                if (error.message && error.message.startsWith('Camera WS')) {
                    alertCaller.popUpError({
                        message: `${lang.topbar.alerts.fail_to_connect_with_camera}<br/>${error.message || ''}`,
                    });
                }
                else {
                    alertCaller.popUpError({
                        message: `${lang.topbar.alerts.fail_to_start_preview}<br/>${error.message || ''}`,
                    });
                }
                return false;
            }
            finally {
                progressCaller.popById(this.progressId);
            }
        });
        this.setupAPI = () => __awaiter(this, void 0, void 0, function* () {
            this.cameraTransformAPI = new CameraTransformAPI();
            let res = yield this.cameraTransformAPI.setFisheyeParam(this.fisheyeParams);
            if (!res) {
                throw new Error('Failed to set fisheye parameters');
            }
            const workarea = getWorkarea(workareaManager.model);
            const { width, height } = workarea;
            res = yield this.cameraTransformAPI.setFisheyeGrid({ x: [0, width, 10], y: [0, height, 10] });
            if (!res) {
                throw new Error('Failed to set fisheye grid');
            }
        });
        this.end = () => __awaiter(this, void 0, void 0, function* () {
            var _a, _b;
            (_a = this.webCamConnection) === null || _a === void 0 ? void 0 : _a.end();
            (_b = this.cameraTransformAPI) === null || _b === void 0 ? void 0 : _b.close();
        });
        this.preview = () => __awaiter(this, void 0, void 0, function* () {
            try {
                MessageCaller.openMessage({
                    key: 'full-area-preview',
                    content: i18n.lang.topbar.preview,
                    level: MessageLevel.LOADING,
                    duration: 20,
                });
                let imgBlob = yield this.webCamConnection.getPicture();
                imgBlob = yield this.cameraTransformAPI.transformImage(imgBlob);
                const imgUrl = URL.createObjectURL(imgBlob);
                yield new Promise((resolve) => {
                    PreviewModeBackgroundDrawer.drawFullWorkarea(imgUrl, resolve);
                });
                URL.revokeObjectURL(imgUrl);
                MessageCaller.openMessage({
                    key: 'full-area-preview',
                    level: MessageLevel.SUCCESS,
                    content: 'Successfully previewed',
                    duration: 3,
                });
                return true;
            }
            catch (error) {
                MessageCaller.closeMessage('full-area-preview');
                throw error;
            }
        });
        this.previewRegion = () => __awaiter(this, void 0, void 0, function* () {
            const res = yield this.preview();
            return res;
        });
        this.previewFullWorkarea = () => __awaiter(this, void 0, void 0, function* () {
            const res = yield this.preview();
            return res;
        });
        this.progressId = 'promark-preview-manager';
    }
}
export default PromarkPreviewManager;
