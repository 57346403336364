var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import classNames from 'classnames';
import React, { useContext, useState } from 'react';
import { Button, Switch } from 'antd';
import { Popover } from 'antd-mobile';
import alertCaller from 'app/actions/alert-caller';
import alertConstants from 'app/constants/alert-constants';
import colorConstants from 'app/constants/color-constants';
import colorPickerStyles from 'app/widgets/ColorPicker.module.scss';
import LayerModule, { modelsWithModules } from 'app/constants/layer-module/layer-modules';
import LayerPanelIcons from 'app/icons/layer-panel/LayerPanelIcons';
import ObjectPanelIcons from 'app/icons/object-panel/ObjectPanelIcons';
import ObjectPanelItem from 'app/views/beambox/Right-Panels/ObjectPanelItem';
import presprayArea from 'app/actions/canvas/prespray-area';
import splitFullColorLayer from 'helpers/layer/full-color/splitFullColorLayer';
import toggleFullColorLayer from 'helpers/layer/full-color/toggleFullColorLayer';
import updateLayerColor from 'helpers/color/updateLayerColor';
import useI18n from 'helpers/useI18n';
import useWorkarea from 'helpers/hooks/useWorkarea';
import { ContextMenu, MenuItem } from 'helpers/react-contextmenu';
import { cloneLayers, deleteLayers, getAllLayerNames, getLayerElementByName, getLayerPosition, mergeLayers, setLayersLock, } from 'helpers/layer/layer-helper';
import { getData } from 'helpers/layer/layer-config-helper';
import { getSVGAsync } from 'helpers/svg-editor-helper';
import { LayerPanelContext } from 'app/views/beambox/Right-Panels/contexts/LayerPanelContext';
import { ObjectPanelContext } from 'app/views/beambox/Right-Panels/contexts/ObjectPanelContext';
import { useIsMobile } from 'helpers/system-helper';
import styles from './LayerContextMenu.module.scss';
let svgCanvas;
getSVGAsync((globalSVG) => {
    svgCanvas = globalSVG.Canvas;
});
const LayerContextMenu = ({ drawing, selectOnlyLayer, renameLayer }) => {
    const lang = useI18n();
    const LANG = lang.beambox.right_panel.layer_panel;
    const LANG2 = lang.alert;
    const workarea = useWorkarea();
    const { selectedLayers, setSelectedLayers, forceUpdate } = useContext(LayerPanelContext);
    const isMobile = useIsMobile();
    const { activeKey, updateActiveKey } = useContext(ObjectPanelContext);
    const [color, setColor] = useState(colorConstants.printingLayerColor[0]);
    const layerElem = getLayerElementByName(selectedLayers[0]);
    const isLocked = (layerElem === null || layerElem === void 0 ? void 0 : layerElem.getAttribute('data-lock')) === 'true';
    const onContextMenuShow = (e) => {
        var _a;
        const trigger = (_a = e.detail.data) === null || _a === void 0 ? void 0 : _a.target;
        const layerItem = trigger === null || trigger === void 0 ? void 0 : trigger.closest('.layer-item');
        const layerName = layerItem === null || layerItem === void 0 ? void 0 : layerItem.getAttribute('data-layer');
        if (layerName && !selectedLayers.includes(layerName)) {
            selectOnlyLayer(layerName);
        }
    };
    const handleRename = () => {
        selectOnlyLayer(selectedLayers[0]);
        renameLayer();
    };
    const handleCloneLayers = () => {
        const newLayers = cloneLayers(selectedLayers);
        setSelectedLayers(newLayers);
    };
    const handleDeleteLayers = () => {
        deleteLayers(selectedLayers);
        setSelectedLayers([]);
        presprayArea.togglePresprayArea();
    };
    const toggleLayerLocked = () => {
        svgCanvas.clearSelection();
        setLayersLock(selectedLayers, !isLocked);
        forceUpdate();
    };
    const handleMergeDown = () => __awaiter(void 0, void 0, void 0, function* () {
        const layer = selectedLayers[0];
        const layerPosition = getLayerPosition(layer);
        if (layerPosition === 0)
            return;
        const baseLayerName = drawing.getLayerName(layerPosition - 1);
        const merged = yield mergeLayers([layer], baseLayerName);
        if (merged)
            selectOnlyLayer(baseLayerName);
    });
    const handleMergeAll = () => __awaiter(void 0, void 0, void 0, function* () {
        const allLayerNames = getAllLayerNames();
        const baseLayerName = yield mergeLayers(allLayerNames);
        if (!baseLayerName)
            return;
        const elem = getLayerElementByName(baseLayerName);
        updateLayerColor(elem);
        selectOnlyLayer(baseLayerName);
    });
    const handleMergeSelected = () => __awaiter(void 0, void 0, void 0, function* () {
        const currentLayerName = drawing.getCurrentLayerName();
        const baseLayer = yield mergeLayers(selectedLayers, currentLayerName);
        if (!baseLayer)
            return;
        const elem = getLayerElementByName(baseLayer);
        updateLayerColor(elem);
        setSelectedLayers([baseLayer]);
    });
    const isSelectingPrinterLayer = selectedLayers.length === 1 &&
        layerElem &&
        modelsWithModules.has(workarea) &&
        getData(layerElem, 'module') === LayerModule.PRINTER;
    const isFullColor = getData(layerElem, 'fullcolor');
    const isSplitLayer = getData(layerElem, 'split');
    const handleSplitColor = () => __awaiter(void 0, void 0, void 0, function* () {
        svgCanvas.clearSelection();
        if (!isSelectingPrinterLayer || isSplitLayer)
            return;
        const res = yield new Promise((resolve) => {
            alertCaller.popUp({
                id: 'split-color',
                caption: LANG.notification.splitColorTitle,
                message: LANG.notification.splitColorMsg,
                messageIcon: 'notice',
                buttonType: alertConstants.CONFIRM_CANCEL,
                onConfirm: () => resolve(true),
                onCancel: () => resolve(false),
            });
        });
        if (!res)
            return;
        const layer = selectedLayers[0];
        yield splitFullColorLayer(layer);
        setSelectedLayers([]);
    });
    const handleLayerFullColor = (newColor) => {
        svgCanvas.clearSelection();
        if (!isSelectingPrinterLayer)
            return;
        if (isFullColor &&
            (newColor ||
                !colorConstants.printingLayerColor.includes(layerElem.getAttribute('data-color')))) {
            layerElem.setAttribute('data-color', newColor || colorConstants.printingLayerColor[0]);
        }
        const cmd = toggleFullColorLayer(layerElem);
        if (cmd && !cmd.isEmpty())
            svgCanvas.undoMgr.addCommandToHistory(cmd);
        setSelectedLayers([]);
    };
    const isMultiSelecting = selectedLayers.length > 1;
    const isSelectingLast = selectedLayers.length === 1 && drawing.getLayerName(0) === selectedLayers[0];
    return isMobile ? (React.createElement("div", { className: styles['item-group'] },
        React.createElement(ObjectPanelItem.Divider, null),
        isSelectingPrinterLayer && (React.createElement(ObjectPanelItem.Item, { id: "split_color", content: React.createElement(LayerPanelIcons.Expand, null), label: LANG.layers.splitFullColor, onClick: handleSplitColor, disabled: isMultiSelecting || !isFullColor })),
        isSelectingPrinterLayer && (React.createElement(Popover, { visible: activeKey === 'toggle_fullcolor_layer' && isFullColor, content: React.createElement(React.Fragment, null,
                React.createElement("div", { className: colorPickerStyles.preset }, colorConstants.printingLayerColor.map((preset) => (React.createElement("div", { key: preset, className: classNames(colorPickerStyles['preset-block'], colorPickerStyles.color, colorPickerStyles.printing, { [colorPickerStyles.checked]: preset === color }), onClick: () => setColor(preset) },
                    React.createElement("div", { className: colorPickerStyles.inner, style: { backgroundColor: preset } }))))),
                React.createElement("div", { className: colorPickerStyles.footer },
                    React.createElement(Button, { type: "primary", className: colorPickerStyles.btn, onClick: () => {
                            updateActiveKey(null);
                            handleLayerFullColor(color);
                            setColor(colorConstants.printingLayerColor[0]);
                        } }, LANG2.ok),
                    React.createElement(Button, { type: "default", className: colorPickerStyles.btn, onClick: () => {
                            updateActiveKey(null);
                            setColor(colorConstants.printingLayerColor[0]);
                        } }, LANG2.cancel))) },
            React.createElement(ObjectPanelItem.Item, { id: "toggle_fullcolor_layer", content: React.createElement(Switch, { checked: isFullColor }), label: LANG.layers.fullColor, onClick: () => {
                    if (!isFullColor && !isSplitLayer) {
                        handleLayerFullColor();
                        updateActiveKey(null);
                    }
                }, disabled: isMultiSelecting || isSplitLayer, autoClose: false }))),
        React.createElement(ObjectPanelItem.Item, { id: "deletelayer", content: React.createElement(ObjectPanelIcons.Trash, null), label: LANG.layers.del, onClick: handleDeleteLayers }),
        React.createElement(ObjectPanelItem.Item, { id: "merge_down_layer", content: React.createElement(LayerPanelIcons.Merge, null), label: LANG.layers.merge_down, onClick: handleMergeDown, disabled: isMultiSelecting || isSelectingLast }),
        React.createElement(ObjectPanelItem.Item, { id: "locklayer", content: isLocked ? React.createElement(LayerPanelIcons.Unlock, null) : React.createElement(LayerPanelIcons.Lock, null), label: isLocked ? LANG.layers.unlock : LANG.layers.lock, onClick: toggleLayerLocked }),
        React.createElement(ObjectPanelItem.Item, { id: "dupelayer", content: React.createElement(ObjectPanelIcons.Duplicate, null), label: LANG.layers.dupe, onClick: handleCloneLayers }),
        React.createElement(ObjectPanelItem.Item, { id: "renameLayer", content: React.createElement(LayerPanelIcons.Rename, null), label: LANG.layers.rename, onClick: handleRename, disabled: isMultiSelecting }))) : (React.createElement(ContextMenu, { id: "layer-contextmenu", onShow: onContextMenuShow },
        React.createElement(MenuItem, { attributes: { id: 'renameLayer' }, disabled: isMultiSelecting, onClick: handleRename }, LANG.layers.rename),
        React.createElement(MenuItem, { attributes: { id: 'dupelayer' }, onClick: handleCloneLayers }, LANG.layers.dupe),
        React.createElement(MenuItem, { attributes: { id: 'locklayer' }, onClick: toggleLayerLocked }, isLocked ? LANG.layers.unlock : LANG.layers.lock),
        React.createElement(MenuItem, { attributes: { id: 'deletelayer' }, onClick: handleDeleteLayers }, LANG.layers.del),
        React.createElement(MenuItem, { attributes: { id: 'merge_down_layer' }, disabled: isMultiSelecting || isSelectingLast, onClick: handleMergeDown }, LANG.layers.merge_down),
        React.createElement(MenuItem, { attributes: { id: 'merge_all_layer' }, disabled: isMultiSelecting, onClick: handleMergeAll }, LANG.layers.merge_all),
        React.createElement(MenuItem, { attributes: { id: 'merge_selected_layer' }, disabled: !isMultiSelecting, onClick: handleMergeSelected }, LANG.layers.merge_selected),
        isSelectingPrinterLayer && (React.createElement(React.Fragment, null,
            React.createElement(MenuItem, { attributes: { id: 'toggle_fullcolor_layer' }, disabled: isMultiSelecting || isSplitLayer, onClick: () => handleLayerFullColor() }, isFullColor ? LANG.layers.switchToSingleColor : LANG.layers.switchToFullColor),
            React.createElement(MenuItem, { attributes: { id: 'split_color' }, disabled: isMultiSelecting || !isFullColor, onClick: handleSplitColor }, LANG.layers.splitFullColor)))));
};
export default LayerContextMenu;
