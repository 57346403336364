// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-views-beambox-ShapePanel-ShapeIcon-module__icon--X8g2W{background-color:#f0f0f0;font-size:44px;border-radius:4px;border:2px solid #f0f0f0}.src-web-app-views-beambox-ShapePanel-ShapeIcon-module__icon--X8g2W:hover{border-color:#3f8ff7}@media screen and (max-width: 600px){.src-web-app-views-beambox-ShapePanel-ShapeIcon-module__icon--X8g2W{border-radius:7px;border:none}}", "",{"version":3,"sources":["webpack://./src/web/app/views/beambox/ShapePanel/ShapeIcon.module.scss","webpack://./src/web/styles/_variables.scss"],"names":[],"mappings":"AAEA,oEAEE,wBCDgB,CDEhB,cAAA,CACA,iBAAA,CACA,wBAAA,CACA,0EACE,oBAAA,CAEF,qCATF,oEAUI,iBAAA,CACA,WAAA,CAAA","sourcesContent":["@use 'styles/variables' as variables;\n\n.icon {\n  // total size = font-size + border-width * 2\n  background-color: variables.$backgroundColor;\n  font-size: 44px;\n  border-radius: 4px;\n  border: 2px solid variables.$backgroundColor;\n  &:hover {\n    border-color: #3f8ff7;\n  }\n  @media screen and (max-width: 600px) {\n    border-radius: 7px;\n    border: none;\n  }\n}\n","$print-default-font-color: #4A4A4A;\n$default-button-border-radius: 4px;\n$size: 200px;\n$backgroundColor: #F0F0F0;\n$sidePanelWidth: 242px;\n$winSidePanelWidth: 258px;\n$panelBorderColor: #E0E0E0;\n$topBarHeight: 40px;\n\n$primary-blue: #1890ff;\n$primary-gray: #1e1e1e;\n$secondary-gray: #7c7c7c;\n$s3: 'https://beam-studio-web.s3.ap-northeast-1.amazonaws.com';\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "src-web-app-views-beambox-ShapePanel-ShapeIcon-module__icon--X8g2W"
};
export default ___CSS_LOADER_EXPORT___;
