var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import deviceMaster from 'helpers/device-master';
import { getWorkarea } from 'app/constants/workarea-constants';
const getAutoFocusPosition = (device) => __awaiter(void 0, void 0, void 0, function* () {
    const workarea = getWorkarea(device.model, 'ado1');
    const { width, height } = workarea;
    const lastPosition = yield deviceMaster.rawGetLastPos();
    const { x, y } = lastPosition;
    let xIndex = 0;
    if (x > width * (2 / 3))
        xIndex = 2;
    else if (x > width * (1 / 3))
        xIndex = 1;
    let yIndex = 0;
    if (y > height * (2 / 3))
        yIndex = 2;
    else if (y > height * (1 / 3))
        yIndex = 1;
    const refKey = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'][yIndex * 3 + xIndex];
    console.log('Probe position', lastPosition, 'refKey', refKey);
    return refKey;
});
export default getAutoFocusPosition;
