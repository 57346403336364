/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react';
import useI18n from 'helpers/useI18n';
import { Button, Flex, Radio } from 'antd';
import { createLayer } from 'helpers/layer/layer-helper';
import svgEditor from 'app/actions/beambox/svg-editor';
import { writeDataLayer } from 'helpers/layer/layer-config-helper';
import constant from 'app/actions/beambox/constant';
import { getSVGAsync } from 'helpers/svg-editor-helper';
import updateElementColor from 'helpers/color/updateElementColor';
import history from 'app/svgedit/history/history';
import LayerPanelController from 'app/views/beambox/Right-Panels/contexts/LayerPanelController';
import storage from 'implementations/storage';
import createNewText from 'app/svgedit/text/createNewText';
import undoManager from 'app/svgedit/history/undoManager';
import workareaManager from 'app/svgedit/workarea';
import DraggableModal from 'app/widgets/DraggableModal';
import styles from './index.module.scss';
import WorkAreaInfo from './WorkAreaInfo';
import TableSettingForm from './TableSettingForm';
import BlockSettingForm from './BlockSettingForm';
import { tableSetting as defaultTableSetting } from './TableSetting';
import { textSetting as defaultTextSetting } from './TextSetting';
import { blockSetting as defaultBlockSetting } from './BlockSetting';
import generateSvgInfo from './generateSvgInfo';
import TextSettingForm from './TextSettingForm';
let svgCanvas;
getSVGAsync((globalSVG) => {
    svgCanvas = globalSVG.Canvas;
});
const { dpmm } = constant;
const paramWidth = {
    speed: 81.61 * dpmm,
    strength: 60.66 * dpmm,
    repeat: 42.63 * dpmm,
};
const paramString = {
    speed: 'Speed (mm/s)',
    strength: 'Power (%)',
    repeat: 'Passes',
};
const getTextAdjustment = (rawText) => (rawText.toString().length * 2.7) / 2;
const MaterialTestGeneratorPanel = ({ onClose }) => {
    const t = useI18n();
    const [tableSetting, setTableSetting] = React.useState(defaultTableSetting);
    const [blockSetting, setBlockSetting] = React.useState(defaultBlockSetting);
    const [textSetting, setTextSetting] = React.useState(defaultTextSetting);
    const [blockOption, setBlockOption] = React.useState('cut');
    const blockOptions = [
        { label: t.material_test_generator.cut, value: 'cut' },
        { label: t.material_test_generator.engrave, value: 'engrave' },
    ];
    const batchCmd = React.useRef(new history.BatchCommand('Material Test Generator'));
    const isInch = React.useMemo(() => storage.get('default-units') === 'inches', []);
    const generateText = (svgInfos, blockSetting, batchCmd) => {
        const { column, row } = blockSetting;
        const [startPadding, endPadding] = [30 * dpmm, 10 * dpmm];
        const [right, bottom] = [
            startPadding + (row.count.value - 1) * (row.spacing.value + row.size.value) * dpmm,
            startPadding + (column.count.value - 1) * (column.spacing.value + column.size.value) * dpmm,
        ];
        const rightBound = right + row.size.value * dpmm + endPadding;
        const bottomBound = bottom + column.size.value * dpmm + endPadding;
        const [colParam, rowParam] = Object.entries(tableSetting).sort(([, { selected: a }], [, { selected: b }]) => a - b);
        const { cmd: tableCmd } = createLayer('Material Test - Frame', {
            hexCode: '#000',
            isSubCmd: true,
        });
        if (tableCmd && !tableCmd.isEmpty()) {
            batchCmd.addSubCommand(tableCmd);
        }
        svgCanvas.addSvgElementFromJson({
            element: 'rect',
            curStyles: false,
            attr: {
                x: 0,
                y: 0,
                width: rightBound,
                height: bottomBound,
                stroke: '#000',
                id: svgCanvas.getNextId(),
                fill: 'none',
                'fill-opacity': 0,
                opacity: 1,
                rx: 50,
            },
        });
        const { layer: infoLayer, cmd: infoCmd } = createLayer('Material Test - Info', {
            hexCode: '#000',
            isSubCmd: true,
        });
        writeDataLayer(infoLayer, 'power', textSetting.power);
        writeDataLayer(infoLayer, 'speed', textSetting.speed);
        if (infoCmd && !infoCmd.isEmpty()) {
            batchCmd.addSubCommand(infoCmd);
        }
        // rowText
        createNewText(startPadding + (right - startPadding) / 2 - paramWidth[rowParam[0]] / 2, startPadding / 2, {
            text: paramString[rowParam[0]],
            fontSize: 130,
            fill: '#000',
            isDefaultFont: true,
        });
        const colText = createNewText(
        // magic number to align the text
        -(paramWidth[colParam[0]] * 0.55) + 13.19 * dpmm, startPadding + (bottom - startPadding) / 2 + paramWidth[colParam[0]] / 10, {
            text: paramString[colParam[0]],
            fontSize: 130,
            fill: '#000',
            isDefaultFont: true,
        });
        svgCanvas.setRotationAngle(-90, true, colText);
        Array.from({ length: row.count.value }).forEach((_, index) => {
            createNewText(startPadding +
                (row.size.value + row.spacing.value) * dpmm * index +
                (row.size.value * dpmm) / 2 -
                getTextAdjustment(svgInfos[index][rowParam[0]]) * dpmm, startPadding - 5 * dpmm, {
                text: svgInfos[index][rowParam[0]].toString(),
                fontSize: 48,
                fill: '#000',
                isDefaultFont: true,
            });
        });
        Array.from({ length: column.count.value }).forEach((_, index) => {
            createNewText(startPadding - 10 * dpmm, startPadding +
                (column.size.value + column.spacing.value) * dpmm * index +
                (column.size.value / 2) * dpmm +
                (4 / 2) * dpmm, {
                text: svgInfos[index * row.count.value][colParam[0]].toString(),
                fontSize: 48,
                fill: '#000',
                isDefaultFont: true,
            });
        });
    };
    const generateBlocks = (svgInfos, blockSetting, batchCmd) => {
        const { row, column } = blockSetting;
        const startPadding = 30 * dpmm;
        const [right, bottom] = [
            startPadding + (row.count.value - 1) * (row.spacing.value + row.size.value) * dpmm,
            startPadding + (column.count.value - 1) * (column.spacing.value + column.size.value) * dpmm,
        ];
        const [width, height] = [row.size.value * dpmm, column.size.value * dpmm];
        let [x, y] = [right, bottom];
        [...svgInfos].reverse().forEach(({ name, strength, speed, repeat }, index) => {
            const { layer, cmd } = createLayer(name, { isSubCmd: true });
            if (cmd && !cmd.isEmpty()) {
                batchCmd.addSubCommand(cmd);
            }
            writeDataLayer(layer, 'power', strength);
            writeDataLayer(layer, 'speed', speed);
            writeDataLayer(layer, 'repeat', repeat);
            const newRect = svgCanvas.addSvgElementFromJson({
                element: 'rect',
                curStyles: false,
                attr: {
                    x,
                    y,
                    width,
                    height,
                    stroke: '#000',
                    id: svgCanvas.getNextId(),
                    fill: blockOption === 'engrave' ? '#000' : 'none',
                    'fill-opacity': blockOption === 'engrave' ? 1 : 0,
                    opacity: 1,
                },
            });
            if ((index + 1) % row.count.value === 0) {
                x = right;
                y -= (column.size.value + column.spacing.value) * dpmm;
            }
            else {
                x -= (row.size.value + row.spacing.value) * dpmm;
            }
            updateElementColor(newRect);
        });
    };
    const handlePreview = () => {
        const svgInfos = generateSvgInfo({ tableSetting, blockSetting });
        batchCmd.current.unapply();
        // to prevent layer id conflict
        svgCanvas.identifyLayers();
        batchCmd.current = new history.BatchCommand('Material Test Generator');
        generateBlocks(svgInfos, blockSetting, batchCmd.current);
        generateText(svgInfos, blockSetting, batchCmd.current);
    };
    const handleExport = () => {
        undoManager.addCommandToHistory(batchCmd.current);
        svgEditor.updateContextPanel();
        LayerPanelController.updateLayerPanel();
        onClose();
    };
    const handleCancel = () => {
        batchCmd.current.unapply();
        // to prevent layer id conflict
        svgCanvas.identifyLayers();
        onClose();
    };
    React.useEffect(() => {
        workareaManager.resetView();
        handlePreview();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableSetting, blockSetting, textSetting, blockOption]);
    return (React.createElement(DraggableModal, { open: true, centered: true, wrapClassName: styles['modal-wrap'], title: t.material_test_generator.title, onCancel: handleCancel, footer: React.createElement("div", { className: styles.footer },
            React.createElement(Button, { onClick: handleCancel }, t.global.cancel),
            React.createElement(Button, { type: "primary", onClick: handleExport }, t.material_test_generator.export)) },
        React.createElement(Flex, { className: styles['mb-28'], justify: "space-between" },
            React.createElement(WorkAreaInfo, { isInch: isInch }),
            React.createElement(Radio.Group, { options: blockOptions, onChange: ({ target: { value } }) => {
                    setBlockOption(value);
                }, value: blockOption, optionType: "button" })),
        React.createElement(TableSettingForm, { className: styles['mb-28'], isInch: isInch, tableSetting: tableSetting, handleChange: setTableSetting }),
        React.createElement(BlockSettingForm, { className: styles['mb-28'], isInch: isInch, blockSetting: blockSetting, handleChange: setBlockSetting }),
        React.createElement(TextSettingForm, { isInch: isInch, setting: textSetting, handleChange: setTextSetting })));
};
export default MaterialTestGeneratorPanel;
