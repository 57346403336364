var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Slider, Space, Tooltip } from 'antd';
import constant from 'app/actions/beambox/constant';
import deviceMaster from 'helpers/device-master';
import PreviewModeController from 'app/actions/beambox/preview-mode-controller';
import useI18n from 'helpers/useI18n';
import WorkareaIcons from 'app/icons/workarea/WorkareaIcons';
import { CanvasContext, CanvasMode } from 'app/contexts/CanvasContext';
import styles from './PreviewSlider.module.scss';
const PreviewSlider = () => {
    const [opacity, setOpacity] = useState(1);
    const [showOpacity, setShowOpacity] = useState(false);
    const [exposureSetting, setExposureSetting] = useState(null);
    const { mode } = useContext(CanvasContext);
    const isPreviewing = mode === CanvasMode.Preview;
    const lang = useI18n();
    const getSetting = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        if (!((_a = deviceMaster === null || deviceMaster === void 0 ? void 0 : deviceMaster.currentDevice) === null || _a === void 0 ? void 0 : _a.info))
            return;
        if (!constant.adorModels.includes(deviceMaster.currentDevice.info.model))
            return;
        try {
            const exposureRes = yield deviceMaster.getDeviceSetting('camera_exposure_absolute');
            setExposureSetting(JSON.parse(exposureRes.value));
        }
        catch (e) {
            console.error('Failed to get exposure setting', e);
        }
    });
    const updateBgOpacity = useCallback((val) => {
        const bgImg = document.querySelector('#background_image');
        if (bgImg) {
            bgImg.style.opacity = val;
            setShowOpacity(true);
        }
        else {
            setShowOpacity(false);
        }
    }, []);
    useEffect(() => {
        updateBgOpacity(isPreviewing ? '1' : opacity.toString());
    }, [isPreviewing, opacity, updateBgOpacity]);
    // this is also triggered by UPDATE_CONTEXT event in PreviewModeController.start
    useEffect(() => {
        setExposureSetting(null);
        if (isPreviewing && PreviewModeController.isPreviewModeOn)
            getSetting();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPreviewing, PreviewModeController.isPreviewModeOn]);
    if (mode === CanvasMode.PathPreview)
        return null;
    return (React.createElement(Space, { className: styles.space, direction: "vertical" },
        !isPreviewing && showOpacity && (React.createElement("div", { className: styles.container },
            React.createElement(Tooltip, { title: lang.editor.opacity },
                React.createElement(WorkareaIcons.Opacity, { className: styles.icon })),
            React.createElement(Slider, { className: styles.slider, min: 0, max: 1, step: 0.25, value: opacity, onChange: setOpacity, tooltip: { open: false } }),
            React.createElement("div", { className: styles.value },
                opacity * 100,
                "%"))),
        isPreviewing && exposureSetting && (React.createElement("div", { className: styles.container },
            React.createElement(Tooltip, { title: lang.editor.exposure },
                React.createElement(WorkareaIcons.Exposure, { className: styles.icon })),
            React.createElement(Slider, { className: styles.slider, min: Math.max(exposureSetting.min, 250), max: Math.min(exposureSetting.max, 650), step: exposureSetting.step, value: exposureSetting.value, onChange: (value) => setExposureSetting(Object.assign(Object.assign({}, exposureSetting), { value })), onAfterChange: (value) => __awaiter(void 0, void 0, void 0, function* () {
                    setExposureSetting(Object.assign(Object.assign({}, exposureSetting), { value }));
                    yield deviceMaster.setDeviceSetting('camera_exposure_absolute', value.toString());
                    yield PreviewModeController.previewFullWorkarea();
                }), tooltip: { open: false } }),
            React.createElement("div", { className: styles.value }, exposureSetting.value)))));
};
export default PreviewSlider;
