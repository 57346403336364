import * as React from 'react';
import classNames from 'classnames';
import { FloatingPanel as AntdFloatingPanel } from 'antd-mobile';
import ActionIcon from 'app/icons/action-panel/ActionPanelIcons';
import Icon from '@ant-design/icons';
import styles from './FloatingPanel.module.scss';
const FloatingPanel = ({ className, anchors, title, fixedContent, children, forceClose = false, onClose, }) => {
    const panelRef = React.useRef(null);
    const [panelHeight, setPanelHeight] = React.useState(anchors[0]);
    const [isAnimating, setIsAnimating] = React.useState(true);
    const hasClosedRef = React.useRef(false);
    React.useEffect(() => {
        if (forceClose) {
            panelRef.current.setHeight(0);
        }
        else if (panelHeight === 0 || !anchors.includes(panelHeight)) {
            panelRef.current.setHeight(anchors.find((anchor) => anchor > 0));
            hasClosedRef.current = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [anchors, panelRef, forceClose]);
    const onHeightChange = (height, animating) => {
        setPanelHeight(height);
        setIsAnimating(animating);
        if (height <= 0 && !hasClosedRef.current) {
            hasClosedRef.current = true;
            onClose === null || onClose === void 0 ? void 0 : onClose();
        }
    };
    return (React.createElement(AntdFloatingPanel, { className: classNames(className, styles.panel), ref: panelRef, "data-animating": isAnimating, anchors: anchors, handleDraggingOfContent: false, onHeightChange: onHeightChange, style: { height: panelHeight } },
        React.createElement(Icon, { className: styles['close-icon'], component: ActionIcon.Delete, onClick: () => panelRef.current.setHeight(0) }),
        React.createElement("div", { className: styles.title }, title),
        fixedContent,
        React.createElement("div", { className: styles['scroll-content'] }, children)));
};
export default FloatingPanel;
