// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-widgets-PathInput-module__container--Pc\\+a1{display:flex;align-items:center}.src-web-app-widgets-PathInput-module__container--Pc\\+a1 .src-web-app-widgets-PathInput-module__btn--5Wfwr{cursor:pointer;width:32px;height:32px;margin-right:8px;border:1px solid #bbb;border-radius:4px}.src-web-app-widgets-PathInput-module__container--Pc\\+a1 .src-web-app-widgets-PathInput-module__btn--5Wfwr:hover{background-color:#eee}.src-web-app-widgets-PathInput-module__container--Pc\\+a1 .src-web-app-widgets-PathInput-module__btn--5Wfwr>img{padding:4px}", "",{"version":3,"sources":["webpack://./src/web/app/widgets/PathInput.module.scss"],"names":[],"mappings":"AAAA,yDACE,YAAA,CACA,kBAAA,CAEA,2GACE,cAAA,CACA,UAAA,CACA,WAAA,CACA,gBAAA,CACA,qBAAA,CACA,iBAAA,CAEA,iHACE,qBAAA,CAGF,+GACE,WAAA","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n\n  .btn {\n    cursor: pointer;\n    width: 32px;\n    height: 32px;\n    margin-right: 8px;\n    border: 1px solid #bbbbbb;\n    border-radius: 4px;\n\n    &:hover {\n      background-color: #eeeeee;\n    }\n\n    > img {\n      padding: 4px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "src-web-app-widgets-PathInput-module__container--Pc+a1",
	"btn": "src-web-app-widgets-PathInput-module__btn--5Wfwr"
};
export default ___CSS_LOADER_EXPORT___;
