import transfromlist from './transfromlist';
const getRotationAngleFromTransformList = (tlist, toRad = false) => {
    if (!tlist)
        return 0;
    for (let i = 0; i < tlist.numberOfItems; i++) {
        const xform = tlist.getItem(i);
        if (xform.type === SVGTransform.SVG_TRANSFORM_ROTATE) {
            return toRad ? (xform.angle * Math.PI) / 180.0 : xform.angle;
        }
    }
    return 0;
};
export const getRotationAngle = (elem, toRad = false) => {
    const tlist = transfromlist.getTransformList(elem);
    return getRotationAngleFromTransformList(tlist, toRad);
};
export default {
    getRotationAngle,
    getRotationAngleFromTransformList,
};
