import classNames from 'classnames';
import React from 'react';
import i18n from 'helpers/i18n';
import SelectView from 'app/widgets/Select';
const LANG = i18n.lang.beambox.tool_panels;
function OffsetDirectionPanel({ dir: dirProps, onValueChange }) {
    const [dir, updateDir] = React.useState(dirProps);
    const [isCollapsed, updateIsCollapsed] = React.useState(false);
    const updateOffsetDir = (val) => {
        onValueChange(val);
        updateDir(val);
    };
    const getOffsetDir = () => ({
        0: LANG._offset.inward,
        1: LANG._offset.outward,
    }[dir]);
    const options = [
        {
            value: 1,
            label: LANG._offset.outward,
            selected: dir === 1,
        },
        {
            value: 0,
            label: LANG._offset.inward,
            selected: dir === 0,
        },
    ];
    return (React.createElement("div", { className: "tool-panel" },
        React.createElement("label", { className: "controls accordion" },
            React.createElement("input", { type: "checkbox", className: "accordion-switcher", defaultChecked: true }),
            React.createElement("p", { className: "caption", onClick: () => updateIsCollapsed(!isCollapsed) },
                LANG._offset.direction,
                React.createElement("span", { className: "value" }, getOffsetDir())),
            React.createElement("div", { className: classNames('tool-panel-body', { collapsed: isCollapsed }) },
                React.createElement("div", { className: "control offset-dir" },
                    React.createElement(SelectView, { id: "select-offset-dir", options: options, onChange: (e) => { updateOffsetDir(parseInt(e.target.value, 10)); } }))))));
}
export default OffsetDirectionPanel;
