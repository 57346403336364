var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useRef, useState } from 'react';
import alertCaller from 'app/actions/alert-caller';
import checkDeviceStatus from 'helpers/check-device-status';
import deviceMaster from 'helpers/device-master';
import progressCaller from 'app/actions/progress-caller';
import useI18n from 'helpers/useI18n';
import { addDialogComponent, isIdExist, popDialogById } from 'app/actions/dialog-controller';
import { setFisheyeConfig } from 'helpers/camera-calibration-helper';
import CheckpointData from './common/CheckpointData';
import Chessboard from './BB2Calibration/Chessboard';
import Instruction from './common/Instruction';
import moveLaserHead from './BB2Calibration/moveLaserHead';
import SolvePnP from './common/SolvePnP';
import { bb2PnPPoints } from './common/solvePnPConstants';
var Steps;
(function (Steps) {
    Steps[Steps["CHECKPOINT_DATA"] = 0] = "CHECKPOINT_DATA";
    Steps[Steps["PRE_CHESSBOARD"] = 1] = "PRE_CHESSBOARD";
    Steps[Steps["CHESSBOARD"] = 2] = "CHESSBOARD";
    Steps[Steps["PUT_PAPER"] = 3] = "PUT_PAPER";
    Steps[Steps["SOLVE_PNP"] = 4] = "SOLVE_PNP";
})(Steps || (Steps = {}));
const PROGRESS_ID = 'bb2-calibration';
const BB2Calibration = ({ onClose }) => {
    const lang = useI18n();
    const tCali = lang.calibration;
    const calibratingParam = useRef({});
    const useOldData = useRef(false);
    const [step, setStep] = useState(Steps.CHECKPOINT_DATA);
    const updateParam = useCallback((param) => {
        calibratingParam.current = Object.assign(Object.assign({}, calibratingParam.current), param);
    }, []);
    if (step === Steps.CHECKPOINT_DATA) {
        return (React.createElement(CheckpointData, { askUser: true, allowCheckPoint: false, updateParam: updateParam, onClose: onClose, onNext: (res) => {
                if (res) {
                    useOldData.current = true;
                    setStep(Steps.PUT_PAPER);
                }
                else
                    setStep(Steps.PRE_CHESSBOARD);
            } }));
    }
    if (step === Steps.PRE_CHESSBOARD) {
        return (React.createElement(Instruction, { title: tCali.put_chessboard, steps: [tCali.put_chessboard_desc], buttons: [
                { label: tCali.back, onClick: () => setStep(Steps.CHECKPOINT_DATA) },
                {
                    label: tCali.next,
                    onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        const res = yield moveLaserHead();
                        if (res)
                            setStep(Steps.CHESSBOARD);
                    }),
                    type: 'primary',
                },
            ], onClose: onClose }));
    }
    if (step === Steps.CHESSBOARD) {
        return (React.createElement(Chessboard, { chessboard: [24, 14], updateParam: updateParam, onNext: () => setStep(Steps.PUT_PAPER), onClose: onClose }));
    }
    if (step === Steps.PUT_PAPER) {
        const handleNext = () => __awaiter(void 0, void 0, void 0, function* () {
            const deviceStatus = yield checkDeviceStatus(deviceMaster.currentDevice.info);
            if (!deviceStatus)
                return;
            try {
                progressCaller.openNonstopProgress({
                    id: PROGRESS_ID,
                    message: tCali.drawing_calibration_image,
                });
                yield deviceMaster.doBB2Calibration();
                progressCaller.update(PROGRESS_ID, { message: tCali.preparing_to_take_picture });
                const res = yield moveLaserHead();
                if (!res)
                    return;
                setStep(Steps.SOLVE_PNP);
            }
            catch (err) {
                console.error(err);
            }
            finally {
                progressCaller.popById(PROGRESS_ID);
            }
        });
        return (React.createElement(Instruction, { onClose: () => onClose(false), 
            // TODO: animation
            animationSrcs: [
                { src: 'video/ador-calibration-2/paper.webm', type: 'video/webm' },
                { src: 'video/ador-calibration-2/paper.mp4', type: 'video/mp4' },
            ], title: tCali.put_paper, steps: [tCali.put_paper_step1, tCali.put_paper_step2, tCali.perform_autofocus_bb2], buttons: [
                { label: tCali.back, onClick: () => setStep(useOldData.current ? Steps.CHECKPOINT_DATA : Steps.CHESSBOARD) },
                { label: tCali.start_engrave, onClick: () => handleNext(), type: 'primary' },
            ] }));
    }
    if (step === Steps.SOLVE_PNP) {
        return (React.createElement(SolvePnP, { params: calibratingParam.current, dh: 0, refPoints: bb2PnPPoints, onClose: onClose, onBack: () => setStep(Steps.PUT_PAPER), onNext: (rvec, tvec) => __awaiter(void 0, void 0, void 0, function* () {
                progressCaller.openNonstopProgress({ id: PROGRESS_ID, message: lang.device.processing });
                updateParam({ rvec, tvec });
                console.log('calibratingParam.current', calibratingParam.current);
                progressCaller.popById(PROGRESS_ID);
                const param = {
                    k: calibratingParam.current.k,
                    d: calibratingParam.current.d,
                    rvec,
                    tvec,
                    v: 3,
                };
                const res = yield setFisheyeConfig(param);
                if (res.status === 'ok') {
                    alertCaller.popUp({ message: tCali.camera_parameter_saved_successfully });
                    onClose(true);
                }
                else {
                    alertCaller.popUpError({
                        message: `${tCali.failed_to_save_camera_parameter}:<br />${JSON.stringify(res)}`,
                    });
                }
            }) }));
    }
    onClose();
    return React.createElement(React.Fragment, null);
};
export const showBB2Calibration = () => {
    const id = 'bb2-calibration';
    const onClose = () => popDialogById(id);
    if (isIdExist(id))
        onClose();
    return new Promise((resolve) => {
        addDialogComponent(id, React.createElement(BB2Calibration, { onClose: (completed = false) => {
                onClose();
                resolve(completed);
            } }));
    });
};
export default BB2Calibration;
