import beamboxPreference from 'app/actions/beambox/beambox-preference';
import isDev from 'helpers/is-dev';
import isWeb from 'helpers/is-web';
import layoutConstants from 'app/constants/layout-constants';
import { modelsWithModules } from 'app/constants/layer-module/layer-modules';
export var TopRef;
(function (TopRef) {
    TopRef[TopRef["WINDOW"] = 0] = "WINDOW";
    TopRef[TopRef["TOPBAR"] = 1] = "TOPBAR";
    TopRef[TopRef["LAYER_LIST"] = 2] = "LAYER_LIST";
    TopRef[TopRef["LAYER_PARAMS"] = 3] = "LAYER_PARAMS";
})(TopRef || (TopRef = {}));
export var RightRef;
(function (RightRef) {
    RightRef[RightRef["WINDOW"] = 0] = "WINDOW";
    RightRef[RightRef["RIGHT_SROLL_BAR"] = 1] = "RIGHT_SROLL_BAR";
    RightRef[RightRef["RIGHT_PANEL"] = 2] = "RIGHT_PANEL";
    RightRef[RightRef["PATH_PREVIEW_BTN"] = 3] = "PATH_PREVIEW_BTN";
})(RightRef || (RightRef = {}));
const isMacOrWeb = window.os === 'MacOS' || isWeb();
export const calculateTop = (top, ref = TopRef.WINDOW) => {
    var _a;
    switch (ref) {
        case TopRef.TOPBAR:
            return top + layoutConstants.topBarHeight;
        case TopRef.LAYER_LIST:
            return top + layoutConstants.topBarHeight + layoutConstants.layerListHeight;
        case TopRef.LAYER_PARAMS: {
            const offset = ((_a = document.querySelector('#layer-parameters')) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect().top) || 0;
            return top + offset;
        }
        default:
            return top + layoutConstants.titlebarHeight;
    }
};
export const calculateRight = (right, ref = RightRef.WINDOW) => {
    switch (ref) {
        case RightRef.RIGHT_SROLL_BAR:
            return right + layoutConstants.rightPanelScrollBarWidth;
        case RightRef.RIGHT_PANEL:
            return right + layoutConstants.rightPanelWidth;
        case RightRef.PATH_PREVIEW_BTN: {
            const workarea = beamboxPreference.read('workarea');
            const shouldHideBtn = !isDev() && modelsWithModules.has(workarea);
            const offset = (isMacOrWeb ? 6 : 26) + (shouldHideBtn ? 0 : 42);
            return right + offset;
        }
        default:
            return right;
    }
};
