// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-widgets-FullWindowPanel-Header-module__header--2IEK1{display:flex;align-items:center;border-bottom:1px solid #ddd;padding-left:40px;padding-right:20px}.src-web-app-widgets-FullWindowPanel-Header-module__header--2IEK1 .src-web-app-widgets-FullWindowPanel-Header-module__icon--ZLrnR{color:#000}.src-web-app-widgets-FullWindowPanel-Header-module__header--2IEK1 .src-web-app-widgets-FullWindowPanel-Header-module__title--MM99W{font-size:20px;font-weight:700;line-height:46px}", "",{"version":3,"sources":["webpack://./src/web/app/widgets/FullWindowPanel/Header.module.scss"],"names":[],"mappings":"AAAA,kEACE,YAAA,CACA,kBAAA,CACA,4BAAA,CACA,iBAAA,CACA,kBAAA,CAEA,kIACE,UAAA,CAGF,mIACE,cAAA,CACA,eAAA,CACA,gBAAA","sourcesContent":[".header {\n  display: flex;\n  align-items: center;\n  border-bottom: 1px solid #ddd;\n  padding-left: 40px;\n  padding-right: 20px;\n\n  .icon {\n    color: #000000;\n  }\n\n  .title {\n    font-size: 20px;\n    font-weight: 700;\n    line-height: 46px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "src-web-app-widgets-FullWindowPanel-Header-module__header--2IEK1",
	"icon": "src-web-app-widgets-FullWindowPanel-Header-module__icon--ZLrnR",
	"title": "src-web-app-widgets-FullWindowPanel-Header-module__title--MM99W"
};
export default ___CSS_LOADER_EXPORT___;
