import classNames from 'classnames';
import React, { memo, useContext, useEffect, useState } from 'react';
import useI18n from 'helpers/useI18n';
import { getObjectLayer, moveToOtherLayer } from 'helpers/layer/layer-helper';
import { LayerPanelContext } from 'app/views/beambox/Right-Panels/contexts/LayerPanelContext';
import { SelectedElementContext } from 'app/contexts/SelectedElementContext';
import styles from './SelLayerBlock.module.scss';
const defaultOption = ' ';
function SelLayerBlock({ layerNames }) {
    const lang = useI18n().beambox.right_panel.layer_panel;
    const [promptMoveLayerOnce, setPromptMoveLayerOnce] = useState(false);
    const [displayValue, setDisplayValue] = useState(defaultOption);
    const { selectedElement } = useContext(SelectedElementContext);
    const { selectedLayers } = useContext(LayerPanelContext);
    useEffect(() => {
        var _a;
        if (!selectedElement)
            return;
        if (selectedElement.getAttribute('data-tempgroup') === 'true') {
            const originalLayers = new Set([...selectedElement.childNodes]
                .filter((elem) => (elem === null || elem === void 0 ? void 0 : elem.getAttribute('data-imageborder')) !== 'true')
                .map((elem) => elem.getAttribute('data-original-layer')));
            if (originalLayers.size === 1) {
                const [firstValue] = originalLayers;
                setDisplayValue(firstValue !== null && firstValue !== void 0 ? firstValue : defaultOption);
            }
            else
                setDisplayValue(defaultOption);
        }
        else {
            const currentLayer = getObjectLayer(selectedElement);
            const currentLayerName = (_a = currentLayer === null || currentLayer === void 0 ? void 0 : currentLayer.title) !== null && _a !== void 0 ? _a : defaultOption;
            setDisplayValue(currentLayerName);
        }
    }, [selectedElement, selectedLayers]);
    if (!selectedElement)
        return null;
    if (layerNames.length === 1)
        return null;
    const onChange = (e) => {
        const select = e.target;
        const destLayer = select.options[select.selectedIndex].value;
        moveToOtherLayer(destLayer, () => {
            setDisplayValue(destLayer);
            setPromptMoveLayerOnce(true);
        }, !promptMoveLayerOnce);
    };
    const options = [];
    if (displayValue === defaultOption) {
        options.push(React.createElement("option", { value: defaultOption, key: -1 }, lang.move_elems_to));
    }
    for (let i = layerNames.length - 1; i >= 0; i -= 1) {
        const layerName = layerNames[i];
        options.push(React.createElement("option", { value: layerName, key: i }, layerName));
    }
    return (React.createElement("div", { className: classNames('controls', styles.container) },
        React.createElement("span", { className: styles.label }, lang.move_elems_to),
        React.createElement("select", { className: styles.select, value: displayValue, title: "Move selected elements to a different layer", onChange: onChange, disabled: options.length < 2 }, options)));
}
export default memo(SelLayerBlock);
