import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ConfigProvider, InputNumber, Slider } from 'antd';
import ColorBlock from 'app/components/beambox/right-panel/ColorBlock';
import ColorPicker from 'app/widgets/ColorPicker';
import ColorPickerMobile from 'app/widgets/ColorPickerMobile';
import constant from 'app/actions/beambox/constant';
import eventEmitterFactory from 'helpers/eventEmitterFactory';
import FloatingPanel from 'app/widgets/FloatingPanel';
import HistoryCommandFactory from 'app/svgedit/history/HistoryCommandFactory';
import ObjectPanelItem from 'app/views/beambox/Right-Panels/ObjectPanelItem';
import OptionPanelIcons from 'app/icons/option-panel/OptionPanelIcons';
import storage from 'implementations/storage';
import useDidUpdateEffect from 'helpers/hooks/useDidUpdateEffect';
import useI18n from 'helpers/useI18n';
import { CanvasContext } from 'app/contexts/CanvasContext';
import { getSVGAsync } from 'helpers/svg-editor-helper';
import { useIsMobile } from 'helpers/system-helper';
import styles from './ColorPanel.module.scss';
const workareaEvents = eventEmitterFactory.createEventEmitter('workarea');
let svgCanvas;
getSVGAsync((globalSVG) => {
    svgCanvas = globalSVG.Canvas;
});
const EditStep = {
    Closed: 0,
    Color: 1,
    Previewing: 2,
};
const EditType = {
    None: 0,
    Fill: 1,
    Stroke: 2,
};
const deriveElementState = (element) => {
    const fill = element.getAttribute('fill') || '#000000';
    const stroke = element.getAttribute('stroke') || 'none';
    const strokeWidthAttr = element.getAttribute('stroke-width') || '1';
    const strokeWidth = Number.isNaN(Number(strokeWidthAttr)) ? 1 : Number(strokeWidthAttr);
    return { fill, stroke, strokeWidth };
};
const ColorPanel = ({ elem }) => {
    const isMobile = useIsMobile();
    const lang = useI18n();
    const t = lang.beambox.right_panel.object_panel.option_panel;
    const [state, setState] = useState(deriveElementState(elem));
    const [previewState, setPreviewState] = useState({
        currentStep: EditStep.Closed,
        type: EditType.None,
    });
    const previewRef = useRef({ origColor: '', newColor: '' });
    const { isColorPreviewing, setIsColorPreviewing } = useContext(CanvasContext);
    const { fill, stroke, strokeWidth } = state;
    useDidUpdateEffect(() => {
        setState(deriveElementState(elem));
    }, [elem]);
    const handleFillColorChange = (newColor, actual = true) => {
        if (actual && previewRef.current.origColor !== previewRef.current.newColor) {
            handleFillColorChange(previewRef.current.origColor, false);
        }
        const batchCmd = HistoryCommandFactory.createBatchCommand('Color Panel Fill');
        svgCanvas.undoMgr.beginUndoableChange('fill', [elem]);
        svgCanvas.changeSelectedAttributeNoUndo('fill', newColor, [elem]);
        let cmd = svgCanvas.undoMgr.finishUndoableChange();
        if (!cmd.isEmpty())
            batchCmd.addSubCommand(cmd);
        svgCanvas.undoMgr.beginUndoableChange('fill-opacity', [elem]);
        svgCanvas.changeSelectedAttributeNoUndo('fill-opacity', newColor === 'none' ? '0' : '1', [
            elem,
        ]);
        cmd = svgCanvas.undoMgr.finishUndoableChange();
        if (!cmd.isEmpty())
            batchCmd.addSubCommand(cmd);
        if (actual) {
            svgCanvas.undoMgr.addCommandToHistory(batchCmd);
            previewRef.current.origColor = newColor;
        }
        previewRef.current.newColor = newColor;
        setState(Object.assign(Object.assign({}, state), { fill: newColor }));
    };
    const handleStrokeColorChange = (newColor, actual = true) => {
        if (actual && previewRef.current.origColor !== previewRef.current.newColor) {
            handleStrokeColorChange(previewRef.current.origColor, false);
        }
        svgCanvas.undoMgr.beginUndoableChange('stroke', [elem]);
        svgCanvas.changeSelectedAttributeNoUndo('stroke', newColor, [elem]);
        const cmd = svgCanvas.undoMgr.finishUndoableChange();
        if (actual) {
            if (!cmd.isEmpty())
                svgCanvas.undoMgr.addCommandToHistory(cmd);
            previewRef.current.origColor = newColor;
        }
        previewRef.current.newColor = newColor;
        setState(Object.assign(Object.assign({}, state), { stroke: newColor }));
    };
    const handleStrokeWidthChange = (val) => {
        svgCanvas.changeSelectedAttribute('stroke-width', val, [elem]);
        setState(Object.assign(Object.assign({}, state), { strokeWidth: val }));
    };
    const { ratio, decimal, step, max, unit } = useMemo(() => storage.get('default-units') === 'inches'
        ? {
            ratio: constant.dpmm * 25.4,
            decimal: 2,
            step: constant.dpmm * 0.254,
            max: 127,
            unit: 'inch',
        }
        : { ratio: constant.dpmm, decimal: 1, step: constant.dpmm * 0.1, max: 100, unit: 'mm' }, []);
    const startPreviewMode = (type, color) => {
        setIsColorPreviewing(true);
        svgCanvas.unsafeAccess.setCurrentMode('preview_color');
        svgCanvas.selectorManager.requestSelector(elem).resize();
        workareaEvents.emit('update-context-menu', { menuDisabled: true });
        setPreviewState({ currentStep: EditStep.Previewing, type });
        previewRef.current = { origColor: color, newColor: color };
    };
    const endPreviewMode = (newStatus = EditStep.Closed) => {
        const { currentStep, type } = previewState;
        const { origColor, newColor } = previewRef.current;
        if (currentStep === EditStep.Previewing) {
            if (origColor !== newColor) {
                if (type === EditType.Fill)
                    handleFillColorChange(origColor, false);
                else
                    handleStrokeColorChange(origColor, false);
            }
            setIsColorPreviewing(false);
            svgCanvas.unsafeAccess.setCurrentMode('select');
            svgCanvas.selectorManager.requestSelector(elem).resize();
            workareaEvents.emit('update-context-menu', { menuDisabled: false });
        }
        setPreviewState({ currentStep: newStatus, type });
    };
    useEffect(() => {
        if (!isColorPreviewing && previewState.currentStep === EditStep.Previewing) {
            endPreviewMode();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isColorPreviewing]);
    const renderBackIcon = () => {
        if (previewState.currentStep !== EditStep.Previewing)
            return null;
        return (React.createElement("div", { className: styles.back, onClick: () => endPreviewMode(EditStep.Color) },
            React.createElement(OptionPanelIcons.Left, null)));
    };
    return isMobile ? (React.createElement(React.Fragment, null,
        React.createElement(ObjectPanelItem.Item, { id: "fill", content: React.createElement(ColorBlock, { size: "small", color: fill }), label: t.fill, onClick: () => {
                setPreviewState({ currentStep: EditStep.Color, type: EditType.Fill });
                previewRef.current = { origColor: '', newColor: '' };
            } }),
        previewState.type === EditType.Fill && (React.createElement(FloatingPanel, { anchors: previewState.currentStep === EditStep.Color ? [0, 180] : [0, 320], title: t.fill, forceClose: previewState.currentStep === EditStep.Closed, onClose: endPreviewMode, fixedContent: renderBackIcon() },
            React.createElement("div", { className: styles.panel },
                React.createElement(ColorBlock, { size: "large", color: fill, onClick: () => startPreviewMode(EditType.Fill, fill) }),
                React.createElement(ColorPickerMobile, { color: fill, onChange: handleFillColorChange, open: previewState.currentStep === EditStep.Previewing, onClose: () => endPreviewMode(EditStep.Color) })))),
        React.createElement(ObjectPanelItem.Item, { id: "stroke", content: React.createElement(OptionPanelIcons.Stroke, null), label: t.stroke, onClick: () => {
                setPreviewState({
                    currentStep: EditStep.Color,
                    type: EditType.Stroke,
                });
                previewRef.current = { origColor: '', newColor: '' };
            } }),
        previewState.type === EditType.Stroke && (React.createElement(FloatingPanel, { anchors: previewState.currentStep === EditStep.Color ? [0, 270] : [0, 320], title: previewState.currentStep === EditStep.Color ? t.stroke : t.stroke_color, forceClose: previewState.currentStep === EditStep.Closed, onClose: endPreviewMode, fixedContent: renderBackIcon() },
            React.createElement("div", { className: styles.panel },
                React.createElement("div", { className: styles.label }, t.stroke_color),
                React.createElement(ColorBlock, { size: "large", color: strokeWidth === 0 ? 'none' : stroke, onClick: () => startPreviewMode(EditType.Stroke, strokeWidth === 0 ? 'none' : stroke) }),
                React.createElement(ColorPickerMobile, { color: strokeWidth === 0 ? 'none' : stroke, onChange: handleStrokeColorChange, open: previewState.currentStep === EditStep.Previewing, onClose: () => endPreviewMode(EditStep.Color) }),
                React.createElement("div", { className: styles.field },
                    React.createElement("div", null,
                        React.createElement("span", { className: styles.label }, t.stroke_width),
                        React.createElement(ConfigProvider, { theme: { token: { borderRadius: 100 } } },
                            React.createElement(InputNumber, { value: strokeWidth, min: 0, step: step, suffix: React.createElement("span", { className: styles.suffix }, unit), onChange: handleStrokeWidthChange, precision: decimal, formatter: (v, { userTyping, input }) => userTyping ? input : (v / ratio).toFixed(decimal), parser: (value) => Number(value) * ratio, controls: false }))),
                    React.createElement(Slider, { min: 0, max: max, step: step, value: strokeWidth, onChange: handleStrokeWidthChange, tooltip: {
                            formatter: (val) => (val / ratio).toFixed(decimal),
                            placement: 
                            // eslint-disable-next-line no-nested-ternary
                            strokeWidth < max * 0.1
                                ? 'topRight'
                                : strokeWidth > max * 0.9
                                    ? 'topLeft'
                                    : 'top',
                            arrow: { pointAtCenter: true },
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        } }))))),
        React.createElement(ObjectPanelItem.Divider, null))) : (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles.block },
            React.createElement("div", { className: styles.label }, t.fill),
            React.createElement(ColorPicker, { allowClear: true, initColor: fill, onChange: handleFillColorChange })),
        React.createElement("div", { className: styles.block },
            React.createElement("div", { className: styles.label }, t.stroke),
            React.createElement("div", { className: styles.controls },
                React.createElement(ColorPicker, { initColor: strokeWidth === 0 ? 'none' : stroke, triggerType: "stroke", onChange: handleStrokeColorChange }),
                React.createElement(ConfigProvider, { theme: {
                        components: {
                            InputNumber: {
                                controlWidth: 60,
                            },
                        },
                    } },
                    React.createElement(InputNumber, { id: "stroke-width", value: strokeWidth, size: "small", min: 0, step: step, onChange: handleStrokeWidthChange, precision: decimal, 
                        // addonAfter={isInch ? 'in' : 'mm'}
                        formatter: (v, { userTyping, input }) => userTyping ? input : (v / ratio).toFixed(decimal), parser: (value) => Number(value) * ratio }))))));
};
export default ColorPanel;
