// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-components-dialogs-MaterialTestGeneratorPanel-WorkAreaInfo-module__workarea--z6RGE{color:#7c7c7c;font-size:14px;margin:0 10px 0 0;display:flex;align-items:center;column-gap:8px;line-height:30px}@media screen and (max-width: 600px){.src-web-app-components-dialogs-MaterialTestGeneratorPanel-WorkAreaInfo-module__workarea--z6RGE{margin-left:0}}.src-web-app-components-dialogs-MaterialTestGeneratorPanel-WorkAreaInfo-module__workarea--z6RGE .src-web-app-components-dialogs-MaterialTestGeneratorPanel-WorkAreaInfo-module__icon--H8\\+S7{font-size:16px}", "",{"version":3,"sources":["webpack://./src/web/app/components/dialogs/MaterialTestGeneratorPanel/WorkAreaInfo.module.scss"],"names":[],"mappings":"AAAA,gGACE,aAAA,CACA,cAAA,CACA,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,cAAA,CACA,gBAAA,CAEA,qCATF,gGAUI,aAAA,CAAA,CAGF,6LACE,cAAA","sourcesContent":[".workarea {\n  color: #7c7c7c;\n  font-size: 14px;\n  margin: 0 10px 0 0;\n  display: flex;\n  align-items: center;\n  column-gap: 8px;\n  line-height: 30px;\n\n  @media screen and (max-width: 600px) {\n    margin-left: 0;\n  }\n\n  .icon {\n    font-size: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"workarea": "src-web-app-components-dialogs-MaterialTestGeneratorPanel-WorkAreaInfo-module__workarea--z6RGE",
	"icon": "src-web-app-components-dialogs-MaterialTestGeneratorPanel-WorkAreaInfo-module__icon--H8+S7"
};
export default ___CSS_LOADER_EXPORT___;
