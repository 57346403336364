// ref: https://www.notion.so/flux3dp/030619b6721849498cadc57e660107d3?pvs=4
var LayerModule;
(function (LayerModule) {
    LayerModule[LayerModule["LASER_10W_DIODE"] = 1] = "LASER_10W_DIODE";
    LayerModule[LayerModule["LASER_20W_DIODE"] = 2] = "LASER_20W_DIODE";
    LayerModule[LayerModule["LASER_1064"] = 4] = "LASER_1064";
    LayerModule[LayerModule["PRINTER"] = 5] = "PRINTER";
    LayerModule[LayerModule["UNKNOWN"] = 9] = "UNKNOWN";
    LayerModule[LayerModule["LASER_UNIVERSAL"] = 15] = "LASER_UNIVERSAL";
})(LayerModule || (LayerModule = {}));
export const modelsWithModules = new Set(['fad1', 'ado1']);
export default LayerModule;
