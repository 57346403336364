import AreaCheck from './areacheck.svg';
import Contour from './contour.svg';
import Framing from './framing.svg';
import Hull from './hull.svg';
export default {
    AreaCheck,
    Contour,
    Framing,
    Hull,
};
