import isWeb from 'helpers/is-web';
export default {
    rightPanelWidth: window.os !== 'MacOS' ? 258 : 242,
    rightPanelScrollBarWidth: window.os !== 'MacOS' ? 16 : 0,
    sidePanelsWidth: window.os !== 'MacOS' ? 308 : 292,
    topBarHeight: window.os === 'Windows' && !isWeb() ? 70 : 40,
    topBarHeightWithoutTitleBar: 40,
    titlebarHeight: window.os === 'Windows' && !isWeb() ? 30 : 0,
    menuberHeight: window.os === 'Windows' && !isWeb() ? 30 : 40,
    layerListHeight: 400,
    rulerWidth: 15, // px
};
