// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-components-mobile-CanvasTabBar-module__container--QIScp{display:block;position:fixed;width:100%;border-top:solid 1px #ccc;background:#fff;z-index:998;overflow-x:scroll;bottom:0;padding:0 4px}.src-web-app-components-mobile-CanvasTabBar-module__container--QIScp .adm-tab-bar-item-icon{display:flex;align-items:center;height:32px;font-size:32px}.src-web-app-components-mobile-CanvasTabBar-module__container--QIScp .adm-tab-bar-item-icon .src-web-app-components-mobile-CanvasTabBar-module__icon--QDgk3{margin-right:auto}.src-web-app-components-mobile-CanvasTabBar-module__sep--3QExj{display:inline-block;width:1px;height:100%;background:#ccc}.src-web-app-components-mobile-CanvasTabBar-module__badge--LxYT1{color:inherit;font-size:inherit}.src-web-app-components-mobile-CanvasTabBar-module__badge--LxYT1 .src-web-app-components-mobile-CanvasTabBar-module__flux-plus--iTCVp{font-size:20px}", "",{"version":3,"sources":["webpack://./src/web/app/components/mobile/CanvasTabBar.module.scss"],"names":[],"mappings":"AAAA,qEACE,aAAA,CACA,cAAA,CACA,UAAA,CACA,yBAAA,CACA,eAAA,CACA,WAAA,CACA,iBAAA,CACA,QAAA,CACA,aAAA,CAEA,4FACE,YAAA,CACA,kBAAA,CACA,WAAA,CACA,cAAA,CAEA,4JACE,iBAAA,CAKN,+DACE,oBAAA,CACA,SAAA,CACA,WAAA,CACA,eAAA,CAGF,iEACE,aAAA,CACA,iBAAA,CAEA,sIACE,cAAA","sourcesContent":[".container {\n  display: block;\n  position: fixed;\n  width: 100%;\n  border-top: solid 1px #CCC;\n  background: #FFFFFF;\n  z-index: 998;\n  overflow-x: scroll;\n  bottom: 0;\n  padding: 0 4px;\n\n  :global(.adm-tab-bar-item-icon) {\n    display: flex;\n    align-items: center;\n    height: 32px;\n    font-size: 32px;\n\n    .icon {\n      margin-right: auto;\n    }\n  }\n}\n\n.sep {\n  display: inline-block;\n  width: 1px;\n  height: 100%;\n  background: #CCC;\n}\n\n.badge {\n  color: inherit;\n  font-size: inherit;\n\n  .flux-plus {\n    font-size: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "src-web-app-components-mobile-CanvasTabBar-module__container--QIScp",
	"icon": "src-web-app-components-mobile-CanvasTabBar-module__icon--QDgk3",
	"sep": "src-web-app-components-mobile-CanvasTabBar-module__sep--3QExj",
	"badge": "src-web-app-components-mobile-CanvasTabBar-module__badge--LxYT1",
	"flux-plus": "src-web-app-components-mobile-CanvasTabBar-module__flux-plus--iTCVp"
};
export default ___CSS_LOADER_EXPORT___;
