/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classNames from 'classnames';
function ButtonGroup({ buttons = [], className = '' }) {
    if (buttons.length <= 0) {
        return (React.createElement("span", null));
    }
    const buttonsElems = buttons.map((opt, i) => {
        const type = opt.type || 'button';
        const attrs = {};
        if (opt.dataAttrs) {
            const keys = Object.keys(opt.dataAttrs);
            for (let j = 0; j < keys.length; j += 1) {
                const key = keys[i];
                if (!attrs[`data-${key}`]) {
                    attrs[`data-${key}`] = opt.dataAttrs[key];
                }
            }
        }
        const hasOptClassname = typeof opt.className === 'string' && opt.className !== '';
        const btnClassName = classNames('btn', hasOptClassname ? opt.className : 'btn-default', {
            'pull-right': opt.right,
        });
        if (typeof opt.label === 'string') {
            attrs['data-test-key'] = opt.label.toLowerCase();
        }
        if (type === 'link') {
            return (React.createElement("a", Object.assign({ className: btnClassName, key: i, href: opt.href }, attrs, { onClick: opt.onClick }), opt.label));
        }
        if (type === 'icon') {
            return (React.createElement("button", Object.assign({ key: i, title: opt.title, className: btnClassName, type: "button", onClick: opt.onClick }, attrs), opt.label));
        }
        return (React.createElement("button", Object.assign({ key: i, title: opt.title, className: btnClassName, type: "button", onClick: opt.onClick, onMouseDown: opt.onMouseDown, onMouseUp: opt.onMouseUp, onMouseLeave: opt.onMouseLeave, dangerouslySetInnerHTML: { __html: opt.label } }, attrs)));
    }, this);
    const hasClassName = typeof className === 'string' && className !== '';
    const groupClassName = classNames('button-group', hasClassName ? className : 'btn-h-group');
    return (React.createElement("div", { className: groupClassName }, buttonsElems));
}
export default ButtonGroup;
