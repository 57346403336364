var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Dropdown, Input, Popconfirm } from 'antd';
import { ArrowRightOutlined, CopyOutlined, DeleteOutlined, DownloadOutlined, EditOutlined, EllipsisOutlined, } from '@ant-design/icons';
import useI18n from 'helpers/useI18n';
import { getWorkarea } from 'app/constants/workarea-constants';
import { MyCloudContext } from 'app/contexts/MyCloudContext';
import { useIsMobile } from 'helpers/system-helper';
import styles from './GridFile.module.scss';
const getFileSize = (bytes) => {
    const k = 1000;
    const units = ['B', 'KB', 'MB', 'GB'];
    let size = bytes;
    let i = 0;
    for (; i < units.length - 1; i += 1) {
        if (size > k)
            size /= k;
        else
            break;
    }
    return size.toFixed(1) + units[i];
};
const GridFile = ({ file }) => {
    const lang = useI18n().my_cloud.action;
    const isMobile = useIsMobile();
    const workarea = getWorkarea(file.workarea);
    const [actionDropdownOpen, setActionDropdownOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const { fileOperation, editingId, setEditingId, selectedId, setSelectedId } = useContext(MyCloudContext);
    const inputRef = useRef(null);
    const [error, setError] = useState(false);
    const isEditing = useMemo(() => editingId === file.uuid, [editingId, file]);
    const isSelected = useMemo(() => selectedId === file.uuid, [selectedId, file]);
    const onClick = (e) => {
        e.stopPropagation();
        setSelectedId(file.uuid);
        if (isMobile) {
            setActionDropdownOpen(!actionDropdownOpen);
        }
    };
    const onDoubleClick = () => {
        if (!isMobile)
            fileOperation.open(file);
    };
    const actions = [
        { key: 'open', icon: React.createElement(ArrowRightOutlined, null), label: lang.open },
        { key: 'rename', icon: React.createElement(EditOutlined, null), label: lang.rename },
        { key: 'duplicate', icon: React.createElement(CopyOutlined, null), label: lang.duplicate },
        { key: 'download', icon: React.createElement(DownloadOutlined, null), label: lang.download },
        { key: 'delete', icon: React.createElement(DeleteOutlined, null), label: lang.delete, danger: true },
    ];
    const onAction = (e) => {
        setActionDropdownOpen(false);
        if (e.key === 'open') {
            fileOperation.open(file);
        }
        else if (e.key === 'rename') {
            setEditingId(file.uuid);
            setError(false);
        }
        else if (e.key === 'duplicate') {
            fileOperation.duplicate(file);
        }
        else if (e.key === 'download') {
            fileOperation.download(file);
        }
        else if (e.key === 'delete') {
            setDeleteModalOpen(true);
        }
    };
    const onChangeName = (e) => __awaiter(void 0, void 0, void 0, function* () {
        if (error) {
            setEditingId(null);
            return;
        }
        const newName = e.target.value;
        yield fileOperation.rename(file, newName);
    });
    useEffect(() => {
        if (isEditing && inputRef.current) {
            inputRef.current.focus({ cursor: 'all' });
        }
    }, [isEditing, inputRef]);
    return (React.createElement("div", { className: classNames(styles.grid, { [styles.selected]: isSelected && !isEditing }) },
        React.createElement("div", { className: styles['img-container'] },
            React.createElement("div", { className: styles['guide-lines'], style: { background: "url('core-img/flux-plus/guide-lines.png')" }, onClick: onClick, onDoubleClick: onDoubleClick },
                file.thumbnail_url && (React.createElement("img", { src: `${file.thumbnail_url}?lastmod=${file.last_modified_at}` })),
                React.createElement(Dropdown, { menu: { items: actions, onClick: onAction }, trigger: ['click'], placement: "bottomRight", getPopupContainer: (triggerNode) => triggerNode.parentElement, overlayClassName: styles.overlay, open: actionDropdownOpen, onOpenChange: setActionDropdownOpen },
                    React.createElement("div", { className: classNames(styles.overlay, styles.trigger) },
                        React.createElement(EllipsisOutlined, null))),
                React.createElement(Popconfirm, { getPopupContainer: (triggerNode) => triggerNode.parentElement, overlayClassName: styles.overlay, title: lang.confirmFileDelete, onConfirm: () => fileOperation.delete(file), arrow: false, open: deleteModalOpen, onOpenChange: setDeleteModalOpen, onPopupClick: (e) => e.stopPropagation() }))),
        React.createElement("div", { className: styles.name }, isEditing ? (React.createElement(Input, { className: styles.edit, size: "small", defaultValue: file.name, ref: inputRef, onChange: (e) => {
                const { value } = e.target;
                setError(!value || value.includes('/'));
            }, onBlur: onChangeName, onPressEnter: onChangeName, status: error ? 'error' : undefined, maxLength: 255 })) : (React.createElement("div", { className: styles.display, onClick: onClick, onDoubleClick: onDoubleClick }, file.name))),
        React.createElement("div", { className: styles.info },
            React.createElement("div", null, workarea === null || workarea === void 0 ? void 0 :
                workarea.label,
                " \u2022 ",
                getFileSize(file.size)),
            dayjs(file.last_modified_at).format('MM/DD/YYYY hh:mm A'))));
};
export default GridFile;
