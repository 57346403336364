/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/dot-notation */
import Dialog from 'app/actions/dialog-caller';
import isWeb from 'helpers/is-web';
import storage from 'implementations/storage';
import { getInfo, submitRating } from 'helpers/api/flux-id';
const getRecord = () => storage.get('rating-record');
const setRecord = (record) => {
    storage.set('rating-record', record);
};
const setNotShowing = () => {
    const record = getRecord();
    setRecord(Object.assign(Object.assign({}, record), { version: window['FLUX'].version, isIgnored: true }));
};
const setVoted = (score) => {
    const record = getRecord();
    setRecord(Object.assign(Object.assign({}, record), { score, version: window['FLUX'].version, isVoted: true }));
    getInfo(true).then((response) => {
        if (response && response.status === 'ok') {
            submitRating({
                user: response.email,
                score,
                version: window['FLUX'].version,
                app: 'Beam Studio',
            });
        }
        else {
            submitRating({
                score,
                version: window['FLUX'].version,
                app: 'Beam Studio',
            });
        }
    });
};
const setDefaultRatingRecord = () => {
    const defaultRecord = {
        times: 1,
        version: window['FLUX'].version,
        score: 0,
        isVoted: false,
        isIgnored: false,
    };
    storage.set('rating-record', defaultRecord);
};
const init = () => {
    if (isWeb())
        return;
    if (!storage.isExisting('rating-record')) {
        setDefaultRatingRecord();
    }
    else {
        const record = getRecord();
        console.log('Rating Record', record);
        if (window['FLUX'].version !== record.version) {
            setDefaultRatingRecord();
            return;
        }
        if (record.isIgnored || record.isVoted) {
            return;
        }
        if (record.times > 4 && record.times % 5 === 0 && window.navigator.onLine) {
            Dialog.showRatingDialog(setVoted);
        }
        setRecord(Object.assign(Object.assign({}, record), { times: record.times + 1 }));
    }
};
export default {
    init,
    setNotShowing,
};
