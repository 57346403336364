// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-components-beambox-top-bar-FrameButton-module__button--qAwz4{height:30px;opacity:.7;cursor:pointer;-webkit-app-region:no-drag}.src-web-app-components-beambox-top-bar-FrameButton-module__button--qAwz4.src-web-app-components-beambox-top-bar-FrameButton-module__disabled--aoITL{opacity:.2;pointer-events:none}.src-web-app-components-beambox-top-bar-FrameButton-module__button--qAwz4:hover:not(.src-web-app-components-beambox-top-bar-FrameButton-module__disabled--aoITL){opacity:1}@media screen and (max-width: 600px){.src-web-app-components-beambox-top-bar-FrameButton-module__button--qAwz4:hover:not(.src-web-app-components-beambox-top-bar-FrameButton-module__disabled--aoITL){opacity:.7}}", "",{"version":3,"sources":["webpack://./src/web/app/components/beambox/top-bar/FrameButton.module.scss","webpack://./src/web/app/components/beambox/top-bar/_mixins.scss"],"names":[],"mappings":"AAEA,0ECDE,WAAA,CACA,UAAA,CACA,cAAA,CACA,0BAAA,CACA,qJACE,UAAA,CACA,mBAAA,CAEF,iKACE,SAAA,CAEF,qCACE,iKACE,UAAA,CAAA","sourcesContent":["@use './_mixins.scss' as mixins;\n\n.button {\n  @include mixins.button();\n}\n","@mixin button() {\n  height: 30px;\n  opacity: 0.7;\n  cursor: pointer;\n  -webkit-app-region: no-drag;\n  &.disabled {\n    opacity: 0.2;\n    pointer-events: none;\n  }\n  &:hover:not(.disabled) {\n    opacity: 1;\n  }\n  @media screen and (max-width: 600px) {\n    &:hover:not(.disabled) {\n      opacity: 0.7;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "src-web-app-components-beambox-top-bar-FrameButton-module__button--qAwz4",
	"disabled": "src-web-app-components-beambox-top-bar-FrameButton-module__disabled--aoITL"
};
export default ___CSS_LOADER_EXPORT___;
