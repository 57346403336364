import { eventEmitter } from 'app/views/tutorials/TutorialContext';
export const handleNextStep = () => {
    eventEmitter.emit('HANDLE_NEXT_STEP');
};
export const getNextStepRequirement = () => {
    const response = {
        nextStepRequirement: '',
    };
    eventEmitter.emit('GET_NEXT_STEP_REQUIREMENT', response);
    return response.nextStepRequirement;
};
export default {
    handleNextStep,
    getNextStepRequirement,
};
