import classNames from 'classnames';
import React from 'react';
import Icon from '@ant-design/icons';
import { ConfigProvider, InputNumber, Modal, Slider } from 'antd';
import ActionPanelIcons from 'app/icons/action-panel/ActionPanelIcons';
import i18n from 'helpers/i18n';
import storage from 'implementations/storage';
import units from 'helpers/units';
import styles from './ArrayModal.module.scss';
const LANG = i18n.lang.beambox.tool_panels;
const unitSettings = {
    mm: {
        precision: 2,
        distance: { default: 20, min: 1, max: 50 },
    },
    inch: {
        precision: 2,
        distance: { default: 1, min: 0, max: 2, step: 0.1 },
    },
};
const ArrayModal = ({ onCancel, onOk }) => {
    const unit = React.useMemo(() => (storage.get('default-units') === 'inches' ? 'inch' : 'mm'), []);
    const setting = unitSettings[unit];
    const [data, setData] = React.useState({
        row: 3,
        column: 3,
        dx: setting.distance.default,
        dy: setting.distance.default,
    });
    const { row, column, dx, dy } = data;
    const setRow = (val) => setData(Object.assign(Object.assign({}, data), { row: val }));
    const setColumn = (val) => setData(Object.assign(Object.assign({}, data), { column: val }));
    const setDx = (val) => setData(Object.assign(Object.assign({}, data), { dx: val }));
    const setDy = (val) => setData(Object.assign(Object.assign({}, data), { dy: val }));
    return (React.createElement(ConfigProvider, { theme: {
            components: {
                Button: { borderRadius: 100 },
                InputNumber: { borderRadiusSM: 100 },
            },
        } },
        React.createElement(Modal, { className: styles.modal, closeIcon: React.createElement(Icon, { className: styles['close-icon'], component: ActionPanelIcons.Delete }), okText: LANG.confirm, cancelText: LANG.cancel, onOk: () => {
                const dxInMM = +units.convertUnit(data.dx, 'mm', unit).toFixed(2);
                const dyInMM = +units.convertUnit(data.dy, 'mm', unit).toFixed(2);
                onOk(Object.assign(Object.assign({}, data), { dx: dxInMM, dy: dyInMM }));
            }, onCancel: onCancel, centered: true, open: true },
            React.createElement("div", { className: styles.title }, LANG.grid_array),
            React.createElement("div", { className: styles.subtitle }, LANG.array_dimension),
            React.createElement("div", { className: styles.field },
                React.createElement("span", { className: styles.label }, LANG.columns),
                React.createElement(Slider, { className: styles.slider, min: 1, max: 10, value: column, onChange: setColumn }),
                React.createElement(InputNumber, { className: styles.input, type: "number", size: "small", min: 1, precision: 0, value: column, onChange: setColumn, controls: false })),
            React.createElement("div", { className: styles.field },
                React.createElement("span", { className: styles.label }, LANG.rows),
                React.createElement(Slider, { className: styles.slider, min: 1, max: 10, value: row, onChange: setRow }),
                React.createElement(InputNumber, { className: styles.input, type: "number", size: "small", min: 1, precision: 0, value: row, onChange: setRow, controls: false })),
            React.createElement("div", { className: styles.subtitle }, LANG.array_interval),
            React.createElement("div", { className: styles.field },
                React.createElement("span", { className: styles.label }, LANG.dx),
                React.createElement(Slider, { className: styles.slider, min: setting.distance.min, max: setting.distance.max, step: setting.distance.step, value: dx, onChange: setDx }),
                React.createElement(InputNumber, { className: classNames(styles.input, styles['with-unit']), type: "number", size: "small", min: 0, precision: setting.precision, value: dx, onChange: setDx, prefix: React.createElement("span", { className: styles.unit }, unit), controls: false })),
            React.createElement("div", { className: styles.field },
                React.createElement("span", { className: styles.label }, LANG.dy),
                React.createElement(Slider, { className: styles.slider, min: setting.distance.min, max: setting.distance.max, step: setting.distance.step, value: dy, onChange: setDy }),
                React.createElement(InputNumber, { className: classNames(styles.input, styles['with-unit']), type: "number", size: "small", min: 0, precision: setting.precision, value: dy, onChange: setDy, prefix: React.createElement("span", { className: styles.unit }, unit), controls: false })))));
};
export default ArrayModal;
