import React from 'react';
import { Button, Tooltip } from 'antd';
import { QuestionCircleOutlined, ReloadOutlined, ZoomInOutlined, ZoomOutOutlined, } from '@ant-design/icons';
import useI18n from 'helpers/useI18n';
import styles from './CanvasController.module.scss';
const CanvasController = ({ setResetKey, setZoomKey }) => {
    const lang = useI18n().boxgen;
    const onClick = (type) => {
        switch (type) {
            case 'reset':
                setResetKey((key) => key + 1);
                break;
            case 'zoomin':
                setZoomKey((key) => Math.abs(key) + 1);
                break;
            case 'zoomout':
                setZoomKey((key) => Math.abs(key) * -1 - 1);
                break;
            default:
                break;
        }
    };
    const renderButton = (type, icon) => (React.createElement(Button, { className: styles.button, shape: "circle", type: "text", icon: icon, onClick: () => onClick(type) }));
    return (React.createElement("div", { className: styles.container },
        React.createElement("div", null,
            React.createElement("div", null, renderButton('reset', React.createElement(ReloadOutlined, null))),
            React.createElement("span", { className: styles.label }, lang.reset)),
        React.createElement("div", null,
            React.createElement("div", null,
                renderButton('zoomout', React.createElement(ZoomOutOutlined, null)),
                renderButton('zoomin', React.createElement(ZoomInOutlined, null))),
            React.createElement("span", { className: styles.label }, lang.zoom)),
        React.createElement("div", null,
            React.createElement(Tooltip, { overlayClassName: styles['tooltip-text'], title: navigator.maxTouchPoints >= 1 ? lang.control_tooltip_touch : lang.control_tooltip, placement: "topRight", arrow: { pointAtCenter: true } },
                React.createElement(QuestionCircleOutlined, { className: styles.tooltip })))));
};
export default CanvasController;
