var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { sprintf } from 'sprintf-js';
import alertCaller from 'app/actions/alert-caller';
import beamboxPreference from 'app/actions/beambox/beambox-preference';
import constant, { PreviewSpeedLevel } from 'app/actions/beambox/constant';
import deviceMaster from 'helpers/device-master';
import i18n from 'helpers/i18n';
import MessageCaller, { MessageLevel } from 'app/actions/message-caller';
import PreviewModeBackgroundDrawer from 'app/actions/beambox/preview-mode-background-drawer';
import progressCaller from 'app/actions/progress-caller';
import { getWorkarea } from 'app/constants/workarea-constants';
import BasePreviewManager from './BasePreviewManager';
// TODO: Add tests
class BB2PreviewManager extends BasePreviewManager {
    constructor(device) {
        super(device);
        this.cameraPpmm = 5;
        this.previewPpmm = 10;
        this.grid = {
            x: [-80, 80, 10],
            y: [0, 100, 10],
        };
        this.maxMovementSpeed = [54000, 6000]; // mm/min, speed cap of machine
        this.getMovementSpeed = () => {
            const previewMovementSpeedLevel = beamboxPreference.read('preview_movement_speed_level');
            if (previewMovementSpeedLevel === PreviewSpeedLevel.FAST)
                return 30000;
            if (previewMovementSpeedLevel === PreviewSpeedLevel.MEDIUM)
                return 24000;
            return 18000;
        };
        this.setup = (args) => __awaiter(this, void 0, void 0, function* () {
            const { lang } = i18n;
            const { progressId } = args || {};
            if (progressId)
                this.progressId = progressId;
            try {
                progressCaller.openNonstopProgress({
                    id: this.progressId,
                    message: sprintf(lang.message.connectingMachine, this.device.name),
                });
                try {
                    this.fisheyeParams = yield deviceMaster.fetchFisheyeParams();
                }
                catch (err) {
                    console.log('Fail to fetchFisheyeParams', err === null || err === void 0 ? void 0 : err.message);
                    throw new Error('Unable to get fisheye parameters, please make sure you have calibrated the camera');
                }
                progressCaller.update(this.progressId, { message: lang.message.gettingLaserSpeed });
                const laserSpeed = yield deviceMaster.getLaserSpeed();
                if (Number(laserSpeed.value) !== 1) {
                    this.originalSpeed = Number(laserSpeed.value);
                    progressCaller.update(this.progressId, {
                        message: lang.message.settingLaserSpeed,
                    });
                    yield deviceMaster.setLaserSpeed(1);
                }
                progressCaller.update(this.progressId, { message: lang.message.enteringRawMode });
                yield deviceMaster.enterRawMode();
                progressCaller.update(this.progressId, { message: lang.message.exitingRotaryMode });
                yield deviceMaster.rawSetRotary(false);
                progressCaller.update(this.progressId, { message: lang.message.homing });
                yield deviceMaster.rawHome();
                yield deviceMaster.rawStartLineCheckMode();
                this.lineCheckEnabled = true;
                progressCaller.update(this.progressId, { message: lang.message.turningOffFan });
                yield deviceMaster.rawSetFan(false);
                progressCaller.update(this.progressId, { message: lang.message.turningOffAirPump });
                yield deviceMaster.rawSetAirPump(false);
                yield deviceMaster.rawSetWaterPump(false);
                progressCaller.update(this.progressId, { message: lang.message.connectingCamera });
                yield this.setupFisheyeCamera();
                return true;
            }
            catch (error) {
                yield this.end();
                console.log('Error in setup', error);
                if (error.message && error.message.startsWith('Camera WS')) {
                    alertCaller.popUpError({
                        message: `${lang.topbar.alerts.fail_to_connect_with_camera}<br/>${error.message || ''}`,
                    });
                }
                else {
                    alertCaller.popUpError({
                        message: `${lang.topbar.alerts.fail_to_start_preview}<br/>${error.message || ''}`,
                    });
                }
                return false;
            }
            finally {
                progressCaller.popById(this.progressId);
            }
        });
        this.end = () => __awaiter(this, void 0, void 0, function* () {
            this.ended = true;
            MessageCaller.closeMessage('camera-preview');
            try {
                const res = yield deviceMaster.select(this.device);
                if (res.success) {
                    deviceMaster.disconnectCamera();
                    if (deviceMaster.currentControlMode !== 'raw')
                        yield deviceMaster.enterRawMode();
                    if (this.lineCheckEnabled)
                        yield deviceMaster.rawEndLineCheckMode();
                    yield deviceMaster.rawLooseMotor();
                    yield deviceMaster.endRawMode();
                    if (this.originalSpeed && this.originalSpeed !== 1) {
                        yield deviceMaster.setLaserSpeed(this.originalSpeed);
                        this.originalSpeed = 1;
                    }
                    deviceMaster.kick();
                }
            }
            catch (error) {
                console.log('Failed to end BeamPreviewManager', error);
            }
        });
        this.setupFisheyeCamera = () => __awaiter(this, void 0, void 0, function* () {
            yield deviceMaster.connectCamera();
            let res = yield deviceMaster.setFisheyeParam(this.fisheyeParams);
            if (!res)
                throw new Error('Failed to set fisheye parameters');
            res = yield deviceMaster.setFisheyePerspectiveGrid(this.grid);
            if (!res)
                throw new Error('Failed to set fisheye perspective grid');
        });
        /**
         *
         * @param x x in px
         * @param y y in px
         * @returns preview camera position x, y in mm
         */
        this.getPreviewPosition = (x, y) => {
            let newX = x / constant.dpmm - this.cameraCenterOffset.x;
            let newY = y / constant.dpmm - this.cameraCenterOffset.y;
            const { width, height: origH, displayHeight } = getWorkarea(this.workarea);
            const height = displayHeight !== null && displayHeight !== void 0 ? displayHeight : origH;
            newX = Math.min(Math.max(newX, -this.grid.x[0]), width - this.grid.x[1]);
            newY = Math.min(Math.max(newY, -this.grid.y[0]), height - this.grid.y[1]);
            return { x: newX, y: newY };
        };
        this.preprocessImage = (imgUrl, opts = {}) => __awaiter(this, void 0, void 0, function* () {
            const { overlapRatio = 0, overlapFlag = 0 } = opts;
            const img = new Image();
            yield new Promise((resolve) => {
                img.onload = () => resolve();
                img.src = imgUrl;
            });
            const canvas = document.createElement('canvas');
            const ratio = this.previewPpmm / this.cameraPpmm;
            canvas.width = img.width * ratio;
            canvas.height = img.height * ratio;
            const ctx = canvas.getContext('2d', { willReadFrequently: true });
            if (!ctx)
                throw new Error('Failed to get canvas context');
            ctx.scale(ratio, ratio);
            ctx.drawImage(img, 0, 0);
            const { width, height } = canvas;
            const overlapWidth = Math.round(width * overlapRatio);
            const overlapHeight = Math.round(height * overlapRatio);
            if (overlapWidth > 0 || overlapHeight > 0) {
                const imageData = ctx.getImageData(0, 0, width, height);
                for (let x = 0; x < width; x += 1) {
                    for (let y = 0; y < height; y += 1) {
                        // eslint-disable-next-line no-bitwise
                        const tDist = overlapFlag & 1 ? y : overlapHeight;
                        // eslint-disable-next-line no-bitwise
                        const rDist = overlapFlag & 2 ? width - x - 1 : overlapWidth;
                        // eslint-disable-next-line no-bitwise
                        const bDist = overlapFlag & 4 ? height - y - 1 : overlapHeight;
                        // eslint-disable-next-line no-bitwise
                        const lDist = overlapFlag & 8 ? x : overlapWidth;
                        const xDist = overlapWidth ? Math.min((Math.min(lDist, rDist) + 1) / overlapWidth, 1) : 1;
                        const yDist = overlapHeight
                            ? Math.min((Math.min(tDist, bDist) + 1) / overlapHeight, 1)
                            : 1;
                        let alphaRatio = xDist * yDist;
                        if (alphaRatio < 1) {
                            alphaRatio = Math.pow(alphaRatio, 1);
                            const i = (y * width + x) * 4;
                            imageData.data[i + 3] = Math.round(imageData.data[i + 3] * alphaRatio);
                        }
                    }
                }
                ctx.putImageData(imageData, 0, 0);
            }
            return canvas;
        });
        this.preview = (x, y, opts = {}) => __awaiter(this, void 0, void 0, function* () {
            if (this.ended)
                return false;
            const { overlapRatio = 0, overlapFlag } = opts;
            const cameraPosition = this.getPreviewPosition(x, y);
            const imgUrl = yield this.getPhotoAfterMoveTo(cameraPosition.x, cameraPosition.y);
            const imgCanvas = yield this.preprocessImage(imgUrl, { overlapRatio, overlapFlag });
            const drawCenter = {
                x: (cameraPosition.x + this.cameraCenterOffset.x) * constant.dpmm,
                y: (cameraPosition.y + this.cameraCenterOffset.y) * constant.dpmm,
            };
            yield PreviewModeBackgroundDrawer.drawImageToCanvas(imgCanvas, drawCenter.x, drawCenter.y, {
                opacityMerge: overlapRatio > 0,
            });
            return true;
        });
        this.previewRegion = (x1, y1, x2, y2, opts = {}) => __awaiter(this, void 0, void 0, function* () {
            const { overlapRatio = 0.05 } = opts;
            const getPoints = () => {
                const imgW = (this.grid.x[1] - this.grid.x[0]) * constant.dpmm;
                const imgH = (this.grid.y[1] - this.grid.y[0]) * constant.dpmm;
                const { x: l, y: t } = this.constrainPreviewXY(Math.min(x1, x2), Math.min(y1, y2));
                const { x: r, y: b } = this.constrainPreviewXY(Math.max(x1, x2), Math.max(y1, y2));
                const res = [];
                const xStep = imgW * (1 - overlapRatio);
                const yStep = imgH * (1 - overlapRatio);
                const xTotal = Math.max(1, Math.ceil((r - l) / xStep));
                const yTotal = Math.max(1, Math.ceil((b - t) / yStep));
                for (let j = 0; j < yTotal; j += 1) {
                    const y = t + imgH / 2 + j * yStep;
                    const row = [];
                    for (let i = 0; i < xTotal; i += 1) {
                        const x = l + imgW / 2 + i * xStep;
                        let overlapFlag = 0;
                        // 1: top, 2: right, 4: bottom, 8: left
                        if (j !== 0)
                            overlapFlag += 1;
                        if (i !== xTotal - 1)
                            overlapFlag += 2;
                        if (j !== yTotal - 1)
                            overlapFlag += 4;
                        if (i !== 0)
                            overlapFlag += 8;
                        row.push({ point: [x, y], overlapFlag });
                    }
                    if (j % 2 !== 0)
                        row.reverse();
                    res.push(...row);
                }
                return res;
            };
            const points = getPoints();
            try {
                for (let i = 0; i < points.length; i += 1) {
                    if (this.ended)
                        return false;
                    MessageCaller.openMessage({
                        key: 'camera-preview',
                        content: `${i18n.lang.topbar.preview} ${i}/${points.length}`,
                        level: MessageLevel.LOADING,
                        duration: 20,
                    });
                    const { point, overlapFlag } = points[i];
                    // eslint-disable-next-line no-await-in-loop
                    const result = yield this.preview(point[0], point[1], { overlapRatio, overlapFlag });
                    if (!result)
                        return false;
                }
                MessageCaller.openMessage({
                    key: 'camera-preview',
                    level: MessageLevel.SUCCESS,
                    content: i18n.lang.device.completed,
                    duration: 3,
                });
                return true;
            }
            catch (error) {
                MessageCaller.closeMessage('camera-preview');
                throw error;
            }
        });
        this.progressId = 'beam-preview-manager';
        this.cameraCenterOffset = {
            x: this.grid.x[0] + (this.grid.x[1] - this.grid.x[0]) / 2,
            y: this.grid.y[0] + (this.grid.y[1] - this.grid.y[0]) / 2,
        };
    }
}
export default BB2PreviewManager;
