/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import i18n from 'helpers/i18n';
import SelectControl from 'app/components/settings/SelectControl';
function General({ isWeb, supportedLangs, notificationOptions, changeActiveLang, updateConfigChange, }) {
    const { lang } = i18n;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "subtitle" }, lang.settings.groups.general),
        React.createElement(SelectControl, { label: lang.settings.language, id: "select-lang", options: Object.keys(supportedLangs).map((l) => ({
                value: l,
                label: supportedLangs[l],
                selected: l === i18n.getActiveLang(),
            })), onChange: changeActiveLang }),
        isWeb ? null
            : (React.createElement(SelectControl, { label: lang.settings.notifications, id: "set-notifications", options: notificationOptions, onChange: (e) => updateConfigChange('notification', e.target.value) }))));
}
export default General;
