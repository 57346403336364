import Ador from './ador.svg';
import Beamo from './beamo.svg';
import Beambox from './beambox.svg';
import Hexa from './hexa.svg';
export default {
    Ador,
    Beamo,
    Beambox,
    Hexa,
};
