// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-components-settings-connection-TestInfo-module__container--x7qjP{font-size:12px;line-height:23px;letter-spacing:.1px;white-space:pre;color:#999;font-weight:600;height:100%}.src-web-app-components-settings-connection-TestInfo-module__container--x7qjP .src-web-app-components-settings-connection-TestInfo-module__info--8d7xU{padding:5px 0;color:#333}", "",{"version":3,"sources":["webpack://./src/web/app/components/settings/connection/TestInfo.module.scss"],"names":[],"mappings":"AAAA,8EACE,cAAA,CACA,gBAAA,CACA,mBAAA,CACA,eAAA,CACA,UAAA,CACA,eAAA,CACA,WAAA,CAEA,uJACE,aAAA,CACA,UAAA","sourcesContent":[".container {\n  font-size: 12px;\n  line-height: 23px;\n  letter-spacing: 0.1px;\n  white-space: pre;\n  color: #999999;\n  font-weight: 600;\n  height: 100%;\n\n  .info {\n    padding: 5px 0;\n    color: #333333;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "src-web-app-components-settings-connection-TestInfo-module__container--x7qjP",
	"info": "src-web-app-components-settings-connection-TestInfo-module__info--8d7xU"
};
export default ___CSS_LOADER_EXPORT___;
