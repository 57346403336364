import * as React from 'react';
import i18n from 'helpers/i18n';
import SelectControl from 'app/components/settings/SelectControl';
function Privacy({ enableSentryOptions, updateConfigChange }) {
    const lang = i18n.lang;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "subtitle" }, lang.settings.groups.privacy),
        React.createElement(SelectControl, { label: lang.settings.share_with_flux, id: "set-sentry", options: enableSentryOptions, onChange: (e) => updateConfigChange('enable-sentry', e.target.value) })));
}
export default Privacy;
