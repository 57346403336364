const wheelEventHandlerGenerator = (getCurrentRatio, zoomFunction, opts) => {
    let targetRatio;
    let timer = null;
    let trigger;
    const handler = (e) => {
        // @ts-expect-error use wheelDelta if exists
        const { deltaX, wheelDelta, detail, ctrlKey } = e;
        const { maxZoom, minZoom, zoomInterval = 20, getCenter } = opts !== null && opts !== void 0 ? opts : {};
        let isMouse = window.os !== 'MacOS';
        if (Math.abs(deltaX) > 0)
            isMouse = false;
        const zoomProcess = () => {
            const currentRatio = getCurrentRatio();
            if (targetRatio === currentRatio) {
                clearTimeout(timer);
                timer = null;
                return;
            }
            const center = getCenter ? getCenter(e) : { x: e.clientX, y: e.clientY };
            trigger = Date.now();
            zoomFunction(targetRatio, center);
        };
        const zoom = () => {
            var _a;
            const delta = (_a = wheelDelta !== null && wheelDelta !== void 0 ? wheelDelta : -detail) !== null && _a !== void 0 ? _a : 0;
            targetRatio = getCurrentRatio();
            if (maxZoom && targetRatio >= maxZoom && delta > 0)
                return;
            if (minZoom && targetRatio <= minZoom && delta < 0)
                return;
            targetRatio *= Math.pow(1.02, (delta / (isMouse ? 50 : 100)));
            if (maxZoom)
                targetRatio = Math.min(targetRatio, maxZoom);
            if (minZoom)
                targetRatio = Math.max(targetRatio, minZoom);
            if (Date.now() - trigger < zoomInterval) {
                clearTimeout(timer);
                timer = setTimeout(zoomProcess, zoomInterval);
            }
            else
                zoomProcess();
        };
        if (isMouse) {
            // mouse
            e.preventDefault();
            e.stopPropagation();
        }
        else if (!ctrlKey)
            return;
        zoom();
    };
    return handler;
};
export default wheelEventHandlerGenerator;
