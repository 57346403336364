var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import beamboxPreference from 'app/actions/beambox/beambox-preference';
import imageData from 'helpers/image-data';
import NS from 'app/constants/namespaces';
/**
 * updateImagesResolution update all images resolution for exporting or light weight display
 * @param fullResolution target resolution: full resolution if true, otherwise low resolution
 */
const updateImagesResolution = (fullResolution, parent) => __awaiter(void 0, void 0, void 0, function* () {
    if (beamboxPreference.read('image_downsampling') === false)
        return;
    const svgcontent = document.getElementById('svgcontent');
    const images = parent ? parent.querySelectorAll('image') : svgcontent === null || svgcontent === void 0 ? void 0 : svgcontent.querySelectorAll('image');
    if (!images)
        return;
    const promises = Array.from(images).map((image) => {
        const origImage = image.getAttribute('origImage');
        if (!origImage)
            return Promise.resolve();
        const isFullColor = image.getAttribute('data-fullcolor') === '1';
        const isShading = image.getAttribute('data-shading') === 'true';
        const threshold = parseInt(image.getAttribute('data-threshold') || '128', 10);
        return new Promise((resolve) => {
            imageData(origImage, {
                grayscale: isFullColor
                    ? undefined
                    : {
                        is_rgba: true,
                        is_shading: isShading,
                        threshold,
                        is_svg: false,
                    },
                isFullResolution: fullResolution,
                onComplete: (result) => {
                    image.setAttributeNS(NS.XLINK, 'xlink:href', result.pngBase64);
                    resolve();
                },
            });
        });
    });
    yield Promise.all(promises);
});
export default updateImagesResolution;
