// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-components-dialogs-PreviewHeight-module__text--nWaC1 .src-web-app-components-dialogs-PreviewHeight-module__tooltip--fwAYg{margin-left:6px}.src-web-app-components-dialogs-PreviewHeight-module__video--4uP7u{width:100%;margin-top:12px;max-height:300px;min-height:225px}.src-web-app-components-dialogs-PreviewHeight-module__inputs--0XivB{padding:12px 0;display:flex;align-items:center;justify-content:space-evenly}", "",{"version":3,"sources":["webpack://./src/web/app/components/dialogs/PreviewHeight.module.scss"],"names":[],"mappings":"AACE,uIACE,eAAA,CAIJ,mEACE,UAAA,CACA,eAAA,CACA,gBAAA,CACA,gBAAA,CAGF,oEACE,cAAA,CACA,YAAA,CACA,kBAAA,CACA,4BAAA","sourcesContent":[".text {\n  .tooltip {\n    margin-left: 6px;\n  }\n}\n\n.video {\n  width: 100%;\n  margin-top: 12px;\n  max-height: 300px;\n  min-height: 225px;\n}\n\n.inputs {\n  padding: 12px 0;\n  display: flex;\n  align-items: center;\n  justify-content: space-evenly;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "src-web-app-components-dialogs-PreviewHeight-module__text--nWaC1",
	"tooltip": "src-web-app-components-dialogs-PreviewHeight-module__tooltip--fwAYg",
	"video": "src-web-app-components-dialogs-PreviewHeight-module__video--4uP7u",
	"inputs": "src-web-app-components-dialogs-PreviewHeight-module__inputs--0XivB"
};
export default ___CSS_LOADER_EXPORT___;
