// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-components-settings-Control-module__control--Ou6lq .src-web-app-components-settings-Control-module__label--OP-BF{width:calc(30% - 10px)}@media screen and (max-width: 600px){.src-web-app-components-settings-Control-module__control--Ou6lq{display:flex;flex-direction:column;gap:5px}}", "",{"version":3,"sources":["webpack://./src/web/app/components/settings/Control.module.scss"],"names":[],"mappings":"AACE,8HACE,sBAAA,CAGF,qCALF,gEAMI,YAAA,CACA,qBAAA,CACA,OAAA,CAAA","sourcesContent":[".control {\n  .label {\n    width: calc(100% / 10 * 3 - 10px);\n  }\n\n  @media screen and (max-width: 600px) {\n    display: flex;\n    flex-direction: column;\n    gap: 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"control": "src-web-app-components-settings-Control-module__control--Ou6lq",
	"label": "src-web-app-components-settings-Control-module__label--OP-BF"
};
export default ___CSS_LOADER_EXPORT___;
