import React from 'react';
import { Button } from 'antd';
import useI18n from 'helpers/useI18n';
import styles from './PresetsManagementPanel.module.scss';
const Footer = ({ handleSave, handleReset, onClose }) => {
    const tLaserPanel = useI18n().beambox.right_panel.laser_panel;
    const t = tLaserPanel.preset_management;
    return (React.createElement("div", { className: styles.footer },
        React.createElement("div", null,
            React.createElement(Button, { onClick: handleReset }, t.reset)),
        React.createElement("div", null,
            React.createElement(Button, { onClick: onClose }, tLaserPanel.cancel),
            React.createElement(Button, { type: "primary", onClick: handleSave }, t.save_and_exit))));
};
export default Footer;
