// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-views-monitor-widgets-Breadcrumbs-module__breadcrumb--2TNTW li:last-child{font-weight:bold}.src-web-app-views-monitor-widgets-Breadcrumbs-module__breadcrumb--2TNTW .src-web-app-views-monitor-widgets-Breadcrumbs-module__item--W7fGT{cursor:pointer}", "",{"version":3,"sources":["webpack://./src/web/app/views/monitor/widgets/Breadcrumbs.module.scss"],"names":[],"mappings":"AACE,uFACE,gBAAA,CAEF,4IACE,cAAA","sourcesContent":[".breadcrumb {\n  li:last-child {\n    font-weight: bold;\n  }\n  .item {\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breadcrumb": "src-web-app-views-monitor-widgets-Breadcrumbs-module__breadcrumb--2TNTW",
	"item": "src-web-app-views-monitor-widgets-Breadcrumbs-module__item--W7fGT"
};
export default ___CSS_LOADER_EXPORT___;
