import React from 'react';
import isDev from 'helpers/is-dev';
import SelectControl from 'app/components/settings/SelectControl';
function Experimental({ multipassCompensationOptions, oneWayPrintingOptions, updateBeamboxPreferenceChange, }) {
    if (!isDev())
        return null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "subtitle" }, "Experimental Features"),
        React.createElement(SelectControl, { label: "Multipass Compensation", id: "multipass-compensation", options: multipassCompensationOptions, onChange: (e) => updateBeamboxPreferenceChange('multipass-compensation', e.target.value) }),
        React.createElement(SelectControl, { label: "One-way Printing", id: "one-way-printing", options: oneWayPrintingOptions, onChange: (e) => updateBeamboxPreferenceChange('one-way-printing', e.target.value) })));
}
export default Experimental;
