var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable no-console */
import EventEmitter from 'eventemitter3';
import arrayBuffer from 'helpers/arrayBuffer';
import Websocket from 'helpers/websocket';
class UtilsWebSocket extends EventEmitter {
    constructor() {
        super();
        this.transformRgbImageToCmyk = (blob, opts = {}) => __awaiter(this, void 0, void 0, function* () {
            const data = yield arrayBuffer(blob);
            const { onProgress, resultType = 'binary' } = opts;
            return new Promise((resolve, reject) => {
                this.removeCommandListeners();
                this.setDefaultErrorResponse(reject);
                this.setDefaultFatalResponse(reject);
                let totalLength = 0;
                const blobs = [];
                this.on('message', (response) => {
                    if (response instanceof Blob) {
                        blobs.push(response);
                        const result = new Blob(blobs);
                        if (totalLength === result.size) {
                            resolve(result);
                        }
                    }
                    else {
                        const { status, length, progress } = response;
                        if (status === 'continue') {
                            let sentLength = 0;
                            while (sentLength < data.byteLength) {
                                const end = Math.min(sentLength + 1000000, data.byteLength);
                                this.ws.send(data.slice(sentLength, end));
                                sentLength = end;
                            }
                        }
                        else if (status === 'progress' && progress) {
                            if (onProgress)
                                onProgress(progress);
                        }
                        else if (status === 'complete') {
                            totalLength = length;
                        }
                        else if (status === 'uploaded') {
                            console.log('Upload finished');
                        }
                        else if (status === 'ok') {
                            resolve(response.data);
                        }
                        else {
                            console.log('strange message from /ws/utils', response);
                            reject(Error('strange message from /ws/utils'));
                        }
                    }
                });
                const args = ['rgb_to_cmyk', data.byteLength, resultType === 'binary' ? 'binary' : 'base64'];
                this.ws.send(args.join(' '));
            });
        });
        this.splitColor = (blob, opts = {
            colorType: 'rgb',
        }) => __awaiter(this, void 0, void 0, function* () {
            const data = yield arrayBuffer(blob);
            const { colorType = 'rgb' } = opts;
            return new Promise((resolve, reject) => {
                this.removeCommandListeners();
                this.setDefaultErrorResponse(reject);
                this.setDefaultFatalResponse(reject);
                this.on('message', (response) => {
                    if (response instanceof Blob) {
                        console.log('strange message from /ws/utils', response);
                        reject(Error('strange message from /ws/utils'));
                    }
                    else {
                        const { status } = response;
                        if (status === 'continue') {
                            let sentLength = 0;
                            while (sentLength < data.byteLength) {
                                const end = Math.min(sentLength + 1000000, data.byteLength);
                                this.ws.send(data.slice(sentLength, end));
                                sentLength = end;
                            }
                        }
                        else if (status === 'uploaded') {
                            console.log('Upload finished');
                        }
                        else if (status === 'ok') {
                            const { c, m, y, k } = response;
                            resolve({ c, m, y, k });
                        }
                        else {
                            console.log('strange message from /ws/utils', response);
                            reject(Error('strange message from /ws/utils'));
                        }
                    }
                });
                const args = ['split_color', data.byteLength, colorType];
                this.ws.send(args.join(' '));
            });
        });
        this.getSimilarContours = (imgBlob, opts) => __awaiter(this, void 0, void 0, function* () {
            const data = yield arrayBuffer(imgBlob);
            return new Promise((resolve, reject) => {
                this.removeCommandListeners();
                this.setDefaultErrorResponse(reject);
                this.setDefaultFatalResponse(reject);
                const { isSplcingImg, onProgress } = opts || {};
                this.on('message', (response) => {
                    if (response instanceof Blob) {
                        console.log('strange message from /ws/utils', response);
                        reject(Error('strange message from /ws/utils'));
                    }
                    else {
                        const { status } = response;
                        if (status === 'continue') {
                            let sentLength = 0;
                            while (sentLength < data.byteLength) {
                                const end = Math.min(sentLength + 1000000, data.byteLength);
                                this.ws.send(data.slice(sentLength, end));
                                sentLength = end;
                            }
                        }
                        else if (status === 'uploaded') {
                            console.log('Upload finished');
                        }
                        else if (status === 'ok') {
                            console.log(response.data);
                            resolve(response.data);
                        }
                        else if (status === 'progress') {
                            onProgress === null || onProgress === void 0 ? void 0 : onProgress(response.progress);
                        }
                        else if (status === 'error') {
                            reject(Error(response.info));
                        }
                        else {
                            console.log('strange message from /ws/utils', response);
                            reject(Error('strange message from /ws/utils'));
                        }
                    }
                });
                const args = ['get_similar_contours', data.byteLength, isSplcingImg ? 1 : 0];
                this.ws.send(args.join(' '));
            });
        });
        this.getConvexHull = (imgBlob) => __awaiter(this, void 0, void 0, function* () {
            const data = yield arrayBuffer(imgBlob);
            return new Promise((resolve, reject) => {
                this.removeCommandListeners();
                this.setDefaultErrorResponse(reject);
                this.setDefaultFatalResponse(reject);
                this.on('message', (response) => {
                    if (response instanceof Blob) {
                        console.log('strange message from /ws/utils', response);
                        reject(Error('strange message from /ws/utils'));
                    }
                    else {
                        const { status } = response;
                        if (status === 'continue') {
                            let sentLength = 0;
                            while (sentLength < data.byteLength) {
                                const end = Math.min(sentLength + 1000000, data.byteLength);
                                this.ws.send(data.slice(sentLength, end));
                                sentLength = end;
                            }
                        }
                        else if (status === 'uploaded') {
                            console.log('Upload finished');
                        }
                        else if (status === 'ok') {
                            resolve(response.data);
                        }
                        else if (status === 'error') {
                            reject(Error(response.info));
                        }
                        else {
                            console.log('strange message from /ws/utils', response);
                            reject(Error('strange message from /ws/utils'));
                        }
                    }
                });
                const args = ['get_convex_hull', data.byteLength];
                this.ws.send(args.join(' '));
            });
        });
        this.ws = Websocket({
            method: 'utils',
            onMessage: (data) => {
                this.emit('message', data);
            },
            onError: (response) => {
                this.emit('error', response);
            },
            onFatal: (response) => {
                this.emit('fatal', response);
            },
        });
    }
    isAlive() {
        return this.ws.currentState === 1;
    }
    removeCommandListeners() {
        this.removeAllListeners('message');
        this.removeAllListeners('error');
        this.removeAllListeners('fatal');
    }
    setDefaultErrorResponse(reject, timeoutTimer) {
        this.on('error', (response) => {
            if (timeoutTimer)
                clearTimeout(timeoutTimer);
            this.removeCommandListeners();
            reject(response.info);
        });
    }
    setDefaultFatalResponse(reject, timeoutTimer) {
        this.on('fatal', (response) => {
            if (timeoutTimer)
                clearTimeout(timeoutTimer);
            this.removeCommandListeners();
            console.log(response);
            if (response.error) {
                if (response.error.join)
                    reject(response.error.join(''));
                else
                    reject(response.error);
            }
            else {
                reject();
            }
        });
    }
    upload(data, url) {
        return new Promise((resolve, reject) => {
            this.removeCommandListeners();
            this.setDefaultErrorResponse(reject);
            this.setDefaultFatalResponse(reject);
            this.on('message', (response) => {
                const { status } = response;
                if (['ok', 'fail', 'none'].includes(status)) {
                    this.removeCommandListeners();
                    resolve(response);
                }
                else if (status === 'continue') {
                    this.ws.send(data);
                }
                else {
                    console.log('strange message from /ws/utils', response);
                }
            });
            this.ws.send(`upload ${url} ${data.byteLength}`);
        });
    }
    pdfToSvgBlob(file) {
        return __awaiter(this, void 0, void 0, function* () {
            const data = yield arrayBuffer(file);
            return new Promise((resolve, reject) => {
                this.removeCommandListeners();
                this.setDefaultErrorResponse(reject);
                this.setDefaultFatalResponse(reject);
                this.on('message', (response) => __awaiter(this, void 0, void 0, function* () {
                    if (response.status === 'continue') {
                        this.ws.send(data);
                    }
                    if (response instanceof Blob) {
                        this.removeCommandListeners();
                        resolve(response);
                    }
                }));
                this.ws.send(`pdf2svg ${data.byteLength}`);
            });
        });
    }
    checkExist(path) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                this.removeCommandListeners();
                this.setDefaultErrorResponse(reject);
                this.setDefaultFatalResponse(reject);
                this.on('message', (response) => {
                    const { status } = response;
                    console.log(response);
                    if (status === 'ok') {
                        this.removeCommandListeners();
                        resolve(response.res);
                    }
                    else {
                        console.log('strange message from /ws/utils', response);
                    }
                });
                this.ws.send(`check_exist ${path}`);
            });
        });
    }
    selectFont(fontPath) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                this.removeCommandListeners();
                this.setDefaultErrorResponse(reject);
                this.setDefaultFatalResponse(reject);
                this.on('message', (response) => {
                    const { status } = response;
                    console.log(response);
                    if (status === 'ok') {
                        this.removeCommandListeners();
                        resolve(true);
                    }
                    else if (status === 'error') {
                        this.removeCommandListeners();
                        resolve(false);
                    }
                    else {
                        console.log('strange message from /ws/utils', response);
                    }
                });
                this.ws.send(`select_font ${fontPath}`);
            });
        });
    }
    uploadTo(blob, path, onProgress) {
        return __awaiter(this, void 0, void 0, function* () {
            const data = yield arrayBuffer(blob);
            return new Promise((resolve, reject) => {
                this.removeCommandListeners();
                this.setDefaultErrorResponse(reject);
                this.setDefaultFatalResponse(reject);
                this.on('message', (response) => {
                    const { status } = response;
                    if (['ok', 'fail'].includes(status)) {
                        this.removeCommandListeners();
                        resolve(status === 'ok');
                    }
                    else if (status === 'continue') {
                        let sentLength = 0;
                        while (sentLength < data.byteLength) {
                            const end = Math.min(sentLength + 1000000, data.byteLength);
                            this.ws.send(data.slice(sentLength, end));
                            sentLength = end;
                        }
                    }
                    else if (status === 'progress' && response.progress) {
                        if (onProgress)
                            onProgress(response.progress);
                    }
                    else {
                        console.log('strange message from /ws/utils', response);
                        resolve(false);
                    }
                });
                this.ws.send(`upload_to ${data.byteLength} ${path}`);
            });
        });
    }
}
let singleton = null;
const getUtilWS = () => {
    singleton = singleton || new UtilsWebSocket();
    return singleton;
};
export default getUtilWS;
