var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Dropdown, Modal } from 'antd';
import { DownloadOutlined, PlusCircleFilled, UploadOutlined } from '@ant-design/icons';
import alertCaller from 'app/actions/alert-caller';
import alertConstants from 'app/constants/alert-constants';
import ConfigPanelIcons from 'app/icons/config-panel/ConfigPanelIcons';
import dialogCaller from 'app/actions/dialog-caller';
import LayerModule, { modelsWithModules } from 'app/constants/layer-module/layer-modules';
import layerModuleHelper from 'helpers/layer-module/layer-module-helper';
import presets from 'app/constants/presets';
import presetHelper from 'helpers/presets/preset-helper';
import Select from 'app/widgets/AntdSelect';
import storage from 'implementations/storage';
import useI18n from 'helpers/useI18n';
import useWorkarea from 'helpers/hooks/useWorkarea';
import { addDialogComponent, isIdExist, popDialogById } from 'app/actions/dialog-controller';
import { getWorkarea } from 'app/constants/workarea-constants';
import { defaultConfig, postPresetChange } from 'helpers/layer/layer-config-helper';
import Footer from './Footer';
import LaserInputs from './LaserInputs';
import PresetList from './PresetList';
import PrintingInputs from './PrintingInputs';
import styles from './PresetsManagementPanel.module.scss';
var Filter;
(function (Filter) {
    Filter["ALL"] = "0";
    Filter["LASER"] = "1";
    Filter["PRINT"] = "2";
})(Filter || (Filter = {}));
const PresetsManagementPanel = ({ currentModule, initPreset, onClose }) => {
    var _a;
    const lang = useI18n();
    const tLaserPanel = lang.beambox.right_panel.laser_panel;
    const t = tLaserPanel.preset_management;
    const workarea = useWorkarea();
    const workareaObj = useMemo(() => getWorkarea(workarea), [workarea]);
    const hasModule = useMemo(() => modelsWithModules.has(workarea), [workarea]);
    const [filter, setFilter] = useState(hasModule ? Filter.ALL : Filter.LASER);
    const listRef = useRef(null);
    const isInch = useMemo(() => (storage.get('default-units') || 'mm') === 'inches', []);
    const lengthUnit = useMemo(() => (isInch ? 'in' : 'mm'), [isInch]);
    const moduleTranslations = useMemo(() => layerModuleHelper.getModulesTranslations(), []);
    const [editingPresets, setEditingPresets] = useState(presetHelper.getAllPresets());
    const [editingValues, setEditingValues] = useState({});
    const displayList = useMemo(() => editingPresets.filter((c) => {
        if (!c.isDefault) {
            if (filter === Filter.ALL)
                return true;
            return c.module === LayerModule.PRINTER
                ? filter === Filter.PRINT
                : filter === Filter.LASER;
        }
        const hasPreset = presetHelper.modelHasPreset(workarea, c.key);
        if (!hasPreset)
            return false;
        if (filter === Filter.ALL || !hasModule)
            return true;
        const isPrintingPreset = Boolean(presetHelper.getDefaultPreset(c.key, workarea, LayerModule.PRINTER));
        return isPrintingPreset ? filter === Filter.PRINT : filter === Filter.LASER;
    }), [workarea, hasModule, editingPresets, filter]);
    const [selectedPreset, setSelectedPreset] = useState(initPreset
        ? (_a = displayList.find((p) => initPreset === p.name || initPreset === p.key)) !== null && _a !== void 0 ? _a : displayList[0]
        : displayList[0]);
    useEffect(() => {
        if (!selectedPreset || !listRef.current)
            return;
        const { key, name, isDefault } = selectedPreset;
        const item = listRef.current.querySelector(`[data-key="${isDefault ? key : name}"]`);
        if (!item)
            return;
        const { offsetTop, scrollTop, clientHeight: listHeight } = listRef.current;
        const { offsetTop: itemTop, clientHeight: itemHeight } = item;
        const listTop = offsetTop + scrollTop;
        const itemBottom = itemTop + itemHeight;
        const listBottom = listTop + listHeight;
        if (itemBottom < listTop || itemTop > listBottom) {
            item === null || item === void 0 ? void 0 : item.scrollIntoView({ behavior: 'auto' });
        }
    }, [selectedPreset]);
    const availableModules = useMemo(() => {
        var _a;
        if ((selectedPreset === null || selectedPreset === void 0 ? void 0 : selectedPreset.isDefault) && hasModule) {
            return Object.keys(((_a = presets[selectedPreset.key]) === null || _a === void 0 ? void 0 : _a[workarea]) || {}).map((m) => parseInt(m, 10));
        }
        return [];
    }, [workarea, hasModule, selectedPreset]);
    const [selectedModule, setSelectedModule] = useState(currentModule);
    useEffect(() => {
        if (availableModules.length > 0) {
            setSelectedModule((cur) => {
                if (availableModules.includes(cur))
                    return cur;
                return availableModules[0];
            });
        }
        else if (selectedPreset === null || selectedPreset === void 0 ? void 0 : selectedPreset.module) {
            setSelectedModule(selectedPreset.module);
        }
    }, [availableModules, selectedPreset]);
    const displayPreset = useMemo(() => {
        var _a;
        if (!selectedPreset)
            return { name: '', isDefault: true };
        if (!selectedPreset.isDefault)
            return Object.assign(Object.assign({}, selectedPreset), editingValues[selectedPreset.name]);
        const keyPresets = (_a = presets[selectedPreset.key]) === null || _a === void 0 ? void 0 : _a[workarea];
        if (!keyPresets)
            return selectedPreset;
        if (keyPresets[selectedModule])
            return Object.assign(Object.assign({}, selectedPreset), keyPresets[selectedModule]);
        return Object.assign(Object.assign({}, selectedPreset), Object.values(keyPresets)[0]);
    }, [workarea, selectedPreset, selectedModule, editingValues]);
    const handleChange = (key, value) => {
        const { name, isDefault } = selectedPreset;
        if (isDefault)
            return;
        const editing = editingValues[name] || {};
        const origValue = selectedPreset[key];
        if (origValue === value) {
            delete editing[key];
            if (Object.keys(editing).length === 0) {
                delete editingValues[name];
            }
        }
        else {
            editing[key] = value;
            editingValues[name] = editing;
        }
        setEditingValues(Object.assign({}, editingValues));
    };
    const toggleHidePreset = (preset) => {
        // eslint-disable-next-line no-param-reassign
        preset.hide = !preset.hide;
        setEditingPresets([...editingPresets]);
    };
    const isPrinting = useMemo(() => displayPreset.module === LayerModule.PRINTER, [displayPreset]);
    const handleDelete = () => {
        if (selectedPreset.isDefault)
            return;
        const displayIdx = displayList.findIndex((p) => p === selectedPreset);
        const newPresets = editingPresets.filter((p) => p !== selectedPreset);
        setSelectedPreset(displayList[displayIdx === 0 ? 1 : displayIdx - 1]);
        setEditingPresets(newPresets);
    };
    const getCurrentPresets = useCallback(() => {
        const res = [...editingPresets];
        for (let i = 0; i < res.length; i += 1) {
            const { name, isDefault } = editingPresets[i];
            if (editingValues[name] && !isDefault) {
                res[i] = Object.assign(Object.assign({}, editingPresets[i]), editingValues[name]);
            }
            else {
                res[i] = Object.assign({}, editingPresets[i]);
            }
        }
        return res;
    }, [editingPresets, editingValues]);
    const handleSave = useCallback(() => {
        presetHelper.savePresetList(getCurrentPresets());
        postPresetChange();
        onClose();
    }, [getCurrentPresets, onClose]);
    const handleImport = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const res = yield presetHelper.importPresets();
        if (res) {
            const newPresets = presetHelper.getAllPresets();
            setEditingPresets(newPresets);
            setSelectedPreset(newPresets[0]);
        }
    }), []);
    const handleExport = useCallback(() => {
        presetHelper.exportPresets(getCurrentPresets());
    }, [getCurrentPresets]);
    const handleReset = useCallback(() => {
        alertCaller.popUp({
            type: alertConstants.WARNING,
            message: t.sure_to_reset,
            buttonType: alertConstants.CONFIRM_CANCEL,
            onConfirm: () => {
                presetHelper.resetPresetList();
                postPresetChange();
                onClose();
            },
        });
    }, [t, onClose]);
    const handleAddPreset = () => __awaiter(void 0, void 0, void 0, function* () {
        let presetModule = LayerModule.LASER_UNIVERSAL;
        if (hasModule) {
            presetModule = yield dialogCaller.showRadioSelectDialog({
                id: 'import-module',
                title: lang.beambox.popup.select_import_module,
                options: [
                    { label: t.laser, value: LayerModule.LASER_UNIVERSAL },
                    { label: t.print, value: LayerModule.PRINTER },
                ],
            });
            if (!presetModule)
                return;
        }
        const name = yield new Promise((resolve) => {
            dialogCaller.promptDialog({
                caption: t.new_preset_name,
                onYes: resolve,
                onCancel: () => resolve(''),
            });
        });
        if (!name)
            return;
        if (editingPresets.find((p) => p.name === name || p.key === name)) {
            alertCaller.popUpError({
                message: tLaserPanel.existing_name,
            });
            return;
        }
        const newPreset = { name: name.trim(), isDefault: false, module: presetModule };
        if (presetModule === LayerModule.PRINTER) {
            newPreset.halftone = 1;
            newPreset.speed = defaultConfig.printingSpeed;
        }
        setEditingPresets([...editingPresets, newPreset]);
        setSelectedPreset(newPreset);
    });
    return (React.createElement(Modal, { open: true, centered: true, wrapClassName: styles['modal-wrap'], title: t.title, onCancel: onClose, footer: React.createElement(Footer, { handleSave: handleSave, handleReset: handleReset, onClose: onClose }) },
        React.createElement("div", { className: styles.container },
            React.createElement(PresetList, { presets: editingPresets, displayList: displayList, editingValues: editingValues, selected: selectedPreset, setSelectedPreset: setSelectedPreset, toggleHidePreset: toggleHidePreset, onReorder: setEditingPresets, ref: listRef }),
            React.createElement("div", { className: styles.controls },
                React.createElement("div", null,
                    React.createElement("button", { className: styles.add, type: "button", onClick: handleAddPreset },
                        React.createElement(PlusCircleFilled, { className: styles.icon }),
                        t.add_new)),
                React.createElement("div", null,
                    React.createElement("button", { type: "button", onClick: handleImport, title: t.import },
                        React.createElement(UploadOutlined, { className: styles.icon })),
                    React.createElement("button", { type: "button", onClick: handleExport, title: t.export },
                        React.createElement(DownloadOutlined, { className: styles.icon })),
                    hasModule && (React.createElement(Dropdown, { menu: {
                            items: [
                                {
                                    key: Filter.ALL,
                                    label: t.show_all,
                                },
                                {
                                    key: Filter.LASER,
                                    label: t.laser,
                                },
                                {
                                    key: Filter.PRINT,
                                    label: t.print,
                                },
                            ],
                            selectable: true,
                            defaultSelectedKeys: [filter],
                            onClick: ({ key }) => setFilter(key),
                        } },
                        React.createElement(ConfigPanelIcons.Filter, { className: classNames(styles.icon, { [styles.highlight]: filter !== Filter.ALL }) }))))),
            React.createElement("div", { className: styles.detail },
                React.createElement("div", { className: styles.header },
                    React.createElement("div", { className: styles.title }, selectedPreset === null || selectedPreset === void 0 ? void 0 : selectedPreset.name),
                    !displayPreset.isDefault && (React.createElement(Button, { danger: true, onClick: handleDelete, "data-testid": "delete" }, t.delete)),
                    hasModule && displayPreset.isDefault && (React.createElement(Select, { value: selectedModule, onChange: setSelectedModule }, availableModules.map((m) => (React.createElement(Select.Option, { key: m, value: m }, moduleTranslations[m])))))),
                isPrinting ? (React.createElement(PrintingInputs, { preset: displayPreset, maxSpeed: workareaObj.maxSpeed, minSpeed: workareaObj.minSpeed, isInch: isInch, lengthUnit: lengthUnit, handleChange: handleChange })) : (React.createElement(LaserInputs, { preset: displayPreset, maxSpeed: workareaObj.maxSpeed, minSpeed: workareaObj.minSpeed, isInch: isInch, lengthUnit: lengthUnit, handleChange: handleChange }))))));
};
export const showPresetsManagementPanel = ({ currentModule, initPreset, onClose, }) => {
    if (!isIdExist('presets-management-panel')) {
        addDialogComponent('presets-management-panel', React.createElement(PresetsManagementPanel, { currentModule: currentModule, initPreset: initPreset, onClose: () => {
                popDialogById('presets-management-panel');
                onClose === null || onClose === void 0 ? void 0 : onClose();
            } }));
    }
};
export default PresetsManagementPanel;
