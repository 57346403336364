import React, { useEffect, useState } from 'react';
import BeamboxStore from 'app/stores/beambox-store';
import StepCrop from 'app/views/beambox/ImageTracePanel/StepCrop';
import StepTune from 'app/views/beambox/ImageTracePanel/StepTune';
var Step;
(function (Step) {
    Step[Step["NONE"] = 0] = "NONE";
    Step[Step["CROP"] = 1] = "CROP";
    Step[Step["TUNE"] = 2] = "TUNE";
})(Step || (Step = {}));
const ImageTracePanel = () => {
    const [step, setStep] = useState(Step.NONE);
    const [cropResult, setCropResult] = useState(null);
    useEffect(() => {
        const handleStart = () => setStep((prev) => (prev === Step.NONE ? Step.CROP : prev));
        BeamboxStore.onCropperShown(handleStart);
        return () => {
            BeamboxStore.removeCropperShownListener(handleStart);
        };
    }, []);
    if (step === Step.CROP) {
        const onFinish = (data, url) => {
            setCropResult({ data, url });
            setStep(Step.TUNE);
        };
        return React.createElement(StepCrop, { onCropFinish: onFinish, onCancel: () => setStep(Step.NONE) });
    }
    if (step === Step.TUNE) {
        const { data, url } = cropResult;
        const handleGoBack = () => {
            URL.revokeObjectURL(url);
            setStep(Step.CROP);
        };
        return React.createElement(StepTune, { cropData: data, imageUrl: url, onGoBack: handleGoBack, onClose: () => setStep(Step.NONE) });
    }
    return null;
};
export default ImageTracePanel;
