const defaultIcons = [
    {
        id: '18944',
        term: 'Star',
        preview_url: 'https://static.thenounproject.com/png/18944-200.png',
        preview_url_42: 'https://static.thenounproject.com/png/18944-42.png',
        preview_url_84: 'https://static.thenounproject.com/png/18944-84.png',
        uploader: {
            location: 'San Francisco, CA, US',
            name: 'Icomatic',
            username: 'Icomatic',
        },
        uploader_id: '150473',
        isDefault: true,
    },
    {
        id: '187',
        term: 'Heart',
        preview_url: 'https://static.thenounproject.com/png/187-200.png',
        preview_url_42: 'https://static.thenounproject.com/png/187-42.png',
        preview_url_84: 'https://static.thenounproject.com/png/187-84.png',
        uploader: {
            location: '',
            name: 'public-domain-nouns',
            username: 'public-domain-nouns',
        },
        uploader_id: '3810397',
        isDefault: true,
    },
    {
        id: '45904',
        term: 'Check',
        preview_url: 'https://static.thenounproject.com/png/45904-200.png',
        preview_url_42: 'https://static.thenounproject.com/png/45904-42.png',
        preview_url_84: 'https://static.thenounproject.com/png/45904-84.png',
        uploader: {
            location: 'San Francisco, CA, US',
            name: 'useiconic.com',
            username: 'useiconic.com',
        },
        uploader_id: '328904',
        isDefault: true,
    },
    {
        id: '134091',
        term: 'cancel',
        preview_url: 'https://static.thenounproject.com/png/134091-200.png',
        preview_url_42: 'https://static.thenounproject.com/png/134091-42.png',
        preview_url_84: 'https://static.thenounproject.com/png/134091-84.png',
        uploader: {
            location: 'Manchester, GB',
            name: 'Dave Houlbrooke',
            username: 'dhoulb',
        },
        uploader_id: '291128',
        isDefault: true,
    },
    {
        id: '18124',
        term: 'Arrow',
        preview_url: 'https://static.thenounproject.com/png/18124-200.png',
        preview_url_42: 'https://static.thenounproject.com/png/18124-42.png',
        preview_url_84: 'https://static.thenounproject.com/png/18124-84.png',
        uploader: {
            location: 'London, GB',
            name: 'Joe Mortell',
            username: 'JoeMortell',
        },
        uploader_id: '10853',
        isDefault: true,
    },
    {
        id: '34656',
        term: 'wifi',
        preview_url: 'https://static.thenounproject.com/png/34656-200.png',
        preview_url_42: 'https://static.thenounproject.com/png/34656-42.png',
        preview_url_84: 'https://static.thenounproject.com/png/34656-84.png',
        uploader: {
            location: 'Berdyansk, zaporozhie, UA',
            name: 'AS Design',
            username: 'audasamora',
        },
        uploader_id: '223845',
        isDefault: true,
    },
    {
        id: '18418',
        term: 'Alert',
        preview_url: 'https://static.thenounproject.com/png/18418-200.png',
        preview_url_42: 'https://static.thenounproject.com/png/18418-42.png',
        preview_url_84: 'https://static.thenounproject.com/png/18418-84.png',
        uploader: {
            location: 'Vienna, AT',
            name: 'Juergen Bauer',
            username: 'Juergen',
        },
        uploader_id: '150005',
        isDefault: true,
    },
    {
        id: '1748424',
        term: 'good',
        preview_url: 'https://static.thenounproject.com/png/1748424-200.png',
        preview_url_42: 'https://static.thenounproject.com/png/1748424-42.png',
        preview_url_84: 'https://static.thenounproject.com/png/1748424-84.png',
        uploader: {
            location: 'FR',
            name: 'Adrien Coquet',
            username: 'coquet_adrien',
        },
        uploader_id: '1145943',
        isDefault: true,
    },
    {
        id: '1748417',
        term: 'bad',
        preview_url: 'https://static.thenounproject.com/png/1748417-200.png',
        preview_url_42: 'https://static.thenounproject.com/png/1748417-42.png',
        preview_url_84: 'https://static.thenounproject.com/png/1748417-84.png',
        uploader: {
            location: 'FR',
            name: 'Adrien Coquet',
            username: 'coquet_adrien',
        },
        uploader_id: '1145943',
        isDefault: true,
    },
    {
        id: '304921',
        term: 'wc',
        preview_url: 'https://static.thenounproject.com/png/304921-200.png',
        preview_url_42: 'https://static.thenounproject.com/png/304921-42.png',
        preview_url_84: 'https://static.thenounproject.com/png/304921-84.png',
        uploader: {
            location: 'Perm, RU',
            name: 'Arthur Shlain',
            username: 'ArtZ91',
        },
        uploader_id: '15311',
        isDefault: true,
    },
    {
        id: '40559',
        term: 'Bell',
        preview_url: 'https://static.thenounproject.com/png/40559-200.png',
        preview_url_42: 'https://static.thenounproject.com/png/40559-42.png',
        preview_url_84: 'https://static.thenounproject.com/png/40559-84.png',
        uploader: {
            location: 'Montevideo, UY',
            name: 'Ramiro Andrade',
            username: 'randrade',
        },
        uploader_id: '51396',
        isDefault: true,
    },
    {
        id: '5609',
        term: 'Speaker',
        preview_url: 'https://static.thenounproject.com/png/5609-200.png',
        preview_url_42: 'https://static.thenounproject.com/png/5609-42.png',
        preview_url_84: 'https://static.thenounproject.com/png/5609-84.png',
        uploader: {
            location: 'Newcastle upon Tyne, GB',
            name: 'Naomi Atkinson',
            username: 'naomiatkinson',
        },
        uploader_id: '11405',
        isDefault: true,
    },
    {
        id: '5486',
        term: 'play',
        preview_url: 'https://static.thenounproject.com/png/5486-200.png',
        preview_url_42: 'https://static.thenounproject.com/png/5486-42.png',
        preview_url_84: 'https://static.thenounproject.com/png/5486-84.png',
        uploader: {
            location: 'Madurai, Tamilnadu, IN',
            name: 'dsathiyaraj',
            username: 'dsathiyaraj',
        },
        uploader_id: '9667',
        isDefault: true,
    },
    {
        id: '8174',
        term: 'Money',
        preview_url: 'https://static.thenounproject.com/png/8174-200.png',
        preview_url_42: 'https://static.thenounproject.com/png/8174-42.png',
        preview_url_84: 'https://static.thenounproject.com/png/8174-84.png',
        uploader: {
            location: 'Auckland, NZ',
            name: 'lemon liu',
            username: 'lemonliu',
        },
        uploader_id: '12291',
        isDefault: true,
    },
    {
        id: '62294',
        term: 'copyright',
        preview_url: 'https://static.thenounproject.com/png/62294-200.png',
        preview_url_42: 'https://static.thenounproject.com/png/62294-42.png',
        preview_url_84: 'https://static.thenounproject.com/png/62294-84.png',
        uploader: {
            location: 'Sofia, BG',
            name: 'Stefan Parnarov',
            username: 'sapi',
        },
        uploader_id: '31590',
        isDefault: true,
    },
    {
        id: '651354',
        term: 'web',
        preview_url: 'https://static.thenounproject.com/png/651354-200.png',
        preview_url_42: 'https://static.thenounproject.com/png/651354-42.png',
        preview_url_84: 'https://static.thenounproject.com/png/651354-84.png',
        uploader: {
            location: 'Bucharest, RO',
            name: 'Ben Davis',
            username: 'smashicons',
        },
        uploader_id: '64420',
        isDefault: true,
    },
    {
        id: '3893',
        term: 'Share',
        preview_url: 'https://static.thenounproject.com/png/3893-200.png',
        preview_url_42: 'https://static.thenounproject.com/png/3893-42.png',
        preview_url_84: 'https://static.thenounproject.com/png/3893-84.png',
        uploader: {
            location: 'Dharamsala, Himachal Pradesh, IN',
            name: 'Arun Ganesh',
            username: 'planemad',
        },
        uploader_id: '7979',
        isDefault: true,
    },
    {
        id: '42970',
        term: 'Phone',
        preview_url: 'https://static.thenounproject.com/png/42970-200.png',
        preview_url_42: 'https://static.thenounproject.com/png/42970-42.png',
        preview_url_84: 'https://static.thenounproject.com/png/42970-84.png',
        uploader: {
            location: '\'s-Hertogenbosch, Noord-Brabant, NL',
            name: 'Joris Hoogendoorn',
            username: 'jorishoogendoorn',
        },
        uploader_id: '1044',
        isDefault: true,
    },
    {
        id: '52971',
        term: 'Phone',
        preview_url: 'https://static.thenounproject.com/png/52971-200.png',
        preview_url_42: 'https://static.thenounproject.com/png/52971-42.png',
        preview_url_84: 'https://static.thenounproject.com/png/52971-84.png',
        uploader: {
            location: 'GB',
            name: 'Tom Walsh',
            username: 'tomwalshdesign',
        },
        uploader_id: '186759',
        isDefault: true,
    },
    {
        id: '2944971',
        term: 'Home',
        preview_url: 'https://static.thenounproject.com/png/2944971-200.png',
        preview_url_42: 'https://static.thenounproject.com/png/2944971-42.png',
        preview_url_84: 'https://static.thenounproject.com/png/2944971-84.png',
        uploader: {
            location: 'DENPASAR, indonesia, ID',
            name: 'fajar hasyim',
            username: 'fajarhasyim.91',
        },
        uploader_id: '4445850',
        isDefault: true,
    },
    {
        id: '476',
        term: 'Camera',
        preview_url: 'https://static.thenounproject.com/png/476-200.png',
        preview_url_42: 'https://static.thenounproject.com/png/476-42.png',
        preview_url_84: 'https://static.thenounproject.com/png/476-84.png',
        uploader: {
            location: 'Los Angeles, US',
            name: 'Edward Boatman',
            username: 'edward',
        },
        uploader_id: '6',
        isDefault: true,
    },
    {
        id: '355699',
        term: 'place',
        preview_url: 'https://static.thenounproject.com/png/355699-200.png',
        preview_url_42: 'https://static.thenounproject.com/png/355699-42.png',
        preview_url_84: 'https://static.thenounproject.com/png/355699-84.png',
        uploader: {
            location: 'Remedios de Escalada, Buenos Aires, AR',
            name: 'Carla Porciuncula',
            username: 'porciunculavalls',
        },
        uploader_id: '178924',
        isDefault: true,
    },
    {
        id: '4570',
        term: 'Earth',
        preview_url: 'https://static.thenounproject.com/png/4570-200.png',
        preview_url_42: 'https://static.thenounproject.com/png/4570-42.png',
        preview_url_84: 'https://static.thenounproject.com/png/4570-84.png',
        uploader: {
            location: 'Paris, FR',
            name: 'Thibault Geffroy',
            username: 'thibault.geffroy',
        },
        uploader_id: '3151',
        isDefault: true,
    },
    {
        id: '602338',
        term: 'bubble',
        preview_url: 'https://static.thenounproject.com/png/602338-200.png',
        preview_url_42: 'https://static.thenounproject.com/png/602338-42.png',
        preview_url_84: 'https://static.thenounproject.com/png/602338-84.png',
        uploader: {
            location: 'RU',
            name: 'Dmitry Grishin',
            username: 'grishin.dimdim',
        },
        uploader_id: '1143655',
        isDefault: true,
    },
    {
        id: '29545',
        term: 'coin',
        preview_url: 'https://static.thenounproject.com/png/29545-200.png',
        preview_url_42: 'https://static.thenounproject.com/png/29545-42.png',
        preview_url_84: 'https://static.thenounproject.com/png/29545-84.png',
        uploader: {
            location: 'Belfast, Northern Ireland, GB',
            name: 'Chris Kerr',
            username: 'chrisk3rr',
        },
        uploader_id: '18231',
        isDefault: true,
    },
    {
        id: '1431576',
        term: 'Prize',
        preview_url: 'https://static.thenounproject.com/png/1431576-200.png',
        preview_url_42: 'https://static.thenounproject.com/png/1431576-42.png',
        preview_url_84: 'https://static.thenounproject.com/png/1431576-84.png',
        uploader: {
            location: 'surabaya, jawa timur, ID',
            name: 'iconesia',
            username: 'iconesia',
        },
        uploader_id: '1682336',
        isDefault: true,
    },
    {
        id: '441269',
        term: 'Calendar',
        preview_url: 'https://static.thenounproject.com/png/441269-200.png',
        preview_url_42: 'https://static.thenounproject.com/png/441269-42.png',
        preview_url_84: 'https://static.thenounproject.com/png/441269-84.png',
        uploader: {
            location: 'US',
            name: 'David',
            username: 'kaxgyatso',
        },
        uploader_id: '1341268',
        isDefault: true,
    },
    {
        id: '329876',
        term: 'point',
        preview_url: 'https://static.thenounproject.com/png/329876-200.png',
        preview_url_42: 'https://static.thenounproject.com/png/329876-42.png',
        preview_url_84: 'https://static.thenounproject.com/png/329876-84.png',
        uploader: {
            location: '',
            name: 'shashank singh',
            username: 'rshashank19',
        },
        uploader_id: '991304',
        isDefault: true,
    },
    {
        id: '116827',
        term: 'glass',
        preview_url: 'https://static.thenounproject.com/png/116827-200.png',
        preview_url_42: 'https://static.thenounproject.com/png/116827-42.png',
        preview_url_84: 'https://static.thenounproject.com/png/116827-84.png',
        uploader: {
            location: 'AT',
            name: 'Alex',
            username: '4lex',
        },
        uploader_id: '4327',
        isDefault: true,
    },
    {
        id: '2547',
        term: 'Glasses',
        preview_url: 'https://static.thenounproject.com/png/2547-200.png',
        preview_url_42: 'https://static.thenounproject.com/png/2547-42.png',
        preview_url_84: 'https://static.thenounproject.com/png/2547-84.png',
        uploader: {
            location: 'Vienna, AT',
            name: 'Philipp Reiner',
            username: 'phillyooo',
        },
        uploader_id: '5375',
        isDefault: true,
    },
    {
        id: '920262',
        term: 'emoji',
        preview_url: 'https://static.thenounproject.com/png/920262-200.png',
        preview_url_42: 'https://static.thenounproject.com/png/920262-42.png',
        preview_url_84: 'https://static.thenounproject.com/png/920262-84.png',
        uploader: {
            location: '',
            name: 'Dev Patel',
            username: 'dev137',
        },
        uploader_id: '2191692',
        isDefault: true,
    },
    {
        id: '920268',
        term: 'emoji',
        preview_url: 'https://static.thenounproject.com/png/920268-200.png',
        preview_url_42: 'https://static.thenounproject.com/png/920268-42.png',
        preview_url_84: 'https://static.thenounproject.com/png/920268-84.png',
        uploader: {
            location: '',
            name: 'Dev Patel',
            username: 'dev137',
        },
        uploader_id: '2191692',
        isDefault: true,
    },
    {
        id: '920244',
        term: 'emoji',
        preview_url: 'https://static.thenounproject.com/png/920244-200.png',
        preview_url_42: 'https://static.thenounproject.com/png/920244-42.png',
        preview_url_84: 'https://static.thenounproject.com/png/920244-84.png',
        uploader: {
            location: '',
            name: 'Dev Patel',
            username: 'dev137',
        },
        uploader_id: '2191692',
        isDefault: true,
    },
    {
        id: '920272',
        term: 'emoji',
        preview_url: 'https://static.thenounproject.com/png/920272-200.png',
        preview_url_42: 'https://static.thenounproject.com/png/920272-42.png',
        preview_url_84: 'https://static.thenounproject.com/png/920272-84.png',
        uploader: {
            location: '',
            name: 'Dev Patel',
            username: 'dev137',
        },
        uploader_id: '2191692',
        isDefault: true,
    },
    {
        id: '920236',
        term: 'emoji',
        preview_url: 'https://static.thenounproject.com/png/920236-200.png',
        preview_url_42: 'https://static.thenounproject.com/png/920236-42.png',
        preview_url_84: 'https://static.thenounproject.com/png/920236-84.png',
        uploader: {
            location: '',
            name: 'Dev Patel',
            username: 'dev137',
        },
        uploader_id: '2191692',
        isDefault: true,
    },
    {
        id: '920276',
        term: 'emoji',
        preview_url: 'https://static.thenounproject.com/png/920276-200.png',
        preview_url_42: 'https://static.thenounproject.com/png/920276-42.png',
        preview_url_84: 'https://static.thenounproject.com/png/920276-84.png',
        uploader: {
            location: '',
            name: 'Dev Patel',
            username: 'dev137',
        },
        uploader_id: '2191692',
        isDefault: true,
    },
    {
        id: '920279',
        term: 'emoji',
        preview_url: 'https://static.thenounproject.com/png/920279-200.png',
        preview_url_42: 'https://static.thenounproject.com/png/920279-42.png',
        preview_url_84: 'https://static.thenounproject.com/png/920279-84.png',
        uploader: {
            location: '',
            name: 'Dev Patel',
            username: 'dev137',
        },
        uploader_id: '2191692',
        isDefault: true,
    },
    {
        id: '920270',
        term: 'emoji',
        preview_url: 'https://static.thenounproject.com/png/920270-200.png',
        preview_url_42: 'https://static.thenounproject.com/png/920270-42.png',
        preview_url_84: 'https://static.thenounproject.com/png/920270-84.png',
        uploader: {
            location: '',
            name: 'Dev Patel',
            username: 'dev137',
        },
        uploader_id: '2191692',
        isDefault: true,
    },
    {
        id: '170',
        term: 'sun',
        preview_url: 'https://static.thenounproject.com/png/170-200.png',
        preview_url_42: 'https://static.thenounproject.com/png/170-42.png',
        preview_url_84: 'https://static.thenounproject.com/png/170-84.png',
        uploader: {
            location: '',
            name: 'Public Domain Nouns',
            username: 'public-domain-nouns',
        },
        uploader_id: '3810397',
        isDefault: true,
    },
    {
        id: '8517',
        term: 'Moon',
        preview_url: 'https://static.thenounproject.com/png/8517-200.png',
        preview_url_42: 'https://static.thenounproject.com/png/8517-42.png',
        preview_url_84: 'https://static.thenounproject.com/png/8517-84.png',
        uploader: {
            location: 'Berlin, DE',
            name: 'Anthony Lui',
            username: 'noallegiances',
        },
        uploader_id: '3810397',
        isDefault: true,
    },
    {
        id: '26219',
        term: 'building',
        preview_url: 'https://static.thenounproject.com/png/26219-200.png',
        preview_url_42: 'https://static.thenounproject.com/png/26219-42.png',
        preview_url_84: 'https://static.thenounproject.com/png/26219-84.png',
        uploader: {
            location: 'AU',
            name: 'Lil Squid',
            username: 'Lil Squid',
        },
        uploader_id: '224091',
        isDefault: true,
    },
    {
        id: '61386',
        term: 'Dog',
        preview_url: 'https://static.thenounproject.com/png/61386-200.png',
        preview_url_42: 'https://static.thenounproject.com/png/61386-42.png',
        preview_url_84: 'https://static.thenounproject.com/png/61386-84.png',
        uploader: {
            location: 'Santiago, RM, CL',
            name: 'Buena Buena',
            username: 'buenabuena',
        },
        uploader_id: '45257',
        isDefault: true,
    },
    {
        id: '75134',
        term: 'Cat',
        preview_url: 'https://static.thenounproject.com/png/75134-200.png',
        preview_url_42: 'https://static.thenounproject.com/png/75134-42.png',
        preview_url_84: 'https://static.thenounproject.com/png/75134-84.png',
        uploader: {
            location: 'Brooklyn, New York, US',
            name: 'Dan Hetteix',
            username: 'DHETTEIX',
        },
        uploader_id: '171496',
        isDefault: true,
    },
    {
        id: '113118',
        term: 'Plane',
        preview_url: 'https://static.thenounproject.com/png/113118-200.png',
        preview_url_42: 'https://static.thenounproject.com/png/113118-42.png',
        preview_url_84: 'https://static.thenounproject.com/png/113118-84.png',
        uploader: {
            location: 'CH',
            name: 'Simon Mettler',
            username: 'SimonMettler',
        },
        uploader_id: '459759',
        isDefault: true,
    },
    {
        id: '1335',
        term: 'Car',
        preview_url: 'https://static.thenounproject.com/png/1335-200.png',
        preview_url_42: 'https://static.thenounproject.com/png/1335-42.png',
        preview_url_84: 'https://static.thenounproject.com/png/1335-84.png',
        uploader: {
            location: 'CY',
            name: 'Iconic',
            username: 'iconicdesign',
        },
        uploader_id: '2635',
        isDefault: true,
    },
    {
        id: '58992',
        term: 'Rocket',
        preview_url: 'https://static.thenounproject.com/png/58992-200.png',
        preview_url_42: 'https://static.thenounproject.com/png/58992-42.png',
        preview_url_84: 'https://static.thenounproject.com/png/58992-84.png',
        uploader: {
            location: 'London, GB',
            name: 'Yamini Ahluwalia',
            username: 'yaminiahluwalia',
        },
        uploader_id: '231495',
        isDefault: true,
    },
    {
        id: '143283',
        term: 'Bike',
        preview_url: 'https://static.thenounproject.com/png/143283-200.png',
        preview_url_42: 'https://static.thenounproject.com/png/143283-42.png',
        preview_url_84: 'https://static.thenounproject.com/png/143283-84.png',
        uploader: {
            location: 'London, England, GB',
            name: 'Joel Avery',
            username: '99avery',
        },
        uploader_id: '35614',
        isDefault: true,
    },
    {
        id: '1279706',
        term: 'Motorcycle',
        preview_url: 'https://static.thenounproject.com/png/1279706-200.png',
        preview_url_42: 'https://static.thenounproject.com/png/1279706-42.png',
        preview_url_84: 'https://static.thenounproject.com/png/1279706-84.png',
        uploader: {
            location: 'Paris, FR',
            name: 'Effach',
            username: 'francois.hardy.359',
        },
        uploader_id: '31894',
        isDefault: true,
    },
];
export default defaultIcons;
