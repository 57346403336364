import storage from 'implementations/storage';
export default {
    read: (key) => {
        const config = storage.get('alert-config') || {};
        return config[key];
    },
    write: (key, value) => {
        const config = storage.get('alert-config') || {};
        config[key] = value;
        storage.set('alert-config', config);
    },
};
