var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import symbolMaker from 'helpers/symbol-maker';
import updateImageDisplay from 'helpers/image/updateImageDisplay';
const endByLayerSymbol = Symbol('end by_layer');
const endByColorSymbol = Symbol('end by_color');
// TODO: add test
const setElementsColor = (elements, color, isFullColor = false) => {
    const descendants = [
        ...elements,
    ];
    let svgByColor = 0;
    let isWireFrame = false;
    const promises = [];
    while (descendants.length > 0) {
        const elem = descendants.pop();
        if (elem === endByColorSymbol) {
            svgByColor -= 1;
        }
        else if (elem === endByLayerSymbol) {
            isWireFrame = false;
        }
        else {
            const attrStroke = elem.getAttribute('stroke');
            const attrFill = elem.getAttribute('fill');
            const attrFillOpacity = elem.getAttribute('fill-opacity');
            if (['rect', 'circle', 'ellipse', 'path', 'polygon', 'text', 'line'].includes(elem.tagName)) {
                if (!isFullColor) {
                    // remove stroke for self drawn elements, set stroke color for imported elements
                    elem.removeAttribute('stroke-width');
                    elem.setAttribute('vector-effect', 'non-scaling-stroke');
                    if (((isWireFrame && svgByColor === 0) || attrStroke) && attrStroke !== 'none') {
                        elem.setAttribute('stroke', color);
                    }
                    if (!['none', '#fff', '#ffffff'].includes(attrFill === null || attrFill === void 0 ? void 0 : attrFill.toLowerCase()) &&
                        attrFillOpacity !== '0' &&
                        !isWireFrame) {
                        elem.setAttribute('fill', color);
                        elem.setAttribute('fill-opacity', '1');
                    }
                }
                else {
                    elem.removeAttribute('vector-effect');
                }
            }
            else if (elem.tagName === 'image') {
                // eslint-disable-next-line no-async-promise-executor
                const promise = new Promise((resolve) => __awaiter(void 0, void 0, void 0, function* () {
                    if (!elem.closest('#svg_defs'))
                        yield updateImageDisplay(elem);
                    if (isFullColor || color === '#000') {
                        elem.removeAttribute('filter');
                    }
                    else {
                        elem.setAttribute('filter', `url(#filter${color})`);
                    }
                    resolve();
                }));
                promises.push(promise);
            }
            else if (['g', 'svg', 'symbol'].includes(elem.tagName)) {
                if (elem.getAttribute('data-color')) {
                    descendants.push(endByColorSymbol);
                    svgByColor += 1;
                }
                descendants.push(...elem.childNodes);
            }
            else if (elem.tagName === 'use') {
                if (elem.getAttribute('data-wireframe')) {
                    descendants.push(endByLayerSymbol);
                    isWireFrame = true;
                }
                descendants.push(...elem.childNodes);
                const href = $(elem).attr('href') || $(elem).attr('xlink:href');
                const shadowRoot = $(href).toArray();
                descendants.push(...shadowRoot);
                const promise = symbolMaker.reRenderImageSymbol(elem);
                promises.push(promise);
            }
            else {
                // console.log(`setElementsColor: unsupported element type ${elem.tagName}`);
            }
        }
    }
    return new Promise((resolve) => {
        Promise.allSettled(promises).then(() => {
            resolve();
        });
    });
};
export default setElementsColor;
