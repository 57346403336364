import sentry from 'implementations/sentry';
import storage from 'implementations/storage';
let isSentryInited = false;
const sendDevices = storage.get('sentry-send-devices') || {};
const { Sentry } = sentry;
const initSentry = () => {
    if (storage.get('enable-sentry')) {
        console.log('Sentry Initiated');
        sentry.initSentry();
        isSentryInited = true;
        Sentry.captureMessage('User Census', {
            level: 'info',
            tags: {
                census: 'v1',
                from: 'renderer',
            },
        });
    }
};
const captureMessage = (lastVersion, uuid, version, model) => {
    Sentry.captureMessage('Device Info', {
        level: 'info',
        tags: {
            'device-lastversion': lastVersion,
            'device-uuid': uuid,
            'device-version': version,
            'device-model': model,
        },
    });
    sendDevices[uuid] = version;
    storage.set('sentry-send-devices', sendDevices);
};
const sendDeviceInfo = (device) => {
    if (isSentryInited) {
        if (!sendDevices[device.uuid]) {
            captureMessage('no', device.uuid, device.version, device.model);
        }
        else if (sendDevices[device.uuid] !== device.version) {
            captureMessage(sendDevices[device.uuid], device.uuid, device.version, device.model);
        }
    }
};
export default {
    initSentry,
    sendDeviceInfo,
};
