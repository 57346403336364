var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import ProgressConstants from 'app/constants/progress-constants';
import eventEmitterFactory from 'helpers/eventEmitterFactory';
const eventEmitter = eventEmitterFactory.createEventEmitter('alert-progress');
export default {
    openNonstopProgress: (args) => __awaiter(void 0, void 0, void 0, function* () {
        if (!args.caption && args.message) {
            // eslint-disable-next-line no-param-reassign
            args.caption = args.message;
        }
        return new Promise((resolve) => {
            eventEmitter.emit('OPEN_PROGRESS', Object.assign(Object.assign({}, args), { isProgress: true, type: ProgressConstants.NONSTOP }), resolve);
        });
    }),
    openSteppingProgress: (args) => new Promise((resolve) => {
        eventEmitter.emit('OPEN_PROGRESS', Object.assign(Object.assign({}, args), { isProgress: true, type: ProgressConstants.STEPPING, percentage: args.percentage || 0 }), resolve);
    }),
    openMessage: (args) => new Promise((resolve) => {
        eventEmitter.emit('OPEN_MESSAGE', Object.assign(Object.assign({}, args), { isProgress: true }), resolve);
    }),
    popById: (id) => {
        eventEmitter.emit('POP_BY_ID', id);
    },
    popLastProgress: () => {
        eventEmitter.emit('POP_LAST_PROGRESS');
    },
    update: (id, args) => {
        eventEmitter.emit('UPDATE_PROGRESS', id, args);
    },
    checkIdExist: (id) => {
        const response = {
            result: false,
        };
        eventEmitter.emit('CHECK_PROGRESS_EXIST', id, response);
        return response.result;
    },
};
