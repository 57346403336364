import BeamboxGlobalInteraction from 'app/actions/beambox/beambox-global-interaction';
import eventEmitterFactory from 'helpers/eventEmitterFactory';
const topBarEventEmitter = eventEmitterFactory.createEventEmitter('top-bar');
const setElement = (elem) => {
    if (!elem) {
        BeamboxGlobalInteraction.onObjectBlur();
    }
    else {
        BeamboxGlobalInteraction.onObjectBlur();
        BeamboxGlobalInteraction.onObjectFocus([elem]);
    }
    topBarEventEmitter.emit('SET_ELEMENT', elem);
};
const updateTitle = () => {
    topBarEventEmitter.emit('UPDATE_TITLE');
};
const setHasUnsavedChange = (hasUnsavedChange) => {
    topBarEventEmitter.emit('SET_HAS_UNSAVED_CHANGE', hasUnsavedChange);
};
const getTopBarPreviewMode = () => {
    const response = {
        isPreviewMode: false,
    };
    topBarEventEmitter.emit('GET_TOP_BAR_PREVIEW_MODE', response);
    return response.isPreviewMode;
};
const getSelectedDevice = () => {
    const response = {
        selectedDevice: null,
    };
    topBarEventEmitter.emit('GET_SELECTED_DEVICE', response);
    return response.selectedDevice;
};
const setSelectedDevice = (device) => {
    topBarEventEmitter.emit('SET_SELECTED_DEVICE', device);
};
export default {
    setElement,
    updateTitle,
    setHasUnsavedChange,
    getTopBarPreviewMode,
    getSelectedDevice,
    setSelectedDevice,
};
