import React from 'react';
import BackButton from 'app/widgets/FullWindowPanel/BackButton';
import Footer from 'app/widgets/FullWindowPanel/Footer';
import FullWindowPanel from 'app/widgets/FullWindowPanel/FullWindowPanel';
import Header from 'app/widgets/FullWindowPanel/Header';
import Sider from 'app/widgets/FullWindowPanel/Sider';
import useI18n from 'helpers/useI18n';
import { BoxgenProvider } from 'app/contexts/BoxgenContext';
import BoxCanvas from './BoxCanvas';
import BoxSelector from './BoxSelector';
import Controller from './Controller';
import ExportButton from './ExportButton';
import styles from './Boxgen.module.scss';
const Boxgen = ({ onClose }) => {
    const lang = useI18n();
    const tBoxgen = lang.boxgen;
    return (React.createElement(BoxgenProvider, { onClose: onClose },
        React.createElement(FullWindowPanel, { onClose: onClose, mobileTitle: tBoxgen.title, renderMobileFixedContent: () => (React.createElement("div", null,
                React.createElement(BoxSelector, null),
                React.createElement("div", { className: styles.canvas },
                    React.createElement(BoxCanvas, null)))), renderMobileContents: () => (React.createElement(React.Fragment, null,
                React.createElement(Controller, null),
                React.createElement(Footer, null,
                    React.createElement(ExportButton, null)))), renderContents: () => (React.createElement(React.Fragment, null,
                React.createElement(Sider, null,
                    React.createElement(BackButton, { onClose: onClose }, lang.buttons.back_to_beam_studio),
                    React.createElement(Header, { title: tBoxgen.title },
                        React.createElement(BoxSelector, null)),
                    React.createElement(Controller, null),
                    React.createElement(Footer, null,
                        React.createElement(ExportButton, null))),
                React.createElement("div", { className: styles.canvas },
                    React.createElement(BoxCanvas, null)))) })));
};
export default Boxgen;
