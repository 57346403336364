import classNames from 'classnames';
import React, { useCallback, useContext, useMemo } from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Switch, Tooltip } from 'antd';
import browser from 'implementations/browser';
import constant from 'app/actions/beambox/constant';
import storage from 'implementations/storage';
import UnitInput from 'app/widgets/UnitInput';
import useI18n from 'helpers/useI18n';
import workareaManager from 'app/svgedit/workarea';
import styles from './PassThrough.module.scss';
import { PassThroughContext } from './PassThroughContext';
const Controls = () => {
    const lang = useI18n().pass_through;
    const { workareaObj, passThroughHeight, setPassThroughHeight, referenceLayer, setReferenceLayer, guideMark, setGuideMark, } = useContext(PassThroughContext);
    const { max, min } = useMemo(() => {
        var _a;
        return ({
            max: (_a = workareaObj.passThroughMaxHeight) !== null && _a !== void 0 ? _a : workareaObj.height,
            min: 120,
        });
    }, [workareaObj]);
    const handleWorkareaHeightChange = useCallback((val) => {
        setPassThroughHeight(Math.max(min, Math.min(val, max)));
    }, [max, min, setPassThroughHeight]);
    const { show, x: guideMarkX, width: guideMarkWidth } = guideMark;
    const { xMax, xMin, widthMax } = useMemo(() => ({
        xMax: workareaObj.width - guideMarkWidth / 2,
        xMin: guideMarkWidth / 2,
        widthMax: (workareaObj.width - guideMarkX) * 2,
    }), [workareaObj.width, guideMarkX, guideMarkWidth]);
    const setX = useCallback((val) => {
        setGuideMark((cur) => (Object.assign(Object.assign({}, cur), { x: Math.max(xMin, Math.min(val, xMax)) })));
    }, [xMax, xMin, setGuideMark]);
    const setWidth = useCallback((val) => {
        setGuideMark((cur) => (Object.assign(Object.assign({}, cur), { width: Math.max(0, Math.min(val, widthMax)) })));
    }, [widthMax, setGuideMark]);
    const isInch = useMemo(() => storage.get('default-units') === 'inches', []);
    const objectSize = useMemo(() => {
        const svgcontent = document.getElementById('svgcontent');
        if (!svgcontent)
            return { width: 0, height: 0 };
        const bbox = svgcontent.getBBox();
        let { height } = bbox;
        if (bbox.y + height > workareaManager.height)
            height = workareaManager.height - bbox.y;
        if (bbox.y < 0)
            height += bbox.y;
        return {
            width: Math.round((bbox.width / constant.dpmm / (isInch ? 25.4 : 1)) * 100) / 100,
            height: Math.round((height / constant.dpmm / (isInch ? 25.4 : 1)) * 100) / 100,
        };
    }, [isInch]);
    return (React.createElement("div", { className: styles.controls },
        React.createElement("div", { className: styles.link, onClick: () => browser.open(lang.help_link) }, lang.help_text),
        React.createElement("div", { className: styles.size },
            React.createElement("div", null,
                lang.object_length,
                React.createElement("span", { className: styles.bold },
                    objectSize.height,
                    " ",
                    isInch ? 'in' : 'mm')),
            React.createElement("div", null,
                lang.workarea_height,
                React.createElement(UnitInput, { className: styles.input, value: passThroughHeight, onChange: handleWorkareaHeightChange, max: max, min: min, addonAfter: isInch ? 'in' : 'mm', isInch: isInch, controls: false }),
                React.createElement(Tooltip, { overlayClassName: styles.tooltip, title: `${lang.height_desc}\n(${isInch
                        ? `${(min / 25.4).toFixed(2)}' ~ ${(max / 25.4).toFixed(2)}'`
                        : `${min}mm ~ ${max}mm`})` },
                    React.createElement(QuestionCircleOutlined, { className: styles.hint })))),
        React.createElement("div", { className: styles.options },
            React.createElement("div", { className: styles.row },
                React.createElement("div", { className: classNames(styles.cell, styles.title) }, lang.ref_layer),
                React.createElement("div", { className: styles.cell },
                    React.createElement(Switch, { disabled: objectSize.width === 0 || objectSize.height === 0, checked: referenceLayer, onChange: () => setReferenceLayer((val) => !val) }))),
            React.createElement("div", { className: styles.row },
                React.createElement("div", { className: classNames(styles.cell, styles.title) }, lang.guide_mark),
                React.createElement("div", { className: styles.cell },
                    React.createElement(Switch, { disabled: objectSize.width === 0 || objectSize.height === 0, checked: show, onChange: (val) => setGuideMark((cur) => (Object.assign(Object.assign({}, cur), { show: val }))) }))),
            show && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: styles.row },
                    React.createElement("div", { className: classNames(styles.cell, styles.title) }, lang.guide_mark_length),
                    React.createElement("div", { className: styles.cell },
                        React.createElement(UnitInput, { className: styles.input, value: guideMarkWidth, onChange: setWidth, max: widthMax, min: 0, addonAfter: isInch ? 'in' : 'mm', isInch: isInch, controls: false }))),
                React.createElement("div", { className: styles.row },
                    React.createElement("div", { className: classNames(styles.cell, styles.title) }, lang.guide_mark_x),
                    React.createElement("div", { className: styles.cell },
                        React.createElement(UnitInput, { className: styles.input, value: guideMarkX, onChange: setX, max: xMax, min: xMin, addonAfter: isInch ? 'in' : 'mm', isInch: isInch, controls: false })))))),
        React.createElement("div", { className: styles.hint },
            React.createElement("div", null,
                "1. ",
                lang.ref_layer_desc),
            React.createElement("br", null),
            React.createElement("div", null,
                "2. ",
                lang.guide_mark_desc))));
};
export default Controls;
