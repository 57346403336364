var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import getExifRotationFlag from 'helpers/image/getExifRotationFlag';
import history from 'app/svgedit/history/history';
import imageData from 'helpers/image-data';
import updateElementColor from 'helpers/color/updateElementColor';
import { getSVGAsync } from 'helpers/svg-editor-helper';
let svgCanvas;
getSVGAsync((globalSVG) => {
    svgCanvas = globalSVG.Canvas;
});
// TODO: add unit test
const imageToPngBlob = (image) => __awaiter(void 0, void 0, void 0, function* () {
    return new Promise((resolve) => {
        const canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0);
        canvas.toBlob((blob) => {
            resolve(blob);
        });
    });
});
const readBitmapFile = (file, opts) => __awaiter(void 0, void 0, void 0, function* () {
    const { parentCmd, offset = [0, 0], gray = true } = opts !== null && opts !== void 0 ? opts : {};
    const reader = new FileReader();
    const arrayBuffer = yield new Promise((resolve) => {
        reader.onloadend = (e) => {
            resolve(e.target.result);
        };
        reader.readAsArrayBuffer(file);
    });
    // TODO: comment out this until more precise spec is defined
    // const [dpmmX, dpmmY] = await getDpmm(arrayBuffer, file.type);
    const [dpmmX, dpmmY] = [10, 10];
    const scaleX = 10 / dpmmX;
    const scaleY = 10 / dpmmY;
    const rotationFlag = getExifRotationFlag(arrayBuffer);
    const img = new Image();
    const blob = new Blob([arrayBuffer]);
    yield new Promise((resolve) => {
        img.onload = () => resolve();
        img.style.opacity = '0';
        img.src = URL.createObjectURL(blob);
    });
    const { width, height } = img;
    const newImage = svgCanvas.addSvgElementFromJson({
        element: 'image',
        attr: {
            x: offset[0],
            y: offset[1],
            width: (rotationFlag <= 4 ? width : height) * scaleX,
            height: (rotationFlag <= 4 ? height : width) * scaleY,
            id: svgCanvas.getNextId(),
            style: 'pointer-events:inherit',
            preserveAspectRatio: 'none',
            'data-threshold': 254,
            'data-shading': true,
            origImage: img.src,
            'data-ratiofixed': true,
        },
    });
    if (file.type === 'image/webp') {
        const pngBlob = yield imageToPngBlob(img);
        const newSrc = URL.createObjectURL(pngBlob);
        URL.revokeObjectURL(img.src);
        newImage.setAttribute('origImage', newSrc);
    }
    yield new Promise((resolve) => {
        imageData(newImage.getAttribute('origImage'), {
            width,
            height,
            rotationFlag,
            grayscale: gray
                ? {
                    is_rgba: true,
                    is_shading: true,
                    threshold: 254,
                    is_svg: false,
                }
                : undefined,
            onComplete: (result) => {
                svgCanvas.setHref(newImage, result.pngBase64);
            },
        });
        updateElementColor(newImage);
        svgCanvas.selectOnly([newImage]);
        const cmd = new history.InsertElementCommand(newImage);
        if (!parentCmd)
            svgCanvas.undoMgr.addCommandToHistory(cmd);
        else
            parentCmd.addSubCommand(cmd);
        if (!offset) {
            svgCanvas.alignSelectedElements('l', 'page');
            svgCanvas.alignSelectedElements('t', 'page');
        }
        resolve();
    });
    return newImage;
});
export default readBitmapFile;
