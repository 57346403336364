import React, { createContext, memo, useCallback, useEffect, useState, useRef } from 'react';
import eventEmitterFactory from 'helpers/eventEmitterFactory';
const canvasEventEmitter = eventEmitterFactory.createEventEmitter('canvas');
export const SelectedElementContext = createContext({
    selectedElement: null,
});
export const SelectedElementContextProvider = memo(({ children }) => {
    const [selectedElement, setSelectedElement] = useState(null);
    const selectedElementRef = useRef(null);
    const handleSetSelectedElem = useCallback((elem) => {
        if (elem === selectedElementRef.current)
            return;
        selectedElementRef.current = elem;
        setSelectedElement((cur) => {
            if (cur === elem)
                return cur;
            document.activeElement.blur();
            return elem;
        });
    }, []);
    useEffect(() => {
        canvasEventEmitter.on('SET_SELECTED_ELEMENT', handleSetSelectedElem);
        return () => {
            canvasEventEmitter.off('SET_SELECTED_ELEMENT', handleSetSelectedElem);
        };
    }, [handleSetSelectedElem]);
    return (React.createElement(SelectedElementContext.Provider, { value: { selectedElement } }, children));
});
export default {
    SelectedElementContext,
    SelectedElementContextProvider,
};
