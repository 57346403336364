import React, { useMemo } from 'react';
import { ConfigProvider, InputNumber } from 'antd';
import UnitInput from 'app/widgets/Unit-Input-v2';
import styles from './ConfigValueDisplay.module.scss';
const ConfigValueDisplay = ({ inputId, type = 'default', max, min, value, unit, hasMultiValue = false, decimal = 0, onChange, options, }) => {
    const selectedOption = options === null || options === void 0 ? void 0 : options.find((opt) => opt.value === value);
    const ratio = useMemo(() => ((unit === null || unit === void 0 ? void 0 : unit.includes('in')) ? 25.4 : 1), [unit]);
    if (selectedOption)
        return (React.createElement("span", { className: styles.value }, hasMultiValue ? '-' : selectedOption.label || selectedOption.value));
    if (type === 'panel-item')
        return (React.createElement(ConfigProvider, { theme: { token: { borderRadius: 100 } } },
            React.createElement(InputNumber, { className: styles.input, type: "number", min: min, max: max, suffix: unit, step: ratio, value: value, onChange: onChange, formatter: (v, { userTyping, input }) => userTyping ? input : (v / ratio).toFixed(decimal), parser: (v) => Number(v) * ratio, precision: decimal, controls: false })));
    return (React.createElement(UnitInput, { id: inputId, className: { [styles.input]: true }, min: min, max: max, unit: unit, defaultValue: value, getValue: onChange, decimal: decimal, displayMultiValue: hasMultiValue, disabled: !!options }));
};
export default ConfigValueDisplay;
