import React from 'react';
import UnitInput from 'app/widgets/UnitInput';
import useI18n from 'helpers/useI18n';
import { Flex } from 'antd';
import Select from 'app/widgets/AntdSelect';
import { tableParams } from './TableSetting';
import styles from './Form.module.scss';
export default function TableSettingForm({ isInch, tableSetting, handleChange, className, }) {
    const { beambox: { right_panel: { laser_panel: tLaserPanel }, }, material_test_generator: tMaterial, } = useI18n();
    const lengthUnit = isInch ? 'in/s' : 'mm/s';
    const settingEntries = React.useMemo(() => Object.entries(tableSetting), [tableSetting]);
    const options = tableParams.map((value) => ({ value, label: tLaserPanel[value] }));
    const handleSelectChange = (value, index) => {
        var _a;
        const currentKey = (_a = settingEntries.find(([, { selected }]) => selected === index)) === null || _a === void 0 ? void 0 : _a[0];
        if (!currentKey) {
            return;
        }
        handleChange(Object.assign(Object.assign({}, tableSetting), { [currentKey]: Object.assign(Object.assign({}, tableSetting[currentKey]), { selected: tableSetting[value].selected }), [value]: Object.assign(Object.assign({}, tableSetting[value]), { selected: index }) }));
    };
    const handleValueChange = (key, prefix, value) => {
        const { min, max } = tableSetting[key];
        const limitValue = (v) => {
            const rangedValue = Math[prefix](v, tableSetting[key][prefix === 'min' ? 'maxValue' : 'minValue']);
            return Math.min(max, Math.max(min, rangedValue));
        };
        handleChange(Object.assign(Object.assign({}, tableSetting), { [key]: Object.assign(Object.assign({}, tableSetting[key]), { [`${prefix}Value`]: limitValue(value) }) }));
    };
    const renderInputGroup = (index) => {
        const [key, detail] = settingEntries.find(([, { selected }]) => selected === index) || [];
        const useInch = isInch && key === 'speed';
        return (React.createElement(Flex, { vertical: true, justify: "space-between", gap: "8px", key: `table-setting-${index}` },
            React.createElement("div", { className: styles['sub-title'] }, tMaterial[index ? 'rows' : 'columns']),
            React.createElement(Select, { className: styles.input, options: options, value: key, onChange: (value) => handleSelectChange(value, index) }),
            ['min', 'max'].map((prefix) => (React.createElement(UnitInput, { key: `${prefix}-${key}`, "data-testid": `${prefix}-${key}`, isInch: useInch, className: styles.input, value: detail[`${prefix}Value`], max: detail.max, min: detail.min, precision: useInch ? 4 : 0, step: useInch ? 25.4 : 1, 
                // eslint-disable-next-line no-nested-ternary
                addonAfter: key === 'strength' ? '%' : key === 'speed' ? lengthUnit : '', onChange: (value) => handleValueChange(key, prefix, value) })))));
    };
    return (React.createElement(Flex, { className: className, justify: "space-between" },
        React.createElement(Flex, { vertical: true, justify: "space-between", gap: "8px" },
            React.createElement("div", { className: styles.title }, tMaterial.table_settings),
            React.createElement("div", { className: styles.label }, tMaterial.parameter),
            React.createElement("div", { className: styles.label }, tMaterial.min),
            React.createElement("div", { className: styles.label }, tMaterial.max)),
        React.createElement(Flex, { className: styles.inputs, justify: "flex-end", gap: "20px" }, [0, 1].map(renderInputGroup))));
}
