import React from 'react';
import classNames from 'classnames';
import ColorPicker from 'app/widgets/ColorPicker';
import LayerPanelIcons from 'app/icons/layer-panel/LayerPanelIcons';
import { getSVGAsync } from 'helpers/svg-editor-helper';
import styles from './DragImage.module.scss';
let svgCanvas;
getSVGAsync((globalSVG) => {
    svgCanvas = globalSVG.Canvas;
});
function DragImage({ selectedLayers, draggingLayer = null }) {
    if (!draggingLayer)
        return React.createElement("div", { id: "drag-image" });
    const drawing = svgCanvas.getCurrentDrawing();
    const layer = drawing.getLayerByName(draggingLayer);
    if (!layer)
        return React.createElement("div", { id: "drag-image" });
    const isLocked = layer.getAttribute('data-lock') === 'true';
    const isFullColor = layer.getAttribute('data-fullcolor') === '1';
    const isVis = drawing.getLayerVisibility(draggingLayer);
    const backLayers = [];
    for (let i = selectedLayers.length - 1; i >= 1; i -= 1) {
        backLayers.push(React.createElement("div", { className: styles.back, key: i, style: { top: -10 * i, left: 10 * i } }));
    }
    return (React.createElement("div", { id: "drag-image", className: styles.container },
        backLayers,
        React.createElement("div", { className: classNames(styles.layer, styles.sel, { lock: isLocked }) },
            React.createElement("div", { className: styles['sensor-area'] }),
            React.createElement("div", { className: styles.row },
                React.createElement("div", { className: styles.color }, isFullColor ? (React.createElement(LayerPanelIcons.FullColor, null)) : (React.createElement(ColorPicker, { disabled: true, initColor: drawing.getLayerColor(draggingLayer), triggerSize: "small", onChange: (color) => console.log(color) }))),
                React.createElement("div", { className: styles.name }, draggingLayer),
                React.createElement("div", { className: classNames(styles.vis, {
                        [styles.invis]: !drawing.getLayerVisibility(draggingLayer),
                    }) },
                    React.createElement("img", { src: isVis ? 'img/right-panel/icon-eyeopen.svg' : 'img/right-panel/icon-eyeclose.svg', alt: "vis-icon" })),
                React.createElement("div", { className: styles.lock },
                    React.createElement("img", { src: "img/right-panel/icon-layerlock.svg", alt: "lock-icon" }))),
            React.createElement("div", { className: styles['sensor-area'] }))));
}
export default DragImage;
