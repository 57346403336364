// TODO: List all possible reason for
export var ConnectionError;
(function (ConnectionError) {
    ConnectionError["TIMEOUT"] = "TIMEOUT";
    ConnectionError["UNKNOWN_DEVICE"] = "UNKNOWN_DEVICE";
    ConnectionError["NOT_FOUND"] = "NOT_FOUND";
    ConnectionError["DISCONNECTED"] = "DISCONNECTED";
    ConnectionError["AUTH_ERROR"] = "AUTH_ERROR";
    ConnectionError["AUTH_FAILED"] = "AUTH_FAILED";
    ConnectionError["FLUXMONITOR_VERSION_IS_TOO_OLD"] = "FLUXMONITOR_VERSION_IS_TOO_OLD";
})(ConnectionError || (ConnectionError = {}));
