import React from 'react';
import { Modal, Tabs } from 'antd';
import { CameraOutlined, FolderOutlined, PictureOutlined } from '@ant-design/icons';
import deviceConstants from 'app/constants/device-constants';
import localeHelper from 'helpers/locale-helper';
import MonitorStatus from 'helpers/monitor-status';
import useI18n from 'helpers/useI18n';
import { Mode } from 'app/constants/monitor-constants';
import { MonitorContext } from 'app/contexts/MonitorContext';
import MessageCaller, { MessageLevel } from 'app/actions/message-caller';
import MonitorCamera from './MonitorCamera';
import MonitorFilelist from './MonitorFilelist';
import MonitorTabExtraContent from './MonitorTabExtraContent';
import MonitorTask from './MonitorTask';
const Monitor = ({ device }) => {
    const { currentPath, mode, onClose, report, setMonitorMode, taskImageURL, uploadProgress } = React.useContext(MonitorContext);
    const LANG = useI18n();
    const taskMode = report.st_id === deviceConstants.status.IDLE ? Mode.PREVIEW : Mode.WORKING;
    const monitorMode = [Mode.PREVIEW, Mode.FILE_PREVIEW, Mode.WORKING].includes(mode)
        ? taskMode
        : mode;
    const [isUploadCompleted, setIsUploadCompleted] = React.useState(true);
    const key = 'monitorUploadFileMessage';
    const tabItems = [
        taskImageURL
            ? {
                label: (React.createElement("div", null,
                    React.createElement(PictureOutlined, null),
                    LANG.monitor.taskTab)),
                key: taskMode,
                children: React.createElement(MonitorTask, null),
            }
            : null,
        {
            label: (React.createElement("div", null,
                React.createElement(FolderOutlined, null),
                LANG.topmenu.file.label)),
            key: Mode.FILE,
            children: React.createElement(MonitorFilelist, { path: currentPath.join('/') }),
        },
        localeHelper.isNorthAmerica
            ? null
            : {
                label: (React.createElement("div", null,
                    React.createElement(CameraOutlined, null),
                    LANG.monitor.camera)),
                key: Mode.CAMERA,
                children: React.createElement(MonitorCamera, { device: device }),
            },
    ].filter(Boolean);
    React.useEffect(() => {
        if (uploadProgress) {
            MessageCaller.openMessage({
                key,
                level: MessageLevel.LOADING,
                content: `${LANG.beambox.popup.progress.uploading}...`,
            });
            setIsUploadCompleted(false);
        }
        else if (report && !isUploadCompleted) {
            MessageCaller.openMessage({
                key,
                level: MessageLevel.SUCCESS,
                content: LANG.beambox.popup.successfully_uploaded,
                duration: 2,
                onClose: () => {
                    setIsUploadCompleted(true);
                },
            });
        }
    }, [LANG, isUploadCompleted, report, uploadProgress]);
    const statusText = React.useMemo(() => {
        if (uploadProgress) {
            return LANG.beambox.popup.progress.uploading;
        }
        if (report) {
            return MonitorStatus.getDisplayStatus(report.st_label);
        }
        return LANG.monitor.connecting;
    }, [LANG, report, uploadProgress]);
    return (React.createElement(Modal, { open: true, centered: true, onCancel: onClose, title: `${device.name} - ${statusText}`, footer: null },
        React.createElement(Tabs, { activeKey: monitorMode, items: tabItems, onChange: setMonitorMode, tabBarExtraContent: React.createElement(MonitorTabExtraContent, null) })));
};
export default Monitor;
