import { useEffect, useState } from 'react';
export const isMobile = () => window.innerWidth < 601;
export const isMac = () => window.os === 'MacOS';
export const useIsMobile = () => {
    const [val, setVal] = useState(isMobile());
    useEffect(() => {
        const handler = () => {
            const newVal = isMobile();
            if (val !== newVal)
                setVal(newVal);
        };
        window.addEventListener('resize', handler);
        return () => window.removeEventListener('resize', handler);
    });
    return val;
};
