import React from 'react';
import i18n from 'helpers/i18n';
import { TopBarHintsContext } from 'app/contexts/TopBarHintsContext';
const LANG = i18n.lang.topbar;
const TopBarHints = () => {
    const context = React.useContext(TopBarHintsContext);
    const renderContent = () => {
        const { hintType } = context;
        if (!hintType) {
            return null;
        }
        if (hintType === 'POLYGON') {
            return (React.createElement("div", null, LANG.hint.polygon));
        }
        return null;
    };
    return (React.createElement("div", { className: "hint-container" }, renderContent()));
};
export default TopBarHints;
