import React, { useEffect } from 'react';
import ReactDomServer from 'react-dom/server';
import currentFileManager from 'app/svgedit/currentFileManager';
import eventEmitterFactory from 'helpers/eventEmitterFactory';
import TopBarIcons from 'app/icons/top-bar/TopBarIcons';
import useForceUpdate from 'helpers/use-force-update';
import useI18n from 'helpers/useI18n';
import styles from './FileName.module.scss';
const topBarEventEmitter = eventEmitterFactory.createEventEmitter('top-bar');
function FileName({ hasUnsavedChange, isTitle = false }) {
    const forceUpdate = useForceUpdate();
    useEffect(() => {
        topBarEventEmitter.on('UPDATE_TITLE', forceUpdate);
        return () => {
            topBarEventEmitter.removeListener('UPDATE_TITLE', forceUpdate);
        };
    }, [forceUpdate]);
    const lang = useI18n().topbar;
    const { isCloudFile } = currentFileManager;
    const fileName = currentFileManager.getName() || lang.untitled;
    return (React.createElement("div", { className: isTitle ? styles.title : styles['file-name'] },
        isCloudFile && React.createElement(TopBarIcons.CloudFile, null),
        `${fileName}${hasUnsavedChange ? '*' : ''}`));
}
const updateTitle = () => {
    var _a;
    if (window.os === 'Windows' && window.titlebar) {
        const title = ReactDomServer.renderToStaticMarkup(React.createElement(FileName, { hasUnsavedChange: false, isTitle: true }));
        // eslint-disable-next-line no-underscore-dangle
        if ((_a = window.titlebar) === null || _a === void 0 ? void 0 : _a._title)
            window.titlebar._title.innerHTML = title;
    }
};
topBarEventEmitter.on('UPDATE_TITLE', updateTitle);
export default FileName;
