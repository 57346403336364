import colorConstants from 'app/constants/color-constants';
class RandomColorHelper {
    constructor() {
        this.currentIdx = 0;
        this.currentIdx = 0;
    }
    reset() {
        this.currentIdx = 0;
    }
    getNextColor() {
        const color = colorConstants.randomLayerColors[this.currentIdx];
        this.currentIdx =
            this.currentIdx < colorConstants.randomLayerColors.length - 1 ? this.currentIdx + 1 : 0;
        return color;
    }
}
const randomColorHelper = new RandomColorHelper();
export default {
    getColor: () => randomColorHelper.getNextColor(),
    reset: () => randomColorHelper.reset(),
};
