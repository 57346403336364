import Vector2d from './vector2d';
export const Direction = {
    RIGHT: new Vector2d(1, 0),
    DOWN: new Vector2d(0, 1),
    LEFT: new Vector2d(-1, 0),
    UP: new Vector2d(0, -1),
};
export const transpose = (input) => new Vector2d(input.y, -input.x);
export class Plotter {
    constructor(shape) {
        this.x = 0;
        this.y = 0;
        this.shape = shape;
    }
    lineTo(x, y) {
        this.shape.lineTo(this.x + x, this.y + y);
        this.x += x;
        this.y += y;
    }
    vecTo(vec, scalar) {
        this.lineTo(vec.x * scalar, vec.y * scalar);
    }
    lineToAbs(x, y) {
        this.shape.lineTo(x, y);
        this.x = x;
        this.y = y;
    }
}
