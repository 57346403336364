// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-views-dialogs-AlertAndProgress-module__nonstop--JfjU2 [class~=ant-modal-title]{text-align:center}.src-web-app-views-dialogs-AlertAndProgress-module__nonstop--JfjU2 [class~=ant-modal-footer]{text-align:center}.src-web-app-views-dialogs-AlertAndProgress-module__nonstop--JfjU2 .src-web-app-views-dialogs-AlertAndProgress-module__spinner-container--Mnoig{margin:16px 0;display:flex;justify-content:center}.src-web-app-views-dialogs-AlertAndProgress-module__nonstop--JfjU2 .src-web-app-views-dialogs-AlertAndProgress-module__caption--zDVaS{text-align:center}", "",{"version":3,"sources":["webpack://./src/web/app/views/dialogs/AlertAndProgress.module.scss"],"names":[],"mappings":"AACE,4FACE,iBAAA,CAEF,6FACE,iBAAA,CAGF,gJACE,aAAA,CACA,YAAA,CACA,sBAAA,CAGF,sIACE,iBAAA","sourcesContent":[".nonstop {\n  [class~=\"ant-modal-title\"] {\n    text-align: center;\n  }\n  [class~=\"ant-modal-footer\"]{\n    text-align: center;\n  }\n\n  .spinner-container {\n    margin: 16px 0;\n    display: flex;\n    justify-content: center;\n  }\n\n  .caption {\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nonstop": "src-web-app-views-dialogs-AlertAndProgress-module__nonstop--JfjU2",
	"spinner-container": "src-web-app-views-dialogs-AlertAndProgress-module__spinner-container--Mnoig",
	"caption": "src-web-app-views-dialogs-AlertAndProgress-module__caption--zDVaS"
};
export default ___CSS_LOADER_EXPORT___;
