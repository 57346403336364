import Add from './add.svg';
import Expand from './expand.svg';
import FullColor from './fullcolor.svg';
import Handle from './handle.svg';
import Invisible from './invisible.svg';
import Laser from './laser.svg';
import Lock from './lock.svg';
import Merge from './merge.svg';
import Move from './move.svg';
import Print from './print.svg';
import Ref from './ref.svg';
import Rename from './rename.svg';
import Unlock from './unlock.svg';
import Visible from './visible.svg';
export default {
    Add,
    Expand,
    FullColor,
    Handle,
    Invisible,
    Laser,
    Lock,
    Merge,
    Move,
    Print,
    Ref,
    Rename,
    Unlock,
    Visible,
};
