import * as React from 'react';
import i18n from 'helpers/i18n';
import SelectControl from 'app/components/settings/SelectControl';
function Update({ isWeb, updateNotificationOptions, updateConfigChange }) {
    if (isWeb)
        return null;
    const { lang } = i18n;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "subtitle" }, lang.settings.groups.update),
        React.createElement(SelectControl, { id: "set-auto-update", label: lang.settings.check_updates, options: updateNotificationOptions, onChange: (e) => updateConfigChange('auto_check_update', e.target.value) })));
}
export default Update;
