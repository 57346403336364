var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useRef, useState } from 'react';
import alertCaller from 'app/actions/alert-caller';
import checkDeviceStatus from 'helpers/check-device-status';
import deviceMaster from 'helpers/device-master';
import dialogCaller from 'app/actions/dialog-caller';
import getLevelingData from 'app/actions/camera/preview-helper/getLevelingData';
import progressCaller from 'app/actions/progress-caller';
import useI18n from 'helpers/useI18n';
import { extrinsicRegression, setFisheyeConfig, updateData, } from 'helpers/camera-calibration-helper';
import CalibrateChessBoard from './AdorCalibrationV2/CalibrateChessBoard';
import CheckpointData from './common/CheckpointData';
import CheckPictures from './AdorCalibrationV2/CheckPictures';
import FindCorner from './AdorCalibrationV2/FindCorner';
import Instruction from './common/Instruction';
import SolvePnP from './common/SolvePnP';
import StepElevate from './AdorCalibrationV2/StepElevate';
import { adorPnPPoints } from './common/solvePnPConstants';
import { getMaterialHeight, prepareToTakePicture, saveCheckPoint } from './AdorCalibrationV2/utils';
var Step;
(function (Step) {
    Step[Step["CHECKPOINT_DATA"] = 0] = "CHECKPOINT_DATA";
    Step[Step["CHECK_PICTURE"] = 1] = "CHECK_PICTURE";
    Step[Step["CALIBRATE_CHESSBOARD"] = 2] = "CALIBRATE_CHESSBOARD";
    Step[Step["ASK_CAMERA_TYPE"] = 3] = "ASK_CAMERA_TYPE";
    Step[Step["PUT_PAPER"] = 4] = "PUT_PAPER";
    Step[Step["FIND_CORNER"] = 5] = "FIND_CORNER";
    Step[Step["SOLVE_PNP_INSTRUCTION_1"] = 6] = "SOLVE_PNP_INSTRUCTION_1";
    Step[Step["SOLVE_PNP_1"] = 7] = "SOLVE_PNP_1";
    Step[Step["ELEVATED_CUT"] = 8] = "ELEVATED_CUT";
    Step[Step["SOLVE_PNP_2"] = 9] = "SOLVE_PNP_2";
    Step[Step["FINISH"] = 10] = "FINISH";
})(Step || (Step = {}));
const PROGRESS_ID = 'fisheye-calibration-v2';
const DIALOG_ID = 'fisheye-calibration-v2';
const AdorCalibrationV2 = ({ factoryMode = false, onClose }) => {
    const calibratingParam = useRef({});
    const lang = useI18n();
    const tCali = lang.calibration;
    const [withPitch, setWithPitch] = useState(false);
    const [step, setStep] = useState(Step.CHECKPOINT_DATA);
    const [usePreviousData, setUsePreviousData] = useState(false);
    const onBack = useCallback(() => setStep((prev) => prev - 1), []);
    const onNext = useCallback(() => setStep((prev) => prev + 1), []);
    const updateParam = useCallback((param) => {
        calibratingParam.current = Object.assign(Object.assign({}, calibratingParam.current), param);
    }, []);
    if (step === Step.CHECKPOINT_DATA) {
        return (React.createElement(CheckpointData, { askUser: factoryMode, updateParam: updateParam, onNext: (res) => {
                if (res) {
                    setUsePreviousData(true);
                    console.log('calibratingParam.current', calibratingParam.current);
                    const { heights, source } = calibratingParam.current;
                    if ((heights === null || heights === void 0 ? void 0 : heights.length) > 0 && source === 'user')
                        setStep(Step.ELEVATED_CUT);
                    else
                        setStep(Step.PUT_PAPER);
                }
                else
                    setStep(factoryMode ? Step.CALIBRATE_CHESSBOARD : Step.CHECK_PICTURE);
            }, onClose: onClose }));
    }
    if (step === Step.CHECK_PICTURE) {
        return (React.createElement(CheckPictures, { updateParam: updateParam, onNext: () => __awaiter(void 0, void 0, void 0, function* () {
                progressCaller.openNonstopProgress({ id: PROGRESS_ID, message: lang.device.processing });
                yield saveCheckPoint(calibratingParam.current);
                setUsePreviousData(true);
                progressCaller.popById(PROGRESS_ID);
                setStep(Step.PUT_PAPER);
            }), onClose: onClose }));
    }
    if (step === Step.CALIBRATE_CHESSBOARD) {
        return (React.createElement(CalibrateChessBoard, { updateParam: updateParam, onNext: () => __awaiter(void 0, void 0, void 0, function* () {
                progressCaller.openNonstopProgress({ id: PROGRESS_ID, message: lang.device.processing });
                yield saveCheckPoint(calibratingParam.current);
                setUsePreviousData(true);
                progressCaller.popById(PROGRESS_ID);
                setStep(Step.PUT_PAPER);
            }), onBack: () => setStep(Step.CHECKPOINT_DATA), onClose: onClose }));
    }
    if (step === Step.ASK_CAMERA_TYPE) {
        const onClick = (val) => {
            setWithPitch(val);
            onNext();
        };
        return (React.createElement(Instruction, { onClose: () => onClose(false), animationSrcs: [], title: "Please Select your camera type", buttons: [
                { label: '正拍', type: 'primary', onClick: () => onClick(false) },
                { label: '斜拍', type: 'primary', onClick: () => onClick(true) },
            ] }));
    }
    if (step === Step.PUT_PAPER) {
        const handleNext = (doCutting = true) => __awaiter(void 0, void 0, void 0, function* () {
            const deviceStatus = yield checkDeviceStatus(deviceMaster.currentDevice.info);
            if (!deviceStatus)
                return;
            progressCaller.openNonstopProgress({
                id: PROGRESS_ID,
                message: tCali.getting_plane_height,
            });
            try {
                const height = yield getMaterialHeight();
                console.log('height', height);
                if (usePreviousData) {
                    const dh = height - calibratingParam.current.refHeight;
                    calibratingParam.current.dh1 = dh;
                }
                else {
                    calibratingParam.current.refHeight = height;
                }
                progressCaller.update(PROGRESS_ID, { message: tCali.drawing_calibration_image });
                if (doCutting) {
                    if (usePreviousData)
                        yield deviceMaster.doAdorCalibrationV2(2);
                    else
                        yield deviceMaster.doAdorCalibrationV2(1, withPitch);
                }
                progressCaller.update(PROGRESS_ID, { message: tCali.preparing_to_take_picture });
                yield prepareToTakePicture();
                setStep(usePreviousData ? Step.SOLVE_PNP_INSTRUCTION_1 : Step.FIND_CORNER);
            }
            catch (err) {
                console.error(err);
            }
            finally {
                progressCaller.popById(PROGRESS_ID);
            }
        });
        return (React.createElement(Instruction, { onClose: () => onClose(false), animationSrcs: [
                { src: 'video/ador-calibration-2/paper.webm', type: 'video/webm' },
                { src: 'video/ador-calibration-2/paper.mp4', type: 'video/mp4' },
            ], title: tCali.put_paper, steps: [tCali.put_paper_step1, tCali.put_paper_step2, tCali.put_paper_step3], buttons: [
                { label: tCali.back, onClick: () => setStep(Step.CHECKPOINT_DATA) },
                // { label: tCali.skip, onClick: () => handleNext(false) },
                { label: tCali.start_engrave, onClick: () => handleNext(true), type: 'primary' },
            ] }));
    }
    if (step === Step.FIND_CORNER)
        return (React.createElement(FindCorner, { withPitch: withPitch, updateParam: updateParam, onClose: onClose, onBack: () => setStep(Step.PUT_PAPER), onNext: () => __awaiter(void 0, void 0, void 0, function* () {
                progressCaller.openNonstopProgress({ id: PROGRESS_ID, message: lang.device.processing });
                const levelingData = yield getLevelingData('hexa_platform');
                const refHeight = levelingData.E;
                Object.keys(levelingData).forEach((key) => {
                    levelingData[key] = refHeight - levelingData[key];
                });
                updateParam({ levelingData });
                yield saveCheckPoint(calibratingParam.current);
                progressCaller.popById(PROGRESS_ID);
                setStep(Step.ELEVATED_CUT);
            }) }));
    if (step === Step.SOLVE_PNP_INSTRUCTION_1) {
        return (React.createElement(Instruction, { onClose: () => onClose(false), animationSrcs: [
                { src: 'video/ador-calibration-2/align.webm', type: 'video/webm' },
                { src: 'video/ador-calibration-2/align.mp4', type: 'video/mp4' },
            ], title: tCali.solve_pnp_title, steps: [tCali.solve_pnp_step1, tCali.solve_pnp_step2], buttons: [
                { label: tCali.back, onClick: () => setStep(Step.PUT_PAPER) },
                { label: tCali.next, onClick: onNext, type: 'primary' },
            ] }));
    }
    if (step === Step.SOLVE_PNP_1) {
        return (React.createElement(SolvePnP, { hasNext: true, params: calibratingParam.current, dh: calibratingParam.current.dh1, refPoints: adorPnPPoints, onClose: onClose, onBack: () => setStep(Step.SOLVE_PNP_INSTRUCTION_1), onNext: (rvec, tvec) => __awaiter(void 0, void 0, void 0, function* () {
                progressCaller.openNonstopProgress({ id: PROGRESS_ID, message: lang.device.processing });
                updateParam({
                    rvec,
                    tvec,
                    rvecs: [rvec],
                    tvecs: [tvec],
                    heights: [calibratingParam.current.dh1],
                });
                yield updateData(calibratingParam.current);
                yield saveCheckPoint(calibratingParam.current);
                console.log('calibratingParam.current', calibratingParam.current);
                progressCaller.popById(PROGRESS_ID);
                setStep(Step.ELEVATED_CUT);
            }) }));
    }
    if (step === Step.ELEVATED_CUT) {
        const handleNext = () => __awaiter(void 0, void 0, void 0, function* () {
            const deviceStatus = yield checkDeviceStatus(deviceMaster.currentDevice.info);
            if (!deviceStatus)
                return;
            progressCaller.openNonstopProgress({
                id: PROGRESS_ID,
                message: tCali.getting_plane_height,
            });
            try {
                const height = yield getMaterialHeight();
                console.log('height', height);
                const dh = height - calibratingParam.current.refHeight;
                console.log('dh', dh);
                calibratingParam.current.dh2 = dh;
                progressCaller.update(PROGRESS_ID, { message: tCali.drawing_calibration_image });
                yield deviceMaster.doAdorCalibrationV2(2);
                progressCaller.update(PROGRESS_ID, { message: tCali.preparing_to_take_picture });
                yield prepareToTakePicture();
                onNext();
            }
            catch (err) {
                console.error(err);
            }
            finally {
                progressCaller.popById(PROGRESS_ID);
            }
        });
        return (React.createElement(StepElevate, { onNext: handleNext, onBack: () => setStep(Step.SOLVE_PNP_1), onClose: onClose }));
    }
    return (React.createElement(SolvePnP, { params: calibratingParam.current, dh: calibratingParam.current.dh2, refPoints: adorPnPPoints, onClose: onClose, onBack: onBack, onNext: (rvec, tvec) => __awaiter(void 0, void 0, void 0, function* () {
            const { rvecs, tvecs, heights } = calibratingParam.current;
            rvecs.push(rvec);
            tvecs.push(tvec);
            heights.push(calibratingParam.current.dh2);
            updateParam({ rvecs, tvecs, heights });
            const { success, data } = yield extrinsicRegression(rvecs, tvecs, heights);
            if (!success) {
                alertCaller.popUpError({ message: 'Failed to do extrinsic regression.' });
                return;
            }
            updateParam(data);
            console.log('calibratingParam.current', calibratingParam.current);
            const param = {
                source: calibratingParam.current.source,
                k: calibratingParam.current.k,
                d: calibratingParam.current.d,
                refHeight: calibratingParam.current.refHeight,
                rvec: calibratingParam.current.rvec,
                tvec: calibratingParam.current.tvec,
                rvec_polyfit: calibratingParam.current.rvec_polyfit,
                tvec_polyfit: calibratingParam.current.tvec_polyfit,
                levelingData: calibratingParam.current.levelingData,
                v: 2,
            };
            const res = yield setFisheyeConfig(param);
            if (res.status === 'ok') {
                alertCaller.popUp({ message: tCali.camera_parameter_saved_successfully });
                onClose(true);
            }
            else {
                alertCaller.popUpError({
                    message: `${tCali.failed_to_save_camera_parameter}:<br />${JSON.stringify(res)}`,
                });
            }
        }) }));
};
export const showAdorCalibrationV2 = (factoryMode = false) => __awaiter(void 0, void 0, void 0, function* () {
    if (dialogCaller.isIdExist(DIALOG_ID))
        return false;
    return new Promise((resolve) => {
        dialogCaller.addDialogComponent(DIALOG_ID, React.createElement(AdorCalibrationV2, { factoryMode: factoryMode, onClose: (completed = false) => {
                dialogCaller.popDialogById(DIALOG_ID);
                resolve(completed);
            } }));
    });
});
export default AdorCalibrationV2;
