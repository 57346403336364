import React from 'react';
import eventEmitterFactory from 'helpers/eventEmitterFactory';
export const TopBarHintsContext = React.createContext({
    hintType: null,
});
const topBarHintsEventEmitter = eventEmitterFactory.createEventEmitter('top-bar-hints');
export class TopBarHintsContextProvider extends React.Component {
    constructor(props) {
        super(props);
        this.setHint = (hintType) => {
            this.setState({ hintType });
        };
        this.removeHint = () => {
            this.setState({ hintType: null });
        };
        this.state = {
            hintType: null,
        };
    }
    componentDidMount() {
        topBarHintsEventEmitter.on('SET_HINT', this.setHint.bind(this));
        topBarHintsEventEmitter.on('REMOVE_HINT', this.removeHint.bind(this));
    }
    componentWillUnmount() {
        topBarHintsEventEmitter.removeAllListeners();
    }
    render() {
        const { children } = this.props;
        const { hintType, } = this.state;
        return (React.createElement(TopBarHintsContext.Provider, { value: {
                hintType,
            } }, children));
    }
}
