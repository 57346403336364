const lang = {
    global: {
        cancel: 'Batal',
        back: 'Kembali',
    },
    general: {
        processing: 'Memproses...',
        choose_folder: 'Pilih Folder',
    },
    buttons: {
        next: 'SETERUSNYA',
        back: 'KEMBALI',
        done: 'SELESAI',
        back_to_beam_studio: 'Kembali ke Beam Studio',
    },
    topbar: {
        untitled: 'Tanpa Tajuk',
        titles: {
            settings: 'Tetapan',
        },
        export: 'EKSPORT',
        preview: 'PRATONTON',
        preview_title: 'Pratonton',
        curve_engrave: 'Lengkung 3D',
        task_preview: 'Laluan pratonton',
        frame_task: 'Bingkai sedang berjalan',
        borderless: '(BUKA TANPA SEMPADAN)',
        tag_names: {
            rect: 'Segi empat tepat',
            ellipse: 'Bulatan',
            path: 'Laluan',
            polygon: 'Poligon',
            image: 'Imej',
            text: 'Teks',
            text_path: 'Teks pada Laluan',
            pass_through_object: 'Objek Lulus Melalui',
            line: 'Garisan',
            g: 'Kumpulan',
            multi_select: 'Beberapa Objek',
            use: 'Objek Diimport',
            svg: 'Objek SVG',
            dxf: 'Objek DXF',
            no_selection: 'Tiada Pilihan',
        },
        alerts: {
            start_preview_timeout: '#803 Timeout telah berlaku ketika memulakan mod pratonton. Sila mulakan semula mesin atau Beam Studio anda. Jika ralat ini berterusan, sila ikuti <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">panduan ini</a>.',
            fail_to_start_preview: '#803 Gagal memulakan mod pratonton. Sila mulakan semula mesin atau Beam Studio anda. Jika ralat ini berterusan, sila ikuti <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">panduan ini</a>.',
            fail_to_connect_with_camera: '#803 Gagal menghubungi kamera mesin. Sila mulakan semula mesin atau Beam Studio anda. Jika ralat ini berterusan, sila ikuti <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">panduan ini</a>.',
            add_content_first: 'Sila tambah objek terlebih dahulu',
            headtype_mismatch: 'Modul tidak sepadan dikesan. ',
            headtype_none: 'Modul tidak dikesan. ',
            headtype_unknown: 'Modul tidak diketahui dikesan. ',
            install_correct_headtype: 'Sila pasang modul laser diod 10W/20W dengan betul untuk membolehkan laser beroperasi.',
            door_opened: 'Sila tutup pintu untuk membolehkan laser beroperasi.',
            fail_to_get_door_status: 'Sila pastikan pintu ditutup untuk membolehkan laser beroperasi.',
            QcleanScene: 'Adakah anda mahu padamkan lukisan?<br/>Ini juga akan memadam sejarah undo anda!',
            power_too_high: 'KUASA TERLALU TINGGI',
            power_too_high_msg: 'Menggunakan kuasa laser yang lebih rendah (bawah 70%) akan memanjangkan jangka hayat tiub laser.\nMasukkan "DIAMBIL PERHATIAN" untuk meneruskan.',
            power_too_high_confirm: 'DIAMBIL PERHATIAN',
            pwm_unavailable: 'Mod Kedalaman memerlukan versi firmware 4.3.4 / 5.3.4 atau ke atas. Adakah anda ingin mengemas kini firmware sekarang?',
            job_origin_unavailable: 'Menetapkan asal kerja memerlukan versi firmware 4.3.5 / 5.3.5 atau lebih tinggi. Adakah anda ingin mengemas kini firmware sekarang?',
            job_origin_warning: 'Anda sedang menggunakan “kedudukan semasa” sebagai titik permulaan. Pastikan kepala laser dipindahkan ke kedudukan yang betul untuk mengelakkan perlanggaran.',
        },
        hint: {
            polygon: 'Tekan kekunci + / - untuk menambah / mengurangkan sisi.',
        },
        menu: {
            preferences: 'Keutamaan',
            hide: 'Sembunyikan Beam Studio',
            hideothers: 'Sembunyikan Lain',
            service: 'Perkhidmatan',
            quit: 'Tutup',
            window: 'Tetingkap',
            minimize: 'Minimumkan',
            close: 'Tutup Tetingkap',
            file: 'Fail',
            edit: 'Sunting',
            help: 'Bantuan',
            open: 'Buka',
            samples: 'Contoh',
            import_hello_beamo: 'Contoh beamo',
            import_hello_beambox: 'Contoh beambox',
            import_material_testing_old: 'Ujian Ukiran Bahan - Klasik',
            import_material_testing_simple_cut: 'Ujian Pemotongan Bahan - Mudah',
            import_material_testing_cut: 'Ujian Pemotongan Bahan',
            import_material_testing_engrave: 'Ujian Ukiran Bahan',
            import_material_testing_line: 'Ujian Garisan Bahan',
            import_material_printing_test: 'Ujian Cetak Bahan',
            import_ador_laser_example: 'Contoh Ador Laser',
            import_ador_printing_example_single: 'Contoh Cetakan Ador - Satu Warna',
            import_ador_printing_example_full: 'Contoh Cetakan Ador - Warna Penuh',
            import_acrylic_focus_probe: 'Pemfokus Akrilik Probe - 3mm',
            export_to: 'Eksport Kepada...',
            export_flux_task: 'tugas FLUX',
            export_BVG: 'BVG',
            export_SVG: 'SVG',
            export_PNG: 'PNG',
            export_JPG: 'JPG',
            save_scene: 'Simpan',
            save_as: 'Simpan Sebagai...',
            save_to_cloud: 'Simpan ke Awan',
            about_beam_studio: 'Mengenai Beam Studio',
            undo: 'Buat asal',
            redo: 'Buat semula',
            cut: 'Potong',
            copy: 'Salin',
            paste: 'Tampal',
            paste_in_place: 'Tampal di tempat',
            group: 'Kumpulkan',
            ungroup: 'Nyahkumpul',
            delete: 'Padam',
            duplicate: 'Duplikat',
            offset: 'Ofset',
            scale: 'Skala',
            rotate: 'Putar',
            reset: 'Set semula',
            align_center: 'Jajar tengah',
            photo_edit: 'Imej',
            svg_edit: 'SVG',
            path: 'Laluan',
            decompose_path: 'Nyahkompon',
            object: 'Objek',
            layer_setting: 'Lapisan',
            layer_color_config: 'Tetapan warna',
            image_sharpen: 'Tajamkan',
            image_crop: 'Potong',
            image_invert: 'Songsang',
            image_stamp: 'Cungkil',
            image_vectorize: 'Jejak',
            image_curve: 'Lengkung',
            align_to_edges: 'Jajarkan ke Tepi',
            document_setting: 'Tetapan Dokumen',
            document_setting_short: 'Dokumen',
            rotary_setup: 'Persediaan Putaran',
            clear_scene: 'Fail baru',
            machines: 'Mesin',
            add_new_machine: 'Tambah Mesin Baru',
            help_center: 'Pusat Bantuan',
            show_gesture_tutorial: 'Tutorial Gerak Isyarat',
            show_start_tutorial: 'Tunjukkan Tutorial Permulaan',
            show_ui_intro: 'Tunjukkan Pengenalan Antaramuka',
            questionnaire: 'Soal Selidik Maklum Balas',
            change_logs: 'Log Perubahan',
            contact: 'Hubungi Kami',
            tutorial: 'Tutorial Permulaan',
            design_market: 'Pasaran Reka Bentuk',
            forum: 'Forum Komuniti',
            software_update: 'Kemas Kini Perisian',
            bug_report: 'Laporan Pepijat',
            dashboard: 'Papan Pemuka',
            machine_info: 'Maklumat Mesin',
            network_testing: 'Ujian Rangkaian',
            commands: 'Arahan',
            update_firmware: 'Kemas Kini Perisian',
            using_beam_studio_api: 'Menggunakan API Beam Studio',
            set_as_default: 'Tetapkan sebagai Lalai',
            calibrate_beambox_camera: 'Kalibrasi Kamera',
            calibrate_printer_module: 'Tentukur Modul Pencetakan',
            calibrate_ir_module: 'Tentukur Modul Inframerah',
            calibrate_beambox_camera_borderless: 'Kalibrasi Kamera (Tanpa Bingkai)',
            calibrate_diode_module: 'Kalibrasi Modul Laser Diod',
            manage_account: 'Urus Akaun Saya',
            sign_in: 'Log Masuk',
            sign_out: 'Log Keluar',
            account: 'Akaun',
            my_account: 'Akaun Saya',
            download_log: 'Muat turun Log',
            download_log_canceled: 'Muat turun log dibatalkan',
            download_log_error: 'Ralat tidak diketahui, sila cuba lagi',
            keyboard_shortcuts: 'Pintasan papan kekunci',
            log: {
                network: 'Rangkaian',
                hardware: 'Perkakasan',
                discover: 'Cari',
                usb: 'USB',
                usblist: 'Senarai USB',
                camera: 'Kamera',
                cloud: 'Awan',
                player: 'Pemain',
                robot: 'Robot',
            },
            link: {
                help_center: 'https://helpcenter.flux3dp.com/',
                contact_us: 'https://flux3dp.zendesk.com/hc/en-us/requests/new',
                forum: 'https://www.facebook.com/groups/flux.laser/',
                downloads: 'https://flux3dp.com/downloads/',
                beam_studio_api: 'https://github.com/flux3dp/beam-studio/wiki/Beam-Studio-Easy-API',
                design_market: 'https://dmkt.io',
                shortcuts: 'https://support.flux3dp.com/hc/en-us/articles/10003978157455',
            },
            view: 'Lihat',
            zoom_in: 'Zum Masuk',
            zoom_out: 'Zum Keluar',
            fit_to_window: 'Muat ke Saiz Tetingkap',
            zoom_with_window: 'Padankan Secara Automatik ke Saiz Tetingkap',
            borderless_mode: 'Mod Tanpa Sempadan',
            show_grids: 'Tunjukkan Grid',
            show_rulers: 'Tunjukkan Pembaris',
            show_layer_color: 'Gunakan Warna Lapisan',
            anti_aliasing: 'Anti-Aliasing',
            disassemble_use: 'Nyahpasang',
            about: 'Tentang Beam Studio',
            switch_to_beta: 'Tukar ke Beta Release',
            switch_to_latest: 'Tukar ke Release Stabil',
            reload_app: 'Muat Semula Apl',
            recent: 'Buka Terkini',
            update: 'Semak Kemas Kini',
            dev_tool: 'Alat Debug',
            camera_calibration_data: 'Data Penentukuran Kamera',
            upload_data: 'Muat Naik Data',
            download_data: 'Muat Turun Data',
            tools: {
                title: 'Alat',
                material_test_generator: 'Penjana Ujian Bahan',
                qr_code_generator: 'Penjana Kod QR',
                boxgen: 'Boxgen',
            },
        },
        select_machine: 'Pilih mesin',
    },
    support: {
        no_webgl: 'WebGL tidak disokong. Sila gunakan peranti lain.',
        no_vcredist: 'Sila pasang Visual C++ Redistributable 2015<br/>Ia boleh dimuat turun di flux3dp.com',
    },
    generic_error: {
        UNKNOWN_ERROR: '[UE] Ralat tidak diketahui berlaku. Sila mulakan semula Beam Studio dan mesin.',
        OPERATION_ERROR: '[OE] Konflik status berlaku, sila cuba semula tindakan.',
        SUBSYSTEM_ERROR: '[SE] Ralat perisian mesin sewaktu melaksanakan tugas. Sila mulakan semula mesin.',
        UNKNOWN_COMMAND: '[UC] Sila kemaskini Perisian Peranti',
    },
    device_selection: {
        no_beambox: '#801 Kami tidak dapat mengesan mesin anda dalam rangkaian.\nSila ikuti <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/360001683556">panduan</a> untuk menyelesaikan masalah sambungan.',
        no_device_web: "#801 Semak status mesin anda, atau klik pada 'Tambah Mesin Baru' di bawah untuk menetapkan mesin.",
        select_usb_device: 'Pilih Peranti USB',
    },
    update: {
        update: 'Kemaskini',
        release_note: 'Nota Keluaran:',
        firmware: {
            caption: 'Kemaskini Perisian Peranti kepada mesin tersedia',
            message_pattern_1: '"%s" kini bersedia untuk kemaskini perisian peranti.',
            message_pattern_2: '%s Perisian Peranti v%s kini tersedia - Anda ada v%s.',
            latest_firmware: {
                caption: 'Kemaskini perisian peranti Mesin',
                message: 'Anda ada versi terkini perisian peranti Mesin',
                still_update: 'KEMASKINI',
                cant_get_latest: 'Tidak dapat dapatkan maklumat perisian peranti terkini.',
            },
            confirm: 'MUAT NAIK',
            upload_file: 'Muat naik perisian peranti (*.bin / *.fxfw)',
            update_success: 'Kemaskini perisian peranti berjaya dimuat naik',
            update_fail: '#822 Kemaskini Gagal',
            too_old_for_web: 'Versi perisian peranti mesin semasa anda ialah v%s.\nJika anda mahu menggunakan versi atas talian Studio Beam, sila kemaskini perisian peranti mesin ke versi terkini.',
            force_update_message: '#814 Sila kemaskini mesin anda ke versi perisian peranti terkini.',
            firmware_too_old_update_by_sdcard: 'Versi perisian peranti terlalu lama. Sila kemaskini perisian peranti menggunakan kad SD.',
        },
        software: {
            checking: 'Memeriksa Kemaskini',
            switch_version: 'Tukar Versi',
            check_update: 'Semak Kemaskini',
            caption: 'Kemaskini Perisian Beam Studio tersedia',
            downloading: 'Memuat turun kemaskini di latar belakang, anda boleh klik "OK" untuk meneruskan kerja anda.',
            install_or_not: 'sedia untuk dikemaskini. Adakah anda mahu memulakan semula dan mengemaskini sekarang?',
            switch_or_not: 'sedia untuk ditukar. Adakah anda mahu memulakan semula dan menukar sekarang?',
            available_update: 'Beam Studio v%s tersedia sekarang. Anda mempunyai v%s. Adakah anda mahu memuat turun kemaskini?',
            available_switch: 'Beam Studio v%s tersedia sekarang. Anda mempunyai v%s. Adakah anda mahu beralih ke versi ini?',
            not_found: 'Anda menggunakan versi terkini Beam Studio.',
            no_response: 'Pelayan tidak boleh dicapai<br/>Sila semak sambungan internet',
            switch_version_not_found: 'Versi Boleh Tukar tidak dijumpai.',
            yes: 'Ya',
            no: 'Tidak',
            update_for_ador: 'Versi perisian semasa %s tidak serasi, sila muat turun versi terkini Beam Studio untuk Ador.',
        },
        updating: 'Mengemaskini...',
        skip: 'Langkau Versi Ini',
        preparing: 'Mempersiapkan...',
        later: 'KEMUDIAN',
        download: 'KEMASKINI DALAM TALIAN',
        cannot_reach_internet: '#823 Pelayan tidak boleh dicapai<br/>Sila semak sambungan internet',
        install: 'PASANG',
        upload: 'MUAT NAIK',
    },
    topmenu: {
        version: 'Versi',
        credit: 'Beam Studio dimungkinkan oleh projek sumber terbuka <a target="_blank" href="https://github.com/flux3dp/beam-studio">Beam Studio</a> dan <a target="_blank" href="https://flux3dp.com/credits/">perisian sumber terbuka</a> lain.',
        ok: 'OK',
        file: {
            label: 'Fail',
            import: 'Import',
            save_fcode: 'Eksport Tugas FLUX',
            save_scene: 'Simpan Babak',
            save_svg: 'Eksport SVG',
            save_png: 'Eksport PNG',
            save_jpg: 'Eksport JPG',
            converting: 'Menukar ke Imej...',
            all_files: 'Semua Fail',
            svg_files: 'SVG',
            png_files: 'PNG',
            jpg_files: 'JPG',
            scene_files: 'Skrin Beam Studio',
            fcode_files: 'Kod FLUX',
            clear_recent: 'Kosongkan Baru-baru ini Dibuka',
            path_not_exit: 'Laluan ini sepertinya tidak wujud lagi pada cakera.',
        },
        device: {
            download_log_canceled: 'Muat turun log dibatalkan',
            download_log_error: 'Ralat tidak diketahui berlaku, sila cuba lagi kemudian',
            log: {
                usblist: 'Senarai USB',
            },
            network_test: 'Uji Rangkaian',
        },
    },
    initialize: {
        next: 'Seterusnya',
        start: 'Mulakan',
        skip: 'Langkau',
        cancel: 'Batal',
        confirm: 'Sahkan',
        connect: 'Sambung',
        back: 'Undur',
        retry: 'Cuba Lagi',
        no_machine: 'Saya tiada mesin sekarang.',
        select_language: 'Pilih Bahasa',
        select_machine_type: 'Pilih Model Anda',
        select_connection_type: 'Bagaimana anda mahu sambung?',
        connection_types: {
            wifi: 'Wi-Fi',
            wired: 'Rangkaian Berwayar',
            ether2ether: 'Sambungan Terus',
            usb: 'Sambungan USB',
        },
        connect_wifi: {
            title: 'Menyambung ke Wi-Fi',
            tutorial1: '1. Pergi ke Panel Sentuh > Klik "Rangkaian" > "Sambung ke WiFi".',
            tutorial1_ador: '1. Pergi ke Panel Sentuh > Klik "MESIN" > Klik "Rangkaian" > "Sambung ke WiFi".',
            tutorial2: '2. Pilih dan sambung Wi-Fi pilihan anda.',
            what_if_1: 'Apa yang perlu dilakukan jika saya tidak nampak Wi-Fi saya?',
            what_if_1_content: '1. Mod penyulitan Wi-Fi perlu WPA2 atau tiada kata laluan.\n2. Mod penyulitan boleh ditetapkan dalam antara muka pentadbiran penghala Wi-Fi. Jika penghala tidak menyokong WPA2 dan anda memerlukan bantuan untuk memilih penghala yang betul, sila hubungi Sokongan FLUX.',
            what_if_2: 'Apa yang perlu dilakukan jika saya tidak dapat melihat sebarang Wi-Fi?',
            what_if_2_content: '1. Pastikan dongle Wi-Fi dimasukkan sepenuhnya.\n2. Jika tiada Alamat MAC rangkaian wayarles pada skrin sentuh, sila hubungi Sokongan FLUX.\n3. Saluran Wi-Fi perlu 2.4Ghz (5Ghz tidak disokong).',
        },
        connect_wired: {
            title: 'Menyambung ke Rangkaian Berwayar',
            tutorial1: '1. Sambungkan mesin dengan penghala anda.',
            tutorial2: '2. Tekan "Rangkaian" untuk mendapatkan IP rangkaian berwayar.',
            tutorial2_ador: '2. Tekan "MESIN" > "Rangkaian" untuk mendapatkan IP rangkaian berwayar.',
            what_if_1: 'Apa yang perlu dilakukan jika IP kosong?',
            what_if_1_content: '1. Pastikan Kabel Ethernet dimasukkan sepenuhnya.\n2. Jika tiada Alamat MAC rangkaian berwayar pada skrin sentuh, sila hubungi Sokongan FLUX.',
            what_if_2: 'Apa yang perlu dilakukan jika IP bermula dengan 169?',
            what_if_2_content: '1. Jika alamat IP bermula dengan 169.254, ia sepatutnya isu tetapan DHCP, sila hubungi ISP (pembekal perkhidmatan internet) anda untuk bantuan lanjut.\n2. Jika komputer anda menyambung ke internet secara terus menggunakan PPPoE, sila tukar kepada menggunakan penghala untuk menyambung menggunakan PPPoE, dan hidupkan ciri DHCP dalam penghala.',
        },
        connect_ethernet: {
            title: 'Sambungan Terus',
            tutorial1: '1. Sambungkan mesin dengan komputer anda dengan kabel ethernet.',
            tutorial2_1: '2. Ikuti ',
            tutorial2_a_text: 'panduan ini',
            tutorial2_a_href_mac: 'https://support.flux3dp.com/hc/en-us/articles/360001517076',
            tutorial2_a_href_win: 'https://support.flux3dp.com/hc/en-us/articles/360001507715',
            tutorial2_2: ' untuk menjadikan komputer anda sebagai penghala.',
            tutorial3: '3. Klik Seterusnya.',
        },
        connect_usb: {
            title: 'Sambungan USB',
            title_sub: ' (HEXA Sahaja)',
            tutorial1: '1. Sambungkan mesin dengan komputer anda dengan kabel USB.',
            tutorial2: '2. Klik Seterusnya.',
            turn_off_machine: 'Matikan mesin.',
            turn_on_machine: 'Hidupkan mesin.',
            wait_for_turning_on: "Klik 'Seterusnya' selepas proses permulaan selesai dan anda telah masuk ke skrin utama.",
        },
        connect_machine_ip: {
            check_usb: 'Semak Sambungan USB',
            enter_ip: 'Masukkan IP Mesin Anda',
            check_ip: 'Memeriksa ketersediaan IP',
            invalid_ip: 'IP Tidak Sah: ',
            invalid_format: 'Format tidak sah',
            starts_with_169254: 'Bermula dengan 169.254',
            unreachable: 'IP tidak boleh dicapai',
            check_connection: 'Memeriksa Sambungan Mesin',
            check_firmware: 'Memeriksa versi perisian',
            check_camera: 'Memeriksa ketersediaan kamera',
            retry: 'Cuba lagi',
            succeeded_message: 'Berjaya disambungkan 🎉',
            finish_setting: 'Mulakan Mencipta!',
        },
        connecting: 'Menyambung...',
        setting_completed: {
            start: 'Mulakan',
            great: 'Selamat datang ke Beam Studio',
            setup_later: 'Anda boleh menyediakan mesin anda dari Titlebar > "Mesin" > "Persediaan Mesin"',
            back: 'Kembali',
            ok: 'MULA MENCIPTA',
        },
    },
    error_pages: {
        screen_size: 'Sila ambil perhatian bahawa Beam Studio mungkin tidak berfungsi dengan optimum pada peranti anda. Untuk pengalaman yang terbaik, ia memerlukan peranti dengan lebar skrin minimum 1024 piksel.',
    },
    menu: {
        mm: 'mm',
        inches: 'Inci',
    },
    settings: {
        on: 'Hidup',
        off: 'Mati',
        low: 'Rendah',
        high: 'Tinggi',
        caption: 'Tetapan',
        tabs: {
            general: 'Umum',
            device: 'Mesin',
        },
        ip: 'Alamat IP Mesin',
        guess_poke: 'Cari alamat IP mesin',
        auto_connect: 'Pilih mesin tunggal secara automatik',
        wrong_ip_format: 'Format IP Salah',
        default_machine: 'Mesin Lalai',
        default_machine_button: 'Kosongkan',
        remove_default_machine_button: 'Buang',
        confirm_remove_default: 'Mesin lalai akan dibuang.',
        reset: 'Tetapkan Semula Beam Studio',
        reset_now: 'Tetapkan Semula Beam Studio',
        confirm_reset: 'Sahkan tetapkan semula Beam Studio?',
        language: 'Bahasa',
        notifications: 'Pemberitahuan Meja',
        check_updates: 'Semak Automatik',
        autosave_enabled: 'Simpan Automatik',
        autosave_path: 'Lokasi Simpan Automatik',
        autosave_interval: 'Simpan Setiap',
        autosave_number: 'Bilangan Simpan Automatik',
        autosave_path_not_correct: 'Laluan yang dinyatakan tidak dijumpai.',
        preview_movement_speed: 'Kelajuan Pratonton Pergerakan',
        slow: 'Perlahan',
        medium: 'Sederhana',
        fast: 'Laju',
        default_units: 'Unit Lalai',
        default_font_family: 'Font Lalai',
        default_font_style: 'Gaya Font Lalai',
        fast_gradient: 'Pengoptimuman Kelajuan',
        engraving_direction: 'Arah',
        top_down: 'Atas-bawah',
        bottom_up: 'Bawah-atas',
        vector_speed_constraint: 'Had Kelajuan (20mm/s)',
        loop_compensation: 'Pampasan Gelung',
        blade_radius: 'Jejari Bilah',
        blade_precut_switch: 'Pra-potong Bilah',
        blade_precut_position: 'Kedudukan Pra-potong',
        default_beambox_model: 'Tetapan Dokumen Lalai',
        guides_origin: 'Asal Panduan',
        guides: 'Panduan',
        image_downsampling: 'Kualiti Pratonton Bitmap',
        anti_aliasing: 'Anti-Aliasing',
        continuous_drawing: 'Lukisan Berterusan',
        trace_output: 'Output Imej Jejak',
        single_object: 'Objek Tunggal',
        grouped_objects: 'Objek Berkumpulan',
        simplify_clipper_path: 'Optimumkan Laluan Dikira',
        enable_low_speed: 'Dayakan pergerakan perlahan',
        enable_custom_backlash: 'Dayakan Pampasan Undur Tersuai',
        auto_switch_tab: 'Tukar Automatik Panel Lapisan dan Objek',
        custom_preview_height: 'Ketinggian Pratonton Tersuai',
        mask: 'Penyepadan Kawasan Kerja',
        text_path_calc_optimization: 'Pengoptimuman Pengiraan Laluan',
        font_substitute: 'Gantikan Aksara Tidak Disokong',
        font_convert: 'Penukar Teks ke Laluan',
        default_borderless_mode: 'Buka Bawah Lalai',
        default_enable_autofocus_module: 'Autofokus Lalai',
        default_enable_diode_module: 'Laser Diod Lalai',
        diode_offset: 'Ofset Laser Diod',
        autofocus_offset: 'Ofset Autofokus',
        diode_one_way_engraving: 'Ukiran Satu Arah Laser Diod',
        diode_two_way_warning: 'Pancaran dua arah lebih pantas dan berkemungkinan menjana ketidaktepatan pada kedudukan ukiran laser. Disyorkan untuk uji dahulu.',
        share_with_flux: 'Kongsi Analitik Studio Sinar',
        none: 'Tiada',
        close: 'Tutup',
        enabled: 'Didayakan',
        disabled: 'Dilumpuhkan',
        cancel: 'Batal',
        done: 'Guna',
        module_offset_10w: 'Ofset Laser Diod 10W',
        module_offset_20w: 'Ofset Laser Diod 20W',
        module_offset_printer: 'Ofset Pencetak',
        module_offset_2w_ir: 'Ofset Laser Inframerah 2W',
        printer_advanced_mode: 'Mod Lanjutan Pencetak',
        default_laser_module: 'Modul Laser Lalai',
        low_laser_for_preview: 'Laser untuk Bingkai sedang berjalan',
        groups: {
            general: 'Umum',
            update: 'Kemaskini Perisian',
            connection: 'Sambungan',
            autosave: 'Simpanan Automatik',
            camera: 'Kamera',
            editor: 'Penyunting',
            engraving: 'Pemotongan Raster',
            path: 'Vektor',
            mask: 'Pemotongan Kawasan Kerja',
            text_to_path: 'Teks',
            modules: 'Tambahan',
            ador_modules: 'Modul Ador',
            privacy: 'Privasi',
        },
        notification_on: 'Hidup',
        notification_off: 'Mati',
        update_latest: 'Terkini',
        update_beta: 'Beta',
        help_center_urls: {
            connection: 'https://support.flux3dp.com/hc/en-us/sections/360000302135',
            image_downsampling: 'https://support.flux3dp.com/hc/en-us/articles/360004494995',
            anti_aliasing: 'https://support.flux3dp.com/hc/en-us/articles/360004408956',
            continuous_drawing: 'https://support.flux3dp.com/hc/en-us/articles/360004406496',
            simplify_clipper_path: 'https://support.flux3dp.com/hc/en-us/articles/360004407276',
            fast_gradient: 'https://support.flux3dp.com/hc/en-us/articles/360004496235',
            reverse_engraving: 'https://support.flux3dp.com/hc/en-us/articles/',
            vector_speed_constraint: 'https://support.flux3dp.com/hc/en-us/articles/360004496495',
            loop_compensation: 'https://support.flux3dp.com/hc/en-us/articles/360004408856',
            mask: 'https://support.flux3dp.com/hc/en-us/articles/360004408876',
            font_substitute: 'https://support.flux3dp.com/hc/en-us/articles/360004496575',
            font_convert: 'https://support.flux3dp.com/hc/en-us/articles/9132766761743',
            default_borderless_mode: 'https://support.flux3dp.com/hc/en-us/articles/360001104076',
            default_enable_autofocus_module: 'https://support.flux3dp.com/hc/en-us/articles/360001574536',
            default_enable_diode_module: 'https://support.flux3dp.com/hc/en-us/articles/360001568035',
        },
    },
    beambox: {
        tag: {
            g: 'Kumpulan',
            use: 'Import SVG',
            image: 'Imej',
            text: 'Teks',
        },
        context_menu: {
            cut: 'Potong',
            copy: 'Salin',
            paste: 'Tampal',
            paste_in_place: 'Tampal di Tempat',
            duplicate: 'Duplikat',
            delete: 'Padam',
            group: 'Kumpulan',
            ungroup: 'Nyahkumpul',
            move_front: 'Bawa ke Hadapan',
            move_up: 'Bawa ke Depan',
            move_down: 'Hantar ke Belakang',
            move_back: 'Hantar ke Belakang',
        },
        popup: {
            select_import_method: 'Pilih gaya pelapisan:',
            select_import_module: 'Pilih Modul:',
            touchpad: 'Pad Sentuh',
            mouse: 'Tetikus',
            layer_by_layer: 'Lapisan',
            layer_by_color: 'Warna',
            nolayer: 'Lapisan Tunggal',
            loading_image: 'Memuatkan imej, sila tunggu...',
            no_support_text: 'Beam Studio tidak menyokong tag teks pada masa ini. Sila tukar teks kepada laluan sebelum import.',
            speed_too_high_lower_the_quality: 'Menggunakan kelajuan terlalu tinggi pada resolusi ini boleh mengakibatkan kualiti ukiran bayangan yang lebih rendah.',
            both_power_and_speed_too_high: 'Menggunakan kuasa laser yang lebih rendah akan melanjutkan jangka hayat tiub laser. Juga, kelajuan yang terlalu tinggi pada resolusi ini boleh mengakibatkan kualiti ukiran bayangan yang lebih rendah.',
            too_fast_for_path: 'Menggunakan kelajuan yang terlalu tinggi dalam lapisan yang mengandungi objek laluan boleh mengakibatkan ketepatan yang lebih rendah semasa memotong. Kami tidak mengesyorkan menggunakan kelajuan lebih pantas daripada 20 mm/s semasa memotong.',
            too_fast_for_path_and_constrain: 'Lapisan berikut: %s mengandungi objek laluan vektor, dan mempunyai kelajuan melebihi 20 mm/s. Kelajuan pemotongan objek laluan vektor akan dihadkan kepada 20 mm/s. Anda boleh mengalih keluar had ini di Tetapan Keutamaan.',
            should_update_firmware_to_continue: '#814 Perisian tegar anda tidak menyokong versi Beam Studio ini. Sila kemas kini perisian tegar untuk meneruskan. (Menu > Mesin > [Mesin Anda] > Kemas kini Perisian Tegar)',
            recommend_downgrade_software: 'Kami mengesan versi perisian tegar yang lebih lama. Kami sedang menangani isu-isu keserasian, tetapi buat masa ini, kami mengesyorkan kembali ke <a target="_blank" href="https://beamstudio.s3.ap-northeast-1.amazonaws.com/mac/Beam+Studio+1.9.5.dmg">Beam Studio 1.9.5</a>.',
            recommend_upgrade_firmware: 'Kami mengesan versi firmware yang lebih lama. Kami sedang menangani isu-isu keserasian, tetapi buat masa ini, kami mengesyorkan mengemas kini ke firmware terkini.',
            still_continue: 'Teruskan',
            more_than_two_object: 'Terlalu banyak objek. Hanya menyokong 2 objek',
            not_support_object_type: 'Tidak menyokong jenis objek',
            select_first: 'Pilih objek pertama.',
            select_at_least_two: 'Pilih dua objek untuk teruskan',
            import_file_contain_invalid_path: '#808 Fail SVG yang diimport mengandungi laluan imej yang tidak sah. Sila pastikan semua fail imej wujud atau benam imej dalam fail',
            import_file_error_ask_for_upload: 'Gagal untuk Imported SVG file. Adakah anda bersedia untuk menyediakan fail kepada pasukan pembangunan untuk laporan pepijat ?',
            upload_file_too_large: '#819 Fail terlalu besar untuk dimuat naik.',
            successfully_uploaded: 'Muat naik fail berjaya.',
            upload_failed: '#819 Gagal memuat naik fail.',
            or_turn_off_borderless_mode: ' Atau matikan mod Buka Bawah.',
            svg_1_1_waring: 'Versi fail SVG ini ialah v 1.1, mungkin terdapat masalah ketidakserasian.',
            svg_image_path_waring: 'Fail SVG ini mengandungi <image> memuat dari laluan fail. Ini mungkin menyebabkan kegagalan semasa memuat.\nUntuk mengelakkan risiko ini, Sila baik guna imej terbenam semasa mengeksport SVG.',
            dxf_version_waring: 'Versi fail DXF ini bukan 2013, mungkin terdapat masalah ketidakserasian.',
            dont_show_again: 'Jangan Tunjuk ini pada masa akan datang.',
            convert_to_path_fail: 'Gagal menukar ke laluan.',
            save_unsave_changed: 'Adakah anda mahu menyimpan perubahan yang belum disimpan?',
            dxf_bounding_box_size_over: 'Saiz lukisan melebihi kawasan kerja. Sila pindahkan lukisan anda lebih dekat kepada asal dalam perisian CAD anda, atau pastikan unit ditetapkan dengan betul.',
            progress: {
                uploading: 'Memuat naik',
                calculating: 'Mengira',
            },
            backend_connect_failed_ask_to_upload: '#802 Ralat berterusan berlaku semasa cuba menyambung ke backend. Adakah anda mahu memuat naik laporan pepijat log anda?',
            backend_error_hint: 'Ciri-ciri mungkin tidak berfungsi dengan betul disebabkan ralat backend.',
            pdf2svg: {
                error_when_converting_pdf: '#824 Ralat semasa menukar PDF ke SVG:',
                error_pdf2svg_not_found: '#825 Ralat: Arahan pdf2svg tidak dijumpai. Sila pasang pdf2svg dengan pengurus pakej anda (cth., "yum install pdf2svg" atau "apt-get install pdf2svg").',
            },
            ungroup_use: 'Ini akan menyahkumpulkan DXF atau SVG yang diimport. Oleh kerana fail itu mungkin mengandungi sejumlah besar elemen, ia mungkin mengambil masa untuk menyahkumpulkan. Adakah anda pasti untuk meneruskan?',
            vectorize_shading_image: 'Imej gradien mengambil lebih masa untuk mengesan dan cenderung kepada hingar. Sila matikan gradien imej sebelum melaksanakan.',
            change_workarea_before_preview: 'Kawasan kerja %s tidak sepadan dengan kawasan kerja yang ditetapkan sekarang. Adakah anda mahu menukar kawasan kerja semasa?',
            bug_report: 'Laporan Pepijat',
            sentry: {
                title: 'Mari kita baikkan Beam Studio bersama-sama',
                message: 'Adakah anda bersetuju untuk memuat naik maklumat yang berkaitan secara automatik kepada pasukan pembangunan apabila ralat berlaku?',
            },
            questionnaire: {
                caption: 'Bantu Kami Isi Soal Selidik',
                message: 'Bantu kami mengisi soal selidik untuk menjadikan Beam Studio lebih baik.',
                unable_to_get_url: 'Gagal mendapatkan pautan ke soal selidik melalui internet. Sila sahkan sambungan internet anda.',
                no_questionnaire_available: 'Tiada soal selidik yang tersedia buat masa ini.',
            },
            facebook_group_invitation: {
                title: 'Sertai Kumpulan Pengguna Rasmi FLUX',
                message: 'Sertai kumpulan Facebook rasmi kami untuk berhubung dengan pengguna laser FLUX yang lain, berkongsi karya laser, dan sentiasa dikemas kini dengan maklumat terkini mengenai produk kami. Kami tidak sabar untuk berjumpa dengan anda di sana!',
                join_now: 'Sertai Sekarang',
                later: 'Mungkin Nanti',
                already_joined: 'Sudah Sertai',
                dont_show_again: 'Jangan tunjuk ini lagi',
            },
            ai_credit: {
                relogin_to_use: 'Sila log masuk semula untuk guna fungsi ini.',
                insufficient_credit: 'Anda kehabisan Kredit',
                insufficient_credit_msg: 'Anda tidak boleh guna %s. Pergi ke pusat ahli dan beli Kredit AI.',
                buy_link: 'https://member.flux3dp.com/en-us/credit',
                go: 'Pergi',
            },
            text_to_path: {
                caption: 'Penukar Teks ke Laluan 2.0',
                message: "Beam Studio kini memperkenalkan penukar teks ke laluan yang baru (Penukar 2.0), yang menghasilkan keputusan yang lebih boleh dipercayai! Adakah anda mahu beralih kepadanya sekarang? \nAnda juga boleh menyesuaikan tetapan ini kemudian dalam bahagian 'Penukar Teks ke Laluan' dalam keutamaan.",
            },
            auto_switch_tab: {
                title: 'Tukar Automatik Panel Lapisan dan Objek',
                message: 'Pilihan baru untuk menukar secara automatik antara panel Lapisan dan panel Objek telah ditambah dalam Keutamaan. Pilihan ini dilumpuhkan secara lalai. Adakah anda ingin mengaktifkan penukaran automatik sekarang? <br/>Anda boleh menukar tetapan ini pada bila-bila masa dalam Keutamaan.',
            },
        },
        zoom_block: {
            fit_to_window: 'Muat ke Tetingkap',
        },
        time_est_button: {
            calculate: 'Anggarkan masa',
            estimate_time: 'Anggaran Masa:',
        },
        left_panel: {
            unpreviewable_area: 'Kawasan Buta',
            diode_blind_area: 'Kawasan Buta Tambahan Laser Hibrid',
            borderless_blind_area: 'Kawasan Tidak Ukir',
            borderless_preview: 'Pratonton Mod Kamera Bawah Terbuka',
            rectangle: 'Segi empat tepat',
            ellipse: 'Elips',
            line: 'Garis',
            image: 'Imej',
            text: 'Teks',
            label: {
                cursor: 'Pilih',
                photo: 'Imej',
                text: 'Teks',
                line: 'Garis',
                rect: 'Segi empat tepat',
                oval: 'Bujur',
                polygon: 'Poligon',
                pen: 'Pen',
                shapes: 'Elemen',
                array: 'Susunan',
                preview: 'Pratonton Kamera',
                trace: 'Imej Rentas',
                end_preview: 'Tamat Pratonton',
                clear_preview: 'Kosongkan Pratonton',
                choose_camera: 'Kamera',
                live_feed: 'Suapan Langsung',
                adjust_height: 'Laras Ketinggian',
                qr_code: 'Kod QR',
                boxgen: 'Boxgen',
                my_cloud: 'Awan Saya',
                pass_through: 'Mod Lalu',
                curve_engraving: {
                    title: 'Lengkung 3D',
                    exit: 'Keluar',
                    select_area: 'Pilih kawasan',
                    preview_3d_curve: 'Pratonton Lengkung 3D',
                    clear_area: 'Kosongkan Kawasan Terpilih',
                },
            },
        },
        right_panel: {
            tabs: {
                layers: 'Lapisan',
                objects: 'Objek',
                path_edit: 'Sunting Laluan',
            },
            layer_panel: {
                layer1: 'Lapisan 1',
                layer_bitmap: 'Bitmap',
                layer_engraving: 'Ukiran',
                layer_cutting: 'Pemotongan',
                current_layer: 'Lapisan Semasa',
                move_elems_to: 'Alihkan elemen ke:',
                notification: {
                    dupeLayerName: 'Sudah ada lapisan dengan nama itu!',
                    newName: 'NAMA BARU',
                    enterUniqueLayerName: 'Sila masukkan nama lapisan unik',
                    enterNewLayerName: 'Sila masukkan nama lapisan baru',
                    layerHasThatName: 'Lapisan sudah ada nama itu',
                    QmoveElemsToLayer: "Alihkan elemen terpilih ke lapisan '%s'?",
                    moveElemFromPrintingLayerTitle: 'Alih elemen terpilih ke %s dan tukar ke elemen laser?',
                    moveElemFromPrintingLayerMsg: 'Sila ambil perhatian bahawa jika anda lengkapkan operasi ini, tetapan warna elemen terpilih akan dibuang dan ditetapkan mengikut %s.',
                    moveElemToPrintingLayerTitle: 'Alih elemen terpilih ke %s dan tukar ia ke elemen percetakan?',
                    moveElemToPrintingLayerMsg: 'Sila ambil perhatian bahawa jika anda lengkapkan operasi ini, tetapan elemen terpilih akan dibuang dan ditetapkan mengikut %s.',
                    splitColorTitle: 'Adakah anda mahu mengembangkan lapisan terpilih ke lapisan CMYK?',
                    splitColorMsg: 'Sila ambil perhatian bahawa jika anda teruskan dengan operasi ini, anda tidak akan dapat kembali ke lapisan warna asal.',
                    mergeLaserLayerToPrintingLayerTitle: 'Adakah anda mahu menggabungkan lapisan ini ke satu Lapisan Percetakan?',
                    mergeLaserLayerToPrintingLayerMsg: 'Sila ambil perhatian bahawa jika anda lengkapkan operasi ini, tetapan lapisan laser akan dibuang dan ditetapkan mengikut lapisan semasa.',
                    mergePrintingLayerToLaserLayerTitle: 'Adakah anda mahu menggabungkan lapisan ini ke satu Lapisan Laser?',
                    mergePrintingLayerToLaserLayerMsg: 'Sila ambil perhatian bahawa jika anda lengkapkan operasi ini, tetapan warna lapisan percetakan akan dibuang dan ditetapkan mengikut lapisan semasa.',
                },
                layers: {
                    layer: 'Lapisan',
                    layers: 'Lapisan',
                    del: 'Padam Lapisan',
                    move_down: 'Turun Lapisan',
                    new: 'Lapisan Baru',
                    rename: 'Nama Semula Lapisan',
                    move_up: 'Naik Lapisan',
                    dupe: 'Gandakan Lapisan',
                    lock: 'Kunci Lapisan',
                    unlock: 'Buka Kunci',
                    merge_down: 'Gabung Bawah',
                    merge_all: 'Gabung Semua',
                    merge_selected: 'Gabung Lapisan Terpilih',
                    move_elems_to: 'Alih elemen ke:',
                    move_selected: 'Alih elemen terpilih ke lapisan lain',
                    switchToFullColor: 'Tukar ke lapisan warna penuh',
                    switchToSingleColor: 'Tukar ke lapisan warna tunggal',
                    splitFullColor: 'Kembangkan Lapisan Warna',
                    fullColor: 'Warna penuh',
                },
            },
            laser_panel: {
                preset_setting: 'Tetapan Parameter (%s)',
                multi_layer: 'Lapisan Pelbagai',
                parameters: 'Parameter',
                strength: 'Kuasa',
                pwm_advanced_setting: 'Tetapan Kuasa Mod Kedalaman',
                pwm_advanced_desc: 'Tetapkan kuasa minimum untuk mod kedalaman.',
                pwm_advanced_hint: 'Tetapan ini berlaku khususnya untuk imej kecerunan apabila menggunakan mod kedalaman.',
                low_power_warning: 'Kuasa laser yang rendah (bawah 10%) mungkin tidak memancarkan cahaya laser.',
                speed: 'Kelajuan',
                speed_contrain_warning: 'Kelajuan pemotongan objek laluan vektor akan dihadkan kepada 20 mm/s. Anda boleh mengalih had ini di Tetapan Keutamaan.',
                low_speed_warning: 'Kelajuan rendah mungkin menyebabkan bahan terbakar.',
                repeat: 'Kiraan Lalu',
                advanced: 'Lanjutan',
                lower_focus: 'Turunkan Fokus',
                by: 'Dengan',
                stepwise_focusing: 'Pemfokusan Berperingkat',
                single_color: 'Warna Tunggal',
                lower_focus_desc: 'Menurunkan ketinggian fokus dengan jarak tertentu selepas memfokus untuk meningkatkan prestasi pemotongan.',
                stepwise_focusing_desc: 'Menurunkan jarak fokus secara beransur-ansur berdasarkan ketinggian objek semasa setiap kiraan lalu.',
                single_color_desc: 'Hanya boleh digunakan untuk lapisan warna penuh dan tidak boleh digunakan dengan lapisan warna tunggal yang diperluas.',
                focus_adjustment: 'Pelarasan Fokus',
                height: 'Ketinggian Objek',
                z_step: 'Langkah Z',
                diode: 'Laser Diod',
                backlash: 'Ricih',
                ink_saturation: 'Ketepuan',
                print_multipass: 'Multi-laluan',
                white_ink: 'Dakwat Putih',
                white_ink_settings: 'Tetapan Dakwat Putih',
                color_adjustment: 'Pelarasan Saluran',
                color_adjustment_short: 'Saluran',
                halftone: 'Setengah nada',
                halftone_link: 'https://support.flux3dp.com/hc/en-us/articles/9402670389647',
                color_strength: 'Kekuatan',
                times: 'kali',
                cut: 'Potong',
                engrave: 'Ukir',
                more: 'Urus',
                apply: 'Guna',
                cancel: 'Batal',
                save: 'Simpan',
                custom_preset: 'Suai',
                various_preset: 'Pelbagai Praset',
                module: 'Modul',
                slider: {
                    regular: 'Biasa',
                    low: 'Rendah',
                    very_low: 'Minimum',
                    high: 'Tinggi',
                    very_high: 'Maksimum',
                    slow: 'Perlahan',
                    very_slow: 'Paling Perlahan',
                    fast: 'Pantas',
                    very_fast: 'Paling Pantas',
                },
                dropdown: {
                    parameters: 'Praset',
                    save: 'Tambah parameter semasa',
                    mm: {
                        wood_3mm_cutting: 'Kayu - Pemotongan 3mm',
                        wood_5mm_cutting: 'Kayu - Pemotongan 5mm',
                        wood_7mm_cutting: 'Kayu - Pemotongan 7mm',
                        wood_8mm_cutting: 'Kayu - Pemotongan 8mm',
                        wood_10mm_cutting: 'Kayu - Pemotongan 10mm',
                        wood_engraving: 'Kayu - Ukiran',
                        acrylic_3mm_cutting: 'Akrilik - Pemotongan 3mm',
                        acrylic_5mm_cutting: 'Akrilik - Pemotongan 5mm',
                        acrylic_8mm_cutting: 'Akrilik - Pemotongan 8mm',
                        acrylic_10mm_cutting: 'Akrilik - Pemotongan 10mm',
                        acrylic_engraving: 'Akrilik - Ukiran',
                        mdf_3mm_cutting: 'MDF New Zealand - 3mm Pemotongan',
                        mdf_5mm_cutting: 'MDF New Zealand - 5mm Pemotongan',
                        mdf_engraving: 'MDF New Zealand - Ukiran',
                        leather_3mm_cutting: 'Kulit - Pemotongan 3mm',
                        leather_5mm_cutting: 'Kulit - Pemotongan 5mm',
                        leather_engraving: 'Kulit - Ukiran',
                        denim_1mm_cutting: 'Denim - 1mm Pemotongan',
                        fabric_3mm_cutting: 'Fabrik - Pemotongan 3mm',
                        fabric_5mm_cutting: 'Fabrik - Pemotongan 5mm',
                        fabric_engraving: 'Kain - Ukiran',
                        rubber_bw_engraving: 'Getah - Ukiran',
                        glass_bw_engraving: 'Kaca - Ukiran',
                        metal_bw_engraving: 'Logam - Ukiran',
                        steel_engraving_spray_engraving: 'Logam - Ukiran',
                        stainless_steel_bw_engraving_diode: 'Logam - Ukiran (Laser Diod)',
                        gold_engraving: 'Emas - Ukiran',
                        brass_engraving: 'Gangsa - Ukiran',
                        ti_engraving: 'Titanium - Ukiran',
                        stainless_steel_engraving: 'Keluli Tahan Karat - Ukiran',
                        aluminum_engraving: 'Aluminium - Ukiran',
                        black_acrylic_3mm_cutting: 'Akrilik Hitam - 3mm Pemotongan',
                        black_acrylic_5mm_cutting: 'Akrilik Hitam - 5mm Pemotongan',
                        black_acrylic_engraving: 'Akrilik Hitam - Ukiran',
                        abs_engraving: 'ABS - Ukiran',
                        silver_engraving: 'Perak - Ukiran',
                        iron_engraving: 'Besi - Ukiran',
                        fabric_printing: 'Fabrik - Pencetakan',
                        canvas_printing: 'Kanvas - Pencetakan',
                        cardstock_printing: 'Kad Tebal - Pencetakan',
                        wood_printing: 'Kayu - Pencetakan',
                        bamboo_printing: 'Buluh - Pencetakan',
                        cork_printing: 'Gabus - Cetak',
                        flat_stone_printing: 'Batu Rata - Cetak',
                        acrylic_printing: 'Akrilik - Cetak',
                        pc_printing: 'PC - Cetak',
                        stainless_steel_printing: 'Keluli Tahan Karat - Cetak',
                        gloss_leather_printing: 'Kulit Berkilat - Cetak',
                        glass_printing: 'Kaca - Cetak',
                    },
                    inches: {
                        wood_3mm_cutting: 'Kayu - 0.1 inci Pemotongan',
                        wood_5mm_cutting: 'Kayu - 0.2 inci Pemotongan',
                        wood_7mm_cutting: 'Kayu - 0.3" Potong',
                        wood_8mm_cutting: 'Kayu - 0.3 inci Pemotongan',
                        wood_10mm_cutting: 'Kayu - 0.4 inci Pemotongan',
                        wood_engraving: 'Kayu - Ukiran',
                        acrylic_3mm_cutting: 'Akrilik - 0.1 inci Pemotongan',
                        acrylic_5mm_cutting: 'Akrilik - 0.2 inci Pemotongan',
                        acrylic_8mm_cutting: 'Akrilik - 0.3 inci Pemotongan',
                        acrylic_10mm_cutting: 'Akrilik - 0.4 inci Pemotongan',
                        acrylic_engraving: 'Akrilik - Ukiran',
                        mdf_3mm_cutting: 'MDF New Zealand - 0.1" Potong',
                        mdf_5mm_cutting: 'MDF New Zealand - 0.2" Potong',
                        mdf_engraving: 'MDF New Zealand - Ukir',
                        leather_3mm_cutting: 'Kulit - 0.1 inci Pemotongan',
                        leather_5mm_cutting: 'Kulit - 0.2 inci Pemotongan',
                        leather_engraving: 'Kulit - Ukiran',
                        denim_1mm_cutting: 'Denim - 0.04" Potong',
                        fabric_3mm_cutting: 'Kain - 0.1" Pemotongan',
                        fabric_5mm_cutting: 'Kain - 0.2" Pemotongan',
                        fabric_engraving: 'Kain - Ukiran',
                        rubber_bw_engraving: 'Getah - Ukiran',
                        glass_bw_engraving: 'Kaca - Ukiran',
                        metal_bw_engraving: 'Logam - Ukiran',
                        steel_engraving_spray_engraving: 'Logam - Ukiran',
                        stainless_steel_bw_engraving_diode: 'Logam - Ukiran (Laser Diod)',
                        gold_engraving: 'Emas - Ukir',
                        brass_engraving: 'Gangsa - Ukir',
                        ti_engraving: 'Titanium - Ukir',
                        stainless_steel_engraving: 'Keluli Tahan Karat - Ukir',
                        aluminum_engraving: 'Aluminium - Ukir',
                        black_acrylic_3mm_cutting: 'Akrilik Hitam - 0.1" Potong',
                        black_acrylic_5mm_cutting: 'Akrilik Hitam - 0.2" Potong',
                        black_acrylic_engraving: 'Akrilik Hitam - Ukir',
                        abs_engraving: 'ABS - Ukiran',
                        silver_engraving: 'Perak - Ukiran',
                        iron_engraving: 'Besi - Ukiran',
                        fabric_printing: 'Fabrik - Cetakan',
                        canvas_printing: 'Kanvas - Cetakan',
                        cardstock_printing: 'Kad Stok - Cetakan',
                        wood_printing: 'Kayu - Cetakan',
                        bamboo_printing: 'Buluh - Cetakan',
                        cork_printing: 'Gabus - Cetakan',
                        flat_stone_printing: 'Batu Rata - Cetakan',
                        acrylic_printing: 'Akrilik - Cetakan',
                        pc_printing: 'PC - Cetakan',
                        stainless_steel_printing: 'Keluli Tahan Karat - Cetakan',
                        gloss_leather_printing: 'Kulit Berkilat - Cetakan',
                        glass_printing: 'Kaca - Cetakan',
                    },
                },
                laser_speed: {
                    text: 'Kelajuan',
                    unit: 'mm/s',
                    fast: 'Pantas',
                    slow: 'Perlahan',
                    min: 3,
                    max: 300,
                    step: 0.1,
                },
                power: {
                    text: 'Kuasa',
                    high: 'Tinggi',
                    low: 'Rendah',
                    min: 1,
                    max: 100,
                    step: 0.1,
                },
                ink_type: {
                    text: 'Jenis Dakwat',
                    normal: 'Dakwat',
                    UV: 'Dakwat UV',
                },
                para_in_use: 'Parameter ini sedang digunakan.',
                do_not_adjust_default_para: 'Praset tidak boleh diubah.',
                existing_name: 'Nama ini telah digunakan.',
                presets: 'Praset',
                preset_management: {
                    preset: 'Pratetap',
                    title: 'Urus Parameter',
                    add_new: 'Tambah Baru',
                    lower_focus_by: 'Turunkan Fokus oleh',
                    save_and_exit: 'Simpan dan Keluar',
                    delete: 'Padam',
                    reset: 'Tetap Semula',
                    sure_to_reset: 'Ini akan memadamkan parameter tersuai anda dan menetapkan semula semua pratetap, adakah anda pasti untuk meneruskan?',
                    show_all: 'Tunjukkan Semua',
                    laser: 'Laser',
                    print: 'Cetak',
                    export: 'Eksport',
                    export_preset_title: 'Eksport Praset',
                    import: 'Import',
                    sure_to_import_presets: 'Ini akan memuatkan susunan pratetap dan menimpa parameter yang telah disesuaikan, adakah anda pasti untuk meneruskan?',
                    new_preset_name: 'Nama Praset Baharu',
                },
            },
            object_panel: {
                zoom: 'Zum',
                group: 'Kumpulkan',
                ungroup: 'Nyahgabung',
                distribute: 'Agihkan',
                hdist: 'Agihkan Mendatar',
                vdist: 'Agihkan Menegak',
                align: 'Jajarkan',
                left_align: 'Jajar Kiri',
                center_align: 'Jajar Tengah',
                right_align: 'Jajar Kanan',
                top_align: 'Jajar Atas',
                middle_align: 'Jajar Tengah',
                bottom_align: 'Jajar Bawah',
                boolean: 'Boolean',
                union: 'Gabung',
                subtract: 'Tolak',
                intersect: 'Iris',
                difference: 'Beza',
                flip: 'Terbalik',
                hflip: 'Terbalik Mendatar',
                vflip: 'Terbalik Menegak',
                lock_aspect: 'Kunci Nisbah',
                unlock_aspect: 'Buka Kunci Nisbah',
                option_panel: {
                    fill: 'Isi',
                    rounded_corner: 'Sudut Bulat',
                    sides: 'Sisi',
                    font_family: 'Font',
                    font_style: 'Gaya',
                    font_size: 'Saiz',
                    letter_spacing: 'Jarak huruf',
                    line_spacing: 'Jarak baris',
                    vertical_text: 'Teks menegak',
                    start_offset: 'Ofset teks',
                    vertical_align: 'Penjajaran',
                    text_infill: 'Isi teks',
                    path_infill: 'Isi laluan',
                    shading: 'Cerun',
                    pwm_engraving: 'Mod Kedalaman',
                    pwm_engraving_link: 'https://support.flux3dp.com/hc/en-us/articles/10419884701327',
                    threshold: 'Ambang kecerahan',
                    threshold_short: 'Ambang',
                    stroke: 'Lukisan',
                    stroke_color: 'Warna Lukisan',
                    stroke_width: 'Lebar Garisan',
                    color: 'Warna',
                },
                actions_panel: {
                    replace_with: 'Ganti Dengan...',
                    replace_with_short: 'Ganti',
                    trace: 'Jejak',
                    grading: 'Pemeringkatan',
                    brightness: 'Kecerahan',
                    sharpen: 'Tajamkan',
                    crop: 'Potong',
                    bevel: 'Bevel',
                    invert: 'Songsang',
                    weld_text: 'Kimpal Teks',
                    convert_to_path: 'Tukar ke Laluan',
                    fetching_web_font: 'Mendapatkan fon dalam talian...',
                    uploading_font_to_machine: 'Memuat naik fon ke mesin...',
                    wait_for_parsing_font: 'Mohon tunggu sebentar',
                    offset: 'Ofset',
                    array: 'Susunan',
                    auto_fit: 'Muat Auto',
                    smart_nest: 'Sarang Pintar',
                    decompose_path: 'Hurai',
                    disassemble_use: 'Hurai',
                    create_textpath: 'Cipta Teks pada Laluan',
                    create_textpath_short: 'Teks Laluan',
                    detach_path: 'Lepaskan Teks dari Laluan',
                    detach_path_short: 'Lepaskan',
                    edit_path: 'Edit Laluan',
                    disassembling: 'Menghurai...',
                    ungrouping: 'Menyahkumpul...',
                    simplify: 'Ringkas',
                    ai_bg_removal: 'Buang Latar Belakang',
                    ai_bg_removal_short: 'Buang LB',
                    ai_bg_removal_reminder: 'Menekan butang akan menggunakan 0.2 Kredit dengan serta-merta, anda mahu meneruskan?',
                    outline: 'Garis luar',
                },
                path_edit_panel: {
                    node_type: 'JENIS NOD',
                    sharp: 'Tajam',
                    round: 'Bulat',
                    connect: 'Sambung',
                    disconnect: 'Putuskan',
                    delete: 'Padam',
                },
            },
        },
        bottom_right_panel: {
            convert_text_to_path_before_export: 'Tukar Teks ke Laluan...',
            retreive_image_data: 'Dapatkan Data Imej...',
            export_file_error_ask_for_upload: 'Gagal eksport tugas. Adakah anda bersedia untuk memberikan adegan kerja kepada pasukan pembangunan untuk laporan pepijat?',
        },
        image_trace_panel: {
            apply: 'Terap',
            back: 'Kembali',
            cancel: 'Batal',
            next: 'Seterusnya',
            brightness: 'Kecerahan',
            contrast: 'Kontras',
            threshold: 'Ambang',
            okay: 'OK',
            tuning: 'Parameter',
        },
        photo_edit_panel: {
            apply: 'Terap',
            back: 'Kembali',
            cancel: 'Batal',
            next: 'Seterusnya',
            sharpen: 'Tajamkan',
            sharpness: 'Ketajaman',
            radius: 'Jejari',
            crop: 'Pangkas',
            aspect_ratio: 'Nisbah Aspek',
            original: 'Asal',
            free: 'Bebas',
            curve: 'Lengkung',
            start: 'Mula',
            processing: 'Pemprosesan',
            invert: 'Songsang Warna',
            okay: 'Okay',
            compare: 'Banding',
            phote_edit: 'Sunting Foto',
            brightness_and_contrast: 'Kecerahan / Kontras',
            brightness: 'Kecerahan',
            contrast: 'Kontras',
            rotary_warped: 'Berputar Terherot',
            rotary_warped_link: 'https://support.flux3dp.com/hc/en-us/articles/10828006201103',
            diameter: 'Diameter',
            circumference: 'Lilitan',
            warp: 'Herot',
        },
        document_panel: {
            document_settings: 'Tetapan Dokumen',
            machine: 'Mesin',
            watts: 'Watt',
            workarea: 'Kawasan Kerja',
            rotary_mode: 'Rotari',
            borderless_mode: 'Buka Bawah',
            engrave_dpi: 'Resolusi',
            enable_diode: 'Laser Diod',
            enable_autofocus: 'Autofokus',
            extend_workarea: 'Perluaskan kawasan kerja',
            mirror: 'Cermin',
            pass_through: 'Lalu',
            pass_through_height_desc: 'Masukkan panjang objek untuk melanjutkan kawasan kerja.',
            start_position: 'Kedudukan Mula',
            start_from: 'Mula Dari',
            origin: 'Asal',
            current_position: 'Kedudukan Semasa',
            job_origin: 'Asal Tugas',
            add_on: 'Tambahan',
            low: 'Rendah',
            medium: 'Sederhana',
            high: 'Tinggi',
            ultra: 'Ultra Tinggi',
            enable: 'Benarkan',
            disable: 'Lumpuhkan',
            cancel: 'Batal',
            save: 'Simpan',
            notification: {
                changeFromPrintingWorkareaTitle: 'Adakah anda mahu menukar Lapisan Cetakan kepada Lapisan Laser?',
            },
        },
        object_panels: {
            wait_for_parsing_font: 'Menghurai font... Sila tunggu sebentar',
            text_to_path: {
                font_substitute_pop: 'Teks anda mengandungi aksara yang tidak disokong oleh fon semasa. <br/>Adakah anda mahu menggunakan <strong>%s</strong> sebagai pengganti?',
                check_thumbnail_warning: 'Sesetengah teks telah ditukar kepada font lain semasa menukar teks kepada laluan dan sesetengah aksara mungkin tidak ditukar dengan betul.\nSila semak pratonton imej sekali lagi sebelum menghantar tugasan.',
                error_when_parsing_text: 'Ralat semasa menukar teks kepada laluan',
                use_current_font: 'Guna Font Semasa',
                retry: 'Sila cuba semula kemudian atau pilih fon lain',
            },
            lock_desc: 'Pelihara nisbah lebar dan tinggi (SHIFT)',
        },
        tool_panels: {
            cancel: 'Batal',
            confirm: 'Sahkan',
            grid_array: 'Cipta Susunan Grid',
            array_dimension: 'Dimensi Susunan',
            rows: 'Baris',
            columns: 'Lajur',
            array_interval: 'Sela Susunan',
            dx: 'X',
            dy: 'Y',
            offset: 'Ofset',
            nest: 'Pengoptimuman Susun Atur',
            _offset: {
                direction: 'Arah Ofset',
                inward: 'Ke Dalam',
                outward: 'Ke Luar',
                dist: 'Jarak Ofset',
                corner_type: 'Sudut',
                sharp: 'Tajam',
                round: 'Bulat',
                fail_message: 'Gagal untuk Mengoset Objek.',
                not_support_message: 'Elemen terpilih mengandungi tag SVG yang tidak disokong:\nImej, Kumpulan, Teks dan Objek Diimport.',
            },
            _nest: {
                start_nest: 'Susun',
                stop_nest: 'Berhenti',
                end: 'Tutup',
                spacing: 'Jarak',
                rotations: 'Kemungkinan Putaran',
                no_element: 'Tiada elemen untuk disusun.',
            },
        },
        network_testing_panel: {
            network_testing: 'Ujian Rangkaian',
            local_ip: 'Alamat IP tempatan:',
            insert_ip: 'Alamat IP peranti sasaran:',
            empty_ip: '#818 Sila masukkan alamat IP peranti sasaran terlebih dahulu.',
            start: 'Mula',
            end: 'Tamat',
            testing: 'Menguji Rangkaian...',
            invalid_ip: '#818 Alamat IP tidak sah',
            ip_startswith_169: '#843 Alamat IP mesin bermula dengan 169.254',
            connection_quality: 'Kualiti Sambungan',
            average_response: 'Purata Masa Tindak Balas',
            test_completed: 'Ujian Selesai',
            test_fail: 'Ujian Gagal',
            cannot_connect_1: '#840 Gagal menyambung ke IP sasaran.',
            cannot_connect_2: '#840 Gagal menyambung ke IP sasaran. Sila pastikan sasaran berada dalam rangkaian yang sama.',
            network_unhealthy: '#841 Kualiti sambungan <70 atau purata masa gerak balas > 100ms',
            device_not_on_list: '#842 Mesin tidak berada dalam senarai, tetapi Kualiti sambungan> 70 dan purata masa gerak balas <100ms',
            hint_device_often_on_list: 'Mesin kerap tidak ditemui dalam senarai?',
            link_device_often_on_list: 'https://support.flux3dp.com/hc/en-us/articles/360001841636',
            hint_connect_failed_when_sending_job: 'Gagal menyambung ketika menghantar tugasan?',
            link_connect_failed_when_sending_job: 'https://support.flux3dp.com/hc/en-us/articles/360001841656',
            hint_connect_camera_timeout: 'Timeout berlaku ketika memulakan pratonton kamera?',
            link_connect_camera_timeout: 'https://support.flux3dp.com/hc/en-us/articles/360001791895',
            cannot_get_local: 'Akses ke alamat IP tempatan gagal.',
            fail_to_start_network_test: '#817 Gagal memulakan ujian rangkaian.',
            linux_permission_hint: 'Ralat ini biasanya berlaku disebabkan kekurangan kebenaran.\nSila jalankan "sudo beam-studio --no-sandbox" dalam terminal untuk mendapatkan kebenaran dan melakukan ujian rangkaian.',
        },
        layer_color_config_panel: {
            layer_color_config: 'Konfigurasi Warna Lapisan',
            color: 'Warna',
            power: 'Kuasa',
            speed: 'Kelajuan',
            repeat: 'Ulang',
            add: 'Tambah',
            save: 'Simpan',
            cancel: 'Batal',
            default: 'Tetapan Semula ke Lalai',
            add_config: 'Tambah Warna',
            in_use: 'Warna ini sedang digunakan.',
            no_input: 'Sila masukkan kod warna heks yang sah.',
            sure_to_reset: 'Anda akan kehilangan semua parameter tersuai, adakah anda pasti untuk menetapkan semula kepada lalai?',
            sure_to_delete: 'Adakah anda pasti untuk memadam tetapan warna ini?',
        },
        rating_panel: {
            title: 'Suka Beam Studio?',
            description: 'Jika anda suka Beam Studio, kami amat menghargai jika anda boleh menilai kami.',
            dont_show_again: 'Jangan Tunjukkan ini pada masa akan datang.',
            thank_you: 'Terima kasih!',
        },
        svg_editor: {
            unnsupported_file_type: 'Jenis fail ini tidak disokong secara langsung. Sila tukar fail ke SVG atau bitmap',
            unable_to_fetch_clipboard_img: 'Gagal mengambil imej dari papan keratan anda',
        },
        units: {
            walt: 'W',
            mm: 'mm',
        },
        path_preview: {
            play: 'Main',
            pause: 'Jeda',
            stop: 'Berhenti',
            play_speed: 'Kelajuan Main Balik',
            travel_path: 'Laluan Perjalanan',
            invert: 'Songsang',
            preview_info: 'Maklumat Pratonton',
            size: 'Saiz',
            estimated_time: 'Anggaran Masa Keseluruhan',
            cut_time: 'Masa Pemotongan',
            rapid_time: 'Masa Perjalanan',
            cut_distance: 'Jarak Pemotongan',
            rapid_distance: 'Jarak Perjalanan',
            current_position: 'Kedudukan Semasa',
            remark: '* Semua maklumat adalah anggaran nilai untuk rujukan.',
            start_here: 'Mula Di Sini',
            end_preview: 'Tamat Pratonton',
        },
        shapes_panel: {
            title: 'Elemen',
            basic: 'Asas',
            shape: 'Bentuk',
            graphics: 'Grafik',
            arrow: 'Anak Panah',
            label: 'Label',
            decor: 'Hiasan',
            circular: 'Bulat',
            corner: 'Sudut',
            line: 'Garisan',
            photo: 'Foto',
            ribbon: 'Pita',
            speech: 'Ucapan',
            text: 'Teks',
            animals: 'Haiwan',
            birds: 'Burung',
            land: 'Darat',
            sea: 'Laut',
            holidays: 'Cuti',
            celebration: 'Perayaan',
            CNY: 'Tahun Baru Cina',
            easter: 'Easter',
            halloween: 'Halloween',
            valentines: 'Hari Valentine',
            Xmas: 'Krismas',
            nature: 'Alam Semulajadi',
            elements: 'Unsur',
            environment: 'Persekitaran',
            plants: 'Tumbuhan',
            weather: 'Cuaca',
        },
        announcement_panel: {
            title: 'Pengumuman',
            dont_show_again: 'Jangan tunjukkan lagi',
        },
    },
    editor: {
        prespray_area: 'Kawasan Persediaan',
        opacity: 'Ketelusan Pratonton',
        exposure: 'Prapandangan Pendedahan',
    },
    flux_id_login: {
        connection_fail: '#847 Gagal menyambung ke perkhidmatan ahli FLUX.',
        login_success: 'Berjaya log masuk.',
        login: 'Log masuk',
        unlock_shape_library: 'Log masuk untuk membuka pangkalan data bentuk.',
        email: 'E-mel',
        password: 'Kata laluan',
        remember_me: 'Ingatkan saya',
        forget_password: 'Lupa Kata Laluan?',
        register: 'Buat Akaun FLUX Anda',
        offline: 'Bekerja Secara Luar Talian',
        work_offline: 'Bekerja Secara Luar Talian',
        incorrect: 'Alamat e-mel atau kata laluan tidak betul.',
        not_verified: 'Alamat e-mel belum disahkan lagi.',
        new_to_flux: 'Baru kepada FLUX? Buat akaun.',
        signup_url: 'https://id.flux3dp.com/user/login#up',
        lost_password_url: 'https://id.flux3dp.com/user/forgot-password',
        flux_plus: {
            explore_plans: 'Terokai Pelan FLUX+',
            thank_you: 'Terima kasih kerana menjadi ahli yang berharga!',
            ai_credit_tooltip: 'Untuk penyingkiran latar belakang AI',
            flux_credit_tooltip: 'Untuk fail Pasaran Reka Bentuk dan penyingkiran latar belakang AI',
            goto_member_center: 'Pergi ke Pusat Ahli',
            access_plus_feature_1: 'Anda mengakses',
            access_plus_feature_2: 'ciri.',
            access_plus_feature_note: 'Anda perlu mempunyai keahlian FLUX+ untuk mengakses ciri ini.',
            access_monotype_feature: 'Anda tidak mempunyai Tambahan Font Monotype.',
            access_monotype_feature_note: 'Anda perlu mempunyai keahlian FLUX+ Pro atau Tambahan Font Monotype untuk mengakses ciri ini.',
            learn_more: 'Ketahui Lebih Lanjut',
            get_addon: 'Dapatkan Tambahan',
            subscribe_now: 'Langgan sekarang',
            website_url: 'https://flux3dp.com/subscription',
            member_center_url: 'https://member.flux3dp.com/en-US/subscription',
            features: {
                ai_bg_removal: 'Penyingkiran Latar Belakang AI',
                my_cloud: 'Storan Awan Tanpa Had',
                boxgen: 'Penjana Kotak 3D',
                dmkt: '1000+ Fail Reka Bentuk',
                monotype: '250+ Fon Premium',
            },
        },
    },
    noun_project_panel: {
        login_first: 'Log masuk untuk membuka pangkalan data bentuk.',
        enjoy_shape_library: 'Nikmati pangkalan data bentuk.',
        shapes: 'Bentuk',
        elements: 'Elemen',
        recent: 'Terbaru',
        search: 'Cari',
        clear: 'Kosongkan',
        export_svg_title: 'Tidak Dapat Eksport SVG',
        export_svg_warning: 'Projek ini mengandungi objek yang dilindungi oleh undang-undang harta intelek. Oleh itu, Beam Studio akan mengecualikan objek ini secara automatik semasa eksport. Anda masih boleh menyimpan projek anda dalam format Beam Studio Scene (.beam) untuk mengekalkan keseluruhan data anda. Anda masih mahu eksport?',
        learn_more: 'Ketahui Lebih Lanjut',
    },
    change_logs: {
        change_log: 'Log Perubahan:',
        added: 'Ditambah:',
        fixed: 'Diperbetulkan:',
        changed: 'Diubah:',
        see_older_version: 'Lihat Versi Lama',
        help_center_url: 'https://support.flux3dp.com/hc/en-us/sections/360000421876',
    },
    select_device: {
        select_device: 'Pilih Peranti',
        auth_failure: '#811 Pengesahan gagal',
        unable_to_connect: '#810 Tidak dapat membina sambungan stabil dengan mesin',
    },
    device: {
        pause: 'Jeda',
        paused: 'Dijeda',
        pausing: 'Menjeda',
        select_printer: 'Pilih Pencetak',
        retry: 'Cuba Lagi',
        status: 'Status',
        busy: 'Sibuk',
        ready: 'Sedia',
        reset: 'Tetapkan Semula (Tendang)',
        abort: 'Batalkan',
        start: 'Mulakan',
        please_wait: 'Sila Tunggu...',
        quit: 'Keluar',
        completing: 'Menyiapkan',
        aborted: 'Dibatalkan',
        completed: 'Selesai',
        aborting: 'Membatalkan',
        starting: 'Memulakan',
        preparing: 'Mempersiapkan',
        resuming: 'Meneruskan',
        scanning: 'Mengimbas',
        occupied: 'Menyelenggara',
        running: 'Bekerja',
        uploading: 'Memuat Naik',
        processing: 'Memproses',
        disconnectedError: {
            caption: 'Mesin terputus sambungan',
            message: 'Sila sahkan sama ada capaian rangkaian %s tersedia',
        },
        unknown: 'Tidak diketahui',
        pausedFromError: 'Dijeda kerana ralat',
        model_name: 'Nama Model',
        IP: 'IP',
        serial_number: 'Nombor Siri',
        firmware_version: 'Versi Firmware',
        UUID: 'UUID',
        select: 'Pilih',
        deviceList: 'Senarai Peranti',
        disable: 'Lumpuhkan',
        enable: 'Hidupkan',
        submodule_type: 'Module',
        cartridge_serial_number: 'Ink cartridge serial number',
        ink_color: 'Ink color',
        ink_type: 'Ink type',
        ink_level: 'Ink level',
        close_door_to_read_cartridge_info: "To access ink information, please close the machine's door cover.",
        cartridge_info_read_failed: 'Please ensure that the ink is fully inserted. Try to remove the ink and install it back.',
        cartridge_info_verification_failed: 'Please verify that your ink is FLUX authentic.',
        toolhead_change: 'Tukar Kepala Alat',
    },
    monitor: {
        monitor: 'PEMANTAU',
        go: 'Mula',
        resume: 'Sambung Semula',
        start: 'Mula',
        pause: 'Jeda',
        stop: 'Henti',
        record: 'Rakam',
        camera: 'Kamera',
        taskTab: 'Tugas',
        connecting: 'Menyambung, sila tunggu...',
        HARDWARE_ERROR_MAINBOARD_ERROR: '#401 Ralat Kritikal: Papan utama luar talian. Sila hubungi Sokongan FLUX.',
        HARDWARE_ERROR_SUBSYSTEM_ERROR: '#402 Ralat Kritikal: Tiada respons subsistem. Sila hubungi Sokongan FLUX.',
        HARDWARE_ERROR_PUMP_ERROR: '#900 Sila semak tangki air anda.',
        HARDWARE_ERROR_DOOR_OPENED: '#901 Tutup pintu untuk teruskan.',
        HARDWARE_ERROR_OVER_TEMPERATURE: '#902 Terlalu panas. Sila tunggu beberapa minit.',
        HARDWARE_ERROR_BOTTOM_OPENED: '#903 Bahagian bawah Dibuka. Tutup bahagian bawah untuk teruskan.',
        HARDWARE_ERROR_PLATFORM_HOMING_FAILED: '#910 Penetapan semula paksi-Z gagal',
        HARDWARE_ERROR_DRAWER_OPENED: '#911 Laci dibuka',
        HARDWARE_ERROR_FIRE_DETECTED: '#912 Sensor api mengesan anomali',
        HARDWARE_ERROR_AIR_ASSIST_ABNORMAL: '#913 Sensor aliran udara mengesan anomali',
        HARDWARE_ERROR_ROTARY_NOT_DETECTED: '#914 Tiada modul putar dikesan',
        HARDWARE_ERROR_HOMING_PULLOFF_FAILED: '#920 Ralat Tarikan Pulang Penjuruhan',
        HARDWARE_ERROR_HEADTYPE_MISMATCH: '#915 Modul yang salah dikesan. Sila pasang modul yang betul untuk meneruskan.',
        HARDWARE_ERROR_HEADTYPE_NONE: '#917 Modul tidak dikesan. Sila pastikan modul dipasang dengan betul untuk meneruskan.',
        HARDWARE_ERROR_HEADTYPE_UNKNOWN: '#918 Modul tidak diketahui dikesan. Sila pasang modul yang betul untuk meneruskan.',
        HARDWARE_ERROR_PRINTER_NO_RESPONSE: '#919 Tiada respons daripada modul pencetak.',
        USER_OPERATION_ROTARY_PAUSE: 'Sila tukar kepada motor putar',
        USER_OPERATION_REMOVE_CARTRIDGE: 'Sila keluarkan kartrij untuk meneruskan.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_MISMATCH: '#915 Modul yang salah dikesan. Sila pasang modul yang betul untuk meneruskan.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_NONE: '#917 Modul tidak dikesan. Sila pastikan modul dipasang dengan betul untuk meneruskan.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_UNKNOWN: '#918 Modul tidak diketahui dikesan. Sila pasang modul yang betul untuk meneruskan.',
        USER_OPERATION_CHANGE_TOOLHEAD: 'Sila pasang modul yang betul untuk meneruskan.',
        USER_OPERATION_CHANGE_CARTRIDGE_CARTRIDGE_NOT_DETECTED: 'Sila masukkan kartrij untuk meneruskan.',
        USER_OPERATION_CHANGE_CARTRIDGE: 'Sila masukkan kartrij yang betul untuk meneruskan.',
        USER_OPERATION: 'Sila ikuti arahan di panel peranti untuk meneruskan.',
        RESOURCE_BUSY: 'Mesin sibuk\nJika tidak beroperasi, sila mulakan semula mesin',
        DEVICE_ERROR: 'Sesuatu telah berlaku\nSila mulakan semula mesin',
        NO_RESPONSE: '#905 Ralat semasa menyambung ke papan induk.\nSila mulakan semula mesin.',
        SUBSYSTEM_ERROR: '#402 Ralat Kritikal: Tiada respon subsistem. Sila hubungi Sokongan FLUX.',
        HARDWARE_FAILURE: 'Sesuatu telah berlaku\nSila mulakan semula mesin',
        MAINBOARD_OFFLINE: '#905 Ralat semasa menyambung ke papan induk.\nSila mulakan semula mesin.',
        bug_report: 'Laporan Pepijat',
        processing: 'Pemprosesan',
        savingPreview: 'Menghasilkan imej kecil',
        hour: 'j',
        minute: 'm',
        second: 's',
        left: 'kiri',
        temperature: 'Suhu',
        forceStop: 'Adakah anda mahu membatalkan tugas semasa?',
        upload: 'Muat Naik',
        download: 'Muat Turun',
        relocate: 'Pindah Lokasi',
        cancel: 'Batal',
        prepareRelocate: 'Menyediakan untuk Pindah Lokasi',
        extensionNotSupported: 'Format fail ini tidak disokong',
        fileExistContinue: 'Fail sudah wujud, adakah anda mahu menggantikannya?',
        confirmFileDelete: 'Adakah anda pasti mahu memadam fail ini?',
        ask_reconnect: 'Sambungan dengan mesin telah terputus. Adakah anda mahu menyambung semula?',
        task: {
            BEAMBOX: 'Ukiran Laser',
            'N/A': 'Mod Bebas',
        },
    },
    alert: {
        caption: 'Ralat',
        duplicated_preset_name: 'Nama praset sama',
        info: 'INFO',
        warning: 'AMARAN',
        error: 'UH-OH',
        instruction: 'Arahan',
        oops: 'Oops...',
        retry: 'Cuba Lagi',
        abort: 'Batalkan',
        confirm: 'Sahkan',
        cancel: 'Batal',
        close: 'Tutup',
        ok: 'OK',
        ok2: 'OK',
        yes: 'Ya',
        no: 'Tidak',
        stop: 'Berhenti',
        save: 'Simpan',
        dont_save: 'Jangan Simpan',
        learn_more: 'Ketahui Lebih Lanjut',
    },
    caption: {
        connectionTimeout: 'Sambungan tamat masa',
    },
    message: {
        cancelling: 'Membatalkan...',
        connecting: 'Menyambung...',
        connectingMachine: 'Menyambung %s...',
        tryingToConenctMachine: 'Cuba sambung ke mesin...',
        connected: 'Disambung',
        authenticating: 'Mengesahkan...',
        enteringRawMode: 'Memasuki mod mentah...',
        endingRawMode: 'Keluar mod mentah...',
        enteringLineCheckMode: 'Memasuki mod semak garisan...',
        endingLineCheckMode: 'Keluar dari mod semakan garis akhir...',
        exitingRotaryMode: 'Keluar dari mod putaran...',
        turningOffFan: 'Mematikan kipas...',
        turningOffAirPump: 'Mematikan pam udara...',
        gettingLaserSpeed: 'Membaca kelajuan kepala laser...',
        settingLaserSpeed: 'Menetapkan kelajuan kepala laser...',
        retrievingCameraOffset: 'Membaca ofset kamera...',
        connectingCamera: 'Menyambung kamera...',
        homing: 'Penjajaran semula...',
        connectionTimeout: '#805 Sambungan Peranti Tamat Masa. Sila semak keadaan rangkaian anda dan penunjuk Wi-Fi mesin anda.',
        getProbePosition: 'Mendapatkan Kedudukan Probe...',
        device_not_found: {
            caption: 'Mesin Lalai tidak dijumpai',
            message: '#812 Sila semak penunjuk Wi-Fi mesin anda',
        },
        device_busy: {
            caption: 'Mesin Sibuk',
            message: 'Mesin sedang melaksanakan tugas lain, cuba lagi nanti. Jika berhenti berfungsi, sila mulakan semula mesin.',
        },
        device_is_used: 'Mesin sedang digunakan, adakah anda mahu membatalkan tugas semasa?',
        monitor_too_old: {
            caption: 'Firmware Usang',
            content: '#814 Sila pasang firmware terkini dengan <a target="_blank" href="http://helpcenter.flux3dp.com/hc/en-us/articles/216251077">panduan ini</a>.',
        },
        unknown_error: '#821 Aplikasi telah menghadapi ralat yang tidak diketahui, sila gunakan Bantuan > Menu > Laporan Pepijat.',
        unknown_device: '#826 Tidak dapat disambung ke mesin, pastikan USB disambung ke mesin',
        unsupport_osx_version: 'Versi macOS %s semasa mungkin tidak menyokong beberapa fungsi. Sila kemas kini ke macOS 11+.',
        unsupport_win_version: 'Versi OS %s semasa mungkin tidak menyokong beberapa fungsi. Sila kemas kini ke versi terkini.',
        need_password: 'Kata laluan diperlukan untuk Menyambung ke Mesin',
        unavailableWorkarea: '#804 Kawasan kerja semasa melebihi kawasan kerja mesin ini. Sila semak kawasan kerja mesin yang dipilih atau tetapkan kawasan kerja dari Edit > Tetapan Dokumen.',
        please_enter_dpi: 'Sila masukkan unit fail anda (dalam mm)',
        auth_error: '#820 Ralat Pengesahan: Sila kemas kini Beam Studio dan firmware mesin ke versi terkini.',
        usb_unplugged: 'Sambungan USB terputus. Sila semak sambungan USB anda',
        uploading_fcode: 'Memuat naik FCode',
        cant_connect_to_device: '#827 Tidak dapat disambung ke mesin, sila semak sambungan anda',
        unable_to_find_machine: 'Tidak dapat menjumpai mesin ',
        disconnected: 'Sambungan tidak stabil, Sila semak sambungan peranti dan cuba lagi nanti',
        unable_to_start: '#830 Tidak dapat memulakan tugasan. Sila cuba lagi. Jika ini berlaku lagi, sila hubungi kami dengan laporan bug:',
        camera: {
            camera_cable_unstable: 'Kamera menghantar gambar secara tidak stabil dikesan. Pratonton masih boleh dilakukan dengan normal, tetapi mungkin ada masalah pratonton perlahan atau masa tamat.',
            fail_to_transmit_image: '#845 Sesuatu telah berlaku dengan penghantaran imej. Sila mulakan semula mesin atau Beam Studio anda. Jika ralat ini berterusan, sila ikuti <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/4402756056079">panduan ini</a>.',
            ws_closed_unexpectly: '#844 Sambungan ke kamera mesin telah ditutup secara mengejut. Jika ralat ini berterusan, sila ikuti <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/4402755805071">panduan ini</a>.',
            continue_preview: 'Teruskan',
            abort_preview: 'Batalkan',
        },
        preview: {
            camera_preview: 'Pratonton Kamera',
            auto_focus: 'Fokus Automatik',
            auto_focus_instruction: 'Sila gerakkan kepala modul laser di atas objek, dan ikut arahan animasi untuk menekan AF bagi memfokus.',
            already_performed_auto_focus: 'Anda telah melakukan fokus automatik, guna nilai sedia ada?',
            please_enter_height: 'Sila masukkan ketinggian objek. Bagi menangkap gambar kamera yang tepat.',
            apply: 'Guna',
            enter_manually: 'Masukkan Secara Manual',
            adjust: 'Laras',
            adjust_height_tooltip: 'Klik kotak semak untuk membenarkan penyuntingan.',
        },
        unsupported_example_file: 'Contoh fail yang dipilih tidak disokong oleh ruang kerja semasa.',
        time_remaining: 'Masa yang tinggal:',
    },
    machine_status: {
        '0': 'Senggang',
        '1': 'Memulakan',
        '2': 'ST_TRANSFORM',
        '4': 'Memulakan',
        '6': 'Menyambung semula',
        '16': 'Bekerja',
        '18': 'Menyambung semula',
        '32': 'Dijeda',
        '36': 'Dijeda',
        '38': 'Dijeda',
        '48': 'Dijeda',
        '50': 'Dijeda',
        '64': 'Selesai',
        '66': 'Menyempurnakan',
        '68': 'Menyediakan',
        '128': 'Dibatalkan',
        '256': 'Amaran',
        '512': 'Nahas',
        '-17': 'Mod IO Kartrij',
        '-10': 'Mod penyelenggaraan',
        '-2': 'Penimbangan',
        '-1': 'Menyelenggarakan',
        UNKNOWN: 'Tidak diketahui',
    },
    calibration: {
        update_firmware_msg1: 'Firmware anda tidak menyokong fungsi ini. Sila kemaskini firmware ke v',
        update_firmware_msg2: 'atau lebih tinggi untuk meneruskan。 (Menu > Mesin > [Mesin Anda] > Kemaskini Firmware)',
        camera_calibration: 'Penentukuran Kamera',
        diode_calibration: 'Penentukuran Modul Laser Diod',
        module_calibration_printer: 'Penentukuran Modul Pencetak',
        module_calibration_2w_ir: 'Penentukuran Modul Inframerah',
        back: 'Kembali',
        next: 'Seterusnya',
        skip: 'Langkau',
        cancel: 'Batalkan',
        finish: 'Selesai',
        do_engraving: 'Mula Ukiran',
        start_engrave: 'Mula Ukiran',
        start_printing: 'Mula Cetak',
        ask_for_readjust: 'Adakah anda mahu melangkau langkah pengukiran dan kemudian mengambil gambar dan menentusahkan secara langsung?',
        please_goto_beambox_first: 'Sila tukar ke Mod Pengukiran (Beambox) untuk menggunakan ciri ini.',
        please_place_paper: 'Sila letakkan kertas A4 atau Saiz Surat berwarna putih di penjuru kiri atas ruang kerja.',
        please_place_paper_center: 'Sila letakkan kertas A4 atau Saiz Surat berwarna putih di tengah ruang kerja.',
        please_place_dark_colored_paper: 'Sila letakkan kertas berwarna gelap bersaiz A4 atau Surat di tengah kawasan kerja.',
        please_refocus: {
            beambox: 'Sila laraskan platform ke titik fokus (ketinggian akrilik songsang)',
            beamo: 'Sila laraskan kepala laser untuk fokus pada objek ukiran (ketinggian akrilik songsang)',
            beamo_af: 'Sila ketuk dua kali butang sisi tambahan fokus automatik dan biarkan prob menyentuh bahan dengan lembut.',
            hexa: 'Klik dua kali butang pelarasan ketinggian untuk menaikkan meja sarang lebah dan membuat prob menyentuh bahan ukiran.',
        },
        without_af: 'Tanpa Fokus Automatik',
        with_af: 'Dengan Fokus Automatik',
        dx: 'X',
        dy: 'Y',
        rotation_angle: 'Pusingan',
        x_ratio: 'Nisbah X',
        y_ratio: 'Nisbah Y',
        show_last_config: 'Tunjukkan Keputusan Terakhir',
        use_last_config: 'Guna Nilai Pentakbiran Terakhir',
        taking_picture: 'Mengambil Gambar...',
        analyze_result_fail: 'Gagal menganalisis imej yang ditangkap. <br/> Pastikan: <br/> 1. Gambar yang ditangkap diliputi sepenuhnya dengan kertas putih. <br/> 2. Platform difokuskan dengan betul.',
        drawing_calibration_image: 'Melukis imej pentakbiran...',
        calibrate_done: 'Pentakbiran selesai. Ketepatan kamera yang lebih baik diberikan apabila fokus dengan tepat.',
        calibrate_done_diode: 'Pentakbiran selesai. Ofset modul laser diod telah disimpan.',
        hint_red_square: 'Sila sejajarkan luar petak merah dengan petak yang dipotong.',
        hint_adjust_parameters: 'Sila gunakan parameter ini untuk melaraskan kotak merah',
        zendesk_link: 'https://support.flux3dp.com/hc/en-us/articles/360001811416',
        please_do_camera_calibration_and_focus: {
            beambox: 'Semasa menentusahkan modul laser diod, kamera diperlukan.\nPastikan kamera mesin ini telah ditentusahkan.\nDan laraskan platform pada titik fokus (ketinggian akrilik songsang)',
            beamo: 'Semasa menentusahkan modul laser diod, kamera diperlukan.\nPastikan kamera mesin ini telah ditentusahkan.\nDan laraskan kepala laser untuk fokus pada objek ukiran (ketinggian akrilik songsang)',
        },
        downloading_pictures: 'Memuat turun Gambar...',
        failed_to_download_pictures: '#848 Gagal memuat turun gambar, hubungi Sokongan FLUX.',
        uploading_images: 'Memuat naik Imej...',
        calculating_camera_matrix: 'Mengira Matriks Kamera...',
        calculating_regression_parameters: 'Mengira Parameter Regresi...',
        failed_to_calibrate_camera: '#848 Gagal menentusahkan kamera, hubungi Sokongan FLUX.',
        failed_to_save_calibration_results: '#849 Gagal menyimpan keputusan penentusahan, cuba lagi. Jika masalah berterusan, hubungi Sokongan FLUX.',
        ador_autofocus_material: 'Tekan ikon "AF" pada halaman utama mesin selama 3 saat dan biarkan probe menyentuh bahan dengan lembut.',
        ador_autofocus_focusing_block: 'Tekan ikon "AF" pada halaman utama mesin selama 3 saat dan biarkan probe menyentuh Blok Fokus.',
        align_red_cross_cut: 'Sejajarkan tengah salib merah dengan potongan salib.',
        align_red_cross_print: 'Sejajarkan tengah salib merah dengan salib yang dicetak.',
        retake: 'Ambil Semula Gambar',
        calibrate_camera_before_calibrate_modules: 'Lakukan penentusahan kamera sebelum menentusahkan modul.',
        check_checkpoint_data: 'Data kawalan titik',
        checking_checkpoint: 'Memeriksa data kawalan titik...',
        found_checkpoint: 'Data kawalan titik dijumpai pada peranti anda. Mahukah anda pulih daripada titik kawalan?',
        use_old_camera_parameter: 'Adakah anda ingin menggunakan parameter lensa kamera semasa?',
        downloading_checkpoint: 'Memuat turun data kawalan titik...',
        failed_to_parse_checkpoint: 'Gagal mengurai data kawalan titik.',
        check_device_pictures: 'Semak Gambar Peranti',
        checking_pictures: 'Memeriksa gambar peranti...',
        no_picutre_found: '#846 Peranti anda tidak mempunyai gambar mentah yang tersedia untuk kalibrasi. Sila hubungi sokongan FLUX.',
        calibrating_with_device_pictures: 'Mengkalibrasi dengan gambar peranti...',
        failed_to_calibrate_with_pictures: '#847 Gagal mengkalibrasi dengan gambar peranti.',
        getting_plane_height: 'Mendapatkan Ketinggian Satah...',
        preparing_to_take_picture: 'Sedang Bersedia untuk Ambil Gambar...',
        put_paper: 'Letakkan Kertas',
        put_paper_step1: 'Sila letakkan sehelai kertas putih bersaiz A4 atau Surat di tengah-tengah kawasan kerja.',
        put_paper_step2: 'Pastikan keempat-empat sudut kertas diikat untuk memastikan ia rata.',
        put_paper_step3: 'Klik "MULA UKIRAN".',
        solve_pnp_title: 'Sejajarkan Titik Penanda',
        solve_pnp_step1: 'Sila selaraskan titik ukiran mengikut nombor dan kedudukan yang diharapkan bagi setiap titik penanda merah.',
        solve_pnp_step2: 'Anda boleh tekan "Ambil Semula Gambar" untuk menyelaraskan semula atau menyesuaikan posisi penanda secara manual.',
        align_olt: 'Kedudukan: Selaraskan dengan titik terukir di bahagian atas kiri luar.',
        align_ort: 'Kedudukan: Selaraskan dengan titik terukir di bahagian atas kanan luar.',
        align_olb: 'Kedudukan: Selaraskan dengan titik terukir di bahagian bawah kiri luar.',
        align_orb: 'Kedudukan: Selaraskan dengan titik terukir di bahagian bawah kanan luar.',
        align_ilt: 'Kedudukan: Selaraskan dengan titik terukir di bahagian atas kiri dalam.',
        align_irt: 'Kedudukan: Selaraskan dengan titik terukir di bahagian atas kanan dalam.',
        align_ilb: 'Kedudukan: Selaraskan dengan titik terukir di bahagian bawah kiri dalam.',
        align_irb: 'Kedudukan: Selaraskan dengan titik terukir di bahagian bawah kanan dalam.',
        elevate_and_cut: 'Angkat dan Potong',
        elevate_and_cut_step_1: 'Letakkan sekeping kayu berwarna terang berukuran A4 di tengah-tengah ruang kerja, diangkat hingga 20mm.',
        elevate_and_cut_step_1_prism_lift: 'Gunakan Ador Prism Lift dengan panjang maksimum 14mm bersama dengan sekeping kayu yang sekurang-kurangnya 6mm tebal.',
        with_prism_lift: 'Dengan Ador Prism Lift',
        without_prism_lift: 'Tanpa Ador Prism Lift',
        camera_parameter_saved_successfully: 'Parameter kamera berjaya disimpan.',
        failed_to_save_camera_parameter: 'Gagal menyimpan parameter kamera.',
        failed_to_solve_pnp: 'Gagal menyelesaikan kedudukan kamera.',
        calibrating: 'Menentukur...',
        moving_laser_head: 'Menggerakkan kepala laser...',
        failed_to_move_laser_head: 'Gagal menggerakkan kepala laser.',
        put_chessboard: 'Letakkan Papan Catur',
        put_chessboard_desc: 'Sila letakkan papan catur di tengah kawasan kerja.',
        put_chessboard_1: 'Sila pergi ke panel kawalan mesin dan tekan AF untuk melakukan autofokus.',
        put_chessboard_2: 'Sila gerakkan kertas papan dam atau kepala laser sehingga keseluruhan papan dam disahkan berada dalam bingkai merah di tetingkap pratonton langsung.',
        put_chessboard_3: 'Klik kanan untuk memuat turun gambar papan catur di lokasi semasa dan periksa kejelasannya.',
        failed_to_calibrate_chessboard: 'Gagal mengkalibrasi dengan gambar papan dam.',
        calibrate_chessboard_success_msg: 'Gambar papan catur berjaya ditangkap.<br/>Skor untuk gambar ini adalah %s (%.2f).',
        res_excellent: 'Cemerlang',
        res_average: 'Purata',
        res_poor: 'Lemah',
        perform_autofocus_bb2: 'Sila pergi ke panel kawalan mesin dan tekan AF untuk melakukan autofokus.',
    },
    input_machine_password: {
        require_password: '"%s" memerlukan kata laluan',
        connect: 'SAMBUNG',
        password: 'Kata Laluan',
    },
    tutorial: {
        skip: 'Langkau',
        welcome: 'SELAMAT DATANG',
        suggest_calibrate_camera_first: 'Kami menasihatkan pengguna untuk mengkalibrasi kamera pada mulanya dan menetap semula fokus sebelum setiap pratinjau untuk hasil yang optimum.<br/>Adakah anda ingin mengesahkan untuk menjalankan kalibrasi sekarang?<br/>(Anda boleh langkau sekarang dan lakukan nanti dengan mengklik "Menu" > "Mesin" > [Mesin Anda] > "Kalibrasi Kamera".)',
        camera_calibration_failed: 'Penentukuran Kamera Gagal',
        ask_retry_calibration: 'Adakah anda ingin menentukur semula kamera?',
        needNewUserTutorial: 'Adakah anda ingin memulakan tutorial?<br/>(Anda boleh langkau sekarang dan memulakannya kemudian dengan mengklik "Bantuan" > "Tunjukkan Tutorial Permulaan".)',
        needNewInterfaceTutorial: 'Adakah anda ingin memulakan tutorial untuk antara muka baru Beam Studio?<br/>(Anda boleh langkau sekarang dan memulakannya kemudian dengan mengklik "Bantuan" > "Tunjukkan Pengenalan Antara Muka".)',
        next: 'SETERUSNYA',
        look_for_machine: 'Mencari mesin untuk tutorial...',
        unable_to_find_machine: 'Tidak dapat mencari mesin untuk Tutorial. Adakah anda ingin pergi ke halaman tetapan sambungan, cuba semula atau langkau tutorial?',
        skip_tutorial: 'Anda telah melangkau tutorial. Anda sentiasa boleh mulakan tutorial dengan klik "Bantuan" > "Tunjukkan Mulakan Tutorial"',
        set_connection: 'Tetapkan Sambungan',
        retry: 'Cuba Semula',
        newUser: {
            draw_a_circle: 'Lukis Bulatan',
            drag_to_draw: 'Seret untuk Melukis',
            infill: 'Hidupkan Isian',
            switch_to_object_panel: 'Tukar ke Panel Objek',
            switch_to_layer_panel: 'Tukar ke Panel Lapisan',
            set_preset_wood_engraving: 'Tetapkan Praset: Kayu - Ukiran',
            set_preset_wood_cut: 'Tetapkan Praset: Kayu - Pemotongan',
            add_new_layer: 'Tambah Lapisan Baru',
            draw_a_rect: 'Lukis Segi Empat Tepat',
            switch_to_preview_mode: 'Tukar ke Mod Pratonton',
            preview_the_platform: 'Pratonton Platform',
            end_preview_mode: 'Akhiri Mod Pratonton',
            put_wood: '1. Letakkan sampel kayu',
            adjust_focus: '2. Laraskan fokus',
            close_cover: '3. Tutup penutup',
            send_the_file: 'Hantar Fail',
            end_alert: 'Adakah anda pasti untuk menamatkan tutorial?',
            please_select_wood_engraving: 'Sila pilih praset "Kayu - Ukiran".',
            please_select_wood_cutting: 'Sila pilih praset "Kayu - Pemotongan".',
        },
        newInterface: {
            camera_preview: 'Pratonton Kamera',
            select_image_text: 'Pilih / Imej / Teks',
            basic_shapes: 'Bentuk Asas',
            pen_tool: 'Alat Pena',
            add_new_layer: 'Tambah Lapisan Baru',
            rename_by_double_click: 'Namakan Semula dengan klik dua kali',
            drag_to_sort: 'Seret untuk susun',
            layer_controls: 'Klik kanan untuk pilih Kawalan Lapisan:\nDuplikat / Gabung / Kunci / Padam Lapisan',
            switch_between_layer_panel_and_object_panel: 'Tukar antara Panel Lapisan dan Panel Objek',
            align_controls: 'Kawalan Jajar',
            group_controls: 'Kawalan Kumpulan',
            shape_operation: 'Operasi Bentuk',
            flip: 'Terbalik',
            object_actions: 'Tindakan Objek',
            end_alert: 'Adakah anda pasti untuk menamatkan pengenalan UI baharu?',
            select_machine: 'Pilih mesin',
            start_work: 'Mulakan Kerja',
        },
        gesture: {
            pan: 'Tatal dengan dua jari untuk mengimbas kanvas.',
            zoom: 'Cubit dengan dua jari untuk mengecilkan/membesarkan kanvas.',
            click: 'Ketik untuk memilih objek.',
            drag: 'Seret untuk memilih beberapa objek.',
            hold: 'Tekan dan tahan untuk membuka menu konteks.',
        },
        links: {
            adjust_focus: 'https://flux3dp.zendesk.com/hc/en-us/articles/360001684196',
        },
        tutorial_complete: 'Itu sahaja untuk tutorial. Sekarang masanya untuk mencipta!',
    },
    layer_module: {
        none: 'None',
        general_laser: 'Laser',
        laser_10w_diode: 'Laser Diod 10W',
        laser_20w_diode: 'Laser Diod 20W',
        laser_2w_infrared: 'Laser Inframerah 2W',
        printing: 'Pencetakan',
        unknown: 'Unknown Module',
        notification: {
            convertFromPrintingModuleTitle: 'Adakah anda mahu menukar modul Pencetakan kepada modul Laser?',
            convertFromPrintingModuleMsg: 'Sila ambil perhatian bahawa jika anda melengkapkan operasi ini, tetapan warna lapisan pencetakan akan dibuang dan ditetapkan mengikut lapisan semasa.',
            convertFromLaserModuleTitle: 'Adakah anda mahu menukar modul Laser kepada modul Pencetakan?',
            convertFromLaserModuleMsg: 'Sila ambil perhatian bahawa jika anda melengkapkan operasi ini, tetapan lapisan laser akan dibuang dan ditetapkan mengikut lapisan semasa.',
            importedDocumentContainsPrinting: 'Dokumen mengandungi lapisan pencetakan, adakah anda mahu menukar ruang kerja kepada Ador?',
            printingLayersCoverted: 'Lapisan percetakan telah ditukar kepada lapisan laser.',
            performPrintingCaliTitle: 'Lakukan penentukuran modul percetakan',
            performPrintingCaliMsg: 'Klik “Sahkan” untuk melaksanakan penentukuran, atau akses penentukuran melalui menu atas.<br />(Mesin > [Nama Mesin Anda] > Tentukur Modul Percetakan)',
            performIRCaliTitle: 'Lakukan penentukuran modul Inframerah',
            performIRCaliMsg: 'Klik “Sahkan” untuk melaksanakan penentukuran, atau akses penentukuran melalui menu atas.<br />(Mesin > [Nama Mesin Anda] > Tentukur Modul Inframerah)',
        },
        non_working_area: 'Kawasan Bukan Kerja',
    },
    qr_code_generator: {
        title: 'Penjana Kod QR',
        placeholder: 'Masukkan pautan atau teks',
        preview: 'Pratonton',
        error_tolerance: 'Ralat Toleransi',
        error_tolerance_link: 'https://support.flux3dp.com/hc/en-us/articles/9113705072143',
        invert: 'Songsangkan warna latar belakang',
    },
    boxgen: {
        title: 'BOXGEN',
        basic_box: 'Kotak Asas',
        coming_soon: 'Akan Datang',
        workarea: 'Kawasan Kerja',
        max_dimension_tooltip: 'Had maksimum lebar/tinggi/kedalaman ialah %s.',
        volume: 'Isipadu',
        outer: 'Luar',
        inner: 'Dalam',
        width: 'Lebar',
        height: 'Tinggi',
        depth: 'Kedalaman',
        cover: 'Penutup',
        thickness: 'Ketebalan',
        add_option: 'Tambah Pilihan',
        joints: 'Sambungan',
        finger: 'Jari',
        finger_warning: 'Panjang sisi dalam kotak perlu sekurang-kurangnya 6mm (0.24 inci) untuk serasi dengan sambungan Jari.',
        tSlot: 'T-Slot',
        tSlot_warning: 'Panjang sisi kotak perlu sekurang-kurangnya 30mm (1.18 inci) untuk serasi dengan sambungan T-slot.',
        edge: 'Tepi',
        tCount: 'Kiraan T',
        tCount_tooltip: 'Bilangan slot T terpakai pada sisi pendek; kuantiti pada sisi panjang dikira berdasarkan panjangnya.',
        tDiameter: 'Diameter T',
        tLength: 'Panjang T',
        continue_import: 'Teruskan untuk Import',
        customize: 'Ubah suai',
        merge: 'Gabung',
        text_label: 'Label Teks',
        beam_radius: 'Pampasan kerf',
        beam_radius_warning: 'Alih keluar pampasan kerf apabila tepi atau sambungan kotak pendek untuk memastikan pemasangan kotak',
        import: 'Import',
        cancel: 'Batal',
        reset: 'Tetapkan Semula',
        zoom: 'Zum',
        control_tooltip: 'Kiri tetikus untuk putar\nGulung untuk zum\nTetikus kanan untuk anjak',
        control_tooltip_touch: 'Seret untuk putar\nCubit untuk zum\nDua jari untuk anjak',
    },
    my_cloud: {
        title: 'Awan Saya',
        loading_file: 'Memuat...',
        no_file_title: 'Simpan fail ke Awan Saya untuk bermula.',
        no_file_subtitle: 'Pergi ke Menu > "Fail" > "Simpan ke Awan"',
        file_limit: 'Fail percuma',
        upgrade: 'Naik taraf',
        sort: {
            most_recent: 'Terbaru',
            oldest: 'Terlama',
            a_to_z: 'Nama: A - Z',
            z_to_a: 'Nama: Z - A',
        },
        action: {
            open: 'Buka',
            rename: 'Namakan Semula',
            duplicate: 'Duplikat',
            download: 'Muat Turun',
            delete: 'Padam',
            confirmFileDelete: 'Anda pasti mahu memadam fail ini? Tindakan ini tidak boleh dibatalkan.',
        },
        save_file: {
            choose_action: 'Simpan Fail:',
            save: 'Simpan',
            save_new: 'Simpan sebagai fail baru',
            input_file_name: 'Masukkan nama fail:',
            invalid_char: 'Aksara tidak sah:',
            storage_limit_exceeded: 'Penyimpanan awan anda telah mencapai had atas. Sila padam mana-mana fail yang tidak diperlukan sebelum menyimpan fail baru.',
        },
    },
    camera_data_backup: {
        title: 'Simpanan Data Kamera',
        no_picture_found: 'Tiada gambar dijumpai dalam mesin.',
        folder_not_exists: 'Folder yang dipilih tidak wujud.',
        incorrect_folder: 'Gagal mengunggah data kalibrasi. Sila semak sama ada folder yang anda pilih betul atau tidak.',
        downloading_data: 'Memuat turun data...',
        estimated_time_left: 'Anggaran masa yang tinggal:',
        uploading_data: 'Memuat naik data...',
        download_success: 'Data kamera berjaya dimuat turun.',
        upload_success: 'Data kamera berjaya dimuat naik.',
    },
    insecure_websocket: {
        extension_detected: 'Pengesanan Sambungan Beam Studio Connect',
        extension_detected_description: 'Kami telah mengesan anda telah memasang sambungan Beam Studio Connect. Sila klik ‘Sahkan’ untuk diarahkan ke HTTPS, atau klik ‘Batal’ untuk terus menggunakan HTTP.',
        extension_not_deteced: 'Tidak dapat Mengesan Sambungan Beam Studio Connect',
        extension_not_deteced_description: "Untuk menggunakan HTTPS, sila klik 'Sahkan' untuk memasang sambungan Beam Studio Connect. Selepas memasang sambungan, sila segarkan halaman untuk mengaktifkannya.<br/>Jika tidak, klik pautan di bawah untuk melihat cara menggunakan HTTP pada Chrome.",
        unsecure_url_help_center_link: '<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/9935859456271">Pautan</a>',
    },
    curve_engraving: {
        measure_audofocus_area: 'Ukur Kawasan Fokus Auto',
        amount: 'Jumlah',
        gap: 'Jurang',
        rows: 'Baris',
        coloumns: 'Lajur',
        row_gap: 'Jurang Baris',
        column_gap: 'Jurang Lajur',
        set_object_height: 'Tetapkan Ketinggian Objek',
        set_object_height_desc: 'Ukur ketebalan maksimum objek.',
        measure_area_help: 'Bagaimana memilih kawasan autofokus untuk 3D Curve?',
        reselect_area: 'Pilih Semula Kawasan',
        start_autofocus: 'Mulakan Autofokus',
        starting_measurement: 'Memulakan pengukuran...',
        preview_3d_curve: 'Pratonton Lengkung 3D',
        apply_arkwork: 'Sapukan Karya Seni pada Lengkung 3D',
        apply_camera: 'Sapukan Imej Kamera pada Lengkung 3D',
        click_to_select_point: 'Klik untuk memilih atau nyahpilih titik untuk diukur semula.',
        remeasure: 'Ukur Semula',
        remeasuring_points: 'Mengukur Semula Titik...',
    },
    pass_through: {
        title: 'Mod Lalu',
        help_text: 'Bagaimana untuk menyediakan Mod Lalu untuk Ador?',
        help_link: 'https://support.flux3dp.com/hc/en-us/articles/10140002160399',
        object_length: 'Panjang Objek',
        workarea_height: 'Kawasan Kerja (Tinggi):',
        height_desc: 'Tetapkan ketinggian setiap bahagian kawasan kerja.',
        ref_layer: 'Lapisan Rujukan',
        ref_layer_desc: 'Sila ambil perhatian bahawa pelaksanaan lapisan rujukan ditetapkan kepada 0 secara lalai. Ia tidak akan dilaksanakan tetapi hanya untuk rujukan penjajaran.',
        ref_layer_name: 'Rujukan',
        guide_mark: 'Tanda Panduan',
        guide_mark_length: 'Diameter:',
        guide_mark_x: 'Koordinat X:',
        guide_mark_desc: 'Tanda panduan akan diukir sebagai titik rujukan untuk menyelaraskan karya seni.',
        export: 'Eksport ke Kawasan Kerja',
        exporting: 'Mengeksport...',
    },
    auto_fit: {
        title: 'Muat Auto',
        step1: '1. Letakkan objek di posisi yang dikehendaki pada satu keping bahan.',
        step2: '2. Tekan "Sahkan" untuk menggandakan objek pada semua keping bahan yang serupa dalam pratonton kamera.',
        preview_first: 'Sila lakukan pratonton kamera terlebih dahulu.',
        failed_to_auto_fit: 'Gagal untuk muat secara automatik, sila periksa:',
        error_tip1: '1. Adakah karya seni diletakkan dengan betul pada bahan?',
        error_tip2: '2. Adakah kontur bahan cukup jelas untuk pengiktirafan?',
        learn_more: 'Ketahui cara kerja Muat Auto.',
        learn_more_url: 'https://support.flux3dp.com/hc/en-us/articles/10273384373775',
    },
    rotary_settings: {
        type: 'Jenis',
        object_diameter: 'Diameter Objek',
        circumference: 'Lilitan',
    },
    framing: {
        low_laser: 'Laser rendah',
        low_laser_desc: 'Tetapkan nilai laser berkuasa rendah untuk tugas pembingkaian.',
        framing: 'Pembingkaian',
        hull: 'Kerangka',
        area_check: 'Semakan Kawasan',
        framing_desc: 'Pratonton kotak sempadan objek.',
        hull_desc: 'Memaparkan bentuk yang rapat mengikuti reka bentuk, seperti gelang getah yang dililitkan pada objek.',
        areacheck_desc: 'Memastikan kawasan kerja selamat dengan memaparkan kotak sempadan objek dan zon pecutan kepala laser.',
        calculating_task: 'Mengira tugas...',
    },
    material_test_generator: {
        title: 'Penjana Ujian Bahan',
        table_settings: 'Tetapan Jadual',
        block_settings: 'Tetapan Blok',
        text_settings: 'Tetapan Teks',
        preview: 'Pratonton',
        export: 'Eksport',
        cut: 'Potong',
        engrave: 'Ukir',
        columns: 'Lajur',
        rows: 'Baris',
        parameter: 'Parameter',
        min: 'Min',
        max: 'Maks',
        count: 'Kuantiti',
        size: 'Saiz (HxW)',
        spacing: 'Jarak',
    },
    web_cam: {
        no_permission: 'Beam Studio tidak mempunyai kebenaran untuk mengakses kamera. Pastikan kebenaran diberikan kepada Beam Studio dalam tetapan pelayar atau sistem.',
        no_device: 'Tidak dapat mengesan peranti kamera. Sila sambungkan semula kamera dan cuba lagi.',
    },
};
export default lang;
