import React, { useEffect } from 'react';
import HistoryCommandFactory from 'app/svgedit/history/HistoryCommandFactory';
import ObjectPanelItem from 'app/views/beambox/Right-Panels/ObjectPanelItem';
import OptionPanelIcons from 'app/icons/option-panel/OptionPanelIcons';
import UnitInput from 'app/widgets/Unit-Input-v2';
import useI18n from 'helpers/useI18n';
import { getSVGAsync } from 'helpers/svg-editor-helper';
import { useIsMobile } from 'helpers/system-helper';
import styles from './PolygonOptions.module.scss';
let svgCanvas;
getSVGAsync((globalSVG) => {
    svgCanvas = globalSVG.Canvas;
});
function PolygonOptions({ elem, polygonSides }) {
    const [sides, setSides] = React.useState(polygonSides || 5);
    const isMobile = useIsMobile();
    const lang = useI18n().beambox.right_panel.object_panel.option_panel;
    useEffect(() => {
        if (polygonSides)
            setSides(polygonSides);
    }, [polygonSides]);
    const handleSideChanage = (val) => {
        var _a;
        if (val === sides)
            return;
        const batchCmd = HistoryCommandFactory.createBatchCommand('Change Polygon Sides');
        svgCanvas.undoMgr.beginUndoableChange('sides', [elem]);
        svgCanvas.undoMgr.beginUndoableChange('points', [elem]);
        (_a = window.updatePolygonSides) === null || _a === void 0 ? void 0 : _a.call(window, elem, val - sides);
        let cmd = svgCanvas.undoMgr.finishUndoableChange();
        if (!cmd.isEmpty())
            batchCmd.addSubCommand(cmd);
        cmd = svgCanvas.undoMgr.finishUndoableChange();
        if (!cmd.isEmpty())
            batchCmd.addSubCommand(cmd);
        if (!batchCmd.isEmpty())
            svgCanvas.undoMgr.addCommandToHistory(batchCmd);
        setSides(parseInt(elem.getAttribute('sides'), 10));
    };
    const renderSides = () => isMobile ? (React.createElement(ObjectPanelItem.Number, { id: "polygon-sides", value: sides, min: 3, updateValue: handleSideChanage, label: lang.sides, unit: "", decimal: 0 })) : (React.createElement("div", { className: styles['polygon-sides'], key: "polygon-sides" },
        React.createElement("div", { className: styles.label, title: lang.sides },
            React.createElement(OptionPanelIcons.PolygonSide, null)),
        React.createElement(UnitInput, { min: 3, className: { 'option-input': true }, defaultValue: sides, decimal: 0, getValue: (val) => handleSideChanage(val) })));
    return isMobile ? renderSides() : React.createElement("div", null, renderSides());
}
export default PolygonOptions;
