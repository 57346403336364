// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-views-monitor-MonitorTabExtraContent-module__btn--q-wzg{font-size:20px;margin:0 0 0 12px;border:none;background:none}.src-web-app-views-monitor-MonitorTabExtraContent-module__btn--q-wzg:disabled{opacity:.3}.src-web-app-views-monitor-MonitorTabExtraContent-module__btn--q-wzg:not(:disabled):hover{color:#4096ff}", "",{"version":3,"sources":["webpack://./src/web/app/views/monitor/MonitorTabExtraContent.module.scss"],"names":[],"mappings":"AAAA,qEACE,cAAA,CACA,iBAAA,CACA,WAAA,CACA,eAAA,CAEA,8EACE,UAAA,CAIA,0FACE,aAAA","sourcesContent":[".btn {\n  font-size: 20px;\n  margin: 0 0 0 12px;\n  border: none;\n  background: none;\n\n  &:disabled {\n    opacity: 0.3;\n  }\n\n  &:not(:disabled) {\n    &:hover {\n      color: #4096ff;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": "src-web-app-views-monitor-MonitorTabExtraContent-module__btn--q-wzg"
};
export default ___CSS_LOADER_EXPORT___;
