// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-components-dialogs-updateFontConvert-module__message--APtT1{white-space:break-spaces}.src-web-app-components-dialogs-updateFontConvert-module__button--pz6AG{padding:0;margin-top:10px}", "",{"version":3,"sources":["webpack://./src/web/app/components/dialogs/updateFontConvert.module.scss"],"names":[],"mappings":"AAAA,yEACE,wBAAA,CAGF,wEACE,SAAA,CACA,eAAA","sourcesContent":[".message {\n  white-space: break-spaces;\n}\n\n.button {\n  padding: 0;\n  margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"message": "src-web-app-components-dialogs-updateFontConvert-module__message--APtT1",
	"button": "src-web-app-components-dialogs-updateFontConvert-module__button--pz6AG"
};
export default ___CSS_LOADER_EXPORT___;
