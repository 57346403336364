import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { HashRouter, Route, Switch } from 'react-router-dom';
import AlertsAndProgress from 'app/views/dialogs/AlertAndProgress';
import Beambox from 'app/pages/Beambox';
import ConnectEthernet from 'app/pages/ConnectEthernet';
import ConnectMachineIp from 'app/pages/ConnectMachineIp';
import ConnectUsb from 'app/pages/ConnectUsb';
import ConnectWiFi from 'app/pages/ConnectWiFi';
import ConnectWired from 'app/pages/ConnectWired';
import Dialog from 'app/views/dialogs/Dialog';
import Error from 'app/pages/Error';
import FacebookOAuth from 'app/pages/FacebookOAuth';
import FluxIdLogin from 'app/pages/FluxIdLogin';
import GoogleOAuth from 'app/pages/GoogleOAuth';
import Home from 'app/pages/Home';
import Settings from 'app/pages/Settings';
import SelectConnectionType from 'app/pages/SelectConnectionType';
import SelectMachineModel from 'app/pages/SelectMachineModel';
import { AlertProgressContextProvider } from 'app/contexts/AlertProgressContext';
import { DialogContextProvider } from 'app/contexts/DialogContext';
import { StyleProvider } from '@ant-design/cssinjs';
import { ConfigProvider, message, theme } from 'antd';
import enUS from 'antd/locale/en_US';
import deDE from 'antd/locale/de_DE';
import nlNL from 'antd/locale/nl_NL';
import nlBE from 'antd/locale/nl_BE';
import itIT from 'antd/locale/it_IT';
import frFR from 'antd/locale/fr_FR';
import zhTW from 'antd/locale/zh_TW';
import koKR from 'antd/locale/ko_KR';
import jaJP from 'antd/locale/ja_JP';
import plPL from 'antd/locale/pl_PL';
import daDK from 'antd/locale/da_DK';
import elGR from 'antd/locale/el_GR';
import fiFI from 'antd/locale/fi_FI';
import idID from 'antd/locale/id_ID';
import msMY from 'antd/locale/ms_MY';
import nbNO from 'antd/locale/nb_NO';
import svSE from 'antd/locale/sv_SE';
import thTH from 'antd/locale/th_TH';
import viVN from 'antd/locale/vi_VN';
const { defaultAlgorithm } = theme;
const localeMap = {
    'nl-NL': nlNL,
    'nl-BE': nlBE,
    'zh-TW': zhTW,
    'ko-KR': koKR,
    'ja-JP': jaJP,
    'fr-FR': frFR,
    'it-IT': itIT,
    'de-DE': deDE,
    'en-US': enUS,
    'pl-PL': plPL,
    'da_DK': daDK,
    'el_GR': elGR,
    'fi_FI': fiFI,
    'id_ID': idID,
    'ms_MY': msMY,
    'nb_NO': nbNO,
    'sv_SE': svSE,
    'th_TH': thTH,
    'vi_VN': viVN,
};
console.log('Loading language', navigator.language);
const App = () => {
    const [messageApi, contextHolder] = message.useMessage();
    return (React.createElement(AlertProgressContextProvider, { messageApi: messageApi },
        React.createElement(DialogContextProvider, null,
            React.createElement(ConfigProvider, { theme: {
                    algorithm: defaultAlgorithm,
                    token: { screenMD: 601, screenMDMin: 601, screenSMMax: 600 },
                    components: {
                        Message: {
                            // set this value because zIndex of windows custom title bar is 99999
                            zIndexPopup: 100000,
                        }
                    }
                }, locale: localeMap[navigator.language] },
                React.createElement(StyleProvider, { hashPriority: "high" },
                    React.createElement(Dialog, null),
                    React.createElement(AlertsAndProgress, null),
                    contextHolder,
                    React.createElement(HashRouter, null,
                        React.createElement(Switch, null,
                            React.createElement(Route, { exact: true, path: "/google-auth", component: GoogleOAuth }),
                            React.createElement(Route, { exact: true, path: "/fb-auth", component: FacebookOAuth }),
                            React.createElement(Route, { exact: true, path: "/initialize/connect/select-connection-type", component: SelectConnectionType }),
                            React.createElement(Route, { exact: true, path: "/initialize/connect/select-machine-model", component: SelectMachineModel }),
                            React.createElement(Route, { exact: true, path: "/initialize/connect/connect-machine-ip", component: ConnectMachineIp }),
                            React.createElement(Route, { exact: true, path: "/initialize/connect/connect-usb", component: ConnectUsb }),
                            React.createElement(Route, { exact: true, path: "/initialize/connect/connect-wi-fi", component: ConnectWiFi }),
                            React.createElement(Route, { exact: true, path: "/initialize/connect/connect-wired", component: ConnectWired }),
                            React.createElement(Route, { exact: true, path: "/initialize/connect/connect-ethernet", component: ConnectEthernet }),
                            React.createElement(Route, { exact: true, path: "/initialize/connect/flux-id-login", component: FluxIdLogin }),
                            React.createElement(Route, { exact: true, path: "/studio/settings", component: Settings }),
                            React.createElement(Route, { exact: true, path: "/studio/beambox", component: Beambox }),
                            React.createElement(Route, { path: "/error/*", component: Error }),
                            React.createElement(Route, { path: "*", component: Home }))))))));
};
const router = (container) => {
    const root = createRoot(container);
    root.render(React.createElement(App, null));
};
export default router;
