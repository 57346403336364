import * as React from 'react';
import eventEmitterFactory from 'helpers/eventEmitterFactory';
import isWeb from 'helpers/is-web';
export const DialogContext = React.createContext({
    dialogComponents: [],
});
export const eventEmitter = eventEmitterFactory.createEventEmitter();
export class DialogContextProvider extends React.Component {
    constructor(props) {
        super(props);
        this.addDialogComponent = (id, dialogComponent) => {
            this.dialogComponents.push({ id, component: dialogComponent });
            this.forceUpdate();
        };
        this.isIdExist = (id, response) => {
            response.isIdExist = this.dialogComponents.some((dialog) => dialog.id === id);
        };
        this.popDialogById = (id) => {
            this.dialogComponents = this.dialogComponents.filter((dialog) => dialog.id !== id);
            this.forceUpdate();
        };
        this.clearAllDialogComponents = () => {
            this.dialogComponents = [];
            this.forceUpdate();
        };
        this.dialogComponents = [];
    }
    componentDidMount() {
        eventEmitter.on('ADD_DIALOG_COMPONENT', this.addDialogComponent.bind(this));
        eventEmitter.on('CLEAR_ALL_DIALOG_COMPONENTS', this.clearAllDialogComponents.bind(this));
        eventEmitter.on('CHECK_ID_EXIST', this.isIdExist.bind(this));
        eventEmitter.on('POP_DIALOG_BY_ID', this.popDialogById.bind(this));
        if (isWeb()) {
            window.addEventListener('DISMISS_FLUX_LOGIN', () => {
                this.popDialogById.call(this, 'flux-id-login');
            });
        }
    }
    componentWillUnmount() {
        eventEmitter.removeAllListeners();
    }
    render() {
        const { children } = this.props;
        return (React.createElement(DialogContext.Provider, { value: {
                dialogComponents: this.dialogComponents,
            } }, children));
    }
}
