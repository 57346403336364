import eventEmitterFactory from 'helpers/eventEmitterFactory';
const rightPanelEventEmitter = eventEmitterFactory.createEventEmitter('right-panel');
const setDisplayLayer = (val) => {
    rightPanelEventEmitter.emit('DISPLAY_LAYER', val);
};
const updatePathEditPanel = () => {
    rightPanelEventEmitter.emit('UPDATE_PATH_EDIT_PANEL');
};
const setPanelType = (val) => {
    rightPanelEventEmitter.emit('SET_PANEL_TYPE', val);
};
export default {
    setDisplayLayer,
    setPanelType,
    updatePathEditPanel,
};
