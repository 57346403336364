import React, { useContext } from 'react';
import { Col, Progress, Row } from 'antd';
import { ClockCircleOutlined, FileOutlined } from '@ant-design/icons';
import DeviceConstants from 'app/constants/device-constants';
import FormatDuration from 'helpers/duration-formatter';
import i18n from 'helpers/i18n';
import { Mode } from 'app/constants/monitor-constants';
import { MonitorContext } from 'app/contexts/MonitorContext';
import MonitorControl from './MonitorControl';
import styles from './MonitorTask.module.scss';
const defaultImage = 'img/ph_l.png';
const LANG = i18n.lang;
const MonitorTask = () => {
    const { taskTime, mode, report, uploadProgress, taskImageURL, fileInfo, previewTask, } = useContext(MonitorContext);
    const getJobTime = () => {
        if (mode === Mode.WORKING && report && report.prog) {
            const timeLeft = FormatDuration(taskTime * (1 - report.prog));
            return `${timeLeft} ${i18n.lang.monitor.left}`;
        }
        return taskTime ? FormatDuration(taskTime) : null;
    };
    const renderProgress = () => {
        if (uploadProgress !== null) {
            return (React.createElement(Progress, { percent: Number(uploadProgress), status: "active", strokeColor: { from: '#108ee9', to: '#87d068' } }));
        }
        if (!report)
            return null;
        if (report.st_id === DeviceConstants.status.COMPLETED) {
            return React.createElement(Progress, { percent: 100 });
        }
        if (!report.prog) {
            return null;
        }
        const percentageDone = Number((report.prog * 100).toFixed(1));
        if (report.st_id === DeviceConstants.status.ABORTED) {
            return React.createElement(Progress, { percent: percentageDone, status: "exception" });
        }
        return (React.createElement(Progress, { percent: percentageDone, status: "active", strokeColor: { from: '#108ee9', to: '#87d068' } }));
    };
    const renderFileInfo = () => {
        const fileName = fileInfo ? fileInfo[0] : previewTask === null || previewTask === void 0 ? void 0 : previewTask.fileName;
        return (React.createElement("div", { className: styles['left-text'] },
            React.createElement(FileOutlined, null),
            "\u00A0",
            fileName || LANG.monitor.task.BEAMBOX));
    };
    return (React.createElement("div", { className: styles.task },
        React.createElement("div", { className: styles['info-container'] },
            React.createElement("img", { src: taskImageURL || defaultImage }),
            React.createElement("div", { className: styles['info-bar'] },
                React.createElement(Row, null,
                    React.createElement(Col, { span: 24, md: 12 }, renderFileInfo()),
                    React.createElement(Col, { span: 24, md: 12 },
                        React.createElement("div", { className: styles['right-text'] },
                            React.createElement(ClockCircleOutlined, null),
                            "\u00A0",
                            getJobTime()))))),
        React.createElement(Row, null,
            React.createElement(Col, { span: 24, md: 12 }, renderProgress()),
            React.createElement(Col, { span: 24, md: 12 },
                React.createElement("div", { className: styles['control-buttons'] },
                    React.createElement(MonitorControl, null))))));
};
export default MonitorTask;
