export var PanelType;
(function (PanelType) {
    PanelType[PanelType["None"] = 0] = "None";
    PanelType[PanelType["Layer"] = 1] = "Layer";
    PanelType[PanelType["Object"] = 2] = "Object";
    PanelType[PanelType["PathEdit"] = 3] = "PathEdit";
})(PanelType || (PanelType = {}));
export default {
    PanelType,
};
