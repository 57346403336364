import storage from 'implementations/storage';
function socialAuth(result) {
    var _a, _b;
    if (result) {
        if (((_a = window.opener) === null || _a === void 0 ? void 0 : _a.location.hash) === '#/initialize/connect/flux-id-login') {
            const isReady = storage.get('printer-is-ready');
            window.opener.location.hash = isReady ? '#studio/beambox' : '#initialize/connect/select-machine-model';
        }
        (_b = window.opener) === null || _b === void 0 ? void 0 : _b.dispatchEvent(new CustomEvent('DISMISS_FLUX_LOGIN'));
        window.close();
    }
}
export default socialAuth;
