import constant from 'app/actions/beambox/constant';
export const getValue = (dimensionValues, type, opts = {}) => {
    const { unit = 'px', allowUndefined = false } = opts;
    let val;
    if (type === 'w')
        val = dimensionValues === null || dimensionValues === void 0 ? void 0 : dimensionValues.width;
    else if (type === 'h')
        val = dimensionValues === null || dimensionValues === void 0 ? void 0 : dimensionValues.height;
    else if (type === 'rx')
        val = (dimensionValues === null || dimensionValues === void 0 ? void 0 : dimensionValues.rx) ? (dimensionValues === null || dimensionValues === void 0 ? void 0 : dimensionValues.rx) * 2 : dimensionValues === null || dimensionValues === void 0 ? void 0 : dimensionValues.rx;
    else if (type === 'ry')
        val = (dimensionValues === null || dimensionValues === void 0 ? void 0 : dimensionValues.ry) ? (dimensionValues === null || dimensionValues === void 0 ? void 0 : dimensionValues.ry) * 2 : dimensionValues === null || dimensionValues === void 0 ? void 0 : dimensionValues.ry;
    else
        val = dimensionValues === null || dimensionValues === void 0 ? void 0 : dimensionValues[type];
    if (val === undefined) {
        if (allowUndefined)
            return undefined;
        val = 0;
    }
    if (unit === 'px')
        return val;
    val /= constant.dpmm;
    if (unit === 'in')
        val /= 25.4;
    return val;
};
export default {};
