import React, { useContext } from 'react';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { Mode, ItemType } from 'app/constants/monitor-constants';
import { MonitorContext } from 'app/contexts/MonitorContext';
import styles from './MonitorTabExtraContent.module.scss';
const MonitorTabExtraContent = () => {
    const { currentPath, mode, highlightedItem, onDownload, showUploadDialog } = useContext(MonitorContext);
    if (mode !== Mode.FILE)
        return null;
    const canDownload = (highlightedItem === null || highlightedItem === void 0 ? void 0 : highlightedItem.type) === ItemType.FILE;
    const canUpload = currentPath.length > 0;
    return (React.createElement(React.Fragment, null,
        React.createElement("button", { type: "button", className: styles.btn, disabled: !canUpload, onClick: showUploadDialog },
            React.createElement(UploadOutlined, null)),
        React.createElement("button", { type: "button", className: styles.btn, disabled: !canDownload, onClick: onDownload },
            React.createElement(DownloadOutlined, null))));
};
export default MonitorTabExtraContent;
