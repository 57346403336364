var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { createContext, useMemo, useState } from 'react';
import beamboxPreference from 'app/actions/beambox/beambox-preference';
import { getWorkarea } from 'app/constants/workarea-constants';
import sliceWorkarea from './sliceWorkarea';
export const PassThroughContext = createContext({
    workarea: 'ado1',
    workareaObj: getWorkarea('ado1'),
    passThroughHeight: 120,
    setPassThroughHeight: () => { },
    referenceLayer: false,
    setReferenceLayer: () => { },
    guideMark: { show: false, x: 0, width: 40 },
    setGuideMark: () => { },
    handleExport: () => __awaiter(void 0, void 0, void 0, function* () { }),
});
export function PassThroughProvider({ children }) {
    var _a;
    const workarea = useMemo(() => beamboxPreference.read('workarea'), []);
    const workareaObj = useMemo(() => getWorkarea(workarea), [workarea]);
    const [guideMark, setGuideMark] = useState({ show: false, x: workareaObj.width - 40, width: 40 });
    const [passThroughHeight, setPassThroughHeight] = useState((_a = workareaObj.passThroughMaxHeight) !== null && _a !== void 0 ? _a : workareaObj.height);
    const [referenceLayer, setReferenceLayer] = useState(false);
    const handleExport = () => __awaiter(this, void 0, void 0, function* () { return sliceWorkarea(passThroughHeight, { refLayers: referenceLayer, guideMark }); });
    return (React.createElement(PassThroughContext.Provider, { value: {
            workarea,
            workareaObj,
            passThroughHeight,
            setPassThroughHeight,
            referenceLayer,
            setReferenceLayer,
            guideMark,
            setGuideMark,
            handleExport,
        } }, children));
}
