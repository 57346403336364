var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import alertCaller from 'app/actions/alert-caller';
import alertConstants from 'app/constants/alert-constants';
import fileExportHelper from 'helpers/file-export-helper';
import i18n from 'helpers/i18n';
import isWeb from 'helpers/is-web';
import ObjectPanelController from 'app/views/beambox/Right-Panels/contexts/ObjectPanelController';
import { checkConnection } from 'helpers/api/discover';
const webNeedConnectionWrapper = (callback) => {
    if (isWeb() && !checkConnection()) {
        alertCaller.popUp({
            caption: i18n.lang.alert.oops,
            message: i18n.lang.device_selection.no_device_web,
            buttonType: alertConstants.CUSTOM_CANCEL,
            buttonLabels: [i18n.lang.topbar.menu.add_new_machine],
            callbacks: () => __awaiter(void 0, void 0, void 0, function* () {
                ObjectPanelController.updateActiveKey(null);
                const res = yield fileExportHelper.toggleUnsavedChangedDialog();
                if (res)
                    window.location.hash = '#initialize/connect/select-machine-model';
            }),
        });
        return null;
    }
    return callback();
};
export default webNeedConnectionWrapper;
