// intentionally set testing states odd, failed and completed states even
var TestState;
(function (TestState) {
    TestState[TestState["NONE"] = 0] = "NONE";
    TestState[TestState["IP_FORMAT_ERROR"] = 2] = "IP_FORMAT_ERROR";
    TestState[TestState["IP_TESTING"] = 3] = "IP_TESTING";
    TestState[TestState["IP_UNREACHABLE"] = 4] = "IP_UNREACHABLE";
    TestState[TestState["CONNECTION_TESTING"] = 5] = "CONNECTION_TESTING";
    TestState[TestState["CONNECTION_TEST_FAILED"] = 6] = "CONNECTION_TEST_FAILED";
    TestState[TestState["CAMERA_TESTING"] = 7] = "CAMERA_TESTING";
    TestState[TestState["CAMERA_TEST_FAILED"] = 8] = "CAMERA_TEST_FAILED";
    TestState[TestState["TEST_COMPLETED"] = 10] = "TEST_COMPLETED";
})(TestState || (TestState = {}));
export const isTesting = (state) => state % 2 === 1;
export default TestState;
