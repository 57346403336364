var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import classNames from 'classnames';
import React from 'react';
import { Slider, Tooltip } from 'antd';
import deviceMaster from 'helpers/device-master';
import progressCaller from 'app/actions/progress-caller';
import useI18n from 'helpers/useI18n';
import WorkareaIcons from 'app/icons/workarea/WorkareaIcons';
import styles from './ExposureSlider.module.scss';
const ExposureSlider = ({ className, exposureSetting, setExposureSetting, onChanged, }) => {
    const lang = useI18n();
    if (!exposureSetting) {
        return null;
    }
    return (React.createElement("div", { className: classNames(styles.container, className) },
        React.createElement(Tooltip, { title: lang.editor.exposure },
            React.createElement(WorkareaIcons.Exposure, { className: styles.icon })),
        React.createElement(Slider, { className: styles.slider, min: Math.max(exposureSetting.min, 250), max: Math.min(exposureSetting.max, 650), step: exposureSetting.step, value: exposureSetting.value, onChange: (value) => setExposureSetting(Object.assign(Object.assign({}, exposureSetting), { value })), onAfterChange: (value) => __awaiter(void 0, void 0, void 0, function* () {
                try {
                    progressCaller.openNonstopProgress({ id: 'exposure-slider' });
                    setExposureSetting(Object.assign(Object.assign({}, exposureSetting), { value }));
                    yield deviceMaster.setDeviceSetting('camera_exposure_absolute', value.toString());
                    onChanged();
                }
                finally {
                    progressCaller.popById('exposure-slider');
                }
            }), tooltip: { open: false } })));
};
export default ExposureSlider;
