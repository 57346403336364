var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useEffect, useRef, useState } from 'react';
import alertCaller from 'app/actions/alert-caller';
import deviceMaster from 'helpers/device-master';
import progressCaller from 'app/actions/progress-caller';
import i18n from 'helpers/i18n';
import webcamHelper from 'helpers/webcam-helper';
const useCamera = (handleImg, source = 'wifi') => {
    const [exposureSetting, setExposureSetting] = useState(null);
    const webCamConnection = useRef(null);
    const connectWebCam = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (webCamConnection.current)
            return;
        try {
            webCamConnection.current = yield webcamHelper.connectWebcam();
        }
        catch (error) {
            alertCaller.popUpError({ message: `Failed to connect to ${error.message}` });
        }
    }), []);
    const handleTakePicture = useCallback((opts) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        const { retryTimes = 0, silent = false } = opts || {};
        if (!silent)
            progressCaller.openNonstopProgress({
                id: 'use-camera',
                message: i18n.lang.calibration.taking_picture,
            });
        let imgBlob;
        if (source === 'wifi') {
            imgBlob = (_a = (yield deviceMaster.takeOnePicture())) === null || _a === void 0 ? void 0 : _a.imgBlob;
        }
        else if (source === 'usb') {
            if (!webCamConnection.current)
                yield connectWebCam();
            imgBlob = yield ((_b = webCamConnection.current) === null || _b === void 0 ? void 0 : _b.getPicture());
        }
        if (!imgBlob) {
            if (retryTimes < 2)
                return handleTakePicture({ retryTimes: retryTimes + 1, silent });
            alertCaller.popUpError({ message: 'Unable to get image' });
            if (!silent)
                progressCaller.popById('use-camera');
            return null;
        }
        if (handleImg) {
            const res = yield handleImg(imgBlob);
            if (!res && retryTimes < 2)
                return handleTakePicture({ retryTimes: retryTimes + 1, silent });
        }
        if (!silent)
            progressCaller.popById('use-camera');
        return imgBlob;
    }), [connectWebCam, handleImg, source]);
    useEffect(() => {
        const initSetup = () => __awaiter(void 0, void 0, void 0, function* () {
            progressCaller.openNonstopProgress({
                id: 'use-camera',
                message: i18n.lang.calibration.taking_picture,
            });
            try {
                if (source === 'wifi') {
                    yield deviceMaster.connectCamera();
                    try {
                        const exposureRes = yield deviceMaster.getDeviceSetting('camera_exposure_absolute');
                        setExposureSetting(JSON.parse(exposureRes.value));
                    }
                    catch (e) {
                        console.log('Failed to get exposure setting', e);
                    }
                    handleTakePicture();
                }
                else if (source === 'usb') {
                    yield connectWebCam();
                    if (webCamConnection.current)
                        handleTakePicture();
                }
            }
            finally {
                progressCaller.popById('use-camera');
            }
        });
        initSetup();
        return () => {
            var _a;
            if (source === 'wifi')
                deviceMaster.disconnectCamera();
            else if (source === 'usb')
                (_a = webCamConnection.current) === null || _a === void 0 ? void 0 : _a.end();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return { exposureSetting, setExposureSetting, handleTakePicture };
};
export default useCamera;
