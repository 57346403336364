import React, { useRef, useContext } from 'react';
import * as THREE from 'three';
import { Stage, Edges } from '@react-three/drei';
import { Vector3 } from 'three';
import Canvas from 'app/widgets/three/Canvas';
import { getTopBottomShape, getFrontBackShape, getLeftRightShape, } from 'app/components/boxgen/Shape';
import { BoxgenContext } from 'app/contexts/BoxgenContext';
const BoxFace = ({ position, type, }) => {
    const { boxData } = useContext(BoxgenContext);
    const ref = useRef(null);
    const extrudeRef = useRef(null);
    const extrudeSettings = {
        steps: 2,
        depth: boxData.sheetThickness,
        bevelEnabled: false,
    };
    let rotation;
    let shape;
    switch (type) {
        case 'TopBottom':
            rotation = new THREE.Euler(Math.PI / 2, 0, 0, 'XYZ');
            shape = getTopBottomShape(Object.assign(Object.assign({}, boxData), { width: boxData.width, height: boxData.depth }));
            break;
        case 'FrontBack':
            rotation = new THREE.Euler(0, 0, Math.PI, 'XYZ');
            shape = getFrontBackShape(Object.assign(Object.assign({}, boxData), { width: boxData.width, height: boxData.height }));
            break;
        case 'LeftRight':
        default:
            rotation = new THREE.Euler(0, Math.PI / 2, Math.PI, 'XYZ');
            shape = getLeftRightShape(Object.assign(Object.assign({}, boxData), { width: boxData.depth, height: boxData.height }));
            break;
    }
    return (
    // eslint-disable-next-line react/no-unknown-property
    React.createElement("mesh", { position: position, rotation: rotation, ref: ref },
        React.createElement("extrudeGeometry", { ref: extrudeRef, args: [shape.shape, extrudeSettings] }),
        React.createElement("meshNormalMaterial", null),
        React.createElement(Edges, null)));
};
const BoxCanvas = () => {
    const { boxData } = useContext(BoxgenContext);
    return (React.createElement(Canvas, { camera: {
            fov: 55,
            near: 0.1,
            far: 1000,
            position: [150, 150, 150],
        } },
        React.createElement(Stage, { adjustCamera: 1.9, shadows: false, environment: null },
            React.createElement(BoxFace, { type: "TopBottom", position: new Vector3(0, boxData.sheetThickness, 0) }),
            boxData.cover ? (React.createElement(BoxFace, { type: "TopBottom", position: new Vector3(0, boxData.height, 0) })) : null,
            React.createElement(BoxFace, { type: "FrontBack", position: new Vector3(0, boxData.height / 2, -boxData.depth / 2) }),
            React.createElement(BoxFace, { type: "FrontBack", position: new Vector3(0, boxData.height / 2, boxData.depth / 2 - boxData.sheetThickness) }),
            React.createElement(BoxFace, { type: "LeftRight", position: new Vector3(-boxData.width / 2, boxData.height / 2, 0) }),
            React.createElement(BoxFace, { type: "LeftRight", position: new Vector3(boxData.width / 2 - boxData.sheetThickness, boxData.height / 2, 0) }),
            React.createElement("gridHelper", { 
                // eslint-disable-next-line react/no-unknown-property
                args: [
                    Math.max(boxData.width, boxData.height, boxData.depth) + 20,
                    (Math.max(boxData.width, boxData.height, boxData.depth) + 20) / 10,
                ] }))));
};
export default BoxCanvas;
