import classNames from 'classnames';
import React, { useMemo } from 'react';
import { Collapse } from 'antd';
import constant from 'app/actions/beambox/constant';
import useI18n from 'helpers/useI18n';
import styles from './ConnectWired.module.scss';
const ConnectWired = () => {
    const lang = useI18n().initialize;
    const { model } = useMemo(() => {
        const queryString = window.location.hash.split('?')[1] || '';
        const urlParams = new URLSearchParams(queryString);
        return {
            model: urlParams.get('model'),
        };
    }, []);
    const handleNext = () => {
        const urlParams = new URLSearchParams({ model, wired: '1' });
        const queryString = urlParams.toString();
        window.location.hash = `#initialize/connect/connect-machine-ip?${queryString}`;
    };
    const isAdor = useMemo(() => constant.adorModels.includes(model), [model]);
    return (React.createElement("div", { className: styles.container },
        React.createElement("div", { className: styles['top-bar'] }),
        React.createElement("div", { className: styles.btns },
            React.createElement("div", { className: styles.btn, onClick: () => window.history.back() }, lang.back),
            React.createElement("div", { className: classNames(styles.btn, styles.primary), onClick: handleNext }, lang.next)),
        React.createElement("div", { className: classNames(styles.main, { [styles.ador]: isAdor }) },
            React.createElement("div", { className: styles.image },
                React.createElement("div", { className: styles.hint }),
                React.createElement("img", { src: isAdor ? 'core-img/init-panel/ador-network.jpg' : 'img/init-panel/touch-panel-en.jpg', draggable: "false" })),
            React.createElement("div", { className: styles.text },
                React.createElement("div", { className: styles.title }, lang.connect_wired.title),
                React.createElement("div", { className: classNames(styles.contents, styles.tutorial) },
                    React.createElement("div", null, lang.connect_wired.tutorial1),
                    React.createElement("div", null, isAdor ? lang.connect_wired.tutorial2_ador : lang.connect_wired.tutorial2)),
                React.createElement(Collapse, { accordion: true, items: [
                        {
                            key: '1',
                            label: lang.connect_wired.what_if_1,
                            children: (React.createElement("div", { className: classNames(styles.contents, styles.collapse) }, lang.connect_wired.what_if_1_content)),
                        },
                        {
                            key: '2',
                            label: lang.connect_wired.what_if_2,
                            children: (React.createElement("div", { className: classNames(styles.contents, styles.collapse) }, lang.connect_wired.what_if_2_content)),
                        },
                    ] })))));
};
export default ConnectWired;
