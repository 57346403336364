const namingMap = {
    strength: 'P',
    speed: 'S',
    repeat: 'C',
};
export default function generateSvgInfo({ tableSetting, blockSetting: { column: { count: { value: colLength }, }, row: { count: { value: rowLength }, }, }, }) {
    const [col, row, third] = Object.entries(tableSetting).sort(([, { selected: a }], [, { selected: b }]) => a - b);
    const generateRange = (length, { minValue, maxValue }) => Array.from({ length }, (_, i) => Math.ceil(minValue + ((maxValue - minValue) / (length - 1)) * i));
    const colRange = generateRange(colLength, col[1]);
    const rowRange = generateRange(rowLength, row[1]);
    return colRange.flatMap((c) => rowRange.map((r) => ({
        name: `${namingMap[col[0]]}${c}-${namingMap[row[0]]}${r}`,
        [col[0]]: c,
        [row[0]]: r,
        [third[0]]: third[1].default,
    })));
}
