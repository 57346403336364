export var RotaryType;
(function (RotaryType) {
    RotaryType[RotaryType["Roller"] = 1] = "Roller";
    RotaryType[RotaryType["Chuck"] = 2] = "Chuck";
})(RotaryType || (RotaryType = {}));
export const CHUCK_ROTARY_DIAMETER = 133;
const supportList = {
    fbm1: {
        autoFocus: true,
        hybridLaser: true,
        openBottom: true,
        passThrough: true,
        rotary: {
            roller: true,
            chuck: true,
            extendWorkarea: false,
            mirror: false,
        },
        lowerFocus: false,
    },
    fbb1b: {
        autoFocus: false,
        hybridLaser: false,
        openBottom: false,
        passThrough: false,
        rotary: {
            roller: true,
            chuck: false,
            extendWorkarea: false,
            mirror: false,
        },
        lowerFocus: false,
    },
    fbb1p: {
        autoFocus: false,
        hybridLaser: false,
        openBottom: false,
        passThrough: false,
        rotary: {
            roller: true,
            chuck: false,
            extendWorkarea: false,
            mirror: false,
        },
        lowerFocus: false,
    },
    fhexa1: {
        autoFocus: false,
        hybridLaser: false,
        openBottom: false,
        passThrough: false,
        rotary: {
            roller: true,
            chuck: true,
            extendWorkarea: false,
            mirror: false,
        },
        lowerFocus: true,
    },
    ado1: {
        autoFocus: false,
        hybridLaser: false,
        openBottom: false,
        passThrough: true,
        rotary: {
            roller: true,
            chuck: true,
            extendWorkarea: true,
            mirror: true,
            defaultMirror: true,
        },
        lowerFocus: true,
        framingLowLaser: true,
    },
    fbb2: {
        autoFocus: false,
        hybridLaser: false,
        openBottom: false,
        passThrough: true,
        rotary: {
            roller: true,
            chuck: true,
            extendWorkarea: true,
            mirror: true,
        },
        lowerFocus: true,
        redLight: true,
    },
    fpm1: {
        autoFocus: false,
        hybridLaser: false,
        openBottom: false,
        passThrough: false,
        rotary: {
            roller: true,
            chuck: false,
            extendWorkarea: false,
            mirror: false,
        },
        lowerFocus: false,
    },
    flv1: {
        autoFocus: false,
        hybridLaser: false,
        openBottom: false,
        passThrough: false,
        rotary: {
            roller: true,
            chuck: false,
            extendWorkarea: false,
            mirror: false,
        },
        lowerFocus: false,
    },
};
export const getSupportInfo = (workarea) => supportList[workarea] || {
    autoFocus: false,
    hybridLaser: false,
    openBottom: false,
    passThrough: false,
    lowerFocus: false,
};
export default {
    getSupportInfo,
    supportList,
};
