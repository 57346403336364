/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import beamboxPreference from 'app/actions/beambox/beambox-preference';
import { BaseHistoryCommand } from './history';
class BeamboxPreferenceCommand extends BaseHistoryCommand {
    constructor(key, oldValue, newValue) {
        super();
        this.elements = () => [];
        this.type = () => 'BeamboxPreferenceCommand';
        this.doApply = () => {
            beamboxPreference.write(this.key, this.newValue);
        };
        this.doUnapply = () => {
            beamboxPreference.write(this.key, this.oldValue);
        };
        this.key = key;
        this.oldValue = oldValue;
        this.newValue = newValue;
    }
}
export const changeBeamboxPreferenceValue = (key, value, opts = {}) => {
    const { parentCmd } = opts;
    const oldValue = beamboxPreference.read(key);
    beamboxPreference.write(key, value);
    const cmd = new BeamboxPreferenceCommand(key, oldValue, value);
    if (parentCmd)
        parentCmd.addSubCommand(cmd);
    return cmd;
};
export default BeamboxPreferenceCommand;
