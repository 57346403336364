var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import history from 'app/svgedit/history/history';
import requirejsHelper from 'helpers/requirejs-helper';
import { getSVGAsync } from 'helpers/svg-editor-helper';
import { moveElements } from 'app/svgedit/operations/move';
let svgCanvas;
getSVGAsync((globalSVG) => {
    svgCanvas = globalSVG.Canvas;
});
const traceAndImportPath = (imgBase64, dimension) => __awaiter(void 0, void 0, void 0, function* () {
    const ImageTracer = yield requirejsHelper('imagetracer');
    const { x, y, width, height } = dimension;
    return new Promise((resolve) => {
        ImageTracer.imageToSVG(imgBase64, (svgstr) => {
            const gId = svgCanvas.getNextId();
            const batchCmd = new history.BatchCommand('Add Image Trace');
            const g = svgCanvas.addSvgElementFromJson({ element: 'g', attr: { id: gId } });
            const path = svgCanvas.addSvgElementFromJson({
                element: 'path',
                attr: {
                    id: svgCanvas.getNextId(),
                    fill: '#000000',
                    'stroke-width': 1,
                    'vector-effect': 'non-scaling-stroke',
                },
            });
            path.addEventListener('mouseover', svgCanvas.handleGenerateSensorArea);
            path.addEventListener('mouseleave', svgCanvas.handleGenerateSensorArea);
            batchCmd.addSubCommand(new history.InsertElementCommand(path));
            svgCanvas.selectOnly([g]);
            ImageTracer.appendSVGString(svgstr.replace(/<\/?svg[^>]*>/g, ''), gId);
            const gBBox = g.getBBox();
            if (width !== gBBox.width)
                svgCanvas.setSvgElemSize('width', width);
            if (height !== gBBox.height)
                svgCanvas.setSvgElemSize('height', height);
            let d = '';
            for (let i = 0; i < g.childNodes.length; i += 1) {
                const child = g.childNodes[i];
                if (child.getAttribute('opacity') !== '0') {
                    d += child.getAttribute('d');
                }
                child.remove();
                i -= 1;
            }
            g.remove();
            path.setAttribute('d', d);
            moveElements([x], [y], [path], false);
            svgCanvas.selectOnly([path], true);
            svgCanvas.undoMgr.addCommandToHistory(batchCmd);
            resolve(true);
        });
    });
});
export default traceAndImportPath;
