/* eslint-disable react/no-danger */
import * as React from 'react';
import { Col, Modal, Row } from 'antd';
import i18n from 'helpers/i18n';
const LANG = i18n.lang.topmenu;
const { FLUX } = window;
const VISIBLE = true;
function AboutBeamStudio({ onClose }) {
    return (React.createElement(Modal, { open: VISIBLE, centered: true, onCancel: onClose, okText: LANG.ok, onOk: onClose, cancelButtonProps: { style: { display: 'none' } } },
        React.createElement(Row, { gutter: 10 },
            React.createElement(Col, { span: 7 },
                React.createElement("img", { width: 150, src: "img/icon.png", style: { float: 'left' } })),
            React.createElement(Col, { span: 12 },
                React.createElement("strong", null, "Beam Studio"),
                React.createElement("div", { className: "version" }, `${LANG.version} ${FLUX.version}`),
                React.createElement("div", { className: "copyright" }, `Copyright ⓒ ${new Date().getFullYear()} FLUX Inc.`),
                React.createElement("div", { className: "credit", dangerouslySetInnerHTML: { __html: LANG.credit } })))));
}
export default AboutBeamStudio;
