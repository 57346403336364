import eventEmitterFactory from 'helpers/eventEmitterFactory';
const layerPanelEventEmitter = eventEmitterFactory.createEventEmitter('layer-panel');
const updateLayerPanel = () => {
    layerPanelEventEmitter.emit('UPDATE_LAYER_PANEL');
};
const getSelectedLayers = () => {
    const response = {
        selectedLayers: [],
    };
    layerPanelEventEmitter.emit('GET_SELECTED_LAYERS', response);
    return response.selectedLayers;
};
const setSelectedLayers = (selectedLayers) => {
    layerPanelEventEmitter.emit('SET_SELECTED_LAYERS', selectedLayers);
};
const checkVector = () => {
    layerPanelEventEmitter.emit('CHECK_VECTOR');
};
export default {
    checkVector,
    updateLayerPanel,
    getSelectedLayers,
    setSelectedLayers,
};
