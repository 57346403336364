import * as React from 'react';
import alert from 'app/actions/alert-caller';
import alertConstants from 'app/constants/alert-constants';
import browser from 'implementations/browser';
import Controls from 'app/components/settings/Control';
import i18n from 'helpers/i18n';
import SelectControl from 'app/components/settings/SelectControl';
import storage from 'implementations/storage';
function Connection({ originalIP, guessingPokeOptions, autoConnectOptions, updateConfigChange, }) {
    const lang = i18n.lang;
    const checkIPFormat = (e) => {
        const me = e.currentTarget;
        const ips = me.value.split(/[,;] ?/);
        const ipv4Pattern = /^\d{1,3}[.]\d{1,3}[.]\d{1,3}[.]\d{1,3}$/;
        for (let i = 0; i < ips.length; i += 1) {
            const ip = ips[i];
            if (ip !== '' && typeof ip === 'string' && ipv4Pattern.test(ip) === false) {
                me.value = originalIP;
                alert.popUp({
                    id: 'wrong-ip-error',
                    type: alertConstants.SHOW_POPUP_ERROR,
                    message: `${lang.settings.wrong_ip_format}\n${ip}`,
                });
                return;
            }
        }
        updateConfigChange('poke-ip-addr', me.value);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "subtitle" },
            lang.settings.groups.connection,
            React.createElement("span", { className: "info-icon-medium" },
                React.createElement("img", { src: "img/info.svg", onClick: () => browser.open(lang.settings.help_center_urls.connection) }))),
        React.createElement(Controls, { label: lang.settings.ip },
            React.createElement("input", { id: "ip-input", type: "text", autoComplete: "false", defaultValue: storage.get('poke-ip-addr'), onBlur: checkIPFormat })),
        React.createElement(SelectControl, { id: "set-guessing-poke", label: lang.settings.guess_poke, options: guessingPokeOptions, onChange: (e) => updateConfigChange('guessing_poke', e.target.value) }),
        React.createElement(SelectControl, { id: "set-auto-connect", label: lang.settings.auto_connect, options: autoConnectOptions, onChange: (e) => updateConfigChange('auto_connect', e.target.value) })));
}
export default Connection;
