var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import deviceMaster from 'helpers/device-master';
import dialogCaller from 'app/actions/dialog-caller';
import progressCaller from 'app/actions/progress-caller';
import { getWorkarea } from 'app/constants/workarea-constants';
const PROGRESS_ID = 'get-height';
const getHeight = (device, progressId, defaultValue) => __awaiter(void 0, void 0, void 0, function* () {
    if (!progressId)
        progressCaller.openNonstopProgress({ id: PROGRESS_ID });
    try {
        progressCaller.update(progressId || PROGRESS_ID, { message: 'Getting probe position' });
        const res = yield deviceMaster.rawGetProbePos();
        const { z, didAf } = res;
        if (didAf) {
            const { deep } = getWorkarea(device.model, 'ado1');
            return Math.round((deep - z) * 100) / 100;
        }
    }
    catch (e) {
        console.log('Fail to get probe position, using custom height', e);
    }
    if (typeof (defaultValue) === 'number')
        return defaultValue;
    // hide progress for dialogCaller
    progressCaller.popById(progressId || PROGRESS_ID);
    const height = yield dialogCaller.getPreviewHeight({ initValue: undefined });
    return height;
});
export default getHeight;
