var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Icon from '@ant-design/icons';
import React, { useEffect } from 'react';
import ReactDomServer from 'react-dom/server';
import history from 'app/svgedit/history/history';
import HistoryCommandFactory from 'app/svgedit/history/HistoryCommandFactory';
import importSvgString from 'app/svgedit/operations/import/importSvgString';
import updateElementColor from 'helpers/color/updateElementColor';
import useForceUpdate from 'helpers/use-force-update';
import { builtInElements } from 'app/constants/shape-panel-constants';
import { getData } from 'helpers/layer/layer-config-helper';
import { getSVGAsync } from 'helpers/svg-editor-helper';
import { getLayerByName } from 'helpers/layer/layer-helper';
import styles from './ShapeIcon.module.scss';
let svgCanvas;
getSVGAsync((globalSVG) => {
    svgCanvas = globalSVG.Canvas;
});
const icons = {};
const importShape = (IconComponent, jsonMap) => __awaiter(void 0, void 0, void 0, function* () {
    if (jsonMap) {
        const newElement = svgCanvas.addSvgElementFromJson(Object.assign(Object.assign({}, jsonMap), { attr: Object.assign(Object.assign({}, jsonMap.attr), { id: svgCanvas.getNextId() }) }));
        svgCanvas.addCommandToHistory(new history.InsertElementCommand(newElement));
        if (svgCanvas.isUsingLayerColor) {
            updateElementColor(newElement);
        }
        svgCanvas.selectOnly([newElement]);
    }
    else {
        const iconString = ReactDomServer.renderToStaticMarkup(React.createElement(IconComponent, null)).replace(/fill= ?"#(fff(fff)?|FFF(FFF))"/g, 'fill="none"');
        const drawing = svgCanvas.getCurrentDrawing();
        const layerName = drawing.getCurrentLayerName();
        const layerModule = getData(getLayerByName(layerName), 'module');
        const batchCmd = HistoryCommandFactory.createBatchCommand('Shape Panel Import SVG');
        const newElementnewElement = yield importSvgString(iconString, {
            type: 'layer',
            parentCmd: batchCmd,
            layerName,
            targetModule: layerModule,
        });
        const { width, height } = svgCanvas.getSvgRealLocation(newElementnewElement);
        const [newWidth, newHeight] = width > height ? [500, (height * 500) / width] : [(width * 500) / height, 500];
        svgCanvas.selectOnly([newElementnewElement]);
        batchCmd.addSubCommand(svgCanvas.setSvgElemSize('width', newWidth));
        batchCmd.addSubCommand(svgCanvas.setSvgElemSize('height', newHeight));
        batchCmd.addSubCommand(svgCanvas.setSvgElemPosition('x', 0, newElementnewElement, false));
        batchCmd.addSubCommand(svgCanvas.setSvgElemPosition('y', 0, newElementnewElement, false));
        newElementnewElement.setAttribute('data-ratiofixed', 'true');
        batchCmd.addSubCommand(yield svgCanvas.disassembleUse2Group([newElementnewElement], true, false));
        updateElementColor(svgCanvas.getSelectedElems()[0]);
        svgCanvas.addCommandToHistory(batchCmd);
    }
});
const ShapeIcon = ({ activeTab, fileName, onClose, }) => {
    const forceUpdate = useForceUpdate();
    const key = `${activeTab}/${fileName}`;
    useEffect(() => {
        if (icons[key]) {
            return;
        }
        import(`app/icons/shape/${key}.svg`)
            .then((module) => {
            const icon = module.default;
            icons[key] = icon;
            forceUpdate();
        })
            .catch((err) => {
            console.error(`Fail to load icon from 'app/icons/shape/${key}.svg': ${err}`);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [key]);
    const IconComponent = icons[key];
    return (IconComponent && (React.createElement(Icon, { id: key, className: styles.icon, component: IconComponent, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
            yield importShape(IconComponent, builtInElements[fileName]);
            onClose();
        }) })));
};
export default ShapeIcon;
