var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import deviceMaster from 'helpers/device-master';
import { getPerspectivePointsZ3Regression, interpolatePointsFromHeight, } from 'helpers/camera-calibration-helper';
import { getWorkarea } from 'app/constants/workarea-constants';
const getHeightOffsets = () => __awaiter(void 0, void 0, void 0, function* () {
    let autoLevelingData = {
        A: 0,
        B: 0,
        C: 0,
        D: 0,
        E: 0,
        F: 0,
        G: 0,
        H: 0,
        I: 0,
    };
    let heightOffset = Object.assign({}, autoLevelingData);
    try {
        autoLevelingData = yield deviceMaster.fetchAutoLevelingData('hexa_platform');
        console.log('hexa_platform leveling data', Object.assign({}, autoLevelingData));
    }
    catch (e) {
        console.error('Unable to get hexa_platform leveling data', e);
    }
    try {
        const data = yield deviceMaster.fetchAutoLevelingData('bottom_cover');
        const keys = Object.keys(data);
        console.log('bottom_cover leveling data', data);
        keys.forEach((key) => {
            autoLevelingData[key] -= data[key];
        });
    }
    catch (e) {
        console.error('Unable to get bottom_cover leveling data', e);
    }
    try {
        heightOffset = yield deviceMaster.fetchAutoLevelingData('offset');
    }
    catch (e) {
        console.error('Unable to get height offset data', e);
    }
    return { autoLevelingData, heightOffset };
});
const loadCamera3dRotation = () => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const data = yield deviceMaster.fetchFisheye3DRotation();
        console.log('fetchFisheye3DRotation', data);
        return data;
    }
    catch (e) {
        console.error('Unable to get fisheye 3d rotation', e);
    }
    return null;
});
const getHeight = (device) => __awaiter(void 0, void 0, void 0, function* () {
    let enteredRawMode = false;
    try {
        yield deviceMaster.enterRawMode();
        enteredRawMode = true;
        const res = yield deviceMaster.rawGetProbePos();
        const { z, didAf } = res;
        if (didAf) {
            return getWorkarea(device.model, 'ado1').deep - z;
        }
    }
    catch (e) {
        console.log('Fail to get probe position, using custom height', e);
        // do nothing
        return 0;
    }
    finally {
        if (enteredRawMode)
            yield deviceMaster.endRawMode();
    }
    return 0;
});
const getPerspectiveForAlign = (device, param, center) => __awaiter(void 0, void 0, void 0, function* () {
    const { autoLevelingData, heightOffset } = yield getHeightOffsets();
    const rotationParam = yield loadCamera3dRotation();
    const refKey = 'E';
    const refHeight = autoLevelingData[refKey];
    const keys = Object.keys(autoLevelingData);
    keys.forEach((key) => {
        var _a;
        autoLevelingData[key] = Math.round((autoLevelingData[key] - refHeight) * 1000) / 1000;
        autoLevelingData[key] += (_a = heightOffset[key]) !== null && _a !== void 0 ? _a : 0;
    });
    const workarea = getWorkarea(device.model, 'ado1');
    let height = yield getHeight(device);
    if (rotationParam) {
        const { rx, ry, rz, sh, ch } = rotationParam;
        const z = workarea.deep - height;
        const rotationZ = sh * (z + ch);
        yield deviceMaster.set3dRotation({ rx, ry, rz, h: rotationZ, tx: 0, ty: 0 });
    }
    console.log('Use Height: ', height);
    if (rotationParam === null || rotationParam === void 0 ? void 0 : rotationParam.dh)
        height += rotationParam.dh;
    console.log('After applying rotation 3d dh: ', height);
    const { heights, points, z3regParam } = param;
    let perspectivePoints;
    if (points && heights) {
        [perspectivePoints] = points;
        perspectivePoints = interpolatePointsFromHeight(height !== null && height !== void 0 ? height : 0, heights, points, {
            chessboard: [48, 36],
            workarea: [workarea.width, workarea.height],
            center,
            levelingOffsets: autoLevelingData,
        });
    }
    else if (z3regParam) {
        perspectivePoints = getPerspectivePointsZ3Regression(height !== null && height !== void 0 ? height : 0, z3regParam, {
            chessboard: [48, 36],
            workarea: [workarea.width, workarea.height],
            center,
            levelingOffsets: autoLevelingData,
        });
    }
    return perspectivePoints;
});
export default getPerspectiveForAlign;
