// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-components-beambox-left-panel-LeftPanel-module__container--o3ELK{position:absolute;top:40px;left:0px;height:calc(100% - 40px);background:#f8f8f8;border:1px solid #e0e0e0;border-width:0 1px 1px 0;overflow-y:scroll}.src-web-app-components-beambox-left-panel-LeftPanel-module__container--o3ELK::-webkit-scrollbar{display:none}@media screen and (max-width: 600px){.src-web-app-components-beambox-left-panel-LeftPanel-module__container--o3ELK{display:none}}", "",{"version":3,"sources":["webpack://./src/web/app/components/beambox/left-panel/LeftPanel.module.scss"],"names":[],"mappings":"AAAA,8EACE,iBAAA,CACA,QAAA,CACA,QAAA,CACA,wBAAA,CACA,kBAAA,CACA,wBAAA,CACA,wBAAA,CACA,iBAAA,CAEA,iGACE,YAAA,CAGF,qCAdF,8EAeI,YAAA,CAAA","sourcesContent":[".container {\n  position: absolute;\n  top: 40px;\n  left: 0px;\n  height: calc(100% - 40px);\n  background: #f8f8f8;\n  border: 1px solid #e0e0e0;\n  border-width: 0 1px 1px 0;\n  overflow-y: scroll;\n\n  &::-webkit-scrollbar {\n    display: none;\n  }\n\n  @media screen and (max-width: 600px) {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "src-web-app-components-beambox-left-panel-LeftPanel-module__container--o3ELK"
};
export default ___CSS_LOADER_EXPORT___;
