var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable no-await-in-loop */
/* eslint-disable react/no-array-index-key */
import classNames from 'classnames';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Button, Col, Modal, InputNumber, Row, Segmented } from 'antd';
import alertCaller from 'app/actions/alert-caller';
import browser from 'implementations/browser';
import checkDeviceStatus from 'helpers/check-device-status';
import durationFormatter from 'helpers/duration-formatter';
import deviceMaster from 'helpers/device-master';
import getDevice from 'helpers/device/get-device';
import useI18n from 'helpers/useI18n';
import { addDialogComponent, isIdExist, popDialogById } from 'app/actions/dialog-controller';
import { getWorkarea } from 'app/constants/workarea-constants';
import rangeGenerator from './rangeGenerator';
import styles from './MeasureArea.module.scss';
const debugging = false;
var Type;
(function (Type) {
    Type[Type["Amount"] = 1] = "Amount";
    Type[Type["Gap"] = 2] = "Gap";
})(Type || (Type = {}));
// TODO: Add unit tests
const MeasureArea = ({ bbox: { x, y, width, height }, onFinished, onClose, }) => {
    const lang = useI18n();
    const [selectedType, setSelectedType] = useState(Type.Amount);
    const [row, setRow] = useState(12);
    const [column, setColumn] = useState(8);
    const [rowGap, setRowGap] = useState(Math.round(width / 10));
    const [columnGap, setColumnGap] = useState(Math.round(height / 10));
    const [objectHeight, setObjectHeight] = useState(10); // [mm]
    const canceledRef = useRef(false);
    const [cancelling, setCancelling] = useState(false);
    const [isMeasuring, setIsMeasuring] = useState(false);
    const [finishedPoints, setFinishedPoints] = useState(0);
    const [progressText, setProgressText] = useState('');
    const xRange = useMemo(() => {
        if (selectedType === Type.Amount)
            return rangeGenerator.countRangeGenerator(x, x + width, column);
        return rangeGenerator.stepRangeGenerator(x, x + width, columnGap);
    }, [x, width, column, columnGap, selectedType]);
    const yRange = useMemo(() => {
        if (selectedType === Type.Amount)
            return rangeGenerator.countRangeGenerator(y, y + height, row);
        return rangeGenerator.stepRangeGenerator(y, y + height, rowGap);
    }, [y, height, row, rowGap, selectedType]);
    const checkAndUpdate = useCallback((newVal, setState) => {
        setState((cur) => {
            if (Number.isNaN(Number(newVal)) || !newVal)
                return cur;
            return newVal;
        });
    }, []);
    const handleStartMeasuring = () => __awaiter(void 0, void 0, void 0, function* () {
        if (isMeasuring)
            return;
        canceledRef.current = false;
        setCancelling(false);
        setIsMeasuring(true);
        setFinishedPoints(0);
        const { device } = yield getDevice();
        if (!device) {
            setIsMeasuring(false);
            return;
        }
        const deviceStatus = yield checkDeviceStatus(device);
        if (!deviceStatus) {
            setIsMeasuring(false);
            return;
        }
        try {
            setProgressText(lang.message.enteringRawMode);
            yield deviceMaster.enterRawMode();
            setProgressText(lang.message.homing);
            yield deviceMaster.rawHome();
            if (canceledRef.current) {
                setIsMeasuring(false);
                return;
            }
            const currentPosition = { x: 0, y: 0 };
            setProgressText(lang.curve_engraving.starting_measurement);
            const points = [];
            const totalPoints = xRange.length * yRange.length;
            let finished = 0;
            const workarea = getWorkarea(device.model);
            const [offsetX, offsetY, offsetZ] = workarea.autoFocusOffset || [0, 0, 0];
            const feedrate = 6000;
            const start = Date.now();
            let lowest = null;
            let highest = null;
            for (let i = 0; i < yRange.length; i += 1) {
                points.push([]);
                for (let j = 0; j < xRange.length; j += 1) {
                    if (canceledRef.current) {
                        setIsMeasuring(false);
                        return;
                    }
                    const pointX = xRange[j];
                    const pointY = yRange[i];
                    if (!debugging) {
                        yield deviceMaster.rawMove({
                            x: Math.max(pointX - offsetX, 0),
                            y: Math.max(pointY - offsetY),
                            f: feedrate,
                        });
                        const dist = Math.hypot(pointX - currentPosition.x, pointY - currentPosition.y);
                        const time = (dist / feedrate) * 60;
                        yield new Promise((resolve) => setTimeout(resolve, time * 1000));
                        currentPosition.x = pointX;
                        currentPosition.y = pointY;
                    }
                    try {
                        if (!debugging) {
                            const z = yield deviceMaster.rawMeasureHeight(lowest === null
                                ? { relZ: objectHeight }
                                : { baseZ: Math.max(lowest - objectHeight, 0) });
                            if (lowest === null || z > lowest)
                                lowest = z; // actually the max measured value
                            const pointZ = typeof z === 'number' ? Math.max(0, z - offsetZ) : null;
                            // actually the min measured value, use pointZ to display Plane when z is null
                            if (highest === null || z < highest)
                                highest = pointZ;
                            points[i].push([pointX, pointY, pointZ]);
                        }
                        else {
                            // Debugging height measurement
                            const z = 4 + 2 * Math.sin(pointX) + 2 * Math.cos(pointY);
                            if (lowest === null || z > lowest)
                                lowest = z;
                            if (highest === null || z < highest)
                                highest = z;
                            points[i].push([pointX, pointY, z]);
                        }
                    }
                    catch (error) {
                        points[i].push([pointX, pointY, null]);
                        console.error(`Failed to measure height at point ${pointX}, ${pointY}`, error);
                    }
                    const elapsedTime = Date.now() - start;
                    finished += 1;
                    const finishedRatio = finished / totalPoints;
                    const remainingTime = (elapsedTime / finishedRatio - elapsedTime) / 1000;
                    setProgressText(`${lang.message.time_remaining} ${durationFormatter(remainingTime)}`);
                    setFinishedPoints(finished);
                }
            }
            onFinished({
                points,
                gap: [xRange[1] - xRange[0], yRange[1] - yRange[0]],
                objectHeight,
                lowest,
                highest,
            });
            onClose();
        }
        catch (error) {
            alertCaller.popUpError({ message: `Failed to measure area ${error.message}` });
            setIsMeasuring(false);
            console.log(error);
            return;
        }
        finally {
            if (deviceMaster.currentControlMode === 'raw') {
                yield deviceMaster.rawLooseMotor();
                yield deviceMaster.endRawMode();
            }
        }
    });
    const handleCancel = useCallback(() => {
        canceledRef.current = true;
        setProgressText(lang.message.cancelling);
        setCancelling(true);
    }, [lang]);
    return (React.createElement(Modal, { title: lang.curve_engraving.measure_audofocus_area, open: true, centered: true, closable: false, width: 540, maskClosable: false, footer: isMeasuring
            ? [
                React.createElement(Button, { key: "cancel", disabled: cancelling, onClick: handleCancel }, lang.alert.cancel),
            ]
            : [
                React.createElement(Button, { key: "cancel", onClick: onClose }, lang.curve_engraving.reselect_area),
                React.createElement(Button, { key: "start", type: "primary", onClick: handleStartMeasuring }, lang.curve_engraving.start_autofocus),
            ] },
        React.createElement("div", { className: styles.points }, yRange.map((yValue, yIdx) => (React.createElement("div", { className: styles.row, key: yIdx }, xRange.map((xValue, xIdx) => (React.createElement("div", { className: classNames(styles.point, {
                [styles.finished]: isMeasuring && finishedPoints > yIdx * xRange.length + xIdx,
            }), key: `${yIdx}-${xIdx}` }))))))),
        !isMeasuring && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles.controls },
                React.createElement(Col, { span: 24 },
                    React.createElement(Row, { gutter: [48, 0], justify: "center" },
                        React.createElement(Col, { className: styles.col, span: 12 },
                            React.createElement(Row, { gutter: [0, 12], justify: "space-around", align: "middle" },
                                React.createElement(Col, { span: 24 },
                                    React.createElement(Segmented, { block: true, options: [
                                            { value: Type.Amount, label: lang.curve_engraving.amount },
                                            { value: Type.Gap, label: lang.curve_engraving.gap },
                                        ], onChange: (v) => setSelectedType(v) })),
                                React.createElement(Col, { span: 12 }, selectedType === Type.Amount
                                    ? lang.curve_engraving.rows
                                    : lang.curve_engraving.gap),
                                React.createElement(Col, { span: 12 },
                                    React.createElement(InputNumber, { type: "number", value: selectedType === Type.Amount ? row : rowGap, min: selectedType === Type.Amount ? 2 : 1, onChange: (val) => checkAndUpdate(val, selectedType === Type.Amount ? setRow : setRowGap), step: 1, precision: 0, addonAfter: selectedType === Type.Amount ? undefined : 'mm' })),
                                React.createElement(Col, { span: 12 }, selectedType === Type.Amount
                                    ? lang.curve_engraving.coloumns
                                    : lang.curve_engraving.column_gap),
                                React.createElement(Col, { span: 12 },
                                    React.createElement(InputNumber, { type: "number", value: selectedType === Type.Amount ? column : columnGap, min: selectedType === Type.Amount ? 2 : 1, onChange: (val) => checkAndUpdate(val, selectedType === Type.Amount ? setColumn : setColumnGap), step: 1, precision: 0, addonAfter: selectedType === Type.Amount ? undefined : 'mm' })))),
                        React.createElement(Col, { className: styles.col, span: 12 },
                            React.createElement(Row, { gutter: [0, 12], align: "middle" },
                                React.createElement(Col, { className: styles.title, span: 24 }, lang.curve_engraving.set_object_height),
                                React.createElement(Col, { className: styles.info, span: 24 }, lang.curve_engraving.set_object_height_desc),
                                React.createElement(Col, { span: 12 },
                                    React.createElement(InputNumber, { type: "number", value: objectHeight, min: 0, onChange: (val) => checkAndUpdate(val, setObjectHeight), step: 1, precision: 0, addonAfter: "mm" }))))))),
            React.createElement("button", { className: styles.link, type: "button", onClick: () => browser.open('https://google.com') }, lang.curve_engraving.measure_area_help))),
        isMeasuring && React.createElement("div", null, progressText)));
};
export default MeasureArea;
export const showMeasureArea = (bbox) => {
    if (isIdExist('measure-area'))
        popDialogById('measure-area');
    return new Promise((resolve) => {
        addDialogComponent('measure-area', React.createElement(MeasureArea, { bbox: bbox, onFinished: (data) => {
                resolve(data);
                popDialogById('measure-area');
            }, onClose: () => {
                resolve(null);
                popDialogById('measure-area');
            } }));
    });
};
