import React from 'react';
import UnitInput from 'app/widgets/UnitInput';
import { Flex } from 'antd';
import useI18n from 'helpers/useI18n';
import { blockSettingParams, blockSettingScopes } from './BlockSetting';
import styles from './Form.module.scss';
export default function BlockSettingForm({ isInch, blockSetting, handleChange, className, }) {
    const t = useI18n();
    const lengthUnit = isInch ? 'in' : 'mm';
    const handleValueChange = (scope, param, value) => {
        const { min, max } = blockSetting[scope][param];
        handleChange(Object.assign(Object.assign({}, blockSetting), { [scope]: Object.assign(Object.assign({}, blockSetting[scope]), { [param]: Object.assign(Object.assign({}, blockSetting[scope][param]), { value: Math.min(max, Math.max(min, value)) }) }) }));
    };
    const renderInput = (scope, param) => {
        const setting = blockSetting[scope][param];
        const useInch = isInch && param !== 'count';
        return (React.createElement(UnitInput, { isInch: useInch, key: `${scope}-${param}`, "data-testid": `${scope}-${param}`, className: styles.input, value: setting.value, max: setting.max, min: setting.min, precision: useInch ? 4 : 0, step: useInch ? 25.4 : 1, addonAfter: param === 'count' ? '' : lengthUnit, onChange: (value) => handleValueChange(scope, param, value) }));
    };
    const renderColumn = (scope) => (React.createElement(Flex, { key: scope, vertical: true, justify: "space-between", gap: "8px" },
        React.createElement("div", { className: styles['sub-title'] }, t.material_test_generator[scope === 'row' ? 'rows' : 'columns']),
        blockSettingParams.map((param) => renderInput(scope, param))));
    return (React.createElement(Flex, { className: className, justify: "space-between" },
        React.createElement(Flex, { vertical: true, justify: "space-between", gap: "8px" },
            React.createElement("div", { className: styles.title }, t.material_test_generator.block_settings),
            React.createElement("div", { className: styles.label }, t.material_test_generator.count),
            React.createElement("div", { className: styles.label }, t.material_test_generator.size),
            React.createElement("div", { className: styles.label }, t.material_test_generator.spacing)),
        React.createElement(Flex, { className: styles.inputs, justify: "flex-end", gap: "20px" }, blockSettingScopes.map(renderColumn))));
}
