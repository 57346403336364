import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { ColorPicker, Button, Input, Popover } from 'antd';
import ColorBlock from 'app/components/beambox/right-panel/ColorBlock';
import isWeb from 'helpers/is-web';
import useI18n from 'helpers/useI18n';
import styles from './ColorPicker.module.scss';
const getDisplayValue = (c) => !c || c === 'none' ? '00000000' : c.replace('#', '').toUpperCase();
const isHex = (c) => c && c.match(/^[A-Fa-f0-9]{6}$/);
const isWhiteTopBar = window.os !== 'MacOS' && !isWeb();
const ColorPickerMobile = ({ color, onChange, open, onClose }) => {
    const lang = useI18n().alert;
    const previewColor = (c) => onChange(c, false);
    const [displayValue, setDisplayValue] = useState(getDisplayValue(color));
    const isClear = color === 'none';
    useEffect(() => {
        setDisplayValue(getDisplayValue(color));
    }, [color]);
    const panelRender = (panel) => (React.createElement("div", null,
        React.createElement("div", { className: classNames(styles.panel, styles['with-clear']) }, panel),
        React.createElement(ColorBlock, { className: classNames(styles['clear-button'], { [styles.active]: isClear }), color: "none", onClick: () => previewColor('none') }),
        React.createElement("div", { className: styles.footer },
            React.createElement(Input, { className: styles.input, size: "small", value: displayValue, prefix: React.createElement("span", { className: styles.prefix }, "#"), maxLength: 6, onChange: (e) => {
                    const newValue = e.target.value;
                    setDisplayValue(newValue);
                    if (isHex(newValue)) {
                        previewColor(`#${newValue}`);
                    }
                }, onBlur: () => setDisplayValue(getDisplayValue(color)) }),
            React.createElement(Button, { shape: "round", type: "default", className: styles.btn, onClick: onClose }, lang.cancel),
            React.createElement(Button, { shape: "round", type: "primary", className: styles.btn, onClick: () => {
                    onChange(color);
                    onClose();
                } }, lang.ok))));
    return (React.createElement(React.Fragment, null,
        React.createElement(ColorPicker, { rootClassName: styles['mobile-container'], getPopupContainer: (triggerNode) => triggerNode.parentElement, open: open, value: isClear ? '#000000' : color, onChange: (c) => previewColor(c.toHexString()), panelRender: panelRender, arrow: false, disabledAlpha: true },
            React.createElement("div", null)),
        React.createElement(Popover, { rootClassName: styles['mask-container'], open: open, content: React.createElement(React.Fragment, null,
                React.createElement("div", { className: classNames(styles['top-mask'], { [styles.white]: isWhiteTopBar }), onClick: () => onClose() }),
                React.createElement("div", { className: styles['bottom-mask'], onClick: () => onClose() })), arrow: false })));
};
export default ColorPickerMobile;
