import Boxgen from './boxgen.svg';
import Document from './document.svg';
import Draw from './draw.svg';
import Layers from './layers.svg';
import Photo from './photo.svg';
import Shape from './shape.svg';
import Shoot from './shoot.svg';
import Text from './text.svg';
import Trace from './trace.svg';
import Trash from './trash.svg';
export default {
    Boxgen,
    Document,
    Draw,
    Shoot,
    Layers,
    Photo,
    Shape,
    Text,
    Trace,
    Trash,
};
