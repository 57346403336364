/* eslint-disable react/require-default-props */
import * as React from 'react';
import classNames from 'classnames';
import browser from 'implementations/browser';
import { useIsMobile } from 'helpers/system-helper';
import styles from './Control.module.scss';
const Controls = ({ id = '', label, url = '', warningText = null, children, }) => {
    const isDesktop = !useIsMobile();
    const innerHtml = { __html: label };
    const warningIcon = () => {
        if (warningText) {
            return (React.createElement("img", { src: "img/warning.svg", title: warningText }));
        }
        return null;
    };
    const renderIcon = () => {
        if (url) {
            return (React.createElement("span", { className: "info-icon-small" },
                React.createElement("img", { src: "img/info.svg", onClick: () => { browser.open(url); } })));
        }
        return null;
    };
    return (React.createElement("div", { id: id, className: classNames('row-fluid', styles.control) },
        React.createElement("div", { className: classNames('no-left-margin', { span3: isDesktop, [styles.label]: isDesktop }) },
            React.createElement("label", { className: "font2", 
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML: innerHtml }),
            renderIcon()),
        React.createElement("div", { className: classNames('font3', { span8: isDesktop }) },
            children,
            warningIcon())));
};
export default Controls;
