import classNames from 'classnames';
import React, { memo, useEffect, useMemo, useRef } from 'react';
import DimensionPanelIcons from 'app/icons/dimension-panel/DimensionPanelIcons';
import eventEmitterFactory from 'helpers/eventEmitterFactory';
import ObjectPanelItem from 'app/views/beambox/Right-Panels/ObjectPanelItem';
import UnitInput from 'app/widgets/UnitInput';
import useI18n from 'helpers/useI18n';
import { objectPanelInputTheme } from 'app/constants/antd-config';
import { useIsMobile } from 'helpers/system-helper';
import styles from './DimensionPanel.module.scss';
const Rotation = ({ value, onChange }) => {
    const inputRef = useRef(null);
    const objectPanelEventEmitter = useMemo(() => eventEmitterFactory.createEventEmitter('object-panel'), []);
    const isMobile = useIsMobile();
    const t = useI18n().topbar.menu;
    useEffect(() => {
        const handler = (newValues) => {
            if ((newValues === null || newValues === void 0 ? void 0 : newValues.rotation) !== undefined && inputRef.current) {
                inputRef.current.value = newValues.rotation.toFixed(2);
            }
        };
        objectPanelEventEmitter.on('UPDATE_DIMENSION_VALUES', handler);
        return () => {
            objectPanelEventEmitter.removeListener('UPDATE_DIMENSION_VALUES', handler);
        };
    }, [objectPanelEventEmitter]);
    if (isMobile) {
        return (React.createElement(ObjectPanelItem.Number, { id: "rotate", value: value || 0, updateValue: onChange, label: t.rotate, unit: "degree" }));
    }
    return (React.createElement("div", { className: styles.dimension },
        React.createElement("div", { className: classNames(styles.label, styles.img) },
            React.createElement(DimensionPanelIcons.Rotate, null)),
        React.createElement(UnitInput, { ref: inputRef, id: "rotate", className: styles.input, theme: objectPanelInputTheme, underline: true, controls: false, unit: "deg", value: value || 0, precision: 2, onChange: onChange, fireOnChange: true, onBlur: () => onChange(value, true), onPressEnter: () => onChange(value, true) })));
};
export default memo(Rotation);
