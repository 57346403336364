import EventEmitter from 'eventemitter3';
export const listener = new EventEmitter();
export const sender = new EventEmitter();
export default {
    on(channel, handler) {
        listener.on(channel, handler);
    },
    once(channel, handler) {
        listener.once(channel, handler);
    },
    send(channel, ...args) {
        sender.emit(channel, ...args);
    },
    sendSync(channel, ...args) {
        const res = { returnValue: null };
        sender.emit(channel, res, ...args);
        return res.returnValue;
    },
};
