var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { createContext, useCallback, useEffect, useState, } from 'react';
import Alert from 'app/actions/alert-caller';
import alertConstants from 'app/constants/alert-constants';
import BeamFileHelper from 'helpers/beam-file-helper';
import currentFileManager from 'app/svgedit/currentFileManager';
import dialog from 'implementations/dialog';
import dialogCaller from 'app/actions/dialog-caller';
import Progress from 'app/actions/progress-caller';
import useI18n from 'helpers/useI18n';
import { axiosFluxId, getDefaultHeader } from 'helpers/api/flux-id';
import { showFluxPlusWarning } from 'app/actions/dialog-controller';
export const MyCloudContext = createContext({
    sortBy: 'recent',
    setSortBy: () => { },
    files: [],
    editingId: null,
    setEditingId: () => { },
    selectedId: null,
    setSelectedId: () => { },
    onClose: () => { },
    fileOperation: {
        open: () => __awaiter(void 0, void 0, void 0, function* () { }),
        duplicate: () => __awaiter(void 0, void 0, void 0, function* () { }),
        download: () => __awaiter(void 0, void 0, void 0, function* () { }),
        rename: () => __awaiter(void 0, void 0, void 0, function* () { }),
        delete: () => __awaiter(void 0, void 0, void 0, function* () { }),
    },
});
export function MyCloudProvider({ children, onClose }) {
    const LANG = useI18n();
    const [sortBy, setSortBy] = useState('recent');
    const [files, setFiles] = useState(undefined);
    const [editingId, setEditingId] = useState(null);
    const [selectedId, setSelectedId] = useState(null);
    const checkResp = useCallback((resp) => __awaiter(this, void 0, void 0, function* () {
        if (!resp) {
            Alert.popUpError({ message: LANG.flux_id_login.connection_fail });
            return false;
        }
        const { error } = resp;
        if (error) {
            if (!error.response) {
                Alert.popUpError({ message: LANG.flux_id_login.connection_fail });
                return false;
            }
            const { status, statusText } = error.response;
            const { info, message, detail } = error.response.data || {};
            if (status === 403 && detail && detail.startsWith('CSRF Failed: CSRF')) {
                Alert.popUp({
                    message: LANG.beambox.popup.ai_credit.relogin_to_use,
                    buttonType: alertConstants.CONFIRM_CANCEL,
                    onConfirm: dialogCaller.showLoginDialog,
                });
                return false;
            }
            if (info === 'STORAGE_LIMIT_EXCEEDED') {
                Alert.popUpError({ message: LANG.my_cloud.save_file.storage_limit_exceeded });
                return false;
            }
            Alert.popUpError({
                caption: info,
                message: detail || message || `${status}: ${statusText}`,
            });
            return false;
        }
        let { data } = resp;
        if (data instanceof Blob && data.type === 'application/json') {
            data = JSON.parse(yield data.text());
        }
        const { status, info, message } = data;
        if (status !== 'error')
            return true;
        if (info === 'NOT_SUBSCRIBED') {
            onClose();
            showFluxPlusWarning();
            return false;
        }
        Alert.popUpError({ caption: info, message });
        return false;
    }), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [LANG]);
    const sortAndSetFiles = (newFiles = files) => {
        if (!newFiles)
            return;
        newFiles.sort((a, b) => {
            if (sortBy === 'old') {
                if (a.last_modified_at < b.last_modified_at)
                    return -1;
                if (a.last_modified_at > b.last_modified_at)
                    return 1;
                return 0;
            }
            if (sortBy === 'a2z') {
                if (a.name < b.name)
                    return -1;
                if (a.name > b.name)
                    return 1;
                return 0;
            }
            if (sortBy === 'z2a') {
                if (a.name > b.name)
                    return -1;
                if (a.name < b.name)
                    return 1;
                return 0;
            }
            // sortBy === 'recent'
            if (a.last_modified_at > b.last_modified_at)
                return -1;
            if (a.last_modified_at < b.last_modified_at)
                return 1;
            return 0;
        });
        setFiles([...newFiles]);
    };
    const getFileList = () => __awaiter(this, void 0, void 0, function* () {
        try {
            const resp = yield axiosFluxId.get('/api/beam-studio/cloud/list', { withCredentials: true });
            return (yield checkResp(resp)) ? resp.data.data.files : [];
        }
        catch (e) {
            console.error(e);
            return [];
        }
    });
    const refresh = () => __awaiter(this, void 0, void 0, function* () {
        const newFiles = yield getFileList();
        sortAndSetFiles(newFiles);
    });
    useEffect(() => {
        refresh();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        sortAndSetFiles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortBy]);
    const openFile = (file) => __awaiter(this, void 0, void 0, function* () {
        const id = 'open-cloud-file';
        yield Progress.openNonstopProgress({ id });
        try {
            const resp = yield axiosFluxId.get(`/api/beam-studio/cloud/file/${file.uuid}`, {
                withCredentials: true,
                responseType: 'blob',
            });
            if (yield checkResp(resp)) {
                yield BeamFileHelper.readBeam(resp.data);
                currentFileManager.setCloudFile(file);
                onClose();
            }
        }
        catch (e) {
            console.error(e);
            Alert.popUpError({ message: `Error: ${LANG.my_cloud.action.open}` });
        }
        finally {
            Progress.popById(id);
        }
    });
    const duplicateFile = (file) => __awaiter(this, void 0, void 0, function* () {
        const id = 'duplicate-cloud-file';
        yield Progress.openNonstopProgress({ id });
        let newFile;
        try {
            const resp = yield axiosFluxId.put(`/api/beam-studio/cloud/file/operation/${file.uuid}`, { method: 'duplicate' }, { withCredentials: true, headers: getDefaultHeader() });
            if (yield checkResp(resp)) {
                newFile = resp.data.new_file;
            }
        }
        catch (e) {
            console.error(e);
            Alert.popUpError({ message: `Error: ${LANG.my_cloud.action.duplicate}` });
        }
        finally {
            Progress.popById(id);
            refresh();
            if (newFile) {
                setEditingId(newFile);
            }
        }
    });
    const downloadFile = (file) => __awaiter(this, void 0, void 0, function* () {
        const id = 'download-cloud-file';
        yield Progress.openNonstopProgress({ id });
        const langFile = LANG.topmenu.file;
        const getContent = () => __awaiter(this, void 0, void 0, function* () {
            const resp = yield axiosFluxId.get(`/api/beam-studio/cloud/file/${file.uuid}`, {
                withCredentials: true,
                responseType: 'blob',
            });
            return resp.data;
        });
        try {
            yield dialog.writeFileDialog(getContent, langFile.save_scene, window.os === 'Linux' ? `${file.name}.beam` : file.name, [
                {
                    name: window.os === 'MacOS' ? `${langFile.scene_files} (*.beam)` : langFile.scene_files,
                    extensions: ['beam'],
                },
                {
                    name: langFile.all_files,
                    extensions: ['*'],
                },
            ]);
        }
        catch (e) {
            console.error(e);
            Alert.popUpError({ message: `Error: ${LANG.my_cloud.action.download}` });
        }
        finally {
            Progress.popById(id);
        }
    });
    const renameFile = (file, newName) => __awaiter(this, void 0, void 0, function* () {
        const id = 'rename-cloud-file';
        if (newName && file.name !== newName) {
            yield Progress.openNonstopProgress({ id });
            try {
                const resp = yield axiosFluxId.put(`/api/beam-studio/cloud/file/operation/${file.uuid}`, { method: 'rename', data: newName }, { withCredentials: true, headers: getDefaultHeader() });
                if (yield checkResp(resp)) {
                    // eslint-disable-next-line no-param-reassign
                    file.name = newName;
                    if (currentFileManager.isCloudFile && currentFileManager.getPath() === file.uuid) {
                        currentFileManager.setFileName(file.name);
                    }
                    sortAndSetFiles();
                }
            }
            catch (e) {
                console.error(e);
                Alert.popUpError({ message: `Error: ${LANG.my_cloud.action.rename}` });
            }
            finally {
                Progress.popById(id);
            }
        }
        setEditingId(null);
    });
    const deleteFile = (file) => __awaiter(this, void 0, void 0, function* () {
        const id = 'delete-cloud-file';
        yield Progress.openNonstopProgress({ id });
        try {
            const resp = yield axiosFluxId.delete(`/api/beam-studio/cloud/file/${file.uuid}`, {
                withCredentials: true,
                headers: getDefaultHeader(),
            });
            if ((yield checkResp(resp)) && currentFileManager.isCloudFile && currentFileManager.getPath() === file.uuid) {
                currentFileManager.setCloudUUID(null);
            }
        }
        catch (e) {
            console.error(e);
            Alert.popUpError({ message: `Error: ${LANG.my_cloud.action.delete}` });
        }
        finally {
            Progress.popById(id);
            refresh();
        }
    });
    return (React.createElement(MyCloudContext.Provider, { value: {
            sortBy,
            setSortBy,
            files,
            editingId,
            setEditingId,
            selectedId,
            setSelectedId,
            onClose,
            fileOperation: {
                open: openFile,
                duplicate: duplicateFile,
                download: downloadFile,
                rename: renameFile,
                delete: deleteFile,
            },
        } }, children));
}
