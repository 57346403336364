import React, { memo } from 'react';
import { Button } from 'antd';
import DimensionPanelIcons from 'app/icons/dimension-panel/DimensionPanelIcons';
import ObjectPanelItem from 'app/views/beambox/Right-Panels/ObjectPanelItem';
import useI18n from 'helpers/useI18n';
import { useIsMobile } from 'helpers/system-helper';
const RatioLock = ({ isLocked, onClick }) => {
    const t = useI18n().beambox.right_panel.object_panel;
    const isMobile = useIsMobile();
    if (isMobile) {
        return (React.createElement(ObjectPanelItem.Item, { id: "lock", content: isLocked ? React.createElement(DimensionPanelIcons.Locked, null) : React.createElement(DimensionPanelIcons.Unlocked, null), onClick: onClick }));
    }
    return (React.createElement(Button, { id: "lock", type: "text", title: isLocked ? t.unlock_aspect : t.lock_aspect, icon: isLocked ? React.createElement(DimensionPanelIcons.Locked, null) : React.createElement(DimensionPanelIcons.Unlocked, null), onClick: onClick }));
};
export default memo(RatioLock);
