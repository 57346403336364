import React from 'react';
import i18n from 'helpers/i18n';
import historyUtils from 'app/svgedit/history/utils';
import TopBarIcons from 'app/icons/top-bar/TopBarIcons';
import { getSVGAsync } from 'helpers/svg-editor-helper';
import { useIsMobile } from 'helpers/system-helper';
import styles from './CommonTools.module.scss';
let svgEditor;
getSVGAsync((globalSVG) => {
    svgEditor = globalSVG.Editor;
});
const LANG = i18n.lang.topbar;
function CommonTools({ isWeb, hide }) {
    const isMobile = useIsMobile();
    if (!isWeb || hide)
        return null;
    return (React.createElement("div", { className: styles['common-tools-container'] },
        React.createElement("div", { title: LANG.menu.undo, onClick: historyUtils.undo },
            React.createElement(TopBarIcons.Undo, null)),
        React.createElement("div", { title: LANG.menu.redo, onClick: historyUtils.redo },
            React.createElement(TopBarIcons.Redo, null)),
        !isMobile && (React.createElement("div", { title: LANG.menu.delete, onClick: () => svgEditor.deleteSelected() },
            React.createElement(TopBarIcons.Trash, null)))));
}
export default CommonTools;
