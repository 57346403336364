import SegmentControlPoint from './SegmentControlPoint';
const { svgedit } = window;
export default class Segment {
    constructor(index, item) {
        this.index = index;
        this.item = item;
        this.type = item.pathSegType;
        this.controlPoints = [];
    }
    select(isSelected) {
        this.endPoint.setSelected(isSelected);
    }
    update() {
        if (this.ptgrip) {
            const pt = svgedit.path.getGripPt(this);
            svgedit.utilities.assignAttributes(this.ptgrip, {
                cx: pt.x,
                cy: pt.y,
            });
            svgedit.path.getSegSelector(this, true);
        }
    }
    getNodePointAndControlPoints() {
        const pathSeg = this.item;
        if (pathSeg.pathSegType === 1) {
            return {};
        }
        const nodePoint = new svgedit.path.PathNodePoint(pathSeg.x, pathSeg.y, this, this.path);
        const controlPoints = [];
        if (pathSeg.pathSegType === 6) {
            controlPoints.push(new SegmentControlPoint(pathSeg.x1, pathSeg.y1, this, 1));
            controlPoints.push(new SegmentControlPoint(pathSeg.x2, pathSeg.y2, this, 2));
        }
        else if (pathSeg.pathSegType === 8) {
            controlPoints.push(new SegmentControlPoint(pathSeg.x1, pathSeg.y1, this, 1));
        }
        this.controlPoints = controlPoints;
        return { nodePoint, controlPoints };
    }
}
