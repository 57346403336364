import classNames from 'classnames';
import React from 'react';
import i18n from 'helpers/i18n';
import UnitInput from 'app/widgets/Unit-Input-v2';
const LANG = i18n.lang.beambox.tool_panels;
class RowColumn extends React.Component {
    constructor(props) {
        super(props);
        this.onRawChanged = (row) => {
            const { onValueChange } = this.props;
            const { column } = this.state;
            onValueChange({
                row,
                column,
            });
            this.setState({ row });
        };
        this.onColumnChanged = (column) => {
            const { onValueChange } = this.props;
            const { row } = this.state;
            onValueChange({
                row,
                column,
            });
            this.setState({ column });
        };
        const { row, column } = this.props;
        this.state = {
            row,
            column,
            isCollapsed: false,
        };
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            row: nextProps.row,
            column: nextProps.column,
        });
    }
    render() {
        const { row, column, isCollapsed } = this.state;
        return (React.createElement("div", { className: "tool-panel" },
            React.createElement("label", { className: "controls accordion" },
                React.createElement("input", { type: "checkbox", className: "accordion-switcher", defaultChecked: true }),
                React.createElement("p", { className: "caption", onClick: () => this.setState({ isCollapsed: !isCollapsed }) },
                    LANG.array_dimension,
                    React.createElement("span", { className: "value" }, `${row} X ${column}`)),
                React.createElement("div", { className: classNames('tool-panel-body', { collapsed: isCollapsed }) },
                    React.createElement("div", { className: "control" },
                        React.createElement("div", { className: "text-center header" }, LANG.columns),
                        React.createElement(UnitInput, { id: "columns", min: 1, unit: "", decimal: 0, defaultValue: column || 1, getValue: this.onColumnChanged })),
                    React.createElement("div", { className: "control" },
                        React.createElement("div", { className: "text-center header" }, LANG.rows),
                        React.createElement(UnitInput, { id: "rows", min: 1, unit: "", decimal: 0, defaultValue: row || 1, getValue: this.onRawChanged }))))));
    }
}
export default RowColumn;
