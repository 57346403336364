import React from 'react';
import i18n from 'helpers/i18n';
import ButtonGroup from './ButtonGroup';
let lang = i18n.lang.buttons;
/**
  * @deprecated The method should not be used
*/
class AlertDialog extends React.Component {
    constructor(props) {
        super(props);
        this.renderMessage = () => {
            const { displayImages, imgClass, images, message, } = this.props;
            if (displayImages) {
                return React.createElement("img", { className: imgClass, src: images[this.state.imgIndex] });
            }
            return typeof message === 'string'
                ? React.createElement("pre", { className: "message", dangerouslySetInnerHTML: { __html: message } })
                : React.createElement("pre", { className: "message" }, message);
        };
        this._renderCheckbox = () => {
            const self = this;
            const _handleCheckboxClick = function (e) {
                if (e.target.checked) {
                    self.props.buttons[0].onClick = () => {
                        self.props.checkedCallback();
                        self.props.onClose();
                    };
                }
                else {
                    self.props.buttons[0].onClick = () => {
                        self.props.onClose();
                    };
                }
                self.setState(self.state);
            };
            if (this.props.checkbox) {
                return (React.createElement("div", { className: "modal-checkbox" },
                    React.createElement("input", { type: "checkbox", onClick: _handleCheckboxClick }),
                    this.props.checkbox));
            }
            else {
                return null;
            }
        };
        this._renderButtons = () => {
            var self = this;
            if (this.props.displayImages) {
                if (this.state.imgIndex < this.props.images.length - 1) {
                    return (React.createElement(ButtonGroup, { buttons: [{
                                label: lang.next,
                                right: true,
                                onClick: () => {
                                    self.setState({ imgIndex: this.state.imgIndex + 1 });
                                }
                            }] }));
                }
                else {
                    return React.createElement(ButtonGroup, { buttons: [{
                                label: lang.next,
                                right: true,
                                onClick: () => {
                                    self.setState({ imgIndex: 0 });
                                    this.props.onCustom();
                                    self.props.onClose();
                                }
                            }] });
                }
            }
            else {
                return React.createElement(ButtonGroup, { buttons: this.props.buttons });
            }
        };
        this.state = {
            imgIndex: 0,
        };
    }
    render() {
        var caption = ('' !== this.props.caption ?
            React.createElement("h2", { className: "caption" }, this.props.caption) :
            ''), html = this.renderMessage(), checkbox = this._renderCheckbox(), buttons = this._renderButtons(), className = 'modal-alert';
        if (this.props.displayImages) {
            className += ' ' + this.props.imgClass;
        }
        return (React.createElement("div", { className: className },
            caption,
            html,
            checkbox,
            buttons));
    }
}
AlertDialog.defaultProps = {
    caption: '',
    checkbox: '',
    message: '',
    buttons: [],
    images: [],
    imgClass: '',
    displayImages: false,
    onCustom: () => { },
    onClose: () => { },
    checkedCallback: () => { },
};
;
export default AlertDialog;
