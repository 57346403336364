var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Alert from 'app/actions/alert-caller';
import AlertConstants from 'app/constants/alert-constants';
import BeamboxPreference from 'app/actions/beambox/beambox-preference';
import checkSoftwareForAdor from 'helpers/check-software';
import dialogCaller from 'app/actions/dialog-caller';
import i18n from 'helpers/i18n';
import MessageCaller, { MessageLevel } from 'app/actions/message-caller';
import storage from 'implementations/storage';
import TopBarController from 'app/views/beambox/TopBar/contexts/TopBarController';
import DeviceMaster from '../device-master';
import showResizeAlert from './fit-device-workarea-alert';
const getDevice = (showModal = false) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    const currentDevice = TopBarController.getSelectedDevice();
    let device = showModal ? null : currentDevice;
    let isWorkareaMatched = null;
    if (device) {
        let selectRes = { success: true };
        let statusRes;
        try {
            if (((_a = DeviceMaster.currentDevice) === null || _a === void 0 ? void 0 : _a.info.serial) !== device.serial ||
                !((_c = (_b = DeviceMaster.currentDevice) === null || _b === void 0 ? void 0 : _b.control) === null || _c === void 0 ? void 0 : _c.isConnected)) {
                selectRes = yield DeviceMaster.select(device);
            }
            else if (((_e = (_d = DeviceMaster.currentDevice) === null || _d === void 0 ? void 0 : _d.control) === null || _e === void 0 ? void 0 : _e.getMode()) === 'raw') {
                yield DeviceMaster.endRawMode();
            }
            else if (((_g = (_f = DeviceMaster.currentDevice) === null || _f === void 0 ? void 0 : _f.control) === null || _g === void 0 ? void 0 : _g.getMode()) === 'maintain') {
                yield DeviceMaster.endMaintainMode();
            }
            else if (((_j = (_h = DeviceMaster.currentDevice) === null || _h === void 0 ? void 0 : _h.control) === null || _j === void 0 ? void 0 : _j.getMode()) === 'cartridge_io') {
                yield DeviceMaster.endCartridgeIOMode();
            }
            if (selectRes.success) {
                // get current status
                statusRes = yield DeviceMaster.getReport();
            }
        }
        catch (error) {
            console.error("getDeviceError", error);
            yield ((_l = (_k = DeviceMaster.currentDevice) === null || _k === void 0 ? void 0 : _k.control) === null || _l === void 0 ? void 0 : _l.killSelf());
        }
        if (!statusRes || !selectRes.success) {
            device = null;
            MessageCaller.openMessage({
                key: 'reconnect-fail',
                level: MessageLevel.INFO,
                content: i18n.lang.message.device_not_found.caption,
                duration: 3,
            });
        }
        else {
            // update device status
            device = Object.assign(Object.assign({}, device), statusRes);
        }
    }
    if (!device) {
        const autoSelect = storage.get('auto_connect') !== 0;
        const devices = DeviceMaster.getAvailableDevices();
        device =
            !showModal && autoSelect && devices.length === 1
                ? devices[0]
                : yield dialogCaller.selectDevice();
        if (device && !checkSoftwareForAdor(device)) {
            return { device: null };
        }
        TopBarController.setSelectedDevice(device);
        if (device) {
            const isNewDevice = (currentDevice === null || currentDevice === void 0 ? void 0 : currentDevice.uuid) !== device.uuid;
            if (isNewDevice) {
                storage.set('selected-device', device.uuid);
            }
            const res = yield DeviceMaster.select(device);
            if (res.success) {
                isWorkareaMatched = device.model === BeamboxPreference.read('workarea');
                if (!isWorkareaMatched && isNewDevice) {
                    isWorkareaMatched = yield showResizeAlert(device);
                }
            }
            else {
                Alert.popUp({
                    id: 'fatal-occurred',
                    caption: i18n.lang.alert.oops,
                    message: `#813 ${res.error}`,
                    type: AlertConstants.SHOW_POPUP_ERROR,
                });
            }
        }
    }
    else {
        isWorkareaMatched = device.model === BeamboxPreference.read('workarea');
    }
    return { device, isWorkareaMatched };
});
export default getDevice;
