import { BaseHistoryCommand } from './history';
class CustomCommand extends BaseHistoryCommand {
    constructor(text, doApply, doUnapply) {
        super();
        this.elements = () => [];
        this.type = () => 'CustomCommand';
        this.doApply = () => {
            try {
                this._doApply();
            }
            catch (e) {
                console.error('Failed to apply command', this.text, e);
            }
        };
        this.doUnapply = () => {
            try {
                this._doUnapply();
            }
            catch (e) {
                console.error('Failed to unapply command', this.text, e);
            }
        };
        this.text = text;
        this._doApply = doApply;
        this._doUnapply = doUnapply;
    }
}
export default CustomCommand;
