var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import deviceMaster from 'helpers/device-master';
const checkCamera = (device) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const selectResult = yield deviceMaster.select(device);
        if (!selectResult.success) {
            throw new Error(selectResult.error || 'Fail to select device');
        }
        yield deviceMaster.connectCamera();
        yield deviceMaster.takeOnePicture();
        deviceMaster.disconnectCamera();
        return true;
    }
    catch (e) {
        console.log(e);
    }
    return false;
});
export default checkCamera;
