var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import alertCaller from 'app/actions/alert-caller';
import alertConstants from 'app/constants/alert-constants';
import defaultPresets from 'app/constants/presets';
import dialog from 'implementations/dialog';
import i18n from 'helpers/i18n';
import LayerModule from 'app/constants/layer-module/layer-modules';
import storage from 'implementations/storage';
const migrateStorage = () => {
    const defaultKeys = Object.keys(defaultPresets);
    let presets = storage.get('presets');
    if (presets) {
        const existingKeys = new Set();
        presets = presets.filter((c) => {
            if (!c.isDefault)
                return true;
            existingKeys.add(c.key);
            return !!defaultPresets[c.key];
        });
        defaultKeys.forEach((key, idx) => {
            if (!existingKeys.has(key)) {
                let inserIdx = -1;
                if (idx > 0) {
                    const prevKey = defaultKeys[idx - 1];
                    inserIdx = presets.findIndex((p) => p.key === prevKey && p.isDefault);
                }
                const newPreset = { key, isDefault: true, hide: false };
                presets.splice(inserIdx + 1, 0, newPreset);
            }
        });
    }
    else {
        const customizedLaserConfigs = storage.get('customizedLaserConfigs');
        // For version <= 2.3.9, maybe we can remove this in the future
        if (customizedLaserConfigs) {
            presets = [...customizedLaserConfigs];
            const defaultLaserConfigsInUse = storage.get('defaultLaserConfigsInUse') || {};
            defaultKeys.forEach((key, idx) => {
                if (!defaultLaserConfigsInUse[key]) {
                    const hide = defaultLaserConfigsInUse[key] === false;
                    let inserIdx = -1;
                    if (idx > 0) {
                        const prevKey = defaultKeys[idx - 1];
                        inserIdx = presets.findIndex((p) => p.key === prevKey && p.isDefault);
                    }
                    const newPreset = { key, isDefault: true, hide };
                    presets.splice(inserIdx + 1, 0, newPreset);
                }
            });
            presets = presets.filter((c) => !(c.isDefault && !defaultPresets[c.key]));
            presets.forEach((p, idx) => {
                const { isDefault, key, hide } = p;
                if (isDefault)
                    presets[idx] = { key, isDefault, hide: !!hide };
            });
        }
        else {
            presets = defaultKeys.map((key) => ({ key, isDefault: true, hide: false }));
        }
    }
    storage.set('presets', presets);
    return presets;
};
// default + customized
let allPresets;
const getAllPresets = () => allPresets;
let presetsCache = {};
const initPresets = (migrate = false) => {
    if (!allPresets) {
        if (migrate)
            allPresets = migrateStorage();
        else
            allPresets = storage.get('presets') || migrateStorage();
        // translate name
        const unit = storage.get('default-units') || 'mm';
        const LANG = i18n.lang.beambox.right_panel.laser_panel;
        allPresets.forEach((preset) => {
            if (preset.isDefault && preset.key) {
                const { key } = preset;
                const translated = LANG.dropdown[unit][key];
                // eslint-disable-next-line no-param-reassign
                preset.name = translated || key;
            }
        });
    }
};
const clearPresetsCache = () => {
    presetsCache = {};
};
const reloadPresets = (migrate = false) => {
    allPresets = null;
    clearPresetsCache();
    initPresets(migrate);
};
const getDefaultPreset = (key, model, layerModule = LayerModule.LASER_UNIVERSAL) => {
    var _a, _b, _c, _d;
    return ((_b = (_a = defaultPresets[key]) === null || _a === void 0 ? void 0 : _a[model]) === null || _b === void 0 ? void 0 : _b[layerModule]) ||
        ((_d = (_c = defaultPresets[key]) === null || _c === void 0 ? void 0 : _c[model]) === null || _d === void 0 ? void 0 : _d[LayerModule.LASER_UNIVERSAL]) ||
        null;
};
const modelHasPreset = (model, key) => { var _a; return !!((_a = defaultPresets[key]) === null || _a === void 0 ? void 0 : _a[model]); };
const getPresetsList = (model, layerModule = LayerModule.LASER_UNIVERSAL) => {
    var _a;
    if ((_a = presetsCache[model]) === null || _a === void 0 ? void 0 : _a[layerModule]) {
        return presetsCache[model][layerModule];
    }
    const res = (allPresets === null || allPresets === void 0 ? void 0 : allPresets.map((preset) => {
        const { key, isDefault, hide, module } = preset;
        if (hide)
            return null;
        if (isDefault) {
            const defaultPreset = getDefaultPreset(key, model, layerModule);
            if (defaultPreset)
                return Object.assign(Object.assign({}, defaultPreset), preset);
            return null;
        }
        if ((module === LayerModule.PRINTER) !== (layerModule === LayerModule.PRINTER)) {
            return null;
        }
        return preset;
    }).filter((e) => e)) || [];
    if (!presetsCache[model]) {
        presetsCache[model] = {};
    }
    presetsCache[model][layerModule] = res;
    return res;
};
const savePreset = (preset) => {
    allPresets.push(preset);
    storage.set('presets', allPresets);
    clearPresetsCache();
};
const savePresetList = (presets) => {
    allPresets = presets;
    storage.set('presets', allPresets);
    clearPresetsCache();
};
const resetPresetList = () => {
    const defaultKeys = Object.keys(defaultPresets);
    const newPresets = [...defaultKeys.map((key) => ({ key, isDefault: true, hide: false }))];
    storage.set('presets', newPresets);
    reloadPresets();
};
export const importPresets = (file) => __awaiter(void 0, void 0, void 0, function* () {
    const fileBlob = file !== null && file !== void 0 ? file : (yield dialog.getFileFromDialog({
        filters: [{ name: 'JSON', extensions: ['json', 'JSON'] }],
    }));
    if (fileBlob) {
        const res = yield new Promise((resolve) => {
            alertCaller.popUp({
                buttonType: alertConstants.CONFIRM_CANCEL,
                message: i18n.lang.beambox.right_panel.laser_panel.preset_management.sure_to_import_presets,
                onConfirm: () => {
                    const reader = new FileReader();
                    reader.onloadend = (evt) => {
                        const configString = evt.target.result;
                        const newConfigs = JSON.parse(configString);
                        const { customizedLaserConfigs, defaultLaserConfigsInUse, presets } = newConfigs;
                        if (presets) {
                            storage.set('presets', presets);
                        }
                        else if (customizedLaserConfigs && defaultLaserConfigsInUse) {
                            // For version <= 2.3.9
                            const configNames = new Set(customizedLaserConfigs
                                .filter((config) => !config.isDefault)
                                .map((config) => config.name));
                            let currentConfig = storage.get('customizedLaserConfigs') || [];
                            if (typeof currentConfig === 'string') {
                                currentConfig = JSON.parse(currentConfig);
                            }
                            for (let i = 0; i < currentConfig.length; i += 1) {
                                const config = currentConfig[i];
                                if (!config.isDefault && !configNames.has(config.name)) {
                                    customizedLaserConfigs.push(config);
                                }
                            }
                            storage.set('customizedLaserConfigs', customizedLaserConfigs);
                            storage.set('defaultLaserConfigsInUse', defaultLaserConfigsInUse);
                            storage.removeAt('presets');
                        }
                        reloadPresets(true);
                        resolve(true);
                    };
                    reader.readAsText(fileBlob);
                },
            });
        });
        return res;
    }
    return false;
});
const exportPresets = (presets) => __awaiter(void 0, void 0, void 0, function* () {
    const isLinux = window.os === 'Linux';
    const getContent = () => {
        const laserConfig = {
            presets: presets !== null && presets !== void 0 ? presets : storage.get('presets'),
        };
        return JSON.stringify(laserConfig);
    };
    yield dialog.writeFileDialog(getContent, i18n.lang.beambox.right_panel.laser_panel.preset_management.export_preset_title, isLinux ? '.json' : '', [
        {
            name: window.os === 'MacOS' ? 'JSON (*.json)' : 'JSON',
            extensions: ['json'],
        },
        {
            name: i18n.lang.topmenu.file.all_files,
            extensions: ['*'],
        },
    ]);
});
initPresets(true);
export default {
    exportPresets,
    getAllPresets,
    getDefaultPreset,
    getPresetsList,
    importPresets,
    modelHasPreset,
    reloadPresets,
    resetPresetList,
    savePreset,
    savePresetList,
};
