var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable no-console */
import Alert from 'app/actions/alert-caller';
import AlertConstants from 'app/constants/alert-constants';
import BeamboxPreference from 'app/actions/beambox/beambox-preference';
import checkDeviceStatus from 'helpers/check-device-status';
import checkOldFirmware from 'helpers/device/checkOldFirmware';
import Constant, { promarkModels } from 'app/actions/beambox/constant';
import deviceMaster from 'helpers/device-master';
import eventEmitterFactory from 'helpers/eventEmitterFactory';
import i18n from 'helpers/i18n';
import PreviewModeBackgroundDrawer from 'app/actions/beambox/preview-mode-background-drawer';
import Progress from 'app/actions/progress-caller';
import VersionChecker from 'helpers/version-checker';
import AdorPreviewManager from '../camera/preview-helper/AdorPreviewManager';
import BeamPreviewManager from '../camera/preview-helper/BeamPreviewManager';
import BB2PreviewManager from '../camera/preview-helper/BB2PreviewManager';
import PromarkPreviewManager from '../camera/preview-helper/PromarkPreviewManager';
const LANG = i18n.lang;
const canvasEventEmitter = eventEmitterFactory.createEventEmitter('canvas');
class PreviewModeController {
    constructor() {
        this.reloadHeightOffset = () => __awaiter(this, void 0, void 0, function* () {
            var _a, _b;
            (_b = (_a = this.previewManager) === null || _a === void 0 ? void 0 : _a.reloadLevelingOffset) === null || _b === void 0 ? void 0 : _b.call(_a);
        });
        this.resetFishEyeObjectHeight = () => __awaiter(this, void 0, void 0, function* () {
            var _c, _d, _e;
            const res = (_e = (yield ((_d = (_c = this.previewManager) === null || _c === void 0 ? void 0 : _c.resetObjectHeight) === null || _d === void 0 ? void 0 : _d.call(_c)))) !== null && _e !== void 0 ? _e : false;
            if (res && !PreviewModeBackgroundDrawer.isClean())
                yield this.previewFullWorkarea();
        });
        this.isLiveModeOn = () => !!(this.isPreviewModeOn && this.liveModeTimeOut);
        this.prePreview = () => {
            const { isPreviewBlocked, isPreviewModeOn } = this;
            if (isPreviewBlocked || !isPreviewModeOn)
                return false;
            this.isDrawing = true;
            this.isPreviewBlocked = true;
            const workarea = document.querySelector('#workarea');
            workarea.style.cursor = 'wait';
            return true;
        };
        this.onPreviewSuccess = () => {
            const workarea = document.querySelector('#workarea');
            workarea.style.cursor = 'url(img/camera-cursor.svg) 9 12, cell';
            this.isPreviewBlocked = false;
            this.isDrawing = false;
        };
        this.onPreviewFail = (error) => {
            if (this.isPreviewModeOn) {
                console.log(error);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Alert.popUpError({ message: error.message || error.text });
            }
            const workarea = document.querySelector('#workarea');
            workarea.style.cursor = 'auto';
            if (!PreviewModeBackgroundDrawer.isClean())
                this.isDrawing = false;
            this.end();
        };
        this.isDrawing = false;
        this.currentDevice = null;
        this.isPreviewModeOn = false;
        this.isPreviewBlocked = false;
        this.errorCallback = () => { };
    }
    get isFullScreen() {
        var _a;
        return (_a = this.previewManager) === null || _a === void 0 ? void 0 : _a.isFullScreen;
    }
    checkDevice(device) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.isStarting)
                return false;
            if (this.currentDevice && this.currentDevice.serial !== (device === null || device === void 0 ? void 0 : device.serial)) {
                yield this.end();
                PreviewModeBackgroundDrawer.clear();
            }
            if (!device)
                return false;
            const deviceStatus = yield checkDeviceStatus(device);
            if (!deviceStatus)
                return false;
            const vc = VersionChecker(device.version);
            if (!vc.meetRequirement('USABLE_VERSION')) {
                Alert.popUp({
                    type: AlertConstants.SHOW_POPUP_ERROR,
                    message: LANG.beambox.popup.should_update_firmware_to_continue,
                });
                Progress.popById('start-preview-controller');
                return false;
            }
            if (BeamboxPreference.read('borderless') && !vc.meetRequirement('BORDERLESS_MODE')) {
                // eslint-disable-next-line max-len
                const message = `#814 ${LANG.calibration.update_firmware_msg1} 2.5.1 ${LANG.calibration.update_firmware_msg2} ${LANG.beambox.popup.or_turn_off_borderless_mode}`;
                const caption = LANG.beambox.left_panel.borderless_preview;
                Alert.popUp({
                    type: AlertConstants.SHOW_POPUP_ERROR,
                    message,
                    caption,
                });
                Progress.popById('start-preview-controller');
                return false;
            }
            const res = yield checkOldFirmware(device.version);
            if (!res)
                return false;
            return true;
        });
    }
    start(device, errCallback) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            this.reset();
            this.isStarting = true;
            const res = yield deviceMaster.select(device);
            if (!res.success) {
                this.isStarting = false;
                return;
            }
            try {
                this.currentDevice = device;
                if (promarkModels.has(device.model)) {
                    this.previewManager = new PromarkPreviewManager(device);
                }
                else if (Constant.adorModels.includes(device.model)) {
                    this.previewManager = new AdorPreviewManager(device);
                }
                else if (device.model === 'fbb2') {
                    this.previewManager = new BB2PreviewManager(device);
                }
                else
                    this.previewManager = new BeamPreviewManager(device);
                const setupRes = yield this.previewManager.setup({ progressId: 'preview-mode-controller' });
                if (!setupRes) {
                    this.isStarting = false;
                    return;
                }
                PreviewModeBackgroundDrawer.start((_b = (_a = this.previewManager).getCameraOffset) === null || _b === void 0 ? void 0 : _b.call(_a));
                PreviewModeBackgroundDrawer.drawBoundary();
                deviceMaster.setDeviceControlReconnectOnClose(device);
                this.errorCallback = errCallback;
                this.isPreviewModeOn = true;
                canvasEventEmitter.emit('UPDATE_CONTEXT');
            }
            catch (error) {
                console.error(error);
                yield this.end();
                throw error;
            }
            finally {
                this.isStarting = false;
            }
        });
    }
    end() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            console.log('end of pmc');
            this.isPreviewModeOn = false;
            if (this.liveModeTimeOut)
                clearTimeout(this.liveModeTimeOut);
            this.liveModeTimeOut = null;
            PreviewModeBackgroundDrawer.clearBoundary();
            PreviewModeBackgroundDrawer.end();
            const { currentDevice } = this;
            if (currentDevice) {
                deviceMaster.setDeviceControlDefaultCloseListener(currentDevice);
            }
            yield ((_a = this.previewManager) === null || _a === void 0 ? void 0 : _a.end());
            this.reset();
        });
    }
    toggleFullWorkareaLiveMode() {
        if (this.liveModeTimeOut)
            this.stopFullWorkareaLiveMode();
        else
            this.startFullWorkareaLiveMode();
    }
    startFullWorkareaLiveMode() {
        var _a;
        if (!this.isPreviewModeOn || !((_a = this.previewManager) === null || _a === void 0 ? void 0 : _a.previewFullWorkarea))
            return;
        const setNextTimeout = () => {
            this.liveModeTimeOut = setTimeout(() => {
                this.fullWorkareaLiveUpdate(() => {
                    if (this.liveModeTimeOut)
                        setNextTimeout();
                });
            }, 1000);
        };
        setNextTimeout();
    }
    stopFullWorkareaLiveMode() {
        if (this.liveModeTimeOut)
            clearTimeout(this.liveModeTimeOut);
        this.liveModeTimeOut = null;
    }
    fullWorkareaLiveUpdate(callback = () => { }) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.reloadHeightOffset();
            yield this.previewFullWorkarea(callback);
        });
    }
    previewFullWorkarea(callback = () => { }) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            const res = this.prePreview();
            if (!res)
                return false;
            try {
                if (!this.previewManager.previewFullWorkarea)
                    return false;
                yield ((_b = (_a = this.previewManager).previewFullWorkarea) === null || _b === void 0 ? void 0 : _b.call(_a));
                this.onPreviewSuccess();
                callback();
                return true;
            }
            catch (error) {
                this.onPreviewFail(error);
                callback();
                return false;
            }
        });
    }
    preview(x, y, opts = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = this.prePreview();
            if (!res)
                return false;
            const { callback } = opts;
            try {
                const previewRes = yield this.previewManager.preview(x, y);
                if (previewRes)
                    this.onPreviewSuccess();
                callback();
                return previewRes;
            }
            catch (error) {
                this.onPreviewFail(error);
                callback();
                return false;
            }
        });
    }
    previewRegion(x1, y1, x2, y2, opts = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = this.prePreview();
            if (!res)
                return false;
            const { callback } = opts;
            try {
                const previewRes = yield this.previewManager.previewRegion(x1, y1, x2, y2, opts);
                if (previewRes)
                    this.onPreviewSuccess();
                callback();
                return previewRes;
            }
            catch (error) {
                this.onPreviewFail(error);
                callback();
                return false;
            }
        });
    }
    isPreviewMode() {
        return this.isPreviewModeOn;
    }
    getCameraOffset() {
        var _a, _b;
        return ((_b = (_a = this.previewManager).getCameraOffset) === null || _b === void 0 ? void 0 : _b.call(_a)) || null;
    }
    getCameraOffsetStandard() {
        var _a, _b;
        return ((_b = (_a = this.previewManager).getCameraOffsetStandard) === null || _b === void 0 ? void 0 : _b.call(_a)) || null;
    }
    reset() {
        return __awaiter(this, void 0, void 0, function* () {
            this.previewManager = null;
            this.currentDevice = null;
            this.isPreviewModeOn = false;
            this.isPreviewBlocked = false;
            deviceMaster.disconnectCamera();
        });
    }
    // movementX, movementY in mm
    getPhotoAfterMoveTo(movementX, movementY) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            const imgUrl = yield ((_b = (_a = this.previewManager).getPhotoAfterMoveTo) === null || _b === void 0 ? void 0 : _b.call(_a, movementX, movementY));
            return imgUrl;
        });
    }
}
const instance = new PreviewModeController();
export default instance;
