import React from 'react';
import { createRoot } from 'react-dom/client';
import BeamboxGlobalInteraction from 'app/actions/beambox/beambox-global-interaction';
import ToolPanels from 'app/views/beambox/ToolPanels/ToolPanels';
class ToolPanelsController {
    constructor() {
        this.setVisibility = (isVisible) => {
            this.isVisible = isVisible;
            if (isVisible) {
                BeamboxGlobalInteraction.onObjectFocus();
            }
            else {
                BeamboxGlobalInteraction.onObjectBlur();
            }
        };
        this.setType = (type) => {
            this.type = type;
        };
        this.createRoot = () => {
            if (!this.root && document.getElementById('tool-panels-placeholder')) {
                this.root = createRoot(document.getElementById('tool-panels-placeholder'));
            }
        };
        this.render = () => {
            var _a;
            if (this.isVisible) {
                this.createRoot();
                (_a = this.root) === null || _a === void 0 ? void 0 : _a.render(React.createElement(ToolPanels, { type: this.type, data: this.data, unmount: this.unmount }));
            }
            else {
                this.unmount();
            }
        };
        this.unmount = () => {
            var _a;
            this.isVisible = false;
            (_a = this.root) === null || _a === void 0 ? void 0 : _a.unmount();
            this.root = null;
        };
        this.isVisible = false;
        this.type = 'unknown';
        this.data = {
            rowcolumn: {
                row: 1,
                column: 1,
            },
            distance: {
                dx: 0,
                dy: 0,
            },
        };
    }
}
const instance = new ToolPanelsController();
export default instance;
