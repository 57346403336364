import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Divider, Form, InputNumber, Radio, Slider, Space, Switch, Tooltip } from 'antd';
import { PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { sprintf } from 'sprintf-js';
import Select from 'app/widgets/AntdSelect';
import useI18n from 'helpers/useI18n';
import { BoxgenContext } from 'app/contexts/BoxgenContext';
import { SCREW_LENGTH_INCH, SCREW_LENGTH_MM, SCREW_SIZE_INCH, SCREW_SIZE_MM, SHEET_THICKNESS_INCH, SHEET_THICKNESS_MM, } from 'app/constants/boxgen-constants';
import styles from './Controller.module.scss';
const LengthInputItem = ({ className, label, name, hidden, min = 0, max, step = 1, additionalDecimal = 0, }) => {
    const { lengthUnit } = useContext(BoxgenContext);
    const { unit, unitRatio, decimal } = lengthUnit;
    return (React.createElement(Form.Item, { className: className, label: label, name: name, hidden: hidden },
        React.createElement(InputNumber, { className: styles['number-input'], type: "number", min: min, max: max, addonAfter: unit, step: step * unitRatio, formatter: (v, { userTyping, input }) => userTyping ? input : (v / unitRatio).toFixed(decimal + additionalDecimal), parser: (v) => Number(v) * unitRatio })));
};
const Controller = () => {
    const lang = useI18n().boxgen;
    const { boxData, setBoxData, workarea, lengthUnit } = useContext(BoxgenContext);
    const workareaLimit = Math.min(workarea.canvasWidth, workarea.canvasHeight);
    const { unit, unitRatio, decimal } = lengthUnit;
    const isMM = unit === 'mm';
    const [customThickness, setCustomThickness] = useState(0);
    const [thicknessOptions, setOptions] = useState([]);
    const [jointWarning, setJointWarning] = useState(undefined);
    const screwSizes = isMM ? SCREW_SIZE_MM : SCREW_SIZE_INCH;
    const screwLens = isMM ? SCREW_LENGTH_MM : SCREW_LENGTH_INCH;
    useEffect(() => {
        setOptions(isMM ? SHEET_THICKNESS_MM : SHEET_THICKNESS_INCH);
    }, [isMM]);
    const maxTSlotCount = useMemo(() => Math.floor(Math.min(boxData.width, boxData.height, boxData.depth) / 30), [boxData]);
    const maxTeethLength = useMemo(() => Math.min(boxData.width, boxData.height, boxData.depth) - boxData.sheetThickness * 2 - 5, [boxData]);
    const [form] = Form.useForm();
    const onValuesChange = () => {
        setJointWarning(undefined);
        const fields = form.getFieldsValue(true);
        let { width, height, depth, teethLength, tSlotCount, joint } = fields;
        const innerSizeInflation = fields.volume === 'inner' ? fields.sheetThickness * 2 : 0;
        width += innerSizeInflation;
        height += innerSizeInflation;
        depth += innerSizeInflation;
        if (joint === 't-slot') {
            if (maxTSlotCount < 1) {
                joint = 'edge';
                form.setFieldValue('joint', joint);
                setJointWarning(lang.tSlot_warning);
            }
            else {
                tSlotCount = Math.min(Math.max(tSlotCount, 1), maxTSlotCount);
                form.setFieldValue('tSlotCount', tSlotCount);
                teethLength = Math.min(width, height, depth) / (tSlotCount * 2 + 1);
                form.setFieldValue('teethLength', teethLength);
            }
        }
        else if (joint === 'finger') {
            if (maxTeethLength < 1) {
                joint = 'edge';
                form.setFieldValue('joint', joint);
                setJointWarning(lang.finger_warning);
            }
            else {
                teethLength = Math.min(teethLength, maxTeethLength);
                form.setFieldValue('teethLength', teethLength);
            }
        }
        if (joint === 'edge') {
            teethLength = Math.max(width, height, depth);
        }
        const newData = Object.assign(Object.assign({}, fields), { width,
            height,
            depth,
            teethLength,
            tSlotCount,
            joint });
        setBoxData(newData);
    };
    useEffect(() => {
        const { tSlotCount, joint } = form.getFieldsValue(true);
        if (joint === 't-slot' && (maxTSlotCount < 1 || tSlotCount > maxTSlotCount)) {
            onValuesChange();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [maxTSlotCount]);
    useEffect(() => {
        const { teethLength, joint } = form.getFieldsValue(true);
        if (joint === 'finger' && (maxTeethLength < 1 || teethLength > maxTeethLength)) {
            onValuesChange();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [maxTeethLength]);
    const addThicknessOptions = () => {
        if (customThickness <= 0 || thicknessOptions.some((option) => option.value === customThickness))
            return;
        setOptions([
            ...thicknessOptions,
            {
                label: `${+(customThickness / unitRatio).toFixed(decimal)} ${unit}`,
                value: customThickness,
            },
        ]);
    };
    return (React.createElement("div", { className: styles.controller },
        React.createElement("div", { className: styles.workarea },
            React.createElement(Tooltip, { title: sprintf(lang.max_dimension_tooltip, `${(workareaLimit / unitRatio).toFixed(isMM ? 0 : 2)}${unit}`), placement: "bottomLeft", arrow: { pointAtCenter: true } },
                React.createElement(QuestionCircleOutlined, { className: styles.icon })),
            React.createElement("span", null,
                lang.workarea,
                " : ",
                workarea.label,
                " (",
                ' ',
                (workarea.canvasWidth / unitRatio).toFixed(isMM ? 0 : 2),
                " x",
                ' ',
                (workarea.canvasHeight / unitRatio).toFixed(isMM ? 0 : 2),
                " ",
                unit,
                React.createElement("sup", null, "2"),
                " )")),
        React.createElement(Form, { className: styles.form, form: form, labelCol: { span: 8 }, onValuesChange: onValuesChange, initialValues: boxData },
            React.createElement(Form.Item, { label: lang.volume, name: "volume" },
                React.createElement(Radio.Group, null,
                    React.createElement(Radio.Button, { value: "outer" }, lang.outer),
                    React.createElement(Radio.Button, { value: "inner" }, lang.inner))),
            React.createElement(Form.Item, { label: lang.cover, name: "cover", valuePropName: "checked" },
                React.createElement(Switch, null)),
            React.createElement(LengthInputItem, { className: styles['small-margin'], label: lang.width, min: 1, max: workareaLimit, name: "width" }),
            React.createElement(LengthInputItem, { className: styles['small-margin'], label: lang.height, min: 1, max: workareaLimit, name: "height" }),
            React.createElement(LengthInputItem, { label: lang.depth, min: 1, max: workareaLimit, name: "depth" }),
            React.createElement(Form.Item, { label: lang.thickness, name: "sheetThickness" },
                React.createElement(Select, { options: thicknessOptions, popupMatchSelectWidth: false, dropdownRender: (menu) => (React.createElement(React.Fragment, null,
                        menu,
                        React.createElement(Divider, { className: styles.divider }),
                        React.createElement(Space, { className: styles['thickness-editor'], onKeyDown: (e) => e.stopPropagation() },
                            React.createElement(InputNumber, { type: "number", width: 60, defaultValue: customThickness, addonAfter: unit, min: 0, step: unitRatio, formatter: (v, { userTyping, input }) => userTyping ? input : (v / unitRatio).toFixed(decimal), parser: (v) => +v * unitRatio, onChange: setCustomThickness, onPressEnter: addThicknessOptions }),
                            React.createElement(Button, { type: "text", icon: React.createElement(PlusOutlined, null), disabled: customThickness <= 0 ||
                                    thicknessOptions.some((option) => option.value === customThickness), onClick: addThicknessOptions }, lang.add_option)))) })),
            React.createElement(Form.Item, { label: lang.joints, name: "joint", validateStatus: jointWarning ? 'warning' : undefined, help: jointWarning },
                React.createElement(Select, { options: [
                        { value: 'edge', label: lang.edge },
                        { value: 'finger', label: lang.finger, disabled: maxTeethLength < 1 },
                        { value: 't-slot', label: lang.tSlot, disabled: maxTSlotCount < 1 },
                    ] })),
            React.createElement(Form.Item, { className: styles['teeth-length'], label: lang.finger, name: "teethLength", hidden: boxData.joint !== 'finger' },
                React.createElement(Form.Item, { className: styles['no-margin'], name: "teethLength" },
                    React.createElement(Slider, { min: maxTeethLength === 1 ? 0 : 1, max: maxTeethLength, step: 0.1 * unitRatio, tooltip: {
                            formatter: (val) => (val / unitRatio).toFixed(decimal + 1),
                        } })),
                React.createElement(LengthInputItem, { className: styles['no-margin'], label: "", name: "teethLength", hidden: boxData.joint !== 'finger', min: 1, max: maxTeethLength, step: 0.1, additionalDecimal: 1 })),
            React.createElement(Form.Item, { label: lang.tCount, name: "tSlotCount", hidden: boxData.joint !== 't-slot', tooltip: lang.tCount_tooltip },
                React.createElement(Slider, { disabled: maxTSlotCount === 1, min: maxTSlotCount === 1 ? 0 : 1, max: maxTSlotCount, step: 1 })),
            React.createElement(Form.Item, { label: lang.tDiameter, name: "tSlotDiameter", hidden: boxData.joint !== 't-slot' },
                React.createElement(Select, { options: screwSizes })),
            React.createElement(Form.Item, { label: lang.tLength, name: "tSlotLength", hidden: boxData.joint !== 't-slot' },
                React.createElement(Select, { options: screwLens })))));
};
export default Controller;
