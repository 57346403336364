// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-components-dialogs-camera-common-ExposureSlider-module__container--cwFXU{display:flex;align-items:center}.src-web-app-components-dialogs-camera-common-ExposureSlider-module__container--cwFXU .src-web-app-components-dialogs-camera-common-ExposureSlider-module__icon--IeG2E{font-size:22px;margin-right:12px}.src-web-app-components-dialogs-camera-common-ExposureSlider-module__container--cwFXU .src-web-app-components-dialogs-camera-common-ExposureSlider-module__slider---DHIZ{width:100%;margin-left:0;margin-right:0}", "",{"version":3,"sources":["webpack://./src/web/app/components/dialogs/camera/common/ExposureSlider.module.scss"],"names":[],"mappings":"AAAA,sFACE,YAAA,CACA,kBAAA,CAEA,uKACE,cAAA,CACA,iBAAA,CAGF,yKACE,UAAA,CACA,aAAA,CACA,cAAA","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n\n  .icon {\n    font-size: 22px;\n    margin-right: 12px;\n  }\n\n  .slider {\n    width: 100%;\n    margin-left: 0;\n    margin-right: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "src-web-app-components-dialogs-camera-common-ExposureSlider-module__container--cwFXU",
	"icon": "src-web-app-components-dialogs-camera-common-ExposureSlider-module__icon--IeG2E",
	"slider": "src-web-app-components-dialogs-camera-common-ExposureSlider-module__slider---DHIZ"
};
export default ___CSS_LOADER_EXPORT___;
