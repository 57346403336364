import React, { useState } from 'react';
import { Modal, Radio, Space } from 'antd';
import useI18n from 'helpers/useI18n';
// TODO: add test
function RadioSelectDialog({ title, options, defaultValue, onOk, onCancel, }) {
    const [value, setValue] = useState(defaultValue);
    const handleOk = () => onOk(value);
    const lang = useI18n().alert;
    return (React.createElement(Modal, { title: title, open: true, centered: true, maskClosable: false, okText: lang.ok, cancelText: lang.cancel, onOk: handleOk, onCancel: onCancel },
        React.createElement(Radio.Group, { onChange: (e) => setValue(e.target.value), value: value },
            React.createElement(Space, { direction: "vertical" }, options.map((option) => (React.createElement(Radio, { key: option.label, value: option.value }, option.label)))))));
}
export default RadioSelectDialog;
