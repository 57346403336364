// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-components-beambox-top-bar-DocumentButton-module__button--9S7kb{height:30px;opacity:.7;cursor:pointer;-webkit-app-region:no-drag}.src-web-app-components-beambox-top-bar-DocumentButton-module__button--9S7kb.src-web-app-components-beambox-top-bar-DocumentButton-module__disabled--yX-D\\+{opacity:.2;pointer-events:none}.src-web-app-components-beambox-top-bar-DocumentButton-module__button--9S7kb:hover:not(.src-web-app-components-beambox-top-bar-DocumentButton-module__disabled--yX-D\\+){opacity:1}@media screen and (max-width: 600px){.src-web-app-components-beambox-top-bar-DocumentButton-module__button--9S7kb:hover:not(.src-web-app-components-beambox-top-bar-DocumentButton-module__disabled--yX-D\\+){opacity:.7}}.src-web-app-components-beambox-top-bar-DocumentButton-module__button--9S7kb svg{width:30px;height:30px}", "",{"version":3,"sources":["webpack://./src/web/app/components/beambox/top-bar/DocumentButton.module.scss","webpack://./src/web/app/components/beambox/top-bar/_mixins.scss"],"names":[],"mappings":"AAEA,6ECDE,WAAA,CACA,UAAA,CACA,cAAA,CACA,0BAAA,CACA,4JACE,UAAA,CACA,mBAAA,CAEF,wKACE,SAAA,CAEF,qCACE,wKACE,UAAA,CAAA,CDTJ,iFACE,UAAA,CACA,WAAA","sourcesContent":["@use './_mixins.scss' as mixins;\n\n.button {\n  @include mixins.button();\n\n  svg {\n    width: 30px;\n    height: 30px;\n  }\n}\n","@mixin button() {\n  height: 30px;\n  opacity: 0.7;\n  cursor: pointer;\n  -webkit-app-region: no-drag;\n  &.disabled {\n    opacity: 0.2;\n    pointer-events: none;\n  }\n  &:hover:not(.disabled) {\n    opacity: 1;\n  }\n  @media screen and (max-width: 600px) {\n    &:hover:not(.disabled) {\n      opacity: 0.7;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "src-web-app-components-beambox-top-bar-DocumentButton-module__button--9S7kb",
	"disabled": "src-web-app-components-beambox-top-bar-DocumentButton-module__disabled--yX-D+"
};
export default ___CSS_LOADER_EXPORT___;
