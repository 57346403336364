// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-components-beambox-TimeEstimationButton-module__btn--52aEn{cursor:pointer;display:flex;align-items:center;justify-content:center;width:40px;height:40px;font-size:22px;border-radius:20px;background-color:rgba(0,0,0,.6);color:#fff}@media screen and (max-width: 600px){.src-web-app-components-beambox-TimeEstimationButton-module__btn--52aEn{height:30px;font-size:18px}}.src-web-app-components-beambox-TimeEstimationButton-module__result--qX-kt{font-size:14px;color:#333;text-shadow:-1px 0 #fff,0 1px #fff,1px 0 #fff,0 -1px #fff}", "",{"version":3,"sources":["webpack://./src/web/app/components/beambox/TimeEstimationButton.module.scss"],"names":[],"mappings":"AAAA,wEACE,cAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA,CACA,WAAA,CACA,cAAA,CACA,kBAAA,CACA,+BAAA,CACA,UAAA,CAEA,qCAZF,wEAaI,WAAA,CACA,cAAA,CAAA,CAIJ,2EACE,cAAA,CACA,UAAA,CACA,yDAAA","sourcesContent":[".btn {\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 40px;\n  height: 40px;\n  font-size: 22px;\n  border-radius: 20px;\n  background-color: rgba(0, 0, 0, 0.6);\n  color: #ffffff;\n\n  @media screen and (max-width: 600px) {\n    height: 30px;\n    font-size: 18px;\n  }\n}\n\n.result {\n  font-size: 14px;\n  color: #333333;\n  text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": "src-web-app-components-beambox-TimeEstimationButton-module__btn--52aEn",
	"result": "src-web-app-components-beambox-TimeEstimationButton-module__result--qX-kt"
};
export default ___CSS_LOADER_EXPORT___;
