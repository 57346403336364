import classNames from 'classnames';
import React, { useMemo } from 'react';
import useI18n from 'helpers/useI18n';
import styles from './ConnectUsb.module.scss';
const ConnectUsb = () => {
    const lang = useI18n().initialize;
    const { model } = useMemo(() => {
        const queryString = window.location.hash.split('?')[1] || '';
        const urlParams = new URLSearchParams(queryString);
        return {
            model: urlParams.get('model'),
        };
    }, []);
    const handleNext = () => {
        const urlParams = new URLSearchParams({ model, usb: '1' });
        const queryString = urlParams.toString();
        window.location.hash = `#initialize/connect/connect-machine-ip?${queryString}`;
    };
    return (React.createElement("div", { className: styles.container },
        React.createElement("div", { className: styles['top-bar'] }),
        React.createElement("div", { className: styles.btns },
            React.createElement("div", { className: styles.btn, onClick: () => window.history.back() }, lang.back),
            React.createElement("div", { className: classNames(styles.btn, styles.primary), onClick: handleNext }, lang.next)),
        React.createElement("div", { className: styles.main },
            React.createElement("div", { className: styles.image },
                React.createElement("div", { className: classNames(styles.circle, styles.c1) }),
                React.createElement("img", { src: "img/init-panel/icon-usb-cable.svg", draggable: "false" }),
                React.createElement("div", { className: classNames(styles.circle, styles.c2) })),
            React.createElement("div", { className: styles.text },
                React.createElement("div", { className: styles.title }, lang.connect_usb.title),
                React.createElement("div", { className: classNames(styles.contents, styles.tutorial) },
                    React.createElement("div", { className: styles.subtitle }, "HEXA"),
                    React.createElement("div", null,
                        "1. ",
                        lang.connect_usb.tutorial1),
                    React.createElement("div", null,
                        "2. ",
                        lang.connect_usb.tutorial2)),
                React.createElement("div", { className: classNames(styles.contents, styles.tutorial) },
                    React.createElement("div", { className: styles.subtitle }, "Ador"),
                    React.createElement("div", null,
                        "1. ",
                        lang.connect_usb.turn_off_machine),
                    React.createElement("div", null,
                        "2. ",
                        lang.connect_usb.tutorial1),
                    React.createElement("div", null,
                        "3. ",
                        lang.connect_usb.turn_on_machine),
                    React.createElement("div", null,
                        "4. ",
                        lang.connect_usb.wait_for_turning_on))))));
};
export default ConnectUsb;
