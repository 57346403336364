var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { axiosFluxId } from './api/flux-id';
import versionCompare from './version-compare';
export default function checkFirmware(device) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!navigator.onLine) {
            throw new Error('Offline');
        }
        try {
            const key = {
                fhexa1: 'hexa-latest',
                ado1: 'ador-latest',
            }[device.model.toLowerCase()] || 'firmware-latest';
            const resp = yield axiosFluxId.get(`api/check-update?key=${key}`);
            console.log(resp);
            const { data } = resp;
            const latestVersion = data.links[0];
            const [name, link] = latestVersion;
            const version = name.split(' ').pop();
            const needUpdate = versionCompare(device.version, version);
            return {
                needUpdate,
                latestVersion: version,
                downloadUrl: link,
                changelog_en: name,
                changelog_zh: name,
            };
        }
        catch (err) {
            console.error('Error when getting latest firmware version', err);
            return {
                needUpdate: false,
            };
        }
    });
}
