// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-components-dialogs-camera-AdorCalibrationV2-CalibrateChessBoard-module__img-container--Ltxeh{display:flex;min-height:360px;align-items:center;justify-content:center}.src-web-app-components-dialogs-camera-AdorCalibrationV2-CalibrateChessBoard-module__img-container--Ltxeh img{object-fit:contain;height:360px}", "",{"version":3,"sources":["webpack://./src/web/app/components/dialogs/camera/AdorCalibrationV2/CalibrateChessBoard.module.scss"],"names":[],"mappings":"AAAA,0GACE,YAAA,CACA,gBAAA,CACA,kBAAA,CACA,sBAAA,CAEA,8GACE,kBAAA,CACA,YAAA","sourcesContent":[".img-container {\n  display: flex;\n  min-height: 360px;\n  align-items: center;\n  justify-content: center;\n\n  img {\n    object-fit: contain;\n    height: 360px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"img-container": "src-web-app-components-dialogs-camera-AdorCalibrationV2-CalibrateChessBoard-module__img-container--Ltxeh"
};
export default ___CSS_LOADER_EXPORT___;
