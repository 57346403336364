var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import alertCaller from 'app/actions/alert-caller';
import alertConstants from 'app/constants/alert-constants';
import deviceMaster from 'helpers/device-master';
import progressCaller from 'app/actions/progress-caller';
import useI18n from 'helpers/useI18n';
import { findCorners } from 'helpers/camera-calibration-helper';
import styles from './FindCorner.module.scss';
const PROGRESS_ID = 'camera-find-corner';
const FindCorner = ({ withPitch, updateParam, onClose, onBack, onNext }) => {
    const [img, setImg] = useState(null);
    const lang = useI18n();
    const initSetup = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        progressCaller.openNonstopProgress({
            id: PROGRESS_ID,
            message: lang.calibration.taking_picture,
        });
        try {
            yield deviceMaster.connectCamera();
        }
        finally {
            progressCaller.popById(PROGRESS_ID);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), []);
    const handleTakePicture = (retryTimes = 0) => __awaiter(void 0, void 0, void 0, function* () {
        progressCaller.openNonstopProgress({
            id: PROGRESS_ID,
            message: lang.calibration.taking_picture,
        });
        const { imgBlob } = (yield deviceMaster.takeOnePicture()) || {};
        if (!imgBlob) {
            if (retryTimes < 3)
                handleTakePicture(retryTimes + 1);
            else
                alertCaller.popUpError({ message: 'Unable to get image' });
        }
        else {
            try {
                const { success, blob, data } = yield findCorners(imgBlob, withPitch);
                if (!success) {
                    if (retryTimes < 3)
                        handleTakePicture(retryTimes + 1);
                    else
                        alertCaller.popUpError({ message: 'Failed to get correct corners' });
                }
                setImg({ blob, url: URL.createObjectURL(blob), success });
                if (success) {
                    if (data.ret > 3) {
                        alertCaller.popUp({
                            type: alertConstants.WARNING,
                            message: `Large deviation: ${data.ret}, please check engraved points.`,
                        });
                    }
                    updateParam({
                        k: data.k,
                        d: data.d,
                        rvec: data.rvec,
                        tvec: data.tvec,
                        rvecs: [data.rvec],
                        tvecs: [data.tvec],
                        heights: [0],
                        source: 'user',
                    });
                }
            }
            catch (err) {
                alertCaller.popUpError({ message: err.message || 'Fail to find corners' });
            }
        }
        progressCaller.popById(PROGRESS_ID);
    });
    useEffect(() => {
        initSetup().then(() => {
            handleTakePicture();
        });
        return () => deviceMaster.disconnectCamera();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (React.createElement(Modal, { open: true, centered: true, onCancel: () => onClose(false), title: lang.calibration.camera_calibration, footer: [
            React.createElement(Button, { className: styles['footer-button'], onClick: onBack, key: "back" }, lang.buttons.back),
            React.createElement(Button, { className: styles['footer-button'], onClick: () => handleTakePicture(0), key: "retry" }, "Retry"),
            React.createElement(Button, { className: styles['footer-button'], onClick: onNext, disabled: !(img === null || img === void 0 ? void 0 : img.success), key: "next", type: "primary" }, lang.buttons.next),
        ], closable: true, maskClosable: false },
        'tPlease check detected corners',
        React.createElement("div", { className: styles['img-container'] },
            React.createElement("img", { src: img === null || img === void 0 ? void 0 : img.url }))));
};
export default FindCorner;
