import * as React from 'react';
import Dialog from 'app/actions/dialog-caller';
import Monitor from 'app/views/monitor/Monitor';
import { Mode } from 'app/constants/monitor-constants';
import { MonitorContextProvider } from 'app/contexts/MonitorContext';
export default {
    showMonitor: (device, mode = Mode.FILE, previewTask) => {
        Dialog.addDialogComponent('monitor', React.createElement(MonitorContextProvider, { device: device, mode: mode, previewTask: previewTask, onClose: () => Dialog.popDialogById('monitor') },
            React.createElement(Monitor, { device: device })));
    },
};
