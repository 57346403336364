import React from 'react';
import UnitInput from 'app/widgets/Unit-Input-v2';
function NestGAPanel({ nestOptions, updateNestOptions }) {
    const [generations, updateGenerations] = React.useState(nestOptions.generations);
    const [population, updatePopulation] = React.useState(nestOptions.population);
    const updateGen = (val) => {
        updateNestOptions({ generations: val });
        updateGenerations(val);
    };
    const updatePopu = (val) => {
        updateNestOptions({ population: val });
        updatePopulation(val);
    };
    return (React.createElement("div", { className: "tool-panel" },
        React.createElement("label", { className: "controls accordion" },
            React.createElement("input", { type: "checkbox", className: "accordion-switcher", defaultChecked: true }),
            React.createElement("p", { className: "caption" },
                "GA",
                React.createElement("span", { className: "value" }, `G${generations}, P${population}`)),
            React.createElement("label", { className: "accordion-body" },
                React.createElement("div", null,
                    React.createElement("span", { className: "text-center header" }, "Generations"),
                    React.createElement("div", { className: "control" },
                        React.createElement(UnitInput, { min: 1, unit: "", decimal: 0, defaultValue: generations, getValue: updateGen })),
                    React.createElement("span", { className: "text-center header" }, "Population"),
                    React.createElement("div", { className: "control" },
                        React.createElement(UnitInput, { min: 2, unit: "", decimal: 0, defaultValue: population, getValue: updatePopu })))))));
}
export default NestGAPanel;
