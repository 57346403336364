var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import NS from 'app/constants/namespaces';
import workareaManager from 'app/svgedit/workarea';
import { getSupportInfo } from 'app/constants/add-on';
import BeamboxPreference from './beambox-preference';
import Constant from './constant';
let openBottomBoundaryRect;
let openBottomBoundarySVG;
const createBoundary = () => __awaiter(void 0, void 0, void 0, function* () {
    openBottomBoundarySVG = document.createElementNS(NS.SVG, 'svg');
    openBottomBoundaryRect = document.createElementNS(NS.SVG, 'rect');
    const canvasBackground = document.getElementById('canvasBackground');
    canvasBackground.appendChild(openBottomBoundarySVG);
    openBottomBoundarySVG.appendChild(openBottomBoundaryRect);
    openBottomBoundarySVG.id = 'open-bottom-boundary';
    openBottomBoundarySVG.setAttribute('width', '100%');
    openBottomBoundarySVG.setAttribute('height', '100%');
    const { width, height } = workareaManager;
    openBottomBoundarySVG.setAttribute('viewBox', `0 0 ${width} ${height}`);
    openBottomBoundarySVG.setAttribute('x', '0');
    openBottomBoundarySVG.setAttribute('y', '0');
    openBottomBoundarySVG.setAttribute('style', 'pointer-events:none');
    openBottomBoundaryRect.setAttribute('fill', '#CCC');
    openBottomBoundaryRect.setAttribute('fill-opacity', '0.4');
    openBottomBoundaryRect.setAttribute('fill-rule', 'evenodd');
    openBottomBoundaryRect.setAttribute('stroke', 'none');
    openBottomBoundaryRect.setAttribute('style', 'pointer-events:none');
    openBottomBoundaryRect.setAttribute('y', '0');
    openBottomBoundaryRect.setAttribute('width', `${Constant.borderless.safeDistance.X * Constant.dpmm}`);
    openBottomBoundaryRect.setAttribute('height', '100%');
});
const show = () => __awaiter(void 0, void 0, void 0, function* () {
    if (!openBottomBoundarySVG)
        yield createBoundary();
    const { width } = workareaManager;
    const x = width - Constant.borderless.safeDistance.X * Constant.dpmm;
    openBottomBoundaryRect.setAttribute('x', x.toString());
    openBottomBoundaryRect.setAttribute('display', 'block');
});
const hide = () => {
    if (!openBottomBoundaryRect)
        return;
    openBottomBoundaryRect.setAttribute('display', 'none');
};
const update = () => {
    const isOpenBottom = BeamboxPreference.read('borderless');
    const supportOpenBottom = getSupportInfo(BeamboxPreference.read('workarea')).openBottom;
    if (isOpenBottom && supportOpenBottom)
        show();
    else
        hide();
};
export default {
    update,
};
