var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Input, InputNumber, Modal, Radio, Row, Tabs } from 'antd';
import alertCaller from 'app/actions/alert-caller';
import deviceMaster from 'helpers/device-master';
import progressCaller from 'app/actions/progress-caller';
import Select from 'app/widgets/AntdSelect';
const initCapacity = 3.85e10;
const initPlScale = 54.8;
export const parsingChipData = (data) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    return ({
        uid: (_a = data.uid) !== null && _a !== void 0 ? _a : '',
        serial: (_b = data.serial) !== null && _b !== void 0 ? _b : '',
        brand: (_c = data.brand) !== null && _c !== void 0 ? _c : '',
        type: (_d = data.type) !== null && _d !== void 0 ? _d : 0,
        color: (_e = data.color) !== null && _e !== void 0 ? _e : 0,
        offset: (_f = data.pos_offset) !== null && _f !== void 0 ? _f : [0, 0, 0],
        plScale: (_g = data.pl_scale) !== null && _g !== void 0 ? _g : initPlScale,
        totalCapacity: (_h = data.total_capacity) !== null && _h !== void 0 ? _h : initCapacity,
        timeUsed: (_j = data.used_time) !== null && _j !== void 0 ? _j : 0,
        verified: data.verified !== false,
    });
};
const verifyChip = (uid, privateKey, publicKey) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    if (((_b = (_a = deviceMaster.currentDevice) === null || _a === void 0 ? void 0 : _a.control) === null || _b === void 0 ? void 0 : _b.getMode()) !== 'cartridge_io') {
        yield deviceMaster.enterCartridgeIOMode();
    }
    try {
        let resp = yield deviceMaster.cartridgeIOJsonRpcReq('cartridge.generate_chip_hash', [uid]);
        if (resp.status !== 'ok') {
            alertCaller.popUpError({ message: `generate_chip_hash failed ${JSON.stringify(resp)}}` });
            return false;
        }
        const { hash } = resp.data.result;
        resp = yield deviceMaster.cartridgeIOJsonRpcReq('crypto.sign', [privateKey, hash]);
        if (resp.status !== 'ok') {
            alertCaller.popUpError({ message: `crypto.sign failed ${JSON.stringify(resp)}}` });
            return false;
        }
        const { sign } = resp.data.result;
        resp = yield deviceMaster.cartridgeIOJsonRpcReq('crypto.verify', [publicKey, hash, sign]);
        if (resp.status !== 'ok' || !resp.data.result) {
            alertCaller.popUpError({ message: `crypto.verify failed ${JSON.stringify(resp)}}` });
            return false;
        }
        resp = yield deviceMaster.cartridgeIOJsonRpcReq('cartridge.load_sign_data', [hash, sign]);
        if (resp.status !== 'ok' || !resp.data.result) {
            alertCaller.popUpError({
                message: `cartridge.load_sign_data failed ${JSON.stringify(resp)}}`,
            });
            return false;
        }
        return resp.data.result;
    }
    catch (error) {
        const message = error.error ? JSON.stringify(error) : error.message;
        alertCaller.popUpError({ message: `Failed to verify chip: ${message}` });
        return false;
    }
});
const writeChipData = (data) => __awaiter(void 0, void 0, void 0, function* () {
    var _c, _d;
    try {
        if (((_d = (_c = deviceMaster.currentDevice) === null || _c === void 0 ? void 0 : _c.control) === null || _d === void 0 ? void 0 : _d.getMode()) !== 'cartridge_io') {
            yield deviceMaster.enterCartridgeIOMode();
        }
        const params = [
            data.brand,
            data.serial,
            data.color,
            data.type,
            data.offset,
            data.plScale,
            data.totalCapacity,
        ];
        const resp = yield deviceMaster.cartridgeIOJsonRpcReq('cartridge.write_mfg_info', params);
        if (resp.status !== 'ok' || !resp.data.result) {
            alertCaller.popUpError({
                message: `cartridge.write_mfg_info failed ${JSON.stringify(resp)}}`,
            });
            return false;
        }
        return true;
    }
    catch (error) {
        const message = error.error ? JSON.stringify(error) : error.message;
        alertCaller.popUpError({ message: `Failed to verify chip: ${message}` });
        return false;
    }
});
let publicKeyCache = '';
let privateKeyCache = '';
let editValueCache = null;
const CartridgeSettingPanel = ({ inkLevel, initData, onClose }) => {
    var _a, _b, _c, _d, _e, _f;
    const [publicKey, setPublicKey] = useState(publicKeyCache);
    const [privateKey, setPrivateKey] = useState(privateKeyCache);
    const [chipSettings, setChipSettings] = useState(initData);
    const [editingValues, setEditingValues] = useState(editValueCache !== null && editValueCache !== void 0 ? editValueCache : Object.assign(Object.assign({}, initData), { plScale: initPlScale, totalCapacity: initCapacity }));
    const [tabKey, setTabKey] = useState('info');
    const handleSave = () => __awaiter(void 0, void 0, void 0, function* () {
        progressCaller.openNonstopProgress({ id: 'chip-settings', message: 'Saving Chip Data' });
        const res = yield writeChipData(editingValues);
        progressCaller.popById('chip-settings');
        // deep copy
        editValueCache = JSON.parse(JSON.stringify(editingValues));
        if (res)
            onClose();
    });
    const tabItems = useMemo(() => [
        {
            key: 'info',
            label: 'Info',
            children: null,
        },
        {
            key: 'edit',
            label: 'Edit',
            children: null,
        },
    ], []);
    const fetchChipSettings = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        var _g, _h;
        progressCaller.openNonstopProgress({
            id: 'chip-settings',
            message: 'Fetching Chip Data',
        });
        if (((_h = (_g = deviceMaster.currentDevice) === null || _g === void 0 ? void 0 : _g.control) === null || _h === void 0 ? void 0 : _h.getMode()) !== 'cartridge_io') {
            yield deviceMaster.enterCartridgeIOMode();
        }
        const chipData = yield deviceMaster.getCartridgeChipData();
        const parsed = parsingChipData(chipData.data.result);
        setChipSettings(parsed);
        if (!editValueCache)
            setEditingValues(Object.assign(Object.assign({}, parsed), { plScale: initPlScale, totalCapacity: initCapacity }));
        progressCaller.popById('chip-settings');
    }), []);
    useEffect(() => () => {
        var _a, _b;
        if (((_b = (_a = deviceMaster.currentDevice) === null || _a === void 0 ? void 0 : _a.control) === null || _b === void 0 ? void 0 : _b.getMode()) === 'cartridge_io')
            deviceMaster.endCartridgeIOMode();
    }, []);
    const colorSelectOptions = useMemo(() => [
        { label: 'None', value: 0 },
        { label: 'Cyan', value: 1 },
        { label: 'Magenta', value: 2 },
        { label: 'Yellow', value: 3 },
        { label: 'Black', value: 4 },
        { label: 'White', value: 5 },
    ], []);
    const handleVerify = () => __awaiter(void 0, void 0, void 0, function* () {
        const res = yield verifyChip(chipSettings.uid, privateKey, publicKey);
        if (res)
            fetchChipSettings();
    });
    const handleTabChange = (key) => {
        setTabKey(key);
    };
    const isEditing = tabKey === 'edit';
    const values = isEditing ? editingValues : chipSettings;
    return (React.createElement(Modal, { open: true, centered: true, title: "Catridge Chip Settings", maskClosable: false, okText: "Save", onOk: handleSave, okButtonProps: { disabled: !isEditing || !values.verified }, onCancel: onClose, width: 600 },
        React.createElement(Row, { gutter: [8, 8] },
            !values.verified && (React.createElement(React.Fragment, null,
                React.createElement(Col, { span: 4 }, "Public Key"),
                React.createElement(Col, { span: 20 },
                    React.createElement(Input, { value: publicKey, onChange: (e) => {
                            const { value } = e.currentTarget;
                            setPublicKey(value);
                            publicKeyCache = value;
                        } })),
                React.createElement(Col, { span: 4 }, "Private Key"),
                React.createElement(Col, { span: 20 },
                    React.createElement(Input, { value: privateKey, onChange: (e) => {
                            const { value } = e.currentTarget;
                            setPrivateKey(value);
                            privateKeyCache = value;
                        } })))),
            React.createElement(Col, { span: 20 },
                React.createElement(Input, { value: values.uid, disabled: true })),
            React.createElement(Col, { span: 4 },
                React.createElement(Button, { type: "primary", onClick: values.verified ? fetchChipSettings : handleVerify }, values.verified ? 'Reload' : 'Verify'))),
        values.verified ? (React.createElement(React.Fragment, null,
            React.createElement(Tabs, { activeKey: tabKey, items: tabItems, onChange: handleTabChange }),
            React.createElement(Row, { gutter: [8, 8] },
                React.createElement(Col, { span: 8 }, "Serial"),
                React.createElement(Col, { span: 16 },
                    React.createElement(Input, { value: values.serial, disabled: !isEditing, onChange: (e) => setEditingValues(Object.assign(Object.assign({}, editingValues), { serial: e.currentTarget.value })) })),
                React.createElement(Col, { span: 8 }, "Brand"),
                React.createElement(Col, { span: 16 },
                    React.createElement(Input, { value: values.brand, disabled: !isEditing, onChange: (e) => setEditingValues(Object.assign(Object.assign({}, editingValues), { brand: e.currentTarget.value })) })),
                React.createElement(Col, { span: 8 }, "Type"),
                React.createElement(Col, { span: 16 },
                    React.createElement(Radio.Group, { value: values.type, optionType: "button", buttonStyle: "solid", disabled: !isEditing, onChange: (e) => setEditingValues(Object.assign(Object.assign({}, editingValues), { type: e.target.value })) },
                        React.createElement(Radio, { value: 0 }, "None"),
                        React.createElement(Radio, { value: 1 }, "SV"),
                        React.createElement(Radio, { value: 2 }, "UV"))),
                React.createElement(Col, { span: 8 }, "Color"),
                React.createElement(Col, { span: 16 },
                    React.createElement(Select, { disabled: !isEditing, value: values.color, options: colorSelectOptions, onChange: (value) => setEditingValues(Object.assign(Object.assign({}, editingValues), { color: value })) })),
                React.createElement(Col, { span: 24 }, "Calibration"),
                React.createElement(Col, { span: 8 },
                    React.createElement(InputNumber, { addonBefore: "X", value: (_b = (_a = values.offset) === null || _a === void 0 ? void 0 : _a[0]) !== null && _b !== void 0 ? _b : 0, addonAfter: "mm", precision: 2, step: 0.01, disabled: !isEditing, onChange: (val) => setEditingValues(Object.assign(Object.assign({}, editingValues), { offset: [val, editingValues.offset[1], editingValues.offset[2]] })) })),
                React.createElement(Col, { span: 8 },
                    React.createElement(InputNumber, { addonBefore: "Y", value: (_d = (_c = values.offset) === null || _c === void 0 ? void 0 : _c[1]) !== null && _d !== void 0 ? _d : 0, addonAfter: "mm", precision: 2, step: 0.01, disabled: !isEditing, onChange: (val) => setEditingValues(Object.assign(Object.assign({}, editingValues), { offset: [editingValues.offset[0], val, editingValues.offset[2]] })) })),
                React.createElement(Col, { span: 8 },
                    React.createElement(InputNumber, { addonBefore: "Z", value: (_f = (_e = values.offset) === null || _e === void 0 ? void 0 : _e[2]) !== null && _f !== void 0 ? _f : 0, addonAfter: "mm", precision: 2, step: 0.01, disabled: !isEditing, onChange: (val) => setEditingValues(Object.assign(Object.assign({}, editingValues), { offset: [editingValues.offset[0], editingValues.offset[1], val] })) })),
                React.createElement(Col, { span: 8 }, "PL Scale"),
                React.createElement(Col, { span: 16 },
                    React.createElement(InputNumber, { disabled: !isEditing, value: values.plScale, onChange: (val) => setEditingValues(Object.assign(Object.assign({}, editingValues), { plScale: val })) })),
                React.createElement(Col, { span: 8 }, "Total Capacity"),
                React.createElement(Col, { span: 16 },
                    React.createElement(InputNumber, { disabled: !isEditing, value: values.totalCapacity, onChange: (val) => setEditingValues(Object.assign(Object.assign({}, editingValues), { totalCapacity: val })) })),
                React.createElement(Col, { span: 8 }, "Ink Storage"),
                React.createElement(Col, { span: 16 },
                    React.createElement(InputNumber, { disabled: true, addonAfter: "%", value: inkLevel * 100, precision: 2 })),
                React.createElement(Col, { span: 8 }, "Time Used"),
                React.createElement(Col, { span: 16 },
                    React.createElement(InputNumber, { disabled: true, addonAfter: "hr", value: values.timeUsed }))))) : (React.createElement("div", null, "Not verified"))));
};
export default CartridgeSettingPanel;
