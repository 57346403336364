import React, { useContext, useEffect, useRef } from 'react';
import Alert from 'app/views/dialogs/Alert';
import browser from 'implementations/browser';
import NonStopProgress from 'app/views/dialogs/NonStopProgress';
import Progress from 'app/views/dialogs/Progress';
import ProgressConstants from 'app/constants/progress-constants';
import { AlertProgressContext } from 'app/contexts/AlertProgressContext';
const isProgress = (d) => d.isProgress;
const AlertsAndProgress = () => {
    const messageRef = useRef();
    const { alertProgressStack } = useContext(AlertProgressContext);
    useEffect(() => {
        const message = messageRef.current;
        if (message) {
            const aElements = message.querySelectorAll('a');
            for (let i = 0; i < aElements.length; i += 1) {
                const a = aElements[i];
                a.addEventListener('click', (e) => {
                    e.preventDefault();
                    browser.open(a.getAttribute('href'));
                });
            }
        }
    });
    if (alertProgressStack.length === 0)
        return React.createElement("div", null);
    const alertModals = alertProgressStack.map((data) => {
        if (isProgress(data)) {
            if (data.type === ProgressConstants.NONSTOP) {
                return React.createElement(NonStopProgress, { key: `${data.key}-${data.id}`, data: data });
            }
            return React.createElement(Progress, { key: `${data.key}-${data.id}`, data: data });
        }
        return React.createElement(Alert, { key: `${data.key}-${data.id}`, data: data });
    });
    return React.createElement("div", null, alertModals);
};
export default AlertsAndProgress;
