import classNames from 'classnames';
import React, { useContext } from 'react';
import { Button } from 'antd';
import TopBarIcons from 'app/icons/top-bar/TopBarIcons';
import { FullWindowPanelContext } from 'app/widgets/FullWindowPanel/FullWindowPanel';
import styles from './BackButton.module.scss';
const BackButton = ({ className = '', children, onClose }) => {
    const { isDesktop, isWindows } = useContext(FullWindowPanelContext);
    return (React.createElement(Button, { className: classNames(styles.enhancer, styles.button, {
            [styles.windows]: isWindows,
            [styles.desktop]: isDesktop,
        }, className), type: "text", icon: React.createElement(TopBarIcons.Undo, null), onClick: onClose }, children));
};
export default BackButton;
