/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import React, { forwardRef } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import LayerPanelIcons from 'app/icons/layer-panel/LayerPanelIcons';
import useI18n from 'helpers/useI18n';
import styles from './PresetsManagementPanel.module.scss';
const PresetList = forwardRef(({ presets, displayList, editingValues, selected, setSelectedPreset, toggleHidePreset, onReorder, }, outerRef) => {
    const t = useI18n().beambox.right_panel.laser_panel.preset_management;
    const handleBeforeDragStart = ({ source }) => {
        setSelectedPreset(displayList[source.index]);
    };
    const handleDragEnd = (result) => {
        if (!result.destination)
            return;
        const sourceIdx = presets.findIndex((p) => p === displayList[result.source.index]);
        const destIdx = presets.findIndex((p) => p === displayList[result.destination.index]);
        if (sourceIdx === -1 || destIdx === -1)
            return;
        const newPresets = Array.from(presets);
        const [removed] = newPresets.splice(sourceIdx, 1);
        newPresets.splice(destIdx, 0, removed);
        onReorder(newPresets);
    };
    return (React.createElement(DragDropContext, { onBeforeDragStart: handleBeforeDragStart, onDragEnd: handleDragEnd },
        React.createElement(Droppable, { droppableId: "droppable" }, (droppableProvided) => (React.createElement("div", Object.assign({}, droppableProvided.droppableProps, { className: styles.list, ref: (node) => {
                droppableProvided.innerRef(node);
                // eslint-disable-next-line no-param-reassign
                outerRef.current = node;
            } }),
            displayList.map((preset, index) => (React.createElement(Draggable, { key: preset.isDefault ? preset.key : preset.name, draggableId: preset.isDefault ? preset.key : preset.name, index: index }, (draggalbeProvided, snapshot) => (React.createElement("div", Object.assign({}, draggalbeProvided.draggableProps, draggalbeProvided.dragHandleProps, { ref: draggalbeProvided.innerRef, className: classNames(styles.item, {
                    [styles.preset]: preset.isDefault,
                    [styles.selected]: preset === selected,
                    [styles.dragging]: snapshot.isDragging,
                }), title: preset.name, onClick: () => setSelectedPreset(preset), "data-key": preset.isDefault ? preset.key : preset.name }),
                React.createElement("div", { className: styles.left },
                    preset.isDefault && React.createElement("div", { className: styles.mark }, t.preset),
                    React.createElement("div", { className: styles.name },
                        editingValues[preset.name] ? '*' : '',
                        preset.name)),
                React.createElement("div", { className: styles.eye, onClick: (e) => {
                        e.stopPropagation();
                        toggleHidePreset(preset);
                    } }, preset.hide ? React.createElement(LayerPanelIcons.Invisible, null) : React.createElement(LayerPanelIcons.Visible, null))))))),
            droppableProvided.placeholder)))));
});
export default PresetList;
