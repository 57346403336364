var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import classNames from 'classnames';
import Icon, { DownOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { Button, Divider, Popover } from 'antd-mobile';
import ObjectPanelIcons from 'app/icons/object-panel/ObjectPanelIcons';
import storage from 'implementations/storage';
import units from 'helpers/units';
import { ObjectPanelContext } from 'app/views/beambox/Right-Panels/contexts/ObjectPanelContext';
import styles from './ObjectPanelItem.module.scss';
const ObjectPanelItem = ({ id, content, label, onClick, disabled, autoClose = true, }) => {
    const context = useContext(ObjectPanelContext);
    const { activeKey, updateActiveKey } = context;
    if (disabled) {
        return null;
    }
    return (React.createElement("div", { id: id, className: classNames(styles['object-panel-item'], {
            [styles.active]: activeKey === id,
        }), onClick: () => __awaiter(void 0, void 0, void 0, function* () {
            updateActiveKey(id);
            yield (onClick === null || onClick === void 0 ? void 0 : onClick());
            if (autoClose)
                setTimeout(() => updateActiveKey(null), 300);
        }) },
        React.createElement("div", { className: styles.main }, content),
        label && React.createElement("div", { className: styles.label }, label)));
};
const ObjectPanelNumber = ({ id, label, value = 0, min = Number.MIN_SAFE_INTEGER, max = Number.MAX_SAFE_INTEGER, updateValue, unit = 'mm', decimal, }) => {
    const context = useContext(ObjectPanelContext);
    const { activeKey } = context;
    const isActive = activeKey === id;
    const shouldConvert2Inch = useMemo(() => unit === 'mm' && storage.get('default-units') === 'inches', [unit]);
    // for unit conversion
    const fakeUnit = shouldConvert2Inch ? 'inch' : 'mm';
    const defaultPrecision = shouldConvert2Inch ? 4 : 2;
    const precision = decimal === undefined ? defaultPrecision : decimal;
    const valueInUnit = (+units.convertUnit(value, fakeUnit, 'mm').toFixed(precision)).toString();
    const [displayValue, setDisplayValue] = React.useState(valueInUnit);
    const [hasInput, setHasInput] = React.useState(false);
    const onChange = (newValue) => {
        setDisplayValue(newValue);
        updateValue(units.convertUnit(+newValue || 0, 'mm', fakeUnit));
        if (!hasInput)
            setHasInput(true);
    };
    React.useEffect(() => {
        if (+displayValue !== +valueInUnit) {
            setDisplayValue(valueInUnit);
        }
        else if (!isActive) {
            let safeValue = Math.min(value, max);
            safeValue = Math.max(safeValue, min);
            if (safeValue !== value)
                updateValue(safeValue);
            else if (!displayValue)
                setDisplayValue('0');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayValue, value, valueInUnit, isActive]);
    const isKeyDisabled = (key) => {
        var _a;
        if (key === '.') {
            return displayValue.includes('.') || precision === 0;
        }
        return ((_a = displayValue.split('.')[1]) === null || _a === void 0 ? void 0 : _a.length) >= precision;
    };
    const NumberKeyboard = () => (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles['input-keys'] },
            ['1', '2', '3', '4', '5', '6', '7', '8', '9', '.', '0'].map((key) => (React.createElement(Button, { key: key, shape: "rounded", disabled: isKeyDisabled(key), onClick: () => {
                    if (hasInput)
                        onChange(displayValue + key);
                    else if (key === '.')
                        onChange('0.');
                    else
                        onChange(key);
                } }, key))),
            React.createElement(Button, { shape: "rounded", onClick: () => onChange(displayValue.substring(0, displayValue.length - 1)) },
                React.createElement(Icon, { className: styles.icon, component: ObjectPanelIcons.Delete }))),
        React.createElement("div", { className: styles['step-buttons'] },
            React.createElement(Button, { shape: "rounded", onClick: () => onChange((+displayValue - 1).toString()) },
                React.createElement(Icon, { className: styles.icon, component: ObjectPanelIcons.Minus })),
            React.createElement(Button, { shape: "rounded", onClick: () => onChange((+displayValue + 1).toString()) },
                React.createElement(Icon, { className: styles.icon, component: ObjectPanelIcons.Plus })))));
    return (React.createElement(Popover, { className: styles['number-keyboard'], visible: isActive, content: React.createElement(NumberKeyboard, null) },
        React.createElement(ObjectPanelItem, { id: id, label: label, content: React.createElement(Button, { className: styles['number-item'], shape: "rounded", size: "mini", fill: "outline" },
                displayValue,
                unit === 'degree' && React.createElement(React.Fragment, null, "\u00B0")), onClick: () => setHasInput(false), autoClose: false })));
};
const ObjectPanelActionList = ({ id, actions, content, label, disabled, }) => {
    const context = useContext(ObjectPanelContext);
    const { activeKey } = context;
    const isActive = activeKey === id;
    const [activeAction, setActiveAction] = React.useState([]);
    const ActionList = () => (React.createElement("div", null, actions.map((action) => (React.createElement("div", { key: action.label, className: classNames(styles.action, {
            [styles.disabled]: action.disabled,
            [styles.active]: activeAction.includes(action.label),
        }), onClick: () => {
            if (!action.disabled) {
                setActiveAction([...activeAction, action.label]);
                setTimeout(() => setActiveAction((value) => {
                    const idx = value.indexOf(action.label);
                    if (idx !== -1) {
                        value.splice(idx, 1);
                    }
                    return [...value];
                }), 1000);
                action.onClick();
            }
        } },
        action.icon,
        React.createElement("span", { className: styles.label }, action.label))))));
    return (React.createElement(Popover, { className: styles['action-list'], visible: isActive, content: React.createElement(ActionList, null) },
        React.createElement(ObjectPanelItem, { id: id, content: content, label: label, disabled: disabled, autoClose: false })));
};
const ObjectPanelSelect = ({ id, selected = { value: '', label: '' }, options, onChange, label, }) => {
    const context = useContext(ObjectPanelContext);
    const { activeKey, updateActiveKey } = context;
    const isActive = activeKey === id;
    const ref = useRef(null);
    const SelectOptions = () => (React.createElement("div", { id: `${id}-options`, className: styles['select-options'] }, options.map((option) => (React.createElement("div", { key: option.value, className: classNames(styles.option, {
            [styles.active]: selected.value === option.value,
        }), onClick: () => __awaiter(void 0, void 0, void 0, function* () {
            yield onChange(option.value);
            updateActiveKey(null);
        }) },
        React.createElement("span", null, option.label))))));
    useEffect(() => {
        if (ref.current && isActive) {
            const ele = document.querySelector(`#${id}-options .${styles.active}`);
            if (ele) {
                ele.parentElement.scrollTop = ele.offsetTop - 20;
            }
        }
    }, [id, isActive, ref]);
    return (React.createElement(Popover, { ref: ref, className: styles.select, visible: isActive, content: React.createElement(SelectOptions, null) },
        React.createElement(ObjectPanelItem, { id: id, content: React.createElement(Button, { className: styles['select-button'], shape: "rounded", size: "mini", fill: "outline" },
                React.createElement("div", { className: styles['selected-content'] },
                    selected.label,
                    React.createElement(DownOutlined, null))), label: label, disabled: options.length <= 1, autoClose: false })));
};
const ObjectPanelDivider = () => (React.createElement(Divider, { className: styles.divider, direction: "vertical" }));
const ObjectPanelMask = () => {
    const { activeKey, updateActiveKey } = useContext(ObjectPanelContext);
    return (React.createElement("div", { className: classNames(styles.mask, { [styles.hide]: activeKey === null }), onClick: () => updateActiveKey(null) }));
};
export default {
    ActionList: ObjectPanelActionList,
    Divider: ObjectPanelDivider,
    Item: ObjectPanelItem,
    Number: ObjectPanelNumber,
    Select: ObjectPanelSelect,
    Mask: ObjectPanelMask,
};
