// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-pages-Beambox-module__buttons--t1fyw{position:fixed;z-index:1;margin-right:20px;bottom:20px;right:258px;display:inline-flex;align-items:flex-start;gap:8px}.src-web-app-pages-Beambox-module__buttons--t1fyw:where(.src-web-app-pages-Beambox-module__mac--bbZ1j){right:242px}@media screen and (max-width: 600px){.src-web-app-pages-Beambox-module__buttons--t1fyw{flex-direction:column;align-items:flex-end;bottom:70px;right:10px;margin-right:0}}", "",{"version":3,"sources":["webpack://./src/web/app/pages/Beambox.module.scss"],"names":[],"mappings":"AAAA,kDACE,cAAA,CACA,SAAA,CACA,iBAAA,CACA,WAAA,CACA,WAAA,CAEA,mBAAA,CACA,sBAAA,CACA,OAAA,CAEA,uGACE,WAAA,CAGF,qCAfF,kDAgBI,qBAAA,CACA,oBAAA,CACA,WAAA,CACA,UAAA,CACA,cAAA,CAAA","sourcesContent":[".buttons {\n  position: fixed;\n  z-index: 1;\n  margin-right: 20px;\n  bottom: 20px;\n  right: 258px;\n\n  display: inline-flex;\n  align-items: flex-start;\n  gap: 8px;\n\n  &:where(.mac) {\n    right: 242px;\n  }\n\n  @media screen and (max-width: 600px) {\n    flex-direction: column;\n    align-items: flex-end;\n    bottom: 70px;\n    right: 10px;\n    margin-right: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttons": "src-web-app-pages-Beambox-module__buttons--t1fyw",
	"mac": "src-web-app-pages-Beambox-module__mac--bbZ1j"
};
export default ___CSS_LOADER_EXPORT___;
