var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, InputNumber, Modal, Segmented, Spin, Tooltip } from 'antd';
import { LoadingOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import beamboxPreference from 'app/actions/beambox/beambox-preference';
import FramingIcons from 'app/icons/framing/FramingIcons';
import FramingTaskManager, { FramingType } from 'helpers/device/framing';
import getDevice from 'helpers/device/get-device';
import icons from 'app/icons/icons';
import MessageCaller, { MessageLevel } from 'app/actions/message-caller';
import useI18n from 'helpers/useI18n';
import { addDialogComponent, isIdExist, popDialogById } from 'app/actions/dialog-controller';
import { getSupportInfo } from 'app/constants/add-on';
import { promarkModels } from 'app/actions/beambox/constant';
import styles from './FramingModal.module.scss';
// TODO: add unit test
const FramingModal = ({ device, onClose }) => {
    var _a, _b, _c;
    const lang = useI18n();
    const t = lang.framing;
    const [playing, setPlaying] = useState(false);
    const manager = useRef(null);
    useEffect(() => {
        manager.current = new FramingTaskManager(device);
        manager.current.on('status-change', (status) => setPlaying(status));
        const messageKey = 'framing';
        manager.current.on('close-message', () => MessageCaller.closeMessage(messageKey));
        manager.current.on('message', (message) => {
            MessageCaller.closeMessage(messageKey);
            MessageCaller.openMessage({ key: messageKey, level: MessageLevel.LOADING, content: message });
        });
        return () => {
            var _a;
            (_a = manager.current) === null || _a === void 0 ? void 0 : _a.stopFraming();
            MessageCaller.closeMessage(messageKey);
        };
    }, [device]);
    const supportInfo = useMemo(() => getSupportInfo(device.model), [device]);
    const [lowLaser, setLowLaser] = useState((_a = beamboxPreference.read('low_power')) !== null && _a !== void 0 ? _a : 10);
    const [type, setType] = useState(FramingType.Framing);
    const options = useMemo(() => {
        if (promarkModels.has(device.model))
            return [FramingType.Framing];
        return [FramingType.Framing, FramingType.Hull, FramingType.AreaCheck];
    }, [device.model]);
    const handleOk = () => {
        var _a;
        (_a = manager.current) === null || _a === void 0 ? void 0 : _a.startFraming(type, { lowPower: supportInfo.framingLowLaser ? lowLaser : 0 });
    };
    const handleStop = () => {
        var _a;
        (_a = manager.current) === null || _a === void 0 ? void 0 : _a.stopFraming();
    };
    return (React.createElement(Modal, { open: true, centered: true, width: 360, title: t.framing, maskClosable: false, onCancel: onClose, onOk: playing ? handleStop : handleOk, footer: React.createElement("div", { className: styles.footer },
            React.createElement(Button, { className: styles.button, onClick: onClose }, lang.alert.cancel),
            React.createElement(Button, { className: styles.button, onClick: playing ? handleStop : handleOk, type: "primary" },
                playing ? lang.alert.stop : lang.device.start,
                playing ? (React.createElement(Spin, { indicator: React.createElement(LoadingOutlined, { className: styles.icon, spin: true }) })) : (React.createElement(icons.Play, { className: styles.icon })))) },
        React.createElement("div", { className: styles.container },
            supportInfo.framingLowLaser && (React.createElement("div", { className: styles['low-laser'] },
                React.createElement("div", { className: styles.left },
                    React.createElement(Tooltip, { title: t.low_laser_desc },
                        React.createElement(QuestionCircleOutlined, { className: styles.icon })),
                    t.low_laser,
                    ":"),
                React.createElement(InputNumber, { className: styles.input, min: 0, max: 20, value: lowLaser, onChange: (val) => setLowLaser(val), addonAfter: "%", controls: false, precision: 0 }))),
            React.createElement(Segmented, { className: styles.segmented, value: type, onChange: (val) => setType(val), options: options.map((opt) => ({
                    label: (React.createElement("div", { className: styles.seg },
                        {
                            [FramingType.Framing]: React.createElement(FramingIcons.Framing, null),
                            [FramingType.Hull]: React.createElement(FramingIcons.Hull, null),
                            [FramingType.AreaCheck]: React.createElement(FramingIcons.AreaCheck, null),
                        }[opt],
                        React.createElement("div", null, {
                            [FramingType.Framing]: t.framing,
                            [FramingType.Hull]: t.hull,
                            [FramingType.AreaCheck]: t.area_check,
                        }[opt]))),
                    value: opt,
                })) }),
            React.createElement("div", { className: styles.desc },
                React.createElement("div", { className: styles.title }, (_b = {
                    [FramingType.Framing]: t.framing,
                    [FramingType.Hull]: t.hull,
                    [FramingType.AreaCheck]: t.area_check,
                }[type]) !== null && _b !== void 0 ? _b : t.framing),
                React.createElement("div", { className: styles.content }, (_c = {
                    [FramingType.Framing]: t.framing_desc,
                    [FramingType.Hull]: t.hull_desc,
                    [FramingType.AreaCheck]: t.areacheck_desc,
                }[type]) !== null && _c !== void 0 ? _c : t.framing_desc)))));
};
export default FramingModal;
export const showFramingModal = () => __awaiter(void 0, void 0, void 0, function* () {
    const { device } = yield getDevice();
    if (!device)
        return;
    if (isIdExist('framing-modal'))
        return;
    addDialogComponent('framing-modal', React.createElement(FramingModal, { device: device, onClose: () => popDialogById('framing-modal') }));
});
