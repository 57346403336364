var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable no-await-in-loop */
import exifr from 'exifr';
// for rgb image, we need to transform it to cmyk
// for cmyk images we need to update the image data
const updateImageForSpliting = (layerElement) => __awaiter(void 0, void 0, void 0, function* () {
    const images = layerElement.querySelectorAll('image');
    for (let i = 0; i < images.length; i += 1) {
        const image = images[i];
        const origImage = image.getAttribute('origImage');
        let exifrData;
        try {
            exifrData = yield exifr.parse(origImage, { icc: true, tiff: { multiSegment: true } });
        }
        catch (e) {
            console.error('Failed to parse exif data', e);
        }
        if ((exifrData === null || exifrData === void 0 ? void 0 : exifrData.ColorSpaceData) === 'CMYK') {
            image.setAttribute('cmyk', '1');
        }
    }
});
export default updateImageForSpliting;
