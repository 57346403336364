import React from 'react';
import classNames from 'classnames';
import constant from 'app/actions/beambox/constant';
import DpiInfo from 'app/components/beambox/DpiInfo';
import PathPreview from 'app/components/beambox/path-preview/PathPreview';
import Ruler from 'app/components/beambox/svg-editor/Ruler';
import svgEditor from 'app/actions/beambox/svg-editor';
import Workarea from 'app/components/beambox/svg-editor/Workarea';
import workareaManager from 'app/svgedit/workarea';
import ZoomBlock from 'app/components/beambox/ZoomBlock';
import { CanvasContext, CanvasMode } from 'app/contexts/CanvasContext';
import styles from './SvgEditor.module.scss';
export default class SvgEditor extends React.Component {
    constructor() {
        super(...arguments);
        this.renderSvgEditor = () => {
            const { mode } = this.context;
            const platformClassNames = classNames({
                mac: window.os === 'MacOS',
                [styles.mac]: window.os === 'MacOS',
            });
            return (React.createElement("div", { id: "svg_editor", className: platformClassNames, style: mode === CanvasMode.PathPreview ? { display: 'none' } : {} },
                React.createElement("div", null,
                    React.createElement(Ruler, null),
                    React.createElement(Workarea, { className: platformClassNames }),
                    React.createElement("div", { id: "tool_import", style: { display: 'none' } }),
                    React.createElement("input", { id: "text", type: "text", size: 35 }),
                    React.createElement("div", { id: "cur_context_panel" }),
                    React.createElement("div", { id: "option_lists", className: "dropdown" }))));
        };
    }
    componentDidMount() {
        const { $ } = window;
        $(svgEditor.init);
    }
    // eslint-disable-next-line class-methods-use-this
    handleDisableHref(e) {
        e.preventDefault();
        e.stopPropagation();
    }
    render() {
        const { mode } = this.context;
        // HIDE ALMOST ALL TOOLS USING CSS
        return (React.createElement(React.Fragment, null,
            React.createElement("div", null,
                this.renderSvgEditor(),
                React.createElement("div", { id: "dialog_box" },
                    React.createElement("div", { className: "overlay" }),
                    React.createElement("div", { id: "dialog_container" },
                        React.createElement("div", { id: "dialog_content" }),
                        React.createElement("div", { id: "dialog_buttons" })))),
            mode === CanvasMode.PathPreview && React.createElement(PathPreview, null),
            mode !== CanvasMode.PathPreview && (React.createElement(React.Fragment, null,
                React.createElement(ZoomBlock, { setZoom: (zoom) => workareaManager.zoom(zoom / constant.dpmm), resetView: workareaManager.resetView }),
                React.createElement(DpiInfo, null)))));
    }
}
SvgEditor.contextType = CanvasContext;
