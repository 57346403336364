import * as React from 'react';
import classNames from 'classnames';
import Controls from 'app/components/settings/Control';
import i18n from 'helpers/i18n';
import PathInput, { InputType } from 'app/widgets/PathInput';
import SelectControl from 'app/components/settings/SelectControl';
import UnitInput from 'app/widgets/Unit-Input-v2';
function AutoSave({ isWeb, autoSaveOptions, editingAutosaveConfig, warnings, updateState, }) {
    if (isWeb)
        return null;
    const { lang } = i18n;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "subtitle" }, lang.settings.groups.autosave),
        React.createElement(SelectControl, { id: "set-auto-save", label: lang.settings.autosave_enabled, options: autoSaveOptions, onChange: (e) => {
                updateState({
                    editingAutosaveConfig: Object.assign(Object.assign({}, editingAutosaveConfig), { enabled: e.target.value === 'TRUE' }),
                });
            } }),
        React.createElement(Controls, { label: lang.settings.autosave_path, warningText: warnings.autosave_directory },
            React.createElement(PathInput, { "data-id": "location-input", buttonTitle: lang.general.choose_folder, className: classNames({ 'with-error': !!warnings.autosave_directory }), defaultValue: editingAutosaveConfig.directory, forceValidValue: false, getValue: (val, isValid) => {
                    if (!isValid) {
                        warnings.autosave_directory = lang.settings.autosave_path_not_correct;
                    }
                    else {
                        delete warnings.autosave_directory;
                    }
                    updateState({
                        editingAutosaveConfig: Object.assign(Object.assign({}, editingAutosaveConfig), { directory: val }),
                        warnings: Object.assign({}, warnings),
                    });
                }, type: InputType.FOLDER })),
        React.createElement(Controls, { label: lang.settings.autosave_interval },
            React.createElement(UnitInput, { id: "save-every", unit: lang.monitor.minute, min: 1, max: 60, decimal: 0, defaultValue: editingAutosaveConfig.timeInterval, getValue: (val) => {
                    updateState({
                        editingAutosaveConfig: Object.assign(Object.assign({}, editingAutosaveConfig), { timeInterval: val }),
                    });
                }, className: { half: true } })),
        React.createElement(Controls, { label: lang.settings.autosave_number },
            React.createElement(UnitInput, { id: "number-of-auto-save", min: 1, max: 10, decimal: 0, defaultValue: editingAutosaveConfig.fileNumber, getValue: (val) => {
                    updateState({
                        editingAutosaveConfig: Object.assign(Object.assign({}, editingAutosaveConfig), { fileNumber: val }),
                    });
                }, className: { half: true } }))));
}
export default AutoSave;
