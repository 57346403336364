import React from 'react';
import units from 'helpers/units';
function ProgressBar({ simTime, simTimeMax, handleSimTimeChange }) {
    const percentage = `${Math.round(10000 * (simTime / simTimeMax)) / 100}%`;
    return (React.createElement("div", null,
        React.createElement("div", { className: "label pull-left" }),
        React.createElement("div", { id: "progress-bar", className: "path-preview-slider-container" },
            React.createElement("input", { className: "slider", type: "range", min: 0, max: units.convertTimeUnit(simTimeMax, 'ms', 'm'), step: units.convertTimeUnit(0.1, 'ms'), value: units.convertTimeUnit(simTime, 'ms', 'm'), style: {
                    // @ts-ignore Set variable for css to use
                    '--percentage': percentage,
                }, onChange: (e) => handleSimTimeChange(units.convertTimeUnit(Number(e.target.value), 'm', 'ms')) }))));
}
export default ProgressBar;
