import React from 'react';
import i18n from 'helpers/i18n';
import storage from 'implementations/storage';
import UnitInput from 'app/widgets/Unit-Input-v2';
const LANG = i18n.lang.beambox.tool_panels;
function NestSpacingPanel({ spacing: spacingProps, onValueChange }) {
    const [spacing, updateSpacing] = React.useState(spacingProps);
    const updateVal = (val) => {
        onValueChange(val);
        updateSpacing(val);
    };
    const getValueCaption = () => {
        const units = storage.get('default-units') || 'mm';
        return units === 'inches' ? `${Number(spacing / 25.4).toFixed(3)}\"` : `${spacing} mm`;
    };
    return (React.createElement("div", { className: "tool-panel" },
        React.createElement("label", { className: "controls accordion" },
            React.createElement("input", { type: "checkbox", className: "accordion-switcher", defaultChecked: true }),
            React.createElement("p", { className: "caption" },
                LANG._nest.spacing,
                React.createElement("span", { className: "value" }, getValueCaption())),
            React.createElement("label", { className: "accordion-body" },
                React.createElement("div", null,
                    React.createElement("div", { className: "control nest-spacing" },
                        React.createElement(UnitInput, { min: 0, unit: "mm", defaultValue: spacing, getValue: (val) => updateVal(val) })))))));
}
export default NestSpacingPanel;
