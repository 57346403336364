var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import deviceMaster from 'helpers/device-master';
import durationFormatter from 'helpers/duration-formatter';
import i18n from 'helpers/i18n';
import progressCaller from 'app/actions/progress-caller';
import { addFisheyeCalibrateImg, doFishEyeCalibration, startFisheyeCalibrate, } from 'helpers/camera-calibration-helper';
import { getWorkarea } from 'app/constants/workarea-constants';
import alertCaller from 'app/actions/alert-caller';
export const calibrateWithDevicePictures = () => __awaiter(void 0, void 0, void 0, function* () {
    const { lang } = i18n;
    const tCali = lang.calibration;
    const tCameraData = lang.camera_data_backup;
    const progressId = 'calibrate-with-device-pictures';
    let canceled = false;
    try {
        progressCaller.openSteppingProgress({
            id: progressId,
            message: tCali.downloading_pictures,
            onCancel: () => {
                canceled = true;
            },
        });
        const ls = yield deviceMaster.ls('camera_calib');
        const { files } = ls;
        const [names, heights] = files.reduce((acc, name) => {
            const res = name.match(/^pic_([-\d.]+)_top_left\.jpg$/);
            if (res) {
                const height = res[1];
                acc[0].push(name);
                acc[1].push(height);
            }
            return acc;
        }, [[], []]);
        yield startFisheyeCalibrate();
        if (canceled)
            return null;
        let s = Date.now();
        for (let i = 0; i < names.length; i += 1) {
            if (canceled)
                return null;
            // eslint-disable-next-line no-await-in-loop
            const [, blob] = yield deviceMaster.downloadFile('camera_calib', names[i], 
            // eslint-disable-next-line @typescript-eslint/no-loop-func
            ({ left, size }) => {
                const current = 1 - left / size;
                const totalProgress = (current + i) / names.length;
                const timeElapsed = (Date.now() - s) / 1000;
                const timeLeft = durationFormatter(timeElapsed / totalProgress - timeElapsed);
                progressCaller.update(progressId, {
                    message: `${tCali.downloading_pictures} ${i + 1}/${names.length}<br/>${tCameraData.estimated_time_left} ${timeLeft}`,
                    percentage: Math.round(100 * totalProgress),
                });
            });
            // eslint-disable-next-line no-await-in-loop
            const res = yield addFisheyeCalibrateImg(parseFloat(heights[i]), blob);
            if (!res)
                console.warn(`Fail to add image of height ${heights[i]}`);
        }
        if (canceled)
            return null;
        progressCaller.update(progressId, {
            message: tCali.calibrating_with_device_pictures,
            percentage: 0,
        });
        s = Date.now();
        const data = yield doFishEyeCalibration((val) => {
            if (val > 0) {
                const timeElapsed = (Date.now() - s) / 1000;
                const timeLeft = durationFormatter(timeElapsed / val - timeElapsed);
                progressCaller.update(progressId, {
                    message: `${tCali.calibrating_with_device_pictures}<br/>${tCameraData.estimated_time_left} ${timeLeft}`,
                    percentage: Math.round(100 * val),
                });
            }
        });
        if (canceled)
            return null;
        return {
            k: data.k,
            d: data.d,
            rvec: data.rvec,
            tvec: data.tvec,
            rvec_polyfit: data.rvec_polyfit,
            tvec_polyfit: data.tvec_polyfit,
        };
    }
    catch (error) {
        console.error(error);
        alertCaller.popUpError({ message: tCali.failed_to_calibrate_with_pictures });
        return null;
    }
    finally {
        progressCaller.popById(progressId);
    }
});
export const saveCheckPoint = (param) => __awaiter(void 0, void 0, void 0, function* () {
    const dataString = JSON.stringify(param);
    const dataBlob = new Blob([dataString], { type: 'application/json' });
    yield deviceMaster.uploadToDirectory(dataBlob, 'fisheye', 'checkpoint.json');
});
export const getMaterialHeight = (position = 'E') => __awaiter(void 0, void 0, void 0, function* () {
    const device = deviceMaster.currentDevice;
    yield deviceMaster.enterRawMode();
    yield deviceMaster.rawHome();
    yield deviceMaster.rawStartLineCheckMode();
    const workarea = getWorkarea(device.info.model, 'ado1');
    const { cameraCenter, deep } = workarea;
    if (cameraCenter && position === 'E')
        yield deviceMaster.rawMove({ x: cameraCenter[0], y: cameraCenter[1], f: 7500 });
    yield deviceMaster.rawEndLineCheckMode();
    yield deviceMaster.rawAutoFocus();
    const { didAf, z } = yield deviceMaster.rawGetProbePos();
    if (cameraCenter && position === 'E')
        yield deviceMaster.rawMove({ x: 0, y: 0, f: 7500 });
    yield deviceMaster.rawLooseMotor();
    yield deviceMaster.endRawMode();
    if (!didAf)
        throw new Error('Auto focus failed');
    return Math.round((deep - z) * 100) / 100;
});
export const prepareToTakePicture = () => __awaiter(void 0, void 0, void 0, function* () {
    yield deviceMaster.enterRawMode();
    yield deviceMaster.rawHome();
    yield deviceMaster.rawHomeZ();
    yield deviceMaster.rawLooseMotor();
    yield deviceMaster.endRawMode();
});
export default {
    calibrateWithDevicePictures,
    getMaterialHeight,
    prepareToTakePicture,
    saveCheckPoint,
};
