// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-views-beambox-Right-Panels-ConfigPanel-AdvancedBlock-module__container--WbpZO{padding:10px 0 !important}.src-web-app-views-beambox-Right-Panels-ConfigPanel-AdvancedBlock-module__container--WbpZO .ant-collapse-header-text{font-weight:bold;font-size:10pt}.src-web-app-views-beambox-Right-Panels-ConfigPanel-AdvancedBlock-module__container--WbpZO .src-web-app-views-beambox-Right-Panels-ConfigPanel-AdvancedBlock-module__panel--NLig2{padding:0;margin-top:20px;display:flex;flex-direction:column;gap:20px;font-size:10pt}", "",{"version":3,"sources":["webpack://./src/web/app/views/beambox/Right-Panels/ConfigPanel/AdvancedBlock.module.scss"],"names":[],"mappings":"AAAA,2FACE,yBAAA,CAEA,qHACE,gBAAA,CACA,cAAA,CAGF,kLACE,SAAA,CACA,eAAA,CACA,YAAA,CACA,qBAAA,CACA,QAAA,CACA,cAAA","sourcesContent":[".container {\n  padding: 10px 0 !important;\n\n  :global(.ant-collapse-header-text) {\n    font-weight: bold;\n    font-size: 10pt;\n  }\n\n  .panel {\n    padding: 0;\n    margin-top: 20px;\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n    font-size: 10pt;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "src-web-app-views-beambox-Right-Panels-ConfigPanel-AdvancedBlock-module__container--WbpZO",
	"panel": "src-web-app-views-beambox-Right-Panels-ConfigPanel-AdvancedBlock-module__panel--NLig2"
};
export default ___CSS_LOADER_EXPORT___;
