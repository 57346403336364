import React, { memo } from 'react';
import { Button } from 'antd';
import DimensionPanelIcons from 'app/icons/dimension-panel/DimensionPanelIcons';
import ObjectPanelItem from 'app/views/beambox/Right-Panels/ObjectPanelItem';
import useI18n from 'helpers/useI18n';
import { getSVGAsync } from 'helpers/svg-editor-helper';
import { useIsMobile } from 'helpers/system-helper';
import styles from './FlipButtons.module.scss';
let svgCanvas;
getSVGAsync((globalSVG) => {
    svgCanvas = globalSVG.Canvas;
});
const FlipButtons = () => {
    const t = useI18n().beambox.right_panel.object_panel;
    const isMobile = useIsMobile();
    if (isMobile)
        return (React.createElement(ObjectPanelItem.ActionList, { id: "flip", actions: [
                {
                    icon: React.createElement(DimensionPanelIcons.HFlip, null),
                    label: t.hflip,
                    onClick: () => svgCanvas.flipSelectedElements(-1, 1),
                },
                {
                    icon: React.createElement(DimensionPanelIcons.VFlip, null),
                    label: t.vflip,
                    onClick: () => svgCanvas.flipSelectedElements(1, -1),
                },
            ], content: React.createElement(DimensionPanelIcons.HFlip, null), label: t.flip }));
    return (React.createElement("div", { className: styles.container },
        React.createElement(Button, { id: "horizontal_flip", type: "text", icon: React.createElement(DimensionPanelIcons.HFlip, null), onClick: () => svgCanvas.flipSelectedElements(-1, 1), title: t.hflip }),
        React.createElement(Button, { id: "vertical_flip", type: "text", icon: React.createElement(DimensionPanelIcons.VFlip, null), onClick: () => svgCanvas.flipSelectedElements(1, -1), title: t.vflip })));
};
export default memo(FlipButtons);
