/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import cx from 'classnames';
import listener from './globalEventListener';
import AbstractMenu from './AbstractMenu';
import SubMenu from './SubMenu';
import { hideMenu } from './actions';
import { cssClasses, callIfExists, store } from './helpers';
export default class ContextMenu extends AbstractMenu {
    constructor(props) {
        super(props);
        this.getSubMenuType = () => SubMenu;
        this.registerHandlers = () => {
            document.addEventListener('mousedown', this.handleOutsideClick);
            document.addEventListener('touchstart', this.handleOutsideClick);
            if (!this.props.preventHideOnScroll)
                document.addEventListener('scroll', this.handleHide);
            if (!this.props.preventHideOnContextMenu)
                document.addEventListener('contextmenu', this.handleHide);
            document.addEventListener('keydown', this.handleKeyNavigation);
            if (!this.props.preventHideOnResize)
                window.addEventListener('resize', this.handleHide);
        };
        this.unregisterHandlers = () => {
            document.removeEventListener('mousedown', this.handleOutsideClick);
            document.removeEventListener('touchstart', this.handleOutsideClick);
            document.removeEventListener('scroll', this.handleHide);
            document.removeEventListener('contextmenu', this.handleHide);
            document.removeEventListener('keydown', this.handleKeyNavigation);
            window.removeEventListener('resize', this.handleHide);
        };
        this.handleShow = (e) => {
            if (e.detail.id !== this.props.id || this.state.isVisible)
                return;
            const { x, y } = e.detail.position;
            this.setState({ isVisible: true, x, y });
            this.registerHandlers();
            callIfExists(this.props.onShow, e);
        };
        this.handleHide = (e) => {
            if (this.state.isVisible && (!e.detail || !e.detail.id || e.detail.id === this.props.id)) {
                this.unregisterHandlers();
                this.setState({ isVisible: false, selectedItem: null, forceSubMenuOpen: false });
                callIfExists(this.props.onHide, e);
            }
        };
        this.handleOutsideClick = (e) => {
            if (!this.menu.contains(e.target))
                hideMenu();
        };
        this.handleMouseLeave = (event) => {
            event.preventDefault();
            callIfExists(this.props.onMouseLeave, event, Object.assign(Object.assign({}, this.props.data), store.data), store.target);
            if (this.props.hideOnLeave)
                hideMenu();
        };
        this.handleContextMenu = (e) => {
            if (process.env.NODE_ENV === 'production') {
                e.preventDefault();
            }
            this.handleHide(e);
        };
        this.hideMenu = (e) => {
            if (e.keyCode === 27 || e.keyCode === 13) { // ECS or enter
                hideMenu();
            }
        };
        this.getMenuPosition = (x = 0, y = 0) => {
            const menuStyles = {
                top: y,
                left: x,
            };
            if (!this.menu)
                return menuStyles;
            const { innerWidth, innerHeight } = window;
            const rect = this.menu.getBoundingClientRect();
            if (y + rect.height > innerHeight) {
                menuStyles.top -= rect.height;
            }
            if (x + rect.width > innerWidth) {
                menuStyles.left -= rect.width;
            }
            if (menuStyles.top < 0) {
                menuStyles.top = rect.height < innerHeight ? (innerHeight - rect.height) / 2 : 0;
            }
            if (menuStyles.left < 0) {
                menuStyles.left = rect.width < innerWidth ? (innerWidth - rect.width) / 2 : 0;
            }
            return menuStyles;
        };
        this.getRTLMenuPosition = (x = 0, y = 0) => {
            const menuStyles = {
                top: y,
                left: x,
            };
            if (!this.menu)
                return menuStyles;
            const { innerWidth, innerHeight } = window;
            const rect = this.menu.getBoundingClientRect();
            // Try to position the menu on the left side of the cursor
            menuStyles.left = x - rect.width;
            if (y + rect.height > innerHeight) {
                menuStyles.top -= rect.height;
            }
            if (menuStyles.left < 0) {
                menuStyles.left += rect.width;
            }
            if (menuStyles.top < 0) {
                menuStyles.top = rect.height < innerHeight ? (innerHeight - rect.height) / 2 : 0;
            }
            if (menuStyles.left + rect.width > innerWidth) {
                menuStyles.left = rect.width < innerWidth ? (innerWidth - rect.width) / 2 : 0;
            }
            return menuStyles;
        };
        this.menuRef = (c) => {
            this.menu = c;
        };
        this.state = Object.assign(Object.assign({}, this.state), { x: 0, y: 0, isVisible: false });
    }
    componentDidMount() {
        this.listenId = listener.register(this.handleShow, this.handleHide);
    }
    componentDidUpdate() {
        const wrapper = window.requestAnimationFrame || setTimeout;
        if (this.state.isVisible) {
            wrapper(() => {
                const { x, y } = this.state;
                const { top, left } = this.props.rtl
                    ? this.getRTLMenuPosition(x, y)
                    : this.getMenuPosition(x, y);
                wrapper(() => {
                    if (!this.menu)
                        return;
                    this.menu.style.top = `${top}px`;
                    this.menu.style.left = `${left}px`;
                    this.menu.style.opacity = '1';
                    this.menu.style.pointerEvents = 'auto';
                });
            });
        }
        else {
            wrapper(() => {
                if (!this.menu)
                    return;
                this.menu.style.opacity = '0';
                this.menu.style.pointerEvents = 'none';
            });
        }
    }
    componentWillUnmount() {
        if (this.listenId) {
            listener.unregister(this.listenId);
        }
        this.unregisterHandlers();
    }
    render() {
        const { children, className, style } = this.props;
        const { isVisible } = this.state;
        const inlineStyle = Object.assign(Object.assign({}, style), { position: 'fixed', opacity: 0, pointerEvents: 'none' });
        const menuClassnames = cx(cssClasses.menu, className, {
            [cssClasses.menuVisible]: isVisible,
        });
        return (React.createElement("nav", { 
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
            role: "menu", tabIndex: -1, ref: this.menuRef, style: inlineStyle, className: menuClassnames, onContextMenu: this.handleContextMenu, onMouseLeave: this.handleMouseLeave }, this.renderChildren(children)));
    }
}
ContextMenu.defaultProps = {
    className: '',
    data: {},
    hideOnLeave: false,
    rtl: false,
    onHide() { return null; },
    onMouseLeave() { return null; },
    onShow() { return null; },
    preventHideOnContextMenu: false,
    preventHideOnResize: false,
    preventHideOnScroll: false,
    style: {},
};
