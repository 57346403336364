// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-components-beambox-top-bar-PathPreviewButton-module__button--fYglu{height:30px;opacity:.7;cursor:pointer;-webkit-app-region:no-drag}.src-web-app-components-beambox-top-bar-PathPreviewButton-module__button--fYglu.src-web-app-components-beambox-top-bar-PathPreviewButton-module__disabled--YHpAy{opacity:.2;pointer-events:none}.src-web-app-components-beambox-top-bar-PathPreviewButton-module__button--fYglu:hover:not(.src-web-app-components-beambox-top-bar-PathPreviewButton-module__disabled--YHpAy){opacity:1}@media screen and (max-width: 600px){.src-web-app-components-beambox-top-bar-PathPreviewButton-module__button--fYglu:hover:not(.src-web-app-components-beambox-top-bar-PathPreviewButton-module__disabled--YHpAy){opacity:.7}}.src-web-app-components-beambox-top-bar-PathPreviewButton-module__button--fYglu.src-web-app-components-beambox-top-bar-PathPreviewButton-module__highlighted--zrheW{opacity:1;background:#fff;color:#000}", "",{"version":3,"sources":["webpack://./src/web/app/components/beambox/top-bar/PathPreviewButton.module.scss","webpack://./src/web/app/components/beambox/top-bar/_mixins.scss"],"names":[],"mappings":"AAEA,gFCDE,WAAA,CACA,UAAA,CACA,cAAA,CACA,0BAAA,CACA,iKACE,UAAA,CACA,mBAAA,CAEF,6KACE,SAAA,CAEF,qCACE,6KACE,UAAA,CAAA,CDTJ,oKACE,SAAA,CACA,eAAA,CACA,UAAA","sourcesContent":["@use './_mixins.scss' as mixins;\n\n.button {\n  @include mixins.button();\n\n  &.highlighted {\n    opacity: 1;\n    background: #ffffff;\n    color: #000000;\n  }\n}\n","@mixin button() {\n  height: 30px;\n  opacity: 0.7;\n  cursor: pointer;\n  -webkit-app-region: no-drag;\n  &.disabled {\n    opacity: 0.2;\n    pointer-events: none;\n  }\n  &:hover:not(.disabled) {\n    opacity: 1;\n  }\n  @media screen and (max-width: 600px) {\n    &:hover:not(.disabled) {\n      opacity: 0.7;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "src-web-app-components-beambox-top-bar-PathPreviewButton-module__button--fYglu",
	"disabled": "src-web-app-components-beambox-top-bar-PathPreviewButton-module__disabled--YHpAy",
	"highlighted": "src-web-app-components-beambox-top-bar-PathPreviewButton-module__highlighted--zrheW"
};
export default ___CSS_LOADER_EXPORT___;
