import * as React from 'react';
import classNames from 'classnames';
import { DialogContext } from 'app/contexts/DialogContext';
const ComponentWrapper = (props) => props.children;
const Dialog = ({ className = '' }) => {
    const { dialogComponents } = React.useContext(DialogContext);
    const renderComponents = () => {
        const components = [];
        for (let i = 0; i < dialogComponents.length; i += 1) {
            const { component } = dialogComponents[i];
            components.push(React.createElement(ComponentWrapper, { key: i }, component));
        }
        return components;
    };
    return (React.createElement("div", { className: classNames('dialog-container', className) }, renderComponents()));
};
export default Dialog;
