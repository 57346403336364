import React, { useState } from 'react';
import { Button, Checkbox, Modal } from 'antd';
import AnnouncementHelper from 'helpers/announcement-helper';
import browser from 'implementations/browser';
import useI18n from 'helpers/useI18n';
import { ExportOutlined } from '@ant-design/icons';
import styles from './AnnouncementPanel.module.scss';
const AnnouncementPanel = ({ announcement, onClose }) => {
    const lang = useI18n();
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const handleClose = () => {
        if (isCheckboxChecked)
            AnnouncementHelper.setNotShowing(announcement.id);
        onClose();
    };
    return (React.createElement(Modal, { className: styles.announcement, title: announcement.title || lang.beambox.announcement_panel.title, onCancel: handleClose, onOk: handleClose, footer: announcement.link ? (React.createElement(Button, { type: "primary", onClick: () => browser.open(announcement.link) },
            announcement.link_text || lang.alert.learn_more,
            " ",
            React.createElement(ExportOutlined, null))) : (React.createElement(Button, { type: "primary", onClick: handleClose }, lang.alert.close)), centered: true, open: true },
        React.createElement("div", { className: "main-content" },
            React.createElement("div", { dangerouslySetInnerHTML: { __html: announcement.content } }),
            React.createElement("div", { className: styles.checkbox },
                React.createElement(Checkbox, { checked: isCheckboxChecked, onChange: () => setIsCheckboxChecked(!isCheckboxChecked) }, lang.beambox.announcement_panel.dont_show_again)))));
};
export default AnnouncementPanel;
