import React, { useState } from 'react';
import { Modal } from 'antd';
import UnitInput from 'app/widgets/Unit-Input-v2';
import useI18n from 'helpers/useI18n';
import ValidationTextInput from 'app/widgets/Validation-Text-Input';
const AddColorConfigModal = ({ onClose, handleAddConfig }) => {
    const lang = useI18n().beambox.layer_color_config_panel;
    const [newColor, setNewColor] = useState('#FFFFFF');
    let newPower = 50;
    let newSpeed = 10;
    let newRepeat = 1;
    return (React.createElement(Modal, { open: true, title: lang.add_config, okText: lang.add, onOk: () => handleAddConfig({
            color: newColor,
            power: newPower,
            speed: newSpeed,
            repeat: newRepeat,
        }), onCancel: onClose },
        React.createElement("div", { className: "add-config-panel" },
            React.createElement("div", { className: "input-column" },
                React.createElement("div", { className: "color-block", style: { backgroundColor: newColor } }),
                React.createElement("div", { className: "name color" }, `${lang.color} :`),
                React.createElement(ValidationTextInput, { defaultValue: newColor, validation: (val) => val, getValue: (val) => { setNewColor(val); } })),
            React.createElement("div", { className: "input-column" },
                React.createElement("div", { className: "name" }, `${lang.power} :`),
                React.createElement(UnitInput, { className: { power: true }, min: 1, max: 100, unit: "%", defaultValue: newPower, getValue: (val) => { newPower = val; }, decimal: 1 })),
            React.createElement("div", { className: "input-column" },
                React.createElement("div", { className: "name" }, `${lang.speed} :`),
                React.createElement(UnitInput, { className: { speed: true }, min: 3, max: 300, unit: "mm/s", defaultValue: newSpeed, getValue: (val) => { newSpeed = val; }, decimal: 1 })),
            React.createElement("div", { className: "input-column" },
                React.createElement("div", { className: "name" }, `${lang.repeat} :`),
                React.createElement(UnitInput, { className: { repeat: true }, min: 1, max: 10, unit: "", defaultValue: newRepeat, getValue: (val) => { newRepeat = val; }, decimal: 0 })))));
};
export default AddColorConfigModal;
