import classNames from 'classnames';
import React from 'react';
import { Button, Modal } from 'antd';
import browser from 'implementations/browser';
import FluxIcons from 'app/icons/flux/FluxIcons';
import isFluxPlusActive from 'helpers/is-flux-plus-active';
import useI18n from 'helpers/useI18n';
import { useIsMobile } from 'helpers/system-helper';
import styles from './FluxPlusModal.module.scss';
const FluxPlusModal = ({ className, onClose, hideMobileBanner = false, children, }) => {
    const lang = useI18n().flux_id_login.flux_plus;
    const isMobile = useIsMobile();
    const shouldShowBanner = isFluxPlusActive && (!hideMobileBanner || !isMobile);
    return (React.createElement(Modal, { className: classNames(styles['flux-plus'], className), onCancel: onClose, 
        // eslint-disable-next-line no-nested-ternary
        width: isMobile ? 320 : isFluxPlusActive ? 726 : 400, footer: null, centered: true, open: true },
        React.createElement("div", { className: styles.body },
            shouldShowBanner && (React.createElement("div", { className: styles.banner },
                !isMobile && (React.createElement(React.Fragment, null,
                    React.createElement(FluxIcons.FluxPlusLogo, { className: styles.icon }),
                    React.createElement("div", { className: styles.features }, ['ai_bg_removal', 'my_cloud', 'boxgen', 'dmkt', 'monotype'].map((key) => (React.createElement("div", { key: key, className: styles.feature },
                        React.createElement(FluxIcons.FluxPlus, null),
                        " ",
                        lang.features[key])))))),
                React.createElement("div", { className: styles['img-container'] },
                    React.createElement("img", { src: `core-img/flux-plus/explore-flux-plus${isMobile ? '-mobile' : ''}.jpg`, alt: lang.learn_more })),
                React.createElement(Button, { className: styles.button, block: true, type: "default", ghost: true, shape: "round", onClick: () => browser.open(lang.website_url) }, lang.learn_more))),
            React.createElement("div", { className: styles.content }, children))));
};
export default FluxPlusModal;
