export const blockSettingScopes = ['column', 'row'];
export const blockSettingParams = ['count', 'size', 'spacing'];
export const blockSettingValues = ['value', 'min', 'max'];
const blockInfo = () => ({
    count: { value: 10, min: 2, max: 20 },
    size: { value: 10, min: 5, max: Number.MAX_SAFE_INTEGER },
    spacing: { value: 5, min: 5, max: Number.MAX_SAFE_INTEGER },
});
export const blockSetting = () => ({
    column: blockInfo(),
    row: blockInfo(),
});
