import communicator from 'implementations/communicator';
import * as React from 'react';
export const setEditingInput = () => communicator.send('SET_EDITING_STANDARD_INPUT', true);
export const setStopEditingInput = () => communicator.send('SET_EDITING_STANDARD_INPUT', false);
const InputKeyWrapper = (props) => {
    const { children, inputRef } = props;
    const keyFilter = (e) => {
        var _a;
        if (e.metaKey && ['a', 'z', 'x', 'c', 'v'].includes(e.key)) {
            if (e.key === 'a') {
                (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus({
                    cursor: 'all',
                });
                e.stopPropagation();
            }
        }
        else if (e.key !== 'Escape') {
            e.stopPropagation();
        }
    };
    return (React.createElement("div", { onKeyDown: keyFilter }, children));
};
export default InputKeyWrapper;
