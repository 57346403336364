var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import classNames from 'classnames';
import { MonitorContext } from 'app/contexts/MonitorContext';
import DeviceMaster from 'helpers/device-master';
const hdChecked = {};
const getImageSize = (url, onSize) => {
    const img = new Image();
    img.onload = () => {
        onSize([img.naturalWidth, img.naturalHeight]);
    };
    img.src = url;
};
export default class MonitorCamera extends React.PureComponent {
    constructor(props) {
        super(props);
        this.processImage = ({ imgBlob }) => {
            const { device } = this.props;
            const cameraImage = document.getElementById('camera-image');
            if (!cameraImage)
                return;
            const url = URL.createObjectURL(imgBlob);
            if (device) {
                if (!hdChecked[device.serial]) {
                    getImageSize(url, (size) => {
                        console.log('image size', size);
                        if (size[0] > 720) {
                            hdChecked[device.serial] = 2;
                        }
                        else if (size[0] > 0) {
                            hdChecked[device.serial] = 1;
                        }
                    });
                }
                this.setState({ isHd: hdChecked[device.serial] !== 1 });
            }
            this.previewBlob = imgBlob;
            const originalUrl = cameraImage.getAttribute('src');
            if (originalUrl) {
                URL.revokeObjectURL(originalUrl);
            }
            cameraImage.setAttribute('src', url);
        };
        const { device } = this.props;
        const { model } = device;
        this.isBeamboxCamera = [
            'mozu1',
            'fbm1',
            'fbb1b',
            'fbb1p',
            'fhexa1',
            'laser-b1',
            'laser-b2',
            'darwin-dev',
        ].includes(model);
        this.state = {
            isHd: false,
        };
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            this.cameraStream = yield DeviceMaster.streamCamera();
            this.cameraStream.subscribe(this.processImage);
        });
    }
    componentWillUnmount() {
        DeviceMaster.disconnectCamera();
    }
    render() {
        const { isHd } = this.state;
        const className = classNames('camera-image', { 'beambox-camera': this.isBeamboxCamera, hd: isHd });
        return (React.createElement("div", { className: "camera" },
            React.createElement("img", { id: "camera-image", className: className })));
    }
}
MonitorCamera.contextType = MonitorContext;
