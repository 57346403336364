import classNames from 'classnames';
import React from 'react';
import styles from './ColorBlock.module.scss';
const ColorBlock = ({ id, className, size = 'defalut', color, onClick }) => {
    const isFullColor = color === 'fullcolor';
    const isCleared = color === 'none';
    return (React.createElement("div", { id: id, className: classNames(className, styles.color, styles[size]) },
        React.createElement("div", { className: classNames({ [styles['full-color']]: isFullColor, [styles.clear]: isCleared }), style: isFullColor || isCleared ? undefined : { backgroundColor: color }, onClick: onClick })));
};
export default ColorBlock;
