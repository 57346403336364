import classNames from 'classnames';
import React, { useMemo } from 'react';
import beamboxPreference from 'app/actions/beambox/beambox-preference';
import configOptions from 'app/constants/config-options';
import Select from 'app/widgets/AntdSelect';
import UnitInput from 'app/widgets/UnitInput';
import useI18n from 'helpers/useI18n';
import { defaultConfig } from 'helpers/layer/layer-config-helper';
import styles from './PresetsManagementPanel.module.scss';
const PrintingInputs = ({ preset, maxSpeed, minSpeed, isInch = false, lengthUnit = 'mm', handleChange, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    const simpleMode = useMemo(() => !beamboxPreference.read('print-advanced-mode'), []);
    const lang = useI18n();
    const { saturationOptions, printingSpeedOptions, multipassOptions } = useMemo(() => ({
        saturationOptions: configOptions.getSaturationOptions(lang),
        printingSpeedOptions: configOptions.getPrintingSpeedOptions(lang),
        multipassOptions: configOptions.multipassOptions,
    }), [lang]);
    const tLaserPanel = lang.beambox.right_panel.laser_panel;
    return (React.createElement("div", { className: styles.inputs },
        React.createElement("div", null,
            React.createElement("div", { className: styles.field },
                React.createElement("div", { className: styles.label }, tLaserPanel.ink_saturation),
                simpleMode ? (React.createElement(Select, { id: "inkSelect", disabled: preset.isDefault, className: styles.select, onChange: (val) => handleChange('ink', val), value: (_a = preset.ink) !== null && _a !== void 0 ? _a : defaultConfig.ink }, saturationOptions.map((option) => (React.createElement(Select.Option, { key: option.value, value: option.value }, option.label))))) : (React.createElement(UnitInput, { "data-testid": "ink", className: styles.input, disabled: preset.isDefault, value: (_b = preset.ink) !== null && _b !== void 0 ? _b : defaultConfig.ink, max: 15, min: 1, precision: 0, onChange: (value) => handleChange('ink', value) }))),
            React.createElement("div", { className: classNames(styles.field, styles.small) },
                React.createElement("div", { className: styles.label }, "Cyan"),
                React.createElement(UnitInput, { "data-testid": "cRatio", className: styles.input, size: "small", disabled: preset.isDefault, value: (_c = preset.cRatio) !== null && _c !== void 0 ? _c : defaultConfig.cRatio, max: 200, min: 0, precision: 0, addonAfter: "%", onChange: (value) => handleChange('cRatio', value) })),
            React.createElement("div", { className: classNames(styles.field, styles.small) },
                React.createElement("div", { className: styles.label }, "Magenta"),
                React.createElement(UnitInput, { "data-testid": "mRatio", className: styles.input, size: "small", disabled: preset.isDefault, value: (_d = preset.mRatio) !== null && _d !== void 0 ? _d : defaultConfig.mRatio, max: 200, min: 0, precision: 0, addonAfter: "%", onChange: (value) => handleChange('mRatio', value) })),
            React.createElement("div", { className: classNames(styles.field, styles.small) },
                React.createElement("div", { className: styles.label }, "Yellow"),
                React.createElement(UnitInput, { "data-testid": "yRatio", className: styles.input, size: "small", disabled: preset.isDefault, value: (_e = preset.yRatio) !== null && _e !== void 0 ? _e : defaultConfig.yRatio, max: 200, min: 0, precision: 0, addonAfter: "%", onChange: (value) => handleChange('yRatio', value) })),
            React.createElement("div", { className: classNames(styles.field, styles.small) },
                React.createElement("div", { className: styles.label }, "Black"),
                React.createElement(UnitInput, { "data-testid": "kRatio", className: styles.input, size: "small", disabled: preset.isDefault, value: (_f = preset.kRatio) !== null && _f !== void 0 ? _f : defaultConfig.kRatio, max: 200, min: 0, precision: 0, addonAfter: "%", onChange: (value) => handleChange('kRatio', value) }))),
        React.createElement("div", null,
            React.createElement("div", { className: styles.field },
                React.createElement("div", { className: styles.label }, tLaserPanel.halftone),
                React.createElement(Select, { id: "halftoneSelect", disabled: preset.isDefault, className: styles.select, onChange: (val) => handleChange('halftone', val), value: (_g = preset.halftone) !== null && _g !== void 0 ? _g : defaultConfig.halftone },
                    React.createElement(Select.Option, { value: 1 }, "FM"),
                    React.createElement(Select.Option, { value: 2 }, "AM"))),
            React.createElement("div", { className: styles.field },
                React.createElement("div", { className: styles.label }, tLaserPanel.speed),
                simpleMode ? (React.createElement(Select, { id: "speedSelect", disabled: preset.isDefault, className: styles.select, onChange: (val) => handleChange('speed', val), value: (_h = preset.speed) !== null && _h !== void 0 ? _h : defaultConfig.speed }, printingSpeedOptions.map((option) => (React.createElement(Select.Option, { key: option.value, value: option.value }, option.label))))) : (React.createElement(UnitInput, { "data-testid": "speed", className: styles.input, disabled: preset.isDefault, value: (_j = preset.speed) !== null && _j !== void 0 ? _j : defaultConfig.speed, max: maxSpeed, min: minSpeed, precision: isInch ? 2 : 1, addonAfter: `${lengthUnit}/s`, isInch: isInch, onChange: (value) => handleChange('speed', value) }))),
            React.createElement("div", { className: styles.field },
                React.createElement("div", { className: styles.label }, tLaserPanel.print_multipass),
                simpleMode ? (React.createElement(Select, { id: "multipassSelect", disabled: preset.isDefault, className: styles.select, onChange: (val) => handleChange('multipass', val), value: (_k = preset.multipass) !== null && _k !== void 0 ? _k : defaultConfig.multipass }, multipassOptions.map(({ value }) => (React.createElement(Select.Option, { key: value, value: value }, value))))) : (React.createElement(UnitInput, { "data-testid": "multipass", className: styles.input, disabled: preset.isDefault, value: (_l = preset.multipass) !== null && _l !== void 0 ? _l : defaultConfig.multipass, max: 10, min: 1, precision: 0, addonAfter: tLaserPanel.times, onChange: (value) => handleChange('multipass', value) }))),
            React.createElement("div", { className: styles.field },
                React.createElement("div", { className: styles.label }, tLaserPanel.repeat),
                React.createElement(UnitInput, { "data-testid": "repeat", className: styles.input, disabled: preset.isDefault, value: (_m = preset.repeat) !== null && _m !== void 0 ? _m : defaultConfig.repeat, max: 100, min: 0, precision: 0, addonAfter: tLaserPanel.times, onChange: (value) => handleChange('repeat', value) })))));
};
export default PrintingInputs;
