import BeamboxPreference from 'app/actions/beambox/beambox-preference';
import { getWorkarea } from 'app/constants/workarea-constants';
export const tableParams = ['strength', 'speed', 'repeat'];
export const tableSetting = () => {
    const workarea = BeamboxPreference.read('workarea');
    const { maxSpeed } = getWorkarea(workarea);
    return {
        strength: {
            minValue: 15,
            maxValue: 100,
            min: 1,
            max: 100,
            default: 15,
            selected: 0,
        },
        speed: {
            minValue: 20,
            maxValue: maxSpeed,
            min: 1,
            max: maxSpeed,
            default: 20,
            selected: 1,
        },
        repeat: {
            minValue: 1,
            maxValue: 5,
            min: 1,
            max: 100,
            default: 1,
            selected: 2,
        },
    };
};
