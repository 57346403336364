import React from 'react';
import { Avatar, Badge } from 'antd';
import dialogCaller from 'app/actions/dialog-caller';
import FluxIcons from 'app/icons/flux/FluxIcons';
import TopBarIcons from 'app/icons/top-bar/TopBarIcons';
import styles from './UserAvatar.module.scss';
const UserAvatar = ({ user }) => {
    var _a, _b, _c;
    return (React.createElement("div", { className: styles['user-avatar'], onClick: () => dialogCaller.showFluxCreditDialog() },
        React.createElement(Badge, { count: ((_b = (_a = user === null || user === void 0 ? void 0 : user.info) === null || _a === void 0 ? void 0 : _a.subscription) === null || _b === void 0 ? void 0 : _b.is_valid) ? React.createElement(FluxIcons.FluxPlus, { className: styles.badge }) : 0, offset: [-4, 4] },
            React.createElement(Avatar, { icon: React.createElement(TopBarIcons.Account, { className: styles['default-avatar'] }), src: ((_c = user === null || user === void 0 ? void 0 : user.info) === null || _c === void 0 ? void 0 : _c.avatar) || undefined, size: 28, alt: "avatar" }))));
};
export default UserAvatar;
