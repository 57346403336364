var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import alertCaller from 'app/actions/alert-caller';
import deviceMaster from 'helpers/device-master';
import i18n from 'helpers/i18n';
import progressCaller from 'app/actions/progress-caller';
import { getWorkarea } from 'app/constants/workarea-constants';
const moveLaserHead = () => __awaiter(void 0, void 0, void 0, function* () {
    let isLineCheckMode = false;
    const lang = i18n.lang.calibration;
    try {
        progressCaller.openNonstopProgress({
            id: 'move-laser-head',
            message: lang.moving_laser_head,
        });
        const device = deviceMaster.currentDevice;
        yield deviceMaster.enterRawMode();
        yield deviceMaster.rawHome();
        yield deviceMaster.rawStartLineCheckMode();
        isLineCheckMode = true;
        const { width, height, cameraCenter } = getWorkarea(device.info.model, 'fbb2');
        const center = cameraCenter !== null && cameraCenter !== void 0 ? cameraCenter : [width / 2, height / 2];
        yield deviceMaster.rawMove({ x: center[0], y: center[1], f: 7500 });
        yield deviceMaster.rawEndLineCheckMode();
        isLineCheckMode = false;
        yield deviceMaster.rawLooseMotor();
        yield deviceMaster.endRawMode();
        return true;
    }
    catch (error) {
        console.error(error);
        alertCaller.popUpError({ message: lang.failed_to_move_laser_head });
        return false;
    }
    finally {
        try {
            if (deviceMaster.currentControlMode === 'raw') {
                if (isLineCheckMode)
                    yield deviceMaster.rawEndLineCheckMode();
                yield deviceMaster.rawLooseMotor();
                yield deviceMaster.endRawMode();
            }
        }
        finally {
            progressCaller.popById('move-laser-head');
        }
    }
});
export default moveLaserHead;
