import React, { useContext, useMemo } from 'react';
import beamboxPreference from 'app/actions/beambox/beambox-preference';
import configOptions from 'app/constants/config-options';
import storage from 'implementations/storage';
import useI18n from 'helpers/useI18n';
import { getWorkarea } from 'app/constants/workarea-constants';
import ConfigPanelContext from './ConfigPanelContext';
import ConfigSlider from './ConfigSlider';
import ConfigValueDisplay from './ConfigValueDisplay';
import styles from './WhiteInkSettingsModal.module.scss';
// TODO: add test
const WhiteInkSpeed = ({ value, hasMultiValue, onChange }) => {
    const lang = useI18n();
    const t = lang.beambox.right_panel.laser_panel;
    const { simpleMode = true } = useContext(ConfigPanelContext);
    const sliderOptions = useMemo(() => (simpleMode ? configOptions.getPrintingSpeedOptions(lang) : null), [simpleMode, lang]);
    const { display: displayUnit, decimal } = useMemo(() => {
        const unit = storage.get('default-units') || 'mm';
        const display = { mm: 'mm/s', inches: 'in/s' }[unit];
        const d = { mm: 1, inches: 2 }[unit];
        return { display, decimal: d };
    }, []);
    const workarea = beamboxPreference.read('workarea');
    const { maxValue, minValue } = useMemo(() => {
        const workareaObj = getWorkarea(workarea);
        return { maxValue: workareaObj.maxSpeed, minValue: workareaObj.minSpeed };
    }, [workarea]);
    return (React.createElement("div", { className: styles.panel },
        React.createElement("span", { className: styles.title }, t.speed),
        React.createElement(ConfigValueDisplay, { inputId: "white-speed-input", max: maxValue, min: minValue, value: value, hasMultiValue: hasMultiValue, unit: displayUnit, decimal: decimal, onChange: onChange, options: sliderOptions }),
        React.createElement(ConfigSlider, { id: "white-speed", value: value, onChange: onChange, min: minValue, max: maxValue, step: 0.1, options: sliderOptions, unit: displayUnit, decimal: decimal })));
};
export default WhiteInkSpeed;
