const lang = {
    global: {
        cancel: 'Avbryt',
        back: 'Tillbaka',
    },
    general: {
        processing: 'Bearbetar...',
        choose_folder: 'Välj mapp',
    },
    buttons: {
        next: 'NÄSTA',
        back: 'TILLBAKA',
        done: 'KLAR',
        back_to_beam_studio: 'Tillbaka till Beam Studio',
    },
    topbar: {
        untitled: 'Namnlös',
        titles: {
            settings: 'Inställningar',
        },
        export: 'EXPORT',
        preview: 'FÖRHANDSVISA',
        preview_title: 'Förhandsgranskning',
        curve_engrave: '3D Kurva',
        task_preview: 'Banor',
        frame_task: 'Kör ram',
        borderless: '(ÖPPNA BOTTEN)',
        tag_names: {
            rect: 'Rektangel',
            ellipse: 'Oval',
            path: 'Bana',
            polygon: 'Polygon',
            image: 'Bild',
            text: 'Text',
            text_path: 'Text på bana',
            pass_through_object: 'Passera Objekt',
            line: 'Linje',
            g: 'Grupp',
            multi_select: 'Flera objekt',
            use: 'Importerat objekt',
            svg: 'SVG-objekt',
            dxf: 'DXF-objekt',
            no_selection: 'Ingen val',
        },
        alerts: {
            start_preview_timeout: '#803 Timeout uppstod när förhandsgranskningsläget startades. Starta om din dator eller Beam Studio. Om felet kvarstår, följ <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">denna guide</a>.',
            fail_to_start_preview: '#803 Det gick inte att starta förhandsgranskningsläget. Starta om din dator eller Beam Studio. Om felet kvarstår, följ <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">denna guide</a>.',
            fail_to_connect_with_camera: '#803 Det gick inte att ansluta till maskinkameran. Starta om din maskin eller Beam Studio. Om felet kvarstår, följ <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">denna guide</a>.',
            add_content_first: 'Lägg till objekt först',
            headtype_mismatch: 'Fel modultyp upptäckt. ',
            headtype_none: 'Ingen modul upptäckt. ',
            headtype_unknown: 'Okänd modul upptäckt. ',
            install_correct_headtype: 'Installera 10W/20W diodlasermoduler korrekt för att aktivera lågeffektslaser för skärning.',
            door_opened: 'Stäng luckan för att aktivera lågeffektslaser för skärning.',
            fail_to_get_door_status: 'Kontrollera att luckan är stängd för att aktivera lågeffektslaser för skärning.',
            QcleanScene: 'Vill du rensa ritningen?<br/>Detta kommer också att radera din ånghistorik!',
            power_too_high: 'FÖR HÖG EFFEKT',
            power_too_high_msg: 'Att använda lägre laserstyrka (under 70%) förlänger laserns livslängd.\nSkriv in "NOTERAT" för att fortsätta.',
            power_too_high_confirm: 'NOTERAT',
            pwm_unavailable: 'Djup-läge kräver firmware version 4.3.4 / 5.3.4 eller högre. Vill du uppdatera firmware nu?',
            job_origin_unavailable: 'Att ställa in jobbets ursprung kräver firmwareversion 4.3.5 / 5.3.5 eller senare. Vill du uppdatera firmware nu?',
            job_origin_warning: 'Du använder för närvarande "aktuell position" som startpunkt. Se till att laserhuvudet flyttas till rätt position för att undvika kollisioner.',
        },
        hint: {
            polygon: 'Tryck på + / - tangenten för att öka / minska sidor.',
        },
        menu: {
            preferences: 'Inställningar',
            hide: 'Dölj Beam Studio',
            hideothers: 'Dölj andra',
            service: 'Tjänster',
            quit: 'Avsluta',
            window: 'Fönster',
            minimize: 'Minimera',
            close: 'Stäng fönster',
            file: 'Arkiv',
            edit: 'Redigera',
            help: 'Hjälp',
            open: 'Öppna',
            samples: 'Exempel',
            import_hello_beamo: 'Exempel för beamo',
            import_hello_beambox: 'Exempel för beambox',
            import_material_testing_old: 'Materialgraveringstest - Klassiskt',
            import_material_testing_simple_cut: 'Materialskärningstest - Enkelt',
            import_material_testing_cut: 'Materialskärningstest',
            import_material_testing_engrave: 'Materialgraveringstest',
            import_material_testing_line: 'Materiallinjetest',
            import_material_printing_test: 'Materialutskriftstest',
            import_ador_laser_example: 'Exempel på Ador Laser',
            import_ador_printing_example_single: 'Exempel på Ador Utskrift - Enfärg',
            import_ador_printing_example_full: 'Exempel på Ador Utskrift - Flerfärg',
            import_acrylic_focus_probe: 'Akrylfokusprov - 3mm',
            export_to: 'Exportera till...',
            export_flux_task: 'FLUX-uppgift',
            export_BVG: 'BVG',
            export_SVG: 'SVG',
            export_PNG: 'PNG',
            export_JPG: 'JPG',
            save_scene: 'Spara',
            save_as: 'Spara som...',
            save_to_cloud: 'Spara till molnet',
            about_beam_studio: 'Om Beam Studio',
            undo: 'Ångra',
            redo: 'Gör om',
            cut: 'Klipp ut',
            copy: 'Kopiera',
            paste: 'Klistra in',
            paste_in_place: 'Klistra in på plats',
            group: 'Gruppera',
            ungroup: 'Dela upp grupp',
            delete: 'Ta bort',
            duplicate: 'Duplicera',
            offset: 'Förskjut',
            scale: 'Skala',
            rotate: 'Rotera',
            reset: 'Återställ',
            align_center: 'Centrera',
            photo_edit: 'Bild',
            svg_edit: 'SVG',
            path: 'Bana',
            decompose_path: 'Dela upp',
            object: 'Objekt',
            layer_setting: 'Lager',
            layer_color_config: 'Färginställningar',
            image_sharpen: 'Skärpa',
            image_crop: 'Beskär',
            image_invert: 'Invertera',
            image_stamp: 'Fas',
            image_vectorize: 'Spåra',
            image_curve: 'Kurva',
            align_to_edges: 'Fäst vid hörn',
            document_setting: 'Dokumentinställningar',
            document_setting_short: 'Dokument',
            rotary_setup: 'Roterande Inställningar',
            clear_scene: 'Nya filer',
            machines: 'Maskiner',
            add_new_machine: 'Maskininställning',
            help_center: 'Hjälpcenter',
            show_gesture_tutorial: 'Handgester Introduktion',
            show_start_tutorial: 'Visa startguide',
            show_ui_intro: 'Visa gränssnittsintroduktion',
            questionnaire: 'Feedbackenkät',
            change_logs: 'Ändringsloggar',
            contact: 'Kontakta oss',
            tutorial: 'Starta Deltas familjeskrivartutorial',
            design_market: 'Designmarknad',
            forum: 'Communityforum',
            software_update: 'Programuppdatering',
            bug_report: 'Felrapport',
            dashboard: 'Instrumentpanel',
            machine_info: 'Maskininfo',
            network_testing: 'Testa nätverksinställningar',
            commands: 'Kommandon',
            update_firmware: 'Uppdatera inbyggd programvara',
            using_beam_studio_api: 'Använda Beam Studio API',
            set_as_default: 'Ange som standard',
            calibrate_beambox_camera: 'Kalibrera kamera',
            calibrate_printer_module: 'Kalibrera Utskriftsmodul',
            calibrate_ir_module: 'Kalibrera Infraröd Modul',
            calibrate_beambox_camera_borderless: 'Kalibrera kamera (öppen botten)',
            calibrate_diode_module: 'Kalibrera diodlasermodul',
            manage_account: 'Hantera mitt konto',
            sign_in: 'Logga in',
            sign_out: 'Logga ut',
            account: 'Konto',
            my_account: 'Mitt konto',
            download_log: 'Ladda ner loggar',
            download_log_canceled: 'Loggnedladdning avbruten',
            download_log_error: 'Ett okänt fel uppstod, försök igen senare',
            keyboard_shortcuts: 'Tangentbordsgenvägar',
            log: {
                network: 'Nätverk',
                hardware: 'Hårdvara',
                discover: 'Upptäck',
                usb: 'USB',
                usblist: 'USB-lista',
                camera: 'Kamera',
                cloud: 'Moln',
                player: 'Spelare',
                robot: 'Robot',
            },
            link: {
                help_center: 'https://helpcenter.flux3dp.com/',
                contact_us: 'https://flux3dp.zendesk.com/hc/en-us/requests/new',
                forum: 'https://www.facebook.com/groups/flux.laser/',
                downloads: 'https://flux3dp.com/downloads/',
                beam_studio_api: 'https://github.com/flux3dp/beam-studio/wiki/Beam-Studio-Easy-API',
                design_market: 'https://dmkt.io',
                shortcuts: 'https://support.flux3dp.com/hc/en-us/articles/10003978157455',
            },
            view: 'Visa',
            zoom_in: 'Zooma in',
            zoom_out: 'Zooma ut',
            fit_to_window: 'Anpassa till fönster',
            zoom_with_window: 'Autoanpassa till fönster',
            borderless_mode: 'Helskärm',
            show_grids: 'Visa rutnät',
            show_rulers: 'Visa linjaler',
            show_layer_color: 'Använd lagrets färg',
            anti_aliasing: 'Anti-aliasing',
            disassemble_use: 'Demontera',
            about: 'Om Beam Studio',
            switch_to_beta: 'Växla till Beta-version',
            switch_to_latest: 'Växla till Stabil version',
            reload_app: 'Ladda om App',
            recent: 'Öppna Senaste',
            update: 'Kontrollera Uppdatering',
            dev_tool: 'Debug-verktyg',
            camera_calibration_data: 'Kamerakalibreringsdata',
            upload_data: 'Ladda upp Data',
            download_data: 'Ladda ner Data',
            tools: {
                title: 'Verktyg',
                material_test_generator: 'Materialtestgenerator',
                qr_code_generator: 'QR-kodgenerator',
                boxgen: 'Boxgen',
            },
        },
        select_machine: 'Välj en maskin',
    },
    support: {
        no_webgl: 'WebGL stöds inte. Använd en annan enhet.',
        no_vcredist: 'Installera Visual C++ Redistributable 2015<br/>Det kan laddas ner på flux3dp.com',
    },
    generic_error: {
        UNKNOWN_ERROR: '[UE] Ett okänt fel inträffade. Starta om Beam Studio och maskinen.',
        OPERATION_ERROR: '[OE] En statuskonflikt inträffade, försök igen.',
        SUBSYSTEM_ERROR: '[SE] Fel vid maskinvarans programvara när uppgiften utfördes. Starta om maskinen.',
        UNKNOWN_COMMAND: '[UC] Uppdatera maskinvarans programvara',
    },
    device_selection: {
        no_beambox: '#801 Vi hittar inte din maskin i nätverket.\nFölj <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/360001683556">guiden</a> för att felsöka anslutningsproblemet.',
        no_device_web: "#801 Kontrollera statusen för din maskin, eller klicka på 'Maskininställning' nedan för att ställa in maskinen.",
        select_usb_device: 'Välj USB-enhet',
    },
    update: {
        update: 'Uppdatera',
        release_note: 'Versionsinformation:',
        firmware: {
            caption: 'En uppdatering av maskinvarans programvara är tillgänglig',
            message_pattern_1: '"%s" är nu redo för uppdatering av maskinvarans programvara.',
            message_pattern_2: '%s maskinvara v%s finns nu tillgänglig - Du har v%s.',
            latest_firmware: {
                caption: 'Uppdatering av maskinvarans programvara',
                message: 'Du har den senaste versionen av maskinvarans programvara',
                still_update: 'UPPDATERA',
                cant_get_latest: 'Kan inte hämta information om den senaste versionen.',
            },
            confirm: 'LADDA UPP',
            upload_file: 'Ladda upp maskinvara (*.bin / *.fxfw)',
            update_success: 'Maskinvarans programvara har uppdaterats',
            update_fail: '#822 Uppdateringen misslyckades',
            too_old_for_web: 'Din nuvarande version av maskinvarans programvara är v%s.\nOm du vill använda Beam Studios onlineversion, uppdatera maskinvarans programvara till den senaste versionen.',
            force_update_message: '#814 Uppdatera maskinen till den senaste versionen av maskinvarans programvara.',
            firmware_too_old_update_by_sdcard: 'Versionen av maskinvarans programvara är för gammal. Uppdatera med ett SD-kort.',
        },
        software: {
            checking: 'Söker efter uppdateringar',
            switch_version: 'Byt version',
            check_update: 'Sök efter uppdateringar',
            caption: 'En uppdatering för Beam Studio är tillgänglig',
            downloading: 'Laddar ner uppdateringar i bakgrunden, du kan klicka "OK" för att fortsätta arbeta.',
            install_or_not: 'är redo för uppdatering. Vill du starta om och uppdatera nu?',
            switch_or_not: 'är redo för byte. Vill du starta om och byta nu?',
            available_update: 'Beam Studio v%s är tillgänglig nu. Du har v%s. Vill du ladda ner uppdateringen?',
            available_switch: 'Beam Studio v%s är tillgänglig nu. Du har v%s. Vill du byta till den här versionen?',
            not_found: 'Du använder den senaste versionen av Beam Studio.',
            no_response: 'Det gick inte att ansluta till servern, kontrollera dina nätverksinställningar.',
            switch_version_not_found: 'Bytebar version hittades inte.',
            yes: 'Ja',
            no: 'Nej',
            update_for_ador: 'Den aktuella programvaruversionen %s är inkompatibel, vänligen ladda ner den senaste versionen av Beam Studio för Ador.',
        },
        updating: 'Uppdaterar...',
        skip: 'Hoppa över den här versionen',
        preparing: 'Förbereder...',
        later: 'SENARE',
        download: 'ONLINEUPPDATERING',
        cannot_reach_internet: '#823 Servern är inte nåbar<br/>Kontrollera internetanslutningen',
        install: 'INSTALLERA',
        upload: 'LADDA UPP',
    },
    topmenu: {
        version: 'Version',
        credit: 'Beam Studio är möjlig tack vare det öppen källkodsprojektet <a target="_blank" href="https://github.com/flux3dp/beam-studio">Beam Studio</a> och annan <a target="_blank" href="https://flux3dp.com/credits/">öppen källkod</a>.',
        ok: 'OK',
        file: {
            label: 'Fil',
            import: 'Importera',
            save_fcode: 'Exportera FLUX-uppgift',
            save_scene: 'Spara scen',
            save_svg: 'Exportera SVG',
            save_png: 'Exportera PNG',
            save_jpg: 'Exportera JPG',
            converting: 'Konverterar till bild...',
            all_files: 'Alla filer',
            svg_files: 'SVG',
            png_files: 'PNG',
            jpg_files: 'JPG',
            scene_files: 'Beam Studio Scene',
            fcode_files: 'FLUX Code',
            clear_recent: 'Rensa nyligen öppnade',
            path_not_exit: 'Denna sökväg verkar inte längre finnas på disken.',
        },
        device: {
            download_log_canceled: 'Logg nedladdning avbruten',
            download_log_error: 'Okänt fel uppstod, försök igen senare',
            log: {
                usblist: 'USB-lista',
            },
            network_test: 'Testa nätverk',
        },
    },
    initialize: {
        next: 'Nästa',
        start: 'Starta',
        skip: 'Hoppa över',
        cancel: 'Avbryt',
        confirm: 'Bekräfta',
        connect: 'Anslut',
        back: 'Tillbaka',
        retry: 'Försök igen',
        no_machine: 'Jag har ingen maskin nu.',
        select_language: 'Välj språk',
        select_machine_type: 'Välj din modell',
        select_connection_type: 'Hur vill du ansluta?',
        connection_types: {
            wifi: 'Wi-Fi',
            wired: 'Trådbundet nätverk',
            ether2ether: 'Direktanslutning',
            usb: 'USB-anslutning',
        },
        connect_wifi: {
            title: 'Ansluter till Wi-Fi',
            tutorial1: '1. Gå till pekskärmen > Klicka på "Nätverk" > "Anslut till WiFi".',
            tutorial1_ador: '1. Gå till pekskärmen > Klicka på "MASKIN" > Klicka på "Nätverk" > "Anslut till WiFi".',
            tutorial2: '2. Välj och anslut till ditt föredragna Wi-Fi.',
            what_if_1: 'Vad gör jag om jag inte ser mitt Wi-Fi?',
            what_if_1_content: '1. Wi-Fi-krypteringen bör vara WPA2 eller inget lösenord.\n2. Krypteringsläget kan ställas in i Wi-Fi-routerns administrationsgränssnitt. Om routern inte stöder WPA2 och du behöver hjälp med att välja rätt router, kontakta FLUX-supporten.',
            what_if_2: 'Vad händer om jag inte ser något Wi-Fi?',
            what_if_2_content: '1. Se till att Wi-Fi-dongeln är ordentligt ansluten.\n2. Om det inte finns någon MAC-adress för det trådlösa nätverket på pekskärmen, kontakta FLUX-supporten.\n3. Wi-Fi-kanalen bör vara 2,4 GHz (5 GHz stöds inte).',
        },
        connect_wired: {
            title: 'Ansluter till kabelanslutet nätverk',
            tutorial1: '1. Anslut maskinen med din router.',
            tutorial2: '2. Tryck på "Nätverk" för att få den kabelanslutna nätverks-IP:n.',
            tutorial2_ador: '2. Tryck på "MASKIN" > "Nätverk" för att få den trådbundna IP-adressen.',
            what_if_1: 'Vad händer om IP:n är tom?',
            what_if_1_content: '1. Se till att Ethernet-kabeln är ordentligt ansluten.\n2 Om det inte finns någon MAC-adress för det kabelanslutna nätverket på pekskärmen, kontakta FLUX-supporten.',
            what_if_2: 'Vad händer om IP:n börjar med 169?',
            what_if_2_content: '1. Om IP-adressen börjar med 169.254 bör det vara ett DHCP-inställningsproblem, kontakta din internetleverantör för ytterligare hjälp.\n2. Om din dator ansluter direkt till internet med PPPoE, ändra till att ansluta via router med PPPoE och aktivera DHCP-funktionen i routern.',
        },
        connect_ethernet: {
            title: 'Direktanslutning',
            tutorial1: '1. Anslut maskinen till din dator med en Ethernet-kabel.',
            tutorial2_1: '2. Följ ',
            tutorial2_a_text: 'denna guide',
            tutorial2_a_href_mac: 'https://support.flux3dp.com/hc/en-us/articles/360001517076',
            tutorial2_a_href_win: 'https://support.flux3dp.com/hc/en-us/articles/360001507715',
            tutorial2_2: ' för att konfigurera din dator som en router.',
            tutorial3: '3. Klicka på Nästa.',
        },
        connect_usb: {
            title: 'USB-anslutning',
            title_sub: ' (endast HEXA)',
            tutorial1: '1. Anslut maskinen till din dator med en USB-kabel.',
            tutorial2: '2. Klicka på Nästa.',
            turn_off_machine: 'Stäng av maskinen.',
            turn_on_machine: 'Slå på maskinen.',
            wait_for_turning_on: 'Klicka på "Nästa" när startprocessen är klar och du har kommit till huvudskärmen.',
        },
        connect_machine_ip: {
            check_usb: 'Kontrollera USB-anslutning',
            enter_ip: 'Ange maskinens IP',
            check_ip: 'Kontrollerar IP-tillgänglighet',
            invalid_ip: 'Ogiltig IP: ',
            invalid_format: 'Ogiltigt format',
            starts_with_169254: 'Börjar med 169.254',
            unreachable: 'IP oåtkomlig',
            check_connection: 'Kontrollerar maskinanslutning',
            check_firmware: 'Kontrollerar firmware-version',
            check_camera: 'Kontrollerar kameratillgänglighet',
            retry: 'Försök igen',
            succeeded_message: 'Ansluten 🎉',
            finish_setting: 'Börja skapa!',
        },
        connecting: 'Ansluter...',
        setting_completed: {
            start: 'Starta',
            great: 'Välkommen till Beam Studio',
            setup_later: 'Du kan alltid konfigurera din maskin från titelraden > "Maskiner" > "Maskinkonfiguration"',
            back: 'Tillbaka',
            ok: 'BÖRJA SKAPA',
        },
    },
    error_pages: {
        screen_size: 'Observera att Beam Studio kanske inte fungerar optimalt på din enhet. För bästa upplevelse bör enheten ha en skärmstorlek på minst 1024 bildpunkter.',
    },
    menu: {
        mm: 'mm',
        inches: 'tum',
    },
    settings: {
        on: 'På',
        off: 'Av',
        low: 'Låg',
        high: 'Normal',
        caption: 'Inställningar',
        tabs: {
            general: 'Allmänt',
            device: 'Maskin',
        },
        ip: 'Maskinens IP-adress',
        guess_poke: 'Sök efter maskinens IP-adress',
        auto_connect: 'Välj automatiskt den enda maskinen',
        wrong_ip_format: 'Felaktigt IP-format',
        default_machine: 'Standardmaskin',
        default_machine_button: 'Tom',
        remove_default_machine_button: 'Ta bort',
        confirm_remove_default: 'Standardmaskinen kommer att tas bort.',
        reset: 'Återställ Beam Studio',
        reset_now: 'Återställ Beam Studio',
        confirm_reset: 'Bekräfta återställning av Beam Studio?',
        language: 'Språk',
        notifications: 'Skrivbordsmeddelanden',
        check_updates: 'Automatisk kontroll',
        autosave_enabled: 'Autospara',
        autosave_path: 'Autospara plats',
        autosave_interval: 'Spara varje',
        autosave_number: 'Antal autosparningar',
        autosave_path_not_correct: 'Angiven sökväg hittades inte.',
        preview_movement_speed: 'Förhandsgranskning hastighet',
        slow: 'Långsam',
        medium: 'Medel',
        fast: 'Snabb',
        default_units: 'Standardenheter',
        default_font_family: 'Standardtypsnitt',
        default_font_style: 'Standard typsnittsstil',
        fast_gradient: 'Hastighetsoptimering',
        engraving_direction: 'Riktning',
        top_down: 'Uppifrån och ner',
        bottom_up: 'Nerifrån och upp',
        vector_speed_constraint: 'Hastighetsbegränsning (20mm/s)',
        loop_compensation: 'Kompensering för slingor',
        blade_radius: 'Bladradie',
        blade_precut_switch: 'Blad förskärning',
        blade_precut_position: 'Förskärningsposition',
        default_beambox_model: 'Standarddokumentinställning',
        guides_origin: 'Riktlinjeursprung',
        guides: 'Riktlinjer',
        image_downsampling: 'Bildnedsamplingskvalitet',
        anti_aliasing: 'Kantutjämning',
        continuous_drawing: 'Kontinuerlig ritning',
        trace_output: 'Bildspårningsutdata',
        single_object: 'Enskilt objekt',
        grouped_objects: 'Grupperade objekt',
        simplify_clipper_path: 'Optimera den beräknade banan',
        enable_low_speed: 'Aktivera långsam hastighetsrörelse',
        enable_custom_backlash: 'Aktivera anpassad backlashkompensation',
        auto_switch_tab: 'Automatisk växling mellan lager- och objektpanelen',
        custom_preview_height: 'Anpassad förhandsgranskningshöjd',
        mask: 'Arbetsområdesklippning',
        text_path_calc_optimization: 'Bana beräkningsoptimering',
        font_substitute: 'Ersätt osupportade tecken',
        font_convert: 'Text till bana konverterare',
        default_borderless_mode: 'Öppna botten som standard',
        default_enable_autofocus_module: 'Autofokus som standard',
        default_enable_diode_module: 'Diodlaser som standard',
        diode_offset: 'Diodlaseroffset',
        autofocus_offset: 'Autofokusoffset',
        diode_one_way_engraving: 'Diodlaser enkelriktad gravering',
        diode_two_way_warning: 'Tvåvägs ljusemittering är snabbare och genererar förmodligen viss felmarginal på lasergraveringspositionen. Rekommenderar att testa först.',
        share_with_flux: 'Dela Beam Studio-analys',
        none: 'Ingen',
        close: 'Stäng',
        enabled: 'Aktiverad',
        disabled: 'Inaktiverad',
        cancel: 'Avbryt',
        done: 'Verkställ',
        module_offset_10w: '10W Diodlaser Offset',
        module_offset_20w: '20W Diodlaser Offset',
        module_offset_printer: 'Skrivaroffset',
        module_offset_2w_ir: '2W Infraröd Laser Offset',
        printer_advanced_mode: 'Avancerat Skrivarläge',
        default_laser_module: 'Standard Lasermodul',
        low_laser_for_preview: 'Laser för Kör ram',
        groups: {
            general: 'Allmänt',
            update: 'Programuppdateringar',
            connection: 'Anslutning',
            autosave: 'Autospara',
            camera: 'Kamera',
            editor: 'Redigerare',
            engraving: 'Rasterisering (skanning)',
            path: 'Vektor (konturer)',
            mask: 'Arbetsområdesklippning',
            text_to_path: 'Text',
            modules: 'Tillägg',
            ador_modules: 'Ador Moduler',
            privacy: 'Sekretess',
        },
        notification_on: 'På',
        notification_off: 'Av',
        update_latest: 'Senaste',
        update_beta: 'Beta',
        help_center_urls: {
            connection: 'https://support.flux3dp.com/hc/en-us/sections/360000302135',
            image_downsampling: 'https://support.flux3dp.com/hc/en-us/articles/360004494995',
            anti_aliasing: 'https://support.flux3dp.com/hc/en-us/articles/360004408956',
            continuous_drawing: 'https://support.flux3dp.com/hc/en-us/articles/360004406496',
            simplify_clipper_path: 'https://support.flux3dp.com/hc/en-us/articles/360004407276',
            fast_gradient: 'https://support.flux3dp.com/hc/en-us/articles/360004496235',
            reverse_engraving: 'https://support.flux3dp.com/hc/en-us/articles/',
            vector_speed_constraint: 'https://support.flux3dp.com/hc/en-us/articles/360004496495',
            loop_compensation: 'https://support.flux3dp.com/hc/en-us/articles/360004408856',
            mask: 'https://support.flux3dp.com/hc/en-us/articles/360004408876',
            font_substitute: 'https://support.flux3dp.com/hc/en-us/articles/360004496575',
            font_convert: 'https://support.flux3dp.com/hc/en-us/articles/9132766761743',
            default_borderless_mode: 'https://support.flux3dp.com/hc/zh-tw/articles/360001104076',
            default_enable_autofocus_module: 'https://support.flux3dp.com/hc/en-us/articles/360001574536',
            default_enable_diode_module: 'https://support.flux3dp.com/hc/en-us/articles/360001568035',
        },
    },
    beambox: {
        tag: {
            g: 'Grupp',
            use: 'Importera SVG',
            image: 'Bild',
            text: 'Text',
        },
        context_menu: {
            cut: 'Klipp ut',
            copy: 'Kopiera',
            paste: 'Klistra in',
            paste_in_place: 'Klistra in på plats',
            duplicate: 'Duplicera',
            delete: 'Ta bort',
            group: 'Gruppera',
            ungroup: 'Dela grupp',
            move_front: 'Flytta längst fram',
            move_up: 'Flytta framåt',
            move_down: 'Flytta bakåt',
            move_back: 'Flytta längst bak',
        },
        popup: {
            select_import_method: 'Välj skiktstil:',
            select_import_module: 'Välj modul:',
            touchpad: 'Pekplatta',
            mouse: 'Mus',
            layer_by_layer: 'Skikt',
            layer_by_color: 'Färg',
            nolayer: 'Ett skikt',
            loading_image: 'Läser in bild, vänta...',
            no_support_text: 'Beam Studio stöder inte text just nu. Vänligen omvandla text till bana innan import.',
            speed_too_high_lower_the_quality: 'För hög hastighet vid denna upplösning kan ge sämre kvalitet på skugggravering.',
            both_power_and_speed_too_high: 'Att använda lägre laserstyrka förlänger laserns livslängd.\nOckså, för hög hastighet vid denna upplösning kan ge sämre kvalitet på skugggravering.',
            too_fast_for_path: 'För hög hastighet i lager med banobjekt kan ge lägre precision vid skärning.\nVi rekommenderar inte hastigheter över 20 mm/s vid skärning.',
            too_fast_for_path_and_constrain: 'Följande lager: %s\ninnehåller vektorbanaobjekt och har hastighet över 20 mm/s.\nSkärhastigheten för vektorbanaobjekt kommer begränsas till 20 mm/s.\nDu kan ta bort denna begränsning under inställningar.',
            should_update_firmware_to_continue: '#814 Din firmware stöder inte denna version av Beam Studio. Uppdatera firmware för att fortsätta. (Meny > Maskin > [Din maskin] > Uppdatera firmware)',
            recommend_downgrade_software: 'Vi upptäckte en äldre firmwareversion. Vi arbetar aktivt med kompatibilitetsproblem, men för närvarande rekommenderar vi att återgå till Beam Studio 1.9.5.',
            recommend_upgrade_firmware: 'Vi upptäckte en äldre firmware-version. Vi arbetar aktivt med kompatibilitetsproblem, men tills vidare rekommenderar vi att du uppdaterar till den senaste firmware-versionen.',
            still_continue: 'Fortsätt',
            more_than_two_object: 'För många objekt. Stöder endast 2 objekt',
            not_support_object_type: 'Stöder inte objekttyp',
            select_first: 'Välj ett objekt först.',
            select_at_least_two: 'Välj två objekt för att fortsätta',
            import_file_contain_invalid_path: '#808 Importerad SVG-fil innehåller ogiltig bildsökväg. Se till att alla bildfiler finns eller bädda in bild i filen',
            import_file_error_ask_for_upload: 'Misslyckades med att importera SVG-filen. Vill du skicka filen till utvecklingsteamet för felrapportering?',
            upload_file_too_large: '#819 Filen är för stor för uppladdning.',
            successfully_uploaded: 'Filuppladdningen lyckades.',
            upload_failed: '#819 Filuppladdningen misslyckades.',
            or_turn_off_borderless_mode: 'Eller stäng av öppen bottenläge.',
            svg_1_1_waring: 'Versionen av denna SVG-fil är v 1.1, det kan finnas potentiella kompatibilitetsproblem.',
            svg_image_path_waring: 'Denna SVG-fil innehåller <image> som läser in från filsökväg. Detta kan orsaka fel vid inläsning.\nFör att undvika denna risk, använd inbäddade bilder vid export av SVG.',
            dxf_version_waring: 'Versionen av denna DXF-fil är inte 2013, det kan finnas potentiella kompatibilitetsproblem.',
            dont_show_again: 'Visa inte detta igen.',
            convert_to_path_fail: 'Misslyckades med att konvertera till bana.',
            save_unsave_changed: 'Vill du spara osparade ändringar?',
            dxf_bounding_box_size_over: 'Ritningens storlek är utanför arbetsområdet. Flytta din ritning närmare origo i ditt CAD-program, eller se till att enheten är korrekt inställd.',
            progress: {
                uploading: 'Laddar upp',
                calculating: 'Beräknar',
            },
            backend_connect_failed_ask_to_upload: '#802 Fel uppstår hela tiden när anslutning till servern försöks. Vill du ladda upp din felrapportlogg?',
            backend_error_hint: 'Funktioner kanske inte fungerar korrekt på grund av serverfel.',
            pdf2svg: {
                error_when_converting_pdf: '#824 Fel vid konvertering av PDF till SVG:',
                error_pdf2svg_not_found: '#825 Fel: Kommandot pdf2svg hittades inte. Installera pdf2svg med ditt pakethanterare (t.ex. "yum install pdf2svg" eller "apt-get install pdf2svg").',
            },
            ungroup_use: 'Detta kommer att separera importerad DXF eller SVG. Eftersom filen kan innehålla ett stort antal element kan det ta tid att separera. Är du säker på att du vill fortsätta?',
            vectorize_shading_image: 'Gradientbilder tar längre tid att spåra och är benägna till brus. Vänligen stäng av bildgradienten innan du kör.',
            change_workarea_before_preview: 'Arbetsområdet för %s matchar inte det för närvarande inställda arbetsområdet. Vill du byta nuvarande arbetsområde?',
            bug_report: 'Felrapport',
            sentry: {
                title: 'Låt oss göra Beam Studio bättre tillsammans',
                message: 'Godkänner du att relevant information automatiskt laddas upp till utvecklingsteamet när fel uppstår?',
            },
            questionnaire: {
                caption: 'Hjälp oss fylla i frågeformuläret',
                message: 'Hjälp oss fylla i frågeformuläret för att göra Beam Studio bättre.',
                unable_to_get_url: 'Det gick inte att hämta länken till frågeformuläret via internet. Bekräfta din internetanslutning.',
                no_questionnaire_available: 'Det finns inget frågeformulär tillgängligt just nu.',
            },
            facebook_group_invitation: {
                title: 'Gå med i FLUX officiella användargrupp',
                message: 'Gå med i vår officiella Facebook-grupp för att kontakta andra FLUX-laseranvändare, diskutera FLUX-lasrar, dela laserkonst och hålla dig uppdaterad om de senaste uppdateringarna av våra produkter. Vi ser fram emot att se dig där!',
                join_now: 'Räkna in mig',
                later: 'Kanske senare',
                already_joined: 'Redan ansluten',
                dont_show_again: 'Visa inte detta igen',
            },
            ai_credit: {
                relogin_to_use: 'Logga in igen för att använda den här funktionen',
                insufficient_credit: 'Du har slut på kredit',
                insufficient_credit_msg: 'Du kan inte använda %s. Gå till medlemscentret och köp AI-kredit',
                buy_link: 'https://member.flux3dp.com/en-US/credit',
                go: 'Gå',
            },
            text_to_path: {
                caption: 'Text till bana konverterare 2.0',
                message: 'Beam Studio introducerar nu en ny text till bana konverterare (Konverterare 2.0), som producerar mer tillförlitliga resultat! Vill du byta till den nu?\\nDu kan också justera den här inställningen senare i avsnittet "Text till bana konverterare" under inställningarna.',
            },
            auto_switch_tab: {
                title: 'Automatisk Växling mellan Lager- och Objektpanel',
                message: 'Ett nytt alternativ för att automatiskt växla mellan lagpanelen och objektpanelen har lagts till i Inställningar. Detta alternativ är inaktiverat som standard. Vill du aktivera automatisk växling nu? <br/>Du kan ändra denna inställning när som helst i Inställningar.',
            },
        },
        zoom_block: {
            fit_to_window: 'Anpassa till fönster',
        },
        time_est_button: {
            calculate: 'Uppskatta tid',
            estimate_time: 'Uppskattad tid:',
        },
        left_panel: {
            unpreviewable_area: 'Blint område',
            diode_blind_area: 'Hybridlaser tilläggs blint område',
            borderless_blind_area: 'Icke-graveringsområde',
            borderless_preview: 'Öppna bottenläge kameraförhandsgranskning',
            rectangle: 'Rektangel',
            ellipse: 'Ellips',
            line: 'Linje',
            image: 'Bild',
            text: 'Text',
            label: {
                cursor: 'Välj',
                photo: 'Bild',
                text: 'Text',
                line: 'Linje',
                rect: 'Rektangel',
                oval: 'Oval',
                polygon: 'Polygon',
                pen: 'Penn',
                shapes: 'Objekt',
                array: 'Matris',
                preview: 'Kameraförhandsgranskning',
                trace: 'Spåra bild',
                end_preview: 'Avsluta förhandsgranskning',
                clear_preview: 'Rensa förhandsgranskning',
                choose_camera: 'Kamera',
                live_feed: 'Livebild',
                adjust_height: 'Justera höjd',
                qr_code: 'QR-kod',
                boxgen: 'Boxgen',
                my_cloud: 'Mitt moln',
                pass_through: 'Passera Igenom',
                curve_engraving: {
                    title: '3D Kurva',
                    exit: 'Avsluta',
                    select_area: 'Välj område',
                    preview_3d_curve: 'Förhandsgranska 3D-kurva',
                    clear_area: 'Rensa valt område',
                },
            },
        },
        right_panel: {
            tabs: {
                layers: 'Lager',
                objects: 'Objekt',
                path_edit: 'Banas redigering',
            },
            layer_panel: {
                layer1: 'Lager 1',
                layer_bitmap: 'Bitmap',
                layer_engraving: 'Gravering',
                layer_cutting: 'Skärning',
                current_layer: 'Aktuellt lager',
                move_elems_to: 'Flytta objekt till:',
                notification: {
                    dupeLayerName: 'Det finns redan ett lager med det namnet!',
                    newName: 'NYTT NAMN',
                    enterUniqueLayerName: 'Ange ett unikt lagernamn',
                    enterNewLayerName: 'Ange det nya lagernamnet',
                    layerHasThatName: 'Lagret har redan det namnet',
                    QmoveElemsToLayer: "Flytta markerade objekt till lager '%s'?",
                    moveElemFromPrintingLayerTitle: 'Flytta valt element till %s och konvertera det till laser-element?',
                    moveElemFromPrintingLayerMsg: 'Observera att om du slutför den här åtgärden kommer färginställningarna för det valda elementet att tas bort och ställas in enligt %s.',
                    moveElemToPrintingLayerTitle: 'Flytta valt element till %s och konvertera det till utskrifts-element?',
                    moveElemToPrintingLayerMsg: 'Observera att om du slutför den här åtgärden kommer inställningarna för det valda elementet att tas bort och ställas in enligt %s.',
                    splitColorTitle: 'Vill du expandera det valda lagret till CMYK-lager?',
                    splitColorMsg: 'Observera att om du fortsätter med den här åtgärden kan du inte återgå till de ursprungliga färgskikten.',
                    mergeLaserLayerToPrintingLayerTitle: 'Vill du slå samman dessa lager till ett utskriftslager?',
                    mergeLaserLayerToPrintingLayerMsg: 'Observera att om du slutför den här åtgärden kommer laser-lagrets inställningar att tas bort och ställas in enligt aktuellt lager.',
                    mergePrintingLayerToLaserLayerTitle: 'Vill du slå samman dessa lager till ett laser-lager?',
                    mergePrintingLayerToLaserLayerMsg: 'Observera att om du slutför den här åtgärden kommer färginställningarna för utskrifts-lagret att tas bort och ställas in enligt aktuellt lager.',
                },
                layers: {
                    layer: 'Lager',
                    layers: 'Lager',
                    del: 'Ta bort lager',
                    move_down: 'Flytta lager nedåt',
                    new: 'Nytt lager',
                    rename: 'Byt namn på lager',
                    move_up: 'Flytta lager uppåt',
                    dupe: 'Duplicera lager',
                    lock: 'Lås lager',
                    unlock: 'Lås upp',
                    merge_down: 'Slå samman nedåt',
                    merge_all: 'Slå samman alla',
                    merge_selected: 'Slå samman valda lager',
                    move_elems_to: 'Flytta element till:',
                    move_selected: 'Flytta valda element till ett annat lager',
                    switchToFullColor: 'Växla till fullfärgs-lager',
                    switchToSingleColor: 'Växla till en-färgs-lager',
                    splitFullColor: 'Expandera lager',
                    fullColor: 'Fullfärg',
                },
            },
            laser_panel: {
                preset_setting: 'Inställningar (%s)',
                multi_layer: 'Flera lager',
                parameters: 'Parametrar',
                strength: 'Effekt',
                pwm_advanced_setting: 'Djupinställningar för Strömläge',
                pwm_advanced_desc: 'Ställ in en minimieffekt för djupinställningar.',
                pwm_advanced_hint: 'Denna inställning gäller specifikt för gradientbilder när du använder djupinställningar.',
                low_power_warning: 'Lägre laserstyrka (under 10%) kanske inte avger laserljuset.',
                speed: 'Hastighet',
                speed_contrain_warning: 'Skärhastigheten för vektorbanor kommer att begränsas till 20 mm/s. Du kan ta bort denna gräns i inställningarna.',
                low_speed_warning: 'Låg hastighet kan orsaka brännskador på materialet.',
                repeat: 'Passantal',
                advanced: 'Avancerat',
                lower_focus: 'Sänk Fokus',
                by: 'Med',
                stepwise_focusing: 'Stegvis Fokusering',
                single_color: 'Enkelfärg',
                lower_focus_desc: 'Sänka fokus höjden med ett specifikt avstånd efter fokusering för att förbättra skärprestanda.',
                stepwise_focusing_desc: 'Sänka successivt fokusavståndet baserat på objektets höjd under varje pass antal.',
                single_color_desc: 'Endast tillämpligt på fullfärgs lager och kan inte användas med expanderad enfärgs lager.',
                focus_adjustment: 'Fokusjustering',
                height: 'Objekthöjd',
                z_step: 'Z-steg',
                diode: 'Diodlaser',
                backlash: 'Backlash',
                ink_saturation: 'Mättnad',
                print_multipass: 'Fler-pass',
                white_ink: 'Vitt bläck',
                white_ink_settings: 'Inställningar för vitt bläck',
                color_adjustment: 'Kanaljustering',
                color_adjustment_short: 'Kanal',
                halftone: 'Halvton',
                halftone_link: 'https://support.flux3dp.com/hc/en-us/articles/9402670389647',
                color_strength: 'Styrka',
                times: 'gånger',
                cut: 'Skär',
                engrave: 'Gravera',
                more: 'Hantera',
                apply: 'Verkställ',
                cancel: 'Avbryt',
                save: 'Spara',
                custom_preset: 'Anpassad',
                various_preset: 'Olika förinställningar',
                module: 'Modul',
                slider: {
                    regular: 'Vanlig',
                    low: 'Låg',
                    very_low: 'Lägsta',
                    high: 'Hög',
                    very_high: 'Högsta',
                    slow: 'Långsam',
                    very_slow: 'Långsammast',
                    fast: 'Snabb',
                    very_fast: 'Snabbast',
                },
                dropdown: {
                    parameters: 'Förinställningar',
                    save: 'Lägg till aktuella inställningar',
                    mm: {
                        wood_3mm_cutting: 'Trä - 3 mm skärning',
                        wood_5mm_cutting: 'Trä - 5 mm skärning',
                        wood_7mm_cutting: 'Trä - 7mm skärning',
                        wood_8mm_cutting: 'Trä - 8 mm skärning',
                        wood_10mm_cutting: 'Trä - 10 mm skärning',
                        wood_engraving: 'Trä - gravering',
                        acrylic_3mm_cutting: 'Akryl - 3 mm skärning',
                        acrylic_5mm_cutting: 'Akryl - 5 mm skärning',
                        acrylic_8mm_cutting: 'Akryl - 8 mm skärning',
                        acrylic_10mm_cutting: 'Akryl - 10 mm skärning',
                        acrylic_engraving: 'Akryl - gravering',
                        mdf_3mm_cutting: 'MDF - 3mm skärning',
                        mdf_5mm_cutting: 'MDF - 5mm skärning',
                        mdf_engraving: 'MDF - gravering',
                        leather_3mm_cutting: 'Läder - 3 mm skärning',
                        leather_5mm_cutting: 'Läder - 5 mm skärning',
                        leather_engraving: 'Läder - gravering',
                        denim_1mm_cutting: 'Denim - 1mm skärning',
                        fabric_3mm_cutting: 'Tyg - 3 mm skärning',
                        fabric_5mm_cutting: 'Tyg - 5 mm skärning',
                        fabric_engraving: 'Tyg - gravering',
                        rubber_bw_engraving: 'Gummi - gravering',
                        glass_bw_engraving: 'Glas - gravering',
                        metal_bw_engraving: 'Metall - gravering',
                        steel_engraving_spray_engraving: 'Metall - gravering',
                        stainless_steel_bw_engraving_diode: 'Rostfritt stål - gravering (diodlaser)',
                        gold_engraving: 'Guld - gravering',
                        brass_engraving: 'Mässing - gravering',
                        ti_engraving: 'Titan - gravering',
                        stainless_steel_engraving: 'Rostfritt stål - gravering',
                        aluminum_engraving: 'Aluminium - gravering',
                        black_acrylic_3mm_cutting: 'Svart akryl - 3mm skärning',
                        black_acrylic_5mm_cutting: 'Svart akryl - 5mm skärning',
                        black_acrylic_engraving: 'Svart akryl - gravering',
                        abs_engraving: 'ABS - gravering',
                        silver_engraving: 'Silver - gravering',
                        iron_engraving: 'Järn - gravering',
                        fabric_printing: 'Tyg - utskrift',
                        canvas_printing: 'Canvas - utskrift',
                        cardstock_printing: 'Kartong - utskrift',
                        wood_printing: 'Trä - utskrift',
                        bamboo_printing: 'Bambus - utskrift',
                        cork_printing: 'Kork - utskrift',
                        flat_stone_printing: 'Platt sten - utskrift',
                        acrylic_printing: 'Akryl - utskrift',
                        pc_printing: 'PC - utskrift',
                        stainless_steel_printing: 'Rostfritt stål - utskrift',
                        gloss_leather_printing: 'Blankt läder - utskrift',
                        glass_printing: 'Glas - utskrift',
                    },
                    inches: {
                        wood_3mm_cutting: 'Trä - 0,1" skärning',
                        wood_5mm_cutting: 'Trä - 0,2" skärning',
                        wood_7mm_cutting: 'Trä - 0,3" skärning',
                        wood_8mm_cutting: 'Trä - 0,3" skärning',
                        wood_10mm_cutting: 'Trä - 0,4" skärning',
                        wood_engraving: 'Trä - gravering',
                        acrylic_3mm_cutting: 'Akryl - 0,1" skärning',
                        acrylic_5mm_cutting: 'Akryl - 0,2" skärning',
                        acrylic_8mm_cutting: 'Akryl - 0,3" skärning',
                        acrylic_10mm_cutting: 'Akryl - 0,4" skärning',
                        acrylic_engraving: 'Akryl - gravering',
                        mdf_3mm_cutting: 'MDF - 0,1" skärning',
                        mdf_5mm_cutting: 'MDF - 0,2" skärning',
                        mdf_engraving: 'MDF - Gravyr',
                        leather_3mm_cutting: 'Läder - 0,1" skärning',
                        leather_5mm_cutting: 'Läder - 0,2" skärning',
                        leather_engraving: 'Läder - gravering',
                        denim_1mm_cutting: 'Denim - 0,04" skärning',
                        fabric_3mm_cutting: 'Tyg - 0,1" skärning',
                        fabric_5mm_cutting: 'Tyg - 0,2" skärning',
                        fabric_engraving: 'Tyg - gravering',
                        rubber_bw_engraving: 'Gummi - gravering',
                        glass_bw_engraving: 'Glas - gravering',
                        metal_bw_engraving: 'Metall - gravering',
                        steel_engraving_spray_engraving: 'Metall - gravering',
                        stainless_steel_bw_engraving_diode: 'Metall - gravering (diodlaser)',
                        gold_engraving: 'Guld - Gravyr',
                        brass_engraving: 'Mässing - Gravyr',
                        ti_engraving: 'Titan - Gravyr',
                        stainless_steel_engraving: 'Rostfritt stål - Gravyr',
                        aluminum_engraving: 'Aluminium - Gravyr',
                        black_acrylic_3mm_cutting: 'Svart akryl - 0,1" skärning',
                        black_acrylic_5mm_cutting: 'Svart akryl - 0,2" skärning',
                        black_acrylic_engraving: 'Svart akryl - Gravyr',
                        abs_engraving: 'ABS - Gravyr',
                        silver_engraving: 'Silver - Gravyr',
                        iron_engraving: 'Järn - Gravyr',
                        fabric_printing: 'Tyg - Utskrift',
                        canvas_printing: 'Duk - Utskrift',
                        cardstock_printing: 'Kartong - Utskrift',
                        wood_printing: 'Trä - Utskrift',
                        bamboo_printing: 'Bambuträ - Utskrift',
                        cork_printing: 'Kork - Utskrift',
                        flat_stone_printing: 'Platt sten - Utskrift',
                        acrylic_printing: 'Akryl - Utskrift',
                        pc_printing: 'PC - Utskrift',
                        stainless_steel_printing: 'Rostfritt stål - Utskrift',
                        gloss_leather_printing: 'Blankt läder - Utskrift',
                        glass_printing: 'Glas - Utskrift',
                    },
                },
                laser_speed: {
                    text: 'Hastighet',
                    unit: 'mm/s',
                    fast: 'Snabb',
                    slow: 'Långsam',
                    min: 3,
                    max: 300,
                    step: 0.1,
                },
                power: {
                    text: 'Effekt',
                    high: 'Hög',
                    low: 'Låg',
                    min: 1,
                    max: 100,
                    step: 0.1,
                },
                ink_type: {
                    text: 'Bläcktyp',
                    normal: 'Bläck',
                    UV: 'UV-bläck',
                },
                para_in_use: 'Den här parametern används.',
                do_not_adjust_default_para: 'Standardinställningarna kan inte justeras.',
                existing_name: 'Det här namnet har redan använts.',
                presets: 'Standardinställning',
                preset_management: {
                    preset: 'Förinställning',
                    title: 'Hantera Parametrar',
                    add_new: 'Lägg till Ny',
                    lower_focus_by: 'Sänk Fokus med',
                    save_and_exit: 'Spara och avsluta',
                    delete: 'Ta bort',
                    reset: 'Återställ',
                    sure_to_reset: 'Detta kommer att ta bort dina anpassade parametrar och återställa alla förinställningar, är du säker på att du vill fortsätta?',
                    show_all: 'Visa Alla',
                    laser: 'Laser',
                    print: 'Skriv Ut',
                    export: 'Exportera',
                    export_preset_title: 'Exportera förinställningar',
                    import: 'Importera',
                    sure_to_import_presets: 'Detta kommer att ladda arrangemanget av förinställningar och skriva över anpassade parametrar, är du säker på att du vill fortsätta?',
                    new_preset_name: 'Nytt förinställningsnamn',
                },
            },
            object_panel: {
                zoom: 'Zooma',
                group: 'Gruppera',
                ungroup: 'Dela upp',
                distribute: 'Distribuera',
                hdist: 'Distribuera horisontellt',
                vdist: 'Distribuera vertikalt',
                align: 'Justera',
                left_align: 'Vänsterjustera',
                center_align: 'Centrera',
                right_align: 'Högerjustera',
                top_align: 'Toppjustera',
                middle_align: 'Mittenjustera',
                bottom_align: 'Bottenjustera',
                boolean: 'Boolesk',
                union: 'Förena',
                subtract: 'Subtrahera',
                intersect: 'Skärning',
                difference: 'Skillnad',
                flip: 'Vänd',
                hflip: 'Horisontell vändning',
                vflip: 'Vertikal vändning',
                lock_aspect: 'Lås proportioner',
                unlock_aspect: 'Lås upp proportioner',
                option_panel: {
                    fill: 'Fyllning',
                    rounded_corner: 'Rundade hörn',
                    sides: 'Sidor',
                    font_family: 'Typsnitt',
                    font_style: 'Stil',
                    font_size: 'Storlek',
                    letter_spacing: 'Bokstavsavstånd',
                    line_spacing: 'Radavstånd',
                    vertical_text: 'Vertikal text',
                    start_offset: 'Textförskjutning',
                    vertical_align: 'Justering',
                    text_infill: 'Textifyllnad',
                    path_infill: 'Banaifyllnad',
                    shading: 'Gradient',
                    pwm_engraving: 'Djup-läge',
                    pwm_engraving_link: 'https://support.flux3dp.com/hc/en-us/articles/10419884701327',
                    threshold: 'Ljusstyrketröskel',
                    threshold_short: 'Tröskel',
                    stroke: 'Kontur',
                    stroke_color: 'Konturfärg',
                    stroke_width: 'Linjebredd',
                    color: 'Färg',
                },
                actions_panel: {
                    replace_with: 'Ersätt med...',
                    replace_with_short: 'Ersätt',
                    trace: 'Spåra',
                    grading: 'Gradering',
                    brightness: 'Ljusstyrka',
                    sharpen: 'Skärpa',
                    crop: 'Beskär',
                    bevel: 'Fasa',
                    invert: 'Invertera',
                    weld_text: 'Svetsa text',
                    convert_to_path: 'Konvertera till bana',
                    fetching_web_font: 'Hämtar webbfont...',
                    uploading_font_to_machine: 'Laddar upp font till maskin...',
                    wait_for_parsing_font: 'Tolkar font... Vänta en sekund',
                    offset: 'Förskjutning',
                    array: 'Fält',
                    auto_fit: 'Auto Fit',
                    smart_nest: 'Smart Nest',
                    decompose_path: 'Dela upp',
                    disassemble_use: 'Demontera',
                    create_textpath: 'Skapa text på bana',
                    create_textpath_short: 'Text på bana',
                    detach_path: 'Dela upp text på bana',
                    detach_path_short: 'Dela upp',
                    edit_path: 'Redigera bana',
                    disassembling: 'Demonterar...',
                    ungrouping: 'Avgrupperar...',
                    simplify: 'Förenkla',
                    ai_bg_removal: 'Ta bort bakgrunden',
                    ai_bg_removal_short: 'BG bort',
                    ai_bg_removal_reminder: 'Tryck på knappen kommer omedelbart att använda 0,2 kredit, vill du fortsätta?',
                    outline: 'Kontur',
                },
                path_edit_panel: {
                    node_type: 'NODTYP',
                    sharp: 'Skarp',
                    round: 'Rund',
                    connect: 'Anslut',
                    disconnect: 'Koppla från',
                    delete: 'Ta bort',
                },
            },
        },
        bottom_right_panel: {
            convert_text_to_path_before_export: 'Konvertera text till bana...',
            retreive_image_data: 'Hämtar bilddata...',
            export_file_error_ask_for_upload: 'Misslyckades att exportera uppgift. Vill du skicka arbetsytan till utvecklingsteamet för felrapportering?',
        },
        image_trace_panel: {
            apply: 'Tillämpa',
            back: 'Tillbaka',
            cancel: 'Avbryt',
            next: 'Nästa',
            brightness: 'Ljusstyrka',
            contrast: 'Kontrast',
            threshold: 'Tröskelvärde',
            okay: 'OK',
            tuning: 'Inställningar',
        },
        photo_edit_panel: {
            apply: 'Tillämpa',
            back: 'Tillbaka',
            cancel: 'Avbryt',
            next: 'Nästa',
            sharpen: 'Skärpa',
            sharpness: 'Skärpa',
            radius: 'Radie',
            crop: 'Beskär',
            aspect_ratio: 'Bildförhållande',
            original: 'Original',
            free: 'Fritt',
            curve: 'Kurva',
            start: 'Start',
            processing: 'Bearbetar',
            invert: 'Invertera färg',
            okay: 'OK',
            compare: 'Jämför',
            phote_edit: 'Foto redigering',
            brightness_and_contrast: 'Ljusstyrka / Kontrast',
            brightness: 'Ljusstyrka',
            contrast: 'Kontrast',
            rotary_warped: 'Roterande Förvrängd',
            rotary_warped_link: 'https://support.flux3dp.com/hc/en-us/articles/10828006201103',
            diameter: 'Diameter',
            circumference: 'Omkrets',
            warp: 'Förvrängning',
        },
        document_panel: {
            document_settings: 'Dokumentinställningar',
            machine: 'Maskin',
            watts: 'Watt',
            workarea: 'Arbetsområde',
            rotary_mode: 'Roterande',
            borderless_mode: 'Öppen botten',
            engrave_dpi: 'Upplösning',
            enable_diode: 'Diodlaser',
            enable_autofocus: 'Autofokus',
            extend_workarea: 'Utvidga arbetsområdet',
            mirror: 'Spegel',
            pass_through: 'Passera Igenom',
            pass_through_height_desc: 'Ange längden på objektet för att utöka arbetsområdet.',
            start_position: 'Startposition',
            start_from: 'Starta Från',
            origin: 'Ursprung',
            current_position: 'Nuvarande Position',
            job_origin: 'Jobb Ursprung',
            add_on: 'Tillägg',
            low: 'Låg',
            medium: 'Medium',
            high: 'Hög',
            ultra: 'Ultrahög',
            enable: 'Aktivera',
            disable: 'Inaktivera',
            cancel: 'Avbryt',
            save: 'Spara',
            notification: {
                changeFromPrintingWorkareaTitle: 'Vill du konvertera utskriftslagren till laserskikt?',
            },
        },
        object_panels: {
            wait_for_parsing_font: 'Tolkar teckensnitt ... Vänta en sekund',
            text_to_path: {
                font_substitute_pop: 'Din text innehåller tecken som inte stöds av aktuellt typsnitt. <br/>Vill du använda <strong>%s</strong> som ersättning?',
                check_thumbnail_warning: 'Vissa texter ändrades till andra typsnitt när text tolkades till banor och vissa tecken kanske inte konverterades korrekt. <br/> Kontrollera förhandsgranskningsbilden igen innan du skickar uppgiften.',
                error_when_parsing_text: 'Fel vid omvandling av text till bana',
                use_current_font: 'Använd aktuellt typsnitt',
                retry: 'Försök igen senare eller välj ett annat typsnitt',
            },
            lock_desc: 'Behåll bredd- och höjdförhållandet (SHIFT)',
        },
        tool_panels: {
            cancel: 'Avbryt',
            confirm: 'Bekräfta',
            grid_array: 'Skapa rutnätsarray',
            array_dimension: 'Arraydimension',
            rows: 'Rader',
            columns: 'Kolumner',
            array_interval: 'Arrayintervall',
            dx: 'X',
            dy: 'Y',
            offset: 'Förskjutning',
            nest: 'Optimering',
            _offset: {
                direction: 'Förskjutningsriktning',
                inward: 'Inåt',
                outward: 'Utåt',
                dist: 'Förskjutningsavstånd',
                corner_type: 'Hörn',
                sharp: 'Skarp',
                round: 'Rund',
                fail_message: 'Misslyckades att förskjuta objekt.',
                not_support_message: 'Valda element som innehåller ej supportade SVG-taggar:\nBild, Grupp, Text och Importerat Objekt.',
            },
            _nest: {
                start_nest: 'Optimera',
                stop_nest: 'Stoppa',
                end: 'Stäng',
                spacing: 'Mellanrum',
                rotations: 'Möjliga rotationer',
                no_element: 'Det finns inga element att optimera.',
            },
        },
        network_testing_panel: {
            network_testing: 'Nätverkstest',
            local_ip: 'Lokal IP-adress:',
            insert_ip: 'Målenhetens IP-adress:',
            empty_ip: '#818 Ange målenhetens IP-adress först.',
            start: 'Starta',
            end: 'Sluta',
            testing: 'Testar nätverk...',
            invalid_ip: '#818 Ogiltig IP-adress',
            ip_startswith_169: '#843 Maskinens IP-adress börjar med 169.254',
            connection_quality: 'Anslutningskvalitet',
            average_response: 'Genomsnittlig svarstid',
            test_completed: 'Test slutförd',
            test_fail: 'Test misslyckades',
            cannot_connect_1: '#840 Misslyckades att ansluta till mål-IP.',
            cannot_connect_2: '#840 Misslyckades att ansluta till mål-IP. Kontrollera att målet är i samma nätverk.',
            network_unhealthy: '#841 Dålig anslutningskvalitet eller hög svarstid',
            device_not_on_list: '#842 Maskinen hittades inte i listan men anslutningen verkar fungera',
            hint_device_often_on_list: 'Hittas maskinen sällan i listan?',
            link_device_often_on_list: 'https://support.flux3dp.com/hc/en-us/articles/360001841636',
            hint_connect_failed_when_sending_job: 'Problem att ansluta vid jobbsändning?',
            link_connect_failed_when_sending_job: 'https://support.flux3dp.com/hc/en-us/articles/360001841656',
            hint_connect_camera_timeout: 'Tidsgränser vid kameraförhandsgranskning?',
            link_connect_camera_timeout: 'https://support.flux3dp.com/hc/en-us/articles/360001791895',
            cannot_get_local: 'Kunde inte hämta lokal IP-adress',
            fail_to_start_network_test: '#817 Misslyckades starta nätverktest',
            linux_permission_hint: 'Otillräckliga behörigheter. Kör "sudo beam-studio --no-sandbox" för nätverktest.',
        },
        layer_color_config_panel: {
            layer_color_config: 'Lagerfärgsinställningar',
            color: 'Färg',
            power: 'Effekt',
            speed: 'Hastighet',
            repeat: 'Upprepa',
            add: 'Lägg till',
            save: 'Spara',
            cancel: 'Avbryt',
            default: 'Återställ till standard',
            add_config: 'Lägg till färg',
            in_use: 'Den här färgen används.',
            no_input: 'Ange giltig hex-färgkod.',
            sure_to_reset: 'Du kommer att förlora alla anpassade inställningar, är du säker på att du vill återställa till standard?',
            sure_to_delete: 'Är du säker på att du vill ta bort den här färginställningen?',
        },
        rating_panel: {
            title: 'Gillar du Beam Studio?',
            description: 'Om du gillar Beam Studio skulle vi uppskatta det mycket om du kunde betygsätta oss.',
            dont_show_again: 'Visa inte detta nästa gång.',
            thank_you: 'Tack!',
        },
        svg_editor: {
            unnsupported_file_type: 'Filtypen stöds inte direkt. Vänligen konvertera filen till SVG eller bitmap',
            unable_to_fetch_clipboard_img: 'Misslyckades att hämta bild från urklipp',
        },
        units: {
            walt: 'W',
            mm: 'mm',
        },
        path_preview: {
            play: 'Spela',
            pause: 'Pausa',
            stop: 'Stoppa',
            play_speed: 'Uppspelningshastighet',
            travel_path: 'Förflyttningsväg',
            invert: 'Invertera',
            preview_info: 'Förhandsgranskningsinformation',
            size: 'Storlek',
            estimated_time: 'Total uppskattad tid',
            cut_time: 'Skärtid',
            rapid_time: 'Förflyttningstid',
            cut_distance: 'Skärdistans',
            rapid_distance: 'Förflyttningsdistans',
            current_position: 'Aktuell position',
            remark: '* All information är uppskattade värden som referens.',
            start_here: 'Börja här',
            end_preview: 'Sluta förhandsgranska',
        },
        shapes_panel: {
            title: 'Objekt',
            basic: 'Grundläggande',
            shape: 'Form',
            graphics: 'Grafik',
            arrow: 'Pil',
            label: 'Etikett',
            decor: 'Dekor',
            circular: 'Cirkulär',
            corner: 'Hörn',
            line: 'Linje',
            photo: 'Foto',
            ribbon: 'Band',
            speech: 'Tal',
            text: 'Textruta',
            animals: 'Djur',
            birds: 'Fågel',
            land: 'Land',
            sea: 'Hav',
            holidays: 'Helgdag',
            celebration: 'Firande',
            CNY: 'Kinesiskt nyår',
            easter: 'Påsk',
            halloween: 'Halloween',
            valentines: 'Alla hjärtans dag',
            Xmas: 'Jul',
            nature: 'Natur',
            elements: 'Element',
            environment: 'Miljö',
            plants: 'Växter',
            weather: 'Väder',
        },
        announcement_panel: {
            title: 'Meddelande',
            dont_show_again: 'Visa inte igen',
        },
    },
    editor: {
        prespray_area: 'Primeringsyta',
        opacity: 'Förhandsvisningsgenomskinlighet',
        exposure: 'Förhandsvisningsexponering',
    },
    flux_id_login: {
        connection_fail: '#847 Misslyckades att ansluta till FLUX medlemstjänst.',
        login_success: 'Inloggningen lyckades.',
        login: 'Logga in',
        unlock_shape_library: 'Logga in för att låsa upp formdatabasen.',
        email: 'E-post',
        password: 'Lösenord',
        remember_me: 'Kom ihåg mig',
        forget_password: 'Glömt lösenord?',
        register: 'Skapa ditt FLUX-konto',
        offline: 'Arbeta offline',
        work_offline: 'Arbeta offline',
        incorrect: 'Fel e-postadress eller lösenord.',
        not_verified: 'E-postadressen har inte verifierats ännu.',
        new_to_flux: 'Ny på FLUX? Skapa ett konto.',
        signup_url: 'https://id.flux3dp.com/user/login#up',
        lost_password_url: 'https://id.flux3dp.com/user/forgot-password',
        flux_plus: {
            explore_plans: 'Utforska FLUX+ planer',
            thank_you: 'Tack för att du är en värdefull medlem!',
            ai_credit_tooltip: 'För AI-bakgrundsborttagning',
            flux_credit_tooltip: 'För designmarknadsfiler och AI-bakgrundsborttagning',
            goto_member_center: 'Gå till medlemscenter',
            access_plus_feature_1: 'Du använder en',
            access_plus_feature_2: 'funktion.',
            access_plus_feature_note: 'Du måste ha FLUX+ medlemskap för att använda den här funktionen.',
            access_monotype_feature: 'Du har inte Monotype Fonts-tillägget.',
            access_monotype_feature_note: 'Du måste ha FLUX+ Pro-medlemskap eller Monotype Fonts-tillägget för att använda den här funktionen.',
            learn_more: 'Läs mer',
            get_addon: 'Skaffa tillägg',
            subscribe_now: 'Prenumerera nu',
            website_url: 'https://flux3dp.com/subscription',
            member_center_url: 'https://member.flux3dp.com/en-US/subscription',
            features: {
                ai_bg_removal: 'AI bakgrundsborttagning',
                my_cloud: 'Obegränsad molnlagring',
                boxgen: '3D lådgenerator',
                dmkt: '1000+ designfiler',
                monotype: '250+ premiumtypsnitt',
            },
        },
    },
    noun_project_panel: {
        login_first: 'Logga in för att låsa upp formdatabasen.',
        enjoy_shape_library: 'Njut av formdatabasen.',
        shapes: 'Former',
        elements: 'Element',
        recent: 'Senaste',
        search: 'Sök',
        clear: 'Rensa',
        export_svg_title: 'Kan inte exportera SVG',
        export_svg_warning: 'Det här projektet innehåller objekt som skyddas av upphovsrätten. Därför kommer Beam Studio automatiskt utesluta dessa objekt vid exporten. Du kan fortfarande spara ditt projekt i Beam Studio Scene (.beam-format) för att behålla alla dina data. Vill du fortfarande exportera?',
        learn_more: 'Läs mer',
    },
    change_logs: {
        change_log: 'Ändringsloggar:',
        added: 'Tillagt:',
        fixed: 'Fixat:',
        changed: 'Ändrat:',
        see_older_version: 'Se äldre versioner',
        help_center_url: 'https://support.flux3dp.com/hc/en-us/sections/360000421876',
    },
    select_device: {
        select_device: 'Välj enhet',
        auth_failure: '#811 Autentisering misslyckades',
        unable_to_connect: '#810 Kan inte bygga en stabil anslutning med maskinen',
    },
    device: {
        pause: 'Pausa',
        paused: 'Pausad',
        pausing: 'Pausar',
        select_printer: 'Välj skrivare',
        retry: 'Försök igen',
        status: 'Status',
        busy: 'Upptagen',
        ready: 'Redo',
        reset: 'Återställ',
        abort: 'Avbryt',
        start: 'Starta',
        please_wait: 'Vänta...',
        quit: 'Avsluta',
        completing: 'Slutför',
        aborted: 'Avbruten',
        completed: 'Slutförd',
        aborting: 'Avbryter',
        starting: 'Startar',
        preparing: 'Förbereder',
        resuming: 'Återupptar',
        scanning: 'Skannar',
        occupied: 'Underhåller',
        running: 'Arbetar',
        uploading: 'Laddar upp',
        processing: 'Bearbetar',
        disconnectedError: {
            caption: 'Maskinen frånkopplad',
            message: 'Vänligen kontrollera om nätverksåtkomsten för %s är tillgänglig',
        },
        unknown: 'Okänd',
        pausedFromError: 'Pausad på grund av fel',
        model_name: 'Modellnamn',
        IP: 'IP',
        serial_number: 'Serienummer',
        firmware_version: 'Firmware-version',
        UUID: 'UUID',
        select: 'Välj',
        deviceList: 'Maskinlista',
        disable: 'Inaktivera',
        enable: 'Aktivera',
        submodule_type: 'Module',
        cartridge_serial_number: 'Ink cartridge serial number',
        ink_color: 'Ink color',
        ink_type: 'Ink type',
        ink_level: 'Ink level',
        close_door_to_read_cartridge_info: "To access ink information, please close the machine's door cover.",
        cartridge_info_read_failed: 'Please ensure that the ink is fully inserted. Try to remove the ink and install it back.',
        cartridge_info_verification_failed: 'Please verify that your ink is FLUX authentic.',
        toolhead_change: 'Byt verktygshuvud',
    },
    monitor: {
        monitor: 'ÖVERVAKA',
        go: 'Starta',
        resume: 'Återuppta',
        start: 'Starta',
        pause: 'Pausa',
        stop: 'Stoppa',
        record: 'Spela in',
        camera: 'Kamera',
        taskTab: 'Uppgift',
        connecting: 'Ansluter, vänta...',
        HARDWARE_ERROR_MAINBOARD_ERROR: '#401 Kritiskt fel: Huvudkort offline. Kontakta FLUX support.',
        HARDWARE_ERROR_SUBSYSTEM_ERROR: '#402 Kritiskt fel: Inget svar från delsystem. Kontakta FLUX support.',
        HARDWARE_ERROR_PUMP_ERROR: '#900 Kontrollera din vattentank.',
        HARDWARE_ERROR_DOOR_OPENED: '#901 Stäng dörren för att fortsätta.',
        HARDWARE_ERROR_OVER_TEMPERATURE: '#902 Överhettad. Vänta några minuter.',
        HARDWARE_ERROR_BOTTOM_OPENED: '#903 Botten öppen. Stäng botten för att fortsätta.',
        HARDWARE_ERROR_PLATFORM_HOMING_FAILED: '#910 Z-axel återställning misslyckades',
        HARDWARE_ERROR_DRAWER_OPENED: '#911 Lådan öppnades',
        HARDWARE_ERROR_FIRE_DETECTED: '#912 Flamsäkerhetssensor avvikande',
        HARDWARE_ERROR_AIR_ASSIST_ABNORMAL: '#913 Luftflödessensor avvikande',
        HARDWARE_ERROR_ROTARY_NOT_DETECTED: '#914 Ingen roterande modul detekterad',
        HARDWARE_ERROR_HOMING_PULLOFF_FAILED: '#920 Homing Pull-Off-fel',
        HARDWARE_ERROR_HEADTYPE_MISMATCH: '#915 Fel modul upptäckt. Installera rätt modul för att fortsätta.',
        HARDWARE_ERROR_HEADTYPE_NONE: '#917 Modul ej upptäckt. Kontrollera att modulen är korrekt installerad för att fortsätta.',
        HARDWARE_ERROR_HEADTYPE_UNKNOWN: '#918 Okänd modul upptäckt. Installera rätt modul för att fortsätta.',
        HARDWARE_ERROR_PRINTER_NO_RESPONSE: '#919 Inget svar från skrivarmodul.',
        USER_OPERATION_ROTARY_PAUSE: 'Växla till den roterande motorn',
        USER_OPERATION_REMOVE_CARTRIDGE: 'Ta bort patronen för att fortsätta.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_MISMATCH: '#915 Fel modul upptäckt. Installera rätt modul för att fortsätta.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_NONE: '#917 Modul ej upptäckt. Kontrollera att modulen är korrekt installerad för att fortsätta.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_UNKNOWN: '#918 Okänd modul upptäckt. Installera rätt modul för att fortsätta.',
        USER_OPERATION_CHANGE_TOOLHEAD: 'Installera rätt modul för att fortsätta.',
        USER_OPERATION_CHANGE_CARTRIDGE_CARTRIDGE_NOT_DETECTED: 'Sätt i patronen för att fortsätta.',
        USER_OPERATION_CHANGE_CARTRIDGE: 'Sätt i rätt patron för att fortsätta.',
        USER_OPERATION: 'Följ instruktionerna på enhetspanelen för att fortsätta.',
        RESOURCE_BUSY: 'Maskinen är upptagen. Om den inte körs, starta om maskinen',
        DEVICE_ERROR: 'Något gick fel. Starta om maskinen',
        NO_RESPONSE: '#905 Fel vid anslutning till huvudkortet. Starta om maskinen.',
        SUBSYSTEM_ERROR: '#402 Kritiskt fel: Delsystem svarar inte. Kontakta FLUX-supporten.',
        HARDWARE_FAILURE: 'Något gick fel. Starta om maskinen',
        MAINBOARD_OFFLINE: '#905 Fel vid anslutning till huvudkortet. Starta om maskinen.',
        bug_report: 'Felrapport',
        processing: 'Bearbetar',
        savingPreview: 'Genererar miniatyrbilder',
        hour: 'h',
        minute: 'm',
        second: 's',
        left: 'vänster',
        temperature: 'Temperatur',
        forceStop: 'Vill du avbryta aktuell uppgift?',
        upload: 'Ladda upp',
        download: 'Ladda ner',
        relocate: 'Flytta',
        cancel: 'Avbryt',
        prepareRelocate: 'Förbereder flytt',
        extensionNotSupported: 'Det här filformatet stöds inte',
        fileExistContinue: 'Filen finns redan, vill du ersätta den?',
        confirmFileDelete: 'Är du säker på att du vill radera den här filen?',
        ask_reconnect: 'Anslutningen till maskinen har brutits. Vill du återansluta?',
        task: {
            BEAMBOX: 'Lasergravering',
            'N/A': 'Fritt läge',
        },
    },
    alert: {
        caption: 'Fel',
        duplicated_preset_name: 'Duplicerat förinställningsnamn',
        info: 'INFO',
        warning: 'VARNING',
        error: 'OJ',
        instruction: 'Instruktion',
        oops: 'Hoppsan...',
        retry: 'Försök igen',
        abort: 'Avbryt',
        confirm: 'Bekräfta',
        cancel: 'Avbryt',
        close: 'Stäng',
        ok: 'OK',
        ok2: 'OK',
        yes: 'Ja',
        no: 'Nej',
        stop: 'Stopp',
        save: 'Spara',
        dont_save: 'Spara inte',
        learn_more: 'Läs mer',
    },
    caption: {
        connectionTimeout: 'Anslutningstidsgräns',
    },
    message: {
        cancelling: 'Avbryter...',
        connecting: 'Ansluter...',
        connectingMachine: 'Ansluter %s...',
        tryingToConenctMachine: 'Försöker ansluta till maskin...',
        connected: 'Ansluten',
        authenticating: 'Autentiserar...',
        enteringRawMode: 'Går in i råläge...',
        endingRawMode: 'Lämnar råläge...',
        enteringLineCheckMode: 'Går in i linjekontrolläge...',
        endingLineCheckMode: 'Avslutar linjekontrollläge...',
        exitingRotaryMode: 'Avslutar roterande läge...',
        turningOffFan: 'Stänger av fläkt...',
        turningOffAirPump: 'Stänger av luftpump...',
        gettingLaserSpeed: 'Läser laserhuvudhastighet...',
        settingLaserSpeed: 'Ställer in laserhuvudhastighet...',
        retrievingCameraOffset: 'Läser kameraoffset...',
        connectingCamera: 'Ansluter kamera...',
        homing: 'Hemkörning...',
        connectionTimeout: '#805 Enhetens anslutningstid gick ut. Kontrollera ditt nätverkstillstånd och maskinens WiFi-indikator.',
        getProbePosition: 'Hämtar sondposition...',
        device_not_found: {
            caption: 'Standardmaskin hittades inte',
            message: '#812 Kontrollera maskinens WiFi-indikator',
        },
        device_busy: {
            caption: 'Maskinen är upptagen',
            message: 'Maskinen utför en annan uppgift, försök igen senare. Om den slutar fungera, starta om maskinen.',
        },
        device_is_used: 'Maskinen används, vill du avbryta aktuell uppgift?',
        monitor_too_old: {
            caption: 'Föråldrad firmware',
            content: '#814 Installera den senaste firmware med <a target="_blank" href="http://helpcenter.flux3dp.com/hc/en-us/articles/216251077">denna guide</a>.',
        },
        unknown_error: '#821 Applikationen har stött på ett okänt fel, använd Hjälp > Meny > Rapportera bugg.',
        unknown_device: '#826 Kan inte ansluta till maskinen, kontrollera att USB är ansluten till maskinen',
        unsupport_osx_version: 'Aktuell macOS-version %s kanske inte stöder alla funktioner. Uppdatera gärna till macOS 11+.',
        unsupport_win_version: 'Aktuell OS-version %s kanske inte stöder alla funktioner. Uppdatera gärna till den senaste versionen.',
        need_password: 'Lösenord krävs för att ansluta till maskinen',
        unavailableWorkarea: '#804 Aktuellt arbetsområde överskrider maskinens arbetsområde. Kontrollera arbetsområdet för vald maskin eller ställ in arbetsområde från Redigera > Dokumentinställningar.',
        please_enter_dpi: 'Ange enheten för din fil (i mm)',
        auth_error: '#820 Autentiseringsfel: Uppdatera Beam Studio och maskinens firmware till senaste versionen.',
        usb_unplugged: 'USB-anslutningen förlorades. Kontrollera din USB-anslutning',
        uploading_fcode: 'Laddar upp FCode',
        cant_connect_to_device: '#827 Kan inte ansluta till maskinen, kontrollera din anslutning',
        unable_to_find_machine: 'Kan inte hitta maskin ',
        disconnected: 'Ostabil anslutning, kontrollera enhetsanslutning och försök igen senare',
        unable_to_start: '#830 Det gick inte att starta uppgiften. Försök igen. Om detta händer igen, vänligen kontakta oss med en felrapport:',
        camera: {
            camera_cable_unstable: 'Det upptäcktes att kameran överför bilder ostabilt. Förhandsgranskning kan fortfarande utföras normalt, men det kan finnas problem med långsam förhandsgranskning eller timeout.',
            fail_to_transmit_image: '#845 Något gick fel med bildöverföringen. Starta om din maskin eller Beam Studio. Om felet kvarstår, följ <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/4402756056079">denna guide</a>.',
            ws_closed_unexpectly: '#844 Anslutningen till maskinkameran har stängts oväntat. Om felet kvarstår, följ <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/4402755805071">denna guide</a>.',
            continue_preview: 'Fortsätt',
            abort_preview: 'Avbryt',
        },
        preview: {
            camera_preview: 'Kameraförhandsgranskning',
            auto_focus: 'Autofokus',
            auto_focus_instruction: 'Flytta laserhuvudet över objektet och följ anvisningarna för att trycka på AF för att fokusera.',
            already_performed_auto_focus: 'Du har redan utfört autofokus, använd befintliga värden?',
            please_enter_height: 'Ange objektets höjd. För att ta en exakt kamerabild.',
            apply: 'Verkställ',
            enter_manually: 'Ange manuellt',
            adjust: 'Justera',
            adjust_height_tooltip: 'Markera för att aktivera redigering.',
        },
        unsupported_example_file: 'Det valda exempelfilen stöds inte av aktuellt arbetsområde.',
        time_remaining: 'Återstående tid:',
    },
    machine_status: {
        '0': 'Inaktiv',
        '1': 'Initierar',
        '2': 'ST_TRANSFORM',
        '4': 'Startar',
        '6': 'Återupptar',
        '16': 'Arbetar',
        '18': 'Återupptar',
        '32': 'Pausad',
        '36': 'Pausad',
        '38': 'Pausar',
        '48': 'Pausad',
        '50': 'Pausar',
        '64': 'Slutförd',
        '66': 'Slutför',
        '68': 'Förbereder',
        '128': 'Avbruten',
        '256': 'Larm',
        '512': 'Fatal',
        '-17': 'Patron I/O-läge',
        '-10': 'Underhållsläge',
        '-2': 'Skannar',
        '-1': 'Underhåller',
        UNKNOWN: 'Okänd',
    },
    calibration: {
        update_firmware_msg1: 'Din firmware stöder inte den här funktionen. Uppdatera firmware till v',
        update_firmware_msg2: 'eller senare för att fortsätta。 (Meny > Maskin > [Din maskin] > Uppdatera firmware)',
        camera_calibration: 'Kamerakalibrering',
        diode_calibration: 'Diodlasermodulkalibrering',
        module_calibration_printer: 'Skrivarmodulkalibrering',
        module_calibration_2w_ir: 'Infraröd modulkalibrering',
        back: 'Bakåt',
        next: 'Nästa',
        skip: 'Hoppa Över',
        cancel: 'Avbryt',
        finish: 'Klar',
        do_engraving: 'Utför Gravyr',
        start_engrave: 'Starta Gravyr',
        start_printing: 'Starta Utskrift',
        ask_for_readjust: 'Vill du hoppa över gravyret och sedan ta en bild och kalibrera direkt?',
        please_goto_beambox_first: 'Växla till graveringsläge (Beambox) för att kunna använda den här funktionen.',
        please_place_paper: 'Placera ett A4- eller brevpappersark i vitt papper i det övre vänstra hörnet av arbetsområdet.',
        please_place_paper_center: 'Placera ett A4- eller brevpappersark i vitt papper i mitten av arbetsområdet.',
        please_place_dark_colored_paper: 'Placera ett A4- eller brevpappersark i mörkt färgat papper i mitten av arbetsområdet.',
        please_refocus: {
            beambox: 'Justera plattformen till fokalpunkten (höjden på den vända akrylen).',
            beamo: 'Justera laserhuvudet för att fokusera på graveringsobjektet (höjden på den vända akrylen).',
            beamo_af: 'Dubbelklicka på sidoknappen på autofokustillägget och låt sonden mjukt vidröra materialet.',
            hexa: 'Dubbelklicka på höjdjusteringsknappen för att höja honungskakan uppåt och låta sonden vidröra graveringsmaterialet.',
        },
        without_af: 'Utan autofokus',
        with_af: 'Med autofokus',
        dx: 'X',
        dy: 'Y',
        rotation_angle: 'Rotation',
        x_ratio: 'X-förhållande',
        y_ratio: 'Y-förhållande',
        show_last_config: 'Visa senaste resultat',
        use_last_config: 'Använd senaste kalibreringsvärden',
        taking_picture: 'Tar bild...',
        analyze_result_fail: 'Misslyckades med att analysera den fångade bilden. <br/> Kontrollera: <br/> 1. Bilden täcks helt av vitt papper. <br/> 2. Plattformen är korrekt fokuserad.',
        drawing_calibration_image: 'Ritar kalibreringsbild...',
        calibrate_done: 'Kalibrering klar. Bättre kameranoggrannhet vid korrekt fokus.',
        calibrate_done_diode: 'Kalibrering klar. Förskjutning för diodlasermodul sparad.',
        hint_red_square: 'Justera den röda rutans kant med den skurna rutan.',
        hint_adjust_parameters: 'Använd dessa parametrar för att justera den röda rutan',
        zendesk_link: 'https://support.flux3dp.com/hc/en-us/articles/360001811416',
        please_do_camera_calibration_and_focus: {
            beambox: 'När kalibrering av diodlasermodul utförs behövs kamera.\nSe till att kameran på den här maskinen är kalibrerad.\nOch justera plattformen till fokalpunkten (höjden på den vända akrylen)',
            beamo: 'När kalibrering av diodlasermodul utförs behövs kamera.\nSe till att kameran på den här maskinen är kalibrerad.\nOch justera laserhuvudet för att fokusera på graveringsobjektet (höjden på den vända akrylen)',
        },
        downloading_pictures: 'Laddar ner bilder...',
        failed_to_download_pictures: '#848 Det gick inte att ladda ner bilder, kontakta FLUX-supporten.',
        uploading_images: 'Laddar upp bilder...',
        calculating_camera_matrix: 'Beräknar kameramatris...',
        calculating_regression_parameters: 'Beräknar regressionsparametrar...',
        failed_to_calibrate_camera: '#848 Det gick inte att kalibrera kameran, kontakta FLUX-supporten.',
        failed_to_save_calibration_results: '#849 Det gick inte att spara kalibreringsresultat, försök igen. Om detta fortsätter hända, kontakta FLUX-supporten.',
        ador_autofocus_material: 'Tryck på "AF"-ikonen på maskinens huvudsida i 3 sekunder och låt sonden vidröra materialet mjukt.',
        ador_autofocus_focusing_block: 'Tryck på "AF"-ikonen på maskinens huvudsida i 3 sekunder och låt sonden vidröra fokuseringsblocket.',
        align_red_cross_cut: 'Justera mitten av det röda korset med skärkorset.',
        align_red_cross_print: 'Justera mitten av det röda korset med det utskrivna korset.',
        retake: 'Ta om bild',
        calibrate_camera_before_calibrate_modules: 'Utför kamerakalibrering innan modulkalibrering.',
        check_checkpoint_data: 'Kontrollpunktsdata',
        checking_checkpoint: 'Kontrollerar kontrollpunktsdata...',
        found_checkpoint: 'Kontrollpunktsdata hittades på din enhet. Vill du återställa från kontrollpunkten?',
        use_old_camera_parameter: 'Vill du använda aktuella kamerans objektivparametrar?',
        downloading_checkpoint: 'Laddar ner kontrollpunktsdata...',
        failed_to_parse_checkpoint: 'Misslyckades med att analysera kontrollpunktsdata.',
        check_device_pictures: 'Kontrollera enhetsbilder',
        checking_pictures: 'Kontrollerar enhetsbilder...',
        no_picutre_found: '#846 Din enhet har inga tillgängliga råbilder för kalibrering. Vänligen kontakta FLUX-supporten.',
        calibrating_with_device_pictures: 'Kalibrerar med enhetsbilder...',
        failed_to_calibrate_with_pictures: '#847 Misslyckades med att kalibrera med enhetsbilder.',
        getting_plane_height: 'Hämtar Planhöjd...',
        preparing_to_take_picture: 'Förbereder för att ta bild...',
        put_paper: 'Lägg Papper',
        put_paper_step1: 'Vänligen placera ett vitt A4- eller brevpappersark i mitten av arbetsytan.',
        put_paper_step2: 'Säkra de fyra hörnen på papperet för att säkerställa att det ligger plant.',
        put_paper_step3: 'Klicka på "STARTA GRAVYR".',
        solve_pnp_title: 'Justera markörpunkterna',
        solve_pnp_step1: 'Justera gravyrpunkterna enligt numret och den förväntade positionen för varje röd markörpunkt.',
        solve_pnp_step2: 'Du kan trycka på "Ta om bild" för att justera om eller justera markörpositionerna manuellt.',
        align_olt: 'Position: Justera med den graverade punkten på utsidan uppe till vänster.',
        align_ort: 'Position: Justera med den graverade punkten på utsidan uppe till höger.',
        align_olb: 'Position: Justera med den graverade punkten på utsidan nere till vänster.',
        align_orb: 'Position: Justera med den graverade punkten på utsidan nere till höger.',
        align_ilt: 'Position: Justera med den graverade punkten på insidan uppe till vänster.',
        align_irt: 'Position: Justera med den graverade punkten på insidan uppe till höger.',
        align_ilb: 'Position: Justera med den graverade punkten på insidan nere till vänster.',
        align_irb: 'Position: Justera med den graverade punkten på insidan nere till höger.',
        elevate_and_cut: 'Lyft och Skär',
        elevate_and_cut_step_1: 'Placera en ljusfärgad träbit i A4-storlek i mitten av arbetsytan och lyft den till 20 mm höjd.',
        elevate_and_cut_step_1_prism_lift: 'Använd Ador Prism Lift med sin maximala längd på 14 mm tillsammans med ett trästycke med minst 6 mm tjocklek för att lyfta träet till en höjd av 20 mm.',
        with_prism_lift: 'Med Ador Prism Lift',
        without_prism_lift: 'Utan Ador Prism Lift',
        camera_parameter_saved_successfully: 'Kameraparametrar sparade framgångsrikt.',
        failed_to_save_camera_parameter: 'Misslyckades med att spara kameraparametrarna.',
        failed_to_solve_pnp: 'Kunde inte lösa kamerapositionen.',
        calibrating: 'Kalibrerar...',
        moving_laser_head: 'Flyttar laserhuvudet...',
        failed_to_move_laser_head: 'Misslyckades med att flytta laserhuvudet.',
        put_chessboard: 'Placera Schackbrädet',
        put_chessboard_desc: 'Vänligen placera schackbrädet i mitten av arbetsområdet.',
        put_chessboard_1: 'Gå till maskinens kontrollpanel och tryck på AF för att utföra autofokus.',
        put_chessboard_2: 'Flytta schackbrädepappret eller laserhuvudet tills hela schackbrädet är bekräftat att vara inom den röda ramen i fönstret för liveförhandsgranskning.',
        put_chessboard_3: 'Högerklicka för att ladda ner schackbrädesbilden på den aktuella platsen och kontrollera dess skärpa.',
        failed_to_calibrate_chessboard: 'Misslyckades med att kalibrera med schackbrädsbilden.',
        calibrate_chessboard_success_msg: 'Schackbrädesfotot har fångats framgångsrikt.<br/>Poängen för detta foto är %s (%.2f).',
        res_excellent: 'Utmärkt',
        res_average: 'Genomsnittlig',
        res_poor: 'Dålig',
        perform_autofocus_bb2: 'Gå till maskinens kontrollpanel och tryck på AF för att utföra autofokus.',
    },
    input_machine_password: {
        require_password: '"%s" kräver ett lösenord',
        connect: 'ANSLUT',
        password: 'Lösenord',
    },
    tutorial: {
        skip: 'Hoppa över',
        welcome: 'VÄLKOMMEN',
        suggest_calibrate_camera_first: 'Vi rekommenderar användare att kalibrera kameran initialt och fokusera om innan varje förhandsvisning för optimala resultat.<br/>Vill du bekräfta för att genomföra kalibreringen nu?<br/>(Du kan hoppa över det nu och göra det senare genom att klicka på "Meny" > "Maskin" > [Din Maskin] > "Kalibrera Kamera".)',
        camera_calibration_failed: 'Kamerakalibrering misslyckades',
        ask_retry_calibration: 'Skulle du vilja kalibrera kameran igen?',
        needNewUserTutorial: 'Vill du starta en handledning?<br/>(Du kan hoppa över den nu och starta senare genom att klicka på "Hjälp" > "Visa Starthandledning".)',
        needNewInterfaceTutorial: 'Vill du starta en handledning för Beam Studios nya gränssnitt?<br/>(Du kan hoppa över den nu och starta senare genom att klicka på "Hjälp" > "Visa Gränssnittsintroduktion".)',
        next: 'NÄSTA',
        look_for_machine: 'Söker maskin för handledning...',
        unable_to_find_machine: 'Det gick inte att hitta maskinen för handledningen. Vill du gå till anslutningsinställningssidan, försöka igen eller hoppa över handledningen?',
        skip_tutorial: 'Du har hoppat över handledningen. Du kan alltid starta handledningen genom att klicka på "Hjälp" > "Visa starthandledning"',
        set_connection: 'Ställ in anslutning',
        retry: 'Försök igen',
        newUser: {
            draw_a_circle: 'Rita en cirkel',
            drag_to_draw: 'Dra för att rita',
            infill: 'Aktivera utfyllnad',
            switch_to_object_panel: 'Byt till objektpanel',
            switch_to_layer_panel: 'Byt till lagerpanelen',
            set_preset_wood_engraving: 'Ställ in förval: Trä - gravering',
            set_preset_wood_cut: 'Ställ in förval: Trä - skärning',
            add_new_layer: 'Lägg till ett nytt lager',
            draw_a_rect: 'Rita en rektangel',
            switch_to_preview_mode: 'Byt till förhandsgranskningsläge',
            preview_the_platform: 'Förhandsgranska plattformen',
            end_preview_mode: 'Avsluta förhandsgranskningsläge',
            put_wood: '1. Lägg träprovet',
            adjust_focus: '2. Justera fokus',
            close_cover: '3. Stäng locket',
            send_the_file: 'Skicka filen',
            end_alert: 'Är du säker på att du vill avsluta handledningen?',
            please_select_wood_engraving: 'Vänligen välj förinställning "Trä - gravering".',
            please_select_wood_cutting: 'Vänligen välj förinställning "Trä - skärning".',
        },
        newInterface: {
            camera_preview: 'Kameraförhandsgranskning',
            select_image_text: 'Välj / Bild / Text',
            basic_shapes: 'Grundläggande former',
            pen_tool: 'Pennverktyg',
            add_new_layer: 'Lägg till nytt lager',
            rename_by_double_click: 'Byt namn genom dubbelklick',
            drag_to_sort: 'Dra för att sortera',
            layer_controls: 'Högerklicka för att välja lagerkontroller:\nDuplicera / Slå samman / Lås / Ta bort lager',
            switch_between_layer_panel_and_object_panel: 'Växla mellan lagerpanel och objektpanel',
            align_controls: 'Justeringskontroller',
            group_controls: 'Gruppkontroller',
            shape_operation: 'Formåtgärd',
            flip: 'Vänd',
            object_actions: 'Objektåtgärder',
            end_alert: 'Är du säker på att du vill avsluta introduktionen till det nya användargränssnittet?',
            select_machine: 'Välj maskin',
            start_work: 'Börja arbeta',
        },
        gesture: {
            pan: 'Panorera på arbetsytan med två fingrar.',
            zoom: 'Nypa med två fingrar för att zooma in/ut på arbetsytan.',
            click: 'Tryck för att välja objektet.',
            drag: 'Dra för att markera flera objekt.',
            hold: 'Tryck och håll för att öppna snabbmenyn.',
        },
        links: {
            adjust_focus: 'https://flux3dp.zendesk.com/hc/en-us/articles/360001684196',
        },
        tutorial_complete: 'Det var allt för handledningen. Dags att börja skapa!',
    },
    layer_module: {
        none: 'None',
        general_laser: 'Laser',
        laser_10w_diode: '10W diodlaser',
        laser_20w_diode: '20W diodlaser',
        laser_2w_infrared: '2W infraröd laser',
        printing: 'Utskrift',
        unknown: 'Unknown Module',
        notification: {
            convertFromPrintingModuleTitle: 'Vill du konvertera utskriftsmodulen till lasermodul?',
            convertFromPrintingModuleMsg: 'Observera att om du slutför den här åtgärden kommer färginställningarna för utskriftslagret att tas bort och ställas in enligt aktuellt lager.',
            convertFromLaserModuleTitle: 'Vill du konvertera lasermodulen till utskriftsmodul?',
            convertFromLaserModuleMsg: 'Observera att om du slutför den här åtgärden kommer inställningarna för laserskiktet att tas bort och ställas in enligt aktuellt skikt.',
            importedDocumentContainsPrinting: 'Dokumentet innehåller utskriftslager, vill du ändra arbetsområde till Ador?',
            printingLayersCoverted: 'Skrivarlagren har konverterats till laserskikt.',
            performPrintingCaliTitle: 'Utför utskriftsmodulkalibreringen',
            performPrintingCaliMsg: 'Klicka på "Bekräfta" för att utföra kalibreringen eller gå till kalibrering via huvudmenyn.<br />(Maskin > [Ditt maskinnamn] > Kalibrera utskriftsmodul)',
            performIRCaliTitle: 'Utför infraröd modulkalibrering',
            performIRCaliMsg: 'Klicka på "Bekräfta" för att utföra kalibreringen eller gå till kalibrering via huvudmenyn.<br />(Maskin > [Ditt maskinnamn] > Kalibrera infraröd modul)',
        },
        non_working_area: 'Icke-Arbetsområde',
    },
    qr_code_generator: {
        title: 'QR-kodsgenerator',
        placeholder: 'Ange en länk eller text',
        preview: 'Förhandsgranska',
        error_tolerance: 'Felmarginal',
        error_tolerance_link: 'https://support.flux3dp.com/hc/en-us/articles/9113705072143',
        invert: 'Invertera bakgrundsfärg',
    },
    boxgen: {
        title: 'BOXGEN',
        basic_box: 'Grundläggande låda',
        coming_soon: 'Kommer snart',
        workarea: 'Arbetsområde',
        max_dimension_tooltip: 'Max bredd/höjd/djup inställning är %s.',
        volume: 'Volym',
        outer: 'Yttre',
        inner: 'Inre',
        width: 'Bredd',
        height: 'Höjd',
        depth: 'Djup',
        cover: 'Lock',
        thickness: 'Tjocklek',
        add_option: 'Lägg till alternativ',
        joints: 'Fog',
        finger: 'Finger',
        finger_warning: 'Lådans inre sidlängd måste vara minst 6 mm (0,24 tum) för att vara kompatibel med fingerskarvning.',
        tSlot: 'T-spår',
        tSlot_warning: 'Lådans sidlängd måste vara minst 30 mm (1,18 tum) för att vara kompatibel med T-spårskarvning.',
        edge: 'Kant',
        tCount: 'T Antal',
        tCount_tooltip: 'Antalet T-spår gäller den korta sidan; kvantiteten på den långa sidan beräknas baserat på dess längd.',
        tDiameter: 'T Diameter',
        tLength: 'T Längd',
        continue_import: 'Fortsätt importera',
        customize: 'Anpassa',
        merge: 'Sammanfoga',
        text_label: 'Etikett',
        beam_radius: 'Kompensation för skärstråle',
        beam_radius_warning: 'Ta bort kompensation för sågkärv när kanterna eller fogarna på lådan är korta för att säkerställa montering',
        import: 'Importera',
        cancel: 'Avbryt',
        reset: 'Återställ',
        zoom: 'Zooma',
        control_tooltip: 'Vänster mus för att rotera\nScrolla för att zooma\nHöger mus för att panorera',
        control_tooltip_touch: 'Dra för att rotera\nNypa för att zooma\nTvå fingrar för att panorera',
    },
    my_cloud: {
        title: 'Mitt moln',
        loading_file: 'Laddar...',
        no_file_title: 'Spara filer till Mitt moln för att komma igång.',
        no_file_subtitle: 'Gå till Meny > "Arkiv" > "Spara till molnet"',
        file_limit: 'Gratis fil',
        upgrade: 'Uppgradera',
        sort: {
            most_recent: 'Senast',
            oldest: 'Äldst',
            a_to_z: 'Namn: A - Ö',
            z_to_a: 'Namn: Ö - A',
        },
        action: {
            open: 'Öppna',
            rename: 'Byt namn',
            duplicate: 'Duplicera',
            download: 'Ladda ner',
            delete: 'Ta bort',
            confirmFileDelete: 'Är du säker på att du vill ta bort den här filen? Den här åtgärden kan inte ångras.',
        },
        save_file: {
            choose_action: 'Spara fil:',
            save: 'Spara',
            save_new: 'Spara som ny fil',
            input_file_name: 'Ange filnamn:',
            invalid_char: 'Ogiltiga tecken:',
            storage_limit_exceeded: 'Din molnlagring har nått gränsen. Ta bort eventuella onödiga filer innan du sparar nya.',
        },
    },
    camera_data_backup: {
        title: 'Kamera Data Backup',
        no_picture_found: 'Inget bild hittades i maskinen.',
        folder_not_exists: 'Den valda mappen finns inte.',
        incorrect_folder: 'Misslyckades att ladda upp kalibreringsdata. Kontrollera om den valda mappen är korrekt.',
        downloading_data: 'Laddar ner data...',
        estimated_time_left: 'Beräknad återstående tid:',
        uploading_data: 'Laddar upp data...',
        download_success: 'Kameradata har laddats ner framgångsrikt.',
        upload_success: 'Kameradata har laddats upp framgångsrikt.',
    },
    insecure_websocket: {
        extension_detected: 'Beam Studio Connect-tillägg upptäckt',
        extension_detected_description: "Vi har upptäckt att du har installerat Beam Studio Connect-tillägget. Klicka på 'Bekräfta' för att omdirigera till HTTPS, eller klicka på 'Avbryt' för att fortsätta använda HTTP.",
        extension_not_deteced: 'Kunde inte upptäcka Beam Studio Connect-tillägg',
        extension_not_deteced_description: "För att använda HTTPS, klicka på 'Bekräfta' för att installera Beam Studio Connect-tillägget. Efter att ha installerat tillägget, uppdatera sidan för att aktivera det.<br/>Annars klickar du på länken nedan för att se hur du använder HTTP i Chrome.",
        unsecure_url_help_center_link: '<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/9935859456271">Länk</a>',
    },
    curve_engraving: {
        measure_audofocus_area: 'Mät autofokusområde',
        amount: 'Mängd',
        gap: 'Avstånd',
        rows: 'Rader',
        coloumns: 'Kolumner',
        row_gap: 'Radavstånd',
        column_gap: 'Kolumnavstånd',
        set_object_height: 'Ställ in objektets höjd',
        set_object_height_desc: 'Mät objektets maximala tjocklek.',
        measure_area_help: 'Hur väljer man autofokusområdet för 3D-kurva?',
        reselect_area: 'Välj område igen',
        start_autofocus: 'Starta autofokus',
        starting_measurement: 'Startar mätning...',
        preview_3d_curve: 'Förhandsgranska 3D-kurva',
        apply_arkwork: 'Applicera konstverk på 3D-kurva',
        apply_camera: 'Applicera kamerabild på 3D-kurva',
        click_to_select_point: 'Klicka för att välja eller avmarkera punkter för att mäta igen.',
        remeasure: 'Mät om',
        remeasuring_points: 'Mäter om punkter...',
    },
    pass_through: {
        title: 'Passera Igenom-Läge',
        help_text: 'Hur ställer man in Passera Igenom-Läge för Ador?',
        help_link: 'https://support.flux3dp.com/hc/en-us/articles/10140002160399',
        object_length: 'Objektlängd',
        workarea_height: 'Arbetsområde (Höjd):',
        height_desc: 'Ställ in höjden på varje arbetsområdessektion.',
        ref_layer: 'Referenslager',
        ref_layer_desc: 'Observera att utförandet av referenslagret är inställt på 0 som standard. Det kommer inte att utföras utan är endast för justeringsreferens.',
        ref_layer_name: 'Referens',
        guide_mark: 'Guidemärke',
        guide_mark_length: 'Diameter:',
        guide_mark_x: 'X-koordinat:',
        guide_mark_desc: 'Guidemärken kommer att graveras som en referenspunkt för att justera konstverket.',
        export: 'Exportera till Arbetsområde',
        exporting: 'Exporterar...',
    },
    auto_fit: {
        title: 'Auto Fit',
        step1: '1. Placer objektet i önskad position på en bit material.',
        step2: '2. Tryck på "Bekräfta" för att duplicera objektet på alla liknande materialbitar i kameraförhandsgranskningen.',
        preview_first: 'Utför kameraförhandsgranskningen först.',
        failed_to_auto_fit: 'Automatisk passning misslyckades, kontrollera:',
        error_tip1: '1. Är konstverket placerat korrekt på materialet?',
        error_tip2: '2. Är materialkonturerna tillräckligt klara för igenkänning?',
        learn_more: 'Lär dig hur auto fit fungerar.',
        learn_more_url: 'https://support.flux3dp.com/hc/en-us/articles/10273384373775',
    },
    rotary_settings: {
        type: 'Typ',
        object_diameter: 'Objekt Diameter',
        circumference: 'Omkrets',
    },
    framing: {
        low_laser: 'Låg laser',
        low_laser_desc: 'Ställ in ett lågeffektslaservärde för inramningsuppgiften.',
        framing: 'Inramning',
        hull: 'Skrov',
        area_check: 'Områdeskontroll',
        framing_desc: 'Förhandsgranskar objektets avgränsningsruta.',
        hull_desc: 'Förhandsvisar en form som följer designen noggrant, som ett gummiband lindat runt objektet.',
        areacheck_desc: 'Säkerställer att arbetsområdet är säkert genom att förhandsgranska objektets begränsningsram och laserns accelerationszon.',
        calculating_task: 'Beräknar uppgift...',
    },
    material_test_generator: {
        title: 'Materialtestgenerator',
        table_settings: 'Tabellinställningar',
        block_settings: 'Blockinställningar',
        text_settings: 'Textinställningar',
        preview: 'Förhandsgranska',
        export: 'Exportera',
        cut: 'Skär',
        engrave: 'Gravera',
        columns: 'Kolumner',
        rows: 'Rader',
        parameter: 'Parameter',
        min: 'Min',
        max: 'Max',
        count: 'Antal',
        size: 'Storlek (HxB)',
        spacing: 'Mellanrum',
    },
    web_cam: {
        no_permission: 'Beam Studio har inte behörighet att komma åt kameran. Se till att behörigheten är beviljad till Beam Studio i webbläsarinställningarna eller systeminställningarna.',
        no_device: 'Kan inte hitta kameranheten. Anslut kameran igen och försök igen.',
    },
};
export default lang;
