import AdjustHeight from './adjust-height.svg';
import Back from './back.svg';
import Boxgen from './boxgen.svg';
import Cloud from './cloud.svg';
import Cursor from './cursor.svg';
import Curve3D from './curve-3d.svg';
import CurveEngrave from './curve-engrave.svg';
import CuverPreview from './curve-preview.svg';
import CurveSelect from './curve-select.svg';
import Delete from './delete.svg';
import DM from './dm.svg';
import Draw from './draw.svg';
import Element from './element.svg';
import Line from './line.svg';
import Live from './live.svg';
import Oval from './oval.svg';
import PassThrough from './pass-through.svg';
import Photo from './photo.svg';
import Polygon from './polygon.svg';
import QRCode from './qr-code.svg';
import Rect from './rect.svg';
import Shoot from './shoot.svg';
import Text from './text.svg';
import Trace from './trace.svg';
export default {
    AdjustHeight,
    Back,
    Boxgen,
    Cloud,
    Cursor,
    Curve3D,
    CurveEngrave,
    CuverPreview,
    CurveSelect,
    Delete,
    DM,
    Draw,
    Element,
    Line,
    Live,
    Oval,
    PassThrough,
    Photo,
    Polygon,
    QRCode,
    Rect,
    Shoot,
    Text,
    Trace,
};
