// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-components-dialogs-camera-AdorCalibrationV2-FindCorner-module__footer-button--QM5NT{text-transform:capitalize}.src-web-app-components-dialogs-camera-AdorCalibrationV2-FindCorner-module__img-container--EELc3{display:flex;min-height:360px;align-items:center;justify-content:center}.src-web-app-components-dialogs-camera-AdorCalibrationV2-FindCorner-module__img-container--EELc3 img{object-fit:contain;height:360px}", "",{"version":3,"sources":["webpack://./src/web/app/components/dialogs/camera/AdorCalibrationV2/FindCorner.module.scss"],"names":[],"mappings":"AAAA,iGACE,yBAAA,CAGF,iGACE,YAAA,CACA,gBAAA,CACA,kBAAA,CACA,sBAAA,CAEA,qGACE,kBAAA,CACA,YAAA","sourcesContent":[".footer-button {\n  text-transform: capitalize;\n}\n\n.img-container {\n  display: flex;\n  min-height: 360px;\n  align-items: center;\n  justify-content: center;\n\n  img {\n    object-fit: contain;\n    height: 360px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer-button": "src-web-app-components-dialogs-camera-AdorCalibrationV2-FindCorner-module__footer-button--QM5NT",
	"img-container": "src-web-app-components-dialogs-camera-AdorCalibrationV2-FindCorner-module__img-container--EELc3"
};
export default ___CSS_LOADER_EXPORT___;
