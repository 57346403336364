import setElementsColor from 'helpers/color/setElementsColor';
import { getObjectLayer } from 'helpers/layer/layer-helper';
import { getSVGAsync } from 'helpers/svg-editor-helper';
let svgCanvas;
getSVGAsync((globalSVG) => {
    svgCanvas = globalSVG.Canvas;
});
// TODO: add test
const updateElementColor = (elem) => {
    var _a;
    const layer = (_a = getObjectLayer(elem)) === null || _a === void 0 ? void 0 : _a.elem;
    const isFullColor = (layer === null || layer === void 0 ? void 0 : layer.getAttribute('data-fullcolor')) === '1';
    const color = svgCanvas.isUsingLayerColor ? layer === null || layer === void 0 ? void 0 : layer.getAttribute('data-color') : '#000';
    setElementsColor([elem], color, isFullColor);
};
export default updateElementColor;
