import Rotate from './rotate.svg';
import Unlocked from './unlocked.svg';
import Locked from './locked.svg';
import HFlip from './hflip.svg';
import VFlip from './vflip.svg';
export default {
    Rotate,
    HFlip,
    VFlip,
    Unlocked,
    Locked,
};
