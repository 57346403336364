// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-components-boxgen-Boxgen-module__canvas--KyWyc{position:relative;border-left:1px solid #ddd;width:calc(100vw - 500px)}@media screen and (max-width: 600px){.src-web-app-components-boxgen-Boxgen-module__canvas--KyWyc{border-bottom:1px solid #ddd;border-left:0;width:100vw;height:250px}}", "",{"version":3,"sources":["webpack://./src/web/app/components/boxgen/Boxgen.module.scss"],"names":[],"mappings":"AAAA,4DACE,iBAAA,CACA,0BAAA,CACA,yBAAA,CACA,qCAJF,4DAKI,4BAAA,CACA,aAAA,CACA,WAAA,CACA,YAAA,CAAA","sourcesContent":[".canvas {\n  position: relative;\n  border-left: 1px solid #ddd;\n  width: calc(100vw - 500px);\n  @media screen and (max-width: 600px) {\n    border-bottom: 1px solid #ddd;\n    border-left: 0;\n    width: 100vw;\n    height: 250px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"canvas": "src-web-app-components-boxgen-Boxgen-module__canvas--KyWyc"
};
export default ___CSS_LOADER_EXPORT___;
