import beamboxPreference from 'app/actions/beambox/beambox-preference';
import constant from 'app/actions/beambox/constant';
import workareaManager from 'app/svgedit/workarea';
import { getAllLayers } from 'helpers/layer/layer-helper';
import { getData } from 'helpers/layer/layer-config-helper';
export const getRefModule = () => {
    const firstLayer = getAllLayers()
        .reverse()
        .find((layer) => layer.getAttribute('display') !== 'none');
    return getData(firstLayer, 'module');
};
const getJobOrigin = (px = false) => {
    var _a;
    const { width: workareaWidth, height: fullHeight, expansion } = workareaManager;
    const workareaHeight = fullHeight - expansion[0] - expansion[1];
    const svgcontent = document.getElementById('svgcontent');
    const boundary = svgcontent.getBBox();
    const left = Math.max(boundary.x, 0);
    const top = Math.max(boundary.y, 0);
    const right = Math.min(boundary.x + boundary.width, workareaWidth);
    const bottom = Math.min(boundary.y + boundary.height, workareaHeight);
    const jobOrigin = (_a = beamboxPreference.read('job-origin')) !== null && _a !== void 0 ? _a : 1;
    const xRef = (jobOrigin - 1) % 3;
    const yRef = Math.floor((jobOrigin - 1) / 3);
    const res = { x: 0, y: 0 };
    if (xRef === 0)
        res.x = left;
    else if (xRef === 1)
        res.x = (left + right) / 2;
    else
        res.x = right;
    if (yRef === 0)
        res.y = top;
    else if (yRef === 1)
        res.y = (top + bottom) / 2;
    else
        res.y = bottom;
    if (!px) {
        res.x /= constant.dpmm;
        res.y /= constant.dpmm;
    }
    return res;
};
export default getJobOrigin;
