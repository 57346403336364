/**
 * new Alert Modal using antd Modal
 */
import classNames from 'classnames';
import React, { useContext, useState } from 'react';
import { Button, Checkbox, Modal } from 'antd';
import AlertIcons from 'app/icons/alerts/AlertIcons';
import browser from 'implementations/browser';
import i18n from 'helpers/i18n';
import useI18n from 'helpers/useI18n';
import { AlertProgressContext } from 'app/contexts/AlertProgressContext';
import { HELP_CENTER_URLS } from 'app/constants/alert-constants';
import styles from './Alert.module.scss';
const renderIcon = (url) => {
    if (!url)
        return null;
    return React.createElement("img", { className: styles.icon, src: url });
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const messageIconMap = {
    notice: AlertIcons.Notice,
};
const renderMessage = (message, messageIcon = '') => {
    if (!message)
        return null;
    let content = null;
    const IconComponent = messageIconMap[messageIcon];
    if (typeof message === 'string') {
        content = (React.createElement("div", { className: classNames(styles.message, { [styles['with-icon']]: !!IconComponent }), 
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML: { __html: message } }));
    }
    else {
        content = (React.createElement("div", { className: classNames(styles.message, { [styles['with-icon']]: !!IconComponent }) }, message));
    }
    return (React.createElement("div", { className: styles['message-container'] },
        IconComponent && React.createElement(IconComponent, { className: styles.icon }),
        content));
};
const Alert = ({ data }) => {
    const lang = useI18n().alert;
    const { popFromStack } = useContext(AlertProgressContext);
    const { caption, checkbox, message, messageIcon, buttons, iconUrl, links } = data;
    const [checkboxChecked, setCheckboxChecked] = useState(false);
    const renderCheckbox = () => {
        if (!checkbox)
            return null;
        const { text } = checkbox;
        return (React.createElement("div", { className: styles.checkbox },
            React.createElement(Checkbox, { onClick: () => setCheckboxChecked(!checkboxChecked) }, text)));
    };
    const renderLink = () => {
        if (links) {
            return (React.createElement("div", { className: styles.links }, links.map((link) => (React.createElement(Button, { key: link.url, className: styles.link, type: "link", onClick: () => browser.open(link.url) },
                link.text,
                React.createElement(AlertIcons.ExtLink, { className: styles.icon }))))));
        }
        if (typeof message !== 'string')
            return null;
        const errorCode = message.match('^#[0-9]*');
        if (!errorCode)
            return null;
        const link = HELP_CENTER_URLS[errorCode[0].replace('#', '')];
        if (!link)
            return null;
        const isZHTW = i18n.getActiveLang() === 'zh-tw';
        return (React.createElement("div", { className: styles.links },
            React.createElement(Button, { className: styles.link, type: "link", onClick: () => browser.open(isZHTW ? link.replace('en-us', 'zh-tw') : link) }, lang.learn_more)));
    };
    const footer = buttons === null || buttons === void 0 ? void 0 : buttons.map((button, idx) => {
        var _a;
        const buttonType = ((_a = button.className) === null || _a === void 0 ? void 0 : _a.includes('primary')) ? 'primary' : 'default';
        return (React.createElement(Button, { key: button.label, onClick: () => {
                var _a, _b, _c;
                popFromStack();
                if (checkbox && checkboxChecked) {
                    const { callbacks } = checkbox;
                    if (callbacks.length > idx)
                        (_a = callbacks[idx]) === null || _a === void 0 ? void 0 : _a.call(callbacks);
                    else if (typeof callbacks === 'function')
                        callbacks === null || callbacks === void 0 ? void 0 : callbacks();
                    else
                        (_b = button.onClick) === null || _b === void 0 ? void 0 : _b.call(button);
                }
                else
                    (_c = button.onClick) === null || _c === void 0 ? void 0 : _c.call(button);
            }, type: buttonType }, button.label));
    });
    return (React.createElement(Modal, { open: true, title: caption, footer: footer, closable: false, maskClosable: false, centered: true, onCancel: popFromStack, className: styles.container },
        renderIcon(iconUrl),
        renderMessage(message, messageIcon),
        renderLink(),
        renderCheckbox()));
};
export default Alert;
