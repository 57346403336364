// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-components-dialogs-MaterialTestGeneratorPanel-index-module__modal-wrap--Yjcaj{overflow:unset}.src-web-app-components-dialogs-MaterialTestGeneratorPanel-index-module__params--wOJSG{padding-right:30px}.src-web-app-components-dialogs-MaterialTestGeneratorPanel-index-module__header--K3IG0{height:32px;margin:12px 0;display:flex;align-items:center;justify-content:space-between}.src-web-app-components-dialogs-MaterialTestGeneratorPanel-index-module__header--K3IG0 .src-web-app-components-dialogs-MaterialTestGeneratorPanel-index-module__title--6ZJLt{font-weight:700}.src-web-app-components-dialogs-MaterialTestGeneratorPanel-index-module__footer--3V\\+6c{display:flex;justify-content:space-between;margin-top:20px}.src-web-app-components-dialogs-MaterialTestGeneratorPanel-index-module__preview-subtext--LSW8O{font-size:12px;color:#7c7c7c}.src-web-app-components-dialogs-MaterialTestGeneratorPanel-index-module__mb-28--vTz0S{margin-bottom:28px}", "",{"version":3,"sources":["webpack://./src/web/app/components/dialogs/MaterialTestGeneratorPanel/index.module.scss"],"names":[],"mappings":"AAEA,2FACE,cAAA,CAGF,uFACE,kBAAA,CAGF,uFACE,WAAA,CACA,aAAA,CACA,YAAA,CACA,kBAAA,CACA,6BAAA,CAEA,6KACE,eAAA,CAIJ,wFACE,YAAA,CACA,6BAAA,CACA,eAAA,CAGF,gGACE,cAAA,CACA,aAAA,CAGF,sFACE,kBAAA","sourcesContent":["@use 'styles/variables' as variables;\n\n.modal-wrap {\n  overflow: unset;\n}\n\n.params {\n  padding-right: 30px;\n}\n\n.header {\n  height: 32px;\n  margin: 12px 0;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n\n  .title {\n    font-weight: 700;\n  }\n}\n\n.footer {\n  display: flex;\n  justify-content: space-between;\n  margin-top: 20px;\n}\n\n.preview-subtext {\n  font-size: 12px;\n  color: #7c7c7c;\n}\n\n.mb-28 {\n  margin-bottom: 28px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal-wrap": "src-web-app-components-dialogs-MaterialTestGeneratorPanel-index-module__modal-wrap--Yjcaj",
	"params": "src-web-app-components-dialogs-MaterialTestGeneratorPanel-index-module__params--wOJSG",
	"header": "src-web-app-components-dialogs-MaterialTestGeneratorPanel-index-module__header--K3IG0",
	"title": "src-web-app-components-dialogs-MaterialTestGeneratorPanel-index-module__title--6ZJLt",
	"footer": "src-web-app-components-dialogs-MaterialTestGeneratorPanel-index-module__footer--3V+6c",
	"preview-subtext": "src-web-app-components-dialogs-MaterialTestGeneratorPanel-index-module__preview-subtext--LSW8O",
	"mb-28": "src-web-app-components-dialogs-MaterialTestGeneratorPanel-index-module__mb-28--vTz0S"
};
export default ___CSS_LOADER_EXPORT___;
