// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-components-dialogs-AnnouncementPanel-module__announcement--AJcLx .ant-modal-header{margin-bottom:12px}.src-web-app-components-dialogs-AnnouncementPanel-module__announcement--AJcLx .src-web-app-components-dialogs-AnnouncementPanel-module__checkbox--Wzz5-{margin:20px 0}", "",{"version":3,"sources":["webpack://./src/web/app/components/dialogs/AnnouncementPanel.module.scss"],"names":[],"mappings":"AACE,gGACE,kBAAA,CAGF,wJACE,aAAA","sourcesContent":[".announcement {\n  :global(.ant-modal-header) {\n    margin-bottom: 12px;\n  }\n\n  .checkbox {\n    margin: 20px 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"announcement": "src-web-app-components-dialogs-AnnouncementPanel-module__announcement--AJcLx",
	"checkbox": "src-web-app-components-dialogs-AnnouncementPanel-module__checkbox--Wzz5-"
};
export default ___CSS_LOADER_EXPORT___;
