// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media screen and (max-width: 600px){:not(.white) .src-web-app-components-beambox-top-bar-ElementTitle-module__element-title--DlPlf{margin-top:20px}.white .src-web-app-components-beambox-top-bar-ElementTitle-module__element-title--DlPlf{margin-left:40px}}", "",{"version":3,"sources":["webpack://./src/web/app/components/beambox/top-bar/ElementTitle.module.scss"],"names":[],"mappings":"AAAA,qCACE,+FACE,eAAA,CAGF,yFACE,gBAAA,CAAA","sourcesContent":["@media screen and (max-width: 600px) {\n  :not(:global(.white)) .element-title {\n    margin-top: 20px;\n  }\n\n  :global(.white) .element-title {\n    margin-left: 40px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"element-title": "src-web-app-components-beambox-top-bar-ElementTitle-module__element-title--DlPlf"
};
export default ___CSS_LOADER_EXPORT___;
