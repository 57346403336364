var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import alertCaller from 'app/actions/alert-caller';
import { axiosFluxId, FLUXID_HOST } from 'helpers/api/flux-id';
import { showFluxPlusWarning } from 'app/actions/dialog-controller';
const getUrlWithToken = (postscriptName) => __awaiter(void 0, void 0, void 0, function* () {
    const { data } = yield axiosFluxId.get('oauth/?response_type=token&scope=4', {
        withCredentials: true,
    });
    const { token } = data;
    if (!token)
        return null;
    return `${FLUXID_HOST}/api/beam-studio/monotype/${postscriptName}/download/${token}`;
});
const applyStyle = (font, user, silent) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c, _d, _e;
    if ('hasLoaded' in font && !font.hasLoaded) {
        if (!((_b = (_a = user === null || user === void 0 ? void 0 : user.info) === null || _a === void 0 ? void 0 : _a.subscription) === null || _b === void 0 ? void 0 : _b.is_valid) || !((_e = (_d = (_c = user === null || user === void 0 ? void 0 : user.info) === null || _c === void 0 ? void 0 : _c.subscription) === null || _d === void 0 ? void 0 : _d.option) === null || _e === void 0 ? void 0 : _e.monotype)) {
            if (!silent)
                showFluxPlusWarning(true);
            return { success: false };
        }
        const url = yield getUrlWithToken(font.postscriptName);
        if (!url) {
            if (!silent)
                showFluxPlusWarning(true);
            return { success: false };
        }
        const myFont = new FontFace(font.family, `url(${url})`, {
            style: font.italic ? 'italic' : 'normal',
            weight: font.weight.toString(),
            display: 'swap',
        });
        return {
            success: true,
            fontLoadedPromise: myFont
                .load()
                .then((newFontFace) => {
                document.fonts.add(newFontFace);
                // eslint-disable-next-line no-param-reassign
                font.hasLoaded = true;
            })
                .catch(() => {
                alertCaller.popUpError({
                    message: `tUnable to get font ${font.postscriptName}`,
                });
            })
                .finally(() => new Promise((r) => r())),
        };
    }
    return { success: true };
});
const getAvailableFonts = (lang) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const { data } = yield axiosFluxId.get(`api/beam-studio/monotype/fonts${lang ? `?lang=${lang}` : ''}`);
        const { fonts = [] } = data;
        const monotypePreviewSrcMap = {};
        const monotypeLangFonts = fonts.map((font) => {
            if (font.preview_img && !monotypePreviewSrcMap[font.family]) {
                monotypePreviewSrcMap[font.family] = font.preview_img;
            }
            return Object.assign(Object.assign({}, font), { postscriptName: font.postscript_name, hasLoaded: false });
        });
        return { monotypeLangFonts, monotypePreviewSrcMap };
    }
    catch (_f) {
        console.error('Failed to get monotype font list');
        return null;
    }
});
export default {
    getAvailableFonts,
    applyStyle,
    getUrlWithToken,
};
