var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
import alert from 'app/actions/alert-caller';
import browser from 'implementations/browser';
import communicator from 'implementations/communicator';
import cookies from 'implementations/cookies';
import eventEmitterFactory from 'helpers/eventEmitterFactory';
import i18n from 'helpers/i18n';
import isWeb from 'helpers/is-web';
import parseQueryData from 'helpers/query-data-parser';
import progress from 'app/actions/progress-caller';
import storage from 'implementations/storage';
const OAUTH_REDIRECT_URI = 'https://id.flux3dp.com/api/beam-studio/auth';
const FB_OAUTH_URI = 'https://www.facebook.com/v10.0/dialog/oauth';
const FB_APP_ID = '1071530792957137';
const webState = encodeURIComponent(JSON.stringify({ origin: window.location.origin }));
const FB_REDIRECT_URI = `${OAUTH_REDIRECT_URI}${isWeb() ? `?isWeb=true&state=${webState}` : ''}`;
const G_OAUTH_URL = 'https://accounts.google.com/o/oauth2/v2/auth';
const G_CLIENT_ID = '1071432315622-ekdkc89hdt70sevt6iv9ia4659lg70vi.apps.googleusercontent.com';
export const G_REDIRECT_URI = `${OAUTH_REDIRECT_URI}${isWeb() ? `?isWeb=true` : ''}`;
const OAUTH_TOKEN = new Set();
export const FLUXID_HOST = 'https://id.flux3dp.com';
const FLUXID_DOMAIN = '.flux3dp.com';
export const axiosFluxId = axios.create({
    baseURL: FLUXID_HOST,
    timeout: 10000,
});
let currentUser = null;
axiosFluxId.interceptors.response.use((response) => response, (error) => ({ error }));
export const fluxIDEvents = eventEmitterFactory.createEventEmitter('flux-id');
const handleErrorMessage = (error) => {
    if (!error) {
        return;
    }
    const { response } = error;
    if (!response) {
        alert.popUpError({ message: i18n.lang.flux_id_login.connection_fail });
    }
    else {
        const message = `${response.status} ${response.statusText}`;
        alert.popUpError({ message });
    }
};
const updateMenu = (info) => {
    communicator.send('UPDATE_ACCOUNT', info);
};
const updateUser = (info, isWebSocialSignIn = false) => {
    if (info) {
        if (!currentUser) {
            updateMenu(info);
            currentUser = {
                email: info.email,
                info,
            };
            if (isWebSocialSignIn && isWeb()) {
                window.opener.dispatchEvent(new CustomEvent('update-user', {
                    detail: {
                        user: currentUser,
                    },
                }));
            }
        }
        else {
            if (currentUser.email !== info.email) {
                updateMenu(info);
            }
            Object.assign(currentUser.info, info);
        }
        fluxIDEvents.emit('update-user', currentUser);
    }
    else {
        if (currentUser) {
            currentUser = null;
            updateMenu();
        }
        fluxIDEvents.emit('update-user', null);
    }
};
window.addEventListener('update-user', (e) => {
    currentUser = e.detail.user;
});
export const getCurrentUser = () => currentUser;
const handleOAuthLoginSuccess = (data) => {
    updateUser(data, true);
    fluxIDEvents.emit('oauth-logged-in');
    storage.set('keep-flux-id-login', true);
    if (window.location.hash === '#/initialize/connect/flux-id-login') {
        window.location.hash = '#initialize/connect/select-machine-model';
    }
};
export const getInfo = (silent = false, isWebSocialSignIn = false) => __awaiter(void 0, void 0, void 0, function* () {
    const response = (yield axiosFluxId.get('/user/info?query=credits', {
        withCredentials: true,
    }));
    if (response.error) {
        if (!silent) {
            handleErrorMessage(response.error);
        }
        return null;
    }
    const responseData = response.data;
    if (response.status === 200) {
        if (responseData.status === 'ok') {
            updateUser(responseData, isWebSocialSignIn);
        }
        return responseData;
    }
    if (!silent) {
        const message = responseData.message ? `${responseData.info}: ${responseData.message}` : responseData.info;
        alert.popUpError({ message });
    }
    return null;
});
const getAccessToken = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield axiosFluxId.get('/oauth/', {
        params: {
            response_type: 'token',
        },
        withCredentials: true,
    });
    if (response.error) {
        handleErrorMessage(response.error);
        return false;
    }
    const responseData = response.data;
    if (responseData.status === 'ok') {
        return responseData.token;
    }
    const message = responseData.message ? `${responseData.info}: ${responseData.message}` : responseData.info;
    alert.popUpError({ message });
    return null;
});
export const signInWithFBToken = (fb_token) => __awaiter(void 0, void 0, void 0, function* () {
    if (OAUTH_TOKEN.has(fb_token))
        return false;
    OAUTH_TOKEN.add(fb_token);
    progress.openNonstopProgress({ id: 'flux-id-login' });
    const response = (yield axiosFluxId.post('/user/signin', { fb_token }, {
        withCredentials: true,
    }));
    progress.popById('flux-id-login');
    if (response.error) {
        handleErrorMessage(response.error);
        return false;
    }
    const { data } = response;
    if (data.status === 'ok') {
        handleOAuthLoginSuccess(data);
        yield getInfo(true, true);
        return true;
    }
    const message = data.message ? `${data.info}: ${data.message}` : data.info;
    alert.popUpError({ message });
    return false;
});
export const signInWithGoogleCode = (info) => __awaiter(void 0, void 0, void 0, function* () {
    const data = {
        google_code: info.code,
        redirect_uri: info.redirect_url,
    };
    if (OAUTH_TOKEN.has(data.google_code))
        return false;
    OAUTH_TOKEN.add(data.google_code);
    progress.openNonstopProgress({ id: 'flux-id-login' });
    const response = yield axiosFluxId.post('/user/signin', data, {
        withCredentials: true,
    });
    progress.popById('flux-id-login');
    if (response.error) {
        handleErrorMessage(response.error);
        return false;
    }
    const responseData = response.data;
    if (responseData.status === 'ok') {
        handleOAuthLoginSuccess(responseData);
        yield getInfo(true, true);
        return true;
    }
    const message = responseData.message ? `${responseData.info}: ${responseData.message}` : responseData.info;
    alert.popUpError({ message });
    return false;
});
export const signOut = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield axiosFluxId.get('/user/logout', {
        withCredentials: true,
    });
    if (response.status === 200) {
        updateUser();
        fluxIDEvents.emit('logged-out');
        return response.data;
    }
    return false;
});
const setHeaders = (csrftoken) => {
    axiosFluxId.defaults.headers.post['X-CSRFToken'] = csrftoken;
    axiosFluxId.defaults.headers.put['X-CSRFToken'] = csrftoken;
    axiosFluxId.defaults.headers.delete['X-CSRFToken'] = csrftoken;
};
export const init = () => __awaiter(void 0, void 0, void 0, function* () {
    axiosFluxId.defaults.withCredentials = true;
    if (!isWeb()) {
        // Set csrf cookie for electron only
        cookies.on('changed', (event, cookie, cause, removed) => {
            if (cookie.domain === FLUXID_DOMAIN && cookie.name === 'csrftoken' && !removed) {
                setHeaders(cookie.value);
            }
        });
    }
    communicator.on('FB_AUTH_TOKEN', (e, dataString) => {
        const data = parseQueryData(dataString);
        const token = data.access_token;
        signInWithFBToken(token);
    });
    communicator.on('GOOGLE_AUTH', (e, dataString) => {
        const data = parseQueryData(dataString);
        signInWithGoogleCode(data);
    });
    if (isWeb() || storage.get('keep-flux-id-login') || storage.get('new-user')) {
        // If user is new, keep login status after setting machines.
        if (!isWeb()) {
            // Init csrftoken for electron
            const csrfcookies = yield cookies.get({
                domain: FLUXID_DOMAIN,
                name: 'csrftoken',
            });
            if (csrfcookies.length > 0) {
                // Should be unique
                setHeaders(csrfcookies[0].value);
            }
        }
        const res = yield getInfo(true);
        if (res && res.status !== 'ok') {
            updateMenu();
        }
    }
    else {
        signOut();
        updateMenu();
    }
});
export const externalLinkFBSignIn = () => {
    // eslint-disable-next-line max-len
    const fbAuthUrl = `${FB_OAUTH_URI}?client_id=${FB_APP_ID}&redirect_uri=${FB_REDIRECT_URI}&response_type=token&scope=email`;
    browser.open(fbAuthUrl);
};
export const externalLinkGoogleSignIn = () => {
    // eslint-disable-next-line max-len
    const gAuthUrl = `${G_OAUTH_URL}?client_id=${G_CLIENT_ID}&redirect_uri=${G_REDIRECT_URI}&response_type=code&scope=email+profile`;
    browser.open(gAuthUrl);
};
export const externalLinkMemberDashboard = () => __awaiter(void 0, void 0, void 0, function* () {
    const token = yield getAccessToken();
    if (token) {
        const urlPrefix = i18n.getActiveLang() === 'zh-tw' ? 'tw-' : '';
        const url = `https://${urlPrefix}store.flux3dp.com/api_entry/?feature=beam-studio-login&key=${token}`;
        browser.open(url);
    }
});
export const signIn = (signInData) => __awaiter(void 0, void 0, void 0, function* () {
    progress.openNonstopProgress({ id: 'flux-id-login' });
    const response = yield axiosFluxId.post('/user/signin', signInData, {
        withCredentials: true,
    });
    progress.popById('flux-id-login');
    if (response.status === 200) {
        const { data } = response;
        if (data.status === 'ok') {
            updateUser({ email: data.email });
            yield getInfo(true);
        }
        return data;
    }
    handleErrorMessage(response.error);
    return response;
});
export const submitRating = (ratingData) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield axiosFluxId.post('/user_rating/submit_rating', ratingData, {
        withCredentials: true,
    });
    if (response.status === 200) {
        const { data } = response;
        if (data.status === 'ok') {
            updateUser({ email: data.email });
        }
        return data;
    }
    handleErrorMessage(response.error);
    return response;
});
export const getPreference = (key = '', silent = false) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield axiosFluxId.get(`software-preference/bxpref/${key}`, {
        withCredentials: true,
    });
    if (response.status === 200) {
        const { data } = response;
        return data;
    }
    if (!silent)
        handleErrorMessage(response.error);
    return response;
});
export const setPreference = (value) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield axiosFluxId.post('software-preference/bxpref', value, {
        withCredentials: true,
    });
    if (response.status === 200) {
        const { data } = response;
        if (data.status === 'ok')
            return true;
    }
    return false;
});
export const getNPIconsByTerm = (term, offset = 0) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield axiosFluxId.get(`/api/np/icons/${term}`, {
        params: {
            offset,
        },
        withCredentials: true,
    });
    if (response.error) {
        handleErrorMessage(response.error);
        return false;
    }
    return response.data;
});
export const getNPIconByID = (id) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield axiosFluxId.get(`/api/np/icon/${id}`, {
        withCredentials: true,
    });
    if (response.error) {
        handleErrorMessage(response.error);
        return false;
    }
    return response.data;
});
export const getDefaultHeader = () => {
    if (isWeb()) {
        const csrfToken = cookies.getBrowserCookie('csrftoken');
        return {
            'X-CSRFToken': csrfToken,
        };
    }
    return undefined;
};
export default {
    init,
    getPreference,
    setPreference,
};
